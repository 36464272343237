import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

class AddresTimezone extends Component {
  constructor(props) {
    super(props);
    this.interval = null;
    this.state = {
      now: moment.tz(this.props.timeZone),
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      this.setState({ now: moment.tz(this.props.timeZone) });
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return (
      <div className="address">
        <div className="info_address"> 
          <ul>
            <li>{this.props.courtName}</li>
            <li className="text-center">
              Time zone: {this.props.timeZone}
              <br />
              Now {this.state.now.format('hh:mm:ss A')}
            </li>
            <li>
              <span>{this.props.address}</span>
              <img src={this.props.profileImage} alt="" />
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

AddresTimezone.propTypes = {
  timeZone: PropTypes.string,
  address: PropTypes.string,
};
export default AddresTimezone;
