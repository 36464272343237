import React, { Component } from "react";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import { connect } from "react-redux";
import {
  deleteCoachAvailability,
  deleteMicroSettings,
  getCoachAvailabilityDetailedList,
  resetCoachAvailabilityList,
} from "../../actions/MicroSettings";
import { GetLessonTypes } from "../../actions/Lesson";
import CreateAvailability from "./CreateAvailability";
import { LessonTypeSelect } from "../Business";

class ViewAvailability extends Component {
  constructor(props) {
    super(props);
    this.currentDay = props.currentDay
      ? this.momentWithTimeZone(props.currentDay)
      : moment.tz(props.timeZone);

    this.state = {
      loading: false,
      selectedDate: this.currentDay.clone(),
      lesson_type_id: "-1",
      editableId: null,
    };
  }

  componentDidMount() {
    this.props.getLessonTypes();
    this.handleSubmit();
  }

  componentWillUnmount() {
    this.props.resetCoachAvailabilityList();
  }

  render() {
    const {
      className,
      onClose,
      coachAvailabilityDetailedList,
      timeZone,
    } = this.props;
    const { loading, selectedDate, editableId, lesson_type_id } = this.state;
    const minus_13m = moment(this.currentDay).subtract(13, "month");
    const { user } = this.props;
    const { coach_id = user.id } = this.props;

    return (
      <div className={`modal modal-availability active ${className}`}>
        <div className="modal-content" style={{ maxWidth: "1000px" }}>
          <div className="modal-header">
            <div className="close-btn">
              <button onClick={onClose}>
                <img src="/assets/img/cancel.png" alt="cancel" />
              </button>
            </div>
          </div>
          <div className="d-flex app-bootstrap" style={{ width: "100%" }}>
            <div style={{ width: "730px" }}>
              <div
                className="table-responsive"
                style={{ paddingRight: "20px" }}
              >
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th className="text-center">Title</th>
                      <th className="text-center">Lesson</th>
                      <th className="text-center">Created At</th>
                      <th className="text-center">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loading && coachAvailabilityDetailedList.length
                      ? coachAvailabilityDetailedList.map((item) => {
                          const {
                            lesson_type_id,
                            lesson_type_name,
                            created_date: createdAt,
                            note: title,
                          } = item;

                          return (
                            <tr key={item.id}>
                              <td className="text-center">{title}</td>
                              <td className="text-center">
                                {lesson_type_name}
                              </td>
                              <td className="text-center">
                                {moment(createdAt)
                                  .tz(timeZone)
                                  .format("MMMM Do YYYY, h:mm:ss a")}
                              </td>
                              <td className="text-center">
                                <div className="d-flex justify-center">
                                  <button
                                    className="btn btn-red rounded mr-2"
                                    type="button"
                                    title="Delete"
                                    onClick={() => this.handleDelete(item)}
                                  >
                                    <img
                                      src="/assets/img/cancel-white.png"
                                      width={15}
                                      height={15}
                                      alt="delete"
                                    />
                                  </button>
                                  <button
                                    className="btn btn-green rounded mr-2"
                                    type="button"
                                    title="Edit"
                                    onClick={() => this.handleEdit(item)}
                                  >
                                    <img
                                      src="/assets/img/edit.png"
                                      width={15}
                                      height={15}
                                      alt="edit"
                                    />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      : null}
                    {!loading && !coachAvailabilityDetailedList.length && (
                      <tr>
                        <td colSpan="5" className="text-center">
                          There are no availabilities found.
                        </td>
                      </tr>
                    )}
                    {loading && (
                      <tr>
                        <td colSpan="5" className="text-center">
                          Loading...
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="datepicker-container">
              <DatePicker
                inline
                onChange={(val) => {
                  this.handleChange({
                    target: { name: "selectedDate", value: val },
                  });

                  this.setState((st) => ({
                    ...st,
                    loading: true,
                  }));
                  setTimeout(() => this.handleSubmit(), 1000);
                }}
                selected={selectedDate}
                minDate={minus_13m}
                locale="en-gb"
              />
              <div className="rec-form">
                <div className="form-group lesson-form-group">
                  <label>Lesson Type</label>
                  <div className="picky-container">
                    <LessonTypeSelect
                      defaultOption={{ id: -1, value: "All" }}
                      value={{
                        id: lesson_type_id,
                      }}
                      onChange={(opt) => {
                        this.handleChange({
                          target: { name: "lesson_type_id", value: opt.id },
                        });

                        setTimeout(() => this.handleSubmit(), 1000);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {editableId && (
            <CreateAvailability
              editableId={editableId}
              coach_id={coach_id}
              onClose={() => {
                this.setState((st) => ({
                  ...st,
                  editableId: null,
                }));
                this.handleSubmit();
              }}
            />
          )}
        </div>
      </div>
    );
  }

  handleSubmit = async (e) => {
    try {
      e?.preventDefault();

      this.setState((st) => ({
        ...st,
        loading: true,
      }));

      const { user } = this.props;
      const { coach_id = user.id } = this.props;
      const { lesson_type_id, selectedDate } = this.state;

      const startDate = selectedDate.clone().startOf("day");
      const endDate = selectedDate.clone().add(1, "day").startOf("day");

      await this.props.getCoachAvailabilityDetailedList({
        startDate,
        endDate,
        coach_id: coach_id,
        lesson_type_id: lesson_type_id != "-1" ? lesson_type_id : undefined,
      });
    } finally {
      this.setState((st) => ({
        ...st,
        loading: false,
      }));
    }
  };

  handleDelete = async (item) => {
    try {
      if (
        await window.modal.cnfirm(
          "Confirm",
          "Are you sure to delete the availability?"
        )
      ) {
        await this.props.deleteMicroSettings(
          item.id,
          "coach_availability",
          null,
          {}
        );

        this.handleSubmit();
      }
    } catch (e) {
      console.error("error: availability delete:", e);
    }
  };

  handleEdit = async (item) => {
    this.setState((st) => ({
      ...st,
      editableId: item.id,
    }));
  };

  handleChange = (e) => {
    const {
      target: { name, value },
    } = e;

    this.setState((st) => ({ ...st, [name]: value }));
  };

  momentWithTimeZone = (...args) => moment(...args).tz(this.props.timeZone);
}

const mapStateToProps = (state) => {
  return {
    business: state.auth.user.business,
    user: state.auth.user,
    timeZone: state.auth.user.business.timezone,

    coachAvailabilityDetailedList:
      state.micro_settings.coachAvailability.detailedList,

    lessonTypes: state.lesson.lessonTypes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCoachAvailabilityDetailedList: (data) =>
      dispatch(getCoachAvailabilityDetailedList(data)),
    getLessonTypes: (data) => dispatch(GetLessonTypes(data)),
    resetCoachAvailabilityList: () => dispatch(resetCoachAvailabilityList()),
    deleteMicroSettings: (id, type = "apply", group_by = null, options = {}) =>
      dispatch(deleteMicroSettings(id, type, group_by, options)),
    deleteCoachAvailability: (id, data) =>
      dispatch(deleteCoachAvailability(id, data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewAvailability);
