import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
// import { Link } from 'react-router-dom';
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";

class RouteGroup extends Component {
  componentWillMount() {
    const { user } = this.props;
    if (
      user &&
      user.user_type === "bu" &&
      !this.props.premium &&
      this.props.path === "/private"
    ) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }

  render() {
    const { subscription, business } = this.props;
    const is_canceled =
      subscription &&
        (subscription.status === "canceled" || subscription.status === "unpaid")
        ? true
        : false;
    const pause_collection_resumes_at =
      subscription && subscription.pause_collection
        ? subscription.pause_collection.resumes_at
        : null;

    return (
      <div className="app-content" id="app-content">
        {pause_collection_resumes_at || is_canceled ? (
          <React.Fragment>
            {pause_collection_resumes_at && !is_canceled && (
              <div className="line">
                <p>
                  Your paid subscription will end on{" "}
                  {moment
                    .unix(pause_collection_resumes_at)
                    .tz(business.timezone)
                    .format("MMMM Do YYYY")}{" "}
                  <span
                    style={{
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={this.props.reactivateSubscription}
                  >
                    Re-Activate Subscription
                  </span>
                </p>
              </div>
            )}
          </React.Fragment>
        ) : (
          ""
        )}
        {this.props.location?.pathname !== "/widget" && (
          <Header
            signOut={this.props.signOut}
            manage_lessons={this.props.manage_lessons}
            isSignIn={this.props.isSignIn}
            userType={this.props.userType}
          />
        )}

        {this.props.children}
        {this.props.location?.pathname !== "/widget"&&this.props.location?.pathname !== "/" && (
          <Footer isSignIn={this.props.isSignIn} />
        )}
      </div>
    );
  }
}

RouteGroup.propTypes = {
  isSignIn: PropTypes.bool.isRequired,
  signOut: PropTypes.func.isRequired,
  userType: PropTypes.string.isRequired,
  subscribed: PropTypes.bool,
  payment: PropTypes.objectOf(PropTypes.any),
};

export default RouteGroup;
