import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { connectstripeaccount, ConnectError } from '../../actions/Stripe';

import moment from 'moment-timezone';
import StripeCharge from '../../components/StripeCharge';
import TerminalList from '../../components/TerminalList';
import Payments from '../Payments';


import ChargeHistory from '../../components/ChargeHistory';
import { getChargeHistory } from '../../actions/ChargeHistory';
import { hasPermission } from "../../utils/helpers";

import axios from 'axios';

class StripeConnect extends Component {
  constructor(props) {
    super(props);
    const url = new URL(window.location.href);
    this.state = {
      error: url.searchParams.get('error'),
      error_description: url.searchParams.get('error_description'),
      code: url.searchParams.get('code'),
      last_id: null,
      deafultFilters: null,
      permission: hasPermission(this.props.user.member_type.roles, "payments", "update")

      //scope: url.searchParams.get('scope'),
    };
    this.signal = axios.CancelToken.source();
    this.viewMore = this.viewMore.bind(this);

  }
  async componentDidMount() {

    if (this.props.isConnected) {
      this.props.getChargeHistory(0, this.signal.token);
    }
    if (this.state.error) {
      this.props.ConnectError(this.state.error_description);
      await window.modal.alert('Error', this.state.error_description);
    } else if (this.state.code) {
      await this.props.connectstripeaccount(this.state.code);

    }
  }




  componentWillUnmount() {
    this.signal.cancel('Api is being canceled');
  }
  viewMore(last_id, cancel_token) {
    this.props.getChargeHistory(last_id, cancel_token);
  }

  setDefaultFilters = (from_charge = false) => {
    if (!from_charge) return null;
    this.setState({
      deafultFilters: {
        startDate: moment().startOf('month'),
        endDate: moment()
      }
    })

  }
  openStripePriceTable() {
    return async () => {
      const str = `
      <table class='Ballbuttonfees'>
        <thead>
          <tr>
            <th colspan='2' style='background-color: yellow;'>Ballbutton fees (in addition to Stripe fees)</th>
          </tr>
          <tr>
            <th></th>
            <th>Ballbutton</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Terminal card present </td>
            <td>0.2%</td>
          </tr>
          <tr>
            <td>Online Payments</td>
            <td>0.2%</td>
          </tr>
          <tr>
            <td>ACH debit online charge</td>
            <td>0.2%</td>
          </tr>
        </tbody>
      </table>`;
      await window.modal.alert('Info', str, {
        style: {
          width: '500px',
          maxWidth: '100%',
        }
      });
    }
  }
  render() {
    const { data, count, loading, lastObject, isEnd, user } = this.props;
    const { permission } = this.state
    if (!this.props.isConnected) {
      return (
        <div className="stripe">
          <div className="container">
            <div className="stripe-connect">
              <h1>BallButton Payments</h1>
              {/*<p>Ballbutton processes user payments through Stripe, and a Stripe account is required to receive payments through the
                Ballbutton platform.  By enabling payment functionality and linking your
                Stripe account with your Ballbutton, you agree to the payment of certain fees and charges to Ballbutton,
                including payment and processing surcharges on amounts processed through the Ballbutton platform as
                determined from time to time. Ballbutton fees and charges applicable to payment functionality are published
                <span style={{ color: 'blue', borderBottom: '1px solid blue' }} onClick={this.openStripePriceTable()} > here </span>
                and, in accordance with the BallButton Terms of Service, Ballbutton will make reasonable efforts to
                keep pricing information published on the website and in our applications up to date.
                Fees and charges imposed by <a href='https://stripe.com'>Stripe</a> are governed by your agreement with Stripe.</p> */}
              <div className='buttonStripe'>
                <a href={`https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_STRIPE_CONNECT}&scope=read_write&redirect_uri=${process.env.REACT_APP_BASE_URL}private/stripe`} className="connect-button"><span>Connect with Stripe</span></a>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="stripe messages">
        <div className="container">
          <div className="charge-wrapp">
            <div className="charge-col pos_relative">
              <div className={!permission ? "over_modal" : ""}></div>
              <TerminalList />
            </div>
            <div className="stripe-connect  pos_relative charge-col">
              <h2>Charge with</h2>
              { /*<StripeCharge setDefaultFilters={this.setDefaultFilters} />*/}
              <div className={!permission ? "over_modal" : ""}></div>
              <Payments
                show_all={true}
                show_members={true}
                fromPayment={true}
                in_booking={false}
                book_pay_type={null}
              />
            </div>
            <div className="charge-col">
              <ChargeHistory
                isEnd={isEnd}
                lastObject={lastObject}
                viewMore={this.viewMore}
                business={this.props.business}
                getChargeHistory={this.props.getChargeHistory}
                cancelToken={this.signal.token}
                data={data}
                count={count}
                user={user}
                permission={permission}
                loading={loading}
                deafultFilters={this.state.deafultFilters}
              />
            </div>
          </div>
        </div>

      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    isConnected: state.stripe.isConnected,
    data: state.chargeHistory.data,
    count: state.chargeHistory.count,
    business: state.auth.user.business,
    user: state.auth.user,
    loading: state.chargeHistory.loading,
    lastObject: state.chargeHistory.lastObject,
    isEnd: state.chargeHistory.isEnd,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    connectstripeaccount: (code) => dispatch(connectstripeaccount(code)),

    ConnectError: (error) => dispatch(ConnectError(error)),
    getChargeHistory: (last_id, cancel_token, params = null) => dispatch(getChargeHistory(last_id, cancel_token, params)),

  };
};
StripeConnect.propTypes = {
  connectstripeaccount: PropTypes.func.isRequired,
  ConnectError: PropTypes.func.isRequired,
  isConnected: PropTypes.bool.isRequired,
  getChargeHistory: PropTypes.func.isRequired
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StripeConnect));
