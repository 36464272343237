import React from "react";
import { useEffect } from "react";
import Picky from "react-picky";
import { useDispatch, useSelector } from "react-redux";
import { getGroupList } from "../../../actions/Facility";
import { getEntityList } from "../../../actions/Location";

const initialDefaultOption = { id: -1, value: "None" };

const FacilitySelect = ({
  value,
  defaultOption = initialDefaultOption,
  multiple = false,
  onChange,
  className,
  disabled
}) => {
  const { facilityGroup, locationEntity } = useSelector((state) => ({
    facilityGroup: state.facility.group,
    locationEntity: state.location.entity,
  }));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGroupList());
    dispatch(getEntityList());
  }, [dispatch]);

  const options = [
    defaultOption,
    ...[...facilityGroup.list.data]
      .sort(function (a, b) {
        if (a.order_index < b.order_index) {
          return -1;
        }
        if (a.order_index > b.order_index) {
          return 1;
        }
        return 0;
      })
      .map((ftG) => {
        const loc = locationEntity.list.data.find(
          (lc) => lc.id == ftG.location_id
        );
        if (!loc) return;

        return {
          id: ftG.id,
          value: `${loc.name} - ${ftG.name}`,
          data: {
            facilityGroup: ftG,
            location: loc,
          },
        };
      }),
  ].filter((it) => !!it);

  if (Array.isArray(value)) {
    value = value.map((vl) => {
      if (!vl.value) {
        const valueFromList = options.find((itm) => itm.id == vl.id);

        vl = valueFromList
          ? valueFromList
          : {
              ...vl,
              // value: 'Option: ' + vl.id,
              value: "",
            };
      }
      return vl;
    });
  } else if (value && !value.value) {
    const valueFromList = options.find((itm) => itm.id == value.id);

    value = valueFromList
      ? valueFromList
      : {
          ...value,
          // value: 'Option: ' + value.id,
          value: "",
        };
  }

  return (
    <div className={`picky-container ${className}`} style={{ flex: "0 0 70%" }}>
      <Picky
        includeFilter
        options={options}
        value={value}
        onChange={onChange}
        valueKey="id"
        labelKey="value"
        multiple={multiple}
        dropdownHeight={350}
        numberDisplayed={2}
        disabled={disabled}
        includeSelectAll
      />
    </div>
  );
};

export default FacilitySelect;
