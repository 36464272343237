export const STRIPE_CONNECT_SUCCESS = 'STRIPE_CONNECT_SUCCESS';
export const STRIPE_NO_CONNECT_SUCCESS = 'STRIPE_NO_CONNECT_SUCCESS';
export const STRIPE_CONNECT_ERROR = 'STRIPE_CONNECT_ERROR';
export const STRIPE_GET_PLANS_SUCCESS = 'STRIPE_GET_PLANS_SUCCESS';
export const STRIPE_GET_PLANS_ERROR = 'STRIPE_GET_PLANS_ERROR';
export const ADD_PAYMANT_SUCCESS = 'ADD_PAYMANT_SUCCESS';
export const CHANGE_PAYMANT_SUCCESS = 'CHANGE_PAYMANT_SUCCESS';
export const REMOVE_PAYMANT_SUCCESS = 'REMOVE_PAYMANT_SUCCESS';
export const VERIFI_ACCOUNT_SUCCESS = 'VERIFI_ACCOUNT_SUCCESS';
export const VERIFI_ACCOUNT_LOADING = 'VERIFI_ACCOUNT_LOADING';
export const ADD_PLAN_LOADING = 'ADD_PLAN_LOADING';
export const ADD_PLAN_SUCCESS = 'ADD_PLAN_SUCCESS';
export const UPDATE_PLAN_SUCCESS = 'UPDATE_PLAN_SUCCESS';
export const DELETE_PLAN_SUCCESS = 'DELETE_PLAN_SUCCESS';
export const ADD_PRODUCT_SUCCESS = 'ADD_PRODUCT_SUCCESS';
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS';
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS';
export const ADD_CHARGE_LOADING = 'ADD_CHARGE_LOADING';
