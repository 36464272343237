import axios from "../config/axios";
import moment from "moment-timezone";

import {
  GET_COURTS_ERROR,
  GET_COURTS_LISTING_SUCCESS,
  GET_COURTS_LOADING,
  GET_COURTS_SUCCESS,
  GET_ADDONSBYID_SUCCESS,
  GET_LISTING_LOADING,
} from "../constants/Courts";

import {
  GET_COURT_LISTING_SUCCESS,
  GET_COURT_LOADING,
} from "../constants/Court";

/**Single Court part */
export function getCortListingSuccess(payload) {
  return {
    type: GET_COURT_LISTING_SUCCESS,
    payload: payload,
  };
}

// function getCortSuccess(payload) {
//   return {
//     type: GET_COURT_SUCCESS,
//     payload: payload,
//   };
// }

// function getCortError(payload) {
//   return {
//     type: GET_COURT_ERROR,
//     payload: payload,
//   };
// }

function getCourtLoading(bool) {
  return {
    type: GET_COURT_LOADING,
    payload: bool,
  };
}

/**End */

function getCortsListingSuccess(payload) {
  return {
    type: GET_COURTS_LISTING_SUCCESS,
    payload: payload,
  };
}

function getCortsSuccess(payload) {
  return {
    type: GET_COURTS_SUCCESS,
    payload: payload,
  };
}

function getAddOnsByIdSuccess(payload) {
  return {
    type: GET_ADDONSBYID_SUCCESS,
    payload: payload,
  };
}

function getCortsError(payload) {
  return {
    type: GET_COURTS_ERROR,
    payload: payload,
  };
}

function getCourtsLoading(bool) {
  return {
    type: GET_COURTS_LOADING,
    payload: bool,
  };
}

function getCourtsListingLoading(bool) {
  return {
    type: GET_LISTING_LOADING,
    payload: bool,
  };
}

export function getCourts() {
  return async (dispatch) => {
    dispatch(getCourtsLoading(true));
    try {
      let res = await axios.get("court/getall");

      if (res.data.code !== 200)
        throw new Error(`The response code is ${res.data.code}`);

      dispatch(getCortsSuccess(res.data.payload));
      return true;
    } catch (error) {
      dispatch(
        getCortsError({
          message: error.message,
        })
      );
      return false;
    } finally {
      dispatch(getCourtsLoading(false));
    }
  };
}
export function getAddOnsByCortId(id,data) {
  return async (dispatch) => {
    dispatch(getCourtsLoading(true));
    try {
      let res = await axios.post(`add_on/list/${id}`,{...data});

      if (res.data.code !== 200)
        throw new Error(`The response code is ${res.data.code}`);

      dispatch(getAddOnsByIdSuccess(res.data.payload));
      return true;
    } catch (error) {
      dispatch(
        getCortsError({
          message: error.message,
        })
      );
      return false;
    } finally {
      dispatch(getCourtsLoading(false));
    }
  };
}

export function getCourtListingavAilability(state, repeatState) {
  return async (dispatch, getState) => {
    try {
      dispatch(getCourtsLoading(true));
      let sendDate;

      if (!repeatState) {
        sendDate = {
          frequency: "daily",
          occurrences: 1,
          recurrence: false,
          every: 1,
          days: [],
          end_time: state.end_time
            .set({
              second: 0,
              millisecond: 0,
            })
            .format(),
          start_date: state.start_time
            .set({
              second: 0,
              millisecond: 0,
            })
            .format(),
          start_time: state.start_time
            .set({
              second: 0,
              millisecond: 0,
            })
            .format(),
          multipleDates: [],
        };
      } else {
        sendDate = {
          frequency:
            repeatState.repeatType === repeatType.WEEKLY ||
            repeatState.repeatType === repeatType.BI_WEEKLY
              ? "weekly"
              : repeatState.repeatType === repeatType.MONTHLY
              ? "monthly"
              : "daily",
          occurrences:
            repeatState.endstype === "occurrence"
              ? parseInt(repeatState.endCountSelect)
              : 1,
          recurrence: repeatState.repeatOn,
          every: repeatState.repeatType === repeatType.BI_WEEKLY ? 2 : 1,
          days:
            repeatState.repeatType === repeatType.MONTHLY
              ? [moment(state.currentDay).date()]
              : repeatState.repeatType === repeatType.WEEKLY ||
                repeatState.repeatType === repeatType.BI_WEEKLY
              ? repeatState.weekDays.map((item) => item + 1)
              : [],
          end_time: state.end_time
            .set({
              second: 0,
              millisecond: 0,
            })
            .format(),
          start_date: state.start_time
            .set({
              second: 0,
              millisecond: 0,
            })
            .format(),
          start_time: state.start_time
            .set({
              second: 0,
              millisecond: 0,
            })
            .format(),
          multipleDates: [],
        };
      }

      let res = await axios.post("court/availability", sendDate);

      dispatch(getCortsSuccess(res.data.payload));
    } catch (error) {
      console.log(error);
      // await window.modal.alert('Error', error.response.data.message);
    } finally {
      dispatch(getCourtsLoading(false));
    }
  };
}

export function sendMessage(obj, text) {
  return async (dispatch) => {
    try {
      let res = await axios.post(
        "user/court/sendmessagetoparticipantcp",
        {
          ...obj,
          message: text,
        },
        {
          headers: {
            // eslint-disable-next-line no-undef
            "Content-Type": "application/json",
          },
        }
      );
      if (res.data.code !== 200) throw new Error(res.data.message);
      window.modal.alert("Alert", "Message successfully sent.");
    } catch (error) {
      window.modal.alert("Error", error.message);
    }
  };
}
export function sendMessageBulk(user_ids, text) {
  return async (dispatch) => {
    try {
      let res = await axios.post(
        "members/sendemailtomembers",
        {
          user_ids:user_ids,
          message: text,
        },
        {
          headers: {
            // eslint-disable-next-line no-undef
            "Content-Type": "application/json",
          },
        }
      );
      if (res.data.code !== 200) throw new Error(res.data.message);
      window.modal.alert("Alert", "Message successfully sent.");
    } catch (error) {
      window.modal.alert("Error", error.message);
    }
  };
}

const repeatType = {
  DAYLY: "DAYLY",
  WEEKLY: "WEEKLY",
  BI_WEEKLY: "BI-WEEKLY",
  MONTHLY: "MONTHLY",
};

export function updateCourtListing(obj, repeatState, history, mode = 0) {
  // 0 means Single event
  return async (dispatch, getStore) => {
    dispatch(getCourtLoading(true));
    let _assigned = [];
    const { user } = getStore().auth;

    if (["coach", "supercoach"].includes(user.member_type.key)) {
      _assigned = [user.id];
    }
    let url = `appointment/update/${obj.clid}`;
    let recurringInfo = createRequestRecurringInfo(
      repeatState,
      obj.currentDay,
      obj
    );

    let tags = [];

    if (obj.tag && obj.tag.id !== -1) {
      tags = [obj.tag.id];
    }

    let request_data = {
      note: obj.note,
      courts: Array.isArray(obj.courts)
        ? obj.courts.map((v) => v.id)
        : [obj.courts].map((v) => v.id),
      closed: obj.asbook,
      assigned:
        obj.coach.length > 0 ? obj.coach.map((each) => each.id) : _assigned,
      tags,
      recurrence: recurringInfo,
      sport_id: obj.sport_id,
    };

    if (mode > 0) {
      url = `appointment/update_complex/${obj.clid}`;
      request_data = {
        ...request_data,
        mode: mode,
        recurrence_id: obj.currentRecord.recurrence_id,
        force: false,
      };
      //delete request_data.courts;
    }
    try {
      const res = await axios.put(
        url,
        {
          ...request_data,
        },
        {
          headers: {
            // eslint-disable-next-line no-undef
            "Content-Type": "application/json",
          },
        }
      );
      dispatch(getCortListingSuccess(res.data.payload));
    } catch (error) {
      //When we have conflict

      if (error.response && error.response.data.code === 409) {
        const timezone = getStore().auth.user.business.timezone;

        const conflicts_text = error.response.data.err.data
          ? error.response.data.err.data.map((e) => {
              const start_time = moment(e.overlap.start_time)
                .tz(timezone)
                .format("MMM D, YYYY  h:mma");
              const end_time = moment(e.overlap.end_time)
                .tz(timezone)
                .format("MMM D, YYYY  h:mma");
              return `<p>${e.overlap.courts["0"]["name"]} &nbsp; ${start_time} - ${end_time} </p>`;
            })
          : error.response.data.message;
        if (
          (await window.modal.cnfirm("Conflict", conflicts_text, {
            style: {
              width: "400px",
            },
            buuton: {
              success: "Ok",
              cancle: "Cancel",
            },
          })) &&
          mode > 0
        ) {
          const res = await axios.put(
            url,
            {
              ...request_data,
              force: true,
            },
            {
              headers: {
                // eslint-disable-next-line no-undef
                "Content-Type": "application/json",
              },
            }
          );
          dispatch(getCortListingSuccess(res.data.payload));
        }
      } else if (error.response && error.response.data.code === 404) {
        history.push("/private/dashboard");
      } else {
        await window.modal.alert("Error", error.response.data.message);
      }
      console.log(error);
    } finally {
      dispatch(getCourtLoading(false));
    }
  };
}

export function addComplex(obj, repeatState, return_id = false) {
  return async (dispatch, getStore) => {
    dispatch(getCourtLoading(true));
    let _assigned = [];
    const user = getStore().auth.user;
    if (["coach", "supercoach"].includes(user.member_type.key)) {
      _assigned = [user.id];
    }
    let recurringInfo = createRequestRecurringInfo(
      repeatState,
      obj.currentDay,
      obj
    );

    let tags = [];

    if (obj.tag && obj.tag.id !== -1) {
      tags = [obj.tag.id];
    }

    let requestData = {
      note: obj.note,
      courts: obj.courts.map((v) => v.id),
      closed: obj.asbook,
      userId: obj.userId,
      sport_id: obj.sport_id,
      fullName: obj.userFullName,
      partners: obj.partners ? obj.partners.map((v) => v.id) : [],
      guests: obj.guests ? obj.guests.map((v) => v.name) : [],
      assigned:
        obj.coach.length > 0 ? obj.coach.map((each) => each.id) : _assigned,
      tags,
      recurrence: recurringInfo,
    };
    try {
      let res = await axios.post(`appointment/add_complex`, requestData, {
        headers: {
          // eslint-disable-next-line no-undef
          "Content-Type": "application/json",
        },
      });
      dispatch(getCortListingSuccess(res.data.payload));
      if (return_id) {
        return {
          apt_id: res.data.payload.appointment_id,
          court_id: res.data.payload.courts["0"],
        };
      } else {
        return true;
      }
    } catch (error) {
      if (error.response && error.response.data.code === 409) {
        const timezone = getStore().auth.user.business.timezone;

        const conflicts_text = error.response.data.err.data.map((e) => {
          const start_time = moment(e.overlap.start_time)
            .tz(timezone)
            .format("MMM D, YYYY  h:mma");
          const end_time = moment(e.overlap.end_time)
            .tz(timezone)
            .format("MMM D, YYYY  h:mma");
          return `<p>${e.overlap.courts["0"]["name"]} &nbsp; ${start_time} - ${end_time} </p>`;
        });
        if (
          await window.modal.cnfirm("Conflict", conflicts_text, {
            style: {
              width: "400px",
            },
            buuton: {
              success: "Ok",
              cancle: "Cancel",
            },
          })
        ) {
          let res = await axios.post(
            `appointment/add_complex`,
            {
              ...requestData,
              //force: true
            },
            {
              headers: {
                // eslint-disable-next-line no-undef
                "Content-Type": "application/json",
              },
            }
          );
          dispatch(getCortListingSuccess(res.data.payload));
          if (return_id) {
            return {
              apt_id: res.data.payload.appointment_id,
              court_id: res.data.payload.courts["0"],
            };
          } else {
            return true;
          }
        } else {
          return false;
        }
      } else {
        await window.modal.alert("Error", error.response.data.message);
      }
      console.log(error);
    } finally {
      dispatch(getCourtLoading(false));
    }
    return false;
  };
}
export function addWithBook(obj, payment = null, return_id = false) {
  return async (dispatch, getStore) => {
    dispatch(getCourtLoading(true));
    let _assigned = [];
    const user = getStore().auth.user;
    const is_nbu = user.user_type === "nbu";
    if (["coach", "supercoach"].includes(user.member_type.key)) {
      _assigned = [user.id];
    }

    let tags = [];

    if (obj.tag && obj.tag.id !== -1) {
      tags = [obj.tag.id];
    }

    let requestData = {
      start_time: obj.startTime
        .utc()
        .set({
          second: 0,
          millisecond: 0,
        })
        .format(),
      end_time: obj.endTime
        .utc()
        .set({
          second: 0,
          millisecond: 0,
        })
        .format(),
      instantBook: true,
      courts: obj.courts.map((v) => v.id),
      userId: obj.userId,
      force: false,
      note: obj.note,
      sport_id: obj.sport_id,
      fullName: obj.userFullName,
      assigned:
        obj.coach.length > 0 ? obj.coach.map((each) => each.id) : _assigned,
      tags,
      partners: obj.partners ? obj.partners.map((v) => v.id) : [],
      guests: obj.guests ? obj.guests.map((v) => v.name) : [],
      add_on_id:obj?.add_on_id?obj.add_on_id:null
      //recurrence: recurringInfo,
    };
   
    try {
      let _url = `appointment/add_book`;
      if (payment) {
        _url = `appointment/book-and-pay`;
        requestData = {
          startDate: requestData.start_time,
          endDate: requestData.end_time,
          amount: payment.amount,
          method: payment.method,
          save_card: payment.save_card,
          currency: payment.currency,
          description: payment.description,
          courts: requestData.courts,
          userId: requestData.userId,
          splitPayment: false,
          partners: requestData.partners,
          guests: requestData.guests,
          sport_id: requestData.sport_id,
          business_id: payment.business_id,
          add_on_id:requestData?.add_on_id?requestData.add_on_id:null
        };

        if (payment.customer) {
          requestData = {
            ...requestData,
            customer: payment.customer,
          };
        }
        if (payment.token) {
          requestData = {
            ...requestData,
            token: payment.token,
          };
          delete requestData.customer;
        }
      }
      let res = await axios.post(_url, requestData, {
        headers: {
          // eslint-disable-next-line no-undef
          "Content-Type": "application/json",
        },
      });
      dispatch(getCortListingSuccess(res.data.payload));
      if (return_id) {
        return {
          apt_id: res.data.payload.appointment_id,
          court_id: res.data.payload.courts["0"],
        };
      } else {
        return true;
      }

      //TODO need to book
      //dispatch(getCortListingSuccess(res.data.payload));
    } catch (error) {
      if (error.response && error.response.data.code === 409) {
        const timezone = getStore().auth.user.business.timezone;

        if (!error.response.data.err.data) {
          await window.modal.alert("Error", error.response.data.message);
          return false;
        }
        if (is_nbu) {
          await window.modal.alert("Error", error.response.data.message);
          return false;
        }
        const conflicts_text = error.response.data.err.data.map((e) => {
          const start_time = moment(e.overlap.start_time)
            .tz(timezone)
            .format("MMM D, YYYY  h:mma");
          const end_time = moment(e.overlap.end_time)
            .tz(timezone)
            .format("MMM D, YYYY  h:mma");
          return `<p>${e.overlap.courts["0"]["name"]} &nbsp; ${start_time} - ${end_time} </p>`;
        });
        if (
          await window.modal.cnfirm("Conflict", conflicts_text, {
            style: {
              width: "400px",
            },
            buuton: {
              success: "Skip the conflict and create the rest",
              cancle: "Cancel",
            },
          })
        ) {
          let res = await axios.post(
            `appointment/add_book`,
            {
              ...requestData,
              force: true,
            },
            {
              headers: {
                // eslint-disable-next-line no-undef
                "Content-Type": "application/json",
              },
            }
          );
          dispatch(getCortListingSuccess(res.data.payload));
          if (return_id) {
            return {
              apt_id: res.data.payload.appointment_id,
              court_id: res.data.payload.courts["0"],
            };
          } else {
            return true;
          }
        }
      } else {
        await window.modal.alert("Error", error.response.data.message);
      }
      console.log(error);
      return false;
    } finally {
      dispatch(getCourtLoading(false));
    }
    return false;
  };
}

export function deleteRequrence(data, updateListingData, update_court = true) {
  return async (dispatch) => {
    try {
      const id = data.id ? data.id : null;
      const recurrence_id = data.recurrence_id ? data.recurrence_id : null;
      const global_recurrence_id = data.global_recurrence_id
        ? data.global_recurrence_id
        : null;
      const keep_booked = data.keep_booked ? data.keep_booked : false;
      const automatically_refund_payment = data.automatically_refund_payment
        ? data.automatically_refund_payment
        : false;
      let url = `appointment/remove_partly/${id}/${recurrence_id}`;
      if (global_recurrence_id) {
        url = `${url}/${global_recurrence_id}`;
      }
      if (keep_booked) {
        url = `${url}?keep_booked=1&automatically_refund_payment=${automatically_refund_payment}`;
      }
      if (!keep_booked) {
        url = `${url}?automatically_refund_payment=${automatically_refund_payment}`;
      }
      let res = await axios.delete(url, {
        headers: {
          // eslint-disable-next-line no-undef
          "Content-Type": "application/json",
        },
      });
      if (update_court) {
        dispatch(
          getalllisting(updateListingData.currentDay, updateListingData.coach)
        );
      }
    } catch (error) {
      dispatch(
        getCortsError({
          message: error.message,
        })
      );
    }
  };
}

export function getalllisting(
  currentDay = moment(),
  coach,
  family = undefined
) {
  return async (dispatch) => {
    try {
      dispatch(getCourtsListingLoading(true));
      const startDate = currentDay.clone().startOf("day").utc().format();

      const endDate = currentDay.clone().add(1, "day").startOf("day").utc().format();

      let request_data = coach
        ? {
            startDate,
            endDate,
            assignedTo: coach,
          }
        : {
            startDate,
            endDate,
           // show_booked:true
          };
      if (family) {
        request_data = {
          ...request_data,
          family: family,
        };
      }

      const res = await axios.post("appointment/listing", request_data, {
        headers: {
          // eslint-disable-next-line no-undef
          "Content-Type": "application/json",
        },
      });
      if (res.data.code !== 200) throw new Error();

      dispatch(getCortsListingSuccess(res.data.payload));
    } catch (error) {
      dispatch(
        getCortsError({
          message: error.message,
        })
      );
    } finally {
      dispatch(getCourtsListingLoading(false));
    }
  };
}

function createRequestRecurringInfo(repeatState, cuurentDay, state) {
  let start_date = state.startTime;
  start_date = start_date
    .utc()
    .set({
      second: 0,
      millisecond: 0,
    })
    .format();
  let end_date = state.endTime;
  end_date = end_date
    .utc()
    .set({
      second: 0,
      millisecond: 0,
    })
    .format();

  return {
    frequency:
      repeatState.repeatType === repeatType.WEEKLY ||
      repeatState.repeatType === repeatType.BI_WEEKLY
        ? "weekly"
        : repeatState.repeatType === repeatType.MONTHLY
        ? "monthly"
        : "daily",
    occurrences:
      repeatState.endstype === "occurrence"
        ? parseInt(repeatState.endCountSelect)
        : 1,
    ends: repeatState.repeatOn
      ? repeatState.endstype === "dataPicker"
        ? repeatState.ends.startOf("day").utc().format()
        : null
      : null,
    end_time: end_date,
    days:
      repeatState.repeatType === repeatType.MONTHLY
        ? [moment(cuurentDay).date()]
        : repeatState.repeatType === repeatType.WEEKLY ||
          repeatState.repeatType === repeatType.BI_WEEKLY
        ? repeatState.weekDays.map((item) => item)
        : [],
    start_date: start_date,
    start_time: start_date,
    every: repeatState.repeatType === repeatType.BI_WEEKLY ? 2 : 1,
    recurrence: repeatState.repeatOn || false,
    multipleDates: [],
  };
}

/*
 * Apointment code
 * */

export function addAppointmentComplex(data, updateListingData) {
  return async (dispatch, getStore) => {
    dispatch(getCourtLoading(true));
    try {
      const res = await axios.post("appointment/add_complex_microsettings", {
        ...data,
        force: false,
      });
      // dispatch(getalllisting(updateListingData.currentDay, updateListingData.coach));
    } catch (error) {
      if (error.response && error.response.data.code === 409) {
        const timezone = getStore().auth.user.business.timezone;

        const conflicts_text = error.response.data.err.data.map((e) => {
          const start_time = moment(e.overlap.start_time)
            .tz(timezone)
            .format("MMM D, YYYY  h:mma");
          const end_time = moment(e.overlap.end_time)
            .tz(timezone)
            .format("MMM D, YYYY  h:mma");
          return `<p>${e.overlap.courts["0"]["name"]} &nbsp; ${start_time} - ${end_time} </p>`;
        });
        if (
          await window.modal.cnfirm("Conflict", conflicts_text, {
            style: {
              width: "400px",
            },
            buuton: {
              success: "Skip the conflict and create the rest",
              cancle: "Cancel",
            },
          })
        ) {
          await axios.post(
            `appointment/add_complex_microsettings`,
            {
              ...data,
              force: true,
            },
            {
              headers: {
                // eslint-disable-next-line no-undef
                "Content-Type": "application/json",
              },
            }
          );
        }
      }
      console.log(error);
    } finally {
      dispatch(getCourtLoading(false));
    }
  };
}

export function addAppointmentToCourt(data, updateListingData) {
  return async (dispatch) => {
    dispatch(getCourtLoading(true));
    try {
      data = data.map((v) => {
        return {
          start_time: v.start ? v.start : v.start_time,
          end_time: v.end ? v.end : v.end_time,
          courts: v.courts,
          instantBook: !!v.instantBook,
          recurrence: {
            start_time: v.start ? v.start : v.start_time,
            end_time: v.end ? v.end : v.end_time,
            recurrence: false,
            multipleDates: [],
          },
        };
      });
      const res = await axios.post("appointment/add_many", data);

      if (res.data.code !== 200) throw new Error(res.data.message);

      dispatch(
        getalllisting(updateListingData.currentDay, updateListingData.coach)
      );
    } catch (error) {
      console.log(error);
      await window.modal.alert("Error", error.response.data.message);
    } finally {
      dispatch(getCourtLoading(false));
    }
  };
}

export function deleteAppointmentFromCourt(
  data,
  updateListingData,
  update_court = true
) {
  return async (dispatch) => {
    dispatch(getCourtLoading(true));
    try {
      const res = await axios.delete(
        `appointment/delete/${data.clid}?automatically_refund_payment=${data.automatically_refund_payment}`
      );

      if (res.data.code !== 200) throw new Error(res.data.message);
      if (update_court) {
        dispatch(
          getalllisting(updateListingData.currentDay, updateListingData.coach)
        );
      }
    } catch (error) {
      console.log(error);
      await window.modal.alert("Error", error.response.data.message);
    } finally {
      dispatch(getCourtLoading(false));
    }
  };
}
export function deleteTimeRuleFromCourt(
  data,
  updateListingData,
  update_court = true
) {
  return async (dispatch) => {
    dispatch(getCourtLoading(true));
    try {
      const res = await axios.delete("appointment/delete_rule", {
        data,
      });

      if (res.data.code !== 200) throw new Error(res.data.message);
      if (update_court) {
        dispatch(
          getalllisting(updateListingData.currentDay, updateListingData.coach)
        );
      }
    } catch (error) {
      console.log(error);
      await window.modal.alert("Error", error.response.data.message);
    } finally {
      dispatch(getCourtLoading(false));
    }
  };
}

export function updateAppointmentToCourt(
  appointmentId,
  data,
  type = "appointment",
  updateListingData
) {
  return async (dispatch, getStore) => {
    dispatch(getCourtLoading(true));
    try {
      let res;
      res = await axios.put("appointment/drag_and_drop/" + appointmentId, {
        courts: data.courts,
        recurrence: {
          end_time: data.end_time,
          start_time: data.start_time,
        },
        old_court_id: data.old_court_id,
      });

      if (res.data.code !== 200) throw new Error(res.data.message);

      dispatch(
        getalllisting(updateListingData.currentDay, updateListingData.coach)
      );
    } catch (error) {
      if (error.response && error.response.data.code === 409) {
        const timezone = getStore().auth.user.business.timezone;

        const conflicts_text = error.response.data.err.data.map((e) => {
          const start_time = moment(e.overlap.start_time)
            .tz(timezone)
            .format("MMM D, YYYY  h:mma");
          const end_time = moment(e.overlap.end_time)
            .tz(timezone)
            .format("MMM D, YYYY  h:mma");
          return `<p>${e.overlap.courts["0"]["name"]} &nbsp; ${start_time} - ${end_time} </p>`;
        });
        await window.modal.cnfirm("Conflict", conflicts_text.join(" "), {
          style: {
            width: "400px",
          },
          buuton: {
            success: "Ok",
            cancle: "Cancel",
          },
        });
      }
      console.log(error);
    } finally {
      dispatch(getCourtLoading(false));
    }
  };
}

export function moveAppointmentInCourt(data, updateListingData) {
  return async (dispatch) => {
    dispatch(getCourtLoading(true));
    try {
      const result = await axios.post("microsettings/movesingle", {
        ...data,
      });

      dispatch(
        getalllisting(updateListingData.currentDay, updateListingData.coach)
      );
      console.log(result);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(getCourtLoading(false));
    }
  };
}
