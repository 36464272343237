export const ADD_LESSON_SUCCESS = 'ADD_LESSON_SUCCESS';
export const UPDATE_LESSON_SUCCESS = 'UPDATE_LESSON_SUCCESS';
export const GET_COURT_LISTING_AVAILABILITY_LOADING = 'GET_COURT_LISTING_AVAILABILITY_LOADING';
export const GET_COURT_LISTING_AVAILABILITY_SUCCESS = 'GET_COURT_LISTING_AVAILABILITY_SUCCESS';
export const ADD_LESSON_LOADING = 'ADD_LESSON_LOADING';
export const GET_LESSON_SUCCESS = 'GET_LESSON_SUCCESS';
export const MARK_AS_BOOK_SUCCESS = 'MARK_AS_BOOK_SUCCESS';
export const MARK_AS_BOOK_LOADING = 'MARK_AS_BOOK_LOADING';
export const DELATE_LESSON_SUCCESS = 'DELATE_LESSON_SUCCESS';
export const GET_SINGLE_LESSON_SUCCESS = 'GET_SINGLE_LESSON_SUCCESS';
export const RESET_SINGLE_LESSON = 'RESET_SINGLE_LESSON';
export const GET_LESSON_TYPES_SUCCESS = 'GET_LESSON_TYPES_SUCCESS';
export const GET_HIDDEN_LESSON_TYPES_SUCCESS = 'GET_HIDDEN_LESSON_TYPES_SUCCESS';
export const GET_LESSON_TYPE_SUCCESS = 'GET_LESSON_TYPE_SUCCESS';
export const ADD_LESSON_TYPES_SUCCESS = 'ADD_LESSON_TYPES_SUCCESS';
export const EDIT_LESSON_TYPES_SUCCESS = 'EDIT_LESSON_TYPES_SUCCESS';
export const GET_LESSON_TYPE_LOADING = 'GET_LESSON_TYPE_LOADING';
//WaitList Part

export const WAITLIST_LOADING = 'WAITLIST_LOADING';
export const WAITLIST_SUCCESS = 'WAITLIST_SUCCESS';

//Get Checkins
export const CHECKINS_LOADING_L = 'CHECKINS_LOADING_L';
export const CHECKINS_SUCCESS_L = 'CHECKINS_SUCCESS_L';
export const CHECKINS_SUCCESS_By_C = 'CHECKINS_SUCCESS_By_C';
export const CHECKINS_ADD_SUCCESS_L = 'CHECKINS_ADD_SUCCESS_L';
export const CHECKOUT_ADD_SUCCESS_L = 'CHECKOUT_ADD_SUCCESS_L';
export const CLOCKIN_SUCCESS_L = 'CLOCKIN_SUCCESS_L';

//Attendance Balance


export const ATTENDANCE_SUCCESS_L = 'ATTENDANCE_SUCCESS_L';
export const ATTENDANCE_LOADING_L = 'ATTENDANCE_LOADING_L';
export const BALANCE_SUCCESS_L = 'BALANCE_SUCCESS_L';
export const BALANCE_LOADING_L = 'BALANCE_LOADING_L';
