import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {GetLessonTypes ,GetHiddenLessonTypes} from "../../actions/Lesson";
import axiosInstant from "../../config/axios";
class ArchiveList extends Component {
  state = {
    hiddenLessonTypes:[]
  };

  componentDidMount() {
    //this.GetHiddenLessonTypes();
  }

  componentDidUpdate(prevProps, prevState) {
   
  }

  render() {
    const {hiddenLessonTypes,user} = this.props;
    const isUserAdmin= user.member_type.key!="member"? true:false;
   
    return (
      <div className={`tags-list`}>
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Name</th>
                <th>Color</th>
                <th style={{display:isUserAdmin?"block":"none"}}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {
               hiddenLessonTypes.map(row => (
                <tr key={row.id}>
                  <td>{row.display_name}</td>
                  <td>
                    <span
                      style={{
                        width: '15px',
                        height: '15px',
                        background: row.color,
                        display: 'inline-block',
                        borderRadius: '50%',
                      }}></span>
                  </td>
                  <td  style={{display:isUserAdmin?"block":"none"}}>
                      <div className="d-flex justify-center anArchiveBtn">
                        <button
                          className="btn btn-gray "
                          type="button"
                          onClick={() => this.unArchiveType(row.id)}>
                         Put back
                        </button>
                      </div>
                    </td>
                </tr>
              ))
              }
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  unArchiveType = async id => {
    try { 
      let res = await axiosInstant.put(`lesson/type/edit/hidden/${id}`,{hidden:false});
      if(res.data.message=="Success"){
        await  window.modal.alert("Success");
        this.props.clouseModal();
        window.location.reload();
      }
    }catch (error){
      window.modal.alert("Notice","Something went wrong, please try later.");
    }
  };

}

const mapStateToProps = state => {
  return {
    hiddenLessonTypes: state.lesson.hiddenLessonTypes,
    user: state.auth.user,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    GetHiddenLessonTypes: () => dispatch(GetHiddenLessonTypes()),
    GetLessonTypes: () => dispatch(GetLessonTypes()),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ArchiveList)
);
