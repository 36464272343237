import React, { Component } from "react";
import PropTypes from "prop-types";
import { Welcome } from "../../Welcome";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class Homepage extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  render() {
    return (
      <div className="homepage">
        <Welcome />
      </div>
    );
  }
}

Homepage.propTypes = {
  isSignIn: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  return {};
};
const mapDispachToProps = (dispach) => {
  return {};
};

export default withRouter(
  connect(mapStateToProps, mapDispachToProps)(Homepage)
);
//export default Homepage;
