import React, { Component } from "react";
import HistoryItem from "../HistoryItem";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import Loading from "../UI/Loading";
import Picky from "react-picky";
import Pagination from "rc-pagination";

const LIMIT = 4;
class ChargeHistory extends Component {
  constructor(props) {
    super(props);
    const timezone = props.user.business ? props.user.business.timezone : null;
    this.state = {
      startDate: moment().tz(timezone).startOf("month"),
      endDate: moment().tz(timezone),
      type: { id: "all", name: "All" },
      // current: 1,
    };
    this.handleScroll = this.handleScroll.bind(this);
  }
  /* onChangePagination = (page, field) => {
     this.setState({
       [field]: page,
     });
     this.props.getChargeHistory(2160, this.props.cancelToken, {
       ...this.state
     });
   };*/

  handleScroll(e) {
    const { loading, isEnd } = this.props;
    if (!loading && !isEnd) {
      let last_id = null;
      if (this.props.lastObject["id"]) {
        last_id = this.props.lastObject["id"];
      }
      //this.props.viewMore(last_id);
      const { scrollHeight, clientHeight, scrollTop } = e.target;
      if (scrollHeight - clientHeight === scrollTop) {
        this.props.viewMore(last_id, this.props.cancelToken);
      }
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.deafultFilters) {
      this.setState({ ...nextProps.deafultFilters });
    }
  }
  setDate = (date, field) => {
    const timezone = this.props.user.business
      ? this.props.user.business.timezone
      : null;
    let _date =
      field === "startDate"
        ? moment(date).tz(timezone).startOf("day")
        : moment(date).tz(timezone).startOf("day");

    this.setState({
      [field]: _date,
    });

    this.props.getChargeHistory(0, this.props.cancelToken, {
      ...this.state,
      [field]: _date,
      type: this.state.type.id,
    });
  };

  handleChange = (e) => {
    this.setState({
      type: e,
    });
    this.props.getChargeHistory(0, this.props.cancelToken, {
      ...this.state,
      type: e.id,
    });
  };

  render() {
    const { data, count, loading, permission } = this.props;
    const { startDate, endDate, type, current } = this.state;
    const types = [
      { id: "all", name: "All" },
      { id: "card", name: "Card" },
      { id: "terminal", name: "Terminal" },
      { id: "cash", name: "Cash" },
      { id: "check-input", name: "Check input" },
    ];

    return (
      <div className="charge-history">
        <h3>Recent Charges</h3>
        {loading && <Loading />}
        <div className="filters-container">
          <div className="each-filter">
            <span>From</span>
            <DatePicker
              selected={startDate}
              dateFormat="MMM D, YYYY"
              onChange={(date) => this.setDate(date, "startDate")}
            />
          </div>
          <div className="each-filter">
            <span>To</span>
            <DatePicker
              selected={endDate}
              dateFormat="MMM D, YYYY"
              onChange={(date) => this.setDate(date, "endDate")}
            />
          </div>
          <div className="each-filter">
            <span> Select type </span>
            <Picky
              options={types}
              value={type}
              valueKey="id"
              labelKey="name"
              multiple={false}
              numberDisplayed={1}
              includeFilter={true}
              onChange={(e) => this.handleChange(e, "type")}
              dropdownHeight={350}
              placeholder="Select type"
            />
          </div>
        </div>
        <ul className="history-list" onScroll={this.handleScroll}>
          {data.map((item) => (
            <HistoryItem
              permission={permission}
              key={item.id}
              id={item.id}
              amount={item.amount}
              source={item.source}
              note={item.description}
              receipt={item.receipt_url}
              params={{ ...this.state, type: this.state.type.id }}
              refunded={item.refunded}
              amount_refunded={item.amount_refunded}
              payment_method_details={item.payment_method_details}
              item={item}
            />
          ))}
          {loading && <li>Loading ...</li>}
        </ul>
        {/* <div className="pagination-class">
          <Pagination
            onChange={(page) => this.onChangePagination(page, "current")}
            pageSize={LIMIT}
            current={current}
            total={count}
          />
        </div>
        is_data && <div className="total-container">
          <div>Total : {parseFloat(total).toFixed(2)}&nbsp; {this.props.business.currency_code.toUpperCase()}</div>
          <div>Refunded : {parseFloat(refunded).toFixed(2)}&nbsp; {this.props.business.currency_code.toUpperCase()}</div>
          </div>*/}
      </div>
    );
  }
}

ChargeHistory.propTypes = {
  viewMore: PropTypes.func,
};

export default ChargeHistory;
