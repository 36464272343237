import React, { Component } from "react";
import Repeat from "../../components/RepeatLesson";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import BookPayType from "../../components/UI/BookPayType";
import Loading from "../../components/UI/Loading";
import WhoCanBook from "../../components/WhoCanBook";
import { LessonTypeSelect, SportSelect } from "../Business";
import { connect } from "react-redux";
import {
  generateTimeListStartedFromByStep,
  getSplitRange,
  roundDate,
} from "../../utils/helpers";
import {
  createCoachAvailability,
  getMicroSettings,
  resetMicroSetting,
  updateCoachAvailability,
} from "../../actions/MicroSettings";
import { getActiveFacilityGroup } from "../../utils/facility";
import Picky from "react-picky";
import { getCourts } from "../../actions/Courts";
import { GLOBAL_LOCATION_FACILITY_PAIR } from "../../utils/constants";

const initialState = {
  loading: false,
  lesson_type_id: "-1",
  sport_id: "-1",
  note: "",
  who_can_book: [],
  book_pay_type: "1",
  repeatState: null,
  weekDays: [],
  courts: [],
  structure: [
    {
      id: 1,
      time_range: {
        start: undefined,
        end: undefined,
      },
      book_advance: {
        count: 365,
        type: "day",
      },
      buffer:0,
      price: 0,
      point_price: 0,
    },
  ],
};

const allowed_payment_types = ["1", "-1"];
const allowed_payment_point_types = ["-1", "2"];
const dateFormat = "hh:mm a";

class CreateAvailability extends Component {
  constructor(props) {
    super(props);
    this.currentDay = props.currentDay
      ? this.momentWithTimeZone(props.currentDay)
      : moment.tz(props.timeZone);

    this.state = { ...initialState ,editableId :this.props.editableId, isDuplicate:false};
    this.handleChangeStart = this.handleChangeStart.bind(this);
  }

  async componentDidMount() {
    this.setState((st) => ({ ...st, loading: true }));
    this.props.resetMicroSetting();
    await this.props.getCourts();

    setTimeout(async () => {
      const startOfDay = this.getMinDate();

      const start = roundDate(
        this.currentDay.clone(),
        this.props.timeStep,
        startOfDay
      );

      const end = start.clone().add(this.props.minDuration, "minute");

      this.setState((state) => ({
        ...state,
        note: "",
        weekDays: [],
        structure: [
          {
            id: 1,
            time_range: {
              start: start,
              end: end,
            },
            book_advance: {
              count: 365,
              type: "day",
            },
            buffer:0,
            price: 0,
            point_price: 0,
          },
        ],
        loading: false,
      }));

      if (this.state.editableId) {
        try {
          this.setState((st) => ({ ...st, loading: true }));
          const payload = await this.props.getMicroSettings(
            this.state.editableId
          );
          if (payload) {
            const microSettingsData = {
              weekDays: [],
              courts: [],
              structure: [],
            };
            const { lesson_type_id = -1 } = payload;
            const item = payload.micro_settings[0];
            const { sport_id = -1 } = item.facility || {};

            microSettingsData.courts = item.courts.map((id) =>
              this.props.courts.find((crt) => crt.id === id)
            );

            this.currentDay = this.momentWithTimeZone(item.rules[0].start_time);

            item.rules.forEach((rule, i) => {
              microSettingsData.weekDays = rule.days || [];

              microSettingsData.structure.push({
                id: i,
                time_range: {
                  start: this.momentWithTimeZone(rule.start_time || undefined),
                  end: this.momentWithTimeZone(rule.end_time || undefined),
                },
                book_advance: {
                  count: rule.advance || "",
                  type: "day",
                },
                buffer:rule.buffer,
                price: rule.price || "",
                point_price: rule.point_price || "",
              });
            });

            this.setState((st) => ({
              ...st,
              ...microSettingsData,
              who_can_book: payload.micro_settings.map((itm) => ({
                ...itm,
                facility: undefined,
              })),
              sport_id: sport_id,
              lesson_type_id: lesson_type_id,
              book_pay_type:
                item.rules.length > 0
                  ? item.rules["0"]["book_pay_type"]
                    ? item.rules["0"]["book_pay_type"]
                    : "1"
                  : "1",
              note: payload.note,
            }));
          }
        } catch (e) {
          console.error("error: failed to fetch availability: ", e);
        } finally {
          this.setState((st) => ({ ...st, loading: false }));
        }
      }
    }, 1000);
  }
  handleDuplicate = () =>{
    this.setState((st) => ({ ...st, editableId: null ,isDuplicate:true}));
   
  }
  handleChangeStart(selectedStart){
    this.setState((state) => ({
      ...state,
      
      structure: state.structure.map((itm) => {
          const start = moment(moment(selectedStart).format("YYYY-MM-DD") + ' ' + moment(itm.time_range.start).format("HH:mm")).seconds(0).milliseconds(0);
          start.date(selectedStart.date());
          start.month(selectedStart.month());
          start.year(selectedStart.year());
          let end = undefined
          if(start.diff(moment(itm.time_range.end).seconds(0).milliseconds(0))<0){
            end=moment(itm.time_range.end).seconds(0).milliseconds(0);
            end.date(this.currentDay.date());
            end.month(this.currentDay.month());
            end.year(this.currentDay.year());
          }else{
            end=moment(moment(selectedStart).format("YYYY-MM-DD") + ' ' + moment(itm.time_range.end).format("HH:mm")).clone().add(1, 'days').seconds(0).milliseconds(0);
            end.date(selectedStart.date());
            end.month(selectedStart.month());
            end.year(selectedStart.year());
          }
          
          return {
            ...itm,
            time_range: {
              start,
              end,
            },
          };
        }),
   
    }));
   
  }

  render() {
    const { className, onClose, micro_settings, timeStep } = this.props;

    const {
      note,
      lesson_type_id,
      sport_id,
      who_can_book,
      book_pay_type,
      repeatState,
      // weekDays,
      courts,
      structure,
      loading,
    } = this.state;

    const recurrence =
      micro_settings.current && micro_settings.current.data
        ? micro_settings.current.data.recurrence
        : null;
    const min_date = this.getMinDate();
    
    const injectTimes = generateTimeListStartedFromByStep(min_date, timeStep);
    const minSelectDate = moment( moment.tz(this.props.timeZone));
    const maxSelectDate = moment( this.currentDay).add(12, "month");
    const selectedValue = moment(this.state.structure[0].time_range.start);
    const timeIntervals = 24 * 60;
    const sportCategoryId = getActiveFacilityGroup()?.sport_category_id;
    const [_,pairId] = window.localStorage.getItem(GLOBAL_LOCATION_FACILITY_PAIR).split(":");
    const isShowBuffer = (this.props.business.id==1 && pairId == 38) ||
    (this.props.business.id==33 && pairId == 43) ||
    (this.props.business.id==2 && pairId == 10)||
    (this.props.business.id==90 && pairId == 103) ||
    (this.props.business.id==107 && pairId == 130) 
  
    return (
      <div className={`modal modal-availability active ${className}`}>
        <div className="modal-content">
          <div className="modal-header">
            <div className="close-btn">
              <button onClick={onClose}>
                <img src="/assets/img/cancel.png" alt="cancel" />
              </button>
            </div>
            {this.state.isDuplicate? (
          <center style={{ color: "red", fontSize: "11px", marginTop: "-10px", marginBottom: "10px" }}><span>This is the duplicate</span></center>
        ) : null}
          </div>
          <form
            autoComplete="nope"
            className={"dynamic-form"}
            onSubmit={this.handleSave}
            data-op-form-id="0"
          >
            <div className="rec-form">
              <div className="lesson-form-group form-group">
                <label>
                  <span className="important-span">*</span>&nbsp; Title
                  (nickname for your use, your customers don't see the title) 
                </label>
                <input
                  required
                  type="text"
                  className="form-control"
                  name="note"
                  value={note}
                  onChange={this.handleChange}
                />
              </div>
            </div>

            <div className="rec-form">
              <div className="form-group lesson-form-group">
                <label>
                  <span className="important-span">*</span>&nbsp; Sport
                </label>
                <div className="picky-container">
                  <SportSelect
                    sportCategoryId={sportCategoryId}
                    value={{
                      id: sport_id,
                    }}
                    onChange={(opt) =>
                      this.handleChange({
                        target: { name: "sport_id", value: opt.id },
                      })
                    }
                  />
                </div>
              </div>
            </div>

            <div className="rec-form">
              <div className="form-group lesson-form-group">
                <label>
                  <span className="important-span">*</span>&nbsp; Lesson Type
                </label>
                <div className="picky-container">
                  <LessonTypeSelect
                    value={{
                      id: lesson_type_id,
                    }}
                    onChange={(opt) =>
                      this.handleChange({
                        target: { name: "lesson_type_id", value: opt.id },
                      })
                    }
                  />
                </div>
              </div>
            </div>

            {/* <div className="rec-form"> */}
            <div
              className="form-group lesson-form-group"
              style={{ marginTop: 0 }}
            >
              <label>
                <span className="important-span">*</span>&nbsp; Courts
              </label>
              <div className="picky-container" style={{ flex: "0 0 70%" }}>
                <Picky
                  includeFilter
                  options={[...this.props.courts]}
                  includeSelectAll={true}
                  value={courts}
                  valueKey="id"
                  labelKey="name"
                  multiple={true}
                  numberDisplayed={2}
                  onChange={(opts) =>
                    this.handleChange({
                      target: { name: "courts", value: [...opts] },
                    })
                  }
                  dropdownHeight={350}
                />
              </div>
            </div>
            {/* </div> */}

            <div className="rec-form">
              <div
                className="lesson-form-group form-group"
                style={{ marginTop: 0 }}
              >
                <label>
                  <span className="important-span">*</span>&nbsp; Who Can Book
                </label>
                <div className="picky-container" style={{ flex: "0 0 70%" }}>
                  <WhoCanBook
                    onChangeWhoCanBook={(val) =>
                      this.handleChange({
                        target: { name: "who_can_book", value: val },
                      })
                    }
                    value={who_can_book}
                  />
                </div>
              </div>
            </div>

            <div
              className="lesson-form-group form-group"
              style={{ marginTop: 0 }}
            >
              <BookPayType
                book_pay_type={book_pay_type}
                onChangeBookPayType={(val) =>
                  this.handleChange({
                    target: { name: "book_pay_type", value: val },
                  })
                }
              />
            </div>
            <div className="d-block mod-item"> 
              <div className="lesson-form-group ">
                <span style={{marginTop:"4px", textAlign:"left"}}>&nbsp;&nbsp;<b> Start:</b> </span>
                <label htmlFor="StartDay">
                  <span style={{marginTop:"4px"}}>&nbsp;&nbsp;&nbsp;&nbsp; <small>On Date</small> &nbsp;&nbsp;</span>
                  <DatePicker
                    dateFormat="MMM D, YYYY"
                    onChange={this.handleChangeStart}
                    onSelect={() => {
                      return false;
                    }}
                   selected={selectedValue}
                   minDate={minSelectDate}
                   maxDate={maxSelectDate}
                    locale="en-gb"
                  />
                </label>
                </div>
            </div>
            <div className="d-block mod-item">
            <div className="micro_repeat form-group">
              <Repeat
                onChangeRepeat={(val) =>
                  this.handleChange({
                    target: { name: "repeatState", value: val },
                  })
                }
                lessonView={true}
                timezone={this.props.timeZone}
                recurringInfo={!repeatState ? recurrence : null}
                currentDay={moment((this.currentDay.clone().diff(this.state.structure[0].time_range.start)<0?this.state.structure[0].time_range.start:this.currentDay.clone()))}
              />
              </div>
            </div>

            <div className="form-group">
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Time</th>
                      <th>Earliest Book in Advance</th>
                      {isShowBuffer&&(
                        <th>Buffer (not bookable days before) </th>
                      )}
                      
                      {allowed_payment_types.includes(book_pay_type) && (
                        <th>Price / hr</th>
                      )}
                      {allowed_payment_point_types.includes(book_pay_type) && (
                        <th>Point / hr</th>
                      )}
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {structure.map((row) => {
                      const start = this.momentWithTimeZone(
                        row.time_range.start
                      );
                      let end = this.momentWithTimeZone(row.time_range.end);
                      const endOfDay = min_date.clone().add(1, "day").startOf("day");

                      return (
                        <tr key={row.id}>
                          <td>
                            <div className="d-flex">
                              <DatePicker
                                className="mr-2 date-picker"
                                dropdownMode="select"
                                selected={start}
                                minTime={min_date}
                                minDate={min_date}
                                maxTime={min_date.clone().add(1380,"minutes")}
                                 maxDate={endOfDay}
                                onChange={(date) =>
                                  this.handleRuleRangeChange(row, "start", date)
                                }
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={timeIntervals}
                                timeCaption="Time"
                                injectTimes={injectTimes}
                                dateFormat={dateFormat}
                                timeFormat={dateFormat}
                              />
                              <DatePicker
                                className="date-picker"
                                dropdownMode="select"
                                selected={end}
                                minTime={start.clone().add(timeStep, "minutes")}
                                minDate={start.clone().add(timeStep, "minutes")}
                                maxTime={endOfDay}
                                 maxDate={endOfDay}
                                // excludeTimes={excludeTimes}
                                onChange={(date) =>
                                  this.handleRuleRangeChange(row, "end", date)
                                }
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={timeIntervals}
                                timeCaption="Time"
                                injectTimes={injectTimes}
                                dateFormat={dateFormat}
                                timeFormat={dateFormat}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="d-flex">
                              <input
                                className="table-item mr-2"
                                style={{ minWidth: "50px" }}
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                min="1"
                                max="365"
                                value={row.book_advance.count}
                                onChange={({ target: { value } }) =>
                                  this.setState((st) => {
                                    if((value<0)|| (value>365)){
                                      return
                                     }
                                    // value = Math.max(value, 1);
                                    // value = Math.min(value, 365);

                                    const index = st.structure.findIndex(
                                      (item) => item.id === row.id
                                    );

                                    const before = st.structure.slice(0, index);
                                    const item = {
                                      ...row,
                                      book_advance: {
                                        ...row.book_advance,
                                        count: value,
                                      },
                                    };

                                    const after = st.structure.slice(index + 1);

                                    return {
                                      ...st,
                                      structure: [...before, item, ...after],
                                    };
                                  })
                                }
                              />
                              <span>days</span>
                            </div>
                          </td>
                          {isShowBuffer&&(
                            <td>
                            <div className="d-flex">
                              <input
                                className="table-item mr-2"
                                style={{ minWidth: "50px" }}
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                min="0"
                                max="365"
                                value={row.buffer}
                                onChange={({ target: { value } }) =>
                                  this.setState((st) => {
                                    

                                    const index = st.structure.findIndex(
                                      (item) => item.id === row.id
                                    );

                                    const before = st.structure.slice(0, index);
                                    const item = {
                                      ...row,
                                      buffer:value,
                                    };

                                    const after = st.structure.slice(index + 1);

                                    return {
                                      ...st,
                                      structure: [...before, item, ...after],
                                    };
                                  })
                                }
                              />
                              
                            </div>
                          </td>
                          )}
                          
                          {allowed_payment_types.includes(book_pay_type) && (
                            <td>
                              <div className="d-flex">
                                <input
                                  className="table-item"
                                  type="number"
                                  onWheel={(e) => e.target.blur()}
                                  min="0"
                                  step="any"
                                  onChange={({ target: { value } }) =>
                                    this.setState((st) => {
                                      const index = st.structure.findIndex(
                                        (item) => item.id === row.id
                                      );

                                      const before = st.structure.slice(
                                        0,
                                        index
                                      );
                                      const item = {
                                        ...row,
                                        price: value,
                                      };
                                      const after = st.structure.slice(
                                        index + 1
                                      );
                                      return {
                                        ...st,
                                        structure: [...before, item, ...after],
                                      };
                                    })
                                  }
                                  value={row.price}
                                />
                              </div>
                            </td>
                          )}
                          {allowed_payment_point_types.includes(
                            book_pay_type
                          ) && (
                              <td>
                                <div className="d-flex">
                                  <input
                                    className="table-item"
                                    type="number"
                                    onWheel={(e) => e.target.blur()}
                                    min="0"
                                    step="any"
                                    onChange={({ target: { value } }) =>
                                      this.setState((st) => {
                                        const index = st.structure.findIndex(
                                          (item) => item.id === row.id
                                        );

                                        const before = st.structure.slice(
                                          0,
                                          index
                                        );
                                        const item = {
                                          ...row,
                                          point_price: value,
                                        };
                                        const after = st.structure.slice(
                                          index + 1
                                        );
                                        return {
                                          ...st,
                                          structure: [...before, item, ...after],
                                        };
                                      })
                                    }
                                    value={row.point_price}
                                  />
                                </div>
                              </td>
                            )}
                          <td>
                            <div className="d-flex justify-center">
                              <button
                                className="btn btn-red rounded"
                                type="button"
                                onClick={() => this.handleDeleteRow(row.id)}
                              >
                                <img
                                  src="/assets/img/cancel-white.png"
                                  width={15}
                                  height={15}
                                  alt="delete"
                                />
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td
                        colSpan={
                          allowed_payment_point_types.includes(book_pay_type)
                            ? 5
                            : 4
                        }
                      >
                        <button
                          className="btn btn-green rounded mx-auto"
                          type="button"
                          onClick={this.handleAddRow}
                        >
                          <img
                            src="/assets/img/plus.png"
                            width={20}
                            height={20}
                            alt="plus"
                          />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="d-flex justify-center"> 
              <div className="sign-up-btn-new">
                <div className='pretty-button'>
                <button className="btn mr-2 red-button" type="button" onClick={onClose}>
                  Cancel
                </button>
                </div>
              </div>
              <div className="sign-up-btn-new">
                <div className='pretty-button'>
                  <button
                  type="submit"
                  className="btn green-button"
                  style={{ padding: "5px 20px" ,marginLeft: "10px"}}
                  disabled={loading}
                >
                  {this.state.editableId ? "Save" : "Create"}
                </button>
                </div>
              </div>
              <div className="sign-up-btn-new">
                <div className='pretty-button'>
                  <button type="button"  onClick={this.handleDuplicate} className="btn green-button" style={{ padding: "5px 20px", marginLeft: "20px" ,display:(!this.state.editableId?"none":"")}}>
                      Duplicate
                  </button>
                </div>
              </div>
              
            </div>

            {loading ? <Loading /> : null}
          </form>
        </div>
      </div>
    );
  }

  getMinDate = () => {
    const { business, timeZone: timezone } = this.props;
    const csh_start_time =
      business && business.csh_start_time
        ? business.csh_start_time.split("__")
        : null;
    const min_date = moment((this.currentDay.clone().diff(this.state.structure[0].time_range.start)<0?this.state.structure[0].time_range.start:this.currentDay.clone())).tz(timezone).startOf("day");
   
    if (csh_start_time) {
      min_date.set("hour", csh_start_time[0]);
      min_date.set("minute", csh_start_time[1]);
    }

    return moment(min_date).seconds(0).millisecond(0) ;
  };

  handleSave = async (e) => {
    e.preventDefault();

    try {
      this.setState((st) => ({ ...st, loading: true }));
      const {
        lesson_type_id,
        sport_id,
        who_can_book,
        book_pay_type,
        repeatState,
        weekDays,
        courts,
        structure,
        note,
      } = this.state;
      const { user } = this.props;
      const { coach_id = user.id } = this.props;

      try {
        if (
          sport_id == -1 ||
          lesson_type_id == -1 ||
          !who_can_book.length ||
          !courts.length
        )
          throw new Error("Fill all required fields");
      } catch {
        return window.modal.alert("Please fill all required fields.");
      }

      const item = {
        courts: courts.map((crt) => crt.id),
        rules: [],
      };

      const existedDates = [];

      const BreakException = {};
     
      try {
        structure.forEach((rule) => {
          const { start, end } = rule.time_range;
         
          if (start.isSame(end) || start.isAfter(end)) {
            throw BreakException;
          }

          end.set("year", start.year());
          end.set("month", start.month());
          end.set("date", start.date());

          const minDate = this.getMinDate();

          minDate.set("year", start.year());
          minDate.set("month", start.month());
          minDate.set("date", start.date());

          const splitRange = getSplitRange(
            start,
            end,
            this.props.timeStep,
            minDate
          );

          /// some if
          splitRange.forEach((selectedDate) => {
            if (
              !!existedDates.find((existedDate) => {
                return selectedDate.isSame(existedDate);
              })
            ) {
              throw BreakException;
            }
          });
          
          existedDates.push(...splitRange);
         
          item.rules.push({
            days: weekDays,
            start_time: this.momentWithTimeZone( moment(rule.time_range.start).seconds(0).milliseconds(0))
              // .clone()
              //.utc()
              .format(),
            end_time: this.momentWithTimeZone((moment(rule.time_range.end).format("HH:mm")=="00:00"?moment(rule.time_range.end).add(1,"day").seconds(0).milliseconds(0):moment(rule.time_range.end).seconds(0).milliseconds(0)) ) 
              //.clone()
              //.utc()
              .format(),
            price: book_pay_type && book_pay_type === "2" ? 0 : +rule.price,
            point_price:
              book_pay_type && book_pay_type === "1"
                ? 0
                : rule.point_price
                  ? parseFloat(rule.point_price)
                  : 0,
            advance: +rule.book_advance.count,
            buffer: +rule.buffer,
            book_pay_type: book_pay_type ? book_pay_type : "1",
          });
          
        });
       
      } catch (e) {
        console.log("error: overlapped: ", e);
        if (e !== BreakException) throw e;
        return window.modal.alert(
          "Please Check Start and End Days/Times"
        );
      }

      let body = who_can_book;
      body = body.map((v) => {
        return {
          ...v,
          ...item,
        };
      });
      body = body.map((v) => {
        return { ...v, filters: {} };
      });

      const defaultRange = item["rules"][0];

      let repeatData;
      if (repeatState) {
        const repeat = repeatState;
        const repeatType = {
          DAYLY: "DAYLY",
          WEEKLY: "WEEKLY",
          BI_WEEKLY: "BI-WEEKLY",
          MONTHLY: "MONTHLY",
        };
        const _start = repeat.starts
          ? repeat.starts.clone().utc().format()
          : defaultRange.start_time;
        const _end = defaultRange.end_time;

        repeatData = {
          frequency:
            repeat.repeatType === repeatType.WEEKLY ||
              repeat.repeatType === repeatType.BI_WEEKLY
              ? "weekly"
              : repeat.repeatType === repeatType.MONTHLY
                ? "monthly"
                : "daily",
          occurrences:
            repeat.endstype === "occurrence"
              ? parseInt(repeat.endCountSelect)
              : 1,
          ends: repeat.repeatOn
            ? repeat.endstype === "dataPicker"
              ? repeat.ends.add(1, "day").startOf("day").utc().format()
              : null
            : null,
          end_time: _end,
          days:
            repeat.repeatType === repeatType.MONTHLY
              ? [this.currentDay.clone().date()]
              : repeat.repeatType === repeatType.WEEKLY ||
                repeat.repeatType === repeatType.BI_WEEKLY
                ? repeat.weekDays.map((item) => item)
                : [],
          start_date: _start,
          start_time: _start,
          every: repeat.repeatType === repeatType.BI_WEEKLY ? 2 : 1,
          recurrence: repeat.repeatOn || false,
          multipleDates: [],
        };
      }

      // if (!item.courts.length) {
      //   return window.modal.alert("Please select courts.");
      // }

      if (
        !repeatData ||
        (repeatData &&
          repeatData.days.length === 0 &&
          repeatData.frequency !== "daily")
      ) {
        const _start = defaultRange.start_time;
        const _end = defaultRange.end_time;
        repeatData = {
          frequency: "daily",
          occurrences: 1,
          ends: null,
          end_time: _end,
          days: [],
          start_date: _start,
          start_time: _start,
          every: 1,
          recurrence: true,
          multipleDates: [],
        };
      }

      let res;
      if (this.state.editableId) {
        let update_data = {
          settings: body,
          note: note,
          sport_id,
          lesson_type_id,
          coach_id,
          is_coach: true,
        };
        
        if (repeatData) {
          update_data = {
            ...update_data,
            recurrence: repeatData,
          };
        }

        res = await this.props.updateCoachAvailability(this.state.editableId, {
          ...update_data,
        });
       
      } else {
        res = await this.props.createCoachAvailability(
          {
            settings: body,
            recurrence: repeatData,
            note: note,
            sport_id: sport_id,
            lesson_type_id: lesson_type_id,
            coach_id: coach_id,
            is_coach: true,
          },
          false
        );
      }
      if(repeatData.recurrence){
        await window.modal.alert(
          "",
          "Processing for the whole recurrence chain. Please wait.",{
            style: {},
            buuton: {},
            timer:3000
          }
        );
       }
      if (res) {
        this.props.onClose();
        window.modal.alert(
          "Success",
          `Your availability successfully ${this.state.editableId ? "updated" : "created"
          }.`
        );
      }
    } finally {
      this.setState((st) => ({ ...st, loading: false }));
    }
  };

  handleChange = (e) => {
    const {
      target: { name, value },
    } = e;

    this.setState((st) => ({ ...st, [name]: value }));
  };

  momentWithTimeZone = (...args) => moment(...args).tz(this.props.timeZone);

  handleRuleRangeChange = (row, property, date) => {
    this.setState((st) => {
      const index = st.structure.findIndex((item) => item.id === row.id);

      const before = st.structure.slice(0, index);
      const item = {
        ...row,
        time_range: {
          ...row.time_range,
          [property]: date,
        },
      };

      const after = st.structure.slice(index + 1);

      const newStructure = [...before, item, ...after];

      return {
        ...st,
        structure: newStructure,
      };
    });
  };

  handleAddRow = () => {
    this.setState((st) => {
      const last = st.structure.last();
      const start = last.time_range.end.clone();
      const end = start.clone().add(this.props.timeStep, "minute");

      const item = {
        id: last.id + 1,
        time_range: {
          start,
          end,
        },
        book_advance: {
          count: 365,
          type: "day",
        },
        buffer:0,
        price: 0,
        point_price: 0,
      };

      const newStructure = [...st.structure, item];

      return {
        ...st,
        structure: newStructure,
      };
    });
  };

  handleDeleteRow = (id) => {
    this.setState((st) => {
      if (st.structure.length === 1) {
        return st;
      }

      const index = st.structure.findIndex((item) => item.id === id);

      const before = st.structure.slice(0, index);
      const after = st.structure.slice(index + 1);

      return {
        ...st,
        structure: [...before, ...after],
      };
    });
  };
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    micro_settings: state.micro_settings,
    business: state.auth.user.business,
    timeZone: state.auth.user.business.timezone,
    timeStep: state.auth.user.business.booking_to_start,
    minDuration: state.auth.user.business.min_list_duration,
    courts: state.courts.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetMicroSetting: () => dispatch(resetMicroSetting()),
    createCoachAvailability: (data, update = false, options = {}) =>
      dispatch(createCoachAvailability(data, update, options)),

    getMicroSettings: (id, options = {}) =>
      dispatch(getMicroSettings(id, options)),
    updateCoachAvailability: (id, data, options = {}) =>
      dispatch(updateCoachAvailability(id, data, options)),

    getCourts: () => dispatch(getCourts()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateAvailability);
