import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormGroup from '../FormGroup';
import ColorPicker from '../UI/ColorPicker';
import { AddTag, UpdateTag, GetTag } from '../../actions/Tag';
import { connect } from 'react-redux';

class TagsForm extends Component {
  constructor(props) {
    super(props);

    const { data = {} } = props;

    this.state = {
      display_name: '',
      color: '#28e61a',
      ...data,
    };
  }

  render() {
    const { display_name, color } = this.state;
    const {
      tag: { loading },
    } = this.props;

    return (
      <div className="auth-container tag-form">
        <div className="flex-container justify-content-start">
        <div className="form-group ">
          <FormGroup
            type="text"
            name="display_name"
            label="*Tag name"
            onChange={this.handleChange}
            value={display_name}
            maxlength="30"
          />
          <span className="counter_cutum" style={{marginTop:"-8px",marginRight:"12px"}}>
              {display_name ?display_name.length: 0} / {display_name.length>0 ?30- display_name.length:30}
          </span> 
          </div>
          <div className="form-group colorPicker">
            <ColorPicker
              color={color}
              handleChangeColor={v =>
                this.handleChange({
                  target: {
                    name: 'color',
                    value: v.hex,
                  },
                })
              }
            />
          </div>
        </div>

        <div className="flex-container pretty-button m-top-15 justify-content-center">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <>
              <div className="pretty-button">
                <button className="red-button" type="button"  onClick={this.handleCancel} >
                  Cancel
                </button>
              </div>
              <div className="pretty-button" style={{marginLeft:"5px"}}>
                    <button type="submit" className="green-button" style={{ padding: '5px 25px' }}  onClick={this.handleSave}>
                      Save
                    </button>
                    </div>
            </>
          )}
        </div>
      </div>
    );
  }

  handleCancel = () => {
    if (typeof this.props.onCancel === 'function') {
      this.props.onCancel();
    } else {
      this.setState({
        display_name: '',
        color: '#28e61a',
      });
    }
  };

  handleSave = async () => {
    const data = {
      display_name: this.state.display_name,
      color: this.state.color,
    };

    try {
      const res = await (this.state.id ? this.props.updateTag(this.state.id, data) : this.props.createTag(data));

      if (res) {
        return this.handleCancel();
      }
    } catch {}
    alert('Please check entered data.');
  };

  handleChange = ({ target: { name, value } }) => {
    if(name=="display_name" &&value.length>30){
      return
    }
    this.setState({ [name]: value });
  };
}

TagsForm.propTypes = {
  onCancel: PropTypes.func,
  data: PropTypes.shape({}),
};

const mapStateToProps = state => {
  return {
    tag: state.tag,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getTag: id => dispatch(GetTag(id)),
    updateTag: (id, data) => dispatch(UpdateTag(id, data)),
    createTag: (id, data) => dispatch(AddTag(id, data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TagsForm);
