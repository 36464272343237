import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { SignIn } from "../../actions/Auth";
import FormGroup from "../../components/FormGroup";
import Notification from "../../components/Notification";

const Login = ({ onChangeModalTarget, referer }) => {
  const [{ userName, password }, setState] = useState({
    userName: "",
    password: "",
  });
  const { loading } = useSelector((state) => ({
    loading: state.auth.loading,
  }));
  const dispatch = useDispatch();
  const history = useHistory();

  const HandleChange = useCallback(
    (e) => {
      const { name, value } = e.target;

      let cleanValue = value || "";
      if (name === "userName") {
        cleanValue = String(value).toLowerCase().trim();
      }
      if(name === "password"){
        cleanValue = value.trim()
      }
      if(name === "password"){
        cleanValue = value.trim()
      }
      setState((st) => ({ ...st, [name]: cleanValue }));
    },
    [setState]
  );

  const HandleSubmit = useCallback(
    (e) => {
      const role = "bu";

      dispatch(
        SignIn(
          {
            userName,
            password,
            role,
          },
          history,
          referer
        )
      );
    },
    [dispatch, history, password, userName, referer]
  );

  return (
    <div>
      <FormGroup
        type="text"
        name="userName"
        label="Username"
        onChange={HandleChange}
        value={userName}
      >
        <Notification>
          Username must be minimum 2 max 22 characters, only small latin
          letters, numbers, period symbol (.) and underscore symbol (_) allowed
        </Notification>
      </FormGroup>
      <FormGroup
        type="password"
        name="password"
        label="Password"
        onKeyDown={(e) => {
          if (e.keyCode === 13) {
            HandleSubmit();
          }
        }}
        onChange={HandleChange}
        value={password}
      >
        <Notification>
          Password: minimum 8 max 25 characters, at least one letter and at
          least one number.
        </Notification>
      </FormGroup>
      <div className="signin">
        <button disabled={loading} onClick={HandleSubmit}>
          Log In
        </button>
      </div>
      <div className="forgot-password">
        <button onClick={() => onChangeModalTarget("forgot")} type="submit">
          Forgot Your Password?
        </button>
      </div>
    </div>
  );
};

export default Login;
