export const GLOBAL_LOCATION_FACILITY_PAIR = "GLOBAL_LOCATION_FACILITY_PAIR";

export const PRICING_PLANS = [
  {
    name: "Ace",
    price: "239",
    discount: "199",
    features: [
      "Advantage plan PLUS",
      "Up to 12 bookable courts/bays/lanes",
      "Full access to all modules & features (see listed below)",
    ],
    plan: "courtandlessonproviderplan",
  },
  {
    name: "Grand Slam",
    price: "349",
    discount: "299",
    features: ["Ace plan PLUS", "Unlimited bookable courts/bays/lanes","Unlimited marketing emails"],
    plan: "enterprisecourtandlessonproviderplan",
  },
];
