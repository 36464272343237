import React, { Component } from "react";

import RefundPaymentBtn from "../../components/Buttons/RefundPaymentBtn";
import RefundOrderPaymentBtn from "../../components/Buttons/RefundOrderPaymentBtn";
import { connect } from "react-redux";
import moment from "moment-timezone";
import ReceiptModal from "../ReceiptModal";

class HistoryItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openedModal: null,
    };
  }

  handleOpenModal = () => {
    const {
      amount: amount1,
      source = {},
      payment_method_details = {},
      note,
      receipt,
      refunded,
      id,
      paymentId,
      item,
      timezone,
    } = this.props;
    const total = item.total ? item.total : null;
    const amount = total ? total : parseFloat(amount1).toFixed(2);
    const name = item.metadata.full_name ? item.metadata.full_name : "N/A";
    const currency_code = item.currency ? item.currency.toUpperCase() : "";

    this.setState((st) => ({
      ...st,
      openedModal: (
        <ReceiptModal
          data={{
            receiptUrl: receipt,
            name,
            id,
            amount,
            currency: currency_code,
            chargeDate: moment(item.createdAt)
              .tz(timezone)
              .format("ddd, MMM D, YYYY h:mma"),
          }}
          onClose={this.handleCloseModal}
        />
      ),
    }));
  };

  handleCloseModal = () => {
    this.setState((st) => ({ ...st, openedModal: null }));
  };

  render() {
    const {
      amount: amount1,
      source = {},
      payment_method_details = {},
      note,
      receipt,
      refunded,
      id,
      paymentId,
      item,
      timezone,
      permission,
    } = this.props;
    const total = item.total ? item.total : null;
    const amount = total ? total : parseFloat(amount1).toFixed(2);
    const types = {
      cash: "Cash",
      "check-input": "Check input",
    };
    const amount_refunded = item.amount_refunded
      ? parseFloat(item.amount_refunded).toFixed(2)
      : null;

    const name = item.metadata.full_name ? item.metadata.full_name : "N/A";
    const object = source ? source.object : "";
    const refund_for = "";
    const currency_code = item.currency ? item.currency.toUpperCase() : "";
    const cash_input_type = item.cash_input_type
      ? types[item.cash_input_type]
      : null;
    const point = item.point === "1" ? "Point" : null;
    const show_receipt = !cash_input_type && !point;

    let maxAmountForRefund = amount_refunded
      ? parseFloat(amount - amount_refunded).toFixed(2)
      : amount;

    let renderContent = null;

    switch (object) {
      case "bank_account":
        renderContent = (
          <li>
            <ul>
              <li>
                <span>Account number ending:</span> {source.last4}
              </li>
              <li>
                <span>Name:</span> {name}
              </li>
              <li>
                <span>Amount:</span> {total ? total : amount}
                {currency_code}
                {amount_refunded > 0 && (
                  <span style={{ color: "red" }}>
                    Refunded {amount_refunded}
                    {currency_code}{" "}
                  </span>
                )}
                {show_receipt && (
                  <a
                    style={{ color: "#9ad308" }}
                    href="#"
                    onClick={this.handleOpenModal}
                  >
                    Receipt
                  </a>
                )}
              </li>
              <li style={{ width: "100%" }}>
                <span>Note:</span> {note || "N/A"}
              </li>
              {cash_input_type && (
                <li>
                  <span>Type:</span> {cash_input_type}
                </li>
              )}
              {point && (
                <li>
                  <span>Type:</span> {point}
                </li>
              )}
              {maxAmountForRefund > 0 && (
                <li>
                  <RefundPaymentBtn
                    permission={permission}
                    apt_id={this.props.apt_id}
                    refund_for={refund_for}
                    item={item}
                    is_tax={item.tax ? item.tax : false}
                    tax_percent={item.tax_percent}
                    payment_id={item.charge_id}
                    amount={amount ? maxAmountForRefund : null}
                  />
                </li>
              )}
              <span
                style={{
                  borderBottom: "1px solid black",
                  display: "block",
                  width: "100%",
                }}
              ></span>
            </ul>
          </li>
        );
        break;
      case "card":
        renderContent = (
          <li>
            <ul>
              <li>
                <span>Card number ending:</span> {source.last4}
              </li>
              <li>
                <span>Name:</span> {name}
              </li>
              <li>
                <span>Amount:</span> {total ? total : amount}
                {currency_code}
              </li>
              {amount_refunded > 0 && (
                <li>
                  <span style={{ color: "red" }}>
                    Refunded {amount_refunded}
                    {currency_code}
                  </span>
                </li>
              )}
              <li>
                <a
                  style={{ color: "#9ad308" }}
                  href="#"
                  onClick={this.handleOpenModal}
                >
                  Receipt
                </a>
              </li>

              <li style={{ width: "100%" }}>
                <span>Note:</span> {note || "N/A"}
              </li>
              <li style={{ width: "100%" }}>
                <span>Charge date:</span>{" "}
                {moment(item.createdAt)
                  .tz(timezone)
                  .format("ddd, MMM D, YYYY h:mma")}
              </li>
              {item.facility_groups && item.facility_groups.length > 0 && (
                <li style={{ color: "#ff605c " }}>
                  Shared between business facilities
                </li>
              )}
              {maxAmountForRefund > 0 && !item.order_id && (
                <li>
                  <RefundPaymentBtn
                    permission={permission}
                    apt_id={this.props.apt_id}
                    item={item}
                    refund_for={refund_for}
                    is_tax={item.tax ? item.tax : false}
                    tax_percent={item.tax_percent}
                    params={this.props.params}
                    payment_id={item.charge_id}
                    amount={amount ? maxAmountForRefund : null}
                  />
                </li>
              )}
              {maxAmountForRefund > 0 && item.order_id && (
                <li className="refund-li">
                  <RefundOrderPaymentBtn
                    item={item}
                    from={"payment"}
                    permission={permission}
                    params={this.props.params}
                  />
                </li>
              )}
              <span
                style={{
                  borderBottom: "1px solid black",
                  display: "block",
                  width: "100%",
                }}
              ></span>
            </ul>
          </li>
        );

        break;
      case "source":
        renderContent = (
          <li>
            <ul>
              <li>
                <span>Card number ending:</span> {source.card_present.last4}
              </li>
              <li>
                <span>Name:</span> {name}
              </li>
              <li>
                <span>Amount:</span> {total ? total : amount}
                {currency_code}
                {amount_refunded > 0 && (
                  <span style={{ color: "red" }}>
                    Refunded {amount_refunded} {currency_code}{" "}
                  </span>
                )}
                <a
                  style={{ color: "#9ad308" }}
                  href="#"
                  onClick={this.handleOpenModal}
                >
                  Receipt
                </a>
              </li>
              <li style={{ width: "100%" }}>
                <span>Note:</span> {note || "N/A"}
              </li>
              <li style={{ width: "100%" }}>
                <span>Charge date:</span>{" "}
                {moment(item.createdAt)
                  .tz(timezone)
                  .format("ddd, MMM D, YYYY h:mma")}
              </li>
              {item.facility_groups && item.facility_groups.length > 0 && (
                <li style={{ color: "#ff605c " }}>
                  Shared between business facilities
                </li>
              )}
              {maxAmountForRefund > 0 && !item.order_id && (
                <li>
                  <RefundPaymentBtn
                    permission={permission}
                    apt_id={this.props.apt_id}
                    refund_for={refund_for}
                    item={item}
                    is_tax={item.tax ? item.tax : false}
                    tax_percent={item.tax_percent}
                    payment_id={item.charge_id}
                    amount={amount ? maxAmountForRefund : null}
                  />
                </li>
              )}
              {maxAmountForRefund > 0 && item.order_id && (
                <li className="refund-li">
                  <RefundOrderPaymentBtn
                    permission={permission}
                    item={item}
                    from={"payment"}
                    params={this.props.params}
                  />
                </li>
              )}
              <span
                style={{
                  borderBottom: "1px solid black",
                  display: "block",
                  width: "100%",
                }}
              ></span>
            </ul>
          </li>
        );

        break;
      default:
        renderContent = (
          <li>
            {
              <ul>
                <li>
                  <span>Card number ending:</span>{" "}
                  {source && source.last4 ? source.last4 : "N/A"}
                </li>
                <li>
                  <span>Name:</span> {name}
                </li>
                <li>
                  <span>Amount:</span> {total ? total : amount}
                  {currency_code}
                </li>
                {amount_refunded > 0 && (
                  <li>
                    <span style={{ color: "red" }}>
                      Refunded {amount_refunded}
                      {currency_code}
                    </span>
                  </li>
                )}
                {show_receipt && (
                  <li>
                    <a
                      style={{ color: "#9ad308" }}
                      href="#"
                      onClick={this.handleOpenModal}
                    >
                      Receipt
                    </a>
                  </li>
                )}
                <li style={{ width: "100%" }}>
                  <span>Note:</span> {note || "N/A"}
                </li>
                <li style={{ width: "100%" }}>
                  <span>Charge date:</span>{" "}
                  {moment(item.createdAt)
                    .tz(timezone)
                    .format("ddd, MMM D, YYYY h:mma")}
                </li>

                {cash_input_type && (
                  <li>
                    <span>Type:</span> {cash_input_type}
                  </li>
                )}
                {point && (
                  <li>
                    <span>Type:</span> {point}
                  </li>
                )}
                {item.facility_groups && item.facility_groups.length > 0 && (
                  <li style={{ color: "#ff605c " }}>
                    Shared between business facilities
                  </li>
                )}
                {maxAmountForRefund > 0 && !item.order_id && (
                  <li>
                    <RefundPaymentBtn
                      permission={permission}
                      apt_id={this.props.apt_id}
                      refund_for={refund_for}
                      item={item}
                      is_tax={item.tax ? item.tax : false}
                      tax_percent={item.tax_percent}
                      payment_id={item.charge_id}
                      amount={amount ? maxAmountForRefund : null}
                    />
                  </li>
                )}
                {maxAmountForRefund > 0 && item.order_id && (
                  <li className="refund-li">
                    <RefundOrderPaymentBtn
                      permission={permission}
                      item={item}
                      from={"payment"}
                      params={this.props.params}
                    />
                  </li>
                )}
                <span
                  style={{
                    borderBottom: "1px solid black",
                    display: "block",
                    width: "100%",
                  }}
                ></span>
              </ul>
            }
          </li>
        );

        break;
    }

    return (
      <>
        {renderContent}
        {this.state.openedModal}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    timezone: state.auth.user.business.timezone,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(HistoryItem);
