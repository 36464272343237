import createAction from "./create-action";
import axios from "../config/axios";
import * as actionTypes from "../constants/Facility";

// sport part
export const getSportList = (params) => async (dispatch) => {
  dispatch(createAction(actionTypes.FACILITY_SPORT_LIST_LOADING, true));

  try {
    const {
      data: { payload },
    } = await axios.get("facility/sport", { params });
    dispatch(createAction(actionTypes.FACILITY_SPORT_LIST_SUCCESS, payload));
    return true;
  } catch ({ response: { data } }) {
    dispatch(createAction(actionTypes.FACILITY_SPORT_LIST_ERROR, data.message));
  } finally {
    dispatch(createAction(actionTypes.FACILITY_SPORT_LIST_LOADING, false));
  }

  return false;
};

export const getSport = (id, params) => async (dispatch) => {
  dispatch(createAction(actionTypes.FACILITY_SPORT_CURRENT_LOADING, true));

  try {
    const {
      data: { payload },
    } = await axios.get(`facility/sport/${id}`, { params });
    dispatch(createAction(actionTypes.FACILITY_SPORT_CURRENT_SUCCESS, payload));
    return true;
  } catch ({ response: { data } }) {
    dispatch(
      createAction(actionTypes.FACILITY_SPORT_CURRENT_ERROR, data.message)
    );
  } finally {
    dispatch(createAction(actionTypes.FACILITY_SPORT_CURRENT_LOADING, false));
  }

  return false;
};

export const createSport = (data) => async (dispatch) => {
  dispatch(createAction(actionTypes.FACILITY_SPORT_CURRENT_LOADING, true));

  try {
    const {
      data: { payload },
    } = await axios.post("facility/sport", data);
    dispatch(createAction(actionTypes.FACILITY_SPORT_CURRENT_SUCCESS, payload));
    return true;
  } catch ({ response: { data } }) {
    dispatch(
      createAction(actionTypes.FACILITY_SPORT_CURRENT_ERROR, data.message)
    );
  } finally {
    dispatch(createAction(actionTypes.FACILITY_SPORT_CURRENT_LOADING, false));
  }

  return false;
};

export const updateSport = (id, data) => async (dispatch) => {
  dispatch(createAction(actionTypes.FACILITY_SPORT_CURRENT_LOADING, true));

  try {
    const {
      data: { payload },
    } = await axios.put(`facility/sport/${id}`, data);
    dispatch(createAction(actionTypes.FACILITY_SPORT_CURRENT_SUCCESS, payload));
    return true;
  } catch ({ response: { data } }) {
    dispatch(
      createAction(actionTypes.FACILITY_SPORT_CURRENT_ERROR, data.message)
    );
  } finally {
    dispatch(createAction(actionTypes.FACILITY_SPORT_CURRENT_LOADING, false));
  }

  return false;
};

export const deleteSport = (id) => async (dispatch) => {
  dispatch(createAction(actionTypes.FACILITY_SPORT_CURRENT_LOADING, true));

  try {
    const {
      data: { payload },
    } = await axios.delete(`facility/sport/${id}`);
    dispatch(createAction(actionTypes.FACILITY_SPORT_CURRENT_SUCCESS, payload));
    return true;
  } catch ({ response: { data } }) {
    dispatch(
      createAction(actionTypes.FACILITY_SPORT_CURRENT_ERROR, data.message)
    );
  } finally {
    dispatch(createAction(actionTypes.FACILITY_SPORT_CURRENT_LOADING, false));
  }

  return false;
};

export const setSportError = (message) => (dispatch) =>
  dispatch(createAction(actionTypes.FACILITY_SPORT_CURRENT_ERROR, message));

// sport_category part
export const getSportCategoryList = (params) => async (dispatch) => {
  dispatch(
    createAction(actionTypes.FACILITY_SPORT_CATEGORY_LIST_LOADING, true)
  );

  try {
    // facility/sport_category
    const {
      data: { payload },
    } = await axios.get("out/sport_category", { params });
    dispatch(
      createAction(actionTypes.FACILITY_SPORT_CATEGORY_LIST_SUCCESS, payload)
    );
    return true;
  } catch ({ response: { data } }) {
    dispatch(
      createAction(actionTypes.FACILITY_SPORT_CATEGORY_LIST_ERROR, data.message)
    );
  } finally {
    dispatch(
      createAction(actionTypes.FACILITY_SPORT_CATEGORY_LIST_LOADING, false)
    );
  }

  return false;
};

export const getSportCategory = (id, params) => async (dispatch) => {
  dispatch(
    createAction(actionTypes.FACILITY_SPORT_CATEGORY_CURRENT_LOADING, true)
  );

  try {
    const {
      data: { payload },
    } = await axios.get(`facility/sport_category/${id}`, { params });
    dispatch(
      createAction(actionTypes.FACILITY_SPORT_CATEGORY_CURRENT_SUCCESS, payload)
    );
    return true;
  } catch ({ response: { data } }) {
    dispatch(
      createAction(
        actionTypes.FACILITY_SPORT_CATEGORY_CURRENT_ERROR,
        data.message
      )
    );
  } finally {
    dispatch(
      createAction(actionTypes.FACILITY_SPORT_CATEGORY_CURRENT_LOADING, false)
    );
  }

  return false;
};

export const createSportCategory = (data) => async (dispatch) => {
  dispatch(
    createAction(actionTypes.FACILITY_SPORT_CATEGORY_CURRENT_LOADING, true)
  );

  try {
    const {
      data: { payload },
    } = await axios.post("facility/sport_category", data);
    dispatch(
      createAction(actionTypes.FACILITY_SPORT_CATEGORY_CURRENT_SUCCESS, payload)
    );
    return true;
  } catch ({ response: { data } }) {
    dispatch(
      createAction(
        actionTypes.FACILITY_SPORT_CATEGORY_CURRENT_ERROR,
        data.message
      )
    );
  } finally {
    dispatch(
      createAction(actionTypes.FACILITY_SPORT_CATEGORY_CURRENT_LOADING, false)
    );
  }

  return false;
};

export const updateSportCategory = (id, data) => async (dispatch) => {
  dispatch(
    createAction(actionTypes.FACILITY_SPORT_CATEGORY_CURRENT_LOADING, true)
  );

  try {
    const {
      data: { payload },
    } = await axios.put(`facility/sport_category/${id}`, data);
    dispatch(
      createAction(actionTypes.FACILITY_SPORT_CATEGORY_CURRENT_SUCCESS, payload)
    );
    return true;
  } catch ({ response: { data } }) {
    dispatch(
      createAction(
        actionTypes.FACILITY_SPORT_CATEGORY_CURRENT_ERROR,
        data.message
      )
    );
  } finally {
    dispatch(
      createAction(actionTypes.FACILITY_SPORT_CATEGORY_CURRENT_LOADING, false)
    );
  }

  return false;
};

export const deleteSportCategory = (id) => async (dispatch) => {
  dispatch(
    createAction(actionTypes.FACILITY_SPORT_CATEGORY_CURRENT_LOADING, true)
  );

  try {
    const {
      data: { payload },
    } = await axios.delete(`facility/sport_category/${id}`);
    dispatch(
      createAction(actionTypes.FACILITY_SPORT_CATEGORY_CURRENT_SUCCESS, payload)
    );
    return true;
  } catch ({ response: { data } }) {
    dispatch(
      createAction(
        actionTypes.FACILITY_SPORT_CATEGORY_CURRENT_ERROR,
        data.message
      )
    );
  } finally {
    dispatch(
      createAction(actionTypes.FACILITY_SPORT_CATEGORY_CURRENT_LOADING, false)
    );
  }

  return false;
};

export const setSportCategoryError = (message) => (dispatch) =>
  dispatch(
    createAction(actionTypes.FACILITY_SPORT_CATEGORY_CURRENT_ERROR, message)
  );

// group part
export const getGroupList = (params) => async (dispatch) => {
  dispatch(createAction(actionTypes.FACILITY_GROUP_LIST_LOADING, true));

  try {
    const {
      data: {
        payload
      },
    } = await axios.get("facility/group-staff", {
      params
    });
    dispatch(createAction(actionTypes.FACILITY_GROUP_LIST_SUCCESS, payload));
    return true;
  } catch ({ response: { data } }) {
    
    dispatch(createAction(actionTypes.FACILITY_GROUP_LIST_ERROR, data.message));
  } finally {
    dispatch(createAction(actionTypes.FACILITY_GROUP_LIST_LOADING, false));
  }

  return false;
};

export const getGroup = (id, params) => async (dispatch) => {
  dispatch(createAction(actionTypes.FACILITY_GROUP_CURRENT_LOADING, true));

  try {
    const ress = await axios.get(`facility/group/${id}`, { params });
    
    dispatch(createAction(actionTypes.FACILITY_GROUP_CURRENT_SUCCESS, ress.data.payload?ress.data.payload:{}));
    return true;
  } catch ({ response: { data } }) {
    
    dispatch(
      createAction(actionTypes.FACILITY_GROUP_CURRENT_ERROR, data.message)
    );
  } finally {
    dispatch(createAction(actionTypes.FACILITY_GROUP_CURRENT_LOADING, false));
  }

  return false;
};

export const createGroup = (data) => async (dispatch) => {
  dispatch(createAction(actionTypes.FACILITY_GROUP_CURRENT_LOADING, true));

  try {
    const {
      data: { payload },
    } = await axios.post("facility/group", data);
    dispatch(createAction(actionTypes.FACILITY_GROUP_CURRENT_SUCCESS, payload));
    return true;
  } catch ({ response: { data } }) {
    dispatch(
      createAction(actionTypes.FACILITY_GROUP_CURRENT_ERROR, data.message)
    );
  } finally {
    dispatch(createAction(actionTypes.FACILITY_GROUP_CURRENT_LOADING, false));
  }

  return false;
};

export const updateGroup = (id, data) => async (dispatch) => {
  
  dispatch(createAction(actionTypes.FACILITY_GROUP_CURRENT_LOADING, true));

  try {
    const {
      data: { payload },
    } = await axios.put(`facility/group/${id}`, data);
   
    dispatch(createAction(actionTypes.FACILITY_GROUP_CURRENT_SUCCESS, payload));
    window.location.reload()
    return true;
  } catch ({ response: { data } }) {
    dispatch(
      createAction(actionTypes.FACILITY_GROUP_CURRENT_ERROR, data.message)
    );
  } finally {
    dispatch(createAction(actionTypes.FACILITY_GROUP_CURRENT_LOADING, false));
  }

  return false;
};

export const updateGroupsOrdering = (data) => async (dispatch) => {
  try {
    await axios.post(`facility/group/order`, data);
    return true;
  } catch (e) {
    console.log("error: ", e);
  } finally {
  }

  return false;
};

export const deleteGroup = (id) => async (dispatch) => {
  dispatch(createAction(actionTypes.FACILITY_GROUP_CURRENT_LOADING, true));

  try {
    const {
      data: { payload },
    } = await axios.delete(`facility/group/${id}`);
    dispatch(createAction(actionTypes.FACILITY_GROUP_CURRENT_SUCCESS, payload));
    return true;
  } catch ({ response: { data } }) {
    dispatch(
      createAction(actionTypes.FACILITY_GROUP_CURRENT_ERROR, data.message)
    );
  } finally {
    dispatch(createAction(actionTypes.FACILITY_GROUP_CURRENT_LOADING, false));
  }

  return false;
};

export const setGroupError = (message) => (dispatch) =>
  dispatch(createAction(actionTypes.FACILITY_GROUP_CURRENT_ERROR, message));
