import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
class BlueSection extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <MetaTags>
          <title>Find Tennis Partners In Your Area | Ballbutton Tennis App</title>
          <meta name="description" content="Find new tennis players in your area with the Ballbutton app. Schedule tennis matches with friends. Book tennis courts and lessons in a sleek, modern interface. " />
        </MetaTags>
        <section className="blue-section" id="tennis-court">
          <div className="main-container">
            <div className="blue-content">
              <div className="blue-text">
                <h3>Your Members/Customers Will Have The Most Modern And Full-Featured Interaction With Your Business. They can:</h3>
                <p>- See their profile</p>
                <p>- Track their own activity</p>
                <p>- Book tennis courts</p>
                <p>- Book tennis lessons</p>
                <p>- Find partners to play tennis among your members/customers And more…</p>
              </div>
              <div className="blue-img">
                <img src="/assets/img/iphone-3d.png" alt="Iphone X" />
              </div>
              <div className="blue-img">
                <img src="/assets/img/iphone-3a.png" alt="Iphone X" />
              </div>
              <div className="blue-img">
                <img src="/assets/img/iphone-3b.png" alt="Iphone X" />
              </div>
              <div className="blue-img">
                <img src="/assets/img/iphone-3c.png" alt="Iphone X" />
              </div>
            </div>
            <div className="download-app">
              <div className="download-logo">
                <a href="https://itunes.apple.com/us/app/ballbutton/id1336693461?mt=8">
                  <img src="/assets/img/logo1.png" alt="ballbutton" />
                </a>
                <p>
                  Ballbutton:
                <br />
                  For tennis players and fans.
              </p>
              </div>

              {/*<div>

                <h3>Download the app</h3>
                <ul>
                  <li>
                    <a href="https://itunes.apple.com/us/app/ballbutton/id1336693461?mt=8">
                      <img src="/assets/img/app-store.png" alt="App Store" />
                    </a>
                  </li>
                </ul>

              </div>*/}

            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

BlueSection.propTypes = {};

export default BlueSection;
