import React, { Component } from "react";
import { fetchConnectionToken } from "../../actions/util";
import TerminalModal from "../TerminalModal";
import RegisterReader from "../AddTerminalModal";
import ModalCashInput from "../ModalCashInput";

class TerminalList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      terminal: null,
      discoverResult: [],
      modal: null,
      selectedIndex: -1,
    };
    //this.connectReader = this.connectReader.bind(this);
  }

  async componentDidMount() {
    setTimeout(() => {
      this.getReaders();
    }, 1500);
  }

  unexpectedDisconnect() {
    console.log("Disconnected from reader");
  }

  getReaders = async () => {
    if (window.StripeTerminal) {
      const terminal = await window.StripeTerminal.create({
        onFetchConnectionToken: fetchConnectionToken,
        onUnexpectedReaderDisconnect: this.unexpectedDisconnect,
      });
      const discoverResult = await this.discoverReaders(terminal);
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({ terminal, discoverResult });
    } else {
      document
        .querySelector("#stripeTerminal-js")
        .addEventListener("load", async () => {
          const terminal = await window.StripeTerminal.create({
            onFetchConnectionToken: fetchConnectionToken,
            onUnexpectedReaderDisconnect: this.unexpectedDisconnect,
          });
          const discoverResult = await this.discoverReaders(terminal);
          this.setState({ terminal, discoverResult });
        });
    }
  };

  async discoverReaders(terminal) {
    try {
      const discoverResult = await terminal.discoverReaders();
      if (discoverResult.error) {
        console.log("Failed to discover: ", discoverResult.error);
        throw new Error("Failed to discover: ", discoverResult.error);
      } else if (discoverResult.discoveredReaders.length === 0) {
        console.log("No available readers.");
        throw new Error("No available readers.");
      } else {
        return discoverResult.discoveredReaders;
      }
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  closeModal = () => {
    try {
      this.setState({ modal: null });
      this.state.terminal.disconnectReader();
    } catch (error) {
      console.log(error);
    }
  };

  connectReaderByIndex(index) {
    return async () => {
      // Just select the first reader here.
      this.setState({ selectedIndex: index });
      const selectedReader = this.state.discoverResult[index];
      const connectResult = await this.state.terminal.connectReader(
        selectedReader
      );
      if (connectResult.error) {
        console.log("Failed to connect:", connectResult.error);
      } else {
        this.setState({
          modal: (
            <TerminalModal
              terminal={this.state.terminal}
              reader={selectedReader}
              show_members={true}
              connection={connectResult}
              clouseModal={() => this.closeModal(index)}
            />
          ),
          selectedIndex: -1,
        });
        console.log("Connected to reader:", connectResult);
      }
    };
  }

  openRegisterReaderModal() {
    return () => {
      this.setState({
        modal: (
          <RegisterReader
            getReaders={this.getReaders}
            clouseModal={this.closeModal}
          />
        ),
      });
    };
  }

  addCash = (each) => {
    const { type, id } = this.props;
    this.setState({
      modal: (
        <ModalCashInput
          charge_for={"lesson"}
          user={null}
          appointment_id={null}
          closeModal={this.closeModal}
        />
      ),
    });
  };

  render() {
    const { modal } = this.state;
    return (
      <div>
        <ul>
          {this.state.discoverResult.map((item, index) => (
            <li
              className={`terminal-list-item ${
                index === this.state.selectedIndex ? "loading" : ""
              }`}
              onClick={this.connectReaderByIndex(index)}
              key={item.id}
            >
              {item.status + " " + item.label}
            </li>
          ))}
        </ul>
        <div className="sign-up-btn-new" style={{margin:"auto"}}>
          <div className="pretty-button ">
            <button
            className="green-button"
            onClick={this.openRegisterReaderModal()}
          >
            Add Reader
          </button>
          </div>
        </div>
        

        {/* <button className="addReaderClass yellow-bg m-left-15" onClick={() => this.addCash(null)}>
          Cash input
        </button>*/}
        {modal}
      </div>
    );
  }
}

TerminalList.propTypes = {};

export default TerminalList;
