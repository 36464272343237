import React, { Component } from 'react';
import Calendly from '../../components/Calendly';
import RequestDemo from '../Forms/RequestDemo';
import './request-demo-calendly.scss';


class RequestDemoCalendly extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpenedCalendly: null,
    };

  }





  openCalModal = () => {
    this.setState({
      modalOpenedCalendly: (<Calendly opened={true} close={this.closeModal} />),
    });
  }

  closeModal = () => {
    this.setState({
      modalOpenedCalendly: null,
    });
  }

  closeModal(e) {
    if (e.target.id === 'reqModalBack') {
      this.props.close();
    }
  }


  detectSent = (bool) => {
    if (bool) {
      this.openCalModal();
    }

  }

  render() {
    const { modalOpenedCalendly } = this.state;
    const { showBorder } = this.props
    return (
      <React.Fragment>
        <div className="schedule-demo-container">
          <div className="title">
            <h2> Schedule Private Demo</h2>
          </div>
          <RequestDemo detectSent={this.detectSent} />
          {showBorder && (<div className="green-border"></div>)}
        </div>
        {modalOpenedCalendly}
      </React.Fragment>
    );
  }
}


export default RequestDemoCalendly;
