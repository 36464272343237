import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CleareErrors } from '../../actions/Auth';
import Login from './Login';
import Forgot from './Forgot';
import Register from './Register';
import { Route } from "react-router-dom";
import NotFound from "../../components/NotFound";

class Auth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: '',
      password: '',
      WorkspaceId: '',
      err: false,
      modalTarget: props.modalTarget || 'login', // forgot, register
    };
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.error.message!="Your account is on pause. Contact your account manager at Ballbutton to resume."){
      this.setState({
        err: !!nextProps.error.message,
      });
    }
    
  }

  componentWillUnmount() {
    this.props.CleareErrors();
  }

  render() {
    const { err, modalTarget } = this.state;
    const { error, opened, close } = this.props;
    
    return (
      <div className={`modal auth-modal ${opened ? 'active' : ''}`} onClick={this.closeModal} id="modalBack">
        <div className="modal-content">
          <div className="auth-container">
            <div className="close-auth-modal">
              <button
                onClick={() => {
                  close();
                  this.changeModalTarget('login');
                }}>
                <img src="/assets/img/cancel-white.png" alt="cancel" />
              </button>
            </div>
            <div className={`error-message ${err ? 'active' : ''}`}>
              <span onClick={this.closeError}>
                <img src="/assets/img/cancel.png" alt="cancel" />
              </span>
              <p>{error.message}</p>
            </div>
            {this.getTargetComponent(modalTarget)}
          </div>
        </div>
      </div>
    );
  }

  closeModal = e => {
    if (e.target.id === 'modalBack') {
      this.props.close();
      this.setState({
        modalTarget: 'login',
      });
    }
  };

  closeError = () => {
    this.setState({
      err: false,
    });
  };

  changeModalTarget = target => {
    this.props.CleareErrors();
    this.setState(st => ({
      ...st,
      modalTarget: target,
    }));
  };

  getTargetComponent = target => {
    switch (target) {
      case 'register':
        return (
          <Register
            onChangeModalTarget={this.changeModalTarget}
            referer={this.props.referer}
            metadata={this.props.metadata}
          />
        );
      case 'forgot':
        return (
          <Forgot
            onChangeModalTarget={this.changeModalTarget}
            closeModal={this.closeModal}
            err={()=> this.props.error}
            referer={this.props.referer}
            metadata={this.props.metadata}
          />
        );
      default:
        return (
          <Login
            onChangeModalTarget={this.changeModalTarget}
            referer={this.props.referer}
            metadata={this.props.metadata}
          />
        );
    }
  };
}

const mapStateToProps = state => {
  return {
    isSignIn: state.auth.isSignIn,
    user: state.auth.user,
    forgot: state.auth.forgot,
    error: state.auth.error,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    CleareErrors: () => dispatch(CleareErrors()),
  };
};
Auth.propTypes = {
  CleareErrors: PropTypes.func.isRequired,
  isSignIn: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  error: PropTypes.object,
  modalTarget: PropTypes.oneOf(['login', 'forgot', 'register']),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Auth);
