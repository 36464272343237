import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import LocationSelect from '../location-select';
import {
  getSportCategoryList,
  createGroup,
  getGroupList,
  setGroupError,
  updateGroup,
  getGroup,
} from '../../../actions/Facility';
import FacilitySportCategory from './facility-sport-category';
import { PRICING_PLANS } from '../../../utils/constants';

const plans = PRICING_PLANS.map(item => ({
  ...item,
  features: [...item.features.slice(0, item.features.length - 1), 'Full Access to All Features'],
}));

const initialCreateState = {
  activeStep: 0,
  formData: {
    sportCategory: null,
    facilityGroupPlan: null,

    facilityGroupName: '',
    locationId: -1,

    coupon: '',
  },
};

const initialUpdateState = {
  formData: {
    facilityGroupName: '',
  },
};

const FacilityCreateForm = ({ isOpen, toggle }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isOpen) {
      setState(initialCreateState);
    }
  }, [isOpen]);

  const [{ activeStep, formData }, setState] = useState(initialCreateState);
  const { facilityGroup } = useSelector(state => ({
    facilityGroup: state.facility.group.current,
  }));

  const handleInputChange = useCallback(({ target: { type, name, value } }) => {
    setState(st => ({
      ...st,
      formData: {
        ...st.formData,
        [name]: value,
      },
    }));
  }, []);
  const handleStepChange = useCallback(step => setState(st => ({ ...st, activeStep: step })), []);
  const handleSubmit = useCallback(
    async e => {
      e.preventDefault();

      const data = {
        name: formData.facilityGroupName,
        plan: formData.facilityGroupPlan,
        sport_category_id: formData.sportCategory,
        location_id: formData.locationId,
        coupon: formData.coupon || undefined,
      };

      if (!data.location_id || data.location_id == -1) return dispatch(setGroupError('Please select location.'));

      if (await dispatch(createGroup(data))) {
        dispatch(getGroupList());
        toggle();
      }
    },
    [formData, dispatch, toggle]
  );

  return (
    <form autoComplete="nope" className="form" onSubmit={handleSubmit}>
      <Tabs className="px-3 py-4" selectedIndex={activeStep} onSelect={handleStepChange}>
        <TabList className="d-none">
          <Tab>Select facility group</Tab>
          <Tab>Pricing plan</Tab>
          <Tab>Facility information</Tab>
        </TabList>
        <TabPanel>
          <div className="row">
            <div className="col">
              <h4 className="border-0 text-left mt-0 pt-0 mb-4">Select facility group to continue</h4>
            </div>
          </div>

          <div className="d-block">
            <FacilitySportCategory value={formData.sportCategory} onChange={handleInputChange} />
          </div>

          <div className="row">
            <div className="col d-flex">
              <button type="button" className="btn btn-outline-secondary btn-lg px-4 d-block mr-auto" onClick={toggle}>
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-success btn-lg px-4 d-block ml-auto"
                disabled={!formData.sportCategory}
                onClick={() => handleStepChange(1)}>
                Next
              </button>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="row">
            <div className="col">
              <h4 className="border-0 text-left mt-0 pt-0 mb-4">Select facility plan to continue</h4>
            </div>
          </div>
          <div className="facility-plans">
            <div className="row row-cols-3 facility-plans--list">
              {plans.map(plan => {
                const htmlId = `facilityGroupPlan-${plan.plan}`;
                return (
                  <label className="col mb-4 facility-plans--item" htmlFor={htmlId} key={plan.plan}>
                    <input
                      type="radio"
                      name="facilityGroupPlan"
                      value={plan.plan}
                      checked={formData.facilityGroupPlan == plan.plan}
                      onChange={handleInputChange}
                      id={htmlId}
                    />
                    <div className="facility-plans--item__content">
                      <div className="card facility-plan">
                        <div className="card-body position-relative">
                          <h5 className="card-title facility-plan--title">{plan.name}</h5>
                          <h6 className="card-title text-center font-weight-bold mb-4">${plan.price}/month</h6>

                          <div className="card-content">
                            <ul className="list-group list-group-flush plan-list">
                              {plan.features.map(ft => (
                                <li className="list-group-item plan-list--item" key={ft}>
                                  {ft}
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </label>
                );
              })}
            </div>
          </div>

          <div className="row">
            <div className="col d-flex">
              <button
                type="button"
                className="btn btn-outline-secondary btn-lg px-4 d-block mr-auto"
                onClick={() => handleStepChange(0)}>
                Prev
              </button>
              <button
                type="button"
                className="btn btn-success btn-lg px-4 d-block ml-auto"
                disabled={!formData.facilityGroupPlan}
                onClick={() => handleStepChange(2)}>
                Next
              </button>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="row">
            <div className="col">
              <h4 className="border-0 text-left mt-0 pt-0 mb-4">Fill facility information</h4>
            </div>
          </div>

          <div className="row">
            <div className="col col-8 mx-auto">
              <div className="facility-form">
                <div className="form-group">
                  <label className="text-left d-block w-100">Facility (Business) Name*</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    name="facilityGroupName"
                    value={formData.facilityGroupName}
                    onChange={handleInputChange}
                    placeholder="Facility name"
                  />
                </div>

                <div className="form-group">
                  <label className="text-left d-block w-100">Location*</label>
                  <LocationSelect
                    onChange={opt => handleInputChange({ target: { name: 'locationId', value: opt.id } })}
                    value={{
                      id: formData.locationId,
                    }}
                  />
                </div>
                <div className="form-group">
                  <label className="text-left d-block w-100">Coupon Code (optional)</label>
                  <input
                    type="text"
                    className="form-control"
                    name="coupon"
                    value={formData.coupon}
                    onChange={handleInputChange}
                    placeholder="Coupon Code"
                  />
                </div>
                {/* <div className="form-group">
              <label className="text-left d-block w-100">Payment Method</label>
              <input type="text" className="form-control" placeholder="Payment Method" />
            </div> */}
              </div>

              {facilityGroup.error ? (
                <div className="alert alert-danger mt-4" role="alert">
                  {facilityGroup.error}
                </div>
              ) : null}
            </div>
          </div>

          <div className="row">
            <div className="col d-flex">
              <button
                type="button"
                className="btn btn-outline-secondary btn-lg px-4 d-block mr-auto"
                onClick={() => handleStepChange(1)}>
                Prev
              </button>
              <button type="submit" className="btn btn-success btn-lg px-4 d-block ml-auto">
                Save
              </button>
            </div>
          </div>
        </TabPanel>
      </Tabs>
    </form>
  );
};
const FacilityUpdateForm = ({ itemId, isOpen, toggle }) => {
  const dispatch = useDispatch();

  const [{ formData }, setState] = useState(initialUpdateState);

  const { facilityGroup } = useSelector(state => ({
    facilityGroup: state.facility.group.current,
  }));

  const handleInputChange = useCallback(({ target: { type, name, value } }) => {
    setState(st => ({
      ...st,
      formData: {
        ...st.formData,
        [name]: value,
      },
    }));
  }, []);

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault();

      const data = {
        name: formData.facilityGroupName,
      };

      if (await dispatch(updateGroup(itemId, data))) {
        dispatch(getGroupList());
        toggle();
      }
    },
    [formData, dispatch, itemId, toggle]
  );

  useEffect(() => {
    if (!isOpen) {
      setState(initialUpdateState);
    }
  }, [isOpen]);

  useEffect(() => {
    if (itemId) {
      dispatch(getGroup(itemId));
    }
  }, [dispatch, itemId]);

  useEffect(() => {
    if (facilityGroup.data?.id == itemId) {
      setState(st => ({
        ...st,
        formData: {
          ...st.formData,
          facilityGroupName: facilityGroup.data.name,
        },
      }));
    }
  }, [facilityGroup, itemId]);

  return (
    <form autoComplete="nope" className="form" onSubmit={handleSubmit}>
      <div className="px-3 py-4">
        <div className="row">
          <div className="col">
            <h4 className="border-0 text-left mt-0 pt-0 mb-4">Fill facility information</h4>
          </div>
        </div>

        <div className="row">
          <div className="col col-8 mx-auto">
            <div className="facility-form">
              <div className="form-group">
                <label className="text-left d-block w-100">Facility (Business) Name*</label>
                <input
                  type="text"
                  className="form-control"
                  required
                  name="facilityGroupName"
                  value={formData.facilityGroupName}
                  onChange={handleInputChange}
                  placeholder="Facility name"
                />
              </div>
            </div>

            {facilityGroup.error ? (
              <div className="alert alert-danger mt-4" role="alert">
                {facilityGroup.error}
              </div>
            ) : null}
          </div>
        </div>

        <div className="row">
          <div className="col d-flex justify-content-center">
            <button type="button" className="btn btn-outline-secondary btn-lg px-2 d-block mr-1" onClick={toggle}>
              Cancel
            </button>
            <button type="submit" className="btn btn-success btn-lg px-4 d-block ml-1">
              Save
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

const FacilityFormModal = ({ isOpen, toggle, data = {} }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSportCategoryList());
  }, [dispatch]);

  return (
    <div className="app-bootstrap-support">
      <div className={`modal facility-modal ${isOpen ? 'active' : ''}`} onClick={toggle}>
        <div className="modal-content" onClick={e => e.stopPropagation()}>
          <div className="modal-header">
            <h3 className="p-0 m-0 text-left">{`${data.editableItemId ? 'Update' : 'Create'} facility`}</h3>
          </div>
          <div className="modal-body">
            {isOpen &&
              (data.editableItemId ? (
                <FacilityUpdateForm isOpen={isOpen} toggle={toggle} itemId={data.editableItemId} />
              ) : (
                <FacilityCreateForm isOpen={isOpen} toggle={toggle} />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FacilityFormModal;
