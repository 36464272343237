import React, { Component } from "react";
import FormGroup from "../FormGroup";

class ModalDel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      event: "0",
      automatically_refund_payment: props.business
        ? props.business.automatically_refund_payment &&
        ((props.item &&
          props.item.payments &&
          props.item.payments.length > 0) ||
          (props.item &&
            props.item.record &&
            props.item.record.payments &&
            props.item.record.payments.length > 0))
        : false,
    };
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleChangeCheckBox = () => {
    this.setState((prevState) => ({
      automatically_refund_payment: !prevState.automatically_refund_payment,
    }));
  };

  render() {
    const { event, automatically_refund_payment } = this.state;

    const {
      closeDell,
      resolve,
      customClassname,
      if_booked_in_recurrence,
      item,
      hide_refund,
      bookings,
      is_nbu,
    } = this.props;

    const is_recurrence = item && !is_nbu ? item.recurrence_id : false;
    const is_lesson = item ? item.lesson_id : false;

    return (
      <div
        className={
          customClassname ? `modal active ${customClassname}` : "modal active"
        }
      >
        <div className="modal-content">
          {is_recurrence && (
            <div className="check-group dell-group">
              <FormGroup
                type="radio"
                name="event"
                id="this"
                checked={event === "0"}
                value="0"
                onChange={this.handleChange}
                label="This event"
              >
                <label htmlFor="this" className="label-icon" />
              </FormGroup>
              <FormGroup
                type="radio"
                name="event"
                id="thisFoll"
                checked={event === "1"}
                value="1"
                onChange={this.handleChange}
                label={"This and following events"}
              >
                <label htmlFor="thisFoll" className="label-icon" />
              </FormGroup>

              {if_booked_in_recurrence && (
                <FormGroup
                  type="radio"
                  name="event"
                  id="exeptBooked"
                  checked={event === "4"}
                  value="4"
                  onChange={this.handleChange}
                  label={"All Except Booked Events"}
                >
                  <label htmlFor="exeptBooked" className="label-icon" />
                </FormGroup>
              )}
              <FormGroup
                type="radio"
                name="event"
                id="allEvents"
                checked={event === "2"}
                value="2"
                onChange={this.handleChange}
                label="All events"
              >
                <label htmlFor="allEvents" className="label-icon" />
              </FormGroup>
            </div>
          )}
          {!bookings && (
            <div>
              {!is_recurrence && is_lesson && (
                <span>Are you sure to delete the lesson?</span>
              )}
              {!is_recurrence && !is_lesson && (
                <span> Are you sure to delete the listing?</span>
              )}
            </div>
          )}
          {bookings && (
            <div>
              {!is_recurrence && (
                <span>Are you sure to cancel this booking?</span>
              )}
            </div>
          )}

          {!hide_refund && !is_nbu && (
            <div className="check-group dell-group">
              <FormGroup
                type="checkbox"
                name="automatically_refund_payment"
                id="automatically_refund_payment"
                checked={automatically_refund_payment}
                value={automatically_refund_payment}
                onChange={this.handleChangeCheckBox}
                label="Automatically refund payment"
              >
                <label
                  htmlFor="automatically_refund_payment"
                  className="label-icon"
                />
              </FormGroup>
            </div>
          )}
          <div className="modal-btn">
          <button
              onClick={() => {
                resolve({
                  event: event,
                  automatically_refund_payment: automatically_refund_payment,
                });
                closeDell();
              }}
            >
              Yes
            </button>
            <button
              onClick={() => {
                resolve({ event: -1 });

                closeDell();
              }}
            >
              No
            </button>
            
          </div>
        </div>
      </div>
    );
  }
}

ModalDel.propTypes = {};

export default ModalDel;
