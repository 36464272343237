import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Calendly from '../../components/Calendly';
import FormGroup from '../../components/FormGroup';
import { sendDemo } from '../../actions/util';
import RequestDemo from '../Forms/RequestDemo';
import './request-modal.scss';


class RequestModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpenedCalendly: null,
    };
    this.closeModal = this.closeModal.bind(this);
  }





  openCalModal = () => {
    this.setState({
      modalOpenedCalendly: (<Calendly opened={true} close={this.props.close} />),
    });
  }

  closeModal(e) {
    if (e.target.id === 'reqModalBack') {
      this.props.close();
    }
  }


  detectSent = (bool) => {
    if (bool) {
      this.openCalModal();
    }

  }

  render() {
    const { modalOpenedCalendly } = this.state;
    const { opened, close } = this.props;


    return (
      <React.Fragment>
        {!modalOpenedCalendly && <div
          role="test"
          className={`modal auth-modal request-modal req-modal ${opened ? 'active' : ''}`}
          onClick={this.closeModal}
          id="reqModalBack">
          <div className="modal-content">
            <div className="auth-container">
              <div className="close-auth-modal">
                <button onClick={close}>
                  <img src="/assets/img/cancel-white.png" alt="cancel" />
                </button>
              </div>
              <div className="request-modal-demo">
                <RequestDemo detectSent={this.detectSent} />
              </div>
            </div>
          </div>
        </div>}
        {modalOpenedCalendly}
      </React.Fragment>
    );
  }
}

RequestModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

export default RequestModal;
