import React, { Component } from "react";
import moment from "moment-timezone";
import Pagination from "rc-pagination";
import {
  deleteMicroSettings,
  getMicroSettings,
  starMicroSettings,
} from "../../actions/MicroSettings";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MicroSettings from "./micro-settings";
import { getCourts } from "../../actions/Courts";
import { GetExpiredRules} from "../../actions/Coach";

import "rc-pagination/assets/index.css";
const LIMIT = 30;

class MicroSettingsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: props.showForm || false,
      editableId: null,
      current: 1,
    };
  }

  addRow = () => {
    this.setState({
      showForm: true,
      editableId: null,
    });
  };

  handleDelete = async (id) => {
    const { current } = this.state;
    if (
      await window.modal.cnfirm("Confirm", "Are you sure to delete the rule?")
    ) {
      this.props.deleteMicroSettings(id, "apply", null, {
        offset: (current - 1) * LIMIT,
      });
    }
  };
  setCookie=(cname, cvalue, exdays) =>{
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
   
    let expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
  getCookie=(cname)=> {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return false;
  }
  handleEdit = (id) => {
    this.setState({
      showForm: true,
      editableId: id,
    });
  };

  handleStar = (id, type) => {
    const { current } = this.state;
    this.props.starMicroSettings(id, type, {
      offset: (current - 1) * LIMIT,
    });
  };

  handleFormClose = () => {
    this.setState({
      showForm: false,
      editableId: null,
    });
  };
  onChange = (page, field) => {
    this.setState({
      [field]: page,
    });
  };

  componentDidMount() {
    this.props.getCourts();
    this.props.getMicroSettings();
  }

  componentDidUpdate(prevProps, prevState) {
    const { current } = this.state;
    const { current: current_old } = prevState;
    const cookiealue = this.getCookie("expiredRulesId__New"+this.props?.user?.uid)
    
   
    if(cookiealue &&  cookiealue=="null"){
   
      this.props.GetExpiredRules().then((res)=>{
       
        this.setState({
          expiredRules:res
        })
        this.setCookie("expiredRulesId__New"+this.props?.user?.uid,JSON.stringify(res),7)
      })
    }
    

    if (current !== current_old) {
      this.props.getMicroSettings(null, {
        offset: (current - 1) * LIMIT,
      });
    }
  }

  render() {
    const {
      micro_settings: {
        list: { loading, error, data, total },
      },
      timeZone,
      currentDay,
      expiredRules
    } = this.props;

    const { showForm, editableId, current } = this.state;
    const newExpiredRules= this.getCookie("expiredRulesId__New"+this.props?.user?.uid)!=undefined? JSON.parse(this.getCookie("expiredRulesId__New"+this.props?.user?.uid)):[]
    
    const expiredRulesList =  newExpiredRules?.length> expiredRules?.length?newExpiredRules:expiredRules

   const expiredRulesIds=expiredRulesList.map(itm=>itm.id)
    data.sort(function(a, b){  
      return expiredRulesIds?.indexOf(b?.id) - expiredRulesIds?.indexOf(a?.id);
    });
   
    
    return (
      <div className={`micro-settings  ${this.props.extraClassName}`}>
        {showForm ? (
          <MicroSettings
            paging={{
              offset: (current - 1) * LIMIT,
            }}
            onClose={this.handleFormClose}
            timeZone={timeZone}
            currentDay={currentDay}
            editableId={editableId}
          />
        ) : (
          <div className="table-responsive">
            {loading ? (
              <p className="d-block text-center">Loading...</p>
            ) : (
              <React.Fragment>
                <p className="m-0 mb-2">
                  <span className="not-icon">
                    <img
                      src="/assets/img/red-notification.png"
                      style={{ marginRight: "10px" }}
                      alt="notification"
                    />
                  </span>
                  <b style={{ color: "red" }}>
                    Once every year remember to renew (update) your annual rules’ recurrence end date (extend for an additional 1 year) if any of your facility availability rules are meant to be permanent.
                  </b>
                </p>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Note</th>
                      <th>Date</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((row) => {
                      let expiredItem=undefined;
                      expiredItem =expiredRulesList.find((itm) => itm.id == row.id)
                     
                      
                      return (

                        <tr style={{ background: expiredItem ? "#ffd2d2" : "" }} key={row.id}>

                          <td>{row.note}</td>
                          <td>
                            {moment(row.createdAt)
                              .tz(timeZone)
                              .format("MMMM Do YYYY, h:mm:ss a")}
                            {expiredItem &&(
                            <small style={{fontSize:"10px"}}><br/>Expires on {moment(expiredItem.end_time)
                              .tz(timeZone)
                              .format("MMMM Do YYYY")}
                              </small>) }
                          </td>
                          <td>
                            <div className="d-flex justify-center">
                              <button
                                className="btn btn-red rounded mr-2"
                                type="button"
                                onClick={() => this.handleDelete(row.id)}
                              >
                                <img
                                  src="/assets/img/cancel-white.png"
                                  width={15}
                                  height={15}
                                  alt="delete"
                                />
                              </button>
                              <button
                                className="btn btn-green rounded"
                                type="button"
                                onClick={() => this.handleEdit(row.id)}
                              >
                                <img
                                  src="/assets/img/edit.png"
                                  width={15}
                                  height={15}
                                  alt="edit"
                                />
                              </button>
                              {row.stared === 1 && (
                                <img
                                  title="Star"
                                  onClick={() =>this.handleStar(row.id, 0)}
                                  className="chargeButton w-25"
                                  src="/assets/img/yellow-star.png"
                                  alt="Star"
                                />
                              )}
                              {row.stared === 0 && (
                                <img
                                  title="Star"
                                  onClick={() => this.handleStar(row.id, 1)}
                                  className="chargeButton grey-star"
                                  src="/assets/img/grey-star.png"
                                  alt="Star"
                                />
                              )}
                            </div>
                          </td>
                        </tr>
                      )
                    })

                    }
                    <tr>
                      <td colSpan={3}>
                        <button
                          className="btn btn-green rounded mx-auto"
                          type="button"
                          onClick={this.addRow}
                        >
                          <img
                            src="/assets/img/plus.png"
                            width={20}
                            height={20}
                            alt="plus"
                          />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="pagination-class">
                  <Pagination
                    onChange={(page) => this.onChange(page, "current")}
                    pageSize={LIMIT}
                    current={current}
                    total={total}
                  />
                </div>
              </React.Fragment>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    error: state.auth.signup,
    user: state.auth.user,
    micro_settings: state.micro_settings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCourts: (cpid) => dispatch(getCourts(cpid)),
    getMicroSettings: (id, options = {}) =>
      dispatch(getMicroSettings(id, options)),
    deleteMicroSettings: (id, type = "apply", group_by = null, options = {}) =>
      dispatch(deleteMicroSettings(id, type, group_by, options)),
    starMicroSettings: (id, type = true, options = {}) =>
      dispatch(starMicroSettings(id, type, options)),
    GetExpiredRules:()=>dispatch(GetExpiredRules())
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MicroSettingsList)
);
