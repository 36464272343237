import React, { useState } from 'react';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { hasPermission } from '../../utils/helpers';

const More = props => {
  const [{ isOpen }, setState] = useState({
    isOpen: false,
  });

  const handleToggle = useCallback(() => {
    setState(st => ({ ...st, isOpen: !st.isOpen }));
  }, []);

  return (
    <div className="business-switcher app-bootstrap-support">
      <div className={`dropdown  border-0 ml-2 ${isOpen ? 'show' : ''}`}>
        <button className="btn" type="button" onClick={handleToggle}>
          <svg
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
            className="bi bi-gear-fill"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 0 0-5.86 2.929 2.929 0 0 0 0 5.858z"
            />
          </svg>
        </button>
        <div
          className={`dropdown-menu mt-2 dropdown-menu-right ${isOpen ? 'show' : ''}`}
          style={{ width: '200px', zIndex: 5 }}>
          <div className="d-block w-100 px-3">
            {(props.user.user_type === 'nbu' ? props.user.business_id : true) && (
              <div className="form-group m-0">
                <Link
                  onClick={props.toggleMenu, handleToggle}
                  to="/private/profile"
                  className={props.location.pathname === '/private/profile' ? 'active' : ''}>
                  Profile
                </Link>
              </div>
            )}
            {hasPermission(props.roles, 'settings', 'read') && (
              <div className="form-group m-0">
                <Link onClick={props.toggleMenu, handleToggle} to="/private/settings">
                  Settings
                </Link>
              </div>
            )}
            <div className="form-group mt-3 m-0 w-100">
              <button
                className="btn btn-rounded btn-success d-block w-100 text-center"
                onClick={() => props.signOut(props.history)}>
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default More;
