import React, { Component } from "react";

class ModalInfo extends Component {
  render() {
    const { onClose, className } = this.props;
    return (
      <div
        className={`modal infoModalContainer modal-alert active ${
          className || ""
        }`}
      >
        <div className="modal-content">
          <div className="info-container">
            <div className="modal-text">{this.props.children}</div>
          </div>
          <div className="modal-btn">
            <button onClick={onClose}>Ok</button>
          </div>
        </div>
      </div>
    );
  }
}

ModalInfo.propTypes = {};

export default ModalInfo;
