import moment from "moment-timezone";
// import { GLOBAL_LOCATION_FACILITY_PAIR } from "./constants";
export const hasPermission = (roles = [], resource = "", action = "read") => {
  if (!roles || roles.length < 0) {
    return false;
  }
  const filtered = roles.filter((v) => v.resource === resource);
  return filtered && filtered["0"] && filtered["0"][action] ? true : false;
};

export const attachBusinessForNbu = (store) => {
  //const user = store.auth.user;
  return  store?.auth?.user?.business_id;
};

export function getDefaultFasilityGroupsSelected(user) {
  // const [location_id, facility_group_id] = (
  //   window.localStorage.getItem(GLOBAL_LOCATION_FACILITY_PAIR) || `-1:-1`
  // ).split(":");

  const fgs =
    user && user.locations
      ? user.locations.reduce(
          (result, location) => [...result, ...location.facility_groups],
          []
        )
      : [];
  const fg_ids =
    fgs.length === 1
      ? fgs.map((v) => {
          return {
            id: v.id,
          };
        })
      : [];
  return fg_ids;
}

export const generateImgSrc = (id, img) => {
  const _avatar = "/assets/img/avatar.png";
  const src = img
    ? img.indexOf("http") > -1
      ? img
      : process.env.REACT_APP_API_BASE_URL + "public/" + id + "/" + img
    : _avatar;
  return src;
};

export const generatePaging = (
  total,
  currentPage = 1,
  pageSize = 10,
  maxPages = 5
) => {
  // calculate total pages
  let totalPages = Math.ceil(total / pageSize);

  // ensure current page isn't out of range
  if (currentPage < 1) {
    currentPage = 1;
  } else if (currentPage > totalPages) {
    currentPage = totalPages;
  }

  let startPage, endPage;
  if (totalPages <= maxPages) {
    // total pages less than max so show all pages
    startPage = 1;
    endPage = totalPages;
  } else {
    // total pages more than max so calculate start and end pages
    let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
    let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
    if (currentPage <= maxPagesBeforeCurrentPage) {
      // current page near the start
      startPage = 1;
      endPage = maxPages;
    } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
      // current page near the end
      startPage = totalPages - maxPages + 1;
      endPage = totalPages;
    } else {
      // current page somewhere in the middle
      startPage = currentPage - maxPagesBeforeCurrentPage;
      endPage = currentPage + maxPagesAfterCurrentPage;
    }
  }

  // calculate start and end item indexes
  let startIndex = (currentPage - 1) * pageSize;
  let endIndex = Math.min(startIndex + pageSize - 1, total - 1);

  // create an array of pages to ng-repeat in the pager control
  let pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
    (i) => startPage + i
  );

  // return object with all pager properties required by the view
  return {
    total: total,
    currentPage: currentPage,
    pageSize: pageSize,
    totalPages: totalPages,
    startPage: startPage,
    endPage: endPage,
    startIndex: startIndex,
    endIndex: endIndex,
    pages: pages,
  };
};

export const makeid = (l) => {
  let text = "";
  const char_list =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (let i = 0; i < l; i++) {
    text += char_list.charAt(Math.floor(Math.random() * char_list.length));
  }
  return text;
};

export const hexToRgbA = (hex, alpha) => {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return (
      "rgba(" +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
      "," +
      alpha +
      ")"
    );
  }
  throw new Error("Bad Hex");
};

export function roundDate(date = moment(), step = 60, startOfDay) {
  let newDate = moment(date).clone().set("seconds", 0);

  if (!startOfDay) {
    startOfDay = newDate.clone().startOf("day");
  }
  startOfDay = startOfDay.set("seconds", 0);

  if (newDate.isBefore(startOfDay)) {
    newDate = startOfDay.clone();
  }

  const minutes_from_start_of_day = Math.round(
    moment.duration(newDate.diff(startOfDay)).asMinutes()
  );

  const delta = minutes_from_start_of_day % step;

  newDate.subtract(delta, "minutes");

  // const minutes = moment(date).minutes();
  // switch (true) {
  //   case minutes < step:
  //     newDate.set("minute", step);
  //     break;
  //   case minutes >= step:
  //     newDate.set("minute", 0).add(1, "hour");
  //     break;
  //   default:
  //     newDate.set("minute", step);
  //     break;
  // }
  return newDate;
}

export function generateTimeListStartedFromByStep(startTime, stepInMinutes) {
  const endOfDay = startTime.clone().add(1, "day").startOf("day");
  const result = [];

  let next = startTime.clone();
  while (true) {
    if (next.isAfter(endOfDay)) break;

    result.push(next.clone());

    next = next.add(stepInMinutes, "minutes");
  }

  // result.push(startTime.clone().add(1, "day").startOf("day"));

  return result;
}

export const getSplitRange = (start, end, timeStep, startOfDay) => {
  const r_start = roundDate(start, timeStep, startOfDay);
  const r_end = roundDate(end, timeStep, startOfDay);

  const res = [r_start];
  let step = r_start;

  while (true) {
    step = step.clone().add(timeStep, "minute");
    if (step.isSame(r_end) || step.isAfter(r_end)) {
      break;
    }
    res.push(step);
  }

  return res;
};

export const momentToDate = (date) => {
  return new Date(
    date.year(),
    date.month(),
    date.date(),
    date.hours(),
    date.minutes(),
    date.seconds(),
    date.milliseconds()
  );
};

export const dateToMomentWithReplacingOnlyTimezone = (date, timezone) => {
  let momentDate = moment().tz(timezone);
  
  momentDate.set("year", date.getFullYear());
  momentDate.set("month", date.getMonth());
  momentDate.set("date", date.getDate());
  momentDate.set("hour", date.getHours());
  momentDate.set("minute", date.getMinutes());
  momentDate.set("second", date.getSeconds());
  momentDate.set("millisecond", date.getMilliseconds());

  return momentDate;
};

export const roundTextTo = (text = "", length = 15) => {
  if (text.length <= length) return text;

  return (
    text
      .split("")
      .slice(0, length - 3)
      .join("") + "..."
  );
};

export const getMaxDurationErrorText = (data, usersCount) => {
  const {
    max_time_for_singles = 60,
    max_time_for_doubles = 60,
    max_time_for_triples = 60,
    max_time_for_quadruples = 60,
  } = data;

  if (usersCount === 1) {
    return `The business requires maximum ${max_time_for_singles} minutes per booking for 1 person.`;
  } else if (usersCount === 2) {
    return `The business requires maximum ${max_time_for_doubles} minutes per booking for 2 people.`;
  } else if (usersCount === 3) {
    return `The business requires maximum ${max_time_for_triples} minutes per booking for 3 people.`;
  } else {
    return `The business requires maximum ${max_time_for_quadruples} minutes per booking for 4 people and more.`;
  }
};

export const getKeyByUsersCount = (usersCount) => {
  if (usersCount === 1) {
    return `max_time_for_singles`;
  } else if (usersCount === 2) {
    return `max_time_for_doubles`;
  } else if (usersCount === 3) {
    return `max_time_for_triples`;
  } else {
    return `max_time_for_quadruples`;
  }
};

export const getValidationData = (courts, business, validationUsersCount) => {
  const validationData = {
    enable_max_time_for_bookings: false,
    max_time_for_singles: 60,
    max_time_for_doubles: 60,
    max_time_for_triples: 60,
    max_time_for_quadruples: 60,
    min_list_duration: 60,
  };

  if (courts.length) {
    const maxCourtByMinDur = courts.reduce((prev, current) => prev.min_list_duration > current.min_list_duration ? prev : current, courts[0]);

    // const maxCourtListByMinDur = courts.filter(
    //   (i) => i.min_list_duration === maxCourtByMinDur.min_list_duration
    // );

    const maxTimeEnabledCourts = courts.filter(
      (i) => i.enable_max_time_for_bookings
    );

    let minCourtByMaxDur;

    if (maxTimeEnabledCourts.length) {
      const maxTimeKey = getKeyByUsersCount(validationUsersCount);
      minCourtByMaxDur = maxTimeEnabledCourts.reduce((prev, current) => prev[maxTimeKey] < current[maxTimeKey] ? prev : current);
    }

    validationData.enable_max_time_for_bookings = minCourtByMaxDur
      ? minCourtByMaxDur.enable_max_time_for_bookings
      : business?.enable_max_time_for_bookings;
    validationData.max_time_for_singles = minCourtByMaxDur
      ? minCourtByMaxDur.max_time_for_singles
      : business?.max_time_for_singles;
    validationData.max_time_for_doubles = minCourtByMaxDur
      ? minCourtByMaxDur.max_time_for_doubles
      : business?.max_time_for_doubles;
    validationData.max_time_for_triples = minCourtByMaxDur
      ? minCourtByMaxDur.max_time_for_triples
      : business?.max_time_for_triples;
    validationData.max_time_for_quadruples = minCourtByMaxDur
      ? minCourtByMaxDur.max_time_for_quadruples
      : business?.max_time_for_quadruples;
    validationData.min_list_duration = maxCourtByMinDur
      ? maxCourtByMinDur.min_list_duration
      : business?.min_list_duration;
      
  }
  
  return validationData;
};

export const canBeBookedDuration = (data, usersCount, durationInHour) => {
  const {
    enable_max_time_for_bookings = false,
   // min_list_duration = 60,
    max_time_for_singles = 60,
    max_time_for_doubles = 60,
    max_time_for_triples = 60,
    max_time_for_quadruples = 60,
  } = data;

  // const minTimeInHour = min_list_duration / 60;
  // if (durationInHour < minTimeInHour) {
  //   return false;
  // }

  if (!enable_max_time_for_bookings) {
    return true;
  }

  if (usersCount === 1) {
    const maxTimeInHour = max_time_for_singles / 60;
    if (durationInHour <= maxTimeInHour) {
      return true;
    }
  } else if (usersCount === 2) {
    const maxTimeInHour = max_time_for_doubles / 60;
    if (durationInHour <= maxTimeInHour) {
      return true;
    }
  } else if (usersCount === 3) {
    const maxTimeInHour = max_time_for_triples / 60;
    if (durationInHour <= maxTimeInHour) {
      return true;
    }
  } else {
    const maxTimeInHour = max_time_for_quadruples / 60;
    if (durationInHour <= maxTimeInHour) {
      return true;
    }
  }

  return false;
};

export const fillOrCreateDefault = async ({
  res,
  itemData,
  createItem,
  getList,
  initialState,
  stateKey,
  setState,
  dispatch,
  findFn = (i) => i.name.toLowerCase() === "n/a",
}) => {
  let defaultItem = res.find(findFn);

  if (!defaultItem) {
    // create default product type
    defaultItem = await dispatch(createItem(itemData));
    dispatch(getList());
  }

  initialState[stateKey] = defaultItem.id;

  setState((st) => ({
    ...st,
    [stateKey]: defaultItem.id,
  }));
};
