import React, { Component } from "react";
import { connect } from "react-redux";
import Loading from '../../components/UI/Loading';

class MobileTypeDetector extends Component {
    

    componentDidMount() {
        const ua = navigator.userAgent;
        if (/android/i.test(ua)) {
            window.location.href = "https://play.google.com/store/apps/details?id=com.ballbutton.android&hl=en&gl=US";
        }  else if (/iPad|iPhone|iPod/.test(ua) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)){
            window.location.href = "https://apps.apple.com/am/app/ballbutton/id1336693461";
        }else{
            window.location.href = "https://play.google.com/store/apps/details?id=com.ballbutton.android&hl=en&gl=US";
        }
       

      }

      render() {
          return (
            <Loading/>
          );
      }

}

const mapStateToProps = (state) => {
    return {};
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {};
  };

  
  export default connect(mapStateToProps, mapDispatchToProps)(MobileTypeDetector);