import React, { Component } from "react";

import SubscriptionStripe from "../../components/SubscriptionStripe";
import { Elements, StripeProvider } from "react-stripe-elements";
import moment from "moment-timezone";

class PayModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stripe: null,
    };
  }

  componentDidMount() {
    if (window.Stripe) {
      this.setState({
        stripe: window.Stripe(process.env.REACT_APP_STRIPE_PUBLIC),
      });
    } else {
      document.querySelector("#stripe-js").addEventListener("load", () => {
        // Create Stripe instance once Stripe.js loads
        this.setState({
          stripe: window.Stripe(process.env.REACT_APP_STRIPE_PUBLIC),
        });
      });
    }
  }

  getRenderContent = (type) => {
    switch (type) {
      case "restricted": {
        return (
          <div className="modal auth-modal active" onClick={this.props.close}>
            <div className="modal-content">
              <div className="auth-container">
                <div className="close-auth-modal">
                  <button onClick={this.props.close}>
                    <img src="/assets/img/cancel-white.png" alt="cancel" />
                  </button>
                </div>
                <h4>
                  Your club’s set age limit for this plan doesn't allow you to
                  subscribe
                </h4>
              </div>
            </div>
          </div>
        );
      }
      case "need_info": {
        return (
          <div className="modal auth-modal active" onClick={this.props.close}>
            <div className="modal-content">
              <div className="auth-container">
                <div className="close-auth-modal">
                  <button onClick={this.props.close}>
                    <img src="/assets/img/cancel-white.png" alt="cancel" />
                  </button>
                </div>
                <h4>
                  Your club has set an age limit for this plan. Please input
                  your date of birth in your profile to subscribe
                </h4>
              </div>
            </div>
          </div>
        );
      }

      default: {
        return (
          <StripeProvider stripe={this.state.stripe}>
            <Elements>
              <SubscriptionStripe
                options={this.props.options}
                close={this.props.close}
                user={this.props.user}
                subscription_status={this.props.subscription_status}
              />
            </Elements>
          </StripeProvider>
        );
      }
    }
  };

  render() {
    let type;
    // const userDoB = moment(this.props.user.dob);

    const userDoB = this.props.user.dob
      ? moment().diff(this.props.user.dob, "years", false)
      : null;
    const min_age = this.props.options?.plan?.min_age;
    const max_age = this.props.options?.plan?.max_age;

    if (userDoB === null && (min_age || max_age)) {
      type = "need_info";
    } else {
      if (max_age && max_age < userDoB) type = "restricted";

      if (min_age && min_age > userDoB) type = "restricted";
    }

    return this.getRenderContent(type);
  }
}

export default PayModal;
