import React, { useState } from 'react';
import moment from 'moment-timezone';
import { getEntityList } from '../../actions/Location';
import { useDispatch, useSelector } from 'react-redux';
import LocationFormModal from './location-form';

import Loading from '../../components/UI/Loading';
import { useEffect } from 'react';

const Locations = (props) => {
  const [state, setState] = useState({
    editableItemId: null,
    isModalOpen: false,
  });

  const {
    timeZone,
    locationEntity: {
      list: { data, loading },
    },
  } = useSelector(state => ({
    timeZone: state.auth.user.business.timezone,
    locationEntity: state.location.entity,
  }));

  const dispatch = useDispatch();

  const handleCreate = () =>
    setState(st => ({
      ...st,
      editableItemId: null,
      isModalOpen: true,
    }));

  const handleEdit = item =>
    setState(st => ({
      ...st,
      editableItemId: item.id,
      isModalOpen: true,
    }));
  const handleCloseModal = () =>
    setState(st => ({
      ...st,
      editableItemId: null,
      isModalOpen: false,
    }));

  useEffect(() => {
    dispatch(getEntityList());
  }, [dispatch]);

  const { isModalOpen, editableItemId } = state;

  return (
    <div className={`micro-settings freeze-list-container`}>
      <div className="table-responsive">
        {loading ? (
          <Loading />
        ) : (
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Address</th>
                <th>Created At</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {data.map(row => (
                <tr key={row.id}>
                  <td>{row.id}</td>
                  <td>{row.name}</td>
                  <td>{row.address}</td>
                  <td>
                    {moment(row.createdAt)
                      .tz(timeZone)
                      .format('MMMM Do YYYY, h:mm:ss a')}
                  </td>
                  <td>
                    <div className="d-flex justify-center">
                      <button disabled={props.permission ? false : true} className="btn btn-green rounded mr-2" type="button" onClick={() => handleEdit(row)}>
                        <img src="/assets/img/edit.png" width={15} height={15} alt="edit" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}

              <tr>
                <td colSpan={5}>
                  <button disabled={props.permission ? false : true} className="btn btn-green rounded mx-auto" type="button" onClick={handleCreate}>
                    <img src="/assets/img/plus.png" width={20} height={20} alt="plus" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
      <LocationFormModal isOpen={isModalOpen} data={{ editableItemId }} toggle={handleCloseModal} />
    </div>
  );
};

export default Locations;
