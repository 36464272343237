import * as actionTypes from '../constants/Membership';

const initState = {
  current: {
    loading: false,
    data: undefined,
  },
  info: {
    loading: false,
    data: undefined,
  },
};

const MembershipReducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.MEMBERSHIP_INFO_LOADING: {
      return {
        ...state,
        info: {
          ...state.info,
          loading: action.payload,
        },
      };
    }
    case actionTypes.MEMBERSHIP_INFO_ERROR: {
      return {
        ...state,
        info: {
          ...state.info,
          error: action.payload,
        },
      };
    }
    case actionTypes.MEMBERSHIP_INFO_SUCCESS: {
      return {
        ...state,
        info: {
          ...state.info,
          error: undefined,
          data: action.payload,
        },
      };
    }

    // ENTITY_CURRENT handlers
    case actionTypes.MEMBERSHIP_CURRENT_LOADING: {
      return {
        ...state,
        current: {
          ...state.current,
          loading: action.payload,
        },
      };
    }
    case actionTypes.MEMBERSHIP_CURRENT_ERROR: {
      return {
        ...state,
        current: {
          ...state.current,
          error: action.payload,
        },
      };
    }
    case actionTypes.MEMBERSHIP_CURRENT_SUCCESS: {
      return {
        ...state,
        current: {
          ...state.current,
          error: undefined,
          data: action.payload,
        },
      };
    }

    default:
      return state;
  }
};

export default MembershipReducer;
