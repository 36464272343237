import React, { Component } from "react";
import { connect } from "react-redux";

import TerminalIcon from "../../components/TerminalIcon";

import ChargeModalNBU from "../../components/ChargeModalNBU";
import StripeCharge from "../../components/StripeCharge";
import ModalCashInput from "../../components/ModalCashInput";
import ModalPoints from "../../components/ModalPoints";

import { withRouter } from "react-router-dom";
import "./payments.scss";

class PaymentsNBU extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openedModal: null,
      actives: {},
    };
  }

  closeModal = () => {
    this.setState({ openedModal: null });
  };

  openChargePaymantPopUp = (
    uid,
    stripeUserId,
    user,
    child_id = null,
    member_type_id = null,
    booking = null
  ) => {
    const {
      outerState,
      from,
      show_members,
      cbCharge,
      default_price,
      identity,
      clouseModal,
      dateTime
    } = this.props;
    
    const is_nbu = identity.user_type === "nbu";
    if (!this.props.isConnected)
      return window.modal.alert(
        "Warning",
        "First <a href='/private/stripe'> connect to stripe </a>"
      );
     
    this.setState({
      openedModal: (
        <ChargeModalNBU
          outerState={
            outerState
              ? {
                  ...outerState,
                  userFullName: `${user.first_name} ${user.last_name}`,
                  child_id: child_id,
                }
              : null
          }
          uid={uid}
          dateTime={dateTime}
          waitlist={this.props.waitlist}
          from={from}
          cbCharge={cbCharge}
          booking={booking}
          default_price={default_price}
          show_members={is_nbu ? false : show_members}
          member_type_id={member_type_id}
          stripeUserId={stripeUserId}
          clouseModalRoot={clouseModal}
          clouseModal={this.closeModal}
        />
      ),
    });
  };

  openChargeNoModal = (
    uid,
    stripeUserId,
    user,
    child_id = null,
    member_type_id = null,
    booking = null
  ) => {
    const { outerState, from, cbCharge, default_price } = this.props;
    this.setState({
      openedModal: (
        <StripeCharge
          booking={booking}
          cbCharge={cbCharge}
          member_type_id={member_type_id}
          from={from}
          default_price={default_price}
          outerState={
            outerState
              ? {
                  ...outerState,
                  userFullName: `${user.first_name} ${user.last_name}`,
                  child_id: child_id,
                }
              : null
          }
          uid={uid}
          stripeUserId={stripeUserId}
          clouseModal={this.props.clouseModal}
        />
      ),
    });
  };

  
  addPoint = (booking, user) => {
    const {
      type,
      id,
      from,
      show_members,
      cbCharge,
      default_price,
      default_point,
      identity,
      point
    } = this.props;
    const is_nbu = identity.user_type === "nbu";
    this.setState({
      openedModal: (
        <ModalPoints
          booking={booking}
          point={point}
          is_nbu={is_nbu}
          default_point={default_point}
          default_price={default_price}
          cbCharge={cbCharge}
          show_members={show_members}
          from={from}
          charge_for={type}
          user={user}
          appointment_id={id}
          closeModal={this.closeModal}
        />
      ),
    });
  };

  render() {
    const { openedModal } = this.state;
    const {
      id,
      type: type_for,
      in_booking,
      child_id: _child_id,
      charge_user_id: _charge_user_id,
      payment_account_id: _payment_account_id,
      user,
      book_pay_type,
      booking,
      from,
      show_all,
      is_payments,
      show_members,
      cbCharge,
      identity,
      fromNBuCourt
    } = this.props;
    const payment_account_id = _payment_account_id;
    const child_id = _child_id;

    const charge_user_id = _charge_user_id;
    const filters = from && from.filters ? from.filters : null;
    const type = filters && filters.type ? filters.type : null;
    const member_type_id = user ? user.member_type_id : null;
    const is_nbu = identity.user_type === "nbu";
    const hidePayment = fromNBuCourt!=undefined &&is_nbu? false:true;
    const canPayWithoutPaymentAccountId = this.props?.user?.business?.family.length>0
   
    return (
      <div className="payments-container">
        <div className={in_booking ? `` : "make-it-flex"}>
          <React.Fragment>
            {((hidePayment && (canPayWithoutPaymentAccountId?true:payment_account_id)  && book_pay_type !== "2" && !type) ||
              show_all ||
              type === "0") && (
              <li className={"dropdown__list-item "}>
                <img
                  title="Saved Card"
                  onClick={() => {
                    is_payments
                      ? this.openChargeNoModal(
                          charge_user_id,
                          "-1",
                          user,
                          child_id,
                          member_type_id,
                          booking
                        )
                      : this.openChargePaymantPopUp(
                          charge_user_id,
                          "-1",
                          user,
                          child_id,
                          member_type_id,
                          booking
                        );
                  }}
                  className="chargeButton"
                  src="/assets/img/dollar-paper-bills-stack.svg"
                  alt="Saved Card"
                />
               
               <b><small style={{fontSize:"8px",display: "block",textAlign:"center",fontWeight:"bold"}}>Saved Card</small></b>
              </li>
            )}
            {((hidePayment && book_pay_type !== "2" && !type) || show_all || type === "0") && (
              <li className={"dropdown__list-item "}>
                <img
                  title="New Card"
                  onClick={() => {
                    is_payments
                      ? this.openChargeNoModal(null, null, user, null, booking)
                      : this.openChargePaymantPopUp(
                          null,
                          null,
                          user,
                          null,
                          null,
                          booking
                        );
                  }}
                  className="chargeButton"
                  src="/assets/img/dollar-paper-bills-stack-guest.svg"
                  alt="New Card"
                />
                <b><small style={{fontSize:"8px",display: "block",textAlign:"center",fontWeight:"bold"}}>New Card</small></b>
              </li>
            )}
            
            {(((book_pay_type !== "1" && !type) ||
              (booking && booking.facility_fee_point > 0) ||
              show_all ||
              type === "1") && in_booking) && (
              <li className={"dropdown__list-item "}>
                <img
                  title="Points"
                  className="chargeButton cash-input-btn"
                  onClick={() => this.addPoint(booking, user)}
                  src={"/assets/img/points.png"}
                  alt="Points"
                />
                <b><small style={{fontSize:"8px",display: "block",textAlign:"center",fontWeight:"bold"}}>Points</small></b>
              </li>
            )}
          </React.Fragment>
        </div>
        {openedModal}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isConnected: state.stripe.isConnected,
    timezone: state.auth.user.business.timezone,
    identity: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PaymentsNBU)
);
