import React, { Component } from 'react';
import './demo.scss';
class Demo extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {

        return (
            <div className="demo-button">
                <button {...this.props}>Private Demo</button>
            </div>
        );
    }
}

export default Demo;
