import React, { PureComponent } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const DivWrapp = styled.div`
  position: relative;
  margin: 0 auto;
`;
const Parsent = styled.span`
  position: absolute;
  top: 5px;
  right: 7px;
`;
const Input = styled.input.attrs({ type: 'text' })`
  border: 1px solid;
  background-color: transparent;
  font-size: 20px;
  outline: none;
  padding: 0.375rem 0.25rem !important;
  border-radius: 3px;
  font-size: 16px;
  width: 100%;
  border-color: ${props => (props.error ? 'red' : '#606948')} !important ;
`;

class TaxInput extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
    };
  }

  render() {
    const { onChange: parentOnChange, ...rest } = this.props;
    return (
      <DivWrapp>
        <Input
          error={this.state.error}
          {...rest}
          onChange={e => {
            const target = e.target;
            if (/^([0-9]*[.])?[0-9]+$/.test(target.value) && parseFloat(target.value) < 100) {
              this.setState({ error: false });
              target.setCustomValidity('');
              parentOnChange(e, { error: false });
            } else {
              this.setState({ error: true });
              target.setCustomValidity('Not valid Tax number');
              parentOnChange(e, { error: true });
            }
          }}
        />
        <Parsent>%</Parsent>
      </DivWrapp>
    );
  }
}

TaxInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  // value: PropTypes.string.isRequired
};

export default TaxInput;
