import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

import { CardElement, injectStripe } from 'react-stripe-elements';
import Promo from '../Promo/promo';
import { createpaymentmethod } from '../../actions/Pay';
import { setBusiness } from '../../actions/Auth';
import { addPaymant, subscribeNbu } from '../../actions/Stripe';
import FormGroup from '../../components/FormGroup';

//addPaymant
class SubscriptionStripe extends Component {
  constructor(props) {
    super(props);
    const { options } = this.props;
    this.state = {
      promo: '',
      signed: '',
      save_card: !options?.plan?.one_time || false
    };
    this.submit = this.submit.bind(this);
  }

  async submit() {
    this.savebtn.setAttribute("disabled", "disabled");
    const { user, options, subscription_status } = this.props;
    const { save_card, signed } = this.state;
    const nbu_subscribe = options?.nbu_subscribe || false;
    const show_sign = options?.show_sign || false;

    const plan = options?.plan || {};

    const is_incomplete = subscription_status && subscription_status === 'incomplete' ? true : false
    // get User token
    let { token } = await this.props.stripe.createToken({ name: 'Subscription Stripe' });
    if (!nbu_subscribe) {
      //Create Subscription
      if (token && !is_incomplete) {
        await this.props.createpaymentmethod(token.id, this.state.promo);
      }
      if (token && is_incomplete) {
        await this.props.addPaymant(null, token.id, true, user.id);
        await window.modal.alert('Success', 'Card successfully added. We will try to charge from this card');
      }
    } else {
      if (!token) {
        await window.modal.alert('Warning', `Please fill credit card info`);
        this.savebtn && this.savebtn.removeAttribute("disabled");
        return;
      }

      if (show_sign && !signed) {
        await window.modal.alert('Warning', `Please agree to following Terms and Policies`);
        this.savebtn && this.savebtn.removeAttribute("disabled");
        return;
      }


      const bool = await this.props.subscribeNbu({
        "business_id": plan.business_id,
        "plan": plan.plan_id,
        "signed": !!signed,

        "payment": {
          "token": token.id,
          "save_card": save_card
        }

      });
      if (bool) {
        await window.modal.alert('Success', 'Subcription successfully created');
        await this.goToBusiness(plan.business_id);
      }

    }


    this.savebtn && this.savebtn.removeAttribute("disabled");


  }

  componentDidMount() { }

  hendlerChangePromo = (promo) => {
    this.setState({ promo: promo });
  };
  hendlerChange = (e) => {
    const { options } = this.props;
    const one_time = options?.plan?.one_time || false;
    if (!one_time) {
      this.setState({ save_card: true });
    } else {
      this.setState({ [e.target.name]: e.target.checked });
    }
  };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.checked,
    });
  }

  goToBusiness = async business_id => {
    const bool = await this.props.setBusiness(business_id);
    if (bool) {
      return this.props.history.push(`/private/dashboard`);
    }
  };



  render() {
    const { subscription_status, close, options } = this.props;
    const { save_card, signed } = this.state;
    const is_incomplete = subscription_status && subscription_status === 'incomplete' ? true : false;
    const nbu_subscribe = options?.nbu_subscribe || false;
    const show_sign = options?.show_sign || false;
    const plan = options?.plan || {};
    const business = options?.business || {};

    const filename = business?.sign_file;
    let file_url = filename
      ? process.env.REACT_APP_API_BASE_URL + 'public/' + business.user_id + '/' + filename
      : '#';
    return (
      <div className="form-container" style={{ textAlign: 'centor' }}>
        <div id="dropin-container" />
        <div className="promoButtonWraper">
          {close && <div className="close-btn">
            <button onClick={close}>
              <img src="/assets/img/cancel.png" alt="cancel" />
            </button>
          </div>}
          {nbu_subscribe && <h3 style={{ marginBottom: '20px' }}>{plan.nickname}</h3>}
          {is_incomplete && !nbu_subscribe && <h3 style={{ marginBottom: '20px' }}>Unable to charge from your card. Please enter new card info.</h3>}
          <CardElement style={{ base: { fontSize: '16px' } }} />
          {!is_incomplete && !nbu_subscribe && <Promo hendlerChangePromo={this.hendlerChangePromo} />}
          {nbu_subscribe && <div className="save_card_div">
            <FormGroup
              checked={!!save_card}
              type="checkbox"
              name="save_card"
              label="Save a credit card to subscribe & for recurring payments for the subscription"
              onChange={this.hendlerChange}
              value={save_card}
            />
          </div>}
          {nbu_subscribe && show_sign && <div className="check-group dell-group sign-group" style={{ display: "flex", justifyContent: "left", alignItems: "center" }}>
            <label style={{ display: "inline-block", width: "300px", marginRight: "15px" }}>

              {business.name} requires you to agree to the following  {filename ? (
                <a href={file_url} target="_blank">
                  Terms and Policies
                </a>
              ) : (
                  '')}
            </label>
            <FormGroup
              type="checkbox"
              name="signed"
              id="this"
              checked={signed}
              value={signed}
              onChange={this.handleChange}
              label="">
              <label htmlFor="this" className="label-icon" />
            </FormGroup>
          </div>}
          <button ref={btn => { this.savebtn = btn; }} onClick={this.submit} id="submit-button">
            {nbu_subscribe ? `Subscribe` : `Save or Add card`}
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {

  };
};

const mapDispatchToProps = dispatch => {
  return {
    createpaymentmethod: (token, promo) => dispatch(createpaymentmethod(token, promo)),
    addPaymant: (uid = null, token, admin = false, user_id) => dispatch(addPaymant(uid, token, admin, user_id)),
    setBusiness: business_id => dispatch(setBusiness(business_id)),
    subscribeNbu: (options = {}) => dispatch(subscribeNbu(options)),
  };
};
SubscriptionStripe.propTypes = {
  createpaymentmethod: PropTypes.func.isRequired

};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(injectStripe(SubscriptionStripe)));
