import { combineReducers } from 'redux';
import auth from './AuthReducer';
import courts from './CourtsReducer';
import court from './CourtReducer';
import token from './Payreducer';
import members from './MembersReducer';
import notification from './NotificationsReducer';
import analytics from './AnalyticsReducer';
import stripe from './StripeReducer';
import chargeHistory from './ChargeHistoryReducer';
import group from './GroupReducer';
import lesson from './LessonReducer';
import tag from './TagReducer';
import coach from './CoachReducer';
import terminal from './TerminalReducer';
import micro_settings from './MicroSettingsReducer';
import roles from './RolesReducer';
import points from './PointsReducer';
import shop from './ShopReducer';
import facility from './FacilityReducer';
import location from './LocationReducer';
import membership from './MembershipReducer';

import { SIGN_OUT_SUCCESS } from '../constants/Auth';

// Combine all reducers.
const appReducer = combineReducers({
  auth,
  courts,
  court,
  token,
  stripe,
  members,
  notification,
  analytics,
  chargeHistory,
  group,
  lesson,
  tag,
  coach,
  terminal,
  micro_settings,
  roles,
  points,
  shop,
  facility,
  location,
  membership,
});

const rootReducer = (state, action) => {
  // Clear all data in redux store to initial.
  if (action.type === SIGN_OUT_SUCCESS) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
