import React, { Component } from 'react';

import PosDetailsModal from '../InvoiceModal/pos-details-modal';


class RefundOrderPaymentBtn extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openedModal: null,
        };
    }

    openRefundChargeModal = () => {
        const { item, user_id, member_id, params, from } = this.props;
        this.setState({
            openedModal: (
                <PosDetailsModal
                    user_id={user_id}
                    member_id={member_id}
                    payment={item}
                    from={from}
                    params={params}
                    closeModal={this.closeModal}
                    order_details={item.order.details}
                />
            )
        });
    }

    closeModal = () => {
        this.setState({ openedModal: null });
    }
    render() {
        const { openedModal } = this.state;
        const { permission } = this.props;
        return (
            <div className="pretty-button">
                <button disabled={permission ? false : true} onClick={this.openRefundChargeModal} className="red-button" >Refund</button>
                {openedModal}
            </div>
        );
    }
}


export default RefundOrderPaymentBtn;
