import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './bb-video-modal.scss';


class BBVideoModal extends Component {
  constructor(props) {
    super(props);


  }

  closeModal = () => {
    this.props.close();
  }



  render() {
    const { close } = this.props;
    return (
      <React.Fragment>
        <div
          role="test"
          className={`modal auth-modal  bb-video-modal  active`}
          onClick={close}
          id="reqModalBack">
          <div className="modal-content">
            <div className="auth-container">
              <div className="close-auth-modal">
                <button onClick={close}>
                  <img src="/assets/img/cancel-white.png" alt="cancel" />
                </button>
              </div>
              <div className="video-bb">
                <iframe src='https://www.youtube.com/embed/lYFJYrUcSck?rel=0'
                  frameBorder='0'
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title='video'

                />

              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

BBVideoModal.propTypes = {
  close: PropTypes.func.isRequired,
};

export default BBVideoModal;
