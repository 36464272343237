import React, { Component } from "react";
import { sendDemo } from "../../actions/util";
import Demo from '../Demo';
import Calendly from '../../components/Calendly';
import RequestModal from '../../components/RequestModal';
import "./advantage.scss";
import "./bg.scss";

class Advantage extends Component {
    constructor(props) {
        super(props);
        const _randomNr1 = Math.floor(Math.random() * 10);
        const _randomNr2 = Math.floor(Math.random() * 10);

        this.state = {
            fullName: "",
            email: "",
            company: "",
            jobTitle: "",
            message: "",
            error: {},
            total_math: "",
            totalNr: _randomNr1 + _randomNr2,
            randomNr1: _randomNr1,
            randomNr2: _randomNr2,
            //modalOpenedCalendly: null,
            //modalOpenedDemo: null,
            // contactModal: false,

        };
        this.HandleChange = this.HandleChange.bind(this);

        this.hendlerCangeText = this.hendlerCangeText.bind(this);
    }
    HandleChange(e) {
        const { [e.target.name]: error } = this.checkValidation(
            Object.assign(this.state, { [e.target.name]: e.target.value })
        );
        this.setState({
            [e.target.name]: e.target.value,
            error: Object.assign(this.state.error, { [e.target.name]: error }),
        });
    }
    openModalRequestDemo = () => {

        this.setState({
            modalOpenedDemo: <RequestModal opened={true} close={this.closeModal} />,
        });

    };
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    closeModal = () => {
        this.setState({
            modalOpenedDemo: null,
        });
    };
    closeModal2 = () => {
        this.setState({
            contactModal: false,
        });
    };

    openModal2 = () => {
        this.setState({
            contactModal: true,
        });
    };



    openCalModal = () => {
        this.setState({
            modalOpenedCalendly: (<Calendly opened={true} close={this.closeModal3} />),
        });
    }

    closeModal3 = () => {
        this.setState({
            modalOpenedCalendly: null,
        });
    }

    closeModal(e) {
        if (e.target.id === 'reqModalBack') {
            this.props.close();
        }
    }
    detectSent = (bool) => {
        if (bool) {
            this.openCalModal();
        }

    }
    request = async () => {

        const error = this.checkValidation(this.state);
        if (error.success) {
            const bool = await sendDemo(this.state); //await Promise.resolve(true);//
            if (bool) {
                this.setState({
                    fullName: "",
                    email: "",
                    company: "",
                    jobTitle: "",
                    message: "",
                    error: {},
                    total_math: "",
                });
                if (this.detectSent) {
                    this.detectSent(true);
                }
            }
        } else {

            this.setState({ error: Object.assign(this.state.error, error) });
        }
    };
    hendlerCangeText(e) {
        const { name, value } = e.target;
        const { [e.target.name]: error } = this.checkValidation(
            Object.assign(this.state, { [e.target.name]: e.target.value })
        );
        if (typeof value === "string" && value.length <= 210) {
            this.setState({
                [name]: value,
                error: Object.assign(this.state.error, { [e.target.name]: error }),
            });
        }
    }
    checkValidation(state) {
        const {
            fullName,
            email,
            company,
            jobTitle,
            message,
            total_math,
            totalNr,
        } = state;
        const error = { success: true };
        const onlyNumbers = /^\+?[0-9]{0,17}$/gi;
        const regexpEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!fullName || fullName.length < 2) {
            error.fullName = true;
            error.success = false;
        } else {
            error.fullName = false;
        }

        if (!RegExp(regexpEmail).test(email.trim())) {
            error.email = true;
            error.success = false;
        } else {
            error.email = false;
        }

        if (!company) {
            error.company = true;
            error.success = false;
        } else {
            error.company = false;
        }
        if (!jobTitle) {
            error.jobTitle = true;
            error.success = false;
        } else {
            error.jobTitle = false;
        }

        return error;
    }
    mathCaptcha = () => {
        return (
            "I am not a robot. What is the sum of " +
            this.state.randomNr1 +
            " + " +
            this.state.randomNr2
        );
    };
    render() {
        const {
            fullName,
            email,
            company,
            jobTitle,
            message,
            total_math,
        } = this.state;
        const {
            fullName: RfullName,
            email: Remail,
            company: Rcompany,
            jobTitle: RjobTitle,
            message: Rmessage,
            total_math: Rtotal_math,
        } = this.state.error;

        const math_captcha = this.mathCaptcha();
        const { modalOpenedDemo } = this.state;
        const { modalOpenedCalendly } = this.state;
        return (
            <div className="app-bootstrap-support advantage-page">
                <div className="container-fluid bg_image_adv ">
                    <div className="cover_header"></div>
                    <div className="container  header">

                        <div className="row ">
                            <div className="col-md">
                                <div className="topForm">
                                    <div className="formHead">
                                        <p>
                                            <span>Schedule a free private demo to learn how Ballbutton can take your club to the next level.</span>
                                        </p>
                                    </div>
                                    <div className="formBody">

                                        <div className="form-group">
                                            <label htmlFor="fullName">Full Name *</label>
                                            <input className={`${RfullName ? "error" : ""}`}
                                                type="text"
                                                name="fullName"
                                                onChange={this.HandleChange}
                                                value={fullName} required />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="email">Email Address *</label>
                                            <input className={`${Remail ? "error" : ""}`}
                                                type="text"
                                                name="email"
                                                onChange={this.HandleChange}
                                                value={email} required />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="businessName">Club Name *</label>
                                            <input className={`${Rcompany ? "error" : ""}`}
                                                type="text"
                                                name="company"
                                                onChange={this.HandleChange}
                                                value={company} required />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="jobTitle">Job Title *</label>
                                            <input className={`${RjobTitle ? "error" : ""}`}
                                                type="text"
                                                name="jobTitle"
                                                onChange={this.HandleChange}
                                                value={jobTitle} required />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="message">Message (Optional)</label>
                                            {/* <textarea className={"large-input"} */}
                                            <textarea 
                                                className={`large-input optional-field ${Rmessage ? "error " : ""}`}
                                                type="text"
                                                id="messageId"
                                                name="message"
                                                maxLength="210"
                                                onChange={this.hendlerCangeText}
                                                value={message}></textarea>
                                            <span className="char-count-adv">
                                                {message ? message.length : 0} / {message ? 210 - message.length : 210}
                                            </span>
                                        </div>
                                        <div className="form-group">
                                            <button className="button_c" type="button" onClick={this.request}>SCHEDULE PRIVATE DEMO</button>
                                            {modalOpenedCalendly}
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="col-md">
                                <h1>All-in-one member management—for clubs of any size</h1>
                                <br />
                                <p>
                                    <span>Learn how Ballbutton is making memberships management and club operations easier than ever.</span>
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="container content_page">
                    <div className="row">
                        <div className="col-md">
                            <h1>Ballbutton is an all-in-one solution for managing memberships, payments, communications, social engagement and more.</h1>
                            <div className="hr"></div>
                        </div>
                    </div>
                    <div className="row margin_top">
                        <div className="col-md">
                            <div className="content_item">
                                <div>
                                    <img src="/assets/img/adv-people.png" alt="Memberships" />
                                </div>
                                <h2>Memberships</h2>
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="content_item">
                                <div>
                                    <img src="/assets/img/adv_ticket.png" alt="ubscriptions & Billing" />
                                </div>
                                <h2>Subscriptions & Billing</h2>
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="content_item">
                                <div>
                                    <img src="/assets/img/Initiations.png" alt="Initiations" />
                                </div>
                                <h2>Initiations</h2>
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="content_item">
                                <div>
                                    <img src="/assets/img/adv-Payments.png" alt="Automated Payments" />
                                </div>
                                <h2>Automated Payments</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row margin_top">
                        <div className="col-md">
                            <div className="content_item">
                                <div>
                                    <img src="/assets/img/Member-Social-Feed.png" alt="Member-Social-Feed" />
                                </div>
                                <h2>Member Social Feed </h2>
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="content_item">
                                <div>
                                    <img src="/assets/img/Check-Ins.png" alt="Check-Ins" />
                                </div>
                                <h2>Check-Ins</h2>
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="content_item">
                                <div>
                                    <img src="/assets/img/MemberEmails.png" alt="Member Emails" />
                                </div>
                                <h2>Member Emails</h2>
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="content_item">
                                <div>
                                    <img src="/assets/img/adv-Support.png" alt="24/7 Support" />
                                </div>
                                <h2>24/7 Support</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row margin_top">
                        <div className="col-md"></div>
                        <div className="col-md">
                            <button onClick={this.openModalRequestDemo} className="button_c learn_more_btn">LEARN MORE</button>

                        </div>
                        <div className="col-md"></div>
                    </div>
                    <div className="row margin_top mobile_section">
                        <div className="col-md">
                            <img src="/assets/img/adv-mobile1.png" alt="ballbutton" />
                        </div>
                        <div className="col-md">
                            <div className="text_content">
                                <h1>Seamlessly manage and grow your club </h1>
                                <p>Ballbutton's membership management and analytics capabilities help you streamline club operations and deliver a superior member experience.</p>
                                <p>Ballbutton is flexible, customizable, and easy to use—and our 24/7 customer support team is always on standby to help!</p>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="container-fluid ">
                    <div className="row mobile_section_bg margin_top">
                        <div className="container ">
                            <div className="row  " >
                                <div className="col-md">
                                    <div className="text_content">
                                        <h1>Easily manage members, subscriptions, and payments</h1>
                                        <p>Keep detailed member profiles and manage communications via direct and group emails.</p>
                                        <br />
                                        <p>Automate reminders, track activity, and manage check-ins, payments, subscriptions, initiations & more!</p>
                                    </div>
                                </div>
                                <div className="col-md">
                                    <img src="/assets/img/adv-mobile2.png" alt="ballbutton" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container content_page2">
                    <div className="row  mobile_section_2">
                        <div className="col-md">
                            <img src="/assets/img/adv-mobile3.png" alt="ballbutton" />
                        </div>
                        <div className="col-md">
                            <div className="text_content">
                                <h1>Your Club's Own News Feed</h1>
                                <br />
                                <p>Take advantage of our one-of-a-kind Members News Feed.</p>
                                <br />
                                <p>Keep your members engaged with your club!</p>
                                <br />
                            {/* <p>Even if you are already managing your club on any software and don’t want to switch, then fine, just ad-on the social engagement via Ballbutton Membership Advantage!
                                <br /> <span className="orange_font"> Fine if you have a “fridge.” Go bold, add a “wine fridge”!!! </span></p>*/}
                            </div>
                        </div>
                    </div>
                    <div className="row margin_top">
                        <div className="col-md"></div>
                        <div className="col-md">
                            <button onClick={this.openModalRequestDemo} className="button_c learn_more_btn">SCHEDULE PRIVATE DEMO</button>
                            {modalOpenedDemo}
                        </div>
                        <div className="col-md"></div>
                    </div>
                    <div className="row margin_top mobile_section_3">
                        <div className="col-md">
                            <h1>Competitive pricing for clubs of every size</h1>
                            <img src="/assets/img/adv-tenis.jpeg" alt="ballbutton" />
                        </div>
                        <div className="col-md">
                            <center>
                                <span style={{ color: "red" , fontSize:"20px"}}>Limited Time Offer: $149/month! Schedule your private demo now! </span>
                            </center>
                            <br />
                            <div className="plan plan-blue">
                                <div className="inner-plan">
                                    <div className="plan-title">
                                        <h2>Advantage</h2>
                                        <span></span>
                                    </div>
                                    <div className="inner-pricing-title">
                                        {/*<span className="currency">$</span>
                                        <span className="price discount">199
                                            <span></span>
                                        </span>
                                        <span className="discountSale">149</span>
                                        <span className="duration">
                                            <span className="line">/</span>mo
                                        </span>
        <p className="discountAlert">Limited Time Special</p>*/}
                                    </div>
                                    <div className="features-list">
                                        <div className="each-feature">
                                            <span>Membership management tool <br />A to Z: Memberships, Subscriptions, Initiations, Automated Payments, Billing, Checkins and more!</span>
                                        </div>
                                        <div className="each-feature">
                                            <span>One-of-a-kind modules: Members' News Feed!!</span>
                                        </div>
                                        <div className="each-feature">
                                            <span>Unlimited staff profiles & accounts</span>
                                        </div>
                                        <div className="each-feature">
                                            <span>Unlimited members</span>
                                        </div>
                                        <div className="each-feature">
                                            <span>50,000 marketing emails each month,then $1 for each additional 1,000 emails</span>
                                        </div>
                                        <div className="each-feature">
                                            <span>Free BallButton mobile app for your members</span>
                                        </div>
                                    </div>
                                    <div className="radius-circle"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <hr className="line" />
                <div className="container content_page3 margin_top">
                    <div className="row ">

                        <div className="col-md">
                            <h1>Schedule your demo today to discover what makes Ballbutton the preferred choice of modern clubs.</h1>
                            <div className="custom_border"></div>
                            <button onClick={this.openModalRequestDemo} className="button_c learn_more_btn">SCHEDULE A FREE PRIVATE DEMO</button>

                        </div>

                    </div>
                </div>
            </div >
        )

    }
}

export default Advantage;
