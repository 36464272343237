import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import RequestDemoCalendly from "../../components/RequestDemoCalendly";
import PromoModal from "../Promo/promo-modal";
import { connect } from "react-redux";

import {
  changePlanId,
  setDemoUrlCode,
  checkDemoCode,
} from "../../actions/Auth";

import "./pricing.scss";
// import { PRICING_PLANS } from "../../utils/constants";

class Pricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exist_code: false,
      openedModal: null,
    };
  }

  async componentDidMount() {
    const _code = this.props.match.params.code;

    window.scrollTo(0, 0);

    if (_code) {
      const _exists = await checkDemoCode(_code);
      if (_exists) {
        this.setState({
          exist_code: true,
        });
        this.props.setDemoUrlCode(_code);
      }
    }
  }

  selectPlanId(planId) {
    return () => {
      this.props.changePlanId(planId);
      this.props.history.push("/sign-up");
    };
  }

  openPromoModal = (plan) => {
    this.setState({
      openedModal: (
        <PromoModal
          plan={plan}
          selectPlanId={this.props.selectPlanId}
          closeModal={this.closeModal}
        />
      ),
    });
  };

  closeModal = () => {
    this.setState({
      openedModal: null,
    });
  };

  render() {
    const { exist_code, openedModal } = this.state;
    const { update_plan, hideFeaturesList } = this.props;
    const query = new URLSearchParams(this.props.location.search);
    const PRICING_PLANS = [
      // {
      //   name: "Ace",
      //   price: "299",
      //  // discount: "199",
      //   features: [
      //    // "Advantage plan PLUS",
      //    "Membership management tools AtoZ: Memberships, Subscriptions, Initiations, Automated Payments, Billing, Checkins and more",
      //     "One-of-a-kind modules: Members' News Feed",
      //     "Unlimited staff profiles & accounts",
      //     "Unlimited members",
      //     "50,000 marketing emails each month, then $1 for each additional 1,000 emails",
      //     "Free BallButton mobile app for your members",
      //     "Up to 8 bookable courts/bays/lanes",
      //     "Full access to all modules & features " + (!hideFeaturesList ? "(see listed below)" : ""),
      //   ],
      //   plan: "courtandlessonproviderplan",
      // },
      // {
      //   name: "Grand Slam",
      //   price: "499",
      //  // discount: "299",
      //   features: ["Ace plan PLUS", "Unlimited bookable courts/bays/lanes","Unlimited marketing emails"],
      //   plan: "enterprisecourtandlessonproviderplan",
      // },
      {
        name: "Grand Slam",
        price: "299",
       // discount: "199",
        features: [
         "Unlimited bookable courts/bays/lanes",
         "Unlimited marketing emails",
         "Unlimited staff profiles & accounts",
         "Unlimited members",
         "Membership management tools AtoZ: Memberships, Subscriptions, Initiations, Automated Payments, Billing, Checkins and more",
         "Free BallButton mobile app for your members",
         //"One-of-a-kind modules: Members' News Feed",
         "Unlimited access to all modules & features"
        ],
        plan: "courtandlessonproviderplan",
      },
    ];
    let plans = [
      // {
      //   name: "Advantage",
      //   price: "199",
      //   discount: "149",
      //   features: [
      //     "Membership management tools AtoZ: Memberships, Subscriptions, Initiations, Automated Payments, Billing, Checkins and more!",
      //     "One-of-a-kind modules: Members' News Feed!!",
      //     "Unlimited staff profiles & accounts",
      //     "Unlimited members",
      //     "50,000 marketing emails each month, then $1 for each additional 1,000 emails",
      //     "Both web and mobile app for your members",
      //   ],
      //   plan: "",
      //   classNames: "plan-blue",
      // },
      ...PRICING_PLANS,
      // {
      //   name: "Multi Grand Slam",
      //   price: "599",
      //   noImg: true,
      //   features: [
      //     `Grand Slam for multi-facility club full management \n(same location facilities: racquets, golf, swim, yoga, fitness, etc., combined)`,
      //   ],
      //   plan: "",
      // },
      // {
      //   name: "GOAT",
      //   price: "1,099",
      //   noImg: true,
      //   features: ["Multi-Grand Slam plus branded app"],
      //   plan: "",
      // },
    ];

    let update_plans = plans.filter((v) => v.plan !== "courtproviderplan");
    if (update_plan) {
      plans = update_plans;
    }
    if (query.get("ace")) {
      plans = update_plans.filter(
        (v) => v.plan !== "courtandlessonproviderplan"
      );
    }

    const all_features_list = [
      "Manage courts/bays/lanes (any type of courts/bays/lanes)",
      "Block any court/spot/lane and time",
      "Private lessons and group lessons (also recurring) of any type",
      "Advanced Settings: control who can book, duration, custom pricing, and many other",
      "Waitlist",
      "Analytics of business activity. Filter per court/spot/lane, coach, lesson type, member",
      "Multiple staff profiles, coach access levels and login",
      "Memberships and subscriptions",
      "Detailed profiles of members",
      "Groups and rosters",
      "Notification emails and reminders (automatic)",
      "Direct email to customer and bulk emails",
      "Payment processor (both online and card present) and POS",
      "Checkin system",
      "Web cloud software at www.ballbutton.com",
      // "Responsive screen-auto-adjust web view on mobile",
      "Mobile app Ballbutton and web account for your customers/members",
      "Dedicated customer support",
      "Month-to-month subscription. Cancel anytime.",
    ];
    return (
      <div className="pricing-page ">
        <br /> <br /> <br /> <br />
        <div className="pricePageScale">
          <div className="main-container">
            {!update_plan && (
              <div className="pricing-title">
                {/* <h2>Choose a plan that fits your business size and needs</h2> */}
                <h2>One Powerful And Simple Plan</h2>
                {/* <center>
                  <span style={{ color: "red", fontSize: "20px" }}>Limited Time Offer: $195/month for the Grand Slam plan! (Unlimited plan!) Schedule your private demo now! </span>
                </center> */}
                <br/>
              </div>
            )}
          </div>
          {/* <div className="plans-header">
            <div className="plans-header--item">
              <h4>Membership Management</h4>
            </div>
            <div className="plans-header--item">
              <h4>
                Full Club Management enterprise plans: spot bookings, classes,
                memberships, reports, payments, POS and more!
              </h4>
            </div>
          </div> */}
          <div
            className={update_plan ? "plans-list update-plan-list" : "plans-list"}
          >
            {plans.map((v, i) => (
              <div key={i} className={`plan ${v.classNames ? v.classNames : ""}`}>
                <div className="inner-plan">
                  <div className="plan-title">
                    <h2>{v.name}</h2>
                    <span></span>
                  </div>

                  
                    <div className="inner-pricing-title">
                      <span className="currency">$</span>
                      <span className={`price ${v.discount ? "discount" : ""}`} >
                        {v.price}
                        <span className={(v.discount ? "display_visible" : "")}></span>
                      </span>
                      <span className="discountSale">{(v.discount ? v.discount : "")}</span>
                      <span className="duration">
                        <span className="line">/</span>mo
                      </span>
                    </div>

                  <div className="features-list">
                    {v.features.map((v) => (
                      <div key={v} style={{ backgroundImage: plans[i].noImg ? "none" : "" }} className="each-feature">
                        {v.split('\n').map(str => <p>{str}</p>)}
                      </div>
                    ))}
                  </div>
                  {exist_code && v.plan && (
                    <div
                      className={`sign-up`}
                      onClick={this.selectPlanId(`${v.plan}`)}
                    >
                      Sign up Now
                    </div>
                  )}
                  {update_plan && v.plan && (
                    <div
                      className={`sign-up`}
                      onClick={() => this.openPromoModal(`${v.plan}`)}
                    >
                      Upgrade Now
                    </div>
                  )}
                  <div className="radius-circle"></div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="main-container">
          <div className="covid-section info-plans">
            <ul>
              <li>
                <span>
                Unlimited free training for your staff
                </span>
              </li>
              <li>
                <span>
                Your members will see only your club. No extra charge for that

                </span>
              </li>
              <li>
                <span>
                Multi-location and franchise plan pricing is available based on your business needs
                </span>
              </li>
            </ul>
          </div>
          {!update_plan && !hideFeaturesList && (
            <React.Fragment>
              <div className="all-features-list">
                <div className="pricing-title">
                  <h2>Features List</h2>
                </div>
                <div className="features-list-container">
                  <div className="inner-features-list-container">
                    <div className="inner-features-list">
                      {all_features_list.map((v, i) => (
                        <div key={v} className="each-feature">
                          {v}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="schedule-form-in-price" style={{ display: (this.props.hideSchedule ? `none` : `block`) }}>
                <RequestDemoCalendly />
              </div>
            </React.Fragment>
          )}
        </div>
        {openedModal}
      </div>
    );
  }
}

Pricing.propTypes = {
  //cntinue: PropTypes.func.isRequired,
  // changePlanId: PropTypes.func.isRequired,
  //planId: PropTypes.string.isRequired,
};
const mapStateToProps = (state) => {
  return {};
};
const mapDispachToProps = (dispach) => {
  return {
    changePlanId: (planId) => dispach(changePlanId(planId)),
    setDemoUrlCode: (code) => dispach(setDemoUrlCode(code)),
  };
};
export default withRouter(connect(mapStateToProps, mapDispachToProps)(Pricing));
