import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment-timezone";
import Picky from "react-picky";
import DatePicker from "react-datepicker";
import { getCoach } from "../../actions/Coach";
import { getCourts } from "../../actions/Courts";
import { bookCoachAvailability } from "../../actions/MicroSettings";
import { CoachBookRequest } from "../../actions/MicroSettings";
import { CoachBookAndPayRequest } from "../../actions/MicroSettings";
import { CoachSelect, LessonTypeSelect, SportSelect } from "../Business";
import {
  canBeBookedDuration,
  generateTimeListStartedFromByStep,
  getMaxDurationErrorText,
  getValidationData,
} from "../../utils/helpers";
import { getActiveFacilityGroup } from "../../utils/facility";
import AutoFill from "../../components/UI/AutoFill";
import { getCoachAvailabilityPrice, getPrice } from "../../actions/Stripe";
import { getCoachAvailabilityList } from "../../actions/MicroSettings";
import axiosInstant from "../../config/axios";
import { getActivePair } from "../../utils/facility";
import PaymentsModalNBU from "../../components/PaymentsModalNBU";

class BookAvailability extends Component {
  constructor(props) {
    super(props);

    const {
      court_id,
      user_id,
      coach_id,
      lesson_type_id,
      sport_id,
      group_id,
      start,
      end,
      start_time,
      end_time,
      currentDay,

      timeZone,
    } = props;


    let endTime = moment(end).tz(timeZone);
    let startTime = moment(start).tz(timeZone);


    this.state = {
      court: props.courtsList.find((i) => i.id == court_id) || {
        id: court_id,
        name: court_id,
      },
      coach_id: coach_id,
      lesson_type_id: lesson_type_id,
      sport_id: sport_id,
      startTime,
      endTime,
      start_time: moment(start_time).tz(timeZone),
      end_time: moment(end_time).tz(timeZone),
      currentDay: currentDay,
      loadingPage: true,
      coachAvailability: null,
      openedModal: null,
      price: {
        main_value: -1,
        point_value: -1,
        loaded: false,
        error: null,
      },
    };
    
  }

  async componentDidMount() {
    const startDate = this.props.start;
    const endDate = this.props.end;
    const coach_id = this.props.coach_id;
    const lesson_type_id = this.props.lesson_type_id;
    const facilityGroup = this.props.facilityGroup;

    // await this.props.getCoachAvailabilityList();

    const res = await axiosInstant.post("appointment/listing-coach", {
        startDate,
        endDate,
        coach_id: coach_id != "-1" ? coach_id : undefined,
        lesson_type_id: lesson_type_id != "-1" ? lesson_type_id : undefined,
        facilityGroup: facilityGroup?.id,
      });

    res.data.payload.map((item) => {
      if (item.id == this.props.court_id) {
        this.setState((st) => ({
          ...st,
          coachAvailability: item,
        }));
      }
    })

    // Get Court List
    await this.props.getCourts();
    //Get Coach List
    //await this.props.getCoach();

    this.setState((st) => ({
      ...st,
      loadingPage: false,
    }));

    
    this.state.endTime = this.normalizeByMin(this.state.startTime)
    this.handleGetPriceForRange();
  }

  render() {
    const {
      className,
      onClose,
      user,
      business,
      timeStep,
      timeZone,
      minDuration,
      courtsList,
    } = this.props;

    const {
      court,
      coach_id,
      lesson_type_id,
      sport_id,
      startTime,
      endTime,
      start_time,
      end_time,
      loadingPage,
      price,
      openedModal,
    } = this.state;

    const min_date = start_time.clone().tz(timeZone);

    const injectTimes = generateTimeListStartedFromByStep(min_date, timeStep);
    const timeIntervals = 24 * 60;
    const sportCategoryId = getActiveFacilityGroup()?.sport_category_id;
    const userType = this.props.user.member_type?.key || "owner";

    const is_owner = userType === "owner";
    const is_admin = ["owner", "admin"].includes(userType);
    const is_coach = ["coach", "supercoach"].includes(userType);
    const is_nbu = this.props.user.user_type === "nbu";
    const [activeLocation, activeFacilityGroup] = getActivePair();
    const allow_to_pay_in_person = activeFacilityGroup ? activeFacilityGroup.allow_to_pay_in_person : true;
   
    return (
      <div className={`modal modal-availability active ${className}`}>
        <div className="modal-content">
          <div className="modal-header">
            <div className="close-btn">
              <button onClick={onClose}>
                <img src="/assets/img/cancel.png" alt="cancel" />
              </button>
            </div>
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if(!is_nbu){
                this.handleSubmit();
              }
            }}
            className="lessonViewPage courtlisting-div"
          >
            <div className={is_owner || is_admin ? "disabled-div" : "disabled-div"}>
              <div className="rec-form">
                <div className="form-group lesson-form-group">
                  <label>
                    <span className="important-span">*</span>&nbsp; Sport
                  </label>
                  <div className="picky-container">
                    <SportSelect
                      sportCategoryId={sportCategoryId}
                      value={{
                        id: sport_id,
                      }}
                      onChange={(opt) =>
                        this.handleChange({
                          target: { name: "sport_id", value: opt.id },
                        })
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="rec-form">
                <div className="form-group lesson-form-group">
                  <label>
                    <span className="important-span">*</span>&nbsp; Lesson Type
                  </label>
                  <div className="picky-container">
                    <LessonTypeSelect
                      value={{
                        id: lesson_type_id,
                      }}
                      onChange={(opt) =>
                        this.handleChange({
                          target: { name: "lesson_type_id", value: opt.id },
                        })
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="rec-form">
                <div className="form-group lesson-form-group">
                  <label>
                    <span className="important-span">*</span>
                    &nbsp;Coach
                  </label>
                  <div className="picky-container">
                    <CoachSelect
                      value={{
                        id: coach_id,
                      }}
                      onChange={(opt) =>
                        this.handleChange({
                          target: { name: "coach_id", value: opt.id },
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="rec-form">
                <div className="form-group lesson-form-group">
                  <label>
                    <span className="important-span">*</span>
                    &nbsp;Facility
                  </label>
                  <div className="picky-container">
                    <Picky
                      options={courtsList}
                      value={court}
                      valueKey="id"
                      labelKey="name"
                      includeFilter={true}
                      onChange={(opt) =>
                        this.handleChange({
                          target: { name: "court", value: opt },
                        })
                      }
                      dropdownHeight={350}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="rec-form">
              <div className="form-group lesson-form-group">
                <label>
                  <span className="important-span">*</span>&nbsp; Start
                </label>
                <div className="picky-container">
                  <DatePicker
                    selected={startTime}
                    onChange={(date) => {
                      this.handleChange({
                        target: {
                          name: "startTime",
                          value: date,
                        },
                      });
                      setTimeout(() => this.handleGetPriceForRange(), 1000);
                    }}
                    showTimeSelect
                    showTimeSelectOnly
                    minTime={min_date}
                    maxTime={end_time.clone()}
                    popperPlacement="bottom-end"
                    timeIntervals={timeIntervals}
                    dateFormat="MMM D, YYYY  h:mma"
                    timeCaption="Time"
                    injectTimes={injectTimes}
                  />
                </div>
              </div>
            </div>
            <div className="rec-form">
              <div className="form-group lesson-form-group">
                <label>
                  <span className="important-span">*</span>&nbsp; End
                </label>
                <div className="picky-container">
                  <DatePicker
                    selected={endTime}
                    onChange={(date) => {
                      this.handleChange({
                        target: {
                          name: "endTime",
                          value: date,
                        },
                      });

                      setTimeout(() => this.handleGetPriceForRange(), 1000);
                    }}
                    showTimeSelect
                    showTimeSelectOnly
                    minTime={moment(startTime)
                      .tz(timeZone)
                      .add(minDuration, "minutes")}
                    maxTime={end_time.clone()}
                    popperPlacement="bottom-end"
                    timeIntervals={timeIntervals}
                    dateFormat="MMM D, YYYY  h:mma"
                    timeCaption="Time"
                    injectTimes={injectTimes}
                  />
                </div>
              </div>
            </div>
            <div className="rec-form d-flex" style={{ width: "100%" }}>
              <div
                style={{ display: "block", width: "100%", textAlign: "left" }}
              >
                {price.loaded && !price.error ? (
                  <b>
                    {price.main_value != -1
                      ? `Price: ${price.main_value
                      } ${business.currency_code?.toUpperCase()}`
                      : ""}
                    <br />

                    {price.point_value != -1
                      ? `Point Price: ${price.point_value}`
                      : ""}
                  </b>
                ) : null}

                {price.loaded && price.error ? <b>{price.error}</b> : null}
                {!price.loaded ? <b>Calculating price...</b> : null}
              </div>
            </div>
            {!is_nbu && (
              <div className="rec-form d-flex" style={{ width: "100%" }}>
                <div className="autofills">
                  <AutoFill
                    timezone={timeZone}
                    currentRecord={undefined}
                    outerState={{}}
                    is_nbu={false}
                    show_book={true}
                    user={user}
                    button_name={"Book"}
                    is_lesson={true}
                    getPrice={getPrice}
                    handleBook={this.handleSubmit}
                  />
                </div>
              </div>
            )}
            <div className="d-flex justify-center">
            <div className="pretty-button">
              <button className="btn mr-2 red-button" type="button" onClick={onClose}>
                Cancel
              </button>
              </div>
              {is_nbu && (price.loaded && !price.error &&(allow_to_pay_in_person || price==0 )) &&(
                <div style={{marginLeft:"3px"}} className="pretty-button">
                <button
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                   this.hendleCoachBookRequest();
                  }}
                  className="btn green-button"
                  style={{ padding: "5px 20px" }}
                  disabled={loadingPage}
                 
                >
                Book and Pay in Person
                </button>
              </div>
              )}
             
              {is_nbu && this.props?.user?.business?.payment_account_id!=null && price?.main_value>0 &&(
                <div style={{marginLeft:"3px"}} className="pretty-button">
                <button
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                   
                   this.hendleCoachBookAndPayRequest();
                  }}
                  className="btn green-button"
                  style={{ padding: "5px 20px" }}
                  disabled={loadingPage}
                 
                >
                  Book and Authorize Payment
                </button>
              </div>
              )}
            </div>
          </form>
        </div>
        {openedModal}
      </div>
    );
  }

  handleGetPriceForRange = async () => {
    try {
      this.setState((st) => ({
        ...st,
        price: {
          ...st.price,
          loaded: false,
        },
      }));
      const {
        court,
        coach_id,
        lesson_type_id,
        sport_id,
        startTime,
        endTime,
      } = this.state;
      let reqData={}
     
      if(this.props.family){
       reqData={
        startDate: startTime.clone().utc().format(),
        endDate: endTime.clone().utc().format(),
        court_id: court.id,
        coach_id: coach_id,
        sport_id: sport_id,
        user_id:this.props.family,
        lesson_type_id: lesson_type_id,
        for: "price",
      }
      }else{
        reqData={
          startDate: startTime.clone().utc().format(),
          endDate: endTime.clone().utc().format(),
          court_id: court.id,
          coach_id: coach_id,
          sport_id: sport_id,
          lesson_type_id: lesson_type_id,
          for: "price",
        }
      }
      const { main_value, point_value } = await getCoachAvailabilityPrice(reqData);

      this.setState((st) => ({
        ...st,
        price: {
          ...st.price,
          loaded: true,
          main_value,
          point_value,
          error: null,
        },
      }));
    } catch (e) {
      const error =
        "This session is not available at the selected time anymore.";
      this.setState((st) => ({
        ...st,
        price: {
          ...st.price,
          loaded: true,
          error,
        },
      }));

      window.modal.alert("Error", error);
    }
  };

  handleSubmit = async (memberId = null, data = {}) => {
    if (this.checkIsTimeRangeValid()) {
      const { user } = this.props;
      const {
        court,
        coach_id,
        lesson_type_id,
        sport_id,
        startTime,
        endTime,
      } = this.state;

      try {
        let promise;
        promise = this.props.bookCoachAvailability({
          start_time: startTime.clone().utc().format(),
          end_time: endTime.clone().utc().format(),
          force: false,
          coach_id: coach_id,
          sport_id: sport_id,
          lesson_type_id: lesson_type_id,
          court_id: court.id,
          userId: memberId ? memberId : user.id,
          fullName: memberId
            ? data.userFullName
            : `${user.first_name} ${user.last_name}`,
        });

        if (await promise) {
          this.props.onClose();
          window.modal.alert("Success", "Your booking successfully created.");
        }
      } catch (e) {
        window.modal.alert("Error", e.message);
      }
    }
  };
  hendleCoachBookRequest = async ()=>{
    if (this.checkIsTimeRangeValid()) {
      const { user,business } = this.props;
      const {
        court,
        coach_id,
        lesson_type_id,
        sport_id,
        startTime,
        endTime,
      } = this.state;

      try {
        let promise;
        promise = this.props.CoachBookRequest({
          startDate: startTime.clone().utc().format(),
          endDate: endTime.clone().utc().format(),
          sport_id: sport_id,
          business_id:business?.id,
          courts:[court.id],
          coach_id: coach_id,
          lesson_type_id:lesson_type_id,
          partners:[],
          guests:[],
          userId: this.props.family?this.props.family: user?.id,
        });

        if (await promise) {
          this.props.onClose();
          window.modal.alert("Success", "Your booking successfully created.");
        }
      } catch (e) {
        window.modal.alert("Error", e.message);
      }
    }
  }
  hendleCoachBookAndPayRequest = async ()=>{
    if (this.checkIsTimeRangeValid()) {
      const { user,business } = this.props;
      const {
        court,
        coach_id,
        lesson_type_id,
        sport_id,
        startTime,
        endTime,
        price,
        currentRecord
      } = this.state;
      
      const appointmentId = currentRecord?.appointment_id;
      let default_price = price ? price.main_value : 0;
      let default_point = currentRecord ? currentRecord.point_price : 0;
      let payment_account_id = this.getPaymentAccount();
      this.setState({
        openedModal: (
          <PaymentsModalNBU
            from={{
              hide_subtotal: true,
            }}
            lessonId={null}
            appointmentId={appointmentId}
            isRecurrence={currentRecord?.allowBooking === "recurrence"}
            default_price={default_price}
            default_point={default_point}
            payment_account_id={payment_account_id}
            cbCharge={(data) => this.bookAndPay(data)}
            user={user}
            book_pay_type={
              "1"
            }
            clouseModal={this.closeModal}
          />
        ),
      });
      
      
    }
  }
  bookAndPay = async  (data)=>{
    const { user,business ,user_id} = this.props;
      const {
        court,
        coach_id,
        lesson_type_id,
        sport_id,
        startTime,
        endTime,
        price,
        currentRecord
      } = this.state;
    
    try {
      let promise;
      if(data?.token){
        promise = this.props.CoachBookAndPayRequest({
          startDate: startTime.clone().utc().format(),
          endDate: endTime.clone().utc().format(),
          courts:[court.id],
          business_id:business?.id,
          amount:data?.amount,
          currency: data?.currency,
          token: data?.token,
         // customer:null,
          userId: this.props.family?this.props.family:user_id,
          splitPayment:false,
          save_card:data?.save_card,
          method:data?.method,
          sport_id: sport_id,
          coach_id: coach_id,
          lesson_type_id: lesson_type_id
        });
      }else{
        promise = this.props.CoachBookAndPayRequest({
          startDate: startTime.clone().utc().format(),
          endDate: endTime.clone().utc().format(),
          courts:[court.id],
          business_id:business?.id,
          amount:data?.amount,
          currency: data?.currency,
         // token: data?.token,
         // customer:null,
          userId: this.props.family?this.props.family:user_id,
          splitPayment:false,
          save_card:data?.save_card,
          method:data?.method,
          sport_id: sport_id,
          coach_id: coach_id,
          lesson_type_id: lesson_type_id
        });
      }
      

      if (await promise) {
        this.props.onClose();
        window.modal.alert("Success", "Your booking successfully created.");
      }
    } catch (e) {
      window.modal.alert("Error", e.message);
    }
  }
  closeModal = () => {
    this.setState({ openedModal: null });
  };
  getPaymentAccount = () => {
    const { user, family_member, is_nbu } = this.props;
    let payment_account_id;
    if (is_nbu) {
      payment_account_id =
        user && user.business ? user.business.member.payment_account_id : null;
      payment_account_id =
        family_member && family_member.payment_account_id
          ? family_member.payment_account_id
          : !family_member
            ? payment_account_id
            : family_member.payer.payment_account_id;
    }
    return payment_account_id;
  };
  normalizeByMin = (startTime)=>{
    const { business } = this.props;
    const {court} = this.state
    const validationUsersCount = 1;
    
    if (this.state.coachAvailability!=null && this.state.coachAvailability.lesson_is_min_max_enabled) {
      return startTime.clone().add(this.state.coachAvailability.lesson_type_min_list_duration, "minutes")
    }
    const validationData = getValidationData(
      [court],
      business,
      validationUsersCount
    );
 
    const minTime = (validationData.min_list_duration);
    
      return startTime.clone().add(minTime, "minutes")
  }

  checkIsTimeRangeValid = () => {
    const { business, user } = this.props;
    const { startTime, endTime, coachAvailability } = this.state;
    const is_nbu = user.user_type === "nbu";
    if(!is_nbu){
      return true
    }
    const duration = moment.duration(moment(endTime).diff(startTime));
    const hours = duration.asHours();
    const deff = moment.duration(endTime.diff(startTime)).asMinutes();

    if (this.state.coachAvailability.lesson_is_min_max_enabled) {

      if (this.state.coachAvailability.lesson_type_max_list_duration > 0 && deff > this.state.coachAvailability.lesson_type_max_list_duration) {
        window.modal.alert("Notice", "The business requires maximum " + this.state.coachAvailability.lesson_type_max_list_duration + " minutes per booking ");
        return false
      }
      if (deff < this.state.coachAvailability.lesson_type_min_list_duration) {
        window.modal.alert("Notice", "Lesson min duration is " + this.state.coachAvailability.lesson_type_min_list_duration + " minutes");
        return false
      }
      return true
    }

    const validationUsersCount = 1;
    const validationDurationInHour = hours;

    const validationData = getValidationData(
      [this.state.court],
      business,
      validationUsersCount
    );

    if (
      !canBeBookedDuration(
        validationData,
        validationUsersCount,
        validationDurationInHour
      )
    ) {
      window.modal.alert( "Notice", getMaxDurationErrorText(validationData, validationUsersCount) );
      return false;
    }
    return true;
  };

  handleChange = (e) => {
    const {
      target: { name, value },
    } = e;

    this.setState((st) => ({
      ...st,
      [name]: value,
    }));
  };
}

const mapStateToProps = (state) => {
  return {
    business: state.auth.user.business,
    timeZone: state.auth.user.business.timezone,
    courtsList: state.courts.data,
    timeStep: state.auth.user.business.booking_to_start,
    minDuration: state.auth.user.business.min_list_duration,
    user: state.auth.user,
    currentRecord: state.lesson.currentLesson,
    coachAvailabilityList: state.micro_settings.coachAvailability.list
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCoach: () => dispatch(getCoach()),
    getCourts: () => dispatch(getCourts()),
    getCoachAvailabilityList: (data) =>
     dispatch(getCoachAvailabilityList(data)),
    bookCoachAvailability: (data) => dispatch(bookCoachAvailability(data)),
    CoachBookRequest: (data) => dispatch(CoachBookRequest(data)),
    CoachBookAndPayRequest: (data) => dispatch(CoachBookAndPayRequest(data)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BookAvailability)
);
