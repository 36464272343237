import React, { Component } from "react";
import _ from "lodash";
import Picky from "react-picky";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import {
  createMicroSettings,
  getMicroSettings,
  resetMicroSetting,
  updateMicroSettings,
} from "../../actions/MicroSettings";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import WhoCanBook from "../../components/WhoCanBook";
import DropdownFilters from "../../components/DropdownFilters";
import BookedBy from "../../components/BookedBy";
import BookPayType from "../../components/UI/BookPayType";
import { TagsSelect } from "../../components/Tags";

import Repeat from "../../components/RepeatLesson";
import { SportSelect } from "../Business";
import { getActiveFacilityGroup } from "../../utils/facility";
import {
  generateTimeListStartedFromByStep,
  roundDate,
  getSplitRange,
} from "../../utils/helpers";
import { GLOBAL_LOCATION_FACILITY_PAIR } from "../../utils/constants";

const tagDefaultValue = { id: -1, display_name: "None" };

class MicroSettings extends Component {
  constructor(props) {
    super(props);

    this.moment = function (...args) {
      return moment.tz(...args, props.timeZone);
    };

    this.moment.__proto__ = moment;

    this.currentDay =
      moment.tz(props.currentDay, props.timeZone) || this.moment();

    this.state = {
      seditableId: this.props.editableId,
      duplicate_text: "Duplicate",
      hasDataInClipboard: !!this.getClipboardData(),
      who_can_book: [],
      isDuplicate: false,
      who_can_book_filters: {},
      book_pay_type: "1",
      booked_by: [],
      // excludeTimes: [],
      note: "",
      repeatState: null,
      coach: [],
      tag: tagDefaultValue,
      data: {
        weekDays: [],
        courts: [],
        structure: [
          {
            id: 1,
            time_range: {
              start: undefined,
              end: undefined,
            },
            book_advance: {
              count: 365,
              type: "day",
            },
            buffer:0,
            price: 0,
            point_price: 0,
          },
        ],
      },
      sport_id: -1,
    };
    this.handleChangeStart = this.handleChangeStart.bind(this);
    
  }

  onChangeBookedBy = (booked_by) => {
    this.setState({
      booked_by: booked_by,
    });
  };

  selectTag = (value) => {
    this.setState({ tag: value });
  };

  addRow = () => {
    const { fromCourt } = this.props;
    const last = this.state.data.structure.last();
    const start = last.time_range.end.clone();
    const end = start.clone().add(this.props.timeStep, "minutes");

    const item = {
      id: last.id + 1,
      time_range: {
        start,
        end,
      },
      book_advance: {
        count: 365,
        type: "day",
      },
      buffer:0,
      price: 0,
      point_price: 0,
    };
   
    this.setState((state) => {
      const newStructure = [...state.data.structure, item];

      return {
        ...state,
        // excludeTimes,
        data: {
          ...state.data,
          structure: newStructure,
        },
       
      };
    });
  };

  onChangeBookPayType = (changed) => {
    this.setState({
      book_pay_type: changed,
    });
  };

  selectDay(indexOfDey) {
    const {
      data: { weekDays },
    } = this.state;

    const newArrey = weekDays.includes(indexOfDey)
      ? weekDays.filter((item) => item !== indexOfDey)
      : [indexOfDey, ...weekDays];

    this.setState((state) => ({
      ...state,
      data: {
        ...state.data,
        weekDays: newArrey,
      },
    }));
  }
  getCookie=(cname)=> {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return false;
  }
  deleteCookie = (name, path, domain) => {
    if (this.getCookie(name)) {
      document.cookie = name + "=" +
        ((path) ? ";path=" + path : "") +
        ((domain) ? ";domain=" + domain : "") +
        ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
    }
  }
  setCookie = (cname, cvalue, exdays) => {
   
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));

    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
  handelSave = async (e) => {
    e.preventDefault();
    const { paging } = this.props;
    const {
      data,
      note,
      who_can_book,
      sport_id,
      who_can_book_filters,
      booked_by,
      tag,
      coach,
      book_pay_type,
      repeatState,
    } = this.state;
    
    this.setCookie("expiredRulesId__New" +this.props?.user?.uid, null, 7)
    try {
      if (sport_id == -1 || !who_can_book.length)
        throw new Error("Fill all required fields");
    } catch {
      return window.modal.alert("Please fill all required fields.");
    }

    const item = {
      courts: this.props.editable
        ? [this.props.court_id]
        : data.courts.map((crt) => crt.id),
      rules: [],
    };

    const existedDates = [];

    const BreakException = {};
  
    try {
      data.structure.forEach((rule) => {
        const { start, end } = rule.time_range;
       
        if (start.isSame(end) || moment(moment(this.currentDay.clone()).set('hour', start.format("HH")).set('minute', start.format("mm")).format()).isAfter(moment(moment(this.currentDay.clone()).set('hour', end.format("HH")).set('minute', end.format("mm")).format()))) {
        
          if(end.format("HH")!="00" && end.format("mm")!="00" )
          throw BreakException;
        }
        

        end.set("year", start.year());
        end.set("month", start.month());
        end.set("date", start.date());

        const minDate = this.getMinDate();

        minDate.set("year", start.year());
        minDate.set("month", start.month());
        minDate.set("date", start.date());
        
        const splitRange = getSplitRange(
          start,
          end,
          this.props.timeStep,
          minDate
        );
         
        /// some if
        splitRange.forEach((selectedDate) => {
          
          if (!!existedDates.find((existedDate) => {
              return selectedDate.isSame(existedDate);
            })
          ) {
            throw BreakException;
          }
        });

        existedDates.push(...splitRange);
       
       // (property=="end" && moment(date).format("HH:mm")=="00:00"?moment(date).add(1,"day"):date)
        item.rules.push({
          days: data.weekDays,
          start_time: moment(rule.time_range.start).seconds(0).milliseconds(0)
            //.clone()
            //.utc()
            .format(),
          end_time:(moment(rule.time_range.end).format("HH:mm")=="00:00"?moment(rule.time_range.end).add(1,"day").seconds(0).milliseconds(0):moment(rule.time_range.end).seconds(0).milliseconds(0))  
            //.clone()
            //.utc()
            .format(),
          price: book_pay_type && book_pay_type === "2" ? 0 : +rule.price,
          point_price:
            book_pay_type && book_pay_type === "1"
              ? 0
              : rule.point_price
                ? parseFloat(rule.point_price)
                : 0,
          advance: +rule.book_advance.count,
          buffer: +rule.buffer,
          book_pay_type: book_pay_type ? book_pay_type : "1",
        });
      });
    } catch (e) {
      if (e !== BreakException) throw e;
      return window.modal.alert(
        "Please Check Start and End Days/Times"
      );
    }

    let body = who_can_book;
    body = body.map((v) => {
      return {
        ...v,
        ...item,
      };
    });
    body = body.map((v) => {
      return { ...v, filters: who_can_book_filters };
    });

    const is_for_public =
      body.length > 0 ? body["0"]["for"] === "public" : false;
    if (is_for_public && !this.props.isConnected) {
      return window.modal
        .alert(`If you make bookings available to public, you will need to activate the 
                                       payment integration (Stripe) to enable anyone to Book & Pay.
                                       We will require 24 hours advance cancellation, will charge the customers 24 hours  
                                       before the event and will eliminate the risk of no shows for your business!`);
    }
    const defaultRange = item["rules"][0];

    let repeatData;
    if (repeatState) {
      const repeat = repeatState;
      const repeatType = {
        DAYLY: "DAYLY",
        WEEKLY: "WEEKLY",
        BI_WEEKLY: "BI-WEEKLY",
        MONTHLY: "MONTHLY",
      };
      const _start = repeat.starts
        ? repeat.starts.clone().utc().format()
        : defaultRange.start_time;
      const _end = defaultRange.end_time;

      repeatData = {
        frequency:
          repeat.repeatType === repeatType.WEEKLY ||
            repeat.repeatType === repeatType.BI_WEEKLY
            ? "weekly"
            : repeat.repeatType === repeatType.MONTHLY
              ? "monthly"
              : "daily",
        occurrences:
          repeat.endstype === "occurrence"
            ? parseInt(repeat.endCountSelect)
            : 1,
        ends: repeat.repeatOn
          ? repeat.endstype === "dataPicker"
            ? repeat.ends.add(1, "day").startOf("day").utc().format()
            : null
          : null,
        end_time: _end,
        days:
          repeat.repeatType === repeatType.MONTHLY
            ? [this.currentDay.clone().date()]
            : repeat.repeatType === repeatType.WEEKLY ||
              repeat.repeatType === repeatType.BI_WEEKLY
              ? repeat.weekDays.map((item) => item)
              : [],
        start_date: _start,
        start_time: _start,
        every: repeat.repeatType === repeatType.BI_WEEKLY ? 2 : 1,
        recurrence: repeat.repeatOn || false,
        multipleDates: [],
      };
    }

    if (!item.courts.length) {
      return window.modal.alert("Please select courts.");
    }

    if (
      !repeatData ||
      (repeatData &&
        repeatData.days.length === 0 &&
        repeatData.frequency !== "daily")
    ) {
      const _start = defaultRange.start_time;
      const _end = defaultRange.end_time;
      repeatData = {
        frequency: "daily",
        occurrences: 1,
        ends: null,
        end_time: _end,
        days: [],
        start_date: _start,
        start_time: _start,
        every: 1,
        recurrence: true,
        multipleDates: [],
      };
      //  return window.modal.alert('Please select week days.');
    }

    if (this.props.onSubmit) {
      //if For add comp micro sett
      if (body.length === 0) {
        body = [
          {
            for: "public",
            values: [-1],
            courts: [],
            rules: [],
          },
        ];
        body = body.map((v) => {
          return {
            ...v,
            ...item,
          };
        });
      }
      return this.props.onSubmit(
        {
          settings: body,
          booked_by: booked_by,
          tags: tag.id !== -1 ? [tag.id] : [],
          assigned: coach.map((v) => v.id),
          recurrence: repeatData,
          note: note,
          sport_id: sport_id,
        },
        !!this.state.seditableId
      );
    }

    if (this.props.editable) {
      body = body.map((each) => {
        if (each.rules && each.rules.length > 0) {
          each.rules = each.rules.map((v) => {
            return {
              ...v,
              days: [
                this.moment(this.props.get_data.startDate).day() - 1 === -1
                  ? 6
                  : this.moment(this.props.get_data.startDate).day() - 1,
              ],
              start_time: this.moment(v.start_time)
                .set({
                  year: this.currentDay.get("year"),
                  month: this.currentDay.get("month"),
                  date: this.currentDay.get("date"),
                })
                .format(),
              end_time: this.moment(v.end_time)
                .set({
                  year: this.currentDay.get("year"),
                  month: this.currentDay.get("month"),
                  date: this.currentDay.get("date"),
                })
                .format(),
            };
          });
        }
        return { ...each };
      });
      
      const bool = await this.props.createMicroSettings(
        {
          sport_id: sport_id,
          settings: body,
          single_day: true,
          group_id: this.state.seditableId,
          court_id: this.props.court_id,
          date: this.currentDay.format(),
          note: note || "Created From Admin",
        },
        false,
        paging
        //true,
        // this.props.get_data
      );
      if (bool) {
        this.props.onClose();
        this.props.history.push("/private/dashboard");
      }

      return true;
    }

    let update_data = {
      settings: body,
      note: note,
      sport_id,
    };
   
    if (repeatData) {
      update_data = {
        ...update_data,
        recurrence: repeatData,
      };
    }

    if (this.state.seditableId) {
      this.props.updateMicroSettings(
        this.state.seditableId,
        {
          ...update_data,
        },
        paging
      );
    } else {
      this.props.createMicroSettings(
        {
          settings: body,
          recurrence: repeatData,
          note: note || "Created From Admin",
          sport_id: sport_id,
        },
        true,
        paging
      );
    }
    if(repeatData.recurrence){ 
      await window.modal.alert(
        "",
        "Processing for the whole recurrence chain. Please wait.",{
          style: {},
          buuton: {},
          timer:3000
        }
      );
     }
    this.props.onClose();
  };

  handleDelete = (id) => {
    this.setState((state) => {
      const { data } = state;

      if (data.structure.length === 1) {
        return state;
      }

      const index = data.structure.findIndex((item) => item.id === id);

      const before = data.structure.slice(0, index);
      const after = data.structure.slice(index + 1);

      return {
        ...state,
        data: {
          ...data,
          structure: [...before, ...after],
        },
      };
    });
  };

  handleRuleRangeChange = (row, property, date) => {
  
    this.setState((state) => {
      const { data } = state;
      const index = data.structure.findIndex((item) => item.id === row.id);

      const before = data.structure.slice(0, index);
      
      const item = {
        ...row,
        time_range: {
          ...row.time_range,
          [property]: date,
        },
      };
      
      const after = data.structure.slice(index + 1);

      const newStructure = [...before, item, ...after];
     
      return {
        ...state,
        data: {
          ...data,
          structure: newStructure,
        },
      };
    });
  };

  selectMultipleOption = (values) => {
    this.setState((state) => ({
      ...state,
      data: {
        ...state.data,
        courts: [...values],
      },
    }));
  };

  onChangeRepeat = (repeatState) => {
    this.setState({
      repeatState: repeatState,
    });
  };

  componentWillReceiveProps(nextProps, nextContext) {
    const { data } = nextProps.micro_settings.current;
    if (!_.isEmpty(data)) {
      const microSettingsData = {
        weekDays: [],
        courts: [],
        structure: [],
      };
      const item = data.micro_settings[0];
      const { sport_id = -1 } = item.facility || {};

      microSettingsData.courts = item.courts.map((id) =>
        this.props.courts.find((crt) => crt.id === id)
      );

      item.rules.forEach((rule, i) => {
        microSettingsData.weekDays = rule.days || [];
        // maybe

        microSettingsData.structure.push({
          id: i,
          time_range: {
            start: this.moment(rule.start_time || undefined),
            end: this.moment(rule.end_time || undefined),
          },
          book_advance: {
            count: rule.advance || "",
            type: "day",
          },
          buffer:rule.buffer||0,
          price: rule.price || "",
          point_price: rule.point_price || "",
        });
      });

      this.setState({
        ...this.state,
        who_can_book: data.micro_settings.map((itm) => ({
          ...itm,
          facility: undefined,
        })),
        sport_id: sport_id,
        who_can_book_filters: data.filters || {},
        book_pay_type:
          item.rules.length > 0
            ? item.rules["0"]["book_pay_type"]
              ? item.rules["0"]["book_pay_type"]
              : "1"
            : "1",
        note: data.note,
        data: {
          ...this.state.data,
          ...microSettingsData,
        },
      });
    }

    this.currentDay = moment.tz(this.props.currentDay, this.props.timeZone) || this.moment();
  }
  
  componentDidMount() {
    this.props.resetMicroSetting();

    const startOfDay = this.getMinDate();

    const { fromCourt, ruleButton } = this.props;

    const start =
      fromCourt && !ruleButton
        ? roundDate(fromCourt.start, this.props.timeStep, startOfDay)
        : roundDate(this.currentDay.clone(), this.props.timeStep, startOfDay);

    const end =
      fromCourt && !ruleButton
        ? roundDate(fromCourt.end, this.props.timeStep, startOfDay)
        : start.clone().add(this.props.minDuration, "minute");

    this.setState((state) => ({
      ...state,
      note: "",
      data: {
        weekDays: [],
        courts:
          fromCourt && !ruleButton
            ? [
              {
                id: fromCourt.courts[0].id,
                name: fromCourt.courts[0].title,
              },
            ]
            : [],
        structure: [
          {
            id: 1,
            time_range: {
              start: start,
              end: end,
            },
            book_advance: {
              count: 365,
              type: "day",
            },
            buffer:0,
            price: 0,
            point_price: 0,
          },
        ],
      },
    }));

    if (this.state.seditableId) {
      this.props.getMicroSettings(this.state.seditableId);
    }

    window.addEventListener("storage", this.handleStorageChange);
  }

  componentWillUnmount() {
    window.removeEventListener("storage", this.handleStorageChange);
  }

  selectCoachOption = (value) => {
    this.setState({ coach: [...value] });
  };

  onChangeWhoCanBook = (changed) => {
    this.setState({
      who_can_book: changed,
    });
  };
  onChangeWhoCanBookFilters = (changed) => {
    this.setState({
      who_can_book_filters: changed,
    });
  };

  handleStorageChange = () => {
    const hasDataInClipboard = !!this.getClipboardData();
    if (this.state.hasDataInClipboard !== hasDataInClipboard) {
      this.setState((st) => ({ ...st, hasDataInClipboard }));
    }
  };

  getClipboardData = () => {
    try {
      let copy_data = localStorage.getItem("DUPLICATE_RULE_DATA");
      copy_data = JSON.parse(copy_data);
      copy_data = copy_data !== null ? copy_data : undefined;
      return copy_data;
    } catch (e) {
      console.log("log: failed to parse DUPLICATE_RULE_DATA: ", e);
    }

    return undefined;
  };

  handleDuplicate = () => {
    this.state.seditableId = ""
    this.setState((state) => ({
      ...state,
      isDuplicate: true
    }))

    return
    const {
      who_can_book,
      who_can_book_filters,
      book_pay_type,
      note,
      coach,
      tag,
      sport_id,
      data,
    } = this.state;
    const stateData = {
      who_can_book,
      who_can_book_filters,
      book_pay_type,
      note,
      coach,
      tag,
      sport_id,
      data,
      // repeatState,
    };
    localStorage.setItem("DUPLICATE_RULE_DATA", JSON.stringify(stateData));
    window.location.href =
      window.location.origin + "/private/dashboard?create=true";
  };

  handleFillFromClipboard = () => {
    let copy_data = this.getClipboardData();
    
    if (typeof copy_data === "object") {
      localStorage.removeItem("DUPLICATE_RULE_DATA");
      this.setState((state) => ({
        ...state,
        ...copy_data,
        data: {
          ...copy_data.data,
          weekDays: [],
          structure: copy_data.data.structure.map((itm) => {
            const start = this.moment(itm.time_range.start);
            start.date(this.currentDay.date());
            start.month(this.currentDay.month());
            start.year(this.currentDay.year());
            const end = this.moment(itm.time_range.end);
            end.date(this.currentDay.date());
            end.month(this.currentDay.month());
            end.year(this.currentDay.year());

            return {
              ...itm,
              time_range: {
                start,
                end,
              },
            };
          }),
        },
      }));
    }
  };
  handleChangeStart(selectedStart){
   
    this.setState((state) => ({
      ...state,
      data: {
       ...state.data,
        structure: state.data.structure.map((itm) => {
          const start = moment(moment(selectedStart).format("YYYY-MM-DD") + ' ' + moment(itm.time_range.start).format("HH:mm")).seconds(0).milliseconds(0);
          start.date(selectedStart.date());
          start.month(selectedStart.month());
          start.year(selectedStart.year());
          let end = undefined
          
          if(start.diff(this.moment(itm.time_range.end).seconds(0).milliseconds(0))<0){
            end=this.moment(itm.time_range.end).seconds(0).milliseconds(0);
            end.date(this.currentDay.date());
            end.month(this.currentDay.month());
            end.year(this.currentDay.year());
          }else{
            end=moment(moment(selectedStart).format("YYYY-MM-DD") + ' ' + moment(itm.time_range.end).format("HH:mm")).clone().add(1, 'days').seconds(0).milliseconds(0);
            end.date(selectedStart.date());
            end.month(selectedStart.month());
            end.year(selectedStart.year());
          }
          
          return {
            ...itm,
            time_range: {
              start,
              end,
            },
          };
        }),
      },
    }));
   
  }
  getMinDate = () => {
    const { business, timeZone: timezone } = this.props;
    const csh_start_time =
      business && business.csh_start_time
        ? business.csh_start_time.split("__")
        : null;
   // const min_date = moment((this.currentDay.clone().diff(this.state.data.structure[0].time_range.start)<0?this.state.data.structure[0].time_range.start:this.currentDay.clone())).tz(timezone).startOf("day");
   const min_date = moment(this.state.data.structure[0].time_range.start).startOf("day");
    if (csh_start_time) {
      min_date.set("hour", csh_start_time[0]);
      min_date.set("minute", csh_start_time[1]);
    }

    return min_date;
  };
   generateTimeListStartedFromByStep=(startTime, stepInMinutes)=> {
    const endOfDay = startTime.clone().startOf("day").add(1380,"minutes");
    const result = []; 
  
    let next = startTime.clone();
    while (true) {
      if (next.isAfter(endOfDay)) break;
  
      result.push(next.clone());
  
      next = next.add(stepInMinutes, "minutes");
    }
  
    return result;
  }

  render() {
    const {
      who_can_book,
      who_can_book_filters,
      book_pay_type,
      data: { structure, courts },
      // excludeTimes,
      booked_by,
      note,
      repeatState,
      sport_id,
    } = this.state;

    const {
      editable,
      is_coach,
      created_date,
      fromCourt,
      isType,
      dateFormat,
      micro_settings,
      readable,
    } = this.props;
   
    const allowed_payment_types = ["1", "-1"];
    const allowed_payment_point_types = ["-1", "2"];

    const recurrence =
      micro_settings.current && micro_settings.current.data
        ? micro_settings.current.data.recurrence
        : null;

    const sportCategoryId = getActiveFacilityGroup()?.sport_category_id;

    const min_date = this.getMinDate();
   
    const injectTimes = generateTimeListStartedFromByStep(
      min_date,
      this.props.timeStep
    );
   
    const minSelectDate = moment( this.moment());
    const maxSelectDate = moment( this.currentDay).add(12, "month");
    const selectedValue = moment( this.state.data.structure[0].time_range.start);
    const timeIntervals = 24 * 60;
    const [_,pairId] = window.localStorage.getItem(GLOBAL_LOCATION_FACILITY_PAIR).split(":");
    const isShowBuffer = (this.props.business.id==1 && pairId == 38) ||
    (this.props.business.id==33 && pairId == 43) ||
    (this.props.business.id==2 && pairId == 10) ||
    (this.props.business.id==90 && pairId == 103) ||
    (this.props.business.id==107 && pairId == 130) 
    const isWhoCanBookPublic=(who_can_book.length>0?who_can_book[0].for=="public":false)
    
    
    return (
      <div
        className={fromCourt ? `micro-settings from-court-wrapp` : `micro-settings ` }
      >
        {this.state.isDuplicate ? (
          <center style={{ color: "red", fontSize: "11px", marginTop: "-10px", marginBottom: "10px" }}><span>This is the duplicate</span></center>
        ) : null}
        <div className={fromCourt ? `flex-col-70` : ``}>
          <form
            autoComplete="nope"
            className={is_coach ? "disabled-div dynamic-form" : "dynamic-form"}
            onSubmit={this.handelSave}
            data-op-form-id="0"
          >
            {!isType ? (
              <React.Fragment>
                {editable && !fromCourt && !is_coach && (
                  <div className="alert-box alert-info">
                    <span>
                    You can edit and delete the listing for this day here. If you want to edit the Facility Availability Rule for all applicable days, go to Facility Availability Rules button underneath the calendar on the court-sheet page.
                    </span>
                  </div>
                )}
                <div className="rec-form">
                  <div className="lesson-form-group form-group">
                    <label>
                      <span className="important-span">*</span>&nbsp; Title
                      (nickname for your use, your customers don't see the
                      title) 
                    </label>
                    <input
                      required
                      type="text"
                      className="form-control"
                      value={note}
                      onChange={({ target: { value } }) =>
                        this.setState((state) => ({ ...state, note: value }))
                      }
                    />
                  </div>
                </div>
              </React.Fragment>
            ) : null}

            <div className="rec-form">
              <div className="form-group lesson-form-group">
                <label>
                  <span className="important-span">*</span>&nbsp; Sport
                </label>
                <div className="picky-container">
                  <SportSelect
                    value={{
                      id: sport_id,
                    }}
                    sportCategoryId={sportCategoryId}
                    onChange={(opt) =>
                      this.setState((st) => ({ ...st, sport_id: opt.id }))
                    }
                  />
                </div>
              </div>
            </div>

            <div className="rec-form">
              <div
                className="lesson-form-group form-group"
                style={{ marginTop: 0 }}
              >
                <label>
                  <span className="important-span">*</span>&nbsp; Who Can Book
                </label>
                <div className="picky-container" style={{ flex: "0 0 70%" }}>
                  <WhoCanBook
                    onChangeWhoCanBook={this.onChangeWhoCanBook}
                    value={who_can_book}
                  />
                </div>
              </div>
            </div>

            <div className="lesson-form-group form-group">
              <span>
                <span className="midleSpan">
                  {" "}
                  Additional who can book filter{" "}
                </span>
              </span>
              <div className="picky-container" style={{ flex: "0 0 70%" }}>
                <DropdownFilters
                  onChangeWhoCanBook={this.onChangeWhoCanBookFilters}
                  who_can_book_filters={who_can_book_filters}
                />
              </div>
            </div>

            {booked_by.length > 0 && (
              <div className="mod-item">
                <div className="lesson-form-group coachSelect">
                  <span className="midleSpan">Attach tag</span>
                  <TagsSelect
                    value={this.state.tag}
                    onChange={this.selectTag}
                  />
                </div>
              </div>
            )}

            {booked_by.length > 0 && (
              <div className="mod-item">
                <div className="lesson-form-group coachSelect">
                  <span className="midleSpan"> Assign to </span>
                  <div className="picky-container">
                    <Picky
                      options={this.props.coachList}
                      value={this.state.coach}
                      valueKey="id"
                      labelKey="displayName"
                      multiple={true}
                      numberDisplayed={2}
                      includeFilter={this.state.includeFilterCoach}
                      onChange={this.selectCoachOption}
                      placeholder="Admin"
                      dropdownHeight={350}
                    />
                  </div>
                </div>
              </div>
            )}
            {!editable && (
              <div className="lesson-form-group form-group">
                <span>
                  <span className="midleSpan">Courts </span>{" "}
                </span>
                <div className={"picky-container"} style={{ flex: "0 0 70%" }}>
                  <Picky
                    includeFilter
                    options={[...this.props.courts]}
                    includeSelectAll={true}
                    value={courts}
                    valueKey="id"
                    labelKey="name"
                    multiple={true}
                    numberDisplayed={2}
                    onChange={this.selectMultipleOption}
                    dropdownHeight={350}
                  />
                </div>
              </div>
            )}
            <div className="lesson-form-group form-group">
              <BookPayType
                book_pay_type={book_pay_type}
                onChangeBookPayType={this.onChangeBookPayType}
              />
            </div>
            {created_date && created_date !== "false" && (
              <div className="lesson-form-group form-group f-w-600">
                <span>
                  <span className="midleSpan">Applicable date </span>{" "}
                </span>
                <span>
                  <span className="midleSpan">
                    {" "}
                    {moment(created_date).format("MMMM Do YYYY")}{" "}
                  </span>{" "}
                </span>
              </div>
            )}
            
            <div className="d-block mod-item"> 
              <div className="lesson-form-group ">
                <span style={{marginTop:"4px",textAlign:"left"}}>&nbsp;&nbsp;<b> Start:</b> </span>
                <label htmlFor="StartDay">
                  <span style={{marginTop:"4px"}}>&nbsp;&nbsp;&nbsp;&nbsp; <small>On Date</small> &nbsp;&nbsp;</span>
                  <DatePicker
                    dateFormat="MMM D, YYYY"
                    onChange={this.handleChangeStart}
                    onSelect={() => {
                      return false;
                    }}
                    selected={selectedValue}
                    minDate={minSelectDate}
                    maxDate={maxSelectDate}
                    locale="en-gb"
                  />
                </label>
                </div>
            </div>
            <div className="d-block mod-item">
            <div className="micro_repeat form-group">
              <Repeat
                  onChangeRepeat={this.onChangeRepeat}
                  disabled={readable ? true : false}
                  fromCourtListing={false}
                  lessonView={true}
                  timezone={this.props.timeZone}
                  recurringInfo={!repeatState ? recurrence : null}
                  currentDay={moment((this.currentDay.clone().diff(this.state.data.structure[0].time_range.start)<0?this.state.data.structure[0].time_range.start:this.currentDay.clone()))}
                />
            </div>
              
            </div>

            <div className="form-group">
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Time</th>
                      <th>Earliest Book in Advance</th>
                      {isShowBuffer &&(
                        <th>Buffer <br /> <small style={{fontSize:'10px !important'}}>(not bookable days before)</small> </th>
                      )}
                      
                      {allowed_payment_types.includes(book_pay_type) && (
                        <th>Price / hr</th>
                      )}
                      {allowed_payment_point_types.includes(book_pay_type) && (
                        <th>Point / hr</th>
                      )}
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {structure.map((row) => {
                      const start = this.moment(row.time_range.start);
                     
                      let end = this.moment(row.time_range.end);
                     
                      const endOfDay = min_date.clone().add(1, "day").startOf("day");
                       
                      return (
                        <tr key={row.id}>
                          <td>
                            <div className="d-flex res_d-flex">
                              <DatePicker
                                className="mr-2 date-picker"
                                dropdownMode="select"
                                selected={start}
                                showTimeSelectOnly
                                minTime={min_date}
                                minDate={min_date}
                                maxTime={min_date.clone().add(1380,"minutes")}
                                maxDate={min_date}
                                onChange={(date) =>
                                  this.handleRuleRangeChange(row, "start", date)
                                }
                                showTimeSelect
                                
                                timeIntervals={timeIntervals}
                                timeCaption="Time"
                                injectTimes={injectTimes}
                                dateFormat={dateFormat}
                                timeFormat={dateFormat}
                              // timezone={this.props.timeZone}
                              />&nbsp;
                              <DatePicker
                                className="date-picker"
                                dropdownMode="select"
                                selected={end}
                                minTime={start.clone().add(this.props.timeStep, "minutes")}
                                minDate={start.clone().add(this.props.timeStep, "minutes")}
                                maxTime={endOfDay}
                                maxDate={endOfDay}
                                // excludeTimes={excludeTimes}
                                onChange={(date) => {
                                  this.handleRuleRangeChange(row, "end", date);
                                }}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={timeIntervals}
                                timeCaption="Time"
                                injectTimes={injectTimes}
                                dateFormat={dateFormat}
                                timeFormat={dateFormat}
                              // timezone={this.props.timeZone}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="d-flex">
                              <input
                                className="table-item mr-2"
                                style={{ minWidth: "50px" }}
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                min="0"
                                max="365"
                                value={row.book_advance.count}
                                onChange={({ target: { value } }) =>
                                  this.setState(({ data }) => {
                                    value = Math.max(value, 0);
                                    value = Math.min(value, 365);

                                    const index = data.structure.findIndex(
                                      (item) => item.id === row.id
                                    );

                                    const before = data.structure.slice(
                                      0,
                                      index
                                    );
                                    const item = {
                                      ...row,
                                      book_advance: {
                                        ...row.book_advance,
                                        count: (value==''?null:value),
                                      },
                                    };

                                    const after = data.structure.slice(
                                      index + 1
                                    );

                                    return {
                                      data: {
                                        ...data,
                                        structure: [...before, item, ...after],
                                      },
                                    };
                                  })
                                }
                              />
                              <span>days</span>
                            </div>
                          </td>
                          {isShowBuffer &&(
                            <td>
                            <div className="d-flex">
                              
                              <input
                                className="table-item mr-2"
                                style={{ minWidth: "50px" }}
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                min="0"
                                max="365"
                                value={row.buffer}
                                onChange={({ target: { value } }) =>
                                  this.setState(({ data }) => {
                                    

                                    const index = data.structure.findIndex(
                                      (item) => item.id === row.id
                                    );

                                    const before = data.structure.slice(
                                      0,
                                      index
                                    );
                                    const item = {
                                      ...row,
                                      buffer: value
                                    };

                                    const after = data.structure.slice(
                                      index + 1
                                    );

                                    return {
                                      data: {
                                        ...data,
                                        structure: [...before, item, ...after],
                                      },
                                    };
                                  })
                                }
                              />
                              
                            </div>
                          </td>
                          )}
                          
                          {allowed_payment_types.includes(book_pay_type) && (
                            <td>
                              <div className="d-flex">
                                <input
                                  className="table-item"
                                  type="number"
                                  onWheel={(e) => e.target.blur()}
                                  min="0"
                                  step="any"
                                  onChange={({ target: { value } }) =>
                                    this.setState(({ data }) => {
                                      const index = data.structure.findIndex(
                                        (item) => item.id === row.id
                                      );

                                      const before = data.structure.slice(
                                        0,
                                        index
                                      );
                                      const item = {
                                        ...row,
                                        price: value,
                                      };
                                      const after = data.structure.slice(
                                        index + 1
                                      );
                                      return {
                                        data: {
                                          ...data,
                                          structure: [
                                            ...before,
                                            item,
                                            ...after,
                                          ],
                                        },
                                      };
                                    })
                                  }
                                  value={row.price}
                                />
                              </div>
                            </td>
                          )}
                          {allowed_payment_point_types.includes(
                            book_pay_type
                          ) && (
                              <td>
                                <div className="d-flex">
                                  <input
                                    className="table-item"
                                    type="number"
                                    onWheel={(e) => e.target.blur()}
                                    min="0"
                                    step="any"
                                    onChange={({ target: { value } }) =>
                                      this.setState(({ data }) => {
                                        const index = data.structure.findIndex(
                                          (item) => item.id === row.id
                                        );

                                        const before = data.structure.slice(
                                          0,
                                          index
                                        );
                                        const item = {
                                          ...row,
                                          point_price: value,
                                        };
                                        const after = data.structure.slice(
                                          index + 1
                                        );
                                        return {
                                          data: {
                                            ...data,
                                            structure: [
                                              ...before,
                                              item,
                                              ...after,
                                            ],
                                          },
                                        };
                                      })
                                    }
                                    value={row.point_price}
                                  />
                                </div>
                              </td>
                            )}
                          <td>
                            <div className="d-flex justify-center">
                              <button
                                className="btn btn-red rounded"
                                type="button"
                                onClick={() => this.handleDelete(row.id)}
                              >
                                <img
                                  src="/assets/img/cancel-white.png"
                                  width={15}
                                  height={15}
                                  alt="delete"
                                />
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td
                        colSpan={
                          allowed_payment_point_types.includes(book_pay_type)
                            ? 5
                            : 4
                        }
                      >
                        <button
                          className="btn btn-green rounded mx-auto"
                          type="button"
                          onClick={this.addRow}
                        >
                          <img
                            src="/assets/img/plus.png"
                            width={20}
                            height={20}
                            alt="plus"
                          />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="d-flex justify-center">
              <div className="pretty-button">
                <button className="red-button" type="button" onClick={this.props.onClose} >
                  Cancel
                </button>
              </div>
              

              {!is_coach && (
                <React.Fragment>
                  
                  <div className="pretty-button" style={{marginLeft:"5px"}}>
                    <button type="submit" className="green-button" style={{ padding: "5px 20px" }} >
                      Save
                    </button>
                    </div>
                  {this.state.seditableId ? (
                    <div className="pretty-button" >
                    <button
                      type="button"
                      onClick={this.handleDuplicate}
                      className="green-button"
                      style={{ padding: "5px 20px", marginLeft: "10px" }}
                    >
                      {this.state.duplicate_text}
                    </button>
                    </div>
                  ) : null}
                </React.Fragment>
              )}
            </div>
          </form>
        </div>
        <div className="flex-col-30 align-start">
          {fromCourt && (
            <BookedBy
              isWhoCanBookPublic={isWhoCanBookPublic}
              _during_add={true}
              ms={true}
              onChangeBookedBy={this.onChangeBookedBy}
              quantity={1000}
              ref={(booked_by) => {
                this.booked_by = booked_by;
              }}
            />
          )}
        </div>
      </div>
    );
  }
}

MicroSettings.defaultProps = {
  dateFormat: "hh:mm a",
  // timeStep: 15,
};

const mapStateToProps = (state) => {
  return {
    error: state.auth.signup,
    user: state.auth.user,
    courts: state.courts.data,
    coachList: state.coach.data,
    isConnected: state.stripe.isConnected,
    timeStep: state.auth.user.business.booking_to_start, //|| 15,
    minDuration: state.auth.user.business.min_list_duration, //|| 15,
    business: state.auth.user.business, //|| 15,
    micro_settings: state.micro_settings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateMicroSettings: (id, data, options = {}) =>
      dispatch(updateMicroSettings(id, data, options)),
    createMicroSettings: (data, update = true, options = {}) =>
      dispatch(createMicroSettings(data, update, options)),
    resetMicroSetting: () => dispatch(resetMicroSetting()),
    getMicroSettings: (id) => dispatch(getMicroSettings(id)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MicroSettings)
);
