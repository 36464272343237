import React from 'react';
import { useEffect } from 'react';
import Picky from 'react-picky';
import { useDispatch, useSelector } from 'react-redux';
import { getEntityList } from '../../actions/Location';

const initialDefaultOption = { id: -1, value: 'None' };

const LocationSelect = ({ defaultOption = initialDefaultOption, value, onChange, className,fromQRPopup }) => {
  const {
    list: { data },
  } = useSelector(state => state.location.entity);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getEntityList());
  }, [dispatch]);
  
  let options = [defaultOption, ...data.map(itm => ({ id: itm.id, value: itm.name }))];
  
  // if(fromQRPopup && value.id==-1){
    
  //   options=[...data.map(itm => ({ id: itm.id, value: itm.name }))];
  //   value=options[0]
  // }
  if (value && !value.value) {
    const valueFromList = options.find(itm => itm.id == value.id);

    value = valueFromList
      ? valueFromList
      : {
          ...value,
          value: 'Location id: ' + value.id,
        };
  }

  return (
    <div className={`picky-container ${className}`} style={{ flex: '0 0 70%' }}>
      <Picky
        includeFilter
        options={options}
        value={value}
        onChange={onChange}
        valueKey="id"
        labelKey="value"
        multiple={false}
        dropdownHeight={350}
      />
    </div>
  );
};

export default LocationSelect;
