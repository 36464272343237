import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updatetaxRate } from '../../actions/Auth';

import Loading from '../UI/Loading';

import Tax from '../Forms/Tax';

class TaxRateModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.setTax = this.setTax.bind(this);
  }

  async setTax(id, taxModel) {
    await this.props.SetTax(id, taxModel);
    if (this.closeRef) {
      this.closeRef.click();
    }
  }

  render() {
    const { clouseModal, taxLodaing, record } = this.props;
    return (
      <div className="modal stripe-modal active">
        <div className="modal-content">
          <div className="close-btn">
            <button
              ref={buuttonRef => {
                this.closeRef = buuttonRef;
              }}
              onClick={clouseModal}>
              <img src="/assets/img/cancel.png" alt="cancel" />
            </button>
          </div>
          {taxLodaing && <Loading />}
          <Tax record={record} setTax={this.setTax} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    taxLodaing: state.auth.taxLodaing,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    SetTax: (id = null, taxRate) => dispatch(updatetaxRate(id, taxRate)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaxRateModal);
