import axiosOriginal from 'axios';
import axios from '../config/axios';
import FileDownload from "js-file-download";

export function downloadGroupList(group_id, fileName) {
  return async (dispatch) => {
    try {

      let res = await axios.request({
        url: `reports/group?group_id=${group_id}`,
        method: "GET",
        responseType: "blob",
      });
     
      FileDownload(res.data, fileName + `.csv`);

      return true;
    } catch (error) {
      await window.modal.alert("Error", error.response.data.message);
      return false;
    }
  };
}

export function downloadInitiationFeeList(plan_id, fileName) {
  return async (dispatch) => {
    try {

      let res = await axios.request({
        url: `reports/subscription?plan_id=${plan_id}`,
        method: "GET",

        responseType: "blob",
      });

      FileDownload(res.data, fileName + `.csv`);

      return true;
    } catch (error) {
      await window.modal.alert("Error", error.response.data.message);
      return false;
    }
  };
}
export function disableTheInitiationFee(plan_id, bool) {
  return async (dispatch) => {
    try {

      const res = await axios.post(`/payment/plan/disabled/${plan_id}`, {
          is_disabled:bool
        }, {}
      );

    

      return true;
    } catch (error) {
      await window.modal.alert("Error", error.response.data.message);
      return false;
    }
  };
}


export async function getTimeZone({
  lng,
  lat
}) {
  const str = `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=${Math.floor(
    Date.now() / 1000
  )}&key=AIzaSyDU5HCDlB_grwZqROLffrCaKw8oEK2fXjI`;
  // const newStr = `http://api.geonames.org/timezoneJSON?lat=${lat}&lng=${lng}&username=miqjan`;
  const response = await axiosOriginal.get(str);
  return response.data.timezoneId;
}

export async function getPutUrl(fileName) {
  try {
    const res = await axios.post(
      'user/general/createuploadlink', {
        fileName,
      }, {}
    );
    return res.data.payload[0];
  } catch (error) {
    return false;
  }
}
export async function sendMessage(email, name, text) {
  try {
    const res = await axios.post(
      'parent/sendemail', {
        name,
        email,
        message: text,
      }, {}
    );
    await window.modal.alert('Success', 'Your message sent successfully');
    return true;
  } catch (error) {
    await window.modal.alert('Error', error.response.data.message);
    return false;
  }
}
export async function setAvatar(putURL, file) {
  try {
    const data = await fileReader(file);
    await axios.put(putURL, data, {
      processData: false,
      headers: {
        'Content-Type': 'binary/octet-stream',
      },
    });
    return true;
  } catch (error) {
    return false;
  }
}
export async function setProfileImage(postURL, file) {
  try {
    let formData = new FormData();
    formData.append('filename', file);
    formData.append('is_profile', true);
    await axios.post(postURL, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return true;
  } catch (error) {
    return false;
  }
}
export function fileReader(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = e => {
      resolve(reader.result);
    };
    reader.readAsArrayBuffer(file);
  });
}
export async function sendDemo(obj) {
  try {
    const {
      error,
      randomNr1,
      randomNr2,
      totalNr,
      total_math,
      modalOpenedCalendly,
      ...rest
    } = obj;
    const res = await axios.post('auth/requestdemo', {
      //'https://api.ballbuttonbb.com/dev/v1/user/general/requestdemo'
      ...rest,
    });

    // await window.modal.alert('Success', 'Demo request sent.');
    return true;
  } catch (error) {
    await window.modal.alert('Error', error.response.data.message);
    return false;
  }
}

export async function contactUs(obj) {
  try {
    delete obj.error;
    const res = await axios.post('contactus', obj, {});
    if (res.data.code !== 200) throw new Error(res.data.message);
    await window.modal.alert('Success', 'Success. We will get back to you shortly.');
  } catch (error) {
    await window.modal.alert('Error', error.response.data.message);
  }
}
export async function unsubscribeFormRequest(obj) {
  try {
    const res = await axios.post('user/general/cpunsubscriberequest', obj, {});
    if (res.data.code !== 200) throw new Error(res.data.message);
    await window.modal.alert(
      '',
      'Thank You for contacting us! We will get back to you shortly regarding your request.'
    );
  } catch (error) {
    await window.modal.alert('Error', error.response.data.message);
  }
}
export async function unsubscribe(date) {
  try {
    const res = await axios.post('user/payment/cancelsubscription', {}, {});
    if (res.data.code !== 200) throw new Error(res.data.message);
    await window.modal.alert(
      'Success',
      'We are sorry to see you leave. You will continue using our service till your paid subscription end: ' +
      date.format('MMMM Do YYYY')
    );
  } catch (error) {
    await window.modal.alert('Error', error.response.data.message);
  }
}
export async function checkPromo(promo) {
  try {
    const res = await axios.post(
      'payment/verifypromo', {
        promo,
      }, {}
    );
    await window.modal.alert('Success', `Success.`);
  } catch (error) {
    await window.modal.alert('Error', error.response.data.message);
  }
}

export async function searchuserbyfullname(searchName) {
  try {
    const res = await axios.get(`members/get?last_id=-1&search=${searchName}&include_coach=true`, {});
    return res.data.payload;
  } catch (error) {
    return [];
  }
}
export async function fetchConnectionToken() {
  try {
    // Your backend should call /v1/terminal/connection_tokens and return the JSON response from Stripe
    const response = await axios.post('payment/createterminaltoken', {}, {});
    return response.data.payload.secret;
  } catch (error) {
    throw new Error('fetchConnectionToken error');
  }
}
export async function createstripepaymentintents(data, user_id) {
  try {
    // Your backend should call /v1/terminal/connection_tokens and return the JSON response from Stripe
    const response = await axios.post(
      'payment/reader/createpaymentintents', {
        ...data,
        user_id,
      }, {}
    );
    return response.data.payload.client_secret;
  } catch (error) {
    throw new Error('createstripepaymentintents error');
  }
}
export async function sendCaptureIntentRequest(payment_intent_id, data = {}) {
  try {
    // Your backend should call /v1/terminal/connection_tokens and return the JSON response from Stripe
    const response = await axios.post(
      'payment/reader/capturepaymentintents', {
        payment_intent_id,
        ...data,
      }, {}
    );
    if (response.data.code === 200) return response.data.payload;
  } catch (error) {
    throw new Error('sendCaptureIntentRequest error');
  }
}
