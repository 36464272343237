import React, { Component } from "react";
import Picky from "react-picky";
import { connect } from "react-redux";

const initialDefaultOption = { id: -1, value: "Service Provider",disabl:true };

class CoachSelect extends Component {
  state = {
    value: null,
    options: [],
    disable: false,
  };

  componentWillReceiveProps(nextProps) {
    let {
      value,
      disable_no_in_list,
      data,
      defaultOption = initialDefaultOption,
      disabled,
      filterData = () => true,
      selectedCoach
    } = nextProps;
    
    const options = [
      ...data.filter(filterData).map((itm) => ({
        id:itm.coach_id || itm.id,
        value: itm.coach_name || (itm.displayName || `${itm.first_name} ${itm.last_name}`),
      })),
    ];
    let disable = disabled;
    if (value) {
      const valueFromList = options.find((itm) => itm.id == value.id);

      if (valueFromList) {
        value = valueFromList;
      } else {
        value = {
         // value: "Loading...",
          value: selectedCoach||"Service Provider",
          ...value,
        };
        if (disable_no_in_list) {
          disable = true;
        }
      }
    }

    this.setState((st) => ({ ...st, value, options, disable }));
  }

  render() {
    const { className } = this.props;
    const { disable, options, value } = this.state;

    return (
      <div
        className={`picky-container ${className}`}
        style={{ flex: "0 0 70%" }}
      >
        <Picky
          disabled={disable}
          includeFilter
          options={options}
          value={value}
          onChange={this.handleChange}
          valueKey="id"
          labelKey="value"
          multiple={false}
          dropdownHeight={350}
        />
      </div>
    );
  }

  handleChange = (val) => {
    this.props.onChange(val);
    document.querySelector("body").click()
  };
}

const mapStateToProps = (state) => {
  return {
    data: state.coach.dataMobileVersion,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CoachSelect);
