import {
  GET_COURT_ERROR,
  GET_COURT_LISTING_SUCCESS,
  GET_COURT_LOADING,
  GET_COURT_SUCCESS,
  RESET_SINGLE_LISTING,
  WAITLIST_LOADING_C,
  WAITLIST_SUCCESS_C,
  MSGS_LOADING,
  MSGS_SUCCESS,
  CHECKINS_LOADING,
  CHECKINS_SUCCESS,
  CHECKINS_ADD_SUCCESS,
  CHECKOUT_ADD_SUCCESS,


  ATTENDANCE_SUCCESS,
  ATTENDANCE_LOADING,
  BALANCE_SUCCESS,
  BALANCE_LOADING
} from '../constants/Court';

const initStore = {
  loading: false,
  loadingWaitlist: false,
  loadingCheckins: false,
  loadingAttendance: false,
  loadingBalance: false,
  loadingmsgs: false,
  data: {
    details: [],
    surface: [],
  },
  checkins: [],
  listing: {},
  listingmsgs: [],
  error: null,
};
const CourtsReducer = (store = initStore, action) => {
  switch (action.type) {
    case GET_COURT_LISTING_SUCCESS:
      return Object.assign({}, store, {
        listing: action.payload,
      });
    case WAITLIST_LOADING_C:
      return Object.assign({}, store, {
        loadingWaitlist: action.payload,
      });
    case MSGS_LOADING:
      return Object.assign({}, store, {
        loadingmsgs: action.payload,
      });

    case RESET_SINGLE_LISTING:
      return Object.assign({}, store, {
        listing: action.payload,
      });
    case WAITLIST_SUCCESS_C:
      return Object.assign({}, store, {
        listing: {
          ...store.listing,
          waitList: action.payload
        },
      });

    case MSGS_SUCCESS:
      return Object.assign({}, store, {
        listingmsgs: [...action.payload],
      });


    case CHECKINS_LOADING:
      return Object.assign({}, store, {
        loadingCheckins: action.payload,
      });



    case CHECKINS_SUCCESS:
      return Object.assign({}, store, {
        checkins: [
          ...action.payload
        ],
      });

    case ATTENDANCE_SUCCESS:
      return Object.assign({}, store, {
        attendance: [
          ...action.payload.apts
        ],
        all_members_attendance: [
          ...action.payload.all_members_attendance
        ],
      });
    case ATTENDANCE_LOADING:
      return Object.assign({}, store, {
        loadingAttendance: action.payload,
      });



    case BALANCE_SUCCESS:
      return Object.assign({}, store, {
        balance: [
          ...action.payload.apts
        ],
        all_members_balance: [
          ...action.payload.all_members_balance
        ],
      });
    case BALANCE_LOADING:
      return Object.assign({}, store, {
        loadingBalance: action.payload,
      });



    case CHECKINS_ADD_SUCCESS:
      return Object.assign({}, store, {
        listing: {
          ...store.listing,
          checkins: action.payload
        },
      });
    case CHECKOUT_ADD_SUCCESS:
      return Object.assign({}, store, {
        listing: {
          ...store.listing,
          checkouts: action.payload
        },
      });
    case GET_COURT_LOADING:
      return Object.assign({}, store, {
        loading: action.payload
      });
    case GET_COURT_SUCCESS:
      return Object.assign({}, store, {
        data: action.payload,
      });
    case GET_COURT_ERROR:
      return Object.assign({}, store, {
        error: action.payload
      });

    default:
      return store;
  }
};
export default CourtsReducer;
