import React, { Component } from "react";
import Pricing from "../../components/Pricing";
import RequestDemoCalendly from "../../components/RequestDemoCalendly";
import BBVideoModal from "../../components/BBVideoModal";
import { TennisGuy } from "../Common/TennisGuy";
import BbOptions from "../BbOptions";
import AthletesSlider from "../AthletesSlider";
import RequestModal from '../../components/RequestModal';
import MobileScreens from "../MobileScreens";
import { Link } from "react-router-dom";
import { scroller } from "react-scroll";
import "./racquets.scss";

class Racquets extends Component {
    constructor(props) {
        super(props);
        this.state = {
            difers: [
                // {
                //   img: '/assets/img/difer1.png',
                //   text: `<b>All our features are available to all our customers regardless of subscription price you pay.</b> Unlike number of competitors we do not make advanced features accessible only to pricier subscription plans.
                //         `,
                //   showMore: true,
                //   showLess: false,
                //   extra_text: `` /*You will experience the full beauty of Ballbutton software and will have equal access to our all sophisticated features, like, member groups, customized booking restrictions “who can book”, level, customized pricing for different groups, times, court/spot/line/facility type, and more.
                //   The price difference is based only on your business “size” and actual needs, how many courts/spots/lines and members you have.*/,
                // },
                {
                    img: "/assets/img/difer2.png",
                    text: `<b>Unlike number of competitors we do not take cut from each booking.</b> Our key revenue is from flat monthly 
                subscription fee. *(obviously you will also pay payment processing fees if you process payments).`,
                },
                {
                    img: "/assets/img/difer3.png",
                    text: ` <b>Ballbutton users both on the web and on the app see only their membership business.</b>`,
                },
                {
                    img: "/assets/img/difer4-v2.png",
                    text: `<b>Your business has to be social in 21st century.</b> 
                Our one-of-a-kind feature in the market, Members news feed!! Keep your members engaged with your businesss!`,
                },
            ],
            bb_video: null,
        };
    }

    openBBVideo = () => {
        this.setState({
            bb_video: <BBVideoModal close={this.closeVideo} />,
        });
    };

    closeVideo = () => {
        this.setState({
            bb_video: null,
        });
    };
    openModalRequestDemo = () => {
        this.setState({
            modalOpenedDemo: <RequestModal opened={true} close={this.closeModal} />,
        });
    };
    closeModal = () => {
        this.setState({
            modalOpenedDemo: null,
        });
    };
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    showMore = (i) => {
        const { difers } = this.state;
        difers[i]["showMore"] = false;
        difers[i]["showLess"] = true;
        this.setState({
            difers: [...difers],
        });
    };

    showLess = (i) => {
        const { difers } = this.state;
        difers[i]["showMore"] = true;
        difers[i]["showLess"] = false;
        this.setState({
            difers: [...difers],
        });
    };

    render() {
        const games = [
            "Racquet Sports (We started as Tennis software! 🎾📲🖥️🚀)",
            "Cycling, Running, Rowing",
            "Yoga & Pilates",
            "Golf",
            "GYM & Strength Training",
            "Dancing & Fitness Dancing",
            "Swimming",
            "Boxing & Wrestling",
            "Martial Arts",
            "Volleyball",
        ];
        const games_rest = [
            "Soccer",
            "Basketball",
            "Winter Sports",
            "Bowling, Billiards, etc.",
            "Private, Social, University and Any Other Membership Clubs",
        ];
        setTimeout(() => {
            if (this.props.location.pathname == "/social_engage" || this.props.location.pathname == "/newsfeed" || this.props.location.pathname == "/engage") {
                this.props.location.hash = "";
                scroller.scrollTo("social_engage", {
                    duration: 1000,
                    delay: 0,
                    smooth: "easeInOutQuart",
                });
            }
        }, 500);

        const { difers, bb_video } = this.state;
        const { modalOpenedDemo } = this.state;
        return (
            <div className="racquets-page">
                <div className="header-section">
                    <div className="main-container">
                        {/* <div className="christmas-box">
              <h3>
                Holiday Season Special: Schedule a demo before December 31st to get 2 Months Free trial!!!
                <br />
                No credit card required!!!
              </h3>
    </div>*/}
                        <div className="main-container-inner " >
                            <div className="games-list-container racquets_top_bg" style={{ backgroundImage: `url('/assets/img/home-purple-balls.png')` }}>
                                <div className="title">
                                    <h2>Software For Any Racquet Club</h2>
                                </div>
                                {/*  <div className="games-full-release">
                                    <div className="games">
                                        {games.map((v, i) => (
                                            <div key={i} className="game">
                                                {v}
                                            </div>
                                        ))}
                                    </div>
                                    <div className="full-release">
                    Our worldwide full release<br />
                    went live on 01/20/20!!!
                </div>
                                </div>
                                <div className="double-bubble">
                                    <div className="games">
                                        {games_rest.map((v, i) => (
                                            <div key={i} className="game">
                                                {v}
                                            </div>
                                        ))}
                                    </div>
                                </div>*/}
                            </div>
                            <TennisGuy />
                        </div>
                    </div>
                </div>
                <div className="demo-middle">
                    <RequestDemoCalendly showBorder={true} />
                </div>
                <div className="preffered-choice">
                    <div className="main-container">
                        <div className="ota-class-container"></div>
                        <div className="preffered-choice-container">
                            <div className="imac-img">
                                <img
                                    src="/assets/img/black-macbook.png"
                                    alt="/assets/img/black-macbook.png"
                                />
                            </div>
                            <div className="preffered-choice-desc">
                                <div className="title">
                                    <h2>
                                        The Preferred Choice of <br /> Modern Clubs
                                    </h2>
                                </div>
                                <div className="description">
                                    <p>
                                        Ballbutton helps clubs & studios manage their business and
                                        grow the revenue by managing bookings, payments, programs,
                                        lessons, teams, and members (memberships).
                                    </p>
                                    <br />
                                    <p>
                                        Tennis, Pickleball, Badminton, Squash, Padel and other
                                        Courts reservations.
                                    </p>

                                    <p>
                                        Any Other athletic facility Court/Spot/lane reservations.
                                    </p>
                                    <br />
                                    <p>Any lesson, clinic and program reservations.</p>
                                    <br />
                                    <p>Offer any lessons also Streaming!</p>
                                    <br />
                                    <p>
                                        Membership management tools AtoZ: memberships,
                                        subscriptions, initiations, automated payments, billing,
                                        reports, checkins and more!
                                    </p>
                                    <p>
                                        PLUS extraordinary one-of-a-kind module: Members' Social
                                        Feed!!
                                    </p>
                                    <br />
                                    <p>
                                        Ballbutton comes with a <b>24/7 customer support!</b>
                                    </p>
                                    <br />
                                </div>
                            </div>
                            <div className="booking-image">
                                <img src="/assets/img/lesson-mobile.png" alt="Lesson mobile" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="how-it-works">
                    <div className="each-section">
                        <div className="main-container">
                            <div className="container-inner">
                                <div className="img-part">
                                    <img
                                        src="/assets/img/court-listing-booking.png"
                                        alt="/assets/img/court-listing-booking.png"
                                    />
                                </div>
                                <div className="desc-part">
                                    <div className="title-business">
                                        <h2>
                                            Use the Ballbutton software to <br /> manage & grow your
                                            business
                                        </h2>
                                    </div>
                                    <div className="desc">
                                        <p>
                                            Increase your occupancy rate by a magnitude, diminish dead
                                            hours and empty courts/spots/lanes.
                                            <br />
                                            List or block any court/spot/line and time.
                                            <br />
                                            Private lessons and Group lessons (also recurring) of any
                                            type.
                                            <br />
                                            Analytics of business activity: filter per
                                            court/spot/lane, coach, lesson type, member.
                                            <br />
                                        </p>
                                        <div className="bubble">
                                            <img
                                                src="/assets/img/court-listing-booking-bubble.png"
                                                alt="/assets/img/court-listing-booking-bubble.png"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="each-section left-direction">
                        <div className="main-container">
                            <div className="container-inner">
                                <div className="desc-part">
                                    <div className="title-business">
                                        <h2>
                                            Advanced and <br /> customizable settings
                                        </h2>
                                    </div>
                                    <div className="desc">
                                        <p>
                                            Customize who can book, duration, customized pricing for
                                            different groups, times, court/spot/lane/facility type,
                                            and more.
                                            <br />
                                            Manage the waitlist, groups and rosters.
                                            <br />
                                            Multiple staff profiles, coach access levels and login.
                                            <br />
                                        </p>
                                        <div className="double-bubble">
                                            <img
                                                src="/assets/img/customizable-settings-bubble.png"
                                                alt="/assets/img/customizable-settings-bubble.png"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="img-part">
                                    <img
                                        src="/assets/img/customizable-settings.png"
                                        alt="/assets/img/customizable-settings.png"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="each-section">
                        <div className="main-container">
                            <div className="container-inner">
                                <div className="img-part">
                                    <img
                                        src="/assets/img/manage-members-v2.png"
                                        alt="Manage members"
                                    />
                                </div>
                                <div className="desc-part">
                                    <div className="title-business">
                                        <h2>
                                            Manage members and <br /> subscription plans, <br />
                                            Process payments
                                        </h2>
                                    </div>
                                    <div className="desc">
                                        <p>
                                            Detailed profiles of members
                                            <br />
                                            Notification emails and reminders (automatic)
                                            <br />
                                            Direct email to customer & bulk emails
                                            <br />
                                            Payment processor (both online and card present) and POS
                                            <br />
                                            Easily automate reminders, track member activity &
                                            analytics, and more.
                                            <br />
                                            Membership management tools AtoZ: memberships,
                                            subscriptions, initiations, automated payments, billing,
                                            reports, checkins and more!
                                            <br />
                                            PLUS extraordinary one-of-a-kind module: Members' Social
                                            Feed!!
                                            <br />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="each-section p-top-40">
                        <div className="main-container">
                            <div className="container-inner">
                                <div className="img-part">
                                    <img src="/assets/img/imac.png" alt="/assets/img/imac.png" />
                                </div>
                                <div className="desc-part covid-desc-part">
                                    <div className="desc">
                                        <p>
                                            Ballbutton helped many clubs to restart their operations
                                            during the unprecedented difficult times the fitness
                                            industry had to go through because of the COVID-19:
                                        </p>
                                        <br />
                                        <ul>
                                            <li>Process bookings on the mobile app,</li>
                                            <li>
                                                Process contactless payments online and on the mobile
                                                app
                                            </li>
                                            <li>COVID-19 waiver signing on the mobile app</li>
                                            <li>Online checkins on the mobile app</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="container-fluid pricing_part">
                        <div className="row">
                            <div className="col-md-12">
                                <Pricing hideSchedule="true"  hideFeaturesList={true} />
                            </div>
                        </div>
                    </div> */}
                </div>
               
                <div className="how-we-defer-from-competitors ">
                    <div className="title-business">
                        <h2>How We Differ From Competitors</h2>
                    </div>
                    <div className="difers">
                        <div className="main-container">
                            <div className="main-container-inner">
                                {difers.map((v, i) => (
                                    <div key={i} className="difer">
                                        <div className="difer-image">
                                            <img src={v.img} width="110" alt={v.img} />
                                        </div>
                                        <div className="difer-text">
                                            <p dangerouslySetInnerHTML={{ __html: v.text }}>
                                                {/*v.extra_text && v.showMore && (<React.Fragment><br /><span onClick={() => this.showMore(i)} className="green-learn-more">Learn more</span></React.Fragment>)}
                        {v.showLess && (<React.Fragment><br /><span onClick={() => this.showLess(i)} className="green-learn-more">Show less</span></React.Fragment>)*/}
                                            </p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="learn-why-ballbutton">
                                Learn more Why Ballbutton{" "}
                                <Link to={`/why-ballbutton`}>here</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="social_engage">
                    <br /><br />
                </div>
                <div className="each-section p-top-40 margin_top">
                    <div className="main-container">
                        <div className="container-inner">
                            <div className="img-part">
                                <img className="social_image" src="/assets/img/adv-mobile3.png" alt="/assets/img/adv-mobile3.png" />
                            </div>
                            <div className="desc-part ">
                                <div className="desc">
                                    <div className="text_content">
                                    <h1>Your Club's Own News Feed</h1>
                                        <br />
                                        <p>Take advantage of our one-of-a-kind Members News Feed.</p>
                                        <br />
                                        <p>Keep your members engaged with your club!</p>
                                        <br />
                                    {/* <p>Even if you are already managing your club on any software and don’t want to switch, then fine, just ad-on the social engagement via Ballbutton Membership Advantage!
                                <br /> <span className="orange_font"> Fine if you have a “fridge.” Go bold, add a “wine fridge”!!! </span></p>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="schedule_demo schedule_demo_cutom">
                            <button onClick={this.openModalRequestDemo} className="button_c learn_more_btn">SCHEDULE PRIVATE DEMO</button>
                            {modalOpenedDemo}
                        </div>
                    </div>
                </div>
                <div className="purple-balls">
                    <div className="purple-balls-container">
                        <img
                            src="/assets/img/home-purple-balls.png"
                            alt="/assets/img/home-purple-balls.png"
                        />
                    </div>
                </div>
                <AthletesSlider />
                <div className="video-part">
                    <BbOptions />

                    {/*<div className="main-container">
            <div className="title-business">
              <h2>Short 2 Minute Video Teaser</h2>
            </div>
            <div className="video" onClick={this.openBBVideo}>
              <img src='/assets/img/court-sheet-new.png' alt='/assets/img/court-sheet-new.png' />
            </div>
          </div>*/}
                </div>
                <div className="purple-balls">
                    <div className="purple-balls-container">
                        <img
                            src="/assets/img/home-purple-balls.png"
                            alt="/assets/img/home-purple-balls.png"
                        />
                    </div>
                </div>
                <div className="demo-footer">
                    <div className="demo-footer-bg">
                        <img
                            src="/assets/img/demo-footer.jpg"
                            alt="/assets/img/demo-footer.jpg"
                        />
                    </div>
                    <RequestDemoCalendly />
                </div>
                {bb_video}
            </div>
        );
    }
}

export default Racquets;
