import React, { Component } from "react";
import PropTypes from "prop-types";
import { momentLocalizer, Calendar } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { accessor } from "react-big-calendar/lib/utils/accessors";
import moment from "moment-timezone";

import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";

const BigCalendar = withDragAndDrop(Calendar);
// const BigCalendar = Calendar;

// remember the browser's local timezone, as it might by used later on
BigCalendar.tz = moment.tz.guess();
// format all dates in BigCalendar as they would be rendered in browser's local timezone (even if later on they won't)
const m = (...args) => {
  return moment.tz(...args, BigCalendar.tz);
};
m.localeData = moment.localeData;

const localizer = momentLocalizer(m);

export const convertDateTimeToDate = (datetime, timeZone) => {
  const m = moment.tz(datetime, timeZone);
  return new Date(m.year(), m.month(), m.date(), m.hour(), m.minute(), 0);
};

export const convertDateToDateTime = (date, timeZone) => {
  const dateM = moment.tz(date, BigCalendar.tz);
  return moment.tz(
    {
      year: dateM.year(),
      month: dateM.month(),
      date: dateM.date(),
      hour: dateM.hour(),
      minute: dateM.minute(),
    },
    BigCalendar.tz
  );
};

export const convertDateToZonedDate = (date, timeZone) => {
  const dateM = moment.tz(date, BigCalendar.tz);
  return moment.tz(
    {
      year: dateM.year(),
      month: dateM.month(),
      date: dateM.date(),
      hour: dateM.hour(),
      minute: dateM.minute(),
    },
    timeZone
  );
};

class TimeZoneAgnosticBigCalendar extends Component {
  static propTypes = {
    events: PropTypes.array,
    onSelectSlot: PropTypes.func,
    onEventDrop: PropTypes.func,
    timeZone: PropTypes.string,
    startAccessor: PropTypes.string,
    endAccessor: PropTypes.string,
  };

  static defaultProps = {
    startAccessor: "start",
    endAccessor: "end",
  };

  componentDidMount() {
    this.contElem = document.querySelector(
      ".rbc-addons-dnd.rbc-calendar .rbc-time-content"
    );
    if (!this.contElem) return;
    this.contElem.addEventListener("scroll", this.handleScroll);

    try {
      const coords = JSON.parse(
        window.localStorage.getItem("COURT_SHEET_SCROLL")
      );
      if (coords) {
        setTimeout(() => {
          this.scrollTo(coords.x, coords.y);
        }, 1000);
      }
    } catch {
    } finally {
      this.contElem.style.scrollBehavior = "smooth";
    }
  }

  componentWillUnmount() {
    if (!this.contElem) return;
    this.contElem.removeEventListener("scroll", this.handleScroll);
  }

  render() {
    const {
      onSelectSlot,
      onEventDrop,
      onEventResize,
      timeZone,
      ...props
    } = this.props;
    const bigCalendarProps = {
      ...props,
      localizer,
      startAccessor: this.startAccessor,
      endAccessor: this.endAccessor,
      resizableAccessor: this.resizableAccessor,
      onSelectSlot:
        onSelectSlot &&
        (({ start, end, slots, ...rest }) => {
          onSelectSlot({
            ...rest,
            start: convertDateToZonedDate(start, timeZone),
            end: convertDateToZonedDate(end, timeZone),
            slots: slots.map((date) => convertDateToZonedDate(date, timeZone)),
          });
        }),
      onEventDrop:
        onEventDrop &&
        (({ event, start, end, ...rest }) => {
          onEventDrop({
            ...rest,
            event,
            start: convertDateToZonedDate(start, timeZone),
            end: convertDateToZonedDate(end, timeZone),
          });
        }),
      onEventResize:
        onEventResize &&
        (({ event, start, end, ...rest }) => {
          onEventResize({
            event,
            start: convertDateToZonedDate(start, timeZone),
            end: convertDateToZonedDate(end, timeZone),
            ...rest,
          });
        }),
    };
    return <BigCalendar {...bigCalendarProps} />;
  }

  handleScroll = (e) => {
    window.localStorage.setItem(
      "COURT_SHEET_SCROLL",
      JSON.stringify({
        x: e.target.scrollLeft,
        y: e.target.scrollTop,
      })
    );
  };

  scrollTo = (x, y) => {
    if (!this.contElem) return;
    this.contElem.scrollTo(x, y);
  };

  scrollLeft = () => {
    if (!this.contElem) return;

    const currScroll = {
      x: this.contElem.scrollLeft,
      y: this.contElem.scrollTop,
    };

    const minVal = 0;

    this.contElem.scrollTo(
      Math.max(currScroll.x - this.contElem.offsetWidth, minVal),
      currScroll.y
    );
  };

  scrollRight = () => {
    if (!this.contElem) return;

    const currScroll = {
      x: this.contElem.scrollLeft,
      y: this.contElem.scrollTop,
    };

    const timeGutterWidth = this.contElem.querySelector(".rbc-time-gutter")
      .clientWidth;

    const maxVal = this.contElem.scrollWidth - this.contElem.clientWidth;

    this.contElem.scrollTo(
      Math.min(
        currScroll.x + this.contElem.clientWidth - timeGutterWidth,
        maxVal
      ),
      currScroll.y
    );
  };

  startAccessor = (event) => {
    const start = accessor(event, this.props.startAccessor);

    return convertDateTimeToDate(start, this.props.timeZone);
  };
  resizableAccessor = (event) => {
    return false;
  };

  endAccessor = (event) => {
    const end = accessor(event, this.props.endAccessor);
    return convertDateTimeToDate(end, this.props.timeZone);
  };
}

export default TimeZoneAgnosticBigCalendar;
