import {
  ADD_ROLE_SUCCESS,
  ROLE_LOADING,
  GET_ROLE_SUCCESS,
  UPDATE_ROLE_SUCCESS
} from '../constants/Roles';

const initStore = {
  loading: false,
  data: [],
};
const RolesReducer = (store = initStore, action) => {
  switch (action.type) {
    case ADD_ROLE_SUCCESS:
      return Object.assign({}, store, {
        data: [...store.data, action.payload]
      });
    case ROLE_LOADING:
      return Object.assign({}, store, {
        loading: action.payload
      });
    case GET_ROLE_SUCCESS:
      return Object.assign({}, store, {
        data: [...action.payload]
      });
    case UPDATE_ROLE_SUCCESS:
      return Object.assign({}, store, {
        data: [...store.data.map(item => {
          if (item.id === action.payload.id) {
            return action.payload;
          } else {
            return item;
          }
        })]
      });
    default:
      return store;
  }
};



export default RolesReducer;
