import React, { useEffect } from "react";
import PropTypes from "prop-types";
import "./style.scss";
// import Header from '../../../components/Shop/Header';
import Header from "../../../components/Header";

const ShopRoute = (props) => {
  useEffect(() => {
    const appElm = document.querySelector("#app");

    appElm.classList.add("app-bootstrap-support");

    return () => {
      appElm.classList.remove("app-bootstrap-support");
    };
  });

  return (
    <div className="app-shop-route app-content" id="app-content">
      <Header
        signOut={props.signOut}
        manage_lessons={props.manage_lessons}
        isSignIn={props.isSignIn}
        userType={props.userType}
      />

      {props.children}
    </div>
  );
};

ShopRoute.propTypes = {
  isSignIn: PropTypes.bool.isRequired,
  signOut: PropTypes.func.isRequired,
  userType: PropTypes.string.isRequired,
};

export default ShopRoute;
