import {
  ADD_POINTS_SUCCESS,
  POINTS_LOADING,
  GET_POINTS_SUCCESS,
  UPDATE_POINTS_SUCCESS
} from '../constants/Points';

const initStore = {
  loading: false,
  data: [],
};
const PointsReducer = (store = initStore, action) => {
  switch (action.type) {
    case ADD_POINTS_SUCCESS:
      return Object.assign({}, store, {
        data: [...store.data, action.payload]
      });
    case POINTS_LOADING:
      return Object.assign({}, store, {
        loading: action.payload
      });
    case GET_POINTS_SUCCESS:
      return Object.assign({}, store, {
        data: [...action.payload]
      });
    case UPDATE_POINTS_SUCCESS:
      return Object.assign({}, store, {
        data: [...store.data.map(item => {
          if (item.id === action.payload.id) {
            return action.payload;
          } else {
            return item;
          }
        })]
      });
    default:
      return store;
  }
};



export default PointsReducer;
