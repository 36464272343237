import React, { Component } from 'react';

const Days = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]; // index => month [0-11]
const Months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

// function isLeapYear(year) {
//   year = parseInt(year);
//   if (year % 4 != 0) {
//     return false;
//   } else if (year % 400 === 0) {
//     return true;
//   } else if (year % 100 === 0) {
//     return false;
//   } else {
//     return true;
//   }
// }

class DateOfBirth extends Component {
  state = {
    year: 1930,
    month: 1,
    day: 1,
  };

  handleChange = ({ target: { name, value } }) => {
    this.setState(st => {
      const newState = {
        ...st,
        [name]: value,
      };

      const { year, month, day } = {
        ...st,
        [name]: value,
      };

      this.props.onChange(`${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}T20:00:00.000Z`);

      return newState;
    });
  };

  componentDidMount() {
    const { year, month, day } = this.state;
    this.props.onChange(`${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}T20:00:00.000Z`);
  }

  render() {
    return (
      <>
        <div className="data-of-birth" ref={this.contRef}>
          <select name="year" value={this.state.year} onChange={this.handleChange}>
            {(() => {
              const list = [];
              const d = new Date();
              for (let i = 1930; i <= d.getFullYear(); i++) {
                list.push(
                  <option value={i} key={i}>
                    {i}
                  </option>
                );
              }
              return list;
            })()}
          </select>
          <select name="month" value={this.state.month} onChange={this.handleChange}>
            {(() => {
              const list = [];
              for (let i = 1; i < 13; i++) {
                list.push(
                  <option value={i} key={i}>
                    {Months[i - 1]}
                  </option>
                );
              }
              return list;
            })()}
          </select>
          <select name="day" value={this.state.day} onChange={this.handleChange}>
            {(() => {
              const list = [];
              for (let i = 1; i < Days[0] + 1; i++) {
                list.push(
                  <option value={i} key={i}>
                    {i}
                  </option>
                );
              }
              return list;
            })()}
          </select>
        </div>
      </>
    );
  }
}

export default DateOfBirth;
