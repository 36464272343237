export const GET_COURT_SUCCESS = 'GET_COURT_SUCCESS';
export const GET_COURT_ERROR = 'GET_COURT_ERROR';
export const GET_COURT_LOADING = 'GET_COURT_LOADING';
export const GET_COURT_LISTING_SUCCESS = 'GET_COURT_LISTING_SUCCESS';
export const RESET_SINGLE_LISTING = 'RESET_SINGLE_LISTING';

//WaitList Part

export const WAITLIST_LOADING_C = 'WAITLIST_LOADING_C';
export const WAITLIST_SUCCESS_C = 'WAITLIST_SUCCESS_C';
//Get Micro Settings Groups
export const MSGS_LOADING = 'MSGS_LOADING';
export const MSGS_SUCCESS = 'MSGS_SUCCESS';

//Get Checkins
export const CHECKINS_LOADING = 'CHECKINS_LOADING';
export const CHECKINS_SUCCESS = 'CHECKINS_SUCCESS';
export const CHECKINS_ADD_SUCCESS = 'CHECKINS_ADD_SUCCESS';
export const CHECKOUT_ADD_SUCCESS = 'CHECKOUT_ADD_SUCCESS';



//Attendance balance

export const ATTENDANCE_SUCCESS = 'ATTENDANCE_SUCCESS';
export const ATTENDANCE_LOADING = 'ATTENDANCE_LOADING';
export const BALANCE_SUCCESS = 'BALANCE_SUCCESS';
export const BALANCE_LOADING = 'BALANCE_LOADING';
