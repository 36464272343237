import axios from '../config/axios';
import {
  ASSIGN_PAY_LOADING,
  ASSIGN_PAY_SUCCESS,
  CHNAGE_PLAN_ID_SUCCESS,
  GET_PAY_TOKEN_ERROR,
  GET_PAY_TOKEN_SUCCESS,
  REACTIVATE_SUBSCRIPTION_SUCCESS,
} from '../constants/Pay';

import { IsSignIn } from './Auth';



export async function createpaymentauthtoken() {
  try {
    const res = await axios.post('user/payment/createpaymentauthtoken');
    return {
      type: GET_PAY_TOKEN_SUCCESS,
      payload: res.data.payload[0].paymentAuthToken,
    };
  } catch (error) {
    return {
      type: GET_PAY_TOKEN_ERROR,
      payload: '',
    };
  }
}

function assignSuccess(payload) {
  return {
    type: ASSIGN_PAY_SUCCESS,
    payload: payload,
  };
}

function assignPayLoading(payload) {
  return {
    type: ASSIGN_PAY_LOADING,
    payload: payload,
  };
}

function assignError(error) {
  return {
    type: 'ASSIGN_PAY_ERROR',
    payload: error,
  };
}

function changePLanIdSuccess(payload) {
  return {
    type: CHNAGE_PLAN_ID_SUCCESS,
    payload,
  };
}

function reactivateSubscriptionSuccess(payload) {
  return {
    type: REACTIVATE_SUBSCRIPTION_SUCCESS,
    payload,
  };
}

export function createpaymentmethod(token, promo = null) {
  return async (dispatch, getStore) => {
    try {
      dispatch(assignPayLoading(true));
      const res = await axios.post('payment/businesssubscription/create', {
        coupon: promo ? promo : null,
        token,
      });
      document.body.style.overflow = '';
      await window.modal.alert('Success', 'Success.');
      dispatch(assignSuccess(getStore().auth.selectedPlanId));
      dispatch(IsSignIn());
    } catch (error) {
      dispatch(assignError(error));
    } finally {
      dispatch(assignPayLoading(false));
    }
  };
}

export function changePlanId(planId, promo, history) {
  return async (dispatch, getStore) => {
    try {
      dispatch(assignPayLoading(true));
      const oldPlanId = getStore().auth.user.business.planId;
      const res = await axios.post('payment/upgrade', {
        planId,
        coupon: promo ? promo : null,
      });
      if (res.data.code !== 200) throw new Error(res.data.message);
      await window.modal.alert('Success', 'Success.');
      dispatch(changePLanIdSuccess(res.data.payload));
      dispatch(IsSignIn());
      history.push('/private/stripe');
    } catch (error) {
      await window.modal.alert('Error', error.response.data.message);
    } finally {
      dispatch(assignPayLoading(false));
    }
  };
}
export function reactivateSubscription(subscription_id) {
  return async (dispatch, getStore) => {
    try {
      dispatch(assignPayLoading(true));

      const res = await axios.post('payment/reactivatesubscription', {
        subscription_id: subscription_id,
      });
      if (res.data.code !== 200) throw new Error(res.data.message);
      await window.modal.alert('Success', 'Success.');
      dispatch(reactivateSubscriptionSuccess());
    } catch (error) {
      await window.modal.alert('Error', error.response.data.message);
    } finally {
      dispatch(assignPayLoading(false));
    }
  };
}
