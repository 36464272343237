'use strict';

import React from 'react';
import { ChromePicker } from 'react-color';

class ColorPicker extends React.Component {
  constructor(props) {
    super(props);
    const { color } = props;
    this.state = {
      displayColorPicker: false,
      color: color ? color : '#1bb1e4',
    };
  }

  componentDidUpdate(prevProps) {
    const { color } = this.props;

    if (color && color !== prevProps.color) {
      this.setState({
        color,
      });
    }
  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleChangeColor() {
    return color => {
      this.setState({
        color: color.hex,
      });
      this.props.handleChangeColor(color);
    };
  }
  invertColor(hex) {
    if (hex.indexOf('#') === 0) {
      hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
      throw new Error('Invalid HEX color.');
    }
    // invert color components
    var r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
      g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
      b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
    // pad each with zeros and return
    return '#' + this.padZero(r) + this.padZero(g) + this.padZero(b);
  }

  padZero(str, len) {
    len = len || 2;
    var zeros = new Array(len).join('0');
    return (zeros + str).slice(-len);
  }


  render() {
    const popover = {
      position: 'absolute',
      zIndex: '2',
    };
    const cover = {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    };
    return (
      <div>
        <div className="pretty-button">
          <span style={{ "margin-right": "3%" }}>Pick Color</span>
          <button
            type="button"
            style={{ background: this.state.color, color: this.state.color }}
            className="btn-extra btn-extra_color"
            onClick={this.handleClick}>
            color
          </button>
        </div>
        {this.state.displayColorPicker ? (
          <div style={popover}>
            <div style={cover} onClick={this.handleClose} />
            <ChromePicker color={this.state.color} onChange={this.handleChangeColor()} />
          </div>
        ) : null}
      </div>
    );
  }
}

export default ColorPicker;
