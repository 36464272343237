import {
  TAG_LOADING,
  GET_TAGS_SUCCESS,
  GET_TAG_SUCCESS,
  UPDATE_TAG_SUCCESS,
  DELATE_TAG_SUCCESS,
  RESET_TAG,
  RESET_TAGS,
} from '../constants/Tag';

const initStore = {
  loading: false,
  list: [],
  current: {},
};

const TagReducer = (store = initStore, action) => {
  switch (action.type) {
    case TAG_LOADING:
      return {
        ...store,
        loading: action.payload,
      };

    case RESET_TAG:
      return {
        ...store,
        current: { ...initStore.current },
      };

    case RESET_TAGS:
      return {
        ...store,
        list: [...initStore.list],
      };

    case GET_TAGS_SUCCESS:
      return {
        ...store,
        list: action.payload,
      };

    case GET_TAG_SUCCESS:
      return {
        ...store,
        current: action.payload,
      };

    case UPDATE_TAG_SUCCESS: {
      const index = store.list.findIndex(item => item.id === action.payload.id);

      if (index === -1) {
        return store;
      }

      return {
        ...store,
        list: [...store.list.slice(0, index), action.payload, ...store.list.slice(index + 1)],
        current: action.payload,
      };
    }

    case DELATE_TAG_SUCCESS: {
      const index = store.list.findIndex(item => item.id === action.payload.id);

      if (index === -1) {
        return store;
      }

      return {
        ...store,
        list: [...store.list.slice(0, index), ...store.list.slice(index + 1)],
      };
    }
    default:
      return store;
  }
};
export default TagReducer;
