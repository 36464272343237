export default (type, payload, meta) => ({
  type,
  payload,
  meta,
  // meta: Object.assign(
  //   {
  //     __HANDLE_ERROR__: true,
  //     __PARSABLE__: false,
  //   },
  //   meta
  // ),
});
