import React, { useCallback, useEffect, useState } from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { createEntity, getEntity, getEntityList, setEntityError, updateEntity } from '../../actions/Location';

const initialCreateState = {
  formData: {
    name: '',
    address: '',
  },
};

const initialUpdateState = {
  formData: {
    name: '',
  },
};

const LocationCreateForm = ({ isOpen, toggle }) => {
  const [{ formData }, setState] = useState(initialCreateState);
  const dispatch = useDispatch();
  const location = useSelector(state => state.location.entity.current);

  useEffect(() => {
    if (!isOpen) {
      setState(initialCreateState);
    }
  }, [isOpen]);

  const handleInputChange = useCallback(({ target: { type, name, value } }) => {
    setState(st => ({
      ...st,
      formData: {
        ...st.formData,
        [name]: value,
      },
    }));
  }, []);

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault();

      if (await dispatch(createEntity(formData))) {
        dispatch(getEntityList());
        toggle();
      }
    },
    [formData, dispatch, toggle]
  );

  const handleChangeAddress = address => {
    setState(st => ({ ...st, formData: { ...st.formData, address } }));
  };

  const handleSelectAddress = address => {
    geocodeByAddress(address)
      .then(async results => {
        return [getLatLng(results[0]), results[0].formatted_address, results[0].place_id];
      })
      .then(async ([_geo, formattedAddress, _place_id]) => {
        // geo.then(geo => {
        setState(st => ({
          ...st,
          formData: {
            ...st.formData,
            address: formattedAddress,
          },
        }));
        // });
      })
      .catch(error => dispatch(setEntityError(error.message)));
  };

  const handleAddressError = (status, clearSuggestions) => {
    dispatch(setEntityError('Please provide valid address'));
    clearSuggestions();
  };

  return (
    <form autoComplete="nope" className="form" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col">
          <h4 className="border-0 text-left mt-0 pt-0 mb-4"><center>Fill location information</center></h4>
        </div>
      </div>

      <div className="row">
        <div className="col col-8 mx-auto">
          <div className="location-form">
            <div className="form-group">
              <label className="text-left d-block w-100">Location (Business) Name*</label>
              <input
                type="text"
                className="form-control"
                required
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                placeholder="Location name"
              />
            </div>

            <PlacesAutocomplete
              value={formData.address}
              onChange={handleChangeAddress}
              onSelect={handleSelectAddress}
              onError={handleAddressError}>
              {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                <div className="form-group address-group">
                  <label className="text-left d-block w-100">Full Address*</label>
                  <input
                    required
                    {...getInputProps({
                      name: 'address',
                      placeholder: 'Search Places ...',
                      className: `location-search-input`,
                    })}
                  />
                  <div className="autocomplete-dropdown-container">
                    {suggestions.map(suggestion => {
                      const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? { backgroundColor: '#fafafa', cursor: 'pointer', padding: '8px' }
                        : { backgroundColor: '#ffffff', cursor: 'pointer', padding: '8px' };
                      return (
                        <div key={suggestion.index} {...getSuggestionItemProps(suggestion, { className, style })}>
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          </div>

          {location.error ? (
            <div className="alert alert-danger mt-4" role="alert">
              {location.error}
            </div>
          ) : null}
        </div>
      </div>

      <div className="row">
        <div className="col d-flex">
        <div className='pretty-button  px-4 d-block mr-auto'>
          <button type="button" className="red-button " onClick={toggle}>
            Cancel
          </button>
          </div>
          <div className='pretty-button px-4 d-block ml-auto'>
          <button disabled={location.loading} type="submit" className="green-button ">
            Save
          </button>
          </div>
        </div>
      </div>
    </form>
  );
};

const LocationUpdateForm = ({ itemId, isOpen, toggle }) => {
  const [{ formData }, setState] = useState(initialUpdateState);
  const dispatch = useDispatch();
  const location = useSelector(state => state.location.entity.current);

  const handleInputChange = useCallback(({ target: { type, name, value } }) => {
    setState(st => ({
      ...st,
      formData: {
        ...st.formData,
        [name]: value,
      },
    }));
  }, []);

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault();

      if (await dispatch(updateEntity(itemId, formData))) {
        dispatch(getEntityList());
        toggle();
      }
    },
    [formData, dispatch, toggle, itemId]
  );

  useEffect(() => {
    if (!isOpen) {
      setState(initialUpdateState);
    }
  }, [isOpen]);

  useEffect(() => {
    if (itemId) {
      dispatch(getEntity(itemId));
    }
  }, [dispatch, itemId]);

  useEffect(() => {
    if (location.data?.id == itemId) {
      setState(st => ({
        ...st,
        formData: {
          ...st.formData,
          name: location.data.name,
        },
      }));
    }
  }, [location, itemId]);

  return (
    <form autoComplete="nope" className="form" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col">
          <h4 className="border-0 text-left mt-0 pt-0 mb-4"><center>Fill location information</center></h4>
        </div>
      </div>

      <div className="row">
        <div className="col col-8 mx-auto">
          <div className="location-form">
            <div className="form-group">
              <label className="text-left d-block w-100">Location (Business) Name*</label>
              <input
                type="text"
                className="form-control"
                required
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                placeholder="Location name"
              />
            </div>
          </div>

          {location.error ? (
            <div className="alert alert-danger mt-4" role="alert">
              {location.error}
            </div>
          ) : null}
        </div>
      </div>

      <div className="row">
        <div className="col ">
        <div className='pretty-button  px-4 d-block mr-auto' style={{float:"left"}}>
          <button type="button" className="red-button " onClick={toggle}>
            Cancel
          </button>
          </div>
          <div className='pretty-button  px-4 d-block mr-auto' style={{float:"right"}}>
          <button disabled={location.loading} type="submit" className="green-button ">
            Save
          </button>
          </div>
        </div>
      </div>
    </form>
  );
};

const LocationFormModal = ({ isOpen, toggle, data = {} }) => {
  return (
    <div className="app-bootstrap-support">
      <div className={`modal location-modal ${isOpen ? 'active' : ''}`} onClick={toggle}>
        <div className="modal-content" onClick={e => e.stopPropagation()}>
          <div className="modal-header">
            <h3 className="p-0 m-0 text-left"><center>{`${data.editableItemId ? 'Update' : 'Create'} location`}</center></h3>
          </div>
          <div className="modal-body">
            {isOpen &&
              (data.editableItemId ? (
                <LocationUpdateForm isOpen={isOpen} toggle={toggle} itemId={data.editableItemId} />
              ) : (
                <LocationCreateForm isOpen={isOpen} toggle={toggle} />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationFormModal;
