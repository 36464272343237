import React, { Component } from "react";
import moment from "moment-timezone";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-responsive-list";
import "react-responsive-list/assets/index.css";

import RefundPaymentBtn from "../Buttons/RefundPaymentBtn";
import RefundPaymentAndReturnBtn from "../Buttons/RefundPaymentAndReturnBtn";
import "./invoice-modal.scss";
import { getActivePair } from "../../utils/facility";
class PosDetails extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      payment,
      order_details,
      user_id,
      member_id,
      params,
      from,
      closeDetailsModal,
      fromPayment
    } = this.props;
    const [activeLocation, activeFacilityGroup] = getActivePair();
    const timezone = activeLocation ? activeLocation.timezone : null;
   
    return (
      <Table>
        {fromPayment &&(
          <Thead>
            <Tr>
              <Th className="font-size-11" colSpan="4">Description</Th>
              <Th className="font-size-11">Amount</Th>
              <Th className="font-size-11">Tax amount</Th>
              <Th className="font-size-11">Refund</Th>
              <Th className="font-size-11">Quantity</Th>
              <Th className="font-size-11">Returned</Th>
              <Th className="font-size-11">Created At</Th>
              <Th className="font-size-11">#</Th>
            </Tr>
          </Thead>
        )}
        
        <Tbody>
          {order_details.map((d, j) => {
           // const total = (Number(d.price_p||d.price) + Number(d.tax_p||d.tax)) * d.count;
            const total = (Number(d.price_p||d.price) ) * d.count;
            let maxAmountForRefund = d.amount_refunded
              ? parseFloat(total - d.amount_refunded).toFixed(2)
              : total;

            return (
              <Tr key={j}>
                <Td  colSpan="4">{`${d?.unit?.model?.description} x${d.count}`}</Td>
                <Td> {parseFloat(Number(d.price).toFixed(10))}</Td>
                <Td> {parseFloat(Number(d.tax).toFixed(10))}</Td>
                {fromPayment &&(<Td> {parseFloat(Number(d.amount_refunded).toFixed(10))}</Td>)}
                <Td> {d.count}</Td>
                <Td> {d.returned_count}</Td>
                <Td>
                  {timezone
                    ? moment(d.createdAt)
                        .tz(timezone)
                        .format("ddd, MMM D, YYYY h:mma")
                    : ""}
                </Td>
                <Td>
                  {maxAmountForRefund > 0 && (
                  
                    <RefundPaymentBtn
                      is_item={true}
                      order_details_id={d.id}
                      item={payment}
                      user_id={user_id}
                      params={params}
                      closeDetailsModal={closeDetailsModal}
                      from={from}
                      permission={true}
                      member_id={member_id}
                      is_tax={d.tax_p > 0 ? d.tax_p : false||d.tax > 0 ? d.tax : false}
                      tax_percent={d?.unit?.tax?.percentage}
                      payment_id={payment.id}
                      amount={maxAmountForRefund ? maxAmountForRefund : null}
                    />
                   
                  )}
                  {maxAmountForRefund > 0 && (
                    <RefundPaymentAndReturnBtn
                      is_item={true}
                      order_details_id={d.id}
                      item={payment}
                      user_id={user_id}
                      params={params}
                      closeDetailsModal={closeDetailsModal}
                      from={from}
                      permission={true}
                      member_id={member_id}
                      is_tax={d.tax > 0 ? d.tax : false}
                      tax_percent={d?.unit?.tax?.percentage}
                      payment_id={payment.id}
                      count={d.count}
                      amount={maxAmountForRefund ? maxAmountForRefund : null}
                    />
                  )}
                </Td>
              </Tr>
            );
          })}
        </Tbody>
        
      </Table>
    );
  }
}

export default PosDetails;
