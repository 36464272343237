import {
  GET_COURTS_ERROR,
  GET_COURTS_LISTING_SUCCESS,
  GET_COURTS_LOADING,
  GET_COURTS_SUCCESS,
  GET_ADDONSBYID_SUCCESS,
  GET_LISTING_LOADING
} from '../constants/Courts';

const initStore = {
  loading: false,
  loadingListing: false,
  loadingWaitlist: false,
  data: [],
  addonsbyid: [],
  listing: [],
  error: null,
};
const CourtsReducer = (store = initStore, action) => {
  switch (action.type) {
    //   case CLEAR_ERRORS:
    //     return Object.assign({},store,{
    //       error: {},
    //       signup: {message : "",loading: store.signup.loading}
    //     });
    case GET_COURTS_LOADING:
      return Object.assign({}, store, {
        loading: action.payload,
      });

    case GET_LISTING_LOADING:
      return Object.assign({}, store, {
        loadingListing: action.payload,
      });
    case GET_COURTS_SUCCESS:
      return Object.assign({}, store, {
        data: action.payload,
      });
    case GET_ADDONSBYID_SUCCESS:
      return Object.assign({}, store, {
        addonsbyid: action.payload,
      });
    case GET_COURTS_LISTING_SUCCESS:
      return Object.assign({}, store, {
        listing: action.payload,
      });

    case GET_COURTS_ERROR:
      return Object.assign({}, store, {
        error: action.payload,
      });

    default:
      return store;
  }
};
export default CourtsReducer;
