import { GET_PAY_TOKEN_ERROR, GET_PAY_TOKEN_SUCCESS } from '../constants/Pay';

const initStore = {
  data: '',
};
const CourtsReducer = (store = initStore, action) => {
  switch (action.type) {
    case GET_PAY_TOKEN_SUCCESS:
      return Object.assign({}, store, {
        data: action.payload,
      });
    case GET_PAY_TOKEN_ERROR:
      return Object.assign({}, store, {
        data: '',
      });
    default:
      return store;
  }
};
export default CourtsReducer;
