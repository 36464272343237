import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import DatePicker from "react-datepicker";
import Switcher from "../UI/Switcher";
import GreenSwitcher from "../UI/GreenSwitcher";

import ModalInfo from "../ModalInfo";
import isEqual from "lodash/isEqual";
import Loading from "../UI/Loading";

const repeatType = {
  DAYLY: "DAILY",
  WEEKLY: "WEEKLY",
  BI_WEEKLY: "BI-WEEKLY",
  MONTHLY: "MONTHLY",
};

class Repeat extends Component {
  constructor(props) {
    super(props);
    let copy_data = localStorage.getItem("DUPLICATE_LESSON_DATA_FOR_REPEAT");
    copy_data = JSON.parse(copy_data);

    if (copy_data != null) {
      this.state = {
        occurrence: copy_data.occurrence ? copy_data.occurrence : true,
        repeatOn: copy_data.repeatOn ? copy_data.repeatOn : false,
        repeatType: copy_data.repeatType ? copy_data.repeatType : repeatType.DAYLY,
        weekDays: copy_data.weekDays ? copy_data.weekDays : [
          moment(this.props.currentDay).day() - 1 === -1
            ? 6
            : moment(this.props.currentDay).day() - 1,
        ],
        infoModal: copy_data.infoModal ? copy_data.infoModal : null,
        endDate: copy_data.endDate ? moment(copy_data.endDate) : moment(this.props.currentDay),
        ends: copy_data.ends ? moment(copy_data.ends) : moment(this.props.currentDay),
        endCountSelect: copy_data.endCountSelect ? copy_data.endCountSelect : 1,
        monthlyCase: copy_data.monthlyCase ? copy_data.monthlyCase : "1",
        repeateFormat: copy_data.repeateFormat ? copy_data.repeateFormat : 0,
        endstype: copy_data.endstype ? copy_data.endstype : "occurrence",
        monthly: copy_data.monthly ? copy_data.monthly : [moment(this.props.currentDay)],
      };
    } else {
      this.state = {
        occurrence: true,
        repeatOn: false,
        repeatType: repeatType.DAYLY,
        weekDays: [
          moment(this.props.currentDay).day() - 1 === -1
            ? 6
            : moment(this.props.currentDay).day() - 1,
        ],
        infoModal: null,
        endDate: moment(this.props.currentDay),
        ends: moment(this.props.currentDay),
        endCountSelect: 1,
        monthlyCase: "1",
        repeateFormat: 0,
        endstype: "occurrence",
        monthly: [moment(this.props.currentDay)],
      };
    }


    this.changeRepeateType = this.changeRepeateType.bind(this);
    this.handleChangeEndDate = this.handleChangeEndDate.bind(this);
    this.handleChangeEnds = this.handleChangeEnds.bind(this);
    this.selectDay = this.selectDay.bind(this);
    this.changeRepeateFormat = this.changeRepeateFormat.bind(this);
    this.showInfoModal = this.showInfoModal.bind(this);
    this.closeDell = this.closeDell.bind(this);
    this.showBookedDays = this.showBookedDays.bind(this);
    this.showAlertIfDiasbled = this.showAlertIfDiasbled.bind(this);
  }
 
  getWeekDayXthOfMonth(momentObj = moment("2-10-2018", "DD-MM-YYYY")) {
    const dayOfWeek = moment(momentObj).weekday();
    const dayNameOfWeek = moment(momentObj).format("dddd");
    const dayOfMonth = moment(momentObj).date();
    switch (true) {
      case 1 <= dayOfMonth && dayOfMonth <= 7:
        return {
          dayStr: dayNameOfWeek,
          day: dayOfWeek,
          indexWeekOfmonth: 1,
          indexWeekOfmonthStr: "firsth",
          date: dayOfMonth,
        };
      case 8 <= dayOfMonth && dayOfMonth <= 14:
        return {
          dayStr: dayNameOfWeek,
          day: dayOfWeek,
          indexWeekOfmonth: 2,
          indexWeekOfmonthStr: "second",
          date: dayOfMonth,
        };
      case 15 <= dayOfMonth && dayOfMonth <= 21:
        return {
          dayStr: dayNameOfWeek,
          day: dayOfWeek,
          indexWeekOfmonth: 3,
          indexWeekOfmonthStr: "third",
          date: dayOfMonth,
        };
      case 22 <= dayOfMonth && dayOfMonth <= 28:
        return {
          dayStr: dayNameOfWeek,
          day: dayOfWeek,
          indexWeekOfmonth: 4,
          indexWeekOfmonthStr: "fourth",
          date: dayOfMonth,
        };
      default:
        return {
          dayStr: dayNameOfWeek,
          day: dayOfWeek,
          indexWeekOfmonth: 5,
          indexWeekOfmonthStr: "last",
          date: dayOfMonth,
        };
    }
  }
  repeatOn = () => {
    if (!this.state.repeatOn) {
      this.setState({
        repeatOn: true,
        repeatType: repeatType.WEEKLY,
      });
      if(this.props.changeIsRecarenceOn)  this.props.changeIsRecarenceOn(true)
     
    } else {
      if(this.props.changeIsRecarenceOn) this.props.changeIsRecarenceOn(false)
      this.setState({
        repeatOn: false,
        repeatType: repeatType.DAYLY,
        weekDays: [
          moment(this.props.currentDay).day() - 1 === -1
            ? 6
            : moment(this.props.currentDay).day() - 1,
        ],
        endDate: moment(this.props.currentDay),
        monthly: [moment(this.props.currentDay)],
      });
    }
  };
  occurrenceChange() {
    return () => {
      this.setState((prevState) => ({ occurrence: !prevState.occurrence }));
      this.props.changeOccurenc();
    };
  }
  changeRepeateType(e) {
    if (e.target.name === "endCountSelect") {
      this.setState({
        [e.target.name]: e.target.value,
        endstype: "occurrence",
      });
    } else if (
      e.target.name === "repeatType" &&
      e.target.value === repeatType.DAYLY
    ) {
      this.setState({
        [e.target.name]: e.target.value,
        endstype: "dataPicker",
      });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  }
  handleChangeEndDate(endDate) {
    this.setState({ endDate });
  }
  handleChangeEnds(ends) {

    this.setState({ ends:moment(ends).utc() });
  }
  changeRepeateFormat(e) {
    this.setState({ repeateFormat: e.target.value });
  }
  repeatFormatsTypes() {

    return [
      { id: 0, displayName: "This event" },
      { id: 1, displayName: "This and following events" },
      { id: 2, displayName: "All events" },
    ];
  }
  async showAlertIfDiasbled(e) {
    if (e.currentTarget.classList.contains("disabled-div")) {
      await window.modal.alert(
        "Info",
        "Chose either “Do you want to repeat?” or “List Different Times” function"
      );
      return false;
    }
  }
  selectDay(indexOfDey) {
    const { weekDays } = this.state;
    if (weekDays.includes(indexOfDey)) {
      const newArrey = weekDays.filter((item) => item !== indexOfDey);
      this.setState({
        weekDays: newArrey,
      });
    } else {
      this.setState({
        weekDays: [indexOfDey, ...this.state.weekDays],
      });
    }
  }

  showInfoModal() {
    this.setState({
      infoModal: (
        <ModalInfo className={"innerRepeatLesson"} onClose={this.closeDell}>
          <div className="info-container">
            {" "}
            There is a booked event in this reoccurrence. You can make any
            changes for the whole chain except the reoccurrence, in order to
            avoid accidental cancellations of booked events. You can change the
            day of particular event on that event’s details page.
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={this.showBookedDays}
              className="italic-style"
            >
              See the booked event(s){" "}
            </span>
          </div>
        </ModalInfo>
      ),
    });
  }

  closeDell() {
    this.setState({
      infoModal: null,
      showInfo: true,
    });
  }

  async showBookedDays() {
    this.setState({
      infoModal: (
        <ModalInfo className={"innerRepeatLesson"} onClose={this.closeDell}>
          <div className="bookedDates-container">
            {!this.props.loadingBookedDates && <Loading />}
          </div>
        </ModalInfo>
      ),
    });
    await this.props.GetBookedLessonDates(
      this.props.lpid,
      this.props.occurrenceId
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.recurringInfo&&!isEqual({ ...prevState }, { ...this.state })) {
      if (this.props.onChangeRepeat) {
        this.props.onChangeRepeat({ ...this.state });
      }
    }
  }

  generateStateFromProps = (props) => {
    const {
      frequency,
      every,
      days,
      end_time,
      occurrences,
      byDayOfWeek,
      ends,
    } = props.recurringInfo;
    const timezone = props.timezone;
    return {
      occurrence: true,
      repeateFormat: 0,
      infoModal: null,
      repeatType:
        frequency.toUpperCase() === repeatType.WEEKLY && every === 2
          ? repeatType.BI_WEEKLY
          : frequency.toUpperCase(),
      weekDays:
        frequency.toUpperCase() === repeatType.WEEKLY
          ? days.map((item) => item)
          : [],
      endDate: end_time ? moment(end_time).tz(timezone) : moment().tz(timezone),
      endCountSelect: occurrences,
      monthlyCase: byDayOfWeek ? "2" : "1",
      endstype: !ends ? "occurrence" : "dataPicker",
      ends: ends ? moment(ends).tz(timezone) : moment(end_time).tz(timezone),
      monthly:
        frequency.toUpperCase() === repeatType.MONTHLY
          ? days.map((item) => moment(moment.tz(timezone).set("date", item)))
          : [moment(end_time).tz(timezone)],
    };
  };

  componentWillUpdate(nextProps, nextState) {

  }
  componentWillReceiveProps(nextProps) {
    const { lessonView } = this.props;
    if(this.state.ends.diff(moment(nextProps.currentDay).seconds(0).milliseconds(0))<0){
      this.setState((st) => ({ ...st, ends:moment(nextProps.currentDay).utc() }));
    }
    
    if (nextProps.recurringInfo && lessonView) {
      this.setState({
        ...this.generateStateFromProps(nextProps),
        repeatOn: nextProps.recurringInfo.frequency === "daily" ? false : true,
      });
    }
    if (
      nextProps.bookedDates &&
      nextProps.bookedDates.length > 0 &&
      nextProps.loadingBookedDates
    ) {
      this.setState({
        infoModal: (
          <ModalInfo
            className={"innerRepeatLesson autoMinHeight"}
            onClose={this.closeDell}
          >
            <div className="bookedDates-container">
              <table>
                <tbody>
                  {nextProps.bookedDates.map((v, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          <p>
                            {moment(v.starts)
                              .tz(v.timezone)
                              .format("MMM D, YYYY  h:mma")}
                          </p>
                        </td>
                        <td>-</td>
                        <td>
                          <p>
                            {moment(v.ends)
                              .tz(v.timezone)
                              .format("MMM D, YYYY  h:mma")}
                          </p>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </ModalInfo>
        ),
      });
    }

    if (nextProps.classNameForRepeat === "disabled-div") {
      this.setState({
        repeatOn: false,
        repeatType: repeatType.DAYLY,
        weekDays: [
          moment(this.props.currentDay).day() - 1 === -1
            ? 6
            : moment(this.props.currentDay).day() - 1,
        ],
        endDate: moment(this.props.currentDay),
        monthly: [moment(this.props.currentDay)],
      });
    }
  }

  componentDidMount() {
    if (this.props.hideSwitch) {
      this.repeatOn();
    }
  }

  generateSelectedTypeText(){
    if(this.state.repeatType=="WEEKLY"){
      return (this.state.endCountSelect>1?"Weeks":"Week")
    }else if(this.state.repeatType=="BI-WEEKLY"){
      return (this.state.endCountSelect>1?"Bi-weeks":"Bi-week")
    }else if(this.state.repeatType=="MONTHLY"){
      return (this.state.endCountSelect>1?"Months":"Month")
    }else if(this.state.repeatType=="DAILY"){
      return (this.state.endCountSelect>1?"Days":"Day")
    }
  }
  render() {

    const weekArray = moment
      .weekdaysMin(false)
      .slice(-6)
      .concat(moment.weekdaysMin(false)[0]);
      
    const minSelectDate = moment(this.props.currentDay);
    const maxSelectDate = moment(this.props.currentDay).add(12, "month");
    let endCountMax = 0;
    const { infoModal } = this.state;
    switch (this.state.repeatType) {
      case repeatType.BI_WEEKLY:
        endCountMax = 27;
        break;
      case repeatType.WEEKLY:
        endCountMax = 52;
        break;
      case repeatType.MONTHLY:
        endCountMax = 12;
        break;
      default:
        endCountMax = 0;
        break;
    }
   
    return (
      <React.Fragment>
        <div
          onClick={this.showAlertIfDiasbled}
          className={
            this.props.classNameForRepeat === "disabled-div"
              ? "form-group disabled-div"
              : this.props.disabled
                ? "disabledRepiat mod-item form-group"
                : "form-group"
          }
        >
          <label className="switcherLabel">
            Do you want to repeat?
            <GreenSwitcher
              value={this.state.repeatOn}
              checked={this.state.repeatOn}
              onChange={this.repeatOn}
            />
            {this.state.repeatOn ? <span>yes</span> : <span>no</span>}
          </label>
        </div>

        <React.Fragment>
          {!this.props.hideSwitch ? (
            <div
              className={`form-group ${this.props.disabled ? "disabledRepiat mod-item" : ""
                } `}
            >
              <label style={{ display: "none" }}>
                Allow players to book
                <Switcher
                  value={this.state.occurrence}
                  checked={this.state.occurrence}
                  onChange={this.occurrenceChange()}
                />
                <span style={{ minWidth: "209px" }}>
                  {this.state.occurrence
                    ? "by Single occurrence"
                    : "Only all occurrences together"}
                </span>
              </label>
            </div>
          ) : null}
          <div
            className={`mod-item ${this.props.disabled || !this.state.repeatOn
              ? "disabledRepiat"
              : ""
              }`}
          >
            <div className="repeatOn">
              <div
                className="repeatTypeWrapper"
                style={{ position: "relative" }}
              >
                {this.props.isBooked && (
                  <span className="index-z-info">
                    {" "}
                    <img
                      onClick={this.showInfoModal}
                      className="italic-style-img"
                      src="/assets/img/info_icon.png"
                      title="Info about reoccurrence"
                      alt="Info about reoccurrence"
                    />
                  </span>
                )}
                <span>Repeat on : </span>
                <select
                  name="repeatType"
                  id="repeatType"
                  value={this.state.repeatType}
                  onChange={this.changeRepeateType}
                >
                  <option value={repeatType.DAYLY}>{repeatType.DAYLY}</option>
                  <option value={repeatType.WEEKLY}>{repeatType.WEEKLY}</option>
                  <option value={repeatType.BI_WEEKLY}>
                    {repeatType.BI_WEEKLY + " (every other week)"}
                  </option>
                  <option value={repeatType.MONTHLY}>
                    {repeatType.MONTHLY}
                  </option>
                </select>
              </div>
              {(this.state.repeatType === repeatType.WEEKLY ||
                this.state.repeatType === repeatType.BI_WEEKLY) && (
                  <div className="weekdaysWrapper">
                    {weekArray.map((item, index) => {
                      if (this.state.weekDays.includes(index)) {
                        return (
                          <span
                            key={item}
                            onClick={() => {
                              this.selectDay(index);
                            }}
                            className="weekdays selected"
                          >
                            {" "}
                            {item}{" "}
                          </span>
                        );
                      } else {
                        return (
                          <span
                            key={item}
                            onClick={() => {
                              this.selectDay(index);
                            }}
                            className="weekdays"
                          >
                            {" "}
                            {item}{" "}
                          </span>
                        );
                      }
                    })}
                  </div>
                )}
              {this.state.repeatType === repeatType.MONTHLY && (
                <div className="monthWrapper">
                  <select
                    name="monthlyCase"
                    value={this.state.monthlyCase}
                    onChange={this.changeRepeateType}
                  >
                    <option value={1}>
                      {"Monthly on day " + this.state.monthly[0].format("D")}
                    </option>
                    {/*<option value={2}>
                        {'On every ' +
                          this.getWeekDayXthOfMonth(this.state.monthly[0]).indexWeekOfmonthStr +
                          ' ' +
                          this.state.monthly[0].format('dddd')}
                      </option>*/}
                  </select>
                </div>
              )}
              <div className="DatePickerWrapper">
                <span style={{float:"left"}}>Ends: </span>
                <br />
                <table>
                  <tbody>
                    <tr>
                      <td style={{ width: "10px", position: "relative" }}></td>
                      <td> Date</td>
                      <td>
                        <input
                          type="radio"
                          name="endstype"
                          id="endbyDate"
                          value="dataPicker"
                          checked={this.state.endstype === "dataPicker"}
                          onChange={this.changeRepeateType}
                        />
                      </td>
                      <td>
                        <label htmlFor="endbyDate">
                          <DatePicker
                            dateFormat="MMM D, YYYY"
                            onChange={this.handleChangeEnds}
                            onSelect={() => {
                              return false;
                            }}
                            selected={this.state.ends}
                            minDate={minSelectDate}
                            maxDate={maxSelectDate}
                            locale="en-gb"
                          />
                        </label>
                      </td>
                    </tr>
                    {this.state.repeatType !== repeatType.DAYLY && (
                      <React.Fragment>
                        <tr>
                          <td style={{ width: "10px" }} />
                          <td>After</td>
                          <td>
                            <input
                              type="radio"
                              name="endstype"
                              id="endbyCount"
                              value="occurrence"
                              checked={this.state.endstype === "occurrence"}
                              onChange={this.changeRepeateType}
                            />
                          </td>
                          <td>
                            <label htmlFor="endbyCount">
                              <select
                                name="endCountSelect"
                                id="endbyCount"
                                value={this.state.endCountSelect}
                                onChange={this.changeRepeateType}
                              >
                                {(function () {
                                  const tempArray = [];
                                  for (let i = 1; i <= endCountMax; i++) {
                                    tempArray.push(
                                      <option key={i} value={i}>
                                        {i}
                                      </option>
                                    );
                                  }
                                  return tempArray;
                                })()}
                              </select>{" "}
                              <span> occurrence <small>({this.generateSelectedTypeText()})</small></span>
                            </label>
                          </td>
                        </tr>
                      </React.Fragment>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {infoModal}
          </div>
        </React.Fragment>
      </React.Fragment>
    );
  }
}
Repeat.defaultProps = {
  recurringInfo: undefined,
  disabled: false,
};

Repeat.propTypes = {
  currentDay: PropTypes.objectOf(PropTypes.any).isRequired,
  recurringInfo: PropTypes.objectOf(PropTypes.any),
  disabled: PropTypes.bool,
};
export default Repeat;
