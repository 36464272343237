import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Route} from 'react-router-dom';
import NotFound from '../../../components/NotFound';

class PrivateRoute extends PureComponent {
    render() {
        if (this.props.loading) {
            return (<div/>);
        }
        if (this.props.isSignIn) {
            return (
                <Route path={this.props.path}>
                    {this.props.children}
                </Route>
            );
        } else {
            return (<NotFound/>);
        }
    }
}

PrivateRoute.propTypes = {
    isSignIn: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
};

export default PrivateRoute;
