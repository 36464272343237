import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import RequestModal from '../../RequestModal';
import Pricing from '../../Pricing';
import MetaTags from 'react-meta-tags';

class MacSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpened: null,
      modalOpenedCalendly: null
    };
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
  }

  closeModal() {
    this.setState({
      modalOpened: null,
    });
  }

  openModal() {
    this.setState({
      modalOpened: (<RequestModal opened={true} close={this.closeModal} />),
    });
  }


  render() {
    const { modalOpened } = this.state;
    return (
      <React.Fragment>
        <MetaTags>
          <title>Tennis Club Management Software | Ballbutton Enterprise</title>
          <meta name="description" content="Tennis court and club management software for the modern age. Ballbutton Enterprise lets you list and book tennis courts and manage tennis club members to maximize your club's revenue." />
        </MetaTags>
        <section className="mac-section" id="providers-court">
          <img className="img" src="/assets/img/web_play_background_long_1.gif" alt="ten" />
          <div className="main-container">
            <div className="mac-images">
              <div className="mac-img">

                <img src="/assets/img/courtsheet_in_mac.png" alt="Macbook" />

                <div className="message-section">
                  <p>
                    <span>
                      Use Court Management, Ballbutton Ace or Ballbutton Enterprise accounts to manage/grow your tennis club business:
                    </span>
                    <br /> <br />
                    (1) First and foremost, manage court bookings, programs, lessons, teams, members,
                      member retention, keep your current customers engaged, achieve repeated bookings,
                      and manage payments.
                  <br /> <br />
                    (2) List your excess capacity that stays unfilled by your members/customers to be booked by public.
                  <br /> <br />
                    You can use Ballbutton for both functionalities or for any of the two depending on your needs.
                   </p>

                </div>

              </div>
              <div className="iphone-c-img">
                <img src="/assets/img/iphone-light-c.png" alt="Iphone X" />
                <div className="download-app">
                  <div className="download-logo">
                    <a href="https://itunes.apple.com/us/app/ballbutton-enterprise/id1420755918?mt=8">
                      <img src="/assets/img/mac-logo.png" alt="ballbutton" />
                    </a>
                    <p>
                      Ballbutton Enterprise:
                    <br />
                      For tennis court, lesson and group managers
                  </p>
                  </div>

                  {/*<div>

                    <h3>Download the app</h3>
                    <ul>
                      <li>
                        <a href="https://itunes.apple.com/us/app/ballbutton-enterprise/id1420755918?mt=8">
                          <img src="/assets/img/app-store.png" alt="App Store" />
                        </a>
                      </li>
                    </ul>

                  </div>*/}

                </div>
              </div>
            </div>
            <div className="mac-bottom">
              <div className="mac-text">
                <div className="col">
                  {/* <h3>Ballbutton Enterprise: for court managers</h3> */}
                  {/* <div className="download-app">
                  <ul>
                    <li>
                      <img src="/assets/img/mac-logo.png" alt="ballbutton" />
                    </li>
                    <li>
                      <a href="#">
                        <img src="/assets/img/app-store.png" alt="App Store" />
                      </a>
                    </li>
                  </ul>
                </div> */}
                  {/* <p>
                  You must be a Ballbutton Enterprise customer to use the mobile app. Ballbutton Enterprise’s mobile app comes at No additional cost if you have BallButton Enterprise subscription. Just use your username and password to log in and enjoy!
                </p> */}
                </div>
                <div className="iphone-c-img">
                  <img src="/assets/img/iphone-light-c.png" alt="Iphone X" />
                </div>
                <div className="col">
                  {/* <p>
                  Ballbutton Enterprise will help you Grow and Maximize your Revenue. List and Manage Your Courts. Sell court time. Court-sheet: your courts in the palm of your hand. Instantly see when courts are booked, by who and other details. Customize you listings: you can custom list Court types, times, durations, price, location, and other customizations. Define multiple settings for court availability and booking. General settings for all courts and ability to edit each court’s info individually. Manage members and access. Allow Ballbutton users to discover your profile and your services. Allow Ballbutton users to book your courts. Invite your customers to book particular court with you. Easily schedule court time with your customers using state of the art user friendly Ballbutton scheduling tool. Track your activity and revenue in Analytics: see revenue growth during recent 12 months and hours booked dynamic during recent 12 months.
                </p> */}
                </div>
                <div className="mac-img">
                  {/* {!this.props.isSignIn && (
                  <div className="create-account">
                    <ul>
                      <li>
                        For Ballbutton Enterprise
                      <img src="/assets/img/right-arrow-head.png" alt="right" />
                      </li>
                      <li>
                        <Link to="/sign-up">Create Account</Link>
                      </li>
                    </ul>
                  </div>
                )} */}
                  <img src="/assets/img/macbook.png" alt="Macbook" />
                  {/*!this.props.isSignIn && (
                  <div className="demo-btn">
                    <button onClick={this.openModal}>Demo Request</button>
                  </div>
                )*/}
                </div>
              </div>
            </div>
            <Pricing onClickRequestDemo={this.openModal} />
          </div>
          {modalOpened}

        </section>
      </React.Fragment>
    );
  }
}

MacSection.propTypes = {
  isSignIn: PropTypes.bool.isRequired,
};
export default withRouter(
  MacSection
);