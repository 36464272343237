import React, { Component } from 'react';
import { connect } from 'react-redux';
import RefundInputPos from '../UI/RefundInputPos';
import Notification from '../Notification';




class PartialRefundPos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            count:props.count,
            isTax: props.isTax,
            tax_percent: props.tax_percent,
            error: {}
        };

        this.hendlerOnChange = this.hendlerOnChange.bind(this);
    }

    handleRefund(e) {
        e.preventDefaul();

    }

    refundCharge = async (e) => {
        e.preventDefault();
        const { count } = this.state;

        const _count = parseFloat(count); //+ tax;
        
        if (await this.props.refundCharge(this.props.paymentId, _count)) {
            this.props.updateRecentListDitels()
        }

    }

    hendlerOnChange(e, childState) {
       
        const { name, type, value: valueTarget, checked } = e.target;
        const value = type === 'checkbox' ? checked : valueTarget;
       
        this.setState(({ [name]: value }));
        if (childState) {
            this.setState({
                error: { ...this.state.error, ...childState }
            })
        }
    }

    render() {
        const { clouseModal, item } = this.props;
        const { amount,count, error, isTax, tax_percent } = this.state;

        return (
            <div className="modal app-bootstrap-support stripe-modal partial-refund active">
                <div className="modal-content">
                    <div className="close-btn">
                        <button onClick={clouseModal}>
                            <img src="/assets/img/cancel.png" alt="cancel" />
                        </button>
                    </div>
                    <div className="tax-container">
                        <form autoComplete="nope" onSubmit={this.refundCharge}>
                            <div className="make-bolder">Refund And Return</div>
                            {item && item.point === '1' && <div className="alert-box alert-warning">
                                <span>
                                    Only Unexpired Points Are Refundable
                                </span>
                            </div>}
                            <div className="form-group flex-group m-bottom-15">
                                <table className="table">
                                    <tr>
                                        {/* <td>
                                            <label className='notification-group refound_title notification-for-subtotal font-size-11'
                                                htmlFor="typeID">{isTax ? 'Subtotal' : 'Amount'}
                                                {isTax && <Notification className="whiteNotification">
                                                    In the United States the software will add the sales tax to subtotal amount.
                                                </Notification>
                                                }
                                            </label>
                                        </td> */}
                                        <td>
                                            <div className="field-flex-wrapper">
                                                <RefundInputPos required placeholder='Count' name='count'
                                                    maxValue={this.props.count} value={count}
                                                    onChange={this.hendlerOnChange} />
                                            </div>
                                        </td>
                                        <td>
                                            {this.props.count && <div className="field-flex-wrapper ">
                                                <p className="business_tax_rate">Max count {this.props.count} </p>
                                            </div>}
                                        </td>
                                    </tr>
                                    {/* <tr>
                                        <td></td>
                                        <td>

                                        </td>
                                        <td></td>
                                    </tr> */}
                                </table>
                                <div className="pretty-button  refound_btn">
                                    <button className="green-button" disabled={error.error ? true : false} type='submit'>Refund And Return</button>
                                </div>
                            </div>



                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

PartialRefundPos.propTypes = {};
PartialRefundPos.defaultProps = {
    stripeUserId: null,
    from: 'MEMBER',
    newMembersList: []
};
const mapStateToProps = (state) => {
    return {
        tax: state.auth.user.business.business_tax_rate ? state.auth.user.business.business_tax_rate.percentage : null
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(PartialRefundPos);
