import createAction from './create-action';
import axios from '../config/axios';
import * as actionTypes from '../constants/Membership';

// entity part
export const getMembershipInfo = (uid, params) => async dispatch => {
  dispatch(createAction(actionTypes.MEMBERSHIP_INFO_LOADING, true));

  try {
    const {
      data: { payload },
    } = await axios.get(`out/users/business/${uid}/memberships`, { params });

    dispatch(createAction(actionTypes.MEMBERSHIP_INFO_SUCCESS, payload));
    return true;
  } catch ({ response: { data } }) {
    dispatch(createAction(actionTypes.MEMBERSHIP_INFO_ERROR, data.message));
  } finally {
    dispatch(createAction(actionTypes.MEMBERSHIP_INFO_LOADING, false));
  }

  return false;
};

export const setEntityError = message => dispatch =>
  dispatch(createAction(actionTypes.MEMBERSHIP_CURRENT_ERROR, message));
