import React, { useState } from "react";
import moment from "moment-timezone";
import { getGroupList, updateGroupsOrdering } from "../../../actions/Facility";
import { useDispatch, useSelector } from "react-redux";
import FacilityFormModal from "./facility-form";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import Loading from "../../../components/UI/Loading";
import { useEffect } from "react";

const Facilities = (props) => {
  const [state, setState] = useState({
    editableItemId: null,
    isModalOpen: false,
  });

  const {
    timeZone,
    facilityGroup: {
      list: { data, loading },
    },
  } = useSelector((state) => ({
    timeZone: state.auth.user.business.timezone,
    facilityGroup: state.facility.group,
  }));

  const renderableData = [...data].sort(function (a, b) {
    if (a.order_index < b.order_index) {
      return -1;
    }
    if (a.order_index > b.order_index) {
      return 1;
    }
    return 0;
  });

  const dispatch = useDispatch();

  const handleCreate = () =>
    setState((st) => ({
      ...st,
      editableItemId: null,
      isModalOpen: true,
    }));

  const handleEdit = (item) =>
    setState((st) => ({
      ...st,
      editableItemId: item.id,
      isModalOpen: true,
    }));

  const handleCloseModal = () =>
    setState((st) => ({
      ...st,
      isModalOpen: false,
      editableItemId: null,
    }));

  useEffect(() => {
    dispatch(getGroupList());
  }, [dispatch]);

  const { isModalOpen, editableItemId } = state;

  return (
    <div className={`micro-settings freeze-list-container`}>
      <div className="table-responsive">
        {loading ? (
          <Loading />
        ) : (
          <DragDropContext
            onDragEnd={async (param) => {
              if (!param.destination) {
                return;
              }
              const srcIdx = param.source.index;
              const desIdx = param.destination.index;
              renderableData.splice(
                desIdx,
                0,
                renderableData.splice(srcIdx, 1)[0]
              );

              const data = renderableData.map((item, i) => ({
                id: item.id,
                order: i,
              }));

              await dispatch(updateGroupsOrdering({ order_index: data }));

              dispatch(getGroupList());
            }}
          >
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <table
                  ref={provided.innerRef}
                  id="players"
                  className="table table-bordered"
                >
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Location ID</th>
                      <th>Created At</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {renderableData.map((row, i) => {
                      return (
                        <Draggable
                          key={row.id}
                          draggableId={row.id}
                          index={i}
                          className="draggableRow"
                        >
                          {(provided, snapshot) => (
                            <tr
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                ...provided.draggableProps.style,
                                cursor: "move",
                                boxShadow: snapshot.isDragging
                                  ? "0 0 .4rem #666"
                                  : "none",
                                display: snapshot.isDragging
                                  ? "table"
                                  : undefined,
                                background: snapshot.isDragging
                                  ? "white"
                                  : undefined,
                              }}
                            >
                              <td>{row.id}</td>
                              <td>{row.name}</td>
                              <td>{row.location_id}</td>
                              <td>
                                {moment(row.createdAt)
                                  .tz(timeZone)
                                  .format("MMMM Do YYYY, h:mm:ss a")}
                              </td>
                              <td
                                className="d-flex"
                                style={{ justifyContent: "space-around" }}
                              >
                                <button
                                  disabled={props.permission ? false : true}
                                  className="btn btn-green rounded "
                                  type="button"
                                  onClick={() => handleEdit(row)}
                                >
                                  <img
                                    src="/assets/img/edit.png"
                                    width={15}
                                    height={15}
                                    alt="edit"
                                  />
                                </button>
                              </td>
                            </tr>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}

                    <tr>
                      <td colSpan={5}>
                        <button
                          className="btn btn-green rounded mx-auto"
                          type="button"
                          onClick={handleCreate}
                          disabled={props.permission ? false : true}
                        >
                          <img
                            src="/assets/img/plus.png"
                            width={20}
                            height={20}
                            alt="plus"
                          />
                        </button>
                      </td>
                    </tr>

                    {provided.placeholder}
                  </tbody>
                </table>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </div>
      <FacilityFormModal
        isOpen={isModalOpen}
        data={{ editableItemId }}
        toggle={handleCloseModal}
      />
    </div>
  );
};

export default Facilities;
