import React, { Component } from "react";
import PropTypes from "prop-types";
import { Switch, withRouter, Route } from "react-router-dom";
import { connect } from "react-redux";
import Loadable from "react-loadable";
import { SignIn, IsSignIn, SignOut } from "../../actions/Auth";
import { reactivateSubscription } from "../../actions/Pay";
import PrivateRoute from "./PrivateRoute";
import RouteGrup from "./RouteGrup";

import PayModal from "../PayModal";
import Homepage from "../../components/Homepage";
import BlueSection from "../../components/Homepage/BlueSection";
import BusinessPage from "../../components/BusinessPage";
import Yoga from "../../components/Yoga";
import Advantage from "../../components/Advantage";
import MobileTypeDetector from "../../components/MobileMarketQR";
import Golf from "../../components/Golf";
import Verified from "../../components/Verified";
import Racquets from "../../components/Racquets";
import BusinessSubscriptionsPage from "../../components/BusinessSubscriptionsPage";

import StripeConnect from "../Stripe";
import { hasPermission } from "../../utils/helpers";

import "moment/locale/en-gb";
import moment from "moment-timezone";
import ShopRoute from "./ShopRoute";
import Dashboard from "../Dashboard";
import Loading from '../../components/UI/Loading';

moment.locale("en-gb", {
  longDateFormat: {
    LT: "hh:mm A",
  },
});

// function Loading() {
//   return (
//     <div className="backblack"> 
//       {" "}
//       <div id="loader" />{" "}
//     </div>
//   );
// }
const ProfileCoach = Loadable({
  loader: () => import("../ProfileCoach"),
  loading: () => <Loading />,
});

const SignUpLesson = Loadable({
  loader: () => import("../SignUpLesson"),
  loading: () => <Loading />,
});
const SignUp = Loadable({
  loader: () => import("../SignUp"),
  loading: () => <Loading />,
});
// const Dashboard = Loadable({
//   loader: () => import("../Dashboard"),
//   loading: () => <Loading />,
// });
const NotFound = Loadable({
  loader: () => import("../../components/NotFound"),
  loading: () => <Loading />,
});

const TermsPrivacy = Loadable({
  loader: () => import("../../components/TermsPrivacy"),
  loading: () => <Loading />,
});
const Edit = Loadable({
  loader: () => import("../Edit"),
  loading: () => <Loading />,
});
const Profile = Loadable({
  loader: () => import("../Profile"),
  loading: () => <Loading />,
});
const ShopMain = Loadable({
  loader: () => import("../Shop").then((module) => module.ShopMain),
  loading: () => <Loading />,
});
const ShopControlPanel = Loadable({
  loader: () => import("../Shop").then((module) => module.ShopControlPanel),
  loading: () => <Loading />,
});
const ShopList = Loadable({
  loader: () => import("../Shop").then((module) => module.ShopList),
  loading: () => <Loading />,
});
const ShopItem = Loadable({
  loader: () => import("../Shop").then((module) => module.ShopItem),
  loading: () => <Loading />,
});
const ShopCart = Loadable({
  loader: () => import("../Shop").then((module) => module.ShopCart),
  loading: () => <Loading />,
});
const Settings = Loadable({
  loader: () => import("../Settings"),
  loading: () => <Loading />,
});
const Analytics = Loadable({
  loader: () => import("../Analytics"),
  loading: () => <Loading />,
});
const Reports = Loadable({
  loader: () => import("../Reports"),
  loading: () => <Loading />,
});
const Notifications = Loadable({
  loader: () => import("../Notifications"),
  loading: () => <Loading />,
});
const Members = Loadable({
  loader: () => import("../Members"),
  loading: () => <Loading />,
});
const PublicMembers = Loadable({
  loader: () => import("../PublicMembers"),
  loading: () => <Loading />,
});
const _UpdatePlan = Loadable({
  loader: () => import("../../components/UpdatePlan"),
  loading: () => <Loading />,
});
const MemberView = Loadable({
  loader: () => import("../MemberView"),
  loading: () => <Loading />,
});
const ProfileNbu = Loadable({
  loader: () => import("../ProfileNbu"),
  loading: () => <Loading />,
});
const ProfileNbuFamily = Loadable({
  loader: () => import("../ProfileNbuFamily"),
  loading: () => <Loading />,
});
const Lessons = Loadable({
  loader: () => import("../Lessons"),
  loading: () => <Loading />,
});

const Lesson = Loadable({
  loader: () => import("../Lesson"),
  loading: () => <Loading />,
});
const CourtListing = Loadable({
  loader: () => import("../CourtListing"),
  loading: () => <Loading />,
});
const Groups = Loadable({
  loader: () => import("../Groups"),
  loading: () => <Loading />,
});
const GroupItem = Loadable({
  loader: () => import("../GroupItem"),
  loading: () => <Loading />,
});
const GroupMember = Loadable({
  loader: () => import("../GroupMember"),
  loading: () => <Loading />,
});

const ThankYou = Loadable({
  loader: () => import("../../components/ThankYou"),
  loading: () => <Loading />,
});
const Widget = Loadable({
  loader: () => import("../../components/widget"),
  loading: () => <Loading />,
});
const EmailVerified = Loadable({
  loader: () => import("../../components/EmailVerified"),
  loading: () => <Loading />,
});
const ChooseBusiness = Loadable({
  loader: () => import("../../components/ChooseBusiness"),
  loading: () => <Loading />,
});
const ForAthletes = Loadable({
  loader: () => import("../../components/ForAthletes"),
  loading: () => <Loading />,
});
const Pricing = Loadable({
  loader: () => import("../../components/Pricing"),
  loading: () => <Loading />,
});
const WhyBallbutton = Loadable({
  loader: () => import("../../components/WhyBallbutton"),
  loading: () => <Loading />,
});

class Root extends Component {
  constructor(props) {
    super(props);
    /* eslint-disable */
    Array.prototype.remove = function () {
      var what,
        a = arguments,
        L = a.length,
        ax;
      while (L && this.length) {
        what = a[--L];
        while ((ax = this.indexOf(what)) !== -1) {
          this.splice(ax, 1);
        }
      }
      return this;
    };
    /* eslint-enable */
  }

  componentDidMount() {
    this.props.IsSignIn();

    if (this.props.location.pathname === "/lesson_management") {
      this.props.history.push("/");
    }
    if (this.props.location.pathname === "/terms") {
      window.location.replace(
        `${process.env.REACT_APP_BASE_URL}assets/file/terms-of-service.pdf`
      );
    }
    if (this.props.location.pathname === "/privacy") {
      window.location.replace(
        `${process.env.REACT_APP_BASE_URL}assets/file/privacy-policy`
      );
    }
  }

  render() {
    const loadingHtml =
      this.props.loading || this.props.loadingSignUp ? (
        <Loading />
        // <div className="backblack">
        //   {" "}
        //   <div id="loader" />{" "}
        // </div>
      ) : (
        ""
      );

    const { business = {} } = this.props.user;
    const { member_type = {} } = business;
    const { settings = {} } = member_type;
    const roles =
      this.props.user && this.props.user.member_type
        ? this.props.user.member_type.roles
        : [];

    const subscription = this.props.user.subscription
      ? this.props.user.subscription
      : null;
    const subscription_id = subscription ? subscription.subscription_id : null;
    const subscription_status = subscription ? subscription.status : null;
    const manage_lessons = settings ? settings.manage_lessons : false;
    const userType =
      this.props.user.member_type && this.props.user.member_type.key
        ? this.props.user.member_type.key
        : "owner";

    return (
      <>
        {loadingHtml}
        <Switch>
          <PrivateRoute
            isSignIn={this.props.isSignIn}
            loading={this.props.loading}
            path="/private/shop"
          >
            <ShopRoute
              signOut={this.props.SignOut}
              isSignIn={this.props.isSignIn}
              userType={userType}
            >
              <Switch>
                <Route path="/private/shop" exact component={ShopMain} />
                <Route
                  path="/private/shop/control-panel/:metaName?"
                  exact
                  component={ShopControlPanel}
                />
                <Route path="/private/shop/list" exact component={ShopList} />
                <Route
                  path="/private/shop/item/:itemId"
                  exact
                  component={ShopItem}
                />
                <Route path="/private/shop/cart" exact component={ShopCart} />
                <Route component={NotFound} />
              </Switch>
            </ShopRoute>
          </PrivateRoute>

          <PrivateRoute
            isSignIn={this.props.isSignIn}
            loading={this.props.loading}
            path="/private"
          >
            <RouteGrup
              signOut={this.props.SignOut}
              isSignIn={this.props.isSignIn}
              subscribed={this.props.user.premium}
              userType={userType}
              premium={this.props.user.premium}
              user={this.props.user}
              path="/private"
              manage_lessons={manage_lessons}
              subscription={subscription}
              reactivateSubscription={() =>
                this.props.reactivateSubscription(subscription_id)
              }
              business={business}
            >
              <Switch>
                <Route
                  path="/private/dashboard"
                  component={
                    hasPermission(roles, "court", "read") ? Dashboard : NotFound
                  }
                />
                <Route
                  path="/private/reports"
                  component={
                    hasPermission(roles, "analytics", "read")
                      ? Reports
                      : NotFound
                  }
                />
                <Route
                  path="/private/analytics"
                  component={
                    hasPermission(roles, "analytics", "read")
                      ? Analytics
                      : NotFound
                  }
                />
                <Route
                  path="/private/members"
                  component={
                    hasPermission(roles, "members", "read") ? Members : NotFound
                  }
                />
                <Route
                  path="/private/public-members"
                  component={
                    hasPermission(roles, "members", "read") ? PublicMembers : NotFound
                  }
                />
                <Route
                  path="/private/member/edit/:id"
                  component={
                    hasPermission(roles, "members", "read")
                      ? MemberView
                      : NotFound
                  }
                />
                <Route
                  path="/private/lessons/:type"
                  component={
                    hasPermission(roles, "lesson", "read") ? Lessons : NotFound
                  }
                />
                <Route
                  path="/private/lesson/:llid"
                  component={
                    hasPermission(roles, "lesson", "update") ||
                      userType === "member"
                      ? Lesson
                      : NotFound
                  }
                />
                <Route path="/private/court/:courtId" component={Edit} />
                <Route
                  path="/private/notifications"
                  component={
                    hasPermission(roles, "notifications", "read")
                      ? Notifications
                      : NotFound
                  }
                />
                <Route path="/private/updateplan" component={_UpdatePlan} />
                <Route
                  path="/private/choose-business"
                  component={ChooseBusiness}
                />
                <Route
                  path="/private/profile"
                  component={
                    userType !== "member" && userType !== "owner"
                      ? ProfileCoach
                      : userType === "owner"
                        ? Profile
                        : ProfileNbu
                  }
                />
                <Route
                  path="/private/family/:id"
                  component={
                    userType === "coach" || userType === "supercoach"
                      ? ProfileCoach
                      : userType === "member"
                        ? ProfileNbuFamily
                        : Profile
                  }
                />

                <Route path="/private/settings" component={Settings} />
                <Route
                  path="/private/group"
                  exact
                  component={
                    hasPermission(roles, "groups", "read") ? Groups : NotFound
                  }
                />
                <Route
                  path="/private/group/:id"
                  component={
                    hasPermission(roles, "groups", "read")
                      ? GroupItem
                      : NotFound
                  }
                />
                <Route
                  path="/private/groupmember/:id/:type?"
                  component={
                    hasPermission(roles, "groups", "read")
                      ? GroupMember
                      : NotFound
                  }
                />
                <Route
                  path="/private/stripe"
                  component={
                    hasPermission(roles, "payments", "read") &&
                      userType !== "member"
                      ? StripeConnect
                      : NotFound
                  }
                />
                <Route
                  path="/private/courtlisting/:id/:court_id"
                  component={
                    hasPermission(roles, "court", "update") ||
                      userType === "member"
                      ? CourtListing
                      : NotFound
                  }
                />
                <Route
                  path="/private/time_rule/:id/:court_id/:start_time/:end_time/:start/:end"
                  component={
                    hasPermission(roles, "court", "update") ||
                      userType === "member"
                      ? CourtListing
                      : NotFound
                  }
                />
                <Route path="/private/*" component={NotFound} />
              </Switch>
              {this.props.user.user_type === "bu" &&
                !this.props.user.premium ? (
                <PayModal
                  user={this.props.user}
                  subscription_status={subscription_status}
                  key={Date.now()}
                />
              ) : (
                ""
              )}
            </RouteGrup>
          </PrivateRoute>

          <RouteGrup
            signOut={this.props.SignOut}
            isSignIn={this.props.isSignIn}
            userType={userType}
          >
            <Switch>
              {this.props.selectedPlanId && this.props.demoUrlCode && (
                <Route
                  path="/sign-up"
                  component={
                    this.props.selectedPlanId === "lessonproviderplan"
                      ? SignUpLesson
                      : SignUp
                  }
                />
              )}
              <Route
                path={["/", "/login"]}
                exact
                render={() => <Homepage isSignIn={this.props.isSignIn} />}
              />
              <Route path="/thank-you" component={ThankYou} />
              <Route path="/widget" component={Widget} />
              <Route path="/email-verified" component={EmailVerified} />
              <Route path="/athlete" component={ForAthletes} />
              <Route path="/pricing-and-features" component={Pricing} />
              <Route path="/why-ballbutton" component={WhyBallbutton} />
              <Route path="/code/:code" component={Pricing} />
              <Route path="/find_tennis_players" component={BlueSection} />
              <Route
                path="/club_management_software"
                component={BusinessPage}
              />
              <Route
                path="/fitness_yoga_swim_cycle_gym"
                component={Yoga}
              />
              <Route
                path="/racquets"
                component={Racquets}
              />
              <Route
                path="/newsfeed"
                component={BusinessPage}
              />
              <Route
                path="/social_engage"
                component={BusinessPage}
              />
              <Route
                path="/membership_advantage"
                component={Advantage}
              />
              <Route
                path="/golf"
                component={Golf}
              />
              <Route path="/verified" component={Verified} />
              <Route
                path="/memberships/:uid"
                component={BusinessSubscriptionsPage}
              />
              <Route
                path="/mobile_app_market"
                component={MobileTypeDetector}
              />
              <Route path="/terms" component={TermsPrivacy} />
              <Route path="/privacy" component={TermsPrivacy} />
              <Route path="/*" component={NotFound} />
              
            </Switch>
          </RouteGrup>
        </Switch>
      </>
    );
  }
}

Root.propTypes = {
  IsSignIn: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  loadingSignUp: PropTypes.bool.isRequired,
  isSignIn: PropTypes.bool.isRequired,
  selectedPlanId: PropTypes.string,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  SignOut: PropTypes.func.isRequired,
};
Root.defaultProps = {
  selectedPlanId: "lessonproviderplan",
  userType: "owner",
};
const mapStateToProps = (state) => {
  return {
    isSignIn: state.auth.isSignIn,
    loading: state.auth.loading,
    selectedPlanId: state.auth.selectedPlanId,
    demoUrlCode: state.auth.demoUrlCode,
    user: state.auth.user,
    loadingSignUp: state.auth.signup.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    IsSignIn: () => dispatch(IsSignIn()),
    reactivateSubscription: (subscription_id) =>
      dispatch(reactivateSubscription(subscription_id)),
    SignIn: (userName, password) => dispatch(SignIn(userName, password)),
    SignOut: (router) => dispatch(SignOut(router)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Root));
