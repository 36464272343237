import React, { Component } from "react";

class TennisGuy extends Component {
  render() {
    return (
      <div className="tennis-guy">
        <img src="/assets/img/player-guy.png" alt="check" />
      </div>
    );
  }
}

export default TennisGuy;
