import React, { useState } from "react";
import ModalInfo from "../ModalInfo";
import "./ReceiptModal.styles.scss";
import axios from "../../config/axios";
import { parseInt } from "lodash";

const ReceiptModal = (props) => {
  const { data, onClose } = props;
  const [rdata, setData] = useState(0);
  
  const drowTable = (data) => {
    let orderItems = "";

    for (const value in data.order.details) {
      if (data.order.details[value].unit) {
        orderItems += (
          <tr>
            <td>
              <b>Name:</b>
            </td>
            <td>{data.order.details[value].unit.model.name}</td>
          </tr>
        );
      }
    }

    return orderItems;
  };
  if (!rdata) {
    axios.get(`payment/get/${parseInt(data.id)}`).then((ress) => {
      setData(ress.data.payload);
    });
  }
  const printTable = () => {
    window.print();

  }

  return (
    <ModalInfo onClose={onClose} className={"receipt-modal"}>
      <div
        className="modal_bg"
        style={{ backgroundImage: `url('/assets/img/modal_bg_img.png')` }}
      ></div>
      <div
        className={` app-bootstrap-support container-fluid ${
          rdata.amount ? "visible" : "hidden"
        }`}
      >
        <table className="table table-borderless">
          <caption>
            {/*<h2>Receipt from Ballbutton</h2>*/}
            <br />
          </caption>
          <tbody>
            <tr>
              <td>
                <b>Name:</b>
              </td>
              <td>{data.name}</td>
            </tr>
            {rdata.order && (
              <tr>
                <td>
                  <b>Order Items:</b>
                </td>
                <td>
                  <table className="table ordersTable">
                    <thead>
                      <tr>
                        <th>Item name</th>
                        <th>Item quantity</th>
                        <th>Item price</th>
                        <th>Item tax</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rdata.order.details.map((value, index) => {
                        return (
                          <tr>
                            <td>{value.unit.model.name}</td>
                            <td>{value.count}</td>
                            <td>
                              {value.price} {data.currency}
                            </td>
                            <td>
                              {value.tax} {data.currency}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </td>
              </tr>
            )}
            <tr>
              <td>
                <b>{!rdata.tax ? "Amount:" : "Subtotal"}</b>
              </td>
              <td>
                {rdata.amount} {data.currency}
              </td>
            </tr>
            <tr className={!rdata.tax ? "hidden" : ""}>
              <td>
                <b>Tax:</b>
              </td>
              <td>
                {rdata.tax} {data.currency}
              </td>
            </tr>
            <tr className={!rdata.tax ? "hidden" : ""}>
              <td>
                <b>Total:</b>
              </td>
              <td>
                {rdata.total ? rdata.total : rdata.amount} {data.currency}{" "}
              </td>
            </tr>
            <tr>
              <td>
                <b>Charge date:</b>
              </td>
              <td>{data.chargeDate}</td>
            </tr>
            {rdata.amount_refunded !="0.00"&&(
              <tr>
                <td><b>Refunded</b></td>
                <td>{rdata.amount_refunded} USD</td>
              </tr>
            )}
            <tr>
              <td>
                <b>View on Stripe:</b>
              </td>
              <td>
                <a
                  className="receiptLink"
                  target="_blank"
                  href={data.receiptUrl}
                >
                  Receipt
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <a onClick={printTable} className="receiptLink" href="#">Print</a>
              </td>
            </tr>
          </tbody>
        </table>

      </div>
    </ModalInfo>
  );
};

export default ReceiptModal;
