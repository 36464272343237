import React, { Component } from "react";
import { connect } from "react-redux";
import Loading from "../UI/Loading";
import FormGroup from "../FormGroup";
import { GetMember, getMemberBalance } from "../../actions/Members";
import "react-responsive-list/assets/index.css";
import { chargeCustomerWithPoint } from "../../actions/Stripe";
import AsyncSelect from "react-select/async";
import { searchUsers } from "../../actions/Members";
import { getChargeHistory } from "../../actions/ChargeHistory";
import "./modal-points.scss";

class ModalPoints extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
    this.hendlerChange = this.hendlerChange.bind(this);
  }

  getInitialState = () => {
    return {
      amount: (this.props.point?this.props.point:this.props.default_point
        ? this.props.default_point
        : this.props.booking
          ? this.props.booking.point_price
          : this.props.default_price
            ? this.props.default_price
            : ""),
      member: null,
      error: {},
      loading: false,
      note: "",
    };
  };

  componentDidMount() { }
  hendlerChange(e) {
    const { name, value } = e.target;
    let payman = false;
    switch (name) {
      case "name":
        payman = value.length > 2;
        break;
      case "price":
        payman = value > 0;
        break;
      default:
        break;
    }
    this.setState((prevState) => ({
      [name]: value,
      error: { ...prevState.error, [name]: !payman },
    }));
  }

  checkValidation(state) {
    const { amount, type } = state;
    const error = { success: true };
    if (!amount || !parseFloat(amount)) {
      error.success = false;
      error.amount = true;
    } else {
      error.amount = false;
    }

    return error;
  }

  handleChange = (e, field = null) => {
    if (!field) {
      const { [e.target.name]: error } = this.checkValidation(
        Object.assign(this.state, { [e.target.name]: e.target.value })
      );

      this.setState({
        [e.target.name]: e.target.value,
        error: Object.assign(this.state.error, { [e.target.name]: error }),
      });
    } else {
      this.setState({
        [field]: e,
      });
    }
  };

  Save = async () => {
    if (this.props.loadingCharge || this.state.loading) return;
    try {
      this.setState((st) => ({ ...st, loading: true }));
      const {
        closeModal,
        user,
        appointment_id,
        charge_for,
        from,
        cbCharge,
      } = this.props;

      const error = this.checkValidation(this.state);
      if (error.success) {
        const { amount, type, member, note } = this.state;

        const data = {
          appointment_id: appointment_id,
          amount: amount,
          description: note,
          balance: from && from.balance ? true : false,
          user_id: user
            ? user.id
            : member && member.member
              ? member.member.id
              : null,
          charge_for: charge_for,
        };

        if (cbCharge) {
          await cbCharge({
            ...data,
            method: "point",
          });
        } else {
          await this.props.chargeCustomerWithPoint(data);
        }

        if (from) {
          switch (from.type) {
            case "member_view":
              await this.props.GetMember(from.member_id);
              break;
            default:
              break;
          }
          if (from.balance) {
            await this.props.getMemberBalance(
              from.user_id,
              from.id,
              from.filters,
              from.is_staff
            );
          }

          return;
        } else {
          //If from Payments page
          if (!appointment_id) {
            this.props.getChargeHistory(0);
          }
        }

        this.setState({
          ...this.getInitialState(),
        });
        closeModal();
      } else {
        this.setState({ error: Object.assign(this.state.error, error) });
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.setState((st) => ({ ...st, loading: false }));
    }
  };

  render() {
    const { closeModal, loadingCharge, show_members, is_nbu } = this.props;
    const { amount, loading, note } = this.state;
    const { amount: error_amount } = this.state.error;
    return (
      <div className={`modal modal-points active ${this.props.className}`}>
        <div className="modal-content">
          <div className="close-btn">
            <button onClick={closeModal}>
              <img src="/assets/img/cancel.png" alt="cancel" />
            </button>
          </div>
          {(loadingCharge || loading) && <Loading />}
          <div className="cach-input-contaier">
            {show_members && (
              <div className="flex-col-100 member-drop">
                <label>Select Customer</label>
                <AsyncSelect
                  className={"input-with input-with-extra"}
                  cacheOptions
                  isClearable
                  placeholder={"Type..."}
                  value={this.state.member}
                  loadOptions={searchUsers}
                  onChange={(property, value) => {
                    this.setState({ member: property });
                  }}
                />
              </div>
            )}
            <div className="flex-col-100 w-100-between">
              <div className="each-div">
                <label>Point</label>
                <FormGroup
                  disabled={is_nbu}
                  onChange={this.handleChange}
                  className={`${error_amount ? "error" : ""}`}
                  type="text"
                  name="amount"
                  value={amount || ""}
                />
              </div>
              <div className="each-div">
                <label>Note</label>
                <FormGroup
                  disabled={is_nbu}
                  onChange={this.hendlerChange}
                  className={`${error_amount ? "error" : ""}`}
                  type="text"
                  name="note"
                  id="chargeNote"
                  value={note || ""}
                />
              </div>
            </div>
          </div>
          <div className="cach-input-contaier centered m-top-15">
            <div className="save-btn">
              <button
                disabled={loadingCharge || loading}
                className="green-button-simple"
                onClick={this.Save}
              >
                Charge
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loadingCharge: state.stripe.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    chargeCustomerWithPoint: (data) => dispatch(chargeCustomerWithPoint(data)),
    GetMember: (id, params = null) => dispatch(GetMember(id, params)),
    getMemberBalance: (user_id, member_id, filters = {}, is_staff = false) =>
      dispatch(getMemberBalance(user_id, member_id, filters, is_staff)),

    getChargeHistory: (last_id, cancel_token = null, params = null) =>
      dispatch(getChargeHistory(last_id, cancel_token, params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalPoints);
