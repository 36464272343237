import createAction from "./create-action";
import axios from "../config/axios";
import * as actionTypes from "../constants/Location";

// entity part
export const getEntityList = (params) => async (dispatch) => {
  dispatch(createAction(actionTypes.LOCATION_ENTITY_LIST_LOADING, true));

  try {
    const {
      data: { payload },
    } = await axios.get("location/entity", { params });
    dispatch(createAction(actionTypes.LOCATION_ENTITY_LIST_SUCCESS, payload));
    return true;
  } catch ({ response: { data } }) {
    dispatch(
      createAction(actionTypes.LOCATION_ENTITY_LIST_ERROR, data.message)
    );
  } finally {
    dispatch(createAction(actionTypes.LOCATION_ENTITY_LIST_LOADING, false));
  }

  return false;
};

export const getEntity = (id, params) => async (dispatch) => {
  dispatch(createAction(actionTypes.LOCATION_ENTITY_CURRENT_LOADING, true));

  try {
    const {
      data: { payload },
    } = await axios.get(`location/entity/${id}`, { params });
    dispatch(
      createAction(actionTypes.LOCATION_ENTITY_CURRENT_SUCCESS, payload)
    );
    return true;
  } catch ({ response: { data } }) {
    dispatch(
      createAction(actionTypes.LOCATION_ENTITY_CURRENT_ERROR, data.message)
    );
  } finally {
    dispatch(createAction(actionTypes.LOCATION_ENTITY_CURRENT_LOADING, false));
  }

  return false;
};

export const createEntity = (data) => async (dispatch) => {
  dispatch(createAction(actionTypes.LOCATION_ENTITY_CURRENT_LOADING, true));

  try {
    const {
      data: { payload },
    } = await axios.post("location/entity", data);
    dispatch(
      createAction(actionTypes.LOCATION_ENTITY_CURRENT_SUCCESS, payload)
    );
    return true;
  } catch ({ response: { data } }) {
    dispatch(
      createAction(actionTypes.LOCATION_ENTITY_CURRENT_ERROR, data.message)
    );
  } finally {
    dispatch(createAction(actionTypes.LOCATION_ENTITY_CURRENT_LOADING, false));
  }

  return false;
};

export const updateEntity = (id, data) => async (dispatch) => {
  dispatch(createAction(actionTypes.LOCATION_ENTITY_CURRENT_LOADING, true));

  try {
    const {
      data: { payload },
    } = await axios.put(`location/entity/${id}`, data);
    dispatch(
      createAction(actionTypes.LOCATION_ENTITY_CURRENT_SUCCESS, payload)
    );
    return true;
  } catch ({ response: { data } }) {
    dispatch(
      createAction(actionTypes.LOCATION_ENTITY_CURRENT_ERROR, data.message)
    );
  } finally {
    dispatch(createAction(actionTypes.LOCATION_ENTITY_CURRENT_LOADING, false));
  }

  return false;
};

export const deleteEntity = (id) => async (dispatch) => {
  dispatch(createAction(actionTypes.LOCATION_ENTITY_CURRENT_LOADING, true));

  try {
    const {
      data: { payload },
    } = await axios.delete(`location/entity/${id}`);
    dispatch(
      createAction(actionTypes.LOCATION_ENTITY_CURRENT_SUCCESS, payload)
    );
    return true;
  } catch ({ response: { data } }) {
    dispatch(
      createAction(actionTypes.LOCATION_ENTITY_CURRENT_ERROR, data.message)
    );
  } finally {
    dispatch(createAction(actionTypes.LOCATION_ENTITY_CURRENT_LOADING, false));
  }

  return false;
};

// checkIn part
export const getCheckInList = (locationId, params) => async (dispatch) => {
  dispatch(createAction(actionTypes.LOCATION_CHECKIN_LIST_LOADING, true));

  try {
    const {
      data: { payload },
    } = await axios.get(`checkin/get-by-locationid/${locationId}`, { params });
    dispatch(createAction(actionTypes.LOCATION_CHECKIN_LIST_SUCCESS, payload));
    return true;
  } catch ({ response: { data } }) {
    dispatch(
      createAction(actionTypes.LOCATION_CHECKIN_LIST_ERROR, data.message)
    );
  } finally {
    dispatch(createAction(actionTypes.LOCATION_CHECKIN_LIST_LOADING, false));
  }

  return false;
};

export const setEntityError = (message) => (dispatch) =>
  dispatch(createAction(actionTypes.LOCATION_ENTITY_CURRENT_ERROR, message));
