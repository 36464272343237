import {
  CHARGE_HISTORY_ERROR,
  CHARGE_HISTORY_LIMIT,
  CHARGE_HISTORY_LOADING,
  CHARGE_HISTORY_SUCCESS,
} from '../constants/ChargeHistory';

const initStore = {
  loading: false,
  data: [],
  lastObject: null,
  isEnd: false,
  error: {},
};
const AuthReducer = (store = initStore, action) => {
  if (!Array.prototype.last) {
    Array.prototype.last = function () {
      return this[this.length - 1];
    };
  }
  switch (action.type) {
    case CHARGE_HISTORY_LOADING:
      return Object.assign({}, store, {
        loading: action.payload,
      });
    case CHARGE_HISTORY_SUCCESS:
      let newStore = [];
      if (action.last_id === 0) {
        newStore = action.payload;
      } else {
        newStore = [...store.data, ...action.payload];
      }
      return Object.assign({}, store, {
        data: newStore,
        count: action.count,
        lastObject: newStore.last(),
        isEnd: action.payload.length < CHARGE_HISTORY_LIMIT,
      });
    case CHARGE_HISTORY_ERROR:
      return Object.assign({}, store, {
        error: action.payload,
      });
    default:
      return store;
  }
};
export default AuthReducer;
