export const SIGN_IN_LOADING = 'SIGN_IN_LOADING';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_ERROR = 'SIGN_IN_ERROR';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_ERROR = 'SIGN_UP_ERROR';
export const CHECK_USER_NAME = 'CHECK_USER_NAME';
export const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS';
export const SIGN_OUT_ERROR = 'SIGN_OUT_ERROR';
export const SIGN_UP_LOADING = 'SIGN_UP_LOADING';
export const GET_MEMBERSHIPS_SUCCESS = 'GET_MEMBERSHIPS_SUCCESS';
export const RESET_PASS_SUCCESS = 'RESET_PASS_SUCCESS';
export const RESET_PASS_ERROR = 'RESET_PASS_ERROR';
export const RESET_PASS_LOADING = 'RESET_PASS_LOADING';
export const CHANGE_PASS_SUCCESS = 'CHANGE_PASS_SUCCESS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const CHANGE_PLAN_ID_SELECED = 'CHANGE_PLAN_ID_SELECED';
export const SET_DEMO_URL_CODE = 'SET_DEMO_URL_CODE';
export const TAX_RATE_UPDATE_LOADING = 'TAX_RATE_UPDATE_LOADING';
export const TAX_RATE_UPDATE_SUCCESS = 'TAX_RATE_UPDATE_SUCCESS';
export const GET_SOURCIES_SUCCESS = 'GET_SOURCIES_SUCCESS';
export const ADD_SOURCIES_SUCCESS = 'ADD_SOURCIES_SUCCESS';
export const CHANGE_SOURCIES_SUCCESS = 'CHANGE_SOURCIES_SUCCESS';
export const REMOVE_CARD_SUCCESS = 'REMOVE_CARD_SUCCESS';
export const GET_NBU_FAMILIY_SUCCESS = 'GET_NBU_FAMILIY_SUCCESS';
export const SIGNED_SUCCESS = 'SIGNED_SUCCESS';
