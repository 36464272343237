import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import { connect } from "react-redux";
import {
  getalllisting,
  getCourts,
  sendMessage,
  addAppointmentToCourt,
  updateAppointmentToCourt,
  moveAppointmentInCourt,
  deleteAppointmentFromCourt,
  deleteRequrence,
  deleteTimeRuleFromCourt,
  addAppointmentComplex,
} from "../../actions/Courts";
import { getCourt, getCourtListing } from "../../actions/Court";
import { getCoach } from "../../actions/Coach";
import AddresTimezone from "../../components/AddresTimezone";
import RightColume from "../../components/RightColume";
import CourtSheet from "../../components/CourtSheet";
import Loading from "../../components/UI/Loading";
import {
  blockCoachAvailability,
  createMicroSettings,
  unBlockCoachAvailability,
} from "../../actions/MicroSettings";
import { getActivePair } from "../../utils/facility";
import { generateImgSrc } from "../../utils/helpers";
import { BookAvailability } from "../Availability";
import BlockAvailability from "../Availability/BlockAvailability";

class Dashboard extends Component {
  constructor(props) {
    super(props);

    const timeZone = props.business.timezone;

    this.interval = null;
    this.isScroll = true;
    this.rightColumnRef = React.createRef();
    this.state = {
      timeZone,
      currentDay: window.localStorage.getItem("datePickerSetedDay")
        ? moment(window.localStorage.getItem("datePickerSetedDay")).tz(timeZone)
        : moment().tz(timeZone),
      courtNum: props.business.fields_count,
      price: props.business.price,
      CourtList: [],
      isloading: false,
      courtcheck: [],
      horizoncheck: [],
      checkall: false,
      listied: {},
      toggleDate: false,
      modalActive: false,
      delModal: null,
      coach: "",
      family: "",
      isPast: false,
      openedModal: null,
    };

    this.addCortPending = this.addCortPending.bind(this);
    this.haveClassName = this.haveClassName.bind(this);
    this.haveListedStatus = this.haveListedStatus.bind(this);
    this.changeDate = this.changeDate.bind(this);
    this.toggleDatePicker = this.toggleDatePicker.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.hendlerChangeCourtCheck = this.hendlerChangeCourtCheck.bind(this);
    this.hendlerChangeHorizonCheck = this.hendlerChangeHorizonCheck.bind(this);
    this.selectAll = this.selectAll.bind(this);
    this.refreshDashboard = this.refreshDashboard.bind(this);

    this.closeDell = this.closeDell.bind(this);

    this.deleteTimeRuleFromCourt = this.deleteTimeRuleFromCourt.bind(this);
    this.deleteAppointmentFromCourt = this.deleteAppointmentFromCourt.bind(
      this
    );
  }

  componentWillMount() {
    document.addEventListener("scroll", this.scrolAndFixed);
  }

  componentDidMount() {
    this.setState({ isloading: true });

    this.props.getCourts(this.props.cpid);

    this.props.getalllisting(this.state.currentDay, this.state.coach);   

    this.interval = setInterval(this.refreshDashboard, 120000);

    if (["owner", "admin", "member","reception"].includes(this.props.userType)) {
      this.props.getCoach();
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({ isloading: false /*CourtList: []*/ });
  }

  componentDidUpdate() {
    if ((this.props.courts.length + 1) * 59.5 + 80 + 345 >= window.innerWidth) {
      document.querySelector(".dashboard").classList.add("closed");
    }

    for (let temp in this.refs) {
      const selector = this.refs[temp];
      selector.onclick = (e) => {
        this.addCortPending(e.target.dataset.forobj, e.target.dataset.courtid);
      };
      if (
        !selector.classList.contains("pending") &&
        !selector.classList.contains("past")
      ) {
        selector.className = "";
        selector.innerHTML = "";
      }
    }
    let selector = null;

    const booking_to_start = this.props.business?.booking_to_start || 30;

    this.props.listing.forEach((element) => {
      let diff = moment(element.ends).diff(moment(element.starts), "minutes");
      if (diff !== booking_to_start) {
        let temp = diff / booking_to_start;
        // let electorEnd = this.refs[`${element.courtid}@${moment(element.starts).add((temp-1)*30,'minutes').tz(this.state.timeZone).format()}`];
        // let selectorFirst = this.refs[`${element.courtid}@${moment(element.starts).tz(this.state.timeZone).format()}`];
        for (let i = 0; i < temp; i++) {
          selector = this.refs[
            `${element.courtid}@${moment(element.starts)
              .add(i * booking_to_start, "minutes")
              .tz(this.state.timeZone)
              .format()}`
          ];
          if (!selector) continue;

          selector.setAttribute("data-clid", element.clid);
          selector.onclick = (e) => {
            let dataset = e.target.dataset;
            if (e.target.tagName === "SPAN") {
              dataset = e.target.parentElement.dataset;
            }
            this.props.history.push(
              `/private/courtlisting/${dataset.clid}/${dataset.courtid}`
            );
          };
          if (!element.available) {
            selector.classList.add("notavaliable");
          }
          if (i === 0) {
            selector.classList.add("top");
          } else if (i === temp - 1) {
            selector.classList.add("bottom");
          } else {
            selector.classList.add("side");
          }
          if (element.status === "listed") {
            selector.classList.add("listed");
            selector.innerHTML = `<span>${element.status}</span>`;
          } else {
            selector.classList.add("booked");
            selector.innerHTML = `<span>${element.status}</span><span>${element.available ? element.bookedByName : ""
              }</span>`;
          }
        }
        return;
      }
      selector = this.refs[
        `${element.courtid}@${moment(element.starts)
          .tz(this.state.timeZone)
          .format()}`
      ];
      if (selector) {
        // if (!selector.classList.contains('past')) {
        selector.setAttribute("data-clid", element.clid);
        selector.onclick = (e) => {
          let dataset = e.target.dataset;
          if (e.target.tagName === "SPAN") {
            dataset = e.target.parentElement.dataset;
          }

          this.props.history.push(
            `/private/courtlisting/${dataset.clid}/${dataset.courtid}`
          );
        };

        if (element.status === "booked") {
          if (!element.available) {
            selector.classList.add("notavaliable");
          }
          selector.classList.add(element.status);
          selector.classList.add("all");
          selector.innerHTML = `<span>${element.status}</span><span>${element.available ? element.bookedByName : ""
            }</span>`;
        } else {
          selector.classList.add(element.status);
          selector.innerHTML = element.status;
        }
      }
    });
    if (this.isScroll) {
      // document.getElementById('scroll').scrollTop = 970;
      this.isScroll = false;
    }
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.scrolAndFixed);
    window.onresize = null;
    clearInterval(this.interval);
  }

  detectModalActive = (modal_active = false) => {
    if (modal_active) {
      clearInterval(this.interval);
    } else {
      this.interval = setInterval(this.refreshDashboard, 60000);
    }
  };

  closeModal = () => {
    this.setState((st) => ({
      ...st,
      modalActive: false,
      openedModal: null,
    }));
  };

  updateCourtSheetData = () => {
    setTimeout(() => {
      this.refreshDashboard();
      document.querySelector("#refreshPrivate").click()
      this.rightColumnRef.current?.filterAvailabilityRef.current?.handleSubmit();
    }, 1000);
  };

  openCoachAvailabilityBookModal = (props) => {
    
    this.setState((st) => ({
      ...st,
      modalActive: true,
      openedModal: (
        <BookAvailability
          {...props}
          family={this.state.family}
          onClose={() => {
            this.closeModal();
            this.updateCourtSheetData();
          }}
        />
      ),
    }));
  };

  blockCoachAvailability = async (data) => {
    this.setState((st) => ({
      ...st,
      modalActive: true,
      openedModal: ( 
        <BlockAvailability
          data={data}
          onClose={() => {
            this.closeModal();
            this.updateCourtSheetData();
          }}
        />
      ),
    }));
  };

  unBlockCoachAvailability = async (data) => {
    await this.props.unBlockCoachAvailability(data);
    this.updateCourtSheetData();
  };

  scrolAndFixed() {
    const fix2 = document.getElementById("fix2");

    if (158 - window.pageYOffset < 0) {
      fix2 && fix2.classList.add("fixed");
    } else {
      fix2 && fix2.classList.remove("fixed");
    }
  }

  hendlerChangeCourtCheck = (event) => {
    const { currentDay } = this.state;

    const booking_to_start = this.props.business?.booking_to_start || 30;

    const startOfDay = moment(currentDay)
      .tz(this.state.timeZone)
      .startOf("day")
      .add(6, "hours");
    const endfDay = moment(currentDay)
      .tz(this.state.timeZone)
      .add(1, "day")
      .startOf("day");
    const carrentTime = moment();
    const dataArray = [];
    for (
      let i = startOfDay;
      endfDay.diff(i) > 0;
      i.add(booking_to_start, "minutes")
    ) {
      if (
        !(carrentTime.set({ second: 0 }).diff(i, "minutes") > booking_to_start)
      ) {
        dataArray.push({
          starts: i.format(),
          ends: moment(i).add(booking_to_start, "minutes").format(),
        });
      }
    }
    const newArray = [];

    this.props.listing.forEach((item) => {
      if (moment(item.ends).diff(moment(item.starts)) === booking_to_start) {
        newArray.push({ courtid: item.courtid, starts: item.starts });
      } else {
        for (
          let i = moment(item.starts).tz(this.state.timeZone);
          moment(item.ends).diff(i) > 0;
          i.add(booking_to_start, "minutes")
        ) {
          newArray.push({ courtid: item.courtid, starts: i.format() });
        }
      }
    });
    const output = [];
    newArray.forEach(function (value) {
      var existing = output.filter(function (v, i) {
        return v.courtid == value.courtid;
      });
      if (existing.length) {
        var existingIndex = output.indexOf(existing[0]);
        output[existingIndex].listing = output[existingIndex].listing.concat(
          value.starts
        );
      } else {
        if (typeof value.starts == "string")
          output.push({ listing: [value.starts], courtid: value.courtid });
        else output.push({ listing: value.starts, courtid: value.courtid });
      }
    });
    const target = event.target;
    const onlyforCourt = output.find((item) => item.courtid === target.value);
    onlyforCourt &&
      onlyforCourt.listing.forEach((item) => {
        let index = dataArray.findIndex(
          (dataitem) =>
            dataitem.starts === moment(item).tz(this.state.timeZone).format()
        );
        if (index > -1) {
          dataArray.splice(index, 1);
        }
      });

    if (this.state.courtcheck.findIndex((item) => target.value === item) > -1) {
      this.state.courtcheck.remove(target.value);
      let index = this.state.CourtList.findIndex(
        (item) => item.courtid === target.value
      );
      if (index > -1) {
        this.state.CourtList.splice(index, 1);
      }
      this.state.courtcheck.remove(target.value);
      this.setState({
        courtcheck: [...this.state.courtcheck],
        checkall: false,
        CourtList: this.state.CourtList,
      });
    } else {
      const index = this.state.CourtList.findIndex(
        (item) => item.courtid === target.value
      );
      if (index > -1) {
        this.state.CourtList.splice(index, 1);
      }
      this.setState({
        courtcheck: [target.value, ...this.state.courtcheck],
        CourtList: [
          ...this.state.CourtList,
          { courtid: target.value, listing: dataArray },
        ],
      });
    }
  };

  hendlerChangeHorizonCheck = (event) => {
    // const { currentDay } = this.state;
    // const startOfDay = moment(currentDay)
    //   .tz(this.state.timeZone)
    //   .startOf('day')
    //   .add(6, 'hours');
    // const endfDay = moment(currentDay)
    //   .tz(this.state.timeZone)
    //   .endOf('day');
    // const carrentTime = moment();
    const target = event.target;

    const booking_to_start = this.props.business?.booking_to_start || 30;

    const dataArray = [];
    this.props.courts.forEach((item) => {
      dataArray.push({
        courtid: item.courtid,
        starts: target.value,
        ends: moment(target.value).tz(this.state.timeZone).format(),
      });
    });
    const newArray = [];
    this.props.listing.forEach((item) => {
      if (moment(item.ends).diff(moment(item.starts)) === booking_to_start) {
        newArray.push({ courtid: item.courtid, starts: item.starts });
      } else {
        for (
          let i = moment(item.starts).tz(this.state.timeZone);
          moment(item.ends).diff(i) > 0;
          i.add(booking_to_start, "minutes")
        ) {
          newArray.push({ courtid: item.courtid, starts: i.format() });
        }
      }
    });
    const newdata = dataArray.map((item) => {
      const index = newArray.findIndex(
        (item1) =>
          moment(item1.starts).tz(this.state.timeZone).format() ===
          item.starts && item.courtid === item1.courtid
      );
      if (index > -1) {
        return {};
      }
      return item;
    });

    if (
      this.state.horizoncheck.findIndex((item) => target.value === item) > -1
    ) {
      this.state.horizoncheck.remove(target.value);
      this.state.CourtList.forEach((item) => {
        const index = item.listing.findIndex(
          (item1) => item1.starts === target.value
        );
        if (index > -1) {
          item.listing.splice(index, 1);
        }
      });
      this.setState({
        horizoncheck: [...this.state.horizoncheck],
        checkall: false,
        CourtList: this.state.CourtList,
      });
    } else {
      newdata.forEach((item) => {
        if (item.courtid) {
          const index = this.state.CourtList.findIndex(
            (item1) => item1.courtid === item.courtid
          );
          if (index > -1) {
            const index1 = this.state.CourtList[index].listing.findIndex(
              (item2) => item2.starts === item.starts
            );
            if (index1 === -1) {
              this.state.CourtList[index].listing.push({
                starts: item.starts,
                ends: moment(item.starts)
                  .tz(this.state.timeZone)
                  .add(booking_to_start, "minutes")
                  .format(),
              });
            }
          } else {
            this.state.CourtList.push({
              courtid: item.courtid,
              listing: [
                {
                  starts: item.starts,
                  ends: moment(item.starts)
                    .tz(this.state.timeZone)
                    .add(booking_to_start, "minutes")
                    .format(),
                },
              ],
            });
          }
        }
      });
      this.setState({
        horizoncheck: [target.value, ...this.state.horizoncheck],
        CourtList: this.state.CourtList,
      });
    }
  };

  selectAll = (e) => {
    const booking_to_start = this.props.business?.booking_to_start || 30;

    if (this.state.checkall) {
      this.setState({
        checkall: false,
        courtcheck: [],
        horizoncheck: [],
        CourtList: [],
      });
    } else {
      const { currentDay } = this.state;
      const startOfDay = moment(currentDay)
        .tz(this.state.timeZone)
        .startOf("day")
        .add(6, "hours");
      const endfDay = moment(currentDay)
        .tz(this.state.timeZone)
        .add(1, "day")
        .startOf("day");
      const carrentTime = moment();
      const dataArray = [];
      for (
        let i = startOfDay;
        endfDay.diff(i) > 0;
        i.add(booking_to_start, "minutes")
      ) {
        if (
          !(
            carrentTime.set({ second: 0 }).diff(i, "minutes") > booking_to_start
          )
        ) {
          dataArray.push({
            starts: i.format(),
            ends: moment(i).add(booking_to_start, "minutes").format(),
          });
        }
      }

      const newArray = [];
      this.props.listing.forEach((item) => {
        if (moment(item.ends).diff(moment(item.starts)) === booking_to_start) {
          newArray.push({ courtid: item.courtid, starts: item.starts });
        } else {
          for (
            let i = moment(item.starts).tz(this.state.timeZone);
            moment(item.ends).diff(i) > 0;
            i.add(booking_to_start, "minutes")
          ) {
            newArray.push({ courtid: item.courtid, starts: i.format() });
          }
        }
      });
      const output = [];
      newArray.forEach(function (value) {
        var existing = output.filter(function (v, i) {
          return v.courtid == value.courtid;
        });
        if (existing.length) {
          var existingIndex = output.indexOf(existing[0]);
          output[existingIndex].listing = output[existingIndex].listing.concat(
            value.starts
          );
        } else {
          if (typeof value.starts == "string")
            output.push({ listing: [value.starts], courtid: value.courtid });
          else output.push({ listing: value.starts, courtid: value.courtid });
        }
      });
      const temp = this.props.courts.map((item) => {
        let dataArrayTemp = [...dataArray];
        const onlyforCourt = output.find(
          (itemtemp) => itemtemp.courtid === item.courtid
        );
        if (onlyforCourt) {
          onlyforCourt.listing.forEach((item) => {
            let index = dataArrayTemp.findIndex(
              (dataitem) =>
                dataitem.starts ===
                moment(item).tz(this.state.timeZone).format()
            );
            if (index > -1) {
              dataArrayTemp.splice(index, 1);
            }
          });
        }
        return { courtid: item.courtid, listing: dataArrayTemp };
      });
      this.setState({
        checkall: true,
        courtcheck: this.props.courts.map((item) => item.courtid),
        CourtList: temp,
        horizoncheck: dataArray.map((item) => item.starts),
      });
    }
  };

  refreshDashboard() {
   
    if (!this.state.modalActive){
      if(this.state.family){
       
        this.props.getalllisting(this.state.currentDay, "", this.state.family);
      }else{
        this.props.getalllisting(this.state.currentDay, this.state.coach);
      }
    }
     
  }

  addCortPending = (date, courtid) => {
    if (date === undefined && courtid === undefined) {
      return;
    }

    const booking_to_start = this.props.business?.booking_to_start || 30;

    let { CourtList, timeZone } = this.state;
    CourtList = [...CourtList];
    const courtindex = CourtList.findIndex(
      (court) => court.courtid === courtid
    );
    if (courtindex > -1) {
      let { listing } = CourtList[courtindex];
      listing = [...listing];
      const listingindex = listing.findIndex(
        (dateobj) => dateobj.starts === date
      );
      if (listingindex > -1) {
        listing.splice(listingindex, 1);
        CourtList[courtindex].listing = listing;
        this.setState({ CourtList });
      } else {
        listing.push({
          starts: date,
          ends: moment(date)
            .tz(timeZone)
            .add(booking_to_start, "minutes")
            .format(),
        });
        CourtList[courtindex].listing = listing;
        this.setState({ CourtList });
      }
    } else {
      CourtList.push({
        courtid,
        listing: [
          {
            starts: date,
            ends: moment(date)
              .tz(timeZone)
              .add(booking_to_start, "minutes")
              .format(),
          },
        ],
      });
      this.setState({ CourtList });
    }
  };

  haveClassName(date, courtid) {
    const { CourtList } = this.state;
    const courtindex = CourtList.findIndex(
      (court) => court.courtid === courtid
    );
    if (
      courtindex > -1 &&
      CourtList[courtindex].listing.findIndex(
        (dateobj) => dateobj.starts === date
      ) > -1
    ) {
      return true;
    }
    return false;
  }

  changeDate(selected) {
    this.setState((state) => {
      return {
        currentDay: selected,
        checkall: false,
        courtcheck: [],
        horizoncheck: [],
        CourtList: [],
      };
    });

    this.props.getalllisting(selected, this.state.coach, this.state.family);

    window.localStorage.setItem("datePickerSetedDay", selected.toISOString());
  }

  haveListedStatus(date, courtid) {
    const { listing } = this.props;
    const courtindex = listing.findIndex((itme) => {
      return (
        itme.courtid === courtid &&
        moment(itme.starts).tz(this.state.timeZone).format() === date
      );
    });
    if (courtindex > -1) {
      return listing[courtindex].clid;
    }
    return false;
  }

  toggleDatePicker() {
    this.setState({
      toggleDate: !this.state.toggleDate,
    });
  }

  closeDell() {
    this.setState({
      delModal: null,
    });
  }

  hendlerChangeCoach() {
    return (e) => {
      const value = e.target.value;
      this.setState({ coach: value });
      this.props.getalllisting(this.state.currentDay, value);
    };
  }

  hendlerChangeFamily = (v) => {
    
    this.setState({ family: v });
    this.props.getalllisting(this.state.currentDay, "", v);
  };

  /** Court sheet methods */
  addEvent = async (data) => {
    if (data.booked_by && data.booked_by.length > 0) {
      await this.props.addAppointmentComplex({
        ...data,
        note: "Book Spot Time",
      });
    } else {
      const { booked_by, assigned, tags, ...rest } = data;

      await this.props.createMicroSettings(
        {
          ...rest,
        },
        false
      );
    }

    await this.props.getalllisting(
      this.state.currentDay,
      this.state.coach,
      this.state.family
    );
  };

  getListings = async () => {
    await this.props.getalllisting(
      this.state.currentDay,
      this.state.coach,
      this.state.family
    );
  };

  async deleteAppointmentFromCourt(data) {
    await this.props.deleteAppointmentFromCourt(data, {
      currentDay: this.state.currentDay,
      coach: this.state.coach,
    });
  }
  async deleteTimeRuleFromCourt(data) {
    await this.props.deleteTimeRuleFromCourt(data, {
      currentDay: this.state.currentDay,
      coach: this.state.coach,
    });
  }
  deleteRequrence = async (data) => {
    await this.props.deleteRequrence(data, {
      currentDay: this.state.currentDay,
      coach: this.state.coach,
    });
  };

  updateEvent = (event, data, type) => {
    if (type === "microsettings") {
      this.props.moveAppointmentInCourt(
        {
          old_court_id: event.resourceId,
          court_id: data.court_id,
          old_start_time: moment(event.start).clone().utc().format(),
          old_end_time: moment(event.end).clone().utc().format(),
          start_time: moment(data.start).clone().utc().format(),
          end_time: moment(data.end).clone().utc().format(),
        },
        {
          currentDay: this.state.currentDay,
          coach: this.state.coach,
        }
      );
    } else {
      this.props.updateAppointmentToCourt(
        event.id,
        {
          start_time: data.start.utc().format(),
          end_time: data.end.utc().format(),
          courts: [data.court_id],
          old_court_id: event.resourceId,
          settings:
            event.record && event.record.MicroSettingsGroup
              ? event.record.MicroSettingsGroup.MicroSettings
              : [],
        },
        type,
        {
          currentDay: this.state.currentDay,
          coach: this.state.coach,
        }
      );
    }
  };

  render() {
    const { currentDay, timeZone, toggleDate, openedModal } = this.state;
    const { business } = this.props;

    if (this.state.isloading) {
      return <Loading />;
    }

    const [activeLocation, activeFacilityGroup] = getActivePair();

    const locationAddress = activeLocation?.address || "Loading";
    const locationPairName = `${activeLocation?.name || "Loading"} - ${activeFacilityGroup?.name || "Loading"
      }`;
    const profileImage = generateImgSrc(
      business.user_id,
      business.profile_image
    );

    return (
      <div className="dashboard">
        <AddresTimezone
          timeZone={this.state.timeZone}
          address={locationAddress}
          profileImage={profileImage}
          courtName={locationPairName}
        />
        <div className="table-content">
          <div className="table-container">
            {this.props.courts.length > 0 ? (
              <CourtSheet
                currentDay={currentDay}
                timeZone={timeZone}
                business={activeFacilityGroup}
                family={this.state.family}
                dateFormat={
                  activeFacilityGroup && activeFacilityGroup.am_pm
                    ? "hh:mm A"
                    : "HH:mm"
                }
                identity={this.props.identity}
                courts={this.props.courts}
                events={this.props.listing}
                coachAvailabilityList={this.props.coachAvailabilityList}
                blockCoachAvailability={this.blockCoachAvailability}
                unBlockCoachAvailability={this.unBlockCoachAvailability}
                coachList={this.props.coachList}
                addEvent={this.addEvent}
                getListings={this.getListings}
                detectModalActive={this.detectModalActive}
                updateEvent={this.updateEvent}
                deleteAppointmentFromCourt={this.deleteAppointmentFromCourt}
                deleteRequrence={this.deleteRequrence}
                deleteTimeRuleFromCourt={this.deleteTimeRuleFromCourt}
                refresh={this.refreshDashboard}
                loadingListing={this.props.loadingListing}
                onCoachAvailabilityBook={this.openCoachAvailabilityBookModal}
              />
            ) : (
              <h2 className="court-not-found">
                Please set facility Columns Count from{" "}
                <a href="/private/profile">profile</a> page{" "}
              </h2>
            )}
          </div>
          <div className={`toggle-date-btn  zIndexMax ${toggleDate ? "opened" : ""}`}>
            <button type="button" onClick={this.toggleDatePicker}>
              <img src="/assets/img/left-arrow.png" alt="toggle datepicker" />
            </button>
            <p>{this.state.currentDay.format("MMM D")}</p>
          </div>
          <div
            className={`lr-container ${toggleDate ? "opened" : ""}`}
            id="fix2"
          >
            <RightColume
              family={this.state.family>0?this.state.family:null}
              userType={this.props.userType}
              coachList={this.props.coachList}
              addEvent={this.addEvent}
              coach={this.state.coach}
              identity={this.props.identity}
              getListings={this.getListings}
              namespace={
                this.props.namespace === "undefined"
                  ? undefined
                  : this.props.namespace
              }
              changeCoach={this.hendlerChangeCoach()}
              changeFamily={this.hendlerChangeFamily}
              currentDay={this.state.currentDay}
              changeDate={this.changeDate}
              facility_grop_id={activeFacilityGroup}
              isloading={this.state.isloading}
              isEmptyCourtListing={async () => {
                if (this.state.CourtList.length > 0) {
                  if (
                    await window.modal.cnfirm(
                      "Are you sure?",
                      "Are you sure to switch date? This will reset your pending times as well."
                    )
                  ) {
                    return true;
                  }
                  return false;
                }
                return true;
              }}
              timeZone={this.state.timeZone}
              ref={this.rightColumnRef}
            />
          </div>
        </div>

        {this.state.delModal}
        {openedModal}
      </div>
    );
  }
}

Dashboard.propTypes = {
  business: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  court: PropTypes.objectOf(PropTypes.any).isRequired,
  courtlisting: PropTypes.objectOf(PropTypes.any).isRequired,
  cpid: PropTypes.string.isRequired,
  getCourts: PropTypes.func.isRequired,
  // getCourt: PropTypes.func.isRequired,

  getalllisting: PropTypes.func.isRequired,
  // getCourtListing: PropTypes.func.isRequired,
  courts: PropTypes.arrayOf(PropTypes.object).isRequired,
  listing: PropTypes.arrayOf(PropTypes.object).isRequired,
  loadingListing: PropTypes.bool.isRequired,

  sendMessage: PropTypes.func.isRequired,

  courtLoading: PropTypes.bool.isRequired,
  coachList: PropTypes.arrayOf(PropTypes.any).isRequired,
  namespace: PropTypes.string,
  userType: PropTypes.string,
};

Dashboard.defaultProps = {
  userType: "owner",
};

const mapStateToProps = (state) => {
  return {
    business: state.auth.user.business,
    identity: state.auth.user,
    namespace: state.auth.user.namespace,
    userType: state.auth.user.member_type.key,
    cpid: state.auth.user.uid,
    courts: state.courts.data,
    error: state.courts.error,
    listing: state.courts.listing,
    coachAvailabilityList: state.micro_settings.coachAvailability.list,
    loadingListing: state.courts.loadingListing,
    court: state.court.data,
    courtlisting: state.court.listing,
    courtLoading: state.court.loading,
    coachList: state.coach.data,
    facilityGroup: state.facility.group.current,
    // don't remove these props
    redux_location: state.location,
    redux_facility: state.facility,
  };
};

const mapDispatchToProps = (dispatch) => { 
  return {
    getCoach: () => dispatch(getCoach()),
    getCourts: (cpid) => dispatch(getCourts(cpid)),
    getCourt: (cpid, courtid) => dispatch(getCourt(cpid, courtid)),
    getCourtListing: (clid, courtid) =>
      dispatch(getCourtListing(clid, courtid)),

    addAppointmentComplex: (data, updateListingData) =>
      dispatch(addAppointmentComplex(data, updateListingData)),
    addAppointmentToCourt: (data, updateListingData) =>
      dispatch(addAppointmentToCourt(data, updateListingData)),
    createMicroSettings: (data, updateListingData = false) =>
      dispatch(createMicroSettings(data, updateListingData)),
    deleteAppointmentFromCourt: (data, updateListingData) =>
      dispatch(deleteAppointmentFromCourt(data, updateListingData)),
    deleteRequrence: (data, updateListingData = {}) =>
      dispatch(deleteRequrence(data, updateListingData)),
    deleteTimeRuleFromCourt: (data, updateListingData) =>
      dispatch(deleteTimeRuleFromCourt(data, updateListingData)),
    updateAppointmentToCourt: (id, data, type, updateListingData) =>
      dispatch(updateAppointmentToCourt(id, data, type, updateListingData)),
    moveAppointmentInCourt: (data = [], updateListingData) =>
      dispatch(moveAppointmentInCourt(data, updateListingData)),

    getalllisting: (currentDay, coach = "", family = undefined) =>
      dispatch(getalllisting(currentDay, coach, family)),

    sendMessage: (obj, text) => dispatch(sendMessage(obj, text)),
    unBlockCoachAvailability: (data) =>
      dispatch(unBlockCoachAvailability(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
