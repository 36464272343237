import React, { Component } from "react";
import _ from "lodash";
import Picky from "react-picky";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import {
  createFreeze,
  getMicroSettings,
  getWhoCanBook,
  resetMicroSetting,
} from "../../actions/MicroSettings";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  generateTimeListStartedFromByStep,
  roundDate,
} from "../../utils/helpers";
import GreenSwitcher from "../../components/UI/GreenSwitcher";

class Freeze extends Component {
  constructor(props) {
    super(props);
    this.moment = function (...args) {
      return moment.tz(...args, props.timeZone);
    };

    this.moment.__proto__ = moment;

    this.state = {
      selectedType: {
        id: "court",
        name: "courts",
      },
      cancel_on_freeze: false,
      types: [
        {
          id: "court",
          name: "courts",
        }
      ],
      courts: [],
      sport_ids: [...this.props.sport],
      lessonTypes: [],
      note: "",
      trueText: "",
      disableSave: false,
      showNumbers: false,
      numbersValue: "",
      redBorder: 0,
      startTime: moment().tz(props.timeZone).startOf("day"),
      endTime: roundDate(
        moment().tz(props.timeZone),
        30,
        moment().tz(props.timeZone).startOf("day")
      ),
      currentDay: moment.tz(this.props.timeZone),
      error: { success: true },
    };
    this.handleChangeOfTrueText = this.handleChangeOfTrueText.bind(this);
  }
  generateRundomText(length) {
    let result = '';
    // const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const characters = '123456789';
    const charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    this.setState({ trueText: result });
    this.setState({ disableSave: true });
    this.setState({ showNumbers: true });
  }
  handleChange() {
    return (e) => {
      let { name, value } = e.target;
      this.setState({ [name]: value });
      if (value) {
        this.generateRundomText(5)
      } else {
        this.setState({ disableSave: false });
        this.setState({ showNumbers: false });
        this.setState({ redBorder: 0 });
        this.setState({ numbersValue: "" });
      }

    };
  }
  handleChangeOfTrueText(e) {
    this.setState({ numbersValue: e.target.value });
    if (e.target.value.length == this.state.trueText.length) {
      if (e.target.value == this.state.trueText) {
        this.setState({ disableSave: false });
        this.setState({ redBorder: 2 });
      } else {
        this.setState({ redBorder: 1 });
      }
    }
  }
  handelSave = async (e) => {
    e.preventDefault();
    if(this.state.cancel_on_freeze){
      const res  = await window.modal.cnfirm("Confirm", "No refunds will be issued. You can refund manually.");
      if(!res) return
    }
    const {
      note,
      selectedType: freeze_for,
      courts,
      lessonTypes,
      startTime,
      endTime,
      sport_ids,
      cancel_on_freeze
    } = this.state;
    const { timeZone } = this.props;
    const start_end = {
      start_time: moment(startTime)
        .tz(timeZone)
        .set({
          second: 0,
          millisecond: 0,
        })
        .format(),
      end_time: moment(endTime)
        .tz(timeZone)
        .set({
          second: 0,
          millisecond: 0,
        })
        .format(),
    };
    const request_data = {
      note: note,
      cancel_on_freeze: cancel_on_freeze,
      freeze_for: freeze_for.id,
      courts: courts.map((v) => v.id),
      sport_ids: sport_ids.map((v) => v.id),
      filters: lessonTypes.map((v) => v.id),
      rules: [
        {
          ...start_end,
          days: [],
          multipleDates: [
            {
              ...start_end,
            },
          ],
          advance: 1,
          price: 0,
          point_price: 0,
          book_pay_type: "1",
        },
      ],
    };
    const bool = await this.props.createFreeze(request_data);
    if (bool) {
      this.props.onClose();
    }
  };

  selectMultipleOption = (values) => {
    this.setState((state) => ({
      courts: [...values],
    }));
  };

  selectMultipleOptionLessonTypes = (values) => {
    this.setState((state) => ({
      lessonTypes: [...values],
    }));
  };

  setDate = (date, field) => {
    this.setState({
      [field]: date,
    });
  };
  handleOptionChange = (e) => {
    const { types } = this.state;
    const selected = types.filter((v) => v.id === e.target.value);

    this.setState({
      selectedType: selected.length > 0 ? selected["0"] : {},
    });
  };

  isSameDay(today, currentDay) {
    const endDateFormattedDateOnly = today.format("YYYY-MM-DD");

    const startsDateFormattedDateOnly = currentDay.format("YYYY-MM-DD");

    const isSameDay = moment(endDateFormattedDateOnly).isSame(
      moment(startsDateFormattedDateOnly)
    );

    return isSameDay;
  }

  componentWillReceiveProps(nextProps, nextContext) { }

  componentDidMount() {
    this.props.resetMicroSetting();
  }

  render() {
    const {
      types,
      note,
      currentDay,
      courts,
      sport_ids,
      lessonTypes,
      selectedType,
      startTime,
      endTime,
      cancel_on_freeze,
      trueText,
      disableSave,
      showNumbers,
      numbersValue,
      redBorder
    } = this.state;

    const { timeZone, user } = this.props;
    const userLevel = user.member_type.key;

    const start_min_time = startTime.clone().tz(timeZone).startOf("day");
    const end_min_time = endTime.clone().tz(timeZone).startOf("day");

    const timeStep = 30;

    const injectStartTimes = generateTimeListStartedFromByStep(
      start_min_time,
      timeStep
    );
    const injectEndTimes = generateTimeListStartedFromByStep(
      end_min_time,
      timeStep
    );

    const timeIntervals = this.props.timeStep;

    return (
      <div className="freeze-container">
        <form
          autoComplete="nope"
          className="dynamic-form"
          onSubmit={this.handelSave}
          data-op-form-id="0"
        >
          <div className="types-container">
            {types.map((v) => (
              <div key={v.id}>
                <label>
                  {" "}
                  <input
                    className="radio"
                    type="radio"
                    value={v.id}
                    checked={selectedType.id === v.id}
                    onChange={this.handleOptionChange}
                  />
                  {v.name}
                </label>
              </div>
            ))}

          </div>
          <React.Fragment>
            <div className="typeform-group">
              <span>
                <span className="midleSpan">Title </span>
              </span>
              <input
                required
                style={{ flex: "0 0 70%" }}
                type="text"
                className="form-control"
                value={note}
                onChange={({ target: { value } }) =>
                  this.setState((state) => ({ ...state, note: value }))
                }
              />
            </div>
          </React.Fragment>
          {selectedType.id === "lesson" && (
            <div className="  typeform-group">
              <span>
                <span className="midleSpan">Lesson Types </span>{" "}
              </span>
              <div className={"picky-container"} style={{ flex: "0 0 70%" }}>
                <Picky
                  includeFilter
                  options={[...this.props.lessonTypes]}
                  includeSelectAll={true}
                  value={lessonTypes}
                  valueKey="id"
                  labelKey="display_name"
                  multiple={true}
                  numberDisplayed={2}
                  onChange={this.selectMultipleOptionLessonTypes}
                  dropdownHeight={350}
                />
              </div>
            </div>
          )}

          <div className="typeform-group">
            <span>
              <span className="midleSpan">Courts </span>{" "}
            </span>
            <div className={"picky-container"} style={{ flex: "0 0 70%" }}>
              <Picky
                includeFilter
                options={[...this.props.courts]}
                includeSelectAll={true}
                value={courts}
                valueKey="id"
                labelKey="name"
                multiple={true}
                numberDisplayed={2}
                onChange={this.selectMultipleOption}
                dropdownHeight={350}
              />
            </div>
          </div>
          <div className="typeform-group">
            <span>
              <span className="midleSpan">Sport </span>
            </span>
            <div className={"picky-container"} style={{ flex: "0 0 70%" }}>
              <Picky
                includeFilter
                options={[...this.props.sport]}
                includeSelectAll={true}
                value={sport_ids}
                valueKey="id"
                labelKey="display_name"
                multiple={true}
                onChange={(opt) =>
                  this.setState((st) => ({ ...st, sport_ids: opt }))
                }
              />
            </div>
          </div>

          <div className="typeform-group from-to">
            <div className="lesson-start-time   ">
              <span className="midleSpan">From</span>
              <DatePicker
                selected={startTime}
                onChange={(date) => this.setDate(date, "startTime")}
                showTimeSelect
                minDate={moment(currentDay).tz(timeZone)}
                popperPlacement="bottom-end"
                dateFormat="MMM D, YYYY  h:mma"
                timeCaption="Time"
                injectTimes={injectStartTimes}
                timeIntervals={timeIntervals}
                className={`${this.state.error.datepicker ? "error" : ""}`}
              />
            </div>
            <div className="lesson-start-time   ">
              <span className="midleSpan">To</span>
              <DatePicker
                selected={endTime}
                onChange={(date) => this.setDate(date, "endTime")}
                showTimeSelect
                minDate={moment(startTime).tz(timeZone)}
                popperPlacement="bottom-end"
                injectTimes={injectEndTimes}
                timeIntervals={timeIntervals}
                dateFormat="MMM D, YYYY  h:mma"
                timeCaption="Time"
                className={`${this.state.error.datepicker ? "error" : ""}`}
              />
            </div>
          </div>
          {/* {userLevel == "owner" && (
            <label className="main_cancell_bookings_lable">
              <span className="cancell_bookings_lable">Don’t cancel booked events  {" "}</span>
              <GreenSwitcher
                value={cancel_on_freeze}
                checked={cancel_on_freeze}
                onChange={() =>
                  this.handleChange()({
                    target: {
                      name: "cancel_on_freeze",
                      value: !cancel_on_freeze,
                    },
                  })
                }
              />
              <span className="cancell_bookings_lable">Cancel all booked events</span>
            </label>
          )}
          <div className="checkIfCancelTrue" style={{ display: showNumbers ? 'block' : 'none' }}>
            <span>{trueText}</span>
            <input value={numbersValue} onPaste={(e) => {
              e.preventDefault()
              return false;
            }} style={{ borderColor: redBorder == 1 ? 'red' : (redBorder == 2 ? 'green' : '') }} onChange={this.handleChangeOfTrueText} type="text" />
          </div> */}
          <div className="d-flex justify-center">
            <button
              className="btn mr-2"
              type="button"
              onClick={this.props.onClose}
            >
              Cancel
            </button>

            <button
              type="submit"
              disabled={disableSave}
              className="btn btn-green"
              style={{ padding: "5px 20px" }}
            >
              Save
            </button>
          </div>
        </form>
      </div>
    );
  }
}

Freeze.defaultProps = {
  dateFormat: "hh:mm a",
  // timeStep: 15,
};

const mapStateToProps = (state) => {
  return {
    error: state.auth.signup,
    user: state.auth.user,
    courts: state.courts.data,
    sport: state.facility.sport.list.data,
    isConnected: state.stripe.isConnected,
    micro_settings: state.micro_settings,
    timeZone: state.auth.user.business.timezone,
    lessonTypes: state.lesson.lessonTypes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createFreeze: (data) => dispatch(createFreeze(data)),
    resetMicroSetting: () => dispatch(resetMicroSetting()),
    getMicroSettings: (id) => dispatch(getMicroSettings(id)),
    getWhoCanBook: (data) => dispatch(getWhoCanBook(data)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Freeze));
