import React, { Component } from "react";
import { connect } from "react-redux";
import Picky from "react-picky";
import Loading from "../UI/Loading";
import FormGroup from "../../components/FormGroup";
import { GetMember, getMemberBalance } from "../../actions/Members";
import AsyncSelect from "react-select/async";
import { searchUsers } from "../../actions/Members";
import "react-responsive-list/assets/index.css";
import { chargeCustomerWithCashInput } from "../../actions/Stripe";
import { getChargeHistory } from "../../actions/ChargeHistory";
import Switcher from "../UI/Switcher";
import Notification from "../Notification";
import Taxes from "../../containers/Taxes";
import "./cash-input.scss";

class ModalCashInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      price: props.bookings
        ? parseFloat(
          props.bookings.price +
          (props.bookings.facility_fee_price
            ? Number(props.bookings.facility_fee_price)
            : 0)
        ).toFixed(2)
        : props.default_price
          ? props.default_price
          : "",
      selected_taxes: [],
      goods: true,
      tax: "",
      name: "",
      amount: "",
      note: "",
      member: null,
      fullName: null,
      taxTotal: "",
      ...this.getInitialState(props),
    };

    this.hendlerOnChange = this.hendlerOnChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.hendlerChange = this.hendlerChange.bind(this);
  }

  hendlerOnChange(e) {
    const { name, type, value: valueTarget, checked } = e.target;
    const value = type === "checkbox" ? checked : valueTarget;
    this.setState({ [name]: value });
  }
  hendlerChange(e) {
    const { name, value } = e.target;
    let payman = false;
    switch (name) {
      case "name":
        payman = value.length > 2;
        break;
      case "price":
        payman = value > 0;
        break;
      default:
        break;
    }
    this.setState((prevState) => ({
      [name]: value,
      error: { ...prevState.error, [name]: !payman },
    }));
  }
  selectTax = (value) => {
    if (value.length < this.state.selected_taxes.length) {
      this.state.price = this.state.amount;
    }
    this.setState({ selected_taxes: value });
  };

  getInitialState = (props) => {
    return {
      amount: props.default_price ? props.default_price : null,
      member: null,
      type: { id: "cash", name: "Cash" },
      types: [
        { id: "cash", name: "Cash" },
        // { id: "card", name: "Card" },
        // { id: "terminal", name: "Terminal" },
        { id: "check-input", name: "Check input" },
      ],
      error: {},
    };
  };

  componentDidMount() { }

  checkValidation(state) {
    const { amount, type } = state;
    const error = { success: true };
    if (!amount || !parseFloat(amount)) {
      error.success = false;
      error.amount = true;
    } else {
      error.amount = false;
    }

    return error;
  }

  handleChange = (e, field = null) => {
    if (e.target && e.target.value) {
      const { value, name } = e.target;

      this.setState({ [name]: value });
      if (name == "amount") {
        this.setState({ price: value });
      }
    }

    if (!field) {
      const { [e.target.name]: error } = this.checkValidation(
        Object.assign(this.state, { [e.target.name]: e.target.value })
      );

      this.setState({
        [e.target.name]: e.target.value,
        error: Object.assign(this.state.error, { [e.target.name]: error }),
      });
    } else {
      this.setState({
        [field]: e,
      });
    }
  };

  Save = async () => {

    const {
      closeModal,
      user,
      appointment_id,
      charge_for,
      from,
      cbCharge,
    } = this.props;
    const { member } = this.state;
    const error = this.checkValidation(this.state);
    if (error.success) {
      const { amount, type, price, selected_taxes } = this.state;
      let data = {};
      if (this.props.from) {
        data = {
          appointment_id: appointment_id,
          amount: amount < price && selected_taxes.length > 0 ? price : amount,
          type: type.id,
          description: this.state.note,
          selected_taxes: selected_taxes,
          balance: from && from.balance ? true : false,
          fullName:this.state.fullName,
          user_id: user
            ? user.id
            : member && member.member
              ? member.member.id
              : null,
          charge_for: charge_for,
        };
      } else {
        data = {
          appointment_id: appointment_id,
          amount: amount < price && selected_taxes.length > 0 ? price : amount,
          type: type.id,
          description: this.state.note,
          balance: from && from.balance ? true : false,
          fullName:this.state.fullName,
          user_id: user
            ? user.id
            : member && member.member
              ? member.member.id
              : null,
          charge_for: charge_for,
        };
      }

      if (cbCharge) {
        await cbCharge({
          ...data,
          method: "cash",
        });
      } else {
        await this.props.chargeCustomerWithCashInput(data);
      }

      if (from) {
        switch (from.type) {
          case "member_view":
            await this.props.GetMember(from.member_id);
            break;
          default:
            break;
        }
        if (from.balance) {
          await this.props.getMemberBalance(
            from.user_id,
            from.id,
            from.filters,
            from.is_staff
          );
        }

        return;
      } else {
        //If from Payments page
        if (!appointment_id) {
          this.props.getChargeHistory(0);
        }
      }

      this.setState({
        ...this.getInitialState(this.props),
      });
      closeModal();
    } else {
      this.setState({ error: Object.assign(this.state.error, error) });
    }
  };

  render() {
    if (this.state.amount > 0) {
      this.state.price = this.state.amount;
    }
    const {
      closeModal,
      loadingCharge,
      show_members,
      from,
      user,
      tax,
    } = this.props;
    const { amount, type, types, price, selected_taxes, note } = this.state;
    const { amount: error_amount } = this.state.error;

    const hide_subtotal = from && from.hide_subtotal ? true : false;
    const taxPrice = from && from.taxPrice ? from.taxPrice : 0;
    const subTotal = from && from.subTotal ? from.subTotal : 0;
    const totalPrice = from && from.totalPrice ? from.totalPrice : 0;

    let tax_total = 0;
    if (price && selected_taxes.length > 0) {
      tax_total = selected_taxes
        .map((v) => {
          return (
            Math.round(
              (parseFloat(price) / 100) * parseFloat(v.percentage) * 100
            ) / 100
          );
        })
        .reduce((sum, sub_sum) => sum + sub_sum);
      this.state.price = parseFloat(parseFloat(price) + tax_total).toFixed(2);
    }
    
    return (
      <div className={`modal modal-cach-input active ${this.props.className}`}>
        <div className="modal-content">
          <div className="close-btn">
            <button onClick={closeModal}>
              <img src="/assets/img/cancel.png" alt="cancel" />
            </button>
          </div>
          {loadingCharge && <Loading />}
          <div className="cach-input-contaier">
            {show_members && (
              <div className="flex-col-100 member-drop">
                <label>Select Customer</label>
                {!hide_subtotal && (
                  <div className="taxParth">
                    <span>Total</span>
                    <Switcher
                      name="goods"
                      onChange={this.hendlerOnChange}
                      value={this.state.goods}
                      checked={this.state.goods}
                    />
                    <div className="notification-group _notification-for-subtotal">
                      Subtotal
                      <Notification className="whiteNotification">
                        In the United States the software will add the sales tax
                        to subtotal amount.
                      </Notification>
                    </div>
                    <Taxes
                      goods={this.state.goods}
                      selectTax={this.selectTax}
                    />
                  </div>
                )}
                <AsyncSelect
                  className={"input-with input-with-extra"}
                  cacheOptions
                  isClearable
                  placeholder={"Type..."}
                  value={this.state.member}
                  loadOptions={searchUsers}
                  onChange={(property, value) => {
                    this.setState({ member: property });
                    this.setState({ fullName: property.label });
                  }}
                />
              </div>
            )}
            <div className="flex-col-100 w-100-between">
              <div className="each-div">
                <label>Amount</label>
                <FormGroup
                  className={`${error_amount ? "error" : ""}`}
                  type="number"
                  name="amount"
                  onChange={this.handleChange}
                  onWheel={(e) => e.target.blur()}
                  value={amount || ""}
                />
              </div>
              <div className="each-div">
                <label> Select type </label>
                <Picky
                  options={types}
                  value={type}
                  valueKey="id"
                  labelKey="name"
                  multiple={false}
                  numberDisplayed={1}
                  includeFilter={true}
                  onChange={(e) => this.handleChange(e, "type")}
                  dropdownHeight={350}
                  placeholder="Select type"
                />
              </div>

              <br />
            </div>
            <div className="cachInpotNote">
              <input
                type="text"
                name="note"
                id="chargeNote"
                value={note}
                onChange={this.hendlerChange}
                placeholder="note optional"
              />
            </div>
            {!this.state.goods && tax !== 0 ? (
              <div className="taxtTotal">
                <ul>
                  <li>Tax total: {parseFloat(tax_total).toFixed(2)} </li>
                  <li>
                    Total:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {price === ""
                      ? 0
                      : parseFloat(parseFloat(price) + tax_total).toFixed(2)}
                  </li>
                </ul>
              </div>
            ) : null}
            {hide_subtotal ? (
              <div className="taxtTotal">
                <ul>
                  {subTotal > 0 && (
                    <li> Sub total: {parseFloat(subTotal).toFixed(2)} </li>
                  )}
                  {taxPrice > 0 && (
                    <li> Tax total: {parseFloat(taxPrice).toFixed(2)} </li>
                  )}
                  {totalPrice > 0 && (
                    <li>
                      Total:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {parseFloat(totalPrice).toFixed(2)}
                    </li>
                  )}
                </ul>
              </div>
            ) : null}
          </div>
          <div className="cach-input-contaier centered m-top-15">
            <div className="save-btn">
              <button
                disabled={loadingCharge}
                className="green-button-simple"
                onClick={this.Save}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loadingCharge: state.stripe.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    chargeCustomerWithCashInput: (data) =>
      dispatch(chargeCustomerWithCashInput(data)),
    GetMember: (id, params = null) => dispatch(GetMember(id, params)),
    getMemberBalance: (user_id, member_id, filters = {}, is_staff = false) =>
      dispatch(getMemberBalance(user_id, member_id, filters, is_staff)),

    getChargeHistory: (last_id, cancel_token = null, params = null) =>
      dispatch(getChargeHistory(last_id, cancel_token, params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalCashInput);
