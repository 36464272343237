import React, { Component } from "react";
import TaxInput from "../UI/TaxInput";
import Notification from "../Notification";
import { connect } from "react-redux";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";

const taxTypes = [
  { key: "Sales Tax", displayName: "Sales Tax" },
  /*{ key: 'VAT', displayName: 'VAT' },
    { key: 'GST', displayName: 'GST' },*/
  { key: "custom", displayName: "Custom" },
];
const rateTypes = [
  { key: "exclusive", displayName: "Exclusive" },
  //  { key: 'inclusive', displayName: 'Inclusive' }
];

class Tax extends Component {
  constructor(props) {
    super(props);
    const { record } = props;
    this.state = {
      tax: record ? record.percentage : "",
      type: record && record.type ? record.type : taxTypes["0"]["key"],
      region: record ? record.jurisdiction : "",
      description: record ? record.description : "",
      tax_id: record ? record.id : -1,
      rateType: rateTypes["0"]["key"],
      typeName:
        record && record.display_name && record.type === "custom"
          ? record.display_name
          : "",
      taxLodaing: props.taxLodaing,
      error: {},
    };
    this.hendlerOnChange = this.hendlerOnChange.bind(this);
    this.handleChangeAddress = this.handleChangeAddress.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.setTax = this.setTax.bind(this);
    this.onError = this.onError.bind(this);
  }

  async setTax(e) {
    e.preventDefault();
    const { error, region } = this.state;
    const { record } = this.props;
    // error.region = region ? false : true;
    if (!error.error) {
      const id = record ? record.id : null;
      // && !error.region
      await this.props.setTax(id, this.state);
    } else {
      this.state.error["region"] = true;
      this.state.error["error"] = true;
      this.setState({
        error: this.state.error,
      });
    }
  }

  hendlerOnChange(e, childState) {
    const { name, type, value: valueTarget, checked } = e.target;
    const value = type === "checkbox" ? checked : valueTarget;
    this.setState({ [name]: value });
    if (childState) {
      this.setState({
        error: { ...this.state.error, ...childState },
      });
    }
  }

  handleChangeAddress(region) {
    this.setState({ region });
  }

  handleSelect(address) {
    geocodeByAddress(address)
      .then(async (results) => {
        return [
          getLatLng(results[0]),
          results[0].formatted_address,
          results[0].place_id,
        ];
      })
      .then(async ([geo, formattedAddress, place_id]) => {
        geo.then((geo) => {
          this.state.error["region"] = false;
          this.state.error["error"] = false;
          this.setState({
            geo: { lat: geo.lat, long: geo.lng },
            //timeZone,
            region: formattedAddress,
            placeId: place_id,
            error: this.state.error,
          });
        });
      })
      .catch((error) => {
        window.modal.alert("Error", error.message);
      });
  }

  onError(status, clearSuggestions) {
    this.state.error["region"] = true;
    this.state.error["error"] = true;
    this.setState({
      error: this.state.error,
    });
    clearSuggestions();
  }

  render() {
    const {
      tax,
      region,
      taxLodaing,
      type,
      description,
      typeName,
      rateType,
      error,
    } = this.state;
    // const { region: Rregion } = this.state.error;
    const { record } = this.props;
    return (
      <div className="tax-container">
        <form autoComplete="nope" onSubmit={this.setTax}>
          <div className="class-title">Input your jurisdiction tax rate</div>
          <div className="form-group flex-group">
            <label htmlFor="typeID">Type</label>
            <div className="field-flex-wrapper">
              <select
                className="form-custom-control"
                name="type"
                id="typeID"
                value={type}
                onChange={this.hendlerOnChange}
              >
                {taxTypes.map((item) => {
                  return (
                    <option key={item.key} value={item.key}>
                      {item.displayName}
                    </option>
                  );
                })}
              </select>
            </div>
            {type === "custom" && (
              <div className="field-flex-wrapper m-left4">
                <input
                  type="text"
                  className="form-custom-control m-bottom-no"
                  name="typeName"
                  value={typeName}
                  onChange={this.hendlerOnChange}
                />
              </div>
            )}
          </div>
          {/* <PlacesAutocomplete
                        value={region}
                        onChange={this.handleChangeAddress}
                        onSelect={this.handleSelect}
                        onError={this.onError}
                    >
                        {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                            <div className="form-group address-group notification-group">
                                <label htmlFor="region" className="label-text">
                                    <Notification>
                                        Region, or jurisdiction, is the area where a tax rate applies ( such as a city or country). It's useful for tax remittance
                                 </Notification>
                                    Region
                                </label>
                                <input
                                    id="region"
                                    {...getInputProps({
                                        name: 'region',
                                        placeholder: 'Search Region ...',
                                        className: `location-search-input form-custom-control ${Rregion ? "error" : ""}`,
                                    })}
                                />

                                <div className="autocomplete-dropdown-container">
                                    {suggestions.map(suggestion => {
                                        const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                                        // inline style for demonstration purpose
                                        const style = suggestion.active
                                            ? { backgroundColor: '#fafafa', cursor: 'pointer', padding: '8px' }
                                            : { backgroundColor: '#ffffff', cursor: 'pointer', padding: '8px' };
                                        return (
                                            <div key={suggestion.id} {...getSuggestionItemProps(suggestion, { className, style })}>
                                                <span>{suggestion.description}</span>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )}
                    </PlacesAutocomplete>*/}
          {!record && (
            <div className="form-group flex-group">
              <label htmlFor="typeID">Rate</label>
              <div className="field-flex-wrapper ">
                <TaxInput
                  ref={(tax) => {
                    this.taxObj = tax;
                  }}
                  required
                  placeholder="Tax"
                  name="tax"
                  value={tax}
                  onChange={this.hendlerOnChange}
                />
              </div>
              {/*<div className="field-flex-wrapper m-left4">
                            <select className='form-custom-control' name="rateType" id="rateTypeID" value={rateType} onChange={this.hendlerOnChange}>
                                {
                                    rateTypes.map((item) => {
                                        return (
                                            <option key={item.key} value={item.key}>{item.displayName}</option>
                                        )
                                    })
                                }
                            </select>
                            </div>*/}
            </div>
          )}
          <div className="form-group notification-group">
            <label htmlFor="description" className="label-text">
              <Notification>
                This description is for internal use only.
              </Notification>
              Description
            </label>
            <input
              type="text"
              className="form-custom-control"
              name="description"
              value={description}
              onChange={this.hendlerOnChange}
            />
          </div>
          <div className="sign-up-btn">
            <button disabled={error.error ? true : taxLodaing} type="submit">
              Set Tax
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    taxLodaing: state.auth.taxLodaing,
    address: state.auth.user.business.address,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Tax);
