import axios from "../config/axios";
import {
  ADD_POINTS_SUCCESS,
  POINTS_LOADING,
  GET_POINTS_SUCCESS,
  UPDATE_POINTS_SUCCESS,
} from "../constants/Points";

import {
  getMemberPointsPurchaseHistory,
  buyMemberPointsPaymentsSuccess,
} from "./Members";

function addPointsSuccess(payload) {
  return {
    type: ADD_POINTS_SUCCESS,
    payload: payload,
  };
}

function updatePointsSuccess(payload) {
  return {
    type: UPDATE_POINTS_SUCCESS,
    payload: payload,
  };
}

function pointsSuccess(payload) {
  return {
    type: GET_POINTS_SUCCESS,
    payload: payload,
  };
}

function roleLoading(bool) {
  return {
    type: POINTS_LOADING,
    payload: bool,
  };
}

export function savePoint(id, options) {
  const { ...post_data } = options;
  return async (dispatch, getState) => {
    dispatch(roleLoading(true));
    try {
      let _url = id ? `point/entity/${id}` : `point/entity`;
      let res = await (id ? axios.put : axios.post)(_url, post_data);
      if (!id) {
        dispatch(addPointsSuccess(res.data.payload));
      } else {
        dispatch(updatePointsSuccess(res.data.payload));
      }

      return true;
    } catch (error) {
      console.log(error);
      await window.modal.alert("Error", error.response.data.message);
      return false;
    } finally {
      dispatch(roleLoading(false));
    }
  };
}

export function removePoint(point) {
  return async (dispatch) => {
    dispatch(roleLoading(true));
    try {
      const {
        id,
        createdAt,
        updatedAt,
        business_id,
        expiration_date,
        facility_group_id,
        ...rest
      } = point;
      let res = await axios.put(`point/entity/${point.id}`, {
        ...rest,
        status: "1",
      });
      dispatch(updatePointsSuccess(res.data.payload));
    } catch (error) {
      console.log(error);
      // await window.modal.alert('Error', error.response.data.message);
    } finally {
      dispatch(roleLoading(false));
    }
  };
}

export function getAllPoint() {
  return async (dispatch) => {
    dispatch(roleLoading(true));
    try {
      let res = await axios.get(`point/entity`);

      dispatch(pointsSuccess(res.data.payload));
    } catch (error) {
      console.log(error);
      // await window.modal.alert('Error', error.response.data.message);
    } finally {
      dispatch(roleLoading(false));
    }
  };
}

export function buyPoint(data, member_id, user_id) {
  return async (dispatch) => {
    dispatch(roleLoading(true));
    try {
      let res = await axios.post(`point/buy`, {
        ...data,
      });
      if (data.gift) {
        dispatch(
          getMemberPointsPurchaseHistory(user_id, member_id, null, false, true)
        );
      } else {
        dispatch(getMemberPointsPurchaseHistory(user_id, member_id));
      }

      dispatch(
        buyMemberPointsPaymentsSuccess(res.data.payload.user_payment, member_id)
      );
      return true;
    } catch (error) {
      return false;
      console.log(error);
      // await window.modal.alert('Error', error.response.data.message);
    } finally {
      dispatch(roleLoading(false));
    }
  };
}

export function refundGift(id, options = {}) {
  return async (dispatch) => {
    dispatch(roleLoading(true));
    try {
      const amount = options.amount ? options.amount : 0;
      const business_id = options.business_id ? options.business_id : 0;
      const user_id = options.user_id ? options.user_id : 0;
      const member_id = options.member_id ? options.member_id : 0;
      const is_staff = options.is_staff ? options.is_staff : false;
      let res = await axios.post(`point/refund_gift`, {
        user_point_id: id,
        amount: amount,
        user_id: user_id,
        business_id: business_id,
      });

      dispatch(
        getMemberPointsPurchaseHistory(user_id, member_id, null, is_staff, true)
      );

      return true;
    } catch (error) {
      return false;
      // await window.modal.alert('Error', error.response.data.message);
    } finally {
      dispatch(roleLoading(false));
    }
  };
}
