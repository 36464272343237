import React, { Component } from "react";
import { connect } from "react-redux";
import Loading from "../UI/Loading";
import TaxRateModal from "../TaxRateModal";

import { updatetaxRate } from "../../actions/Auth";
import moment from "moment-timezone";

class TaxRateList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openedModal: null,
    };
  }

  closeModal = () => {
    this.setState({ openedModal: null });
  };

  openTaxRateModal = (tax_rate) => {
    this.setState({
      openedModal: (
        <TaxRateModal record={tax_rate} clouseModal={this.closeModal} />
      ),
    });
  };

  archiveTaxRate = async (tax_rate) => {
    if (
      await window.modal.cnfirm(
        "Confirm",
        `Are you sure to remove tax rate <b>${tax_rate.display_name}</b> ?`
      )
    ) {
      await this.props.SetTax(tax_rate.id, null, true);
    }
  };

  render() {
    const { clouseModal, taxLodaing, business, timezone } = this.props;
    const { openedModal } = this.state;
    const selected_taxes = business.business_tax_rate
      ? business.business_tax_rate
      : [];

    return (
      <div className="modal stripe-modal tax-list-modal active">
        <div className="modal-content">
          <div className="close-btn">
            <button
              ref={(buuttonRef) => {
                this.closeRef = buuttonRef;
              }}
              onClick={clouseModal}
            >
              <img src="/assets/img/cancel.png" alt="cancel" />
            </button>
          </div>
          {taxLodaing && <Loading />}
          <table className="table table-bordered table-striped">
            <thead>
              <tr>
                <th>Name</th>
                <th>Description</th>
                <th>Percentage %</th>
                <th>createdAt</th>
                <th>#</th>
              </tr>
            </thead>
            <tbody>
              {selected_taxes &&
                selected_taxes.map((v, i) => {
                  return (
                    <tr key={i}>
                      <td>{v.display_name}</td>
                      <td>{v.description}</td>
                      <td>{v.percentage}</td>
                      <td>
                        {" "}
                        {moment(v.createdAt)
                          .tz(timezone)
                          .format("ddd, MMM D, YYYY h:mma")}
                      </td>
                      <td>
                        <div className="lesson-item-more">
                          <button
                            className="btn btn-green rounded"
                            onClick={() => this.openTaxRateModal(v)}
                            type="button"
                          >
                            <img
                              src="/assets/img/edit.png"
                              width="15"
                              height="15"
                              alt="Edit"
                            />
                          </button>
                          <button
                            onClick={() => this.archiveTaxRate(v)}
                            className="btn btn-red rounded mr-2"
                            type="button"
                          >
                            <img
                              src="/assets/img/cancel-white.png"
                              width="15"
                              height="15"
                              alt="Archive"
                            />
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {openedModal}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    timezone: state.auth.user.business.timezone,
    business: state.auth.user.business,
    taxLodaing: state.auth.taxLodaing,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    SetTax: (id = null, taxRate = null, remove = false) =>
      dispatch(updatetaxRate(id, taxRate, remove)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TaxRateList);
