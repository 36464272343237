"use strict";

import React from "react";
import Picky from "react-picky";
import isEqual from "lodash/isEqual";

const types = [
  { id: "1", name: "Booking only by Currency" },
  { id: "2", name: "Booking only by Points Pass" },
  { id: "-1", name: "Booking by Choice of Customer: Currency or Points" },
];

class BookPayType extends React.Component {
  constructor(props) {
    super(props);
    const { color } = props;
    let book_pay_type = { id: "1", name: "Booking only by Money" };

    if (props.book_pay_type) {
      book_pay_type = types.filter((v) => v.id === props.book_pay_type);
      book_pay_type = book_pay_type.length > 0 ? book_pay_type["0"] : null;
    }
    this.state = {
      book_pay_type: book_pay_type,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { book_pay_type } = this.state;
    const { book_pay_type: book_pay_type_old } = prevState;
    if (!isEqual(book_pay_type, book_pay_type_old)) {
      this.props.onChangeBookPayType(book_pay_type.id);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.book_pay_type && nextProps.book_pay_type !== "1") {
      let book_pay_type = types.filter((v) => v.id === nextProps.book_pay_type);
      book_pay_type = book_pay_type.length > 0 ? book_pay_type["0"] : null;
      this.setState({
        book_pay_type: book_pay_type,
      });
    }
  }

  handleChange = (e) => {
    this.setState({
      book_pay_type: e,
    });
  };

  render() {
    const { book_pay_type } = this.state;
    return (
      <div className="lesson-form-group flex-col-100">
        <label> Select payment type </label>
        <Picky
          options={types}
          value={book_pay_type}
          valueKey="id"
          labelKey="name"
          multiple={true}
          numberDisplayed={1}
          includeFilter={true}
          onChange={(e) => this.handleChange(e)}
          dropdownHeight={350}
          placeholder="Select payment type"
        />
      </div>
    );
  }
}

export default BookPayType;
