import React, { Component } from 'react';
import Promo from './promo';
import { connect } from 'react-redux';




class PromoModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            promo: '',

        }
    }

    hendlerChangePromo = (promo) => {
        this.setState({ promo: promo });
    }

    selectPlanId = async () => {
        const { promo } = this.state;
        const { plan } = this.props;
        this.savebtn.setAttribute("disabled", "disabled");
        await this.props.selectPlanId(plan, promo);

    }


    render() {
        const { closeModal, plan, payLoading, current_plan } = this.props;


        return (
            <div className="modal stripe-modal partial-refund active">
                <div className="modal-content">
                    <div className="close-btn">
                        <button onClick={closeModal}>
                            <img src="/assets/img/cancel.png" alt="cancel" />
                        </button>
                    </div>
                    <h2>Confirm Upgrade ?</h2>
                    {(current_plan !== plan) && <Promo hendlerChangePromo={this.hendlerChangePromo} />}
                    <div className="modal-btns">
                        <button ref={btn => { this.savebtn = btn; }} className="green-button-simple"
                            onClick={this.selectPlanId}> Upgrade Now </button>
                        <button className="green-button-simple red-bg" onClick={closeModal}>Cancel </button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        payLoading: state.auth.payLoading,
        current_plan: state.auth.user.business.plan,
    };
};
const mapDispachToProps = dispach => {
    return {

    };
};

export default
    connect(
        mapStateToProps,
        mapDispachToProps
    )(PromoModal)



