import React, { Component } from 'react';
import { connect } from 'react-redux';
import RefundInput from '../UI/RefundInput';
import Notification from '../Notification';




class PartialRefund extends Component {
    constructor(props) {
        super(props);
        this.state = {
            amount: parseFloat(props.amount),
            isTax: props.isTax,
            tax_percent: props.tax_percent,
            error: {}
        };

        this.hendlerOnChange = this.hendlerOnChange.bind(this);
    }

    handleRefund(e) {
        e.preventDefaul();

    }

    refundCharge = async (e) => {
        e.preventDefault();
        const { amount } = this.state;

        const _amount = parseFloat(amount); //+ tax;

        if (await this.props.refundCharge(this.props.paymentId, _amount)) {
            this.props.updateRecentListDitels()
        }

    }

    hendlerOnChange(e, childState) {

        const { name, type, value: valueTarget, checked } = e.target;
        const value = type === 'checkbox' ? checked : valueTarget;
        this.setState(({ [name]: value }));
        if (childState) {
            this.setState({
                error: { ...this.state.error, ...childState }
            })
        }
    }

    render() {
        const { clouseModal, item,forPOS } = this.props;
        const { amount, error, isTax, tax_percent } = this.state;
       
        return (
            <div className="modal app-bootstrap-support stripe-modal partial-refund active">
                <div className="modal-content">
                    <div className="close-btn">
                        <button onClick={clouseModal}>
                            <img src="/assets/img/cancel.png" alt="cancel" />
                        </button>
                    </div>
                    <div className="tax-container">
                        <form autoComplete="nope" onSubmit={this.refundCharge}>
                            <div className="make-bolder">Refund</div>
                            {item && item.point === '1' && <div className="alert-box alert-warning">
                                <span>
                                    Only Unexpired Points Are Refundable
                                </span>
                            </div>}
                            <div className="form-group flex-group m-bottom-15">
                                <table className="table">
                                    <tr>
                                        <td>
                                            <label className='notification-group refound_title notification-for-subtotal font-size-11'
                                                htmlFor="typeID">{isTax ? 'Subtotal' : 'Amount'}
                                                {isTax && <Notification className="whiteNotification">
                                                    In the United States the software will add the sales tax to subtotal amount.
                                                </Notification>
                                                }
                                            </label>
                                        </td>
                                        <td>
                                            <div className="field-flex-wrapper">
                                                <RefundInput required placeholder='Refund' name='amount'
                                                    maxValue={amount} value={amount}
                                                    onChange={this.hendlerOnChange} />
                                            </div>
                                        </td>
                                        <td colSpan={2}>
                                            {tax_percent && <div className="field-flex-wrapper ">
                                                <p className="business_tax_rate">Tax rate {tax_percent} %</p>
                                            </div>}
                                        </td>
                                    </tr>
                                    {forPOS&&(
                                        <tr>
                                        <td>Total</td>
                                        <td>
                                                {amount>0?parseInt(amount)+(parseInt(amount)*tax_percent/100):0} $
                                        </td>
                                        <td>Tax</td>
                                        <td>
                                                {amount>0?(parseInt(amount)*tax_percent/100):0} $
                                        </td>
                                        
                                    </tr>
                                    )}
                                    
                                </table>
                                <div className="pretty-button sign-up-btn deleteButton refound_btn">
                                    <button className="red-button" disabled={error.error ? true : false} type='submit'>Refund</button>
                                </div>
                            </div>



                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

PartialRefund.propTypes = {};
PartialRefund.defaultProps = {
    stripeUserId: null,
    from: 'MEMBER',
    newMembersList: []
};
const mapStateToProps = (state) => {
    return {
        tax: state.auth.user.business.business_tax_rate ? state.auth.user.business.business_tax_rate.percentage : null
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(PartialRefund);
