import {
  CHANGE_PLAN_ID_SELECED,
  SET_DEMO_URL_CODE,
  CLEAR_ERRORS,
  RESET_PASS_ERROR,
  RESET_PASS_SUCCESS,
  SIGN_IN_ERROR,
  SIGN_IN_LOADING,
  SIGN_IN_SUCCESS,
  SIGN_OUT_SUCCESS,
  SIGN_UP_ERROR,
  SIGN_UP_LOADING,
  GET_MEMBERSHIPS_SUCCESS,
  SIGN_UP_SUCCESS,
  TAX_RATE_UPDATE_LOADING,
  TAX_RATE_UPDATE_SUCCESS,
  GET_SOURCIES_SUCCESS,
  ADD_SOURCIES_SUCCESS,
  CHANGE_SOURCIES_SUCCESS,
  REMOVE_CARD_SUCCESS,
  GET_NBU_FAMILIY_SUCCESS,
  SIGNED_SUCCESS
} from '../constants/Auth';
import {
  ASSIGN_PAY_LOADING,
  ASSIGN_PAY_SUCCESS,
  CHNAGE_PLAN_ID_SUCCESS,
  REACTIVATE_SUBSCRIPTION_SUCCESS
} from '../constants/Pay';

const initStore = {
  loading: false,
  isSignIn: false,
  payLoading: false,
  taxLodaing: false,
  selectedPlanId: '',
  demoUrlCode: '',
  user: {
    member_type: {},
    memberships: []
  },
  error: {},
  forgot: {},
  signup: {
    loading: false,
    message: '',
  },
};
const AuthReducer = (store = initStore, action) => {
  switch (action.type) {
    case CLEAR_ERRORS:
      return Object.assign({}, store, {
        error: {},
        signup: {
          message: '',
          loading: store.signup.loading,
        },
      });
    case SIGN_IN_LOADING:
      return Object.assign({}, store, {
        loading: action.payload,
      });
    case SIGN_UP_LOADING:
      return Object.assign({}, store, {
        signup: {
          loading: action.payload,
          message: store.signup.message,
        },
      });
    case TAX_RATE_UPDATE_LOADING:
      return Object.assign({}, store, {
        taxLodaing: action.payload,
      });
    case SIGN_IN_SUCCESS:
      return Object.assign({}, store, {
        isSignIn: true,
        user: action.payload,
        error: {},
      });
    case SIGN_OUT_SUCCESS:
      return Object.assign({}, store, {
        isSignIn: false,
        user: {},
        error: {},
      });
    case SIGN_IN_ERROR:
      return Object.assign({}, store, {
        isSignIn: false,
        error: action.payload,
      });
    case SIGN_UP_ERROR:
      return Object.assign({}, store, {
        isSignIn: false,
        signup: {
          message: action.payload.message,
          loading: store.signup.loading,
        },
      });
    case SIGN_UP_SUCCESS:
      return Object.assign({}, store, {
        isSignIn: true,
        user: action.payload,
        error: {},
      });
    case GET_MEMBERSHIPS_SUCCESS:
      return Object.assign({}, store, {
        user: {
          ...store.user,
          memberships: action.payload
        },
        error: {},
      });
    case RESET_PASS_SUCCESS:
      return Object.assign({}, store, {
        forgot: {
          code: store.forgot.code,
          message: action.payload.message,
        },
      });
    case RESET_PASS_ERROR:
      return Object.assign({}, store, {
        forgot: {
          code: action.payload.code,
          message: action.payload.message,
        },
        error: action.payload,
      });
    case ASSIGN_PAY_SUCCESS:
      return Object.assign({}, store, {
        user: {
          ...store.user,
          premium: true,
          subscribed: true,
          business: {
            ...store.user.business,
            planId: action.payload,
          },
        },
      });
    case SIGNED_SUCCESS:
      return Object.assign({}, store, {
        user: {
          ...store.user,
          business: {
            ...store.user.business,
            member: {
              ...store.user.business.member,
              signed: true
            },
          },
        },
      });
    case GET_NBU_FAMILIY_SUCCESS:
      return Object.assign({}, store, {
        user: {
          ...store.user,
          business: {
            ...store.user.business,
            family: action.payload,
          },
        },
      });
    case CHANGE_PLAN_ID_SELECED:
      return Object.assign({}, store, {
        selectedPlanId: action.payload,
      });
    case SET_DEMO_URL_CODE:
      return Object.assign({}, store, {
        demoUrlCode: action.payload,
      });
    case ASSIGN_PAY_LOADING:
      return Object.assign({}, store, {
        payLoading: action.payload,
      });
    case CHNAGE_PLAN_ID_SUCCESS:
      return Object.assign({}, store, {
        user: {
          ...store.user,
          business: {
            ...store.user.business,
            plan: action.payload,
          },
        },
      });
    case REACTIVATE_SUBSCRIPTION_SUCCESS:
      return Object.assign({}, store, {
        user: {
          ...store.user,
          subscription: {
            ...store.user.subscription,
            pause_collection: null,
          },
        },
      });
    case TAX_RATE_UPDATE_SUCCESS:
      return Object.assign({}, store, {
        user: {
          ...store.user,
          business: {
            ...store.user.business,
            stripeTaxRateId: action.payload.id,
            business_tax_rate: action.payload,
          },
        },
      });

    case GET_SOURCIES_SUCCESS:
      let sourcies = action.payload.sources.data;
      let default_source = action.payload.default_source;
      sourcies = sourcies.map((item) => {
        if (item.id === default_source) {
          return {
            ...item,
            default_source: true
          };
        }
        return {
          ...item,
          default_source: false
        }
      });
      return Object.assign({}, store, {
        user: {
          ...store.user,
          stripePaymentMethods: sourcies
        },
      });


    case ADD_SOURCIES_SUCCESS:

      return {
        ...store,
        user: {
          ...store.user,
          stripePaymentMethods: [
            ...store.user.stripePaymentMethods.map(item => ({
              ...item,
              default_source: false,
            })),
            {
              ...action.payload,
              default_source: true,
            },
          ],
        }
      };

    case CHANGE_SOURCIES_SUCCESS:

      return {
        ...store,
        user: {
          ...store.user,
          stripePaymentMethods: [
            ...store.user.stripePaymentMethods.map((item) => {
              if (action.payload.defaultPaymentMethod === item.id) {
                return {
                  ...item,
                  default_source: true,
                };
              }
              return {
                ...item,
                default_source: false,
              };

            }),

          ],
        }
      };

    case REMOVE_CARD_SUCCESS:
      const index = store.user.stripePaymentMethods.findIndex(item => item.id === action.payload.id);
      store.user.stripePaymentMethods.splice(index, 1);
      return {
        ...store,
        user: {
          ...store.user,
          stripePaymentMethods: [
            ...store.user.stripePaymentMethods
          ],
        }
      };

    default:
      return store;
  }
};
export default AuthReducer;
