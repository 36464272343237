import {
  GET_MICRO_SETTINGS_SUCCESS,
  GET_MICRO_SETTINGS_ERROR,
  MICRO_SETTINGS_LOADING,
  MICRO_SETTINGS_ERROR,
  CLEAR_ERRORS,
  GET_MICRO_SETTINGS_LIST_SUCCESS,
  GET_MICRO_SETTINGS_LIST_ERROR,
  WHO_CAN_BOOK_DATA,
  MICRO_SETTINGS_RESET,
  FREEZE_LOGIC_LOADING,
  FREEZE_LOGIC_SUCCESS,
  ADDON_LOGIC_SUCCESS,
  RESTRICTIONS_LOGIC_LOADING,
  RESTRICTIONS_LOGIC_SUCCESS,
  RESTRICTIONS_LOGIC_SUCCESS_LIST,
  COACH_AVAILABILITY_LOADING,
  COACH_AVAILABILITY_LIST,
  COACH_AVAILABILITY_DETAILED_LIST,
} from "../constants/MicroSettings";

const initStore = {
  list: {
    total: 0,
    loading: false,
    error: null,
    data: [],
  },
  freeze: {
    loading: false,
    data: [],
  },
  restrictions: {
    loading: false,
    data: [],
    list: [],
  },
  current: {
    loading: false,
    error: null,
    whoCanBook: {},
    data: {},
  },
  coachAvailability: {
    loading: false,
    error: null,
    list: [],
    detailedList: [],
    current: null,
  },
};

const MicroSettingsReducer = (store = initStore, action) => {
  switch (action.type) {
    case CLEAR_ERRORS:
      return {
        ...store,
        list: {
          ...store.list,
          error: null,
        },
        freeze: {
          ...store.freeze,
        },
        restrictions: {
          ...store.restrictions,
        },
        current: {
          ...store.current,
          error: null,
        },
        coachAvailability: {
          ...store.coachAvailability,
          error: null,
        },
      };
    case MICRO_SETTINGS_RESET:
      return {
        ...store,
        current: {
          loading: false,
          error: null,
          whoCanBook: {},
          data: {},
        },
      };
    case MICRO_SETTINGS_LOADING:
      return {
        ...store,
        current: {
          ...store.current,
          loading: action.payload,
        },
      };

    case FREEZE_LOGIC_LOADING:
      return {
        ...store,
        freeze: {
          ...store.freeze,
          loading: action.payload,
        },
      };

    case FREEZE_LOGIC_SUCCESS:
      return {
        ...store,
        freeze: {
          ...store.freeze,
          data: action.payload,
          loading: false,
        },
      };
    case ADDON_LOGIC_SUCCESS:
      return {
        ...store,
        addon: {
          ...store.addon,
          data: action.payload,
          loading: false,
        },
      };

    case RESTRICTIONS_LOGIC_LOADING:
      return {
        ...store,
        restrictions: {
          ...store.restrictions,
          loading: action.payload,
        },
      };

    case RESTRICTIONS_LOGIC_SUCCESS:
      return {
        ...store,
        restrictions: {
          ...store.restrictions,
          data: action.payload,
          loading: false,
        },
      };
    case RESTRICTIONS_LOGIC_SUCCESS_LIST:
      return {
        ...store,
        restrictions: {
          ...store.restrictions,
          list: action.payload,
          loading: false,
        },
      };

    case GET_MICRO_SETTINGS_SUCCESS:
      return {
        ...store,
        current: {
          ...store.current,
          error: null,
          loading: false,
          data: action.payload,
        },
      };

    case GET_MICRO_SETTINGS_LIST_SUCCESS:
      return {
        ...store,
        list: {
          error: null,
          loading: false,
          data: action.payload.rows,
          total: action.payload.total,
        },
      };

    case GET_MICRO_SETTINGS_LIST_ERROR:
      return {
        ...store,
        list: {
          ...store.list,
          loading: false,
          error: action.payload,
        },
      };

    case GET_MICRO_SETTINGS_ERROR:
    case MICRO_SETTINGS_ERROR:
      return {
        ...store,
        current: {
          ...store.current,
          loading: false,
          error: action.payload,
        },
      };

    case WHO_CAN_BOOK_DATA: {
      return {
        ...store,
        current: {
          ...store.current,
          whoCanBook: action.payload,
        },
      };
    }

    /* COACH AVAILABILITY */

    case COACH_AVAILABILITY_LOADING: {
      return {
        ...store,
        coachAvailability: {
          ...store.coachAvailability,
          loading: action.payload,
        },
      };
    }
    case COACH_AVAILABILITY_LIST: {
      return {
        ...store,
        coachAvailability: {
          ...store.coachAvailability,
          list: action.payload,
        },
      };
    }
    case COACH_AVAILABILITY_DETAILED_LIST: {
      return {
        ...store,
        coachAvailability: {
          ...store.coachAvailability,
          detailedList: action.payload,
        },
      };
    }
    default:
      return store;
  }
};

export default MicroSettingsReducer;
