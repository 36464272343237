import React, { Component } from "react";
import Picky from "react-picky";
import PropTypes from "prop-types";
import { CardElement, injectStripe } from "react-stripe-elements";
import {
  chargecustomer,
  chargeCustomerForCourt,
  chargeCustomerForLesson,
  setStateAfterCustomerBookAndCharge,
  setStateAfterCustomerBookAndChargeForCourt,
} from "../../actions/Stripe";
import { searchUsers } from "../../actions/Members";
import AsyncSelect from "react-select/async";
import { AccountInputNumber, RomingInputNumber } from "../InjectedAddPaymant";
import Switcher from "../UI/Switcher";
import Taxes from "../../containers/Taxes";
import { connect } from "react-redux";

import { GetMember, getMemberBalance } from "../../actions/Members";
import { getChargeHistory } from "../../actions/ChargeHistory";
import Notification from "../Notification";
import "./checkout-form.scss";
import Loading from '../../components/UI/Loading';
import moment from "moment";

class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      note: "",
      name: "",
      price: props.booking
        ? parseFloat(
          props.booking.price +
          (props.booking.facility_fee_price
            ? Number(props.booking.facility_fee_price)
            : 0)
        ).toFixed(2)
        : props.default_price
          ? props.default_price
          : "",
      isBank: true,
      goods: true,
      tax: "",
      member: null,
      selected_taxes: [],
      openedModal: null,
      routing_number: "",
      account_number: "",
      account_holder_name: "",
      account_holder_type: "individual",
      loading: false,
      save_card:false,
      member_id: (props.booking && props.booking.user.id ? props.booking.user.id : null)
    };
    this.submit = this.submit.bind(this);
    this.submitForLesson = this.submitForLesson.bind(this);
    this.submitForCourt = this.submitForCourt.bind(this);
    this.submitForOuter = this.submitForOuter.bind(this);
    this.hendlerChange = this.hendlerChange.bind(this);
    this.hendlerOnChange = this.hendlerOnChange.bind(this);
    this.hendlerOnChangeInput = this.hendlerOnChangeInput.bind(this);
    this.HandleChangeCheckBoxDetails = this.HandleChangeCheckBoxDetails.bind(
      this
    );
    this.getOperationName = this.getOperationName.bind(this);

    this.closeModal = this.closeModal.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.tax.length === 1) {
      this.setState({
        selected_taxes: nextProps.tax,
      });
    }
  }

  hendlerOnChange(e) {
    const { name, type, value: valueTarget, checked } = e.target;
    const value = type === "checkbox" ? checked : valueTarget;
    this.setState({ [name]: value });
  }

  hendlerOnChangeInput(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  HandleChangeCheckBoxDetails() {
    this.setState((prevState) => ({ isBank: !prevState.isBank }));
    this.setState({ save_card: false });
  }

  closeModal() {
    this.setState({ openedModal: null });
  }

  async submit(invoice = false) {
    const {
      routing_number,
      account_number,
      account_holder_name,
      account_holder_type,
      isBank,
      member,
      save_card
    } = this.state;
    const { cbCharge, stripeUserId,business,dateTime } = this.props;

    const {cancel_allowed,cancel_hour}=business;
    const duration = moment.duration(moment(dateTime?.startDate)?.utc()?.diff(moment()?.utc()));
    const milliseconds = parseInt(duration.asHours())* 60 * 60 *1000;
    const cancel_hourAsMilliseconds = cancel_hour * 60 * 60 *1000;
  
   
   if(!save_card && cancel_allowed && stripeUserId==null){
      if(cancel_hourAsMilliseconds<milliseconds){
        window.modal.alert("Notice", "Must agree to save a payment method in order to process your payment at later date at the time of cancelation deadline")
        return
      }
   }
    if(this.props?.business?.add_to_invoice && !save_card && stripeUserId==null){
      window.modal.alert("Notice", "Your club operates on automated invoice basis. Thus, saving a payment method is required.")
      return
   }
    if (stripeUserId === null && isBank && this.state.name.length <= 2) {
      return this.setState((prevState) => ({
        error: { ...prevState.error, name: true },
      }));
    }
    if (this.state.price <= 0) {
      return this.setState((prevState) => ({
        error: { ...prevState.error, price: true },
      }));
    }

    const { from, uid } = this.props;
    var response;
    try {
      this.setState({ loading: true });
      let initial_req_data = {
        method: "charge",
      };
      if (stripeUserId === null) {
        if (!this.state.isBank) {
          const { token } = await this.props.stripe.createToken(
            "bank_account",
            {
              country: "US",
              currency: "usd",
              routing_number,
              account_number,
              account_holder_name,
              account_holder_type,
            }
          );
          if (token && token.id) {
            let req_data = {
              amount: this.state.price,
              token: token.id,
              description: this.state.note,
              currency: "usd",
              uid: uid,
              balance: from && from.balance ? true : false,
              child_id: from && from.child_id ? from.child_id : null,
              goods: this.state.goods,
              name: this.state.name,
              selected_taxes: this.state.selected_taxes,
              invoice: invoice,
              save_card,
              ...initial_req_data,
            };
            response = cbCharge
              ? await cbCharge({
                ...req_data,
              })
              : await chargecustomer({
                ...req_data,
              });
          }
        } else {
          const { token } = await this.props.stripe.createToken({
            name: this.state.name,
          });
          if (token && token.id) {
            let req_data = {
              amount: this.state.price,
              token: token.id,
              description: this.state.note,
              currency: business.currency_code,
              uid: uid,
              balance: from && from.balance ? true : false,
              child_id: from && from.child_id ? from.child_id : null,
              goods: this.state.goods,
              name: this.state.name,
              save_card,
              selected_taxes: this.state.selected_taxes,
              invoice: invoice,
              ...initial_req_data,
            };
             response = cbCharge
              ? await cbCharge({
                ...req_data,
              })
              : await chargecustomer({
                ...req_data,
              });
          }
        }
      } else {
        let req_data = {
          amount: this.state.price,
          invoice: invoice,
          token: null,
          description: this.state.note,
          balance: from && from.balance ? true : false,
          currency: business.currency_code,
          uid: uid ? uid : member ? member.id : "-1",
          member_data: uid ? uid : member ? member : "-1",
          child_id: from && from.child_id ? from.child_id : null,
          goods: this.state.goods,
          name: this.state.name,
          selected_taxes: this.state.selected_taxes,
          invoice: invoice,
          save_card,
          ...initial_req_data,
        };
        response = cbCharge
          ? await cbCharge({
            ...req_data,
          })
          : await chargecustomer({
            ...req_data,
          });
      }
      this.setState({ loading: false });
     
      if (response) {
        if(this.props.waitlist){
          this.props.clouseModalRoot()
        }else{
          this.props.clouseModal();
        }
        
       
       
       // await window.modal.alert("Success", "Success");
        //Call method depend on from
        if (from) {
          switch (from.type) {
            case "member_view":
              await this.props.GetMember(from.member_id);
              break;

            default:
              break;
          }
          if (from.balance) {
            await this.props.getMemberBalance(
              from.user_id,
              from.id,
              from.filters,
              from.is_staff
            );
          }

          return;
        } else {
          if (this.props.setDefaultFilters) {
            this.props.setDefaultFilters(true);
          }
          await this.props.getChargeHistory(0, null);
        }
      }else if(response!=undefined && !response){
        this.props.clouseModal();
        await window.modal.alert("Notice", "Something went wrong");
      }
    } catch (error) {
      this.setState({ loading: false });
      console.log(error);
      //await window.modal.alert('Error', error.response.data.message);
    }
  }

  async submitForOuter(invoice = false) {
    const { outerState } = this.props;
    const { llid, clid, courtid } = outerState;
    if (llid) {
      this.submitForLesson(invoice);
    }
    if (clid && courtid) {
      this.submitForCourt(invoice);
    }
  }

  async submitForLesson(invoice = false) {
    const { business, stripeUserId, uid, outerState, from } = this.props;
    const member_id = this.props.booking.user.id;

    const {
      routing_number,
      account_number,
      account_holder_name,
      account_holder_type,
      isBank,
      member,
    } = this.state;
    if (stripeUserId === null && isBank && this.state.name.length <= 2) {
      return this.setState((prevState) => ({
        error: { ...prevState.error, name: true },
      }));
    }
    if (this.state.price <= 0) {
      return this.setState((prevState) => ({
        error: { ...prevState.error, price: true },
      }));
    }

    try {
      this.setState({ loading: true });
      if (stripeUserId === null) {
        if (!this.state.isBank) {
          const { token } = await this.props.stripe.createToken(
            "bank_account",
            {
              country: "US",
              currency: "usd",
              routing_number,
              account_number,
              account_holder_name,
              account_holder_type,
            }
          );
          if (token && token.id) {
            response = await chargeCustomerForLesson(uid, outerState, {
              ...this.state,
              stoken: token.id,
              invoice: invoice,
              currency: business.currency_code,
              member_id: member_id
            }, member_id);
          }
        } else {
          const { token } = await this.props.stripe.createToken({
            name: this.state.name,
          });
          if (token && token.id) {
            var response = await chargeCustomerForLesson(uid, outerState, {
              ...this.state,
              stoken: token.id,
              invoice: invoice,
              currency: business.currency_code,
              member_id: member_id
            }, member_id);
          }
        }
      } else {
        response = await chargeCustomerForLesson(uid, outerState, {
          ...this.state,
          invoice: invoice,
          currency: business.currency_code,
          member_id: member_id
        }, member_id);
      }
      this.setState({ loading: false });
      if (response) {
        this.props.clouseModal();
        await window.modal.alert("Success", "Success");
        this.props.setStateAfterCustomerBookAndCharge(response);
      }
    } catch (error) {
      this.setState({ loading: false });
      await window.modal.alert("Error", error.response.data.message);
    }
  }

  async submitForCourt(invoice = false) {
    const { business, stripeUserId, uid, outerState } = this.props;
    const member_id = this.props.booking.user.id;
    const {
      routing_number,
      account_number,
      account_holder_name,
      account_holder_type,
      isBank,
    } = this.state;
    
    if (stripeUserId === null && isBank && this.state.name.length <= 2) {
      return this.setState((prevState) => ({
        error: { ...prevState.error, name: true },
      }));
    }
    if (this.state.price <= 0) {
      return this.setState((prevState) => ({
        error: { ...prevState.error, price: true },
      }));
    }
    try {
      this.setState({ loading: true });
      if (stripeUserId === null) {
        if (!this.state.isBank) {
          const { token } = await this.props.stripe.createToken(
            "bank_account",
            {
              country: "US",
              currency: "usd",
              routing_number,
              account_number,
              account_holder_name,
              account_holder_type,
            }
          );
          if (token && token.id) {
            response = await chargeCustomerForCourt(uid, outerState, {
              ...this.state,
              stoken: token.id,
              invoice: invoice,
              currency: business.currency_code,
              member_id: member_id
            }, member_id);
          }
        } else {
          const { token } = await this.props.stripe.createToken({
            name: this.state.name,
          });
          if (token && token.id) {
            var response = await chargeCustomerForCourt(uid, outerState, {
              ...this.state,
              stoken: token.id,
              invoice: invoice,
              currency: business.currency_code,
              member_id: member_id
            }, member_id);
          }
        }
      } else {
        response = await chargeCustomerForCourt(uid, outerState, {
          ...this.state,
          invoice: invoice,
          currency: business.currency_code,
          member_id: member_id
        }, member_id);
      }
      this.setState({ loading: false });
      if (response) {
        this.props.clouseModal();
        await window.modal.alert("Success", "Success");
        this.props.setStateAfterCustomerBookAndChargeForCourt(response);
      }
    } catch (error) {
      this.setState({ loading: false });
      await window.modal.alert("Error", error.response.data.message);
    }
  }

  hendlerChange(e) {
    const { name, value } = e.target;
    let payman = false;
    switch (name) {
      case "name":
        payman = value.length > 2;
        break;
      case "price":
        payman = value > 0;
        break;
      default:
        break;
    }
    if(name=="save_card"){
      this.setState((prevState) => ({
        [name]: (value=="false"?true:false),
        error: { ...prevState.error, [name]: !payman },
      }));
    }else{
      this.setState((prevState) => ({
        [name]: value,
        error: { ...prevState.error, [name]: !payman },
      }));
    }
    
  }

  getOperationName() {
    return "Pay";
  }
  selectTax = (value) => {
    this.setState({ selected_taxes: value });
  };

  render() {
    const isOuterState = this.props.outerState ? true : false;
    const {
      tax,
      member_type_id,
      stripeUserId,
      show_members,
      from,
      user,
    } = this.props;
    const is_nbu = user.user_type === "nbu";
    const hide_subtotal = from && from.hide_subtotal ? true : false;
    const taxPrice = from && from.taxPrice ? from.taxPrice : 0;
    const subTotal = from && from.subTotal ? from.subTotal : 0;
    const totalPrice = from && from.totalPrice ? from.totalPrice : 0;
    const coach_types = [3, 14];
    const _operation = this.getOperationName();
    const {
      price,
      note,
      name,
      save_card,
      loading,
      selected_taxes,
      error: { price: priceError, name: nameError } = {},
      isBank,
      openedModal,
    } = this.state;
   
    let tax_total = 0;
    if (price && selected_taxes.length > 0) {
      tax_total = selected_taxes
        .map((v) => {
          return (
            Math.round(
              (parseFloat(price) / 100) * parseFloat(v.percentage) * 100
            ) / 100
          );
        })
        .reduce((sum, sub_sum) => sum + sub_sum);
    }
   
    return (
      <div className={`checkout ${loading ? "loading" : ""}`}>
        <h1>{_operation}</h1>
        {this.props.waitlist &&(
        <soan className="waitlist_mes">Authorize payment. You won't be charged until you get in form the waitlist and until 24 hours before the event.</soan>)}
        {!hide_subtotal && (
          <div className="taxParth">
            <span>Total</span>
            <Switcher
              name="goods"
              onChange={this.hendlerOnChange}
              value={this.state.goods}
              checked={this.state.goods}
            />
            <div className="notification-group _notification-for-subtotal">
              Subtotal
              <Notification className="whiteNotification">
                In the United States the software will add the sales tax to
                subtotal amount.
              </Notification>
            </div>
            <Taxes goods={this.state.goods} selectTax={this.selectTax} />
          </div>
        )}
        {stripeUserId === null ? (
          <div className="credit-card-bank-account">
            <span>Credit card</span>
            <Switcher
              name="isBank"
              onChange={this.HandleChangeCheckBoxDetails}
              value={this.state.isBank}
              checked={this.state.isBank}
            />
            {/* <span>Bank account</span> */}
            <span>ACH</span>
          </div>
        ) : (
          ""
        )}
        <div className="priceNote">
          {stripeUserId !== null && show_members && (
            <div className="">
              <label>Select Customer</label>
              <AsyncSelect
                className={"input-with input-with-extra"}
                cacheOptions
                isClearable
                placeholder={"Type..."}
                value={this.state.member}
                loadOptions={searchUsers}
                onChange={(property, value) => {
                  this.setState({ member: property });
                }}
              />
            </div>
          )}
          <div className="d-flex-center">
            <input
              type="number"
              name="price"
              value={price}
              disabled={is_nbu}
              onChange={this.hendlerChange}
              placeholder="amount"
              onWheel={(e) => e.target.blur()}
              className={priceError ? "error" : ""}
            />
            <input
              type="text"
              name="note"
              id="chargeNote"
              value={note}
              onChange={this.hendlerChange}
              placeholder="note optional"
            />
          </div>
        </div>
        {!this.state.goods && tax !== 0 ? (
          <div className="taxtTotal">
            <ul>
              <li>Tax total: {parseFloat(tax_total).toFixed(2)} </li>
              <li>
                Total:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {price === ""
                  ? 0
                  : parseFloat(parseFloat(price) + tax_total).toFixed(2)}
              </li>
            </ul>
          </div>
        ) : null}
        {hide_subtotal ? (
          <div className="taxtTotal">
            <ul>
              {subTotal > 0 && (
                <li> Sub total: {parseFloat(subTotal).toFixed(2)} </li>
              )}
              {taxPrice > 0 && (
                <li> Tax total: {parseFloat(taxPrice).toFixed(2)} </li>
              )}
              {totalPrice > 0 && (
                <li>
                  Total:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {parseFloat(totalPrice).toFixed(2)}
                </li>
              )}
            </ul>
          </div>
        ) : null}

        {stripeUserId === null ? (
          !isBank ? (
            <div className="bankAccountForm">
              <h3>Bank Info</h3>
              <select name="country" id="">
                <option defaultChecked>United States</option>
              </select>
              <select name="currency" id="">
                <option defaultChecked>USD</option>
              </select>
              <select
                name="account_holder_type"
                value={this.state.account_holder_type}
                onChange={this.hendlerOnChangeInput}
              >
                <option value="individual">individual</option>
                <option value="company">company</option>
              </select>
              <input
                onChange={this.hendlerOnChangeInput}
                type="text"
                name="account_holder_name"
                value={this.state.account_holder_name}
                placeholder="Account holder name"
              />
              <RomingInputNumber
                onChange={this.hendlerOnChangeInput}
                type="text"
                name="routing_number"
                value={this.state.routing_number}
                placeholder="Routing number"
              />
              <AccountInputNumber
                onChange={this.hendlerOnChangeInput}
                type="text"
                name="account_number"
                value={this.state.account_number}
                placeholder="Account number"
              />
               <label  className="saveCard"> <input value={save_card} name="save_card" onChange={this.hendlerChange} type="checkbox"/>
             {" "} Save this payment method for future transactions
              </label>
            </div>
          ) : (
            <React.Fragment>
              <h3>Card Info</h3>
              <input
                type="name"
                className={nameError ? "error" : ""}
                placeholder="FirstName LastName"
                onChange={this.hendlerChange}
                name="name"
                value={name}
              />
              <CardElement style={{ base: { fontSize: "16px" } }} />
              <label  className="saveCard"> <input value={save_card} name="save_card" onChange={this.hendlerChange} type="checkbox"/>
             {" "} Save this payment method for future transactions
              </label>
             
            </React.Fragment>
          )
        ) : (
          ""
        )}
        <div className="buttons">
          <div className="each">
          <div className="pretty-button">
            <button
              type="button"
              className=" green-button-simple_new"
              onClick={
                isOuterState
                  ? () => this.submitForOuter(false)
                  : () => this.submit(false)
              }
            >
              {_operation}
            </button>
            </div>
          </div>
          {(stripeUserId !== null ||save_card) &&
            !coach_types.includes(member_type_id) &&
            user.user_type !== "nbu" && (
              <div className="each">
                <div className="pretty-button">
                <button
                  type="button"
                  onClick={
                    isOuterState
                      ? () => this.submitForOuter(true)
                      : () => this.submit(true)
                  }
                  className="yellow-button"
                >
                  Add to Monthly Invoice
                </button>
                </div>
                {/*<Notification className="whiteNotification">
                            Monthly Invoice charges customer’s all monthly transactions on the fist day of the following month
                        </Notification>*/}
              </div>
            )}
        </div>
        {this.state.loading?<Loading/>:null}
        

        {openedModal}
      </div>
    );
  }
}

CheckoutForm.propTypes = {
  stripe: PropTypes.objectOf(PropTypes.any),
  uid: PropTypes.string,
  stripeUserId: PropTypes.string,
  clouseModal: PropTypes.func.isRequired,
  taxLodaing: PropTypes.bool.isRequired,
};
CheckoutForm.defaultProps = {
  stripe: null,
  uid: null,
  stripeUserId: null,
};
const mapStateToProps = (state) => {
  return {
    tax: state.auth.user.business.business_tax_rate
      ? state.auth.user.business.business_tax_rate.map((v) => {
        return {
          id: v.id,
          tax_id: v.tax_id,
          display_name: v.display_name,
          percentage: v.percentage,
          description: v.description,
          type: v.type,
          createdAt: v.createdAt,
        };
      })
      : [],
    taxLodaing: state.auth.taxLodaing,
    business: state.auth.user.business,
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetMember: (id, params = null) => dispatch(GetMember(id, params)),
    getMemberBalance: (user_id, member_id, filters = {}, is_staff = false) =>
      dispatch(getMemberBalance(user_id, member_id, filters, is_staff)),

    getChargeHistory: (last_id, cancel_token) =>
      dispatch(getChargeHistory(last_id, cancel_token)),
    setStateAfterCustomerBookAndCharge: (payload) =>
      dispatch(setStateAfterCustomerBookAndCharge(payload)),
    setStateAfterCustomerBookAndChargeForCourt: (payload) =>
      dispatch(setStateAfterCustomerBookAndChargeForCourt(payload)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectStripe(CheckoutForm));
