import React, { Component } from 'react';

import 'react-responsive-list/assets/index.css';

import './invoice-modal.scss';

import PosDetails from './pos-details';
class PosDetailsModal extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { payment, order_details, user_id, member_id, params, closeModal, from } = this.props;

        return (
            <div className="modal stripe-modal invoice-modal active">
                <div className="modal-content">
                    <div className="close-btn">
                        <button onClick={closeModal}>
                            <img src="/assets/img/cancel.png" alt="cancel" />
                        </button>
                    </div>
                    <PosDetails
                        user_id={user_id}
                        member_id={member_id}
                        payment={payment}
                        params={params}
                        from={from}
                        fromPayment={true}
                        closeDetailsModal={closeModal}
                        order_details={order_details}
                    />
                </div>
            </div>
        );

    }

}




export default PosDetailsModal;
