export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const MICRO_SETTINGS_LOADING = "MICRO_SETTINGS_LOADING";
export const MICRO_SETTINGS_ERROR = "MICRO_SETTINGS_ERROR";
export const GET_MICRO_SETTINGS_SUCCESS = "GET_MICRO_SETTINGS_SUCCESS";
export const GET_MICRO_SETTINGS_ERROR = "GET_MICRO_SETTINGS_ERROR";

export const GET_MICRO_SETTINGS_LIST_ERROR = "GET_MICRO_SETTINGS_LIST_ERROR";

export const GET_MICRO_SETTINGS_LIST_SUCCESS =
  "GET_MICRO_SETTINGS_LIST_SUCCESS";

export const WHO_CAN_BOOK_DATA = "WHO_CAN_BOOK_DATA";

export const MICRO_SETTINGS_RESET = "MICRO_SETTINGS_RESET";

//For Freeze Logic
export const FREEZE_LOGIC_LOADING = "FREEZE_LOGIC_LOADING";
export const FREEZE_LOGIC_SUCCESS = "FREEZE_LOGIC_SUCCESS";
export const ADDON_LOGIC_SUCCESS = "ADDON_LOGIC_SUCCESS";

//For Restrictions Logic

export const RESTRICTIONS_LOGIC_LOADING = "RESTRICTIONS_LOGIC_LOADING";
export const RESTRICTIONS_LOGIC_SUCCESS = "RESTRICTIONS_LOGIC_SUCCESS";
export const RESTRICTIONS_LOGIC_SUCCESS_LIST =
  "RESTRICTIONS_LOGIC_SUCCESS_LIST";

/* COACH AVAILABILITY */

export const COACH_AVAILABILITY_LOADING = "COACH_AVAILABILITY_LOADING";
export const COACH_AVAILABILITY_LIST = "COACH_AVAILABILITY_LIST";
export const COACH_AVAILABILITY_DETAILED_LIST = "COACH_AVAILABILITY_DETAILED_LIST";
