import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormGroup from '../../components/FormGroup';
import { contactUs } from '../../actions/util';
import { connect } from "react-redux";

class ContactAsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      message: '',
      job_title: '',
      error: {},
    };
    this.HandleChange = this.HandleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.request = this.request.bind(this);
    this.hendlerCangePhone = this.hendlerCangePhone.bind(this);
    this.hendlerCangeText = this.hendlerCangeText.bind(this);
  }

  componentWillMount() { }

  componentDidMount() { }

  componentWillReceiveProps(nextProps) { }

  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  componentWillUpdate(nextProps, nextState) { }

  componentDidUpdate(prevProps, prevState) { }

  componentWillUnmount() { }

  hendlerCangeText(e) {
    const { name, value } = e.target;
    if (typeof value === 'string' && value.length <= 210) {
      this.setState({ [name]: value });
    }
  }

  HandleChange(e) {
    const { [e.target.name]: error } = this.checkValidation(
      Object.assign(this.state, { [e.target.name]: e.target.value })
    );
    this.setState({
      [e.target.name]: e.target.value,
      error: Object.assign(this.state.error, { [e.target.name]: error }),
    });
  }

  hendlerCangePhone(e) {
    if (RegExp(/^\+?[0-9]{0,17}$/gi).test(e.target.value)) {
      const { [e.target.name]: error } = this.checkValidation(
        Object.assign(this.state, { [e.target.name]: e.target.value })
      );
      this.setState({
        [e.target.name]: e.target.value,
        error: Object.assign(this.state.error, { [e.target.name]: error }),
      });
    }
  }

  closeModal(e) {
    if (e.target.id === 'contactUsModalBack') {
      this.props.close();
    }
  }

  async request() {
    const error = this.checkValidation(this.state);
    console.log("log",error)
    if (error.success) {
      this.props.close();
      await contactUs({ ...this.state });
      this.setState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        message: '',
        job_title: '',
        error: {},
      });
    } else {
      this.setState({ error: Object.assign(this.state.error, error) });
      await window.modal.alert('Error', 'Fill all mandatory fields.');
    }
  }

  checkValidation(state) {
    const { first_name, last_name, email, phone, message, job_title } = state;
    const error = { success: true };
    const onlyNumbers = /^\+?[0-9]{0,17}$/gi;
    const regexpEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!first_name || first_name.length < 2) {
      error.first_name = true;
      error.success = false;
    } else {
      error.first_name = false;
    }
    if (!last_name || last_name.length < 2) {
      error.last_name = true;
      error.success = false;
    } else {
      error.last_name = false;
    }
    if (!RegExp(regexpEmail).test(email.trim())) {
      error.email = true;
      error.success = false;
    } else {
      error.email = false;
    }
    // if (!phone) {
    //   error.phone = true;
    //   error.success = false;
    // } else {
    //   error.phone = false;
    // }
    if (!message || message.length < 2 || message.length > 210) {
      error.message = true;
      error.success = false;
    } else {
      error.message = false;
    }
    // if (!job_title) {
    //   error.job_title = true;
    //   error.success = false;
    // } else {
    //   error.job_title = false;
    // }
    return error;
  }

  render() {
    const { first_name, last_name, email, phone, message, job_title } = this.state;
    const { opened, close } = this.props;
    const {
      first_name: Rfirst_name,
      last_name: Rlast_name,
      email: Remail,
      phone: Rphone,
      message: Rmessage,
      job_title: Rjob_title,
    } = this.state.error;
    
    return (
      <div
        className={`modal auth-modal req-modal ${opened ? 'active' : ''}`}
        onClick={this.closeModal}
        id="contactUsModalBack">
        <div className="modal-content">
          <div className="auth-container">
            <div className="close-auth-modal">
              <button onClick={close}>
                <img src="/assets/img/cancel-white.png" alt="cancel" />
              </button>
            </div>
            <small style={{fontSize:"11px"}}>Ballbutton is a software platform. Contact your club directly if you have questions about the club’s services.</small>
            <div>
              <FormGroup
                className={`${Rfirst_name ? 'error' : ''}`}
                type="text"
                name="first_name"
                label="*First Name"
                onChange={this.HandleChange}
                value={first_name}
              />
              <FormGroup
                className={`${Rlast_name ? 'error' : ''}`}
                type="text"
                name="last_name"
                label="*Last Name"
                onChange={this.HandleChange}
                value={last_name}
              />
              <FormGroup
                className={`${Remail ? 'error' : ''}`}
                type="text"
                name="email"
                label="*Email"
                onChange={this.HandleChange}
                value={email}
              />
              {/* <FormGroup
                className={`${Rphone ? 'error' : ''}`}
                type="text"
                name="phone"
                label="*Mobile Phone "
                onChange={this.hendlerCangePhone}
                value={phone}
              />
              <FormGroup
                className={`${Rjob_title ? 'error' : ''}`}
                type="text"
                name="job_title"
                label="*Job Title"
                onChange={this.HandleChange}
                value={job_title}
              /> */}
              <div className="form-group">
                <label htmlFor="messageId">
                  *Message

                </label>
                <textarea
                  className={`${Rmessage ? 'error' : ''}`}
                  id="messageId"
                  name="message"
                  onChange={this.hendlerCangeText}
                  value={message}
                  style={{ width: '100%' }}
                  rows="5"
                />
                <span className="rightLength">
                  {message ? message.length : 0} / {message ? 210 - message.length : 210}
                </span>
              </div>
              <div className="signin">
                <button onClick={this.request}>Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ContactAsModal.propTypes = {
  close: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
};


export default ContactAsModal;

