import axios from "../config/axios";
import moment from "moment-timezone";
import {
  ADD_LESSON_LOADING,
  ADD_LESSON_SUCCESS,
  DELATE_LESSON_SUCCESS,
  GET_COURT_LISTING_AVAILABILITY_SUCCESS,
  GET_LESSON_SUCCESS,
  GET_SINGLE_LESSON_SUCCESS,
  MARK_AS_BOOK_LOADING,
  MARK_AS_BOOK_SUCCESS,
  RESET_SINGLE_LESSON,
  UPDATE_LESSON_SUCCESS,
  GET_LESSON_TYPES_SUCCESS,
  GET_HIDDEN_LESSON_TYPES_SUCCESS,
  GET_LESSON_TYPE_SUCCESS,
  ADD_LESSON_TYPES_SUCCESS,
  EDIT_LESSON_TYPES_SUCCESS,
  GET_LESSON_TYPE_LOADING,
} from "../constants/Lesson";
import {
  getCortListingSuccess
} from "../actions/Courts";

import {
  attachBusinessForNbu
} from "../utils/helpers";

function addLessonSuccess(payload) {
  return {
    type: ADD_LESSON_SUCCESS,
    payload: payload,
  };
}

function updateLessonSuccess(payload) {
  return {
    type: UPDATE_LESSON_SUCCESS,
    payload: payload,
  };
}

function delLessonSuccess(payload) {
  return {
    type: DELATE_LESSON_SUCCESS,
    payload: payload,
  };
}

function getLessonsSuccess(payload) {
  return {
    type: GET_LESSON_SUCCESS,
    payload: payload,
  };
}

function getLessonTypesSuccess(payload) {
  return {
    type: GET_LESSON_TYPES_SUCCESS,
    payload: payload,
  };
}
function getHiddenLessonTypesSuccess(payload) {
  return {
    type: GET_HIDDEN_LESSON_TYPES_SUCCESS,
    payload: payload,
  };
}

function getLessonTypeSuccess(payload) {
  return {
    type: GET_LESSON_TYPE_SUCCESS,
    payload: payload,
  };
}

function addLessonTypesSuccess(payload) {
  return {
    type: ADD_LESSON_TYPES_SUCCESS,
    payload: payload,
  };
}

function editLessonTypesSuccess(payload) {
  return {
    type: EDIT_LESSON_TYPES_SUCCESS,
    payload: payload,
  };
}

function getSingleLessonsSuccess(payload) {
  return {
    type: GET_SINGLE_LESSON_SUCCESS,
    payload: payload,
  };
}

function resetSingleLesson(payload) {
  return {
    type: RESET_SINGLE_LESSON,
    payload: payload,
  };
}

function addLessonLoading(bool) {
  return {
    type: ADD_LESSON_LOADING,
    payload: bool,
  };
}

function getLessonTypeLoading(bool) {
  return {
    type: GET_LESSON_TYPE_LOADING,
    payload: bool,
  };
}

function markAsBookLessonSuccess(payload) {
  return {
    type: MARK_AS_BOOK_SUCCESS,
    payload,
  };
}

function markAsBookLessonLoading(bool) {
  return {
    type: MARK_AS_BOOK_LOADING,
    bool,
  };
}

function getCourtListingavAilabilityLoading(bool) {
  return {
    type: ADD_LESSON_LOADING,
    payload: bool,
  };
}

function getCourtListingavAilabilitySuccess(payload) {
  return {
    type: GET_COURT_LISTING_AVAILABILITY_SUCCESS,
    payload: payload,
  };
}

const lessonsType = {
  privatelesson: "private",
  grouplesson: "group",
  liveball: "liveball",
  cardiotennis: "cardiotennis",
  camps: "camp",
  all: "all",
};

export function GetLessons(value = {}) {
  return async (dispatch, getState) => {
    dispatch(addLessonLoading(true));
    try {
      const {
        auth: {
          user: {
            business: {
              timezone
            } = {}
          } = {}
        } = {},
      } = getState();
      const currentDay = value.currentDay;
      const type = value.type;
      const coach = value.coach;
      const family = value.family ? value.family : undefined;
      const camps = !!value.camps;
      const recurrence = !!value.recurrence;
      const only_online = !!value.only_online;
      const starts = moment(currentDay).startOf("day").utc().format();
      const ends = moment(currentDay).add(1, "day").startOf("day").utc().format();

      const business_id = getState().auth.user.business.id;

      let res = await axios.post(
        `lesson/search/${business_id}`,
        coach ? {
          dates: [{
            startDate: starts,
            endDate: ends,
          }, ],
          filters: {
            typeId: type,
            coach: coach,
            recurrence: recurrence,
            online: only_online,
            camps: camps,
          },
          //TODO NEED TO ADD Coach
        } : {
          dates: [{
            startDate: starts,
            endDate: ends,
          }, ],
          filters: {
            typeId: type,
            recurrence: recurrence,
            online: only_online,
            camps: camps,
            family: family,
            front: true,
          },
        }
      );

      dispatch(getLessonsSuccess(res.data.payload));
    } catch (error) {
      console.log(error);
      //await window.modal.alert('Error', error.response.data.message);
    } finally {
      dispatch(addLessonLoading(false));
    }
  };
}

export function GetLesson(llid) {
  return async (dispatch, getState) => {
    dispatch(addLessonLoading(true));
    try {
      let res = await axios.get(`lesson/appointment/${llid}`);

      dispatch(getSingleLessonsSuccess(res.data.payload));
    } catch (error) {
      /*dispatch(getSingleLessonsSuccess({
        statusCode: 500
      }));*/
      await window.modal.alert("Error", error.response.data.message);
    } finally {
      dispatch(addLessonLoading(false));
    }
  };
}
export function GetLessonTypes() {
  return async (dispatch, getState) => {
    dispatch(addLessonLoading(true));
    try {
      const token = window.localStorage.getItem("token");
      if (!token) {
        dispatch(addLessonLoading(false));
        return;
      }
      let business_id = attachBusinessForNbu(getState());

      let url = business_id ?
        `lesson/getalltypes/${business_id}` :
        "lesson/getalltypes";
      let res = await axios.get(url);

      dispatch(getLessonTypesSuccess(res.data.payload));
    } catch ({
      response = {}
    }) {
      
      if(response.data.err.name=="BlockError"){
      return false;
      }
      console.log(response.data ? response.data.message : "Network error.");
    } finally {
      dispatch(addLessonLoading(false));
    }
  };
}
export function GetHiddenLessonTypes() {
  return async (dispatch, getState) => {
    dispatch(addLessonLoading(true));
    try {
      const token = window.localStorage.getItem("token");
      if (!token) {
        dispatch(addLessonLoading(false));
        return;
      }
     
      let url = "lesson/getalltypes/?hidden=true";
      let res = await axios.get(url);

      dispatch(getHiddenLessonTypesSuccess(res.data.payload));
    } catch ({
      response = {}
    }) {
      console.log(response.data ? response.data.message : "Network error.");
    } finally {
      dispatch(addLessonLoading(false));
    }
  };
}
export function GetLessonType(type) {
  return async (dispatch, getState) => {
    dispatch(getLessonTypeLoading(true));
    try {
      const token = window.localStorage.getItem("token");
      if (!token) {
        dispatch(getLessonTypeLoading(false));
        return;
      }
      let res = await axios.get(`lesson/type/${type}`);

      dispatch(getLessonTypeSuccess(res.data.payload));
    } catch ({
      response = {}
    }) {
      console.log(response.data ? response.data.message : "Network error.");
    } finally {
      dispatch(getLessonTypeLoading(false));
    }
  };
}
export function addLessonType(data) {
  return async (dispatch, getState) => {
    dispatch(addLessonLoading(true));
    try {
      let res = await axios.post("lesson/type/add", {
        display_name: data.display_name,
        color: data.color,
      });

      dispatch(addLessonTypesSuccess(res.data.payload));
    } catch (error) {
      await window.modal.alert("Error", error.response.data.message);
    } finally {
      dispatch(addLessonLoading(false));
    }
  };
}
export function editLessonType(id, data) {
  return async (dispatch, getState) => {
    dispatch(addLessonLoading(true));
    try {
      let res = await axios.put(`lesson/type/edit/${id}`, {
        display_name: data.display_name,
        color: data.color,
        is_min_max_enabled: data.list_duration,
        min_list_duration: data.min_list_duration,
        max_list_duration: data.max_list_duration,
      });

      dispatch(editLessonTypesSuccess(res.data.payload));
      dispatch(GetLessonType(id));
    } catch (error) {
      await window.modal.alert("Error", error.response.data.message);
    } finally {
      dispatch(addLessonLoading(false));
    }
  };
}

export function cancelBooking(
  refund,
  uid,
  apt_id,
  cancel_for = "lesson",
  history,
  _apt_id = null,
  recurrence_id,
  nbu = false,
  is_creator,
  lesson_id
) {
  return async (dispatch) => {
    dispatch(addLessonLoading(true));
    try {
      let _url = _apt_id ?
        `appointment/cancel_for/${apt_id}?cancel_from=${_apt_id}&recurrence_id=${recurrence_id}&automatically_refund_payment=${refund}` :
        `appointment/cancel_for/${apt_id}?automatically_refund_payment=${refund}`;
      let res;
      if (nbu) {
        let _url;
        if (cancel_for === "lesson") {
          _url = is_creator ?
            `lesson/cancel/${lesson_id}` :
            `lesson/cancel/${lesson_id}/${uid}`;
        } else {
          _url = is_creator ?
            `appointment/cancel/${apt_id}` :
            `appointment/cancel/${apt_id}/${uid}`;
        }

        res = await axios.post(_url, {
          cancel_for: cancel_for,
          apt_id: apt_id,
        });
      } else {
        res = await axios.put(_url, {
          user_id: uid,
          cancel_for: cancel_for,
        });
      }

      if (cancel_for === "lesson") {
        dispatch(getSingleLessonsSuccess(res.data.payload));
      } else {
        dispatch(getCortListingSuccess(res.data.payload));
      }
    } catch (error) {
      if (error.response && error.response.data.code === 404) {
        if (cancel_for === "lesson") {
          history.push("/private/lessons/-1");
        } else {
          history.push("/private/dashboard");
        }
      } else {
        await window.modal.alert("Error", error.response.data.message);
      }
    } finally {
      dispatch(addLessonLoading(false));
    }
  };
}

export function sendMessageToUser(uid, description) {
  return async (dispatch) => {
    dispatch(addLessonLoading(true));
    try {
      let res = await axios.post("members/sendemailtomember", {
        user_id: uid,
        message: description,
      });

      await window.modal.alert("Success", "Success");
      //dispatch(getSingleLessonsSuccess(res.data.payload['0']));
    } catch (error) {
      console.log(error);
      //await window.modal.alert('Error', error.response.data.message);
    } finally {
      dispatch(addLessonLoading(false));
    }
  };
}

export function refundCharge(
  llid,
  chargeId,
  paymentId,
  amount,
  refund_for = "lesson"
) {
  return async (dispatch) => {
    dispatch(addLessonLoading(true));
    try {
      let res = await axios.post("payment/charges/refundforappointment", {
        appointment_id: llid, // lesson listing id
        amount: parseFloat(amount),
        refund_for: refund_for,
        charge_id: chargeId, // charge paymentId
        payment_id: paymentId, // charge paymentId
      })

      if (refund_for === "lesson") {
        dispatch(getSingleLessonsSuccess(res.data.payload));
      } else if (refund_for === "court") {
        dispatch(getCortListingSuccess(res.data.payload));
      }
    } catch (error) {
      console.log(error);
      await window.modal.alert("Error Message", error.response.data.message);

    } finally {
      dispatch(addLessonLoading(false));
    }
  };
}

export function resetLesson() {
  return async (dispatch) => {
    try {
      dispatch(resetSingleLesson({}));
    } catch (error) {} finally {}
  };
}

export function delLesson(llid, automatically_refund_payment) {
  return async (dispatch) => {
    try {
      let res = await axios.delete(
        `appointment/delete/${llid}?automatically_refund_payment=${automatically_refund_payment}`
      );
      dispatch(delLessonSuccess(llid));
    } catch (error) {
      await window.modal.alert("Error", error.response.data.message);
    }
  };
}

export function delLessonBatch(data) {
  return async (dispatch) => {
    dispatch(addLessonLoading(true));
    const apt_id = data.apt_id ? data.apt_id : null;
    const lesson_id = data.apt_id ? data.lesson_id : null;
    const _apt_id = data._apt_id ? data._apt_id : apt_id;
    const keep_booked = data.keep_booked ? data.keep_booked : false;
    const automatically_refund_payment = data.automatically_refund_payment ?
      data.automatically_refund_payment :
      false;
    try {
      let url = `lesson/delete/${lesson_id}/${apt_id}?automatically_refund_payment=${automatically_refund_payment}`;

      if (keep_booked) {
        url = `lesson/delete/${lesson_id}/-1?keep_booked=1&automatically_refund_payment=${automatically_refund_payment}`;
      }
      let res = await axios.delete(url);
      dispatch(delLessonSuccess(_apt_id));
    } catch (error) {
      await window.modal.alert("Error", error.response.data.message);
    }finally {
      dispatch(addLessonLoading(false));
    }
  };
}

const repeatType = {
  DAYLY: "DAYLY",
  WEEKLY: "WEEKLY",
  BI_WEEKLY: "BI-WEEKLY",
  MONTHLY: "MONTHLY",
};

export function AddLesson(state, repeatState, lessonTypeToGetAfterAdd) {
  return async (dispatch, getStore) => {
    
    const {
      auth: {
        user: {
          business: {} = {}
        } = {}
      } = {}
    } = getStore();
   
    let sendDate = mapRequestData(state, repeatState, getStore);
    if (state.booked_by && state.booked_by.length > 0) {
      sendDate.booked_by = state.booked_by;
    }
    
    try {
     
      let res = await axios.post("lesson/add", sendDate);
      dispatch(addLessonLoading(true));
      dispatch(addLessonSuccess(res.data.payload));
      dispatch(
        GetLessons({
          currentDay: state.currentDay,
          type: lessonTypeToGetAfterAdd,
        })
      );
     // dispatch(addLessonLoading(false));
      return true;
    } catch (error) {
      if (error.response && error.response.data.code === 409) {
        const timezone = getStore().auth.user.business.timezone;

        const conflicts_text = error.response.data.err.data ?
          error.response.data.err.data.map((e) => {
            const start_time = moment(e.overlap.start_time)
              .tz(timezone)
              .format("MMM D, YYYY  h:mma");
            const end_time = moment(e.overlap.end_time)
              .tz(timezone)
              .format("MMM D, YYYY  h:mma");
            return `<p>${e.overlap.courts["0"]["name"]} &nbsp; ${start_time} - ${end_time} </p>`;
          }) :
          "";

        if (
          await window.modal.cnfirm("Conflict", conflicts_text, {
            style: {
              width: "400px",
            },
            buuton: {
              success: "Skip the conflict and create the rest",
              cancle: "Cancel",
            },
          })
        ) {
          dispatch(addLessonLoading(true));
          await axios.post(
            "lesson/add", {
              ...sendDate,
              force: true,
            }, {
              headers: {
                // eslint-disable-next-line no-undef
                "Content-Type": "application/json",
              },
            }
          );
          dispatch(
            GetLessons({
              currentDay: state.currentDay,
              type: lessonTypeToGetAfterAdd,
            })
          );
          return true;
        } else {
          return false;
        }
      } else {
        await window.modal.alert("Error", error.response.data.message);
      }

      console.log(error);
    } finally {
      dispatch(addLessonLoading(false));
    }
    return false;
  };
}

export function UpdateLesson(state, repeatState, updateModeNumber = 0) {
  let sendDate = {};

  return async (dispatch, getState) => {
    dispatch(addLessonLoading(true));
    let isreload = true;
    try {
      let updateMode = "";
      switch (updateModeNumber) {
        case 0:
          updateMode = "thisevent";
          break;
        case 1:
          updateMode = "thisandfollowingevents";
          break;
        case 2:
          updateMode = "allevents";
          break;
        default:
          updateMode = "thisevent";
          break;
      }

      sendDate = {
        appointment_id: state.llid,
        force: false,
        ...mapRequestData(state, repeatState, getState),
        allowBooking: state.allowBooking,
        mode: updateModeNumber || undefined, // to keep sending 0
      };
      let res = await axios.post(`lesson/update/${state.lesson_id}`, sendDate);
      
      dispatch(updateLessonSuccess(res.data.payload));

    } catch (error) {
      if (error.response && error.response.data.code === 409) {
        const timezone = getState().auth.user.business.timezone;

        const conflicts_text = error.response.data.err.data.map((e) => {
          const start_time = moment(e.overlap.start_time)
            .tz(timezone)
            .format("MMM D, YYYY  h:mma");
          const end_time = moment(e.overlap.end_time)
            .tz(timezone)
            .format("MMM D, YYYY  h:mma");
          const court_name = e.overlap.courts ?
            e.overlap.courts["0"]["name"] :
            "";
          return `<p>${court_name} &nbsp; ${start_time} - ${end_time} </p>`;
        });
        if (
          await window.modal.cnfirm("Conflict", conflicts_text, {
            style: {
              width: "400px",
            },
            buuton: {
              success: "Skip the conflict and create the rest",
              cancle: "Cancel",
            },
          })
        ) {
          const res = await axios.post(
            `lesson/update/${state.lesson_id}`, {
              ...sendDate,
              force: true,
            }, {
              headers: {
                // eslint-disable-next-line no-undef
                "Content-Type": "application/json",
              },
            }
          );
          dispatch(updateLessonSuccess(res.data.payload));
        }
      }

      isreload = false;
      await window.modal.alert('Error', error.response.data.message);
    } finally {
      dispatch(addLessonLoading(false));
      if (isreload) {
        window.location.reload()
      }
    }
  };
}

export function UpdateLessonExtended(
  state,
  repeatState,
  lessonTypeToGetAfterAdd
) {
  return async (dispatch, getState) => {
    let isreload = true;
    try {
      const sendDate = mapRequestDataExtended(state, getState);
      let res = await axios.put(`lesson/appointment/${state.llid}`, sendDate);
      dispatch(addLessonLoading(true));
      dispatch(updateLessonSuccess(res.data.payload));
    } catch (error) {
      if (error.response && error.response.data.code === 409) {
        const timezone = getState().auth.user.business.timezone;

        const conflicts_text = error.response.data.err.data.map((e) => {
          const start_time = moment(e.overlap.start_time)
            .tz(timezone)
            .format("MMM D, YYYY  h:mma");
          const end_time = moment(e.overlap.end_time)
            .tz(timezone)
            .format("MMM D, YYYY  h:mma");
          return `<p>${e.overlap.courts["0"]["name"]} &nbsp; ${start_time} - ${end_time} </p>`;
        });
        if (!conflicts_text) {
          await window.modal.alert("Info", error.response.data.message);
          return false;
        }
        await window.modal.cnfirm("Conflict", conflicts_text.join(" "), {
          style: {
            width: "400px",
          },
          buuton: {
            success: "Ok",
            cancle: "Cancel",
          },
        });
      } else {
        isreload = false;
        if (error.response.data.message == "specialMicroSettings") {
          await window.modal.alert("Notice", "Please Adjust The Special Price Field");
        } else {
          await window.modal.alert("Error", error.response.data.message);
        }

      }

    } finally {
      dispatch(addLessonLoading(false));
      if (isreload) {
        window.location.reload()
      }

    }
  };
}

export function block(id, options = {}) {
  return async (dispatch, getState) => {
    dispatch(addLessonLoading(true));
    try {
      let res = await axios.put(`appointment/block/${id}`, {
        ...options,
      });

      dispatch(updateLessonSuccess(res.data.payload));
    } catch (error) {
      await window.modal.alert("Error", error.response.data.message);
      console.log(error);
      // await window.modal.alert('Error', error.response.data.message);
    } finally {
      dispatch(addLessonLoading(false));
    }
  };
}

export function getCourtListingavAilability(state, repeatState) {
  return async (dispatch, getState) => {
    try {
      dispatch(getCourtListingavAilabilityLoading(true));
      const {
        auth: {
          user: {
            business: {} = {}
          } = {}
        } = {}
      } = getState();
      const {
        courts
      } = getState();
      let sendDate;
      if (!repeatState) {
        sendDate = {
          frequency: "daily",
          occurrences: 1,
          recurrence: false,
          every: 1,
          days: [],
          end_time: state.end_time
            .set({
              second: 0,
              millisecond: 0,
            })
            .format(),
          start_date: state.start_time
            .set({
              second: 0,
              millisecond: 0,
            })
            .format(),
          start_time: state.start_time
            .set({
              second: 0,
              millisecond: 0,
            })
            .format(),
          multipleDates: [],
        };
      } else {
        sendDate = {
          frequency: repeatState.repeatType === repeatType.WEEKLY ||
            repeatState.repeatType === repeatType.BI_WEEKLY ?
            "weekly" : repeatState.repeatType === repeatType.MONTHLY ?
            "monthly" : "daily",
          occurrences: repeatState.endstype === "occurrence" ?
            parseInt(repeatState.endCountSelect) : 1,
          recurrence: repeatState.repeatOn,
          every: repeatState.repeatType === repeatType.BI_WEEKLY ? 2 : 1,
          days: repeatState.repeatType === repeatType.MONTHLY ? [moment(state.currentDay).date()] : repeatState.repeatType === repeatType.WEEKLY ||
            repeatState.repeatType === repeatType.BI_WEEKLY ?
            repeatState.weekDays.map((item) => item + 1) : [],
          end_time: state.end_time
            .set({
              second: 0,
              millisecond: 0,
            })
            .format(),
          start_date: state.start_time
            .set({
              second: 0,
              millisecond: 0,
            })
            .format(),
          start_time: state.start_time
            .set({
              second: 0,
              millisecond: 0,
            })
            .format(),
          multipleDates: [],
        };
      }

      let res = await axios.post("court/availability", sendDate);

      dispatch(getCourtListingavAilabilitySuccess(res.data.payload));
    } catch (error) {
      console.log(error);
      // await window.modal.alert('Error', error.response.data.message);
    } finally {
      dispatch(getCourtListingavAilabilityLoading(false));
    }
  };
}

function mapRequestData(state, repeatState, getState) {


  const user = getState().auth.user;
  const currency_code = user.business.currency_code;
  let _assigned = [];
  if (["coach", "supercoach"].includes(user.member_type.key)) {
    _assigned = [user.id];
  }

  let requestData = {
    advance: state.advance,
    buffer: state.buffer,
    closed: state.closed || false,
    online: state.online || false,
    cancel_allowed: state.cancel_allowed || false,
    cancel_allowed_hour: (state.cancel_allowed_hour!=undefined || state.cancel_allowed_hour!=null?state.cancel_allowed_hour:"24"),
    note: state.note || "",
    allow_booking_until: state.allow_booking_until ?
      state.allow_booking_until
      .utc()
      .set({
        second: 0,
        millisecond: 0,
      })
      .format() : undefined,
    description: state.description || "",
    assigned: state.coach.length > 0 ? state.coach.map((each) => each.id) : _assigned,
    frequency: !repeatState ?
      "daily" : repeatState.repeatType === repeatType.WEEKLY ||
      repeatState.repeatType === repeatType.BI_WEEKLY ?
      "weekly" : repeatState.repeatType === repeatType.MONTHLY ?
      "monthly" : "daily",
    every: !repeatState ?
      "1" : repeatState.repeatType === repeatType.BI_WEEKLY ?
      2 : 1,
    // byDayOfWeek: repeatState.monthlyCase === "2",
    days: !repeatState ? [] : repeatState.repeatType === repeatType.MONTHLY ? [moment(state.currentDay).date()] : repeatState.repeatType === repeatType.WEEKLY ||
      repeatState.repeatType === repeatType.BI_WEEKLY ?
      repeatState.weekDays.map((item) => item) : [],
    occurrences: !repeatState ?
      1 : repeatState.endstype === "occurrence" ?
      parseInt(repeatState.endCountSelect) : 1,
    start_date:(state.currentRecord &&state.currentRecord.occurrence?moment(state.currentRecord.occurrence.start_date): state.start_time) 
      .utc()
      .set({
        second: 0,
        millisecond: 0,
      })
      .format(),
    start_time: state.start_time
      .utc()
      .set({
        second: 0,
        millisecond: 0,
      })
      .format(),
    end_time: state.end_time
      .utc()
      .set({
        second: 0,
        millisecond: 0,
      })
      .format(),
    ends: !repeatState ?
      null : repeatState.endstype === "dataPicker" ?
     // repeatState.ends.add(1, "day").startOf("day").utc().format() 
      repeatState.ends 
      : null,
    maxAllowedBookings: parseInt(state.quantity),
    allowBooking: "single",
    lessonType: state.lessonType,
    sport_id: +state.sportId,
    courts: state.courts.length > 0 ? state.courts.map((court) => court.id) : [],
    currencyCode: currency_code,
    microSettings: state.microSettings,
    force: false,
    include_facility_fee: state.include_facility_fee ?
      state.include_facility_fee : false,
    price: state.book_pay_type && state.book_pay_type === "2" ?
      0 : parseFloat(state.price),
    facility_price: state.include_facility_fee &&
      state.book_pay_type &&
      state.book_pay_type === "2" ?
      0 : state.facility_price ?
      parseFloat(state.facility_price) : 0,
    facility_point: state.include_facility_fee &&
      state.book_pay_type &&
      state.book_pay_type === "1" ?
      0 : state.facility_point ?
      parseFloat(state.facility_point) : 0,

    /* Special price code */
    special_price: state.include_special_price &&
      state.book_pay_type &&
      state.book_pay_type === "2" ?
      0 : state.special_price ?
      parseFloat(state.special_price) : 0,
    special_point: state.include_special_price &&
      state.book_pay_type &&
      state.book_pay_type === "1" ?
      0 : state.special_point ?
      parseFloat(state.special_point) : 0,
    specialMicroSettings: state.specialMicroSettings,

    guest_price: state.book_pay_type && state.book_pay_type === "2" ?
      0 : state.guest_price ?
      parseFloat(state.guest_price) : 0,
    point_price: state.book_pay_type && state.book_pay_type === "1" ?
      0 : state.point_price ?
      parseFloat(state.point_price) : 0,
    guest_point_price: state.book_pay_type && state.book_pay_type === "1" ?
      0 : state.guest_point_price ?
      parseFloat(state.guest_point_price) : 0,
    book_pay_type: state.book_pay_type ? state.book_pay_type : "1",
  };
  if (!repeatState || !repeatState.repeatOn) {

    let multipleDatesForSingleDay = prepareMultipleDatesForSingleDay(
      state.multipleDatesForSingleDay
    );

    requestData = {
      ...requestData,
      recurrence: false,
      multipleDates: multipleDatesForSingleDay,
    };
  } else {
    requestData = {
      ...requestData,
      allowBooking: "recurrence",
      recurrence: true,
      multipleDates: [],
    };
  }

  if (!state.llid && repeatState && repeatState.repeatOn) {
    requestData = {
      ...requestData,
      allowBooking: state.allowBooking,
    };
  }

  return requestData;
}

function prepareMultipleDatesForSingleDay(multipleDatesForSingleDay) {
  return multipleDatesForSingleDay.map((v) => {
    let _accesKey = v["accesKey"];
    let startTime = v["start_time_" + _accesKey];
    let endTime = v["end_time_" + _accesKey];

    return {
      start_time: moment(startTime)
        .utc()
        .set({
          second: 0,
          millisecond: 0,
        })
        .format(),
      end_time: moment(endTime)
        .utc()
        .set({
          second: 0,
          millisecond: 0,
        })
        .format(),
    };
  });
}

function mapRequestDataExtended(state, getState) {
  let _assigned = [];
  const user = getState().auth.user;
  if (["coach", "supercoach"].includes(user.member_type.key)) {
    _assigned = [user.id];
  }

  //-1 all,1 money,2-point
  return {
    advance: state.advance,
    buffer: state.buffer,
    note: state.note || "",
    cancel_allowed: state.cancel_allowed || false,
    cancel_allowed_hour:(state.cancel_allowed_hour!=undefined || state.cancel_allowed_hour!=null?state.cancel_allowed_hour:"24"),
    lessonType: parseFloat(state.lessonType),
    guest_price: state.guest_price ? parseFloat(state.guest_price) : 0,
    include_facility_fee: state.include_facility_fee ?
      state.include_facility_fee : false,
    facility_price: state.include_facility_fee &&
      state.book_pay_type &&
      state.book_pay_type === "2" ?
      0 : state.facility_price ?
      parseFloat(state.facility_price) : 0,
    facility_point: state.include_facility_fee &&
      state.book_pay_type &&
      state.book_pay_type === "1" ?
      0 : state.facility_point ?
      parseFloat(state.facility_point) : 0,

    /* Special price code */
    special_price: state.include_special_price &&
      state.book_pay_type &&
      state.book_pay_type === "2" ?
      0 : state.special_price ?
      parseFloat(state.special_price) : 0,
    special_point: state.include_special_price &&
      state.book_pay_type &&
      state.book_pay_type === "1" ?
      0 : state.special_point ?
      parseFloat(state.special_point) : 0,
    specialMicroSettings: state.specialMicroSettings,

    description: state.description || "",
    closed: state.closed,
    online: state.online || false,
    sport_id: +state.sportId,
    settings: state.settings,
    allowBooking: state.allowBooking,
    // noteMarkAsBooked: state.noteMarkAsBooked || null,
    maxAllowedBookings: parseInt(state.quantity),
    //status: (state.asbook) ? 'booked' : state.status,
    assigned: state.coach.length > 0 ? state.coach.map((each) => each.id) : _assigned,
    courts: state.courts.length > 0 ? state.courts.map((court) => court.id) : [],
    microSettings: state.microSettings,
    price: state.book_pay_type && state.book_pay_type === "2" ?
      0 : parseFloat(state.price),
    guest_price: state.book_pay_type && state.book_pay_type === "2" ?
      0 : state.guest_price ?
      parseFloat(state.guest_price) : 0,
    point_price: state.book_pay_type && state.book_pay_type === "1" ?
      0 : state.point_price ?
      parseFloat(state.point_price) : 0,
    guest_point_price: state.book_pay_type && state.book_pay_type === "1" ?
      0 : state.guest_point_price ?
      parseFloat(state.guest_point_price) : 0,
    book_pay_type: state.book_pay_type ? state.book_pay_type : "1",
    recurrence: {
      recurrence: false,
      start_time: state.start_time.utc().format(),
      end_time: state.end_time.utc().format(),
      multipleDates: [],
    },
  };
}
