import {
  STRIPE_CONNECT_ERROR,
  STRIPE_CONNECT_SUCCESS,
  STRIPE_GET_PLANS_ERROR,
  STRIPE_GET_PLANS_SUCCESS,
  STRIPE_NO_CONNECT_SUCCESS,
  ADD_PLAN_LOADING,
  ADD_PLAN_SUCCESS,
  UPDATE_PLAN_SUCCESS,
  DELETE_PLAN_SUCCESS,
  ADD_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_SUCCESS,
  GET_PRODUCT_SUCCESS,
  ADD_CHARGE_LOADING
} from '../constants/Stripe';

const initStore = {
  isConnected: false,
  loading: false,
  planList: [],
  productList: [],
  error: '',
};
const StripeReducer = (store = initStore, action) => {
  switch (action.type) {
    case STRIPE_CONNECT_SUCCESS:
      return Object.assign({}, store, {
        isConnected: true,
      });
    case ADD_PLAN_LOADING:
      return Object.assign({}, store, {
        loading: action.payload,
      });
    case ADD_CHARGE_LOADING:
      return Object.assign({}, store, {
        loading: action.payload,
      });
      //Plans Part 
    case ADD_PLAN_SUCCESS:
      return Object.assign({}, store, {
        planList: [action.payload, ...store.planList],
      });
    case UPDATE_PLAN_SUCCESS:
      return Object.assign({}, store, {
        planList: store.planList.map(item => {
          if (action.payload.id === item.id) {
            return {
              ...action.payload,
              name: item.name
            };
          } else {
            return item;
          }
        }),
      });
    case DELETE_PLAN_SUCCESS:
      const index = store.planList.findIndex(item => item.id === action.payload.id);
      store.planList.splice(index, 1);
      return {
        ...store,
        planList: [...store.planList]
      };
      //Product part  
    case ADD_PRODUCT_SUCCESS:
      return Object.assign({}, store, {
        productList: [action.payload, ...store.productList],
      });
    case UPDATE_PRODUCT_SUCCESS:
      return Object.assign({}, store, {
        productList: store.productList.map(item => {
          if (action.payload.id === item.id) {
            return {
              ...action.payload,
              name: item.name
            };
          } else {
            return item;
          }
        }),
      });
    case DELETE_PRODUCT_SUCCESS:
      const i = store.productList.findIndex(item => item.product === action.payload.id);
      store.productList.splice(i, 1);
      return {
        ...store,
        productList: [...store.productList]
      };
      /// 


    case STRIPE_NO_CONNECT_SUCCESS:
      return Object.assign({}, store, {
        isConnected: false,
      });
    case STRIPE_CONNECT_ERROR:
      return Object.assign({}, store, {
        isConnected: false,
        error: action.payload,
      });
    case STRIPE_GET_PLANS_SUCCESS:
      return Object.assign({}, store, {
        planList: action.payload,
      });
    case GET_PRODUCT_SUCCESS:
      return Object.assign({}, store, {
        productList: action.payload,
      });
    case STRIPE_GET_PLANS_ERROR:
      return Object.assign({}, store, {
        error: action.payload,
      });
    default:
      return store;
  }
};
export default StripeReducer;
