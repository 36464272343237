import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Modal extends Component {
  constructor(props) {
    super(props);
    this.alert = this.alert.bind(this);
    this.state = {
      isOpen: false,
      type: 'alert',
      subject: '',
      text: '',
      options: {},
      isCopy: false
    };
    this.contentClickHandler = this.contentClickHandler.bind(this);
  }

  alert(subject, text, options = {}) {
    this.setState({ isOpen: true, type: 'alert', subject, text, options });
    return new Promise(resolve => {
      this.Success = () => {
        this.setState({ isOpen: false });
        resolve(true);
      };
      this.Cancel = () => {
        this.setState({ isOpen: false });
        resolve(false);
      };
    });
  }
  pfAlert(subject, text, options = {}) {
    this.setState({ isOpen: true, type: 'pfAlert', subject, text, options });
    return new Promise(resolve => {
      this.Success = () => {
        this.setState({ isOpen: false });
        resolve(true);
      };
      this.Cancel = () => {
        this.setState({ isOpen: false });
        resolve(false);
      };
    });
  }

  cnfirm(subject, text, options = {}) {
    this.setState({ isOpen: true, type: 'cnfirm', subject, text, options });
    return new Promise(resolve => {
      this.Cancel = () => {
        this.setState({ isOpen: false });
        resolve(false);
      };
      this.Success = () => {
        this.setState({ isOpen: false });
        resolve(true);
      };
    });
  }


  contentClickHandler(e) {
    const targetLink = e.target.closest('a');
    if (!targetLink) return;
    e.preventDefault();
    this.props.history.push('/' + e.target.href.replace(/^(?:\/\/|[^\/]+)*\//, ''));
    this.Cancel();
  }

  render() {
    const {
      isOpen,
      type,
      subject,
      text,
      isCopy,
      options: { style = {}, buuton: { success, cancle } = {}, timer },
    } = this.state;

    if (timer != undefined) {
      setTimeout(() => {
        this.Success()
      }, timer);
    }
    switch (type) {
      case 'cnfirm':
        return (
          <div className={`modal ${isOpen ? 'active' : ''}`}>
            <div className="modal-content" style={style}>
              <div className="modal-text">
                <h1>{subject}</h1>
                <p onClick={this.contentClickHandler} dangerouslySetInnerHTML={{ __html: text }} />
              </div>
              <div className="modal-btn">
                <button onClick={() => this.Success()}>{success || 'yes'} </button>
                <button onClick={() => this.Cancel()}>{cancle || 'no'} </button>
              </div>
            </div>
          </div>
        );
      case 'alert':
        return (
          <div className={`modal modal-alert ${isOpen ? 'active' : ''}`}>
            <div className="modal-content" style={style}>
              <div className="modal-text">
                <h1>{subject}</h1>
                <p onClick={this.contentClickHandler} dangerouslySetInnerHTML={{ __html: text }} />
              </div>
              <div className="modal-btn">
                <button onClick={() => this.Success()}>ok</button>
              </div>
            </div>
          </div>
        );
      case 'pfAlert':
        return (
          <div className={`pfmodal modal modal-alert ${isOpen ? 'active' : ''}`}>
            <div className="modal-content" style={style}>
              <div className="pfmodalheader">
                <img src="/assets/img/ballbuttonNewLogo.png" alt="ballbutton" />
              </div>
              <div className="modal-text">

                {/* <h1>{subject}</h1> */} 
                <h2>BallButton User Benefit: PassFactory <br /> (our sister company)</h2>
                {/* <p className='textP'>BallButton is thrilled to announce our new partnership with PassFactory and provide you with an additional benefit of being a BallButton user.</p> */}
                {/* <p className='textP' style={{ paddingBottom: "0 !important" }}><b>Private, Large Group, and Exclusive Dinings</b> <br/>If you manage a restaurant use the code <b className='pTagWithCopy'>BBspecial2</b><br /> to receive 2 months free on Business Plus and Business Grand plans. */}
                  {/* <b className='pTagWithCopy'>10percentoff4ever
                    <img title='Top to copy coupon code'
                      onClick={async () => {
                        try {
                          await navigator.clipboard.writeText("10percentoff4ever");
                          this.setState({ isCopy: true });

                          setTimeout(() => {
                            this.setState({ isCopy: false });
                          }, 1000)
                        } catch (err) {
                          console.error('Failed to copy: ', err);
                        }
                      }}
                      className="copyIcon" src="/assets/img/copy-icon.png" alt="" /> <b> {isCopy && (<sup className='copyStatus'>Copied</sup>)}</b>
                  </b> */}

                {/* </p> */}
                <p className='textP' style={{ paddingBottom: "0 !important",textAlign:"center" }}>Use the code <b>10percentoff4ever</b> to receive <b>10% off.</b></p>
                {/* <p className='textP' style={{paddingBottom:"0 !important"}}><b>Access the experiences you’ve always wanted without the stress or hassle of booking yourself </b> */}

                {/* <ul>
                    <li><b>via PassFactory concierge or </b></li>
                    <li><b>as a guest of a PassFactory member.</b></li>
                    
                  </ul> */}
                {/* </p> */}
                {/* <p className='textP' style={{paddingTop:"0",marginTop:"-5px"}}>
                  
                </p>
                <br /> 
                <br />
                {/* <p className='textP'>
                  <ul>
                    <li>Get day passes to exclusive membership clubs </li>
                    <li>Meet new people & make new connections</li>
                    <li>Attend exclusive events at prestigious venues</li>
                  </ul>
                </p> */}



                <p className='textP' style={{ padding: "1% " }}><center>Visit <a href="https://passfactory.com/ ">https://passfactory.com/ </a> </center></p>
                <br />
                <p className="textP"><center>
                  <a className="pf-button-s" target="_blank" href="https://passfactory.com/ ">Learn More</a>

                </center></p>
              </div>
              <div className="modal-btn">
                <button onClick={() => this.Success()}>ok</button>
              </div>
            </div>
          </div>
        );
      default:
        return (
          <div className={`modal ${isOpen ? 'active' : ''}`}>
            <div className="modal-content">
              <div className="modal-text">
                <h1>{subject}</h1>
                <p>{text}</p>
              </div>
              <div className="modal-btn">
                <button onClick={() => this.Success()}>ok</button>
              </div>
            </div>
          </div>
        );
    }
  }
}

Modal.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Modal;
