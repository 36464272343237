import axios from "../config/axios";
import {
  CHARGE_HISTORY_ERROR,
  CHARGE_HISTORY_LOADING,
  CHARGE_HISTORY_SUCCESS,
} from "../constants/ChargeHistory";

function chargeHistoryLoading(payload) {
  return {
    type: CHARGE_HISTORY_LOADING,
    payload: payload,
  };
}

function chargeHistorySuccess(payload, last_id = null) {
  return {
    type: CHARGE_HISTORY_SUCCESS,
    last_id: last_id,
    payload: payload.rows,
    count: payload.count
  };
}

function chargeHistoryError(error) {
  return {
    type: CHARGE_HISTORY_ERROR,
    payload: error,
  };
}

export function getChargeHistory(
  last_id = 0,
  cancel_token = null,
  params = null
) {
  return async (dispatch) => {
    try {
      const _filters =
        params &&
        Object.keys(params)
        .map((key) => {
          if (key === "type") {
            return key + "=" + params[key];
          } else if (key === "startDate" || key === "endDate") {
            return key + "=" + params[key].clone().utc().format();
          }
        })
        .join("&");
      dispatch(chargeHistoryLoading(true));
      const res = await axios.get(
        `payment/charges/getall?last_id=${last_id}${
          _filters ? `&${_filters}` : ""
        }`, {
          cancelToken: cancel_token,
        }
      );

      dispatch(chargeHistorySuccess(res.data.payload, last_id));
    } catch (error) {
      console.log(error);
      dispatch(chargeHistoryError(error));
    } finally {
      dispatch(chargeHistoryLoading(false));
    }
  };
}
