import React, { Component } from "react";
import Picky from "react-picky";
import { connect } from "react-redux";

const initialDefaultOption = { id: -1, value: "Service Type" };

class LessonTypeSelect extends Component {
  state = {
    value: null,
    options: [],
    disable: false,
  };

  componentWillReceiveProps(nextProps) {
    let {
      value,
      disable_no_in_list,
      data,
      defaultOption = initialDefaultOption,
      disabled,
      filterData = () => true,
    } = nextProps;

    const options = [
      ...data
        .filter(filterData)
        .map((itm) => ({ id: itm.id||itm.lesson_type_id, value: itm.display_name ||itm.lesson_type_name})),
    ];

    let disable = disabled;

    if (value) {
      const valueFromList = options.find((itm) => itm.id == value.id);

      if (valueFromList) {
        value = valueFromList;
      } else {
        value = {
         // value: "Loading...",
          value: "Service Type",
          ...value,
        };
        if (disable_no_in_list) {
          disable = true;
        }
      }
    }

    this.setState((st) => ({ ...st, value, options, disable }));
  }

  render() {
    const { className } = this.props;
    const { disable, value } = this.state;
    let { options } = this.state;
   
    options = options.sort(function(a, b) {
      return (a.value.toUpperCase() < b.value.toUpperCase()) ? -1 : (a.value.toUpperCase() > b.value.toUpperCase()) ? 1 : 0;
    })

    return (
      <div
        className={`picky-container ${className}`}
        style={{ flex: "0 0 70%" }}
      >
        <Picky
          disabled={disable}
          includeFilter
          options={options}
          value={value}
          onChange={this.handleChange}
          valueKey="id"
          labelKey="value"
          multiple={false}
          dropdownHeight={350}
        />
      </div>
    );
  }

  handleChange = (val) => {
    this.props.onChange(val);
    document.querySelector("body").click()
  };
}

const mapStateToProps = (state,props) => {

  let data=(props.coachId!=-1 && props.coachId!=undefined? state.coach.dataMobileVersion.filter(item=> item.coach_id==props.coachId ):[])
  
  return {
    data: data.length>0?data[0].types:props.fromCourtSheet?[]: state.lesson.lessonTypes
   // data: state.lesson.lessonTypes
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(LessonTypeSelect);
