import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { CleareErrors } from '../../actions/Auth';

class Verified extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {}

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {}

  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  componentWillUpdate(nextProps, nextState) {}

  componentDidUpdate(prevProps, prevState) {}

  componentWillUnmount() {}
 
  render() {
    const { error } = this.props;
    return (
      <div className="not-found">
       <img src="/assets/img/player-guy.png" alt="Page Not Found" />
      <div style={{maxWidth:"450px", minWidth:"250px"}}>
          <h1><b>Verified!</b></h1>
          <br />
          <div className="new-button">
            <Link to="/">Go To Home</Link>
          </div>
      </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    isSignIn: state.auth.isSignIn,
    user: state.auth.user,
    forgot: state.auth.forgot,
    error: state.auth.error,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    CleareErrors: () => dispatch(CleareErrors()),
  };
};
Verified.propTypes = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Verified);

