import React, { Component } from "react";
import { sendDemo } from "../../actions/util";
import Demo from '../Demo';
import Pricing from "../../components/Pricing";
import Calendly from '../../components/Calendly';
import RequestModal from '../../components/RequestModal';
import "./yoga.scss";

class Yoga extends Component {
    constructor(props) {
        super(props);
        const _randomNr1 = Math.floor(Math.random() * 10);
        const _randomNr2 = Math.floor(Math.random() * 10);

        this.state = {
            fullName: "",
            email: "",
            company: "",
            jobTitle: "",
            message: "",
            error: {},
            total_math: "",
            totalNr: _randomNr1 + _randomNr2,
            randomNr1: _randomNr1,
            randomNr2: _randomNr2,
            //modalOpenedCalendly: null,
            //modalOpenedDemo: null,
            // contactModal: false,

        };
        this.HandleChange = this.HandleChange.bind(this);

        this.hendlerCangeText = this.hendlerCangeText.bind(this);
    }
    HandleChange(e) {
        const { [e.target.name]: error } = this.checkValidation(
            Object.assign(this.state, { [e.target.name]: e.target.value })
        );
        this.setState({
            [e.target.name]: e.target.value,
            error: Object.assign(this.state.error, { [e.target.name]: error }),
        });
    }
    openModalRequestDemo = () => {

        this.setState({
            modalOpenedDemo: <RequestModal opened={true} close={this.closeModal} />,
        });

    };
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    closeModal = () => {
        this.setState({
            modalOpenedDemo: null,
        });
    };
    closeModal2 = () => {
        this.setState({
            contactModal: false,
        });
    };

    openModal2 = () => {
        this.setState({
            contactModal: true,
        });
    };



    openCalModal = () => {
        this.setState({
            modalOpenedCalendly: (<Calendly opened={true} close={this.closeModal3} />),
        });
    }

    closeModal3 = () => {
        this.setState({
            modalOpenedCalendly: null,
        });
    }

    closeModal(e) {
        if (e.target.id === 'reqModalBack') {
            this.props.close();
        }
    }
    detectSent = (bool) => {
        if (bool) {
            this.openCalModal();
        }

    }
    request = async () => {

        const error = this.checkValidation(this.state);
        if (error.success) {
            const bool = await sendDemo(this.state); //await Promise.resolve(true);//
            if (bool) {
                this.setState({
                    fullName: "",
                    email: "",
                    company: "",
                    jobTitle: "",
                    message: "",
                    error: {},
                    total_math: "",
                });
                if (this.detectSent) {
                    this.detectSent(true);
                }
            }
        } else {

            this.setState({ error: Object.assign(this.state.error, error) });
        }
    };
    hendlerCangeText(e) {
        const { name, value } = e.target;
        const { [e.target.name]: error } = this.checkValidation(
            Object.assign(this.state, { [e.target.name]: e.target.value })
        );
        if (typeof value === "string" && value.length <= 210) {
            this.setState({
                [name]: value,
                error: Object.assign(this.state.error, { [e.target.name]: error }),
            });
        }
    }
    checkValidation(state) {
        const {
            fullName,
            email,
            company,
            jobTitle,
            message,
            total_math,
            totalNr,
        } = state;
        const error = { success: true };
        const onlyNumbers = /^\+?[0-9]{0,17}$/gi;
        const regexpEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!fullName || fullName.length < 2) {
            error.fullName = true;
            error.success = false;
        } else {
            error.fullName = false;
        }

        if (!RegExp(regexpEmail).test(email.trim())) {
            error.email = true;
            error.success = false;
        } else {
            error.email = false;
        }

        if (!company) {
            error.company = true;
            error.success = false;
        } else {
            error.company = false;
        }
        if (!jobTitle) {
            error.jobTitle = true;
            error.success = false;
        } else {
            error.jobTitle = false;
        }

        return error;
    }
    mathCaptcha = () => {
        return (
            "I am not a robot. What is the sum of " +
            this.state.randomNr1 +
            " + " +
            this.state.randomNr2
        );
    };
    render() {

        const {
            fullName,
            email,
            company,
            jobTitle,
            message,
            total_math,
        } = this.state;
        const {
            fullName: RfullName,
            email: Remail,
            company: Rcompany,
            jobTitle: RjobTitle,
            message: Rmessage,
            total_math: Rtotal_math,
        } = this.state.error;

        const math_captcha = this.mathCaptcha();
        const { modalOpenedDemo } = this.state;
        const { modalOpenedCalendly } = this.state;
        const all_features_list = [
            "Manage courts/spots/lanes (any type of courts/spots/lanes)",
            "Block any court/spot/lane and time",
            "Private lessons and group lessons (also recurring) of any type",
            "Advanced Settings: control who can book, duration, custom pricing, and many other",
            "Waitlist",
            "Analytics of business activity. Filter per court/spot/lane, coach, lesson type, member",
            "Multiple staff profiles, coach access levels and login",
            "Memberships and subscriptions",
            "Detailed profiles of members",
            "Groups and rosters",
            "Notification emails and reminders (automatic)",
            "Direct email to customer and bulk emails",
            "Payment processor (both online and card present) and POS",
            "Checkin system",
            "Web cloud software at www.ballbutton.com",
            // "Responsive screen-auto-adjust web view on mobile",
            "Mobile app Ballbutton and web account for your customers/members",
            "Dedicated customer support",
            "Month-to-month subscription. Cancel anytime.",
        ];
        return (
            <div className="app-bootstrap-support yoga-page">
                <div className="container-fluid bg_image " style={{ backgroundImage: `url("/assets/img/yoga_bg.png" )` }}>
                    <div className="cover_header"></div>
                    <div className="container-fluid  header">

                        <div className="row ">
                            <div className="col-md-1"></div>
                            <div className="col-md-3">
                                <h1>The Preferred Choice of Modern Fitness, Swim, Yoga Clubs</h1>
                                <br />
                                <p>
                                    <span>Grow your fitness, swim, yoga club's revenue, automate tasks, and offer a superior member experience with Ballbutton.</span>
                                </p>
                            </div>
                            <div className="col-md-4"></div>
                            <div className="col-md-3">
                                <div className="topForm">
                                    <div className="formHead">
                                        <p>
                                            <span>Schedule a free private demo to learn how Ballbutton can take your fitness, swim, yoga club to the next level.</span>
                                        </p>
                                    </div>
                                    <div className="formBody">

                                        <div className="form-group">
                                            <label htmlFor="fullName">Full Name *</label>
                                            <input className={`${RfullName ? "error" : ""}`}
                                                type="text"
                                                name="fullName"
                                                onChange={this.HandleChange}
                                                value={fullName} required />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="email">Email Address *</label>
                                            <input className={`${Remail ? "error" : ""}`}
                                                type="text"
                                                name="email"
                                                onChange={this.HandleChange}
                                                value={email} required />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="businessName">Club Name *</label>
                                            <input className={`${Rcompany ? "error" : ""}`}
                                                type="text"
                                                name="company"
                                                onChange={this.HandleChange}
                                                value={company} required />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="jobTitle">Job Title *</label>
                                            <input className={`${RjobTitle ? "error" : ""}`}
                                                type="text"
                                                name="jobTitle"
                                                onChange={this.HandleChange}
                                                value={jobTitle} required />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="message">Message (Optional)</label>
                                            <textarea 
                                                className={`large-input optional-field ${Rmessage ? "error " : ""}`}
                                                type="text"
                                                id="messageId"
                                                name="message"
                                                onChange={this.hendlerCangeText}
                                                value={message}></textarea>
                                            <div>
                                                <span className="counter_cutum">
                                                    {message ? message.length : 0} / {message ? 210 - message.length : 210}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <button className="button_c" type="button" onClick={this.request}>SCHEDULE PRIVATE DEMO</button>
                                            {modalOpenedCalendly}
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-1"></div>
                        </div>
                    </div>

                </div>
                <div className="container content_page">
                    <div className="row">
                        <div className="col-md">
                            <h1>Ballbutton is an all-in-one solution for managing memberships, schedules, reservations, payments and more.</h1>
                            <div className="hr"></div>
                        </div>
                    </div>
                    <div className="row margin_top">
                        <div className="col-md">
                            <div className="content_item">
                                <div>
                                    <img src="/assets/img/adv-people.png" alt="Memberships" />
                                </div>
                                <h2>Memberships</h2>
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="content_item">
                                <div>
                                    <img src="/assets/img/adv_ticket.png" alt="ubscriptions & Billing" />
                                </div>
                                <h2>Subscriptions</h2>
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="content_item">
                                <div>
                                    <img src="/assets/img/Initiations.png" alt="Initiations" />
                                </div>
                                <h2>Initiations</h2>
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="content_item">
                                <div>
                                    <img src="/assets/img/adv-Payments.png" alt="Automated Payments" />
                                </div>
                                <h2>Automated Payments</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row margin_top">
                        <div className="col-md">
                            <div className="content_item">
                                <div>
                                    <img src="/assets/img/golf-billing.png" alt="Member-Social-Feed" />

                                </div>
                                <h2>Billing </h2>
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="content_item">
                                <div>
                                    <img src="/assets/img/Check-Ins.png" alt="Check-Ins" />
                                </div>
                                <h2>Check-Ins</h2>
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="content_item">
                                <div>
                                    <img src="/assets/img/golf-booking.png" alt="Member Emails" />
                                </div>
                                <h2>Bookings & Reservations</h2>
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="content_item">
                                <div>
                                    <img src="/assets/img/golf-Schedules.png" alt="24/7 Support" />
                                </div>
                                <h2>Schedules</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row margin_top">
                        <div className="col-md">
                            <div className="content_item">
                                <div>
                                    <img src="/assets/img/Member-Social-Feed.png" alt="Member-Social-Feed" />

                                </div>
                                <h2>Member Social Feed  </h2>
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="content_item">
                                <div>
                                    <img src="/assets/img/golf-Reporting.png" alt="Reporting & Analytics " />
                                </div>
                                <h2>Reporting & Analytics </h2>
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="content_item">
                                <div>
                                    <img src="/assets/img/MemberEmails.png" alt="Member Emails" />
                                </div>
                                <h2>Member Emails</h2>
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="content_item">
                                <div>
                                    <img src="/assets/img/adv-Support.png" alt="24/7 Support" />
                                </div>
                                <h2>24/7 Support</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row margin_top">
                        <div className="col-md"></div>
                        <div className="col-md">
                            <button className="button_c learn_more_btn" onClick={this.openModalRequestDemo} >LEARN MORE</button>

                        </div>
                        <div className="col-md"></div>
                    </div>
                    <div className="row margin_top mobile_section">
                        <div className="col-md">
                            <img src="/assets/img/front_court_listing_with_bubbles.png" alt="ballbutton" />
                        </div>
                        <div className="col-md">
                            <div className="text_content">
                                <h1>Seamlessly manage and grow your business </h1>
                                <p>Ballbutton's analytics and booking capabilities help diminish dead hours, boost occupancy, and manage supplemental revenue streams such as private and group lessons. </p>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="container-fluid ">
                    <div className="row mobile_section_bg margin_top">
                        <div className="container ">
                            <div className="row  " >
                                <div className="col-md">
                                    <div className="text_content">
                                        <h1>Enjoy fully customizable advanced settings</h1>
                                        <p>Customize booking permissions, duration, and pricing for different groups, courses and more. </p>
                                        <br />
                                        <p>Manage waitlists, groups and rosters and create multiple staff profiles, coach access levels, and logins.</p>
                                    </div>
                                </div>
                                <div className="col-md">
                                    <img src="/assets/img/golf-nootbook.png" alt="ballbutton" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container content_page2" >
                    <div className="row  mobile_section_2">
                        <div className="col-md-5">
                            <img style={{width:"120%"}} src="/assets/img/yoga_img2.png" alt="ballbutton" />
                        </div>
                        <div className="col-md-2"></div>
                        <div className="col-md-5">
                            <div className="text_content">
                                <h1>Easily manage members, subscriptions, and payments </h1>
                                <p>Keep detailed member profiles and manage communications via direct and group emails. </p>
                                <br />
                                <p>Automate reminders, track activity, and manage check-ins, payments, subscriptions, initiations & more! </p>
                            </div>
                        </div>
                    </div>
                    <br /><br />
                    <div className="row  mobile_section_2 secondImage">
                        <div className="col-md">
                            <div className="text_content">
                                <h1>Your Club's Own News Feed</h1>
                                <br />
                                <p>Take advantage of our one-of-a-kind Members News Feed.</p>
                                <br />
                                <p>Keep your members engaged with your club!</p>
                                <br />
                            </div>
                        </div>
                        <div className="col-md">
                            <img src="/assets/img/adv-mobile3.png" alt="ballbutton" />
                        </div>
                    </div>
                    <br />
                    <br />
                    <br />
                </div>
                <div className="container-fluid pricing_part">
                    <div className="row">
                        <div className="col-md-12 yogaPagePrice">
                            <Pricing hideSchedule="true" />
                        </div>
                    </div>
                </div>

                <hr className="line" />
                <div className="container content_page3 margin_top">
                    <div className="row ">

                        <div className="col-md">
                            <h1>Schedule your private demo today to discover what makes Ballbutton the preferred choice of modern fitness, swim, yoga clubs. </h1>
                        </div>
                        <div className="col-md">
                            <div className="custumList">
                                <div className="custtomListItem">
                                    <div>
                                        <img src="/assets/img/golf-eya.png" alt="ballbutton" />
                                    </div>
                                    <div>
                                        <span>Ballbutton users only see their membership business  </span>
                                    </div>
                                </div>
                                <div className="custtomListItem">
                                    <div>
                                        <img src="/assets/img/golf-like.png" alt="ballbutton" />
                                    </div>
                                    <div>
                                        <span>Bring your club into the 21st century with social features  </span>
                                    </div>
                                </div>
                            </div>
                            <button onClick={this.openModalRequestDemo} className="button_c learn_more_btn">SCHEDULE PRIVATE DEMO</button>
                            {modalOpenedDemo}
                        </div>
                    </div>
                </div>
            </div >
        )

    }
}

export default Yoga;
