/* eslint-disable no-empty */
import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  createstripepaymentintents,
  sendCaptureIntentRequest,
} from "../../actions/util";
import { captureTerminalStripe } from "../../actions/Stripe";
import { getChargeHistory } from "../../actions/ChargeHistory";
import { connect } from "react-redux";
import AsyncSelect from "react-select/async";
import { searchUsers } from "../../actions/Members";
import Switcher from "../UI/Switcher";
import Notification from "../Notification";
import Taxes from "../../containers/Taxes";
import "./terminalStyles.scss";
export class Amount extends Component {
  constructor(props) {

    super(props);
    this.state = {
      isValidationError: false,
    };
    this.hendlerChange = this.hendlerChange.bind(this);
  }

  hendlerChange(e) {
    const { name, value } = e.target;
    //  const isValidationError = !(/^[1-9]\d{0,5}$/.test(value));
    // this.state.isValidationError !== isValidationError && this.setState({ isValidationError });
    this.props.onChange(e);
  }

  render() {
    const { isValidationError } = this.state;
    const { error, onChange, ...rest } = this.props;

    return (
      <input
        {...rest}
        style={isValidationError || error ? { border: "1px solid red" } : {}}
        onChange={this.hendlerChange}
      />
    );
  }
}

Amount.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string.isRequired,
  error: PropTypes.bool,
};
Amount.defaultProps = {
  error: false,
  onChange: (e) =>
    console.warn(e, "Amount component shuld have a onChange eventHendler"),
};

// eslint-disable-next-line react/no-multi-comp
class TerminalModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      price: props.bookings
        ? parseFloat(
          props.bookings.price +
          (props.bookings.facility_fee_price
            ? Number(props.bookings.facility_fee_price)
            : 0)
        ).toFixed(2)
        : props.default_price
          ? props.default_price
          : "",
      selected_taxes: [],
      goods: true,
      tax: false,
      name: "",
      amount: props.bookings
      ? parseFloat(
        props.bookings.price +
        (props.bookings.facility_fee_price
          ? Number(props.bookings.facility_fee_price)
          : 0)
      ).toFixed(2)
      : props.default_price
        ? props.default_price
        : "",
      note: "",
      member: null,
      taxTotal: "",
      showLoadingToTerminal:false,
    };
    this.hendlerOnChange = this.hendlerOnChange.bind(this);
  }

  hendlerInputChange() {
    return (e) => {

      const { value, name } = e.target;

      this.setState({ [name]: value });
      if (name == "amount") {
        this.setState({ price: value });
      }
    };
  }
  hendlerOnChange(e) {
    const { name, type, value: valueTarget, checked } = e.target;
    const value = type === "checkbox" ? checked : valueTarget;
    this.setState({ [name]: value });
  }
  selectTax = (value) => {
    this.setState({ selected_taxes: value });
  };
  chargeTerminal() {
    const {
      business,
      is_lesson,
      bookings,
      apt_id,
      user_id,
      cbCharge,
      isFromUserProfile,
      user
    } = this.props;
    const { member } = this.state;
    const _user_id = user_id ? user_id : member?member.user_id : null;
    return async () => {
      try {
        
        let totalAmount = 0
        if (this.props.from) {
          totalAmount = this.state.amount;
        } else {
          totalAmount = (this.state.taxTotal != ""  ? parseFloat(this.state.taxTotal) + parseFloat(this.state.price) : parseFloat(this.state.amount))
          
        }
        this.props.terminal.setReaderDisplay({
          type: "cart",
          cart: {
            lineItems: [
              {
                description: this.state.note,
                amount: totalAmount,
                quantity: 1,
              },
            ],
            tax: 0,
            total: totalAmount,
            currency: business.currency_code,
          },
        });
        // await window.modal.alert('Alert', 'test');
        const secret = await createstripepaymentintents(
          {
            amount: totalAmount,
            description: this.state.note,
            full_name: member?member.label:null
          },
         (isFromUserProfile?user.id:_user_id) 
          
        );
        if(secret){
          this.setState({showLoadingToTerminal:true})
        }
        const result = await this.props.terminal.collectPaymentMethod(secret);

        if (result.error) {
          console.error(
            `Collect payment method failed: ${result.error.message}`
          );
        } else {
          const htmltemplate = `Amount: ${(
            result.paymentIntent.amount / 100
          ).toFixed(2)}`;
          if (await window.modal.cnfirm("Confirm", htmltemplate)) {
            const confirmResult = await this.props.terminal.processPayment(
              result.paymentIntent
            );
            if (confirmResult.error) {
              console.error(`Confirm failed: ${confirmResult.error.message}`);
            } else if (confirmResult.paymentIntent) {
              this.props.clouseModal();
              if (bookings) {
                if (is_lesson) {
                  this.props.captureTerminalStripe(
                    confirmResult.paymentIntent.id,
                    {
                      appointment_id: apt_id,
                      user_id: user_id,
                      charge_for: "lesson",
                    }
                  );
                } else {
                  this.props.captureTerminalStripe(
                    confirmResult.paymentIntent.id,
                    {
                      appointment_id: apt_id,
                      user_id: user_id,
                      charge_for: "court",
                    }
                  );
                }
              } else {
                if (cbCharge) {
                  cbCharge({
                    payment_intent_id: confirmResult.paymentIntent.id,
                    user_id: member ? member.user_id : user_id,
                    method: "reader",
                    ...this.state,
                  });
                } else {
                  await sendCaptureIntentRequest(
                    confirmResult.paymentIntent.id,
                    {
                      user_id:(isFromUserProfile?user.id:member ? member.user_id : user_id) ,
                      full_name: member?member.label:"N/A"
                    }
                  );
                if(isFromUserProfile){
                  window.location.reload()
                }
                  await this.props.getChargeHistory(0, null);
                }
              }
            }
            
          }
        }
        this.setState({showLoadingToTerminal:false})
      } catch (error) {
        console.error(error);
      }
    };
  }

  render() {
    const { clouseModal, show_members, tax, member_type_id, stripeUserId, from, user } = this.props;
    
    const { amount, note, price, selected_taxes ,showLoadingToTerminal} = this.state;

    const hide_subtotal = from && from.hide_subtotal ? true : false;
    const taxPrice = from && from.taxPrice ? from.taxPrice : 0;
    const subTotal = from && from.subTotal ? from.subTotal : 0;
    const totalPrice = from && from.totalPrice ? from.totalPrice : 0;
    if (from && from.totalPrice) {
      this.state.amount = from.totalPrice;
      if (this.state.taxTotal == "" && from.taxPrice > 0) {
        this.state.taxTotal = from.taxPrice;
        this.state.tax = true;
      }
    }
    let tax_total = 0;
    if (price && selected_taxes.length > 0) {
      tax_total = selected_taxes
        .map((v) => {
          return (
            Math.round(
              (parseFloat(price) / 100) * parseFloat(v.percentage) * 100
            ) / 100
          );
        })
        .reduce((sum, sub_sum) => sum + sub_sum);
      this.state.taxTotal = tax_total;
      if (this.state.amount == this.state.price) {
        this.state.amount = tax_total + parseInt(this.state.amount);
      } else {
        this.state.amount = this.state.price;
      }
    }

    return (
      <div className="modal terminal-modal active">
        <div className="modal-content" style={{position: "relative"}}>
          <div className="close-btn">
            <button onClick={clouseModal}>
              <img src="/assets/img/cancel.png" alt="cancel" />
            </button>
          </div>


          {!hide_subtotal && (
            <div className="taxParth">
              <span>Total</span>
              <Switcher
                name="goods"
                onChange={this.hendlerOnChange}
                value={this.state.goods}
                checked={this.state.goods}
              />
              <div className="notification-group _notification-for-subtotal">
                Subtotal
                <Notification className="whiteNotification">
                  In the United States the software will add the sales tax to
                  subtotal amount.
                </Notification>
              </div>
              <Taxes goods={this.state.goods} selectTax={this.selectTax} />
            </div>
          )}


          {show_members && (
            <div className="flex-col-100 member-drop">
              <label>Select Customer</label>
              <AsyncSelect
                className={"input-with input-with-extra"}
                cacheOptions
                isClearable
                placeholder={"Type..."}
                value={this.state.member}
                loadOptions={searchUsers}
                onChange={(property, value) => {
                  this.setState({ member: property });
                }}
              />
            </div>
          )}

          <div>
            <div className="m-bottom-15">

              <Amount
                disabled={totalPrice > 0 ? true : false}
                type="number"
                onWheel={(e) => e.target.blur()}
                name="amount"
                placeholder="amount"
                value={totalPrice > 0 ? totalPrice : price}
                onChange={this.hendlerInputChange()}
              />
            </div>
            <div>
              <input
                type="text"
                name="note"
                placeholder="note"
                value={note}
                onChange={this.hendlerInputChange()}
              />
            </div>
            {!this.state.goods && tax !== 0 ? (
              <div className="taxtTotal">
                <ul>
                  <li>Tax total: {parseFloat(tax_total).toFixed(2)} </li>
                  <li>
                    Total:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {price === ""
                      ? 0
                      : parseFloat(parseFloat(price) + tax_total).toFixed(2)}
                  </li>
                </ul>
              </div>
            ) : null}
            {hide_subtotal ? (
              <div className="taxtTotal">
                <ul>
                  {subTotal > 0 && (
                    <li> Sub total: {parseFloat(subTotal).toFixed(2)} </li>
                  )}
                  {taxPrice > 0 && (
                    <li> Tax total: {parseFloat(taxPrice).toFixed(2)} </li>
                  )}
                  {totalPrice > 0 && (
                    <li>
                      Total:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {parseFloat(totalPrice).toFixed(2)}
                    </li>
                  )}
                </ul>
              </div>
            ) : null}
            <button className="chargeBuuton" onClick={this.chargeTerminal()}>Confirm</button>
          </div>
          <div className="terminalMessigeFullScreen" style={{display:showLoadingToTerminal?"block":"none"}}>
                    <center>
                      <b>Continue the process on the terminal.</b>
                    </center>
          </div>
        </div>
       
      </div>
    );
  }
}

TerminalModal.propTypes = {
  clouseModal: PropTypes.func.isRequired,
  terminal: PropTypes.objectOf(PropTypes.any).isRequired,
  reader: PropTypes.objectOf(PropTypes.any).isRequired,
  connection: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = (state) => {
  return {
    business: state.auth.user.business,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getChargeHistory: (last_id, cancel_token) =>
      dispatch(getChargeHistory(last_id, cancel_token)),
    captureTerminalStripe: (payment_intent_id, data = {}) =>
      dispatch(captureTerminalStripe(payment_intent_id, data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TerminalModal);
