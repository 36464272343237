import React, { Component } from "react";
import moment from "moment-timezone";
import {
    deleteMicroSettings,
    getMicroSettingsFreezed,
} from "../../actions/MicroSettings";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Freeze from "./freeze";
import { getCourts } from "../../actions/Courts";
import { getSportList } from "../../actions/Facility";
import { getActiveFacilityGroup } from "../../utils/facility";
import Loading from "../../components/UI/Loading";
import "./freeze.scss";


class FreezeModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showForm: false,
            error: {}
        };
        this.closeModal = this.closeModal.bind(this);

    }
    closeModal = (e) => {
        this.props.close();
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.modalClass !== this.props.modalClass) {
            this.setState({ error: {} });
        }


    }
    addRow = () => {
        this.setState({
            showForm: true,
        });
    };

    handleDelete = async (id, group_by) => {
        if (
            await window.modal.cnfirm(
                "Confirm",
                "Are you sure to unfreeze the freezed days ?"
            )
        ) {
            this.props.deleteMicroSettings(id, "freeze", group_by);
        }
    };
    handleFormClose = () => {
        this.setState({
            showForm: false,
        });
    };

    componentDidMount() {
        const sportCategoryId = getActiveFacilityGroup()?.sport_category_id;
        this.props.getCourts();
        this.props.getMicroSettingsFreezed();
        this.props.getSportList({
            category: sportCategoryId,
        });
    }

    render() {
        const {
            micro_settings: {
                freeze: { loading, data },
            },
            timeZone,
            modalClass
        } = this.props;

        const { showForm } = this.state;

        return (
            <div
                className={`modal modal-pass ${modalClass ? "active" : ""}`}
                onClick={(e) => {
                    if (e.target.id === "modal-dashboard-bak") {
                        this.closeModal(e);
                    }
                }}
                id="modal-dashboard-bak"
            >
                <div className={`modal-content lesson-in-court-modal`}>
                    <div className="close-btn">
                        <button onClick={(e) => { this.closeModal(e) }}>
                            <img src="/assets/img/cancel.png" alt="cancel" />
                        </button>
                    </div>
                    <div className={`freeze-list-container  ${this.props.extraClassName}`}>
                        {showForm ? (
                            <Freeze onClose={this.handleFormClose} />
                        ) : (
                            <div className="table-responsive">
                                {loading ? (
                                    <Loading />
                                ) : (
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Note</th>
                                                <th>From</th>
                                                <th>To</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map((row) => (
                                                <tr key={row.id}>
                                                    <td>{row.note}</td>
                                                    <td>
                                                        {moment(row.micro_settings["0"].rules["0"].start_time)
                                                            .tz(timeZone)
                                                            .format("MMMM Do YYYY, h:mm:ss a")}
                                                    </td>
                                                    <td>
                                                        {moment(row.micro_settings["0"].rules["0"].end_time)
                                                            .tz(timeZone)
                                                            .format("MMMM Do YYYY, h:mm:ss a")}
                                                    </td>
                                                    <td>
                                                        <div className="d-flex justify-center">
                                                            <button
                                                                className="btn btn-red rounded mr-2"
                                                                type="button"
                                                                onClick={() =>
                                                                    this.handleDelete(row.id, row.group_by)
                                                                }
                                                            >
                                                                <img
                                                                    src="/assets/img/cancel-white.png"
                                                                    width={15}
                                                                    height={15}
                                                                    alt="Unfreeze"
                                                                />
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}

                                            <tr>
                                                <td colSpan={4}>
                                                    <button
                                                        className="btn btn-green rounded mx-auto"
                                                        type="button"
                                                        onClick={this.addRow}
                                                    >
                                                        <img
                                                            src="/assets/img/plus.png"
                                                            width={20}
                                                            height={20}
                                                            alt="plus"
                                                        />
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        error: state.auth.signup,
        user: state.auth.user,
        micro_settings: state.micro_settings,
        timeZone: state.auth.user.business.timezone,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getCourts: (cpid) => dispatch(getCourts(cpid)),
        getSportList: (params = {}) => dispatch(getSportList(params)),
        getMicroSettingsFreezed: () => dispatch(getMicroSettingsFreezed()),
        deleteMicroSettings: (id, type = "freeze", group_by = null) =>
            dispatch(deleteMicroSettings(id, type, group_by)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FreezeModal)
