import * as actionTypes from "../constants/Location";

const initState = {
  entity: {
    error: undefined,
    current: {
      loading: false,
      data: undefined,
    },
    list: {
      loading: false,
      total: 0,
      data: [],
    },
  },
  checkIn: {
    error: undefined,
    list: {
      loading: false,
      total: 0,
      data: [],
    },
  },
};

const LocationReducer = (state = initState, action) => {
  switch (action.type) {
    // ENTITY_LIST handlers
    case actionTypes.LOCATION_ENTITY_LIST_LOADING: {
      return {
        ...state,
        entity: {
          ...state.entity,
          list: {
            ...state.entity.list,
            loading: action.payload,
          },
        },
      };
    }
    case actionTypes.LOCATION_ENTITY_LIST_ERROR: {
      return {
        ...state,
        entity: {
          ...state.entity,
          list: {
            ...state.entity.list,
            error: action.payload,
          },
        },
      };
    }
    case actionTypes.LOCATION_ENTITY_LIST_SUCCESS: {
      return {
        ...state,
        entity: {
          ...state.entity,
          list: {
            ...state.entity.list,
            error: undefined,
            data: action.payload.entities,
            total: action.payload.total,
          },
        },
      };
    }

    // ENTITY_CURRENT handlers
    case actionTypes.LOCATION_ENTITY_CURRENT_LOADING: {
      return {
        ...state,
        entity: {
          ...state.entity,
          current: {
            ...state.entity.current,
            loading: action.payload,
          },
        },
      };
    }
    case actionTypes.LOCATION_ENTITY_CURRENT_ERROR: {
      return {
        ...state,
        entity: {
          ...state.entity,
          current: {
            ...state.entity.current,
            error: action.payload,
          },
        },
      };
    }
    case actionTypes.LOCATION_ENTITY_CURRENT_SUCCESS: {
      return {
        ...state,
        entity: {
          ...state.entity,
          current: {
            ...state.entity.current,
            error: undefined,
            data: action.payload,
          },
        },
      };
    }

    // ENTITY_LIST handlers
    case actionTypes.LOCATION_CHECKIN_LIST_LOADING: {
      return {
        ...state,
        checkIn: {
          ...state.checkIn,
          list: {
            ...state.checkIn.list,
            loading: action.payload,
          },
        },
      };
    }
    case actionTypes.LOCATION_CHECKIN_LIST_ERROR: {
      return {
        ...state,
        checkIn: {
          ...state.checkIn,
          list: {
            ...state.checkIn.list,
            error: action.payload,
          },
        },
      };
    }
    case actionTypes.LOCATION_CHECKIN_LIST_SUCCESS: {
      return {
        ...state,
        checkIn: {
          ...state.checkIn,
          list: {
            ...state.checkIn.list,
            error: undefined,
            data: action.payload.rows,
            total: action.payload.total,
          },
        },
      };
    }

    default:
      return state;
  }
};

export default LocationReducer;
