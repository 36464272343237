import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import { getMembershipInfo } from "../../actions/Membership";
import PayModal from "../../containers/PayModal";
import ModalWaiver from "../ModalWaiver";
import Loading from "../../components/UI/Loading";
import NotFound from "../../components/NotFound";

import "./style.scss";
import { generateImgSrc } from "../../utils/helpers";
import Auth from "../../containers/Auth";
import qs from "qs";

const BusinessSubscriptionsPage = () => {
  const {
    auth: { isSignIn, user },
    membershipInfo,
  } = useSelector((state) => ({
    auth: state.auth,
    membershipInfo: state.membership.info,
  }));
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [subsModal, setSubsModal] = useState(null);
  const { uid } = useParams();

  const handleSelect = useCallback(
    (plan) => {
      if (!isSignIn) {
        const data = qs.parse(location.search, {
          ignoreQueryPrefix: true,
        });

        setSubsModal(
          <Auth
            opened
            close={closeModal}
            modalTarget="register"
            metadata={{
              showDoBField: plan.max_age || plan.min_age,
              min_age: plan.min_age,
              max_age: plan.max_age,
            }}
            referer={{
              pathname: location.pathname,
              search: qs.stringify({ ...data, subscribe_to_plan: plan.id }),
            }}
          />
        );

        return;
      }

      const show_sign =
        membershipInfo.data.require_waiver_sign &&
        user.member_in_businesses.filter(
          (v) =>
            v.user_business_id === membershipInfo.data.id && v.signed === true
        ).length === 0;

      setSubsModal(
        <PayModal
          user={user}
          options={{
            nbu_subscribe: true,
            plan: plan,
            show_sign: show_sign,
            business: membershipInfo.data,
          }}
          close={closeModal}
          subscription_status={false}
          key={Date.now()}
        />
      );
    },
    [
      isSignIn,
      user,
      location.search,
      location.pathname,
      membershipInfo,
      openUpdateCase,
    ]
  );

  const openUpdateCase = useCallback(() => {
    return new Promise((resolve, reject) => {
      setSubsModal(
        <ModalWaiver
          from_subscription={true}
          in_business={user.member_in_businesses.find(
            (v) => v.user_business_id === membershipInfo.data.id
          )}
          business={membershipInfo.data}
          resolve={resolve}
          closeDell={closeModal}
        />
      );
    });
  }, [user, membershipInfo, closeModal]);

  const closeModal = () => {
    setSubsModal(null);
  };

  useEffect(() => {
    dispatch(getMembershipInfo(uid));
  }, [dispatch, uid]);

  useEffect(() => {
    const data = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    if (!membershipInfo.loading && membershipInfo.data) {
      if (data.subscribe_to_plan) {
        const plan = membershipInfo.data.plans.find(
          (itm) => itm.id == data.subscribe_to_plan
        );
        if (plan) {
          handleSelect(plan);
          history.push(location.pathname);
        }
      }
    }
  }, [
    handleSelect,
    history,
    location.pathname,
    location.search,
    membershipInfo.data,
    membershipInfo.loading,
  ]);

  if (membershipInfo.loading || !membershipInfo.data) {
    // todo: show loading or error

    if (membershipInfo.loading) return <Loading />;
    else return <NotFound />;
  }

  return (
    <div className="app-bootstrap-support business-memberships">
      <div className="container-fluid mt-5">
        <div className="container">
          <div className="row">
            <div className="col-10 col-lg-7 col-xl-7 mx-auto">
              <img
                src={generateImgSrc(
                  membershipInfo.data.user_id,
                  membershipInfo.data.profile_image
                )}
                width="200"
                height="200"
                className="rounded mx-auto d-block"
                alt=""
              />
              <h4 className="mt-4 mb-2 text-center">
                {membershipInfo.data.name}
              </h4>
              <p className="text-center mb-4">{membershipInfo.data.about}</p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col col-9 mx-auto">
            <div className="row row-cols-1 row-cols-lg-3 row-cols-xl-3 membership-plans--list justify-content-center">
              {membershipInfo.data.plans.map((plan) => (
                <div className="col mb-4 membership-plans--item" key={plan.id}>
                  <div className="membership-plans--item__content">
                    <div className="card membership-plan">
                      <div className="card-body position-relative">
                        <h5 className="card-title membership-plan--title">
                          {plan.nickname}
                        </h5>
                        <h6 className="card-title text-center font-weight-bold mb-4 membership-plan--price">
                          ${plan.amount}/{plan.interval}
                        </h6>

                        <div className="card-content">
                          <p className="mb-4">{plan.description}</p>
                          <p className="mb-4">
                            Available locations and facilities
                          </p>
                          <ul className="list-group list-group-flush plan-list">
                            {plan.facility_groups.map((itm) => (
                              <li
                                className="list-group-item plan-list--item"
                                key={itm.id}
                              >
                                {itm.location.name} - {itm.name}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                      <div className="card-footer">
                        <button
                          type="button"
                          className="btn py-2 px-4 rounded-pill btn-success"
                          onClick={() => handleSelect(plan)}
                        >
                          Subscribe
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {subsModal}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessSubscriptionsPage;
