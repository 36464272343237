import { GET_TERMINAL_LIST, GET_TERMINAL_LIST_LOADING } from '../constants/Terminal';

const initStore = {
  loading: false,
  data: [],
};
const TerminalReducer = (store = initStore, action) => {
  switch (action.type) {
    case GET_TERMINAL_LIST_LOADING:
      return Object.assign({}, store, {
        loading: action.payload,
      });

    case GET_TERMINAL_LIST:
      return Object.assign({}, store, {
        data: action.payload,
      });

    // eslint-disable-next-line no-case-declarations

    default:
      return store;
  }
};
export default TerminalReducer;
