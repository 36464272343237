export const GET_MEMBERS_ERROR = 'GET_MEMBERS_ERROR';
export const GET_MEMBERS_LOADING = 'GET_MEMBERS_LOADING';
export const GET_MEMBERS_SUCCESS = 'GET_MEMBERS_SUCCESS';
export const GET_MEMBER_ONLY_SUCCESS = 'GET_MEMBER_ONLY_SUCCESS';
export const REMOVE_MEMBERS_SUCCESS = 'REMOVE_MEMBERS_SUCCESS';
export const ADD_MEMBER_SUCCESS = 'ADD_MEMBER_SUCCESS';
export const GET_CUSTOMER_SUCCESS = 'GET_CUSTOMER_SUCCESS';
export const GET_MEMBER_FAMILY_SUCCESS = 'GET_MEMBER_FAMILY_SUCCESS';
export const GET_MEMBER_BALANCE_SUCCESS = 'GET_MEMBER_BALANCE_SUCCESS';
export const GET_MEMBER_BOOKING_SUCCESS = 'GET_MEMBER_BOOKING_SUCCESS';
export const GET_MEMBER_INVOICE_ITEM_SUCCESS = 'GET_MEMBER_INVOICE_ITEM_SUCCESS';
export const GET_MEMBER_INVOICE_HISTORY_SUCCESS = 'GET_MEMBER_INVOICE_HISTORY_SUCCESS';
export const GET_MEMBER_GROUPS_SUCCESS = 'GET_MEMBER_GROUPS_SUCCESS';
export const GET_CUSTOMER_LOADING = 'GET_CUSTOMER_LOADING';
export const GET_MEMBERS_GROUPS_LOADING = 'GET_MEMBERS_GROUPS_LOADING';
export const GET_MEMBER_POINTS_PAYMENTS_SUCCESS = 'GET_MEMBER_POINTS_PAYMENTS_SUCCESS';
export const ADD_MEMBER_POINTS_PAYMENTS_SUCCESS = 'ADD_MEMBER_POINTS_PAYMENTS_SUCCESS';
export const BUY_MEMBER_POINTS_PAYMENTS_SUCCESS = 'BUY_MEMBER_POINTS_PAYMENTS_SUCCESS';
export const GET_MEMBER_RESTRICTIONS_SUCCESS = 'GET_MEMBER_RESTRICTIONS_SUCCESS';
export const GET_STAFF_BOOKING_OVERIDES_SUCCESS = 'GET_STAFF_BOOKING_OVERIDES_SUCCESS';
export const GET_MEMBER_LEVEL_SUCCESS = 'GET_MEMBER_LEVEL_SUCCESS';
