import React, { Component } from "react";
import Picky from "react-picky";
import { connect } from "react-redux";
import { getSportList } from "../../actions/Facility";
import { GLOBAL_LOCATION_FACILITY_PAIR } from "../../utils/constants";

const initialDefaultOption = { id: -1, value: "None" };

class SportSelect extends Component {
  state = {
    value: null,
    options: [],
    disable: false,
  };

  componentDidUpdate(prevProps) {
    const { sportCategoryId } = this.props;
    if (sportCategoryId !== prevProps.sportCategoryId) {
      this.props.getSportList({
        category: sportCategoryId,
      });
    }
  }

  componentDidMount() {
    const { value, sportCategoryId } = this.props;
    if (!value || value?.id === -1) {
      const sportId = this.getStoredSportId();

      if (!!Number(sportId)) this.handleChange({ id: sportId });
    }

    if (sportCategoryId) {
      this.props.getSportList({
        category: sportCategoryId,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    let {
      value,
      disable_no_in_list,
      sport,
      defaultOption = initialDefaultOption,
      disabled,
    } = nextProps;

    const {
      list: { data },
    } = sport;
    const options = [
      defaultOption,
      ...data.map((itm) => ({ id: itm.id, value: itm.display_name })),
    ];
    let disable = disabled;

    if (value) {
      const valueFromList = options.find((itm) => itm.id == value.id);

      if (valueFromList) {
        value = valueFromList;
      } else {
        value = {
          value: "Loading...",
          ...value,
        };
        if (disable_no_in_list) {
          disable = true;
        }
      }
    }

    this.setState((st) => ({ ...st, value, options, disable }));
  }

  render() {
    const { className } = this.props;
    const { disable, options, value } = this.state;

    return (
      <div
        className={`picky-container ${className}`}
        style={{ flex: "0 0 70%" }}
      >
        <Picky
          disabled={disable}
          includeFilter
          options={options}
          value={value}
          onChange={this.handleChange}
          valueKey="id"
          labelKey="value"
          multiple={false}
          dropdownHeight={350}
        />
      </div>
    );
  }

  handleChange = (val) => {
    this.setStoredSportId(val.id);
    this.props.onChange(val);
  };

  getStoredSportId = () => {
    const pairId =
      window.localStorage.getItem(GLOBAL_LOCATION_FACILITY_PAIR) || "-1:-1";

    const [_, facilityGroupId] = pairId.split(":");

    return window.localStorage.getItem(`FG_${facilityGroupId}_SPORT`);
  };

  setStoredSportId = (id) => {
    const pairId =
      window.localStorage.getItem(GLOBAL_LOCATION_FACILITY_PAIR) || "-1:-1";

    const [_, facilityGroupId] = pairId.split(":");

    window.localStorage.setItem(`FG_${facilityGroupId}_SPORT`, id);
  };
}

const mapStateToProps = (state) => {
  return {
    sport: state.facility.sport,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSportList: (data) => dispatch(getSportList(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SportSelect);
