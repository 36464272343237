import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Demo from '../Demo';
import { withRouter } from 'react-router';
import RequestModal from '../../components/RequestModal';
import ContactAsModal from '../../components/ContactAsModal';
import './footer.scss';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpenedDemo: null,
      contactModal: false,
    };
  }

  openModalRequestDemo = () => {
    this.setState({
      modalOpenedDemo: <RequestModal opened={true} close={this.closeModal} />,
    });
  };

  closeModal = () => {
    this.setState({
      modalOpenedDemo: null,
    });
  };

  closeModal2 = () => {
    this.setState({
      contactModal: false,
    });
  };

  openModal2 = () => {
    this.setState({
      contactModal: true,
    });
  };

  render() {
    let footer_menu = [
      {
        name: 'Terms of Service',
        link: '/assets/file/terms-of-service',
      },
      /* {
        name: 'Features',
        link: '#'
      }, {
        name: 'Product Documentation',
        link: '#'
      }, {
        name: 'Contact',
        link: '#'
      }, {
        name: 'Support',
        link: '#'
      }, {
        name: 'App Downloads',
        link: '#'
      }, */
      {
        name: 'Privacy Policy',
        link: '/assets/file/privacy-policy',
      },
      /*  , {
          name: 'Pricing',
          link: '/pricing-and-features'
        },
        {
          name: 'FAQ',
          link: '#'
        },*/
    ];
    const is_pricing = this.props.location.pathname === '/pricing-and-features';
    const is_business = this.props.location.pathname === '/club_management_software';
    
    if (is_pricing) {
      footer_menu = [
        ...footer_menu,
        {
          name: 'Payments Processing',
          link: '/assets/file/payments-processing-accounts.pdf',
        },
        {
          name: 'Why Ballbutton',
          link: '/why-ballbutton',
          target: '_self',
        },
      ];
    }
    if (is_business) {
      footer_menu = [
        ...footer_menu,
        {
          name: 'Why Ballbutton',
          link: '/why-ballbutton',
          target: '_self',
        },
      ];
    }
    const { modalOpenedDemo } = this.state;
    const { isSignIn } = this.props;
    const pathArray = window.location.pathname.split('/');
   
    return (
      <footer id="footer">
        <div className="main-container">
          <div className="footer-content">
            <div className="footer-logo">
              <div className="logo-div">
                <Link to="/">
                  <img src="/assets/img/logo-new.png" alt="ballbutton" />
                </Link>
              </div>
              {!isSignIn && (
                <div className="schedule-div">
                  <Demo onClick={this.openModalRequestDemo} />
                </div>
              )}
            </div>
            <div className="footer-menu">
              <ul>
                {footer_menu.map((v, i) => (
                  <li key={i}>
                    <Link target={v.target ? v.target : '_blank'} to={v.link}>
                      {v.name}
                    </Link>
                  </li>
                ))}
                {!isSignIn && !pathArray.includes("pricing-and-features") && (
                  <li>
                    <Link  to={"/pricing-and-features"}>
                        Pricing
                    </Link>
                  </li>
                )}
                <li>
                  <a onClick={this.openModal2}> Contact us</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="footer-copyright">
          <div className="main-container">
            <p>
              &copy; {new Date().getFullYear()} Ballbutton. All rights reserved.<span style={{ float: 'right' }}></span>
            </p>
          </div>
          <div className="address-footer">
            <p>Made in Los Angeles</p>
          </div>
        </div>
        {modalOpenedDemo}
        <ContactAsModal opened={this.state.contactModal} close={this.closeModal2} />
      </footer>
    );
  }
}

export default withRouter(Footer);
