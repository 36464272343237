import { GET_ANALITICS_ERROR, GET_ANALITICS_LOADING, GET_ANALITICS_SUCCESS } from '../constants/Analytics';

const initStore = {
  loading: false,
  data: [],
  error: null,
};
const AnalyticsReducer = (store = initStore, action) => {
  switch (action.type) {
    case GET_ANALITICS_SUCCESS:
      return Object.assign({}, store, {
        data: action.payload,
      });
    case GET_ANALITICS_ERROR:
      return Object.assign({}, store, {
        error: action.payload,
      });
    case GET_ANALITICS_LOADING:
      return Object.assign({}, store, {
        loading: action.payload,
      });
    default:
      return store;
  }
};
export default AnalyticsReducer;
