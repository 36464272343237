import {
  GET_NOTIFICATION_ERROR,
  GET_NOTIFICATION_SUCCESS,
  LOADING
} from '../constants/Notifications';

const initStore = {
  data: {
    total: 0,
    rows: [],
  },
  error: '',
  loading: false,
};
const NotificationsReducer = (store = initStore, action) => {
  switch (action.type) {
    case LOADING:
      return Object.assign({}, store, {
        loading: action.payload,
      });
    case GET_NOTIFICATION_SUCCESS:
      return Object.assign({}, store, {
        data: {
          ...action.payload
        },
      });

    case GET_NOTIFICATION_ERROR:
      return Object.assign({}, store, {
        error: action.payload,
      });
    default:
      return store;
  }
};
export default NotificationsReducer;
