import React, { Component } from "react";

class Calendly extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    const head = document.querySelector("head");
    const script = document.createElement("script");
    script.setAttribute(
      "src",
      "https://assets.calendly.com/assets/external/widget.js"
    );
    head.appendChild(script);
  }

  closeModal(e) {
    if (e.target.id === "reqModalBack") {
      this.props.close();
    }
  }

  render() {
    const { opened, close } = this.props;

    return (
      <div
        role="test"
        className={`modal auth-modal req-modal calendly  ${
          opened ? "active" : ""
        }`}
        onClick={this.closeModal}
        id="reqModalBack"
      >
        <div className="modal-content">
          <div className="auth-container">
            <div className="close-auth-modal">
              <button onClick={close}>
                <img src="/assets/img/cancel-white.png" alt="cancel" />
              </button>
              <div style={{ visibility: "hidden" }}>
                {process.env.NODE_ENV === "production" ? (
                  <img src="https://ct.capterra.com/capterra_tracker.gif?vid=2154788&vkey=f04e38b553717b439b01430763d68d12" /> // Capterea.com tracking pixel
                ) : null}
              </div>
            </div>

            <div id="schedule_form">
              <div
                className="calendly-inline-widget"
                data-url="https://calendly.com/ballbutton/30min"
                style={{ height: "100%" }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Calendly;
