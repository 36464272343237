import React, { Component } from "react";
import PropTypes from "prop-types";
import PaymentsNBU from "../../containers/PaymentsNBU";
import axios from "../../config/axios";
import Loading from "../UI/Loading";

class PaymentsModalNBU extends Component {
  state = {
    loading: false,
    default_price: 0,
    default_point: 0,
  };

  getLessonPriceForUser = async (
    lessonId,
    userId,
    appointmentId,
    isRecurrence
  ) => {
    try {
      this.setState((st) => ({
        ...st,
        loading: true,
      }));

      let payload;

      if (isRecurrence) {
        const {
          data: {
            payload: { start_time },
          },
        } = await axios.get("/lesson/appointment/" + appointmentId);

        const { data } = await axios.post("/lesson/get-price-camps", {
          user_id: userId,
          lesson_id: lessonId,
          start_time: start_time,
        });

        payload = data.payload;
      } else {
        const { data } = await axios.post("/lesson/get-price", {
          user_id: userId,
          lesson_id: lessonId,
        });

        payload = data.payload;
      }

      this.setState((st) => ({
        ...st,
        default_price:
          (Number(payload.special_price) || Number(payload.price)) +
          (Number(payload.facility_price) || 0),
        default_point:
          (Number(payload.special_point) || Number(payload.point)) +
          (Number(payload.facility_point) || 0),
      }));
    } finally {
      this.setState((st) => ({
        ...st,
        loading: false,
      }));
    }
  };

  componentDidMount() {
    const { lessonId, user, appointmentId, isRecurrence } = this.props;

    if (lessonId) {
      this.getLessonPriceForUser(
        lessonId,
        user.id,
        appointmentId,
        isRecurrence
      );
    }
  }

  render() {
    const {
      id,
      clouseModal,
      booking,
      user,
      charge_user_id,
      child_id,
      payment_account_id,
      type,
      from,
      cbCharge,
      default_price,
      default_point,
      book_pay_type,
      dateTime,
    } = this.props;

    const {
      loading,
      default_price: state_default_price,
      default_point: state_default_point,
    } = this.state;
    
    return (
      <div className="modal stripe-modal active">
        <div className="modal-content">
          <div className="close-btn">
            <button onClick={clouseModal}>
              <img src="/assets/img/cancel.png" alt="cancel" />
            </button>
          </div>
          <React.Fragment>
            {loading && <Loading />}
            <PaymentsNBU
              waitlist={this.props.waitlist}
              clouseModal={clouseModal}
              isFromUserProfile={this.props.isFromUserProfile}
              id={id}
              dateTime={dateTime}
              type={type}
              in_booking={false}
              from={from}
              child_id={child_id}
              charge_user_id={charge_user_id}
              payment_account_id={payment_account_id}
              booking={booking}
              user={user}
              default_price={state_default_price || default_price}
              default_point={state_default_point || default_point}
              cbCharge={cbCharge}
              book_pay_type={book_pay_type ? book_pay_type : null}
            />
          </React.Fragment>
        </div>
      </div>
    );
  }
}

PaymentsModalNBU.propTypes = {
  clouseModal: PropTypes.func.isRequired,
  //uid: PropTypes.string.isRequired,
  stripeUserId: PropTypes.string,
};
PaymentsModalNBU.defaultProps = {
  stripeUserId: null,
};
export default PaymentsModalNBU;
