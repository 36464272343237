import React, { Component } from "react";
import { TennisGuy } from "../Common/TennisGuy";
import { Link } from "react-router-dom";
class Welcome extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="welcome-page">
        {/*<div className="christmas-box">
          <h3>
            Holiday Season Special: Schedule a demo before December 31st to get 2 Months Free trial!!!
            <br />
            No credit card required!!!
          </h3>
        </div>*/}
        <div className="main-container">
          <div className="padding-container">
            <div className="new-button main_btn">
              <Link to="/club_management_software"> I’m A Business</Link>
            </div>
            <TennisGuy />
            <div className="new-button main_btn">
              <Link to="/athlete"> I'm A Member/Customer</Link>
            </div>
          </div>
        </div>

        <footer id="footer" style={{ marginTop: 0 }}>
          <div className="footer-copyright" style={{ marginTop: 0 }}>
            <div className="main-container">
              <p className="text-center">
                &copy; {new Date().getFullYear()} Ballbutton. All rights
                reserved.<span style={{ float: "none" }}></span>
              </p>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default Welcome;
