import React, { Component } from "react";
import PropTypes from "prop-types";
import { Elements, StripeProvider } from "react-stripe-elements";
// import PropTypes from 'prop-types';
import InjectedCheckoutForm from "../InjectedCheckoutForm";

class StripeCharge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stripe: null,
    };
  }

  componentDidMount() {
    if (window.Stripe) {
      this.setState({
        stripe: window.Stripe(process.env.REACT_APP_STRIPE_PUBLIC),
      });
    } else {
      document.querySelector("#stripe-js").addEventListener("load", () => {
        // Create Stripe instance once Stripe.js loads
        this.setState({
          stripe: window.Stripe(process.env.REACT_APP_STRIPE_PUBLIC),
        });
      });
    }
  }

  render() {
  
    return (
      <StripeProvider stripe={this.state.stripe}>
        <Elements>
          <InjectedCheckoutForm
            from={this.props.from}
            setDefaultFilters={this.props.setDefaultFilters}
            outerState={this.props.outerState ? this.props.outerState : null}
            uid={this.props.uid}
            fromNewCard={this.props.fromNewCard}
            cbCharge={this.props.cbCharge}
            booking={this.props.booking}
            default_price={this.props.default_price}
            show_members={this.props.show_members}
            member_type_id={this.props.member_type_id}
            stripeUserId={this.props.stripeUserId}
            clouseModal={this.props.clouseModal}
          />
        </Elements>
      </StripeProvider>
    );
  }
}

StripeCharge.propTypes = {
  uid: PropTypes.string,
  stripeUserId: PropTypes.string,
  clouseModal: PropTypes.func,
};
StripeCharge.defaultProps = {
  uid: null,
  stripeUserId: null,
  clouseModal: () => { },
};
export default StripeCharge;
