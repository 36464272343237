import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import { extendMoment } from "moment-range";
import Picky from "react-picky";
import DatePicker from "react-datepicker";
import Repeat from "../RepeatLesson";
import FormGroup from "../FormGroup";
import BookedBy from "../BookedBy";
import Notification from "../Notification";
import Loading from "../UI/Loading";
import GreenSwitcher from "../UI/GreenSwitcher";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import { getCourts } from "../../actions/Courts";
import { getCoach } from "../../actions/Coach";
import { AddLesson, getCourtListingavAilability } from "../../actions/Lesson";
import WhoCanBook, {
  WhoCanBookPlain,
  titleKeyValueMapper,
} from "../../components/WhoCanBook";
import DropdownFilters from "../../components/DropdownFilters";
import BookPayType from "../UI/BookPayType";
import { SportSelect } from "../../containers/Business";
import { getActiveFacilityGroup } from "../../utils/facility";
import {
  generateTimeListStartedFromByStep,
  roundDate,
} from "../../utils/helpers";
import { GLOBAL_LOCATION_FACILITY_PAIR } from "../../utils/constants";

class ModalLessons extends Component {
  constructor(props) {
    super(props);
    this.moment = function (...args) {
      return moment.tz(...args, props.timezone);
    };
    const { timeStep, minDuration, business, currentDay, timezone } = props;

    const csh_start_time =
      business && business.csh_start_time
        ? business.csh_start_time.split("__")
        : null;
    const min_date = currentDay.clone().tz(timezone);

    if (csh_start_time) {
      min_date.set("hour", csh_start_time[0]);
      min_date.set("minute", csh_start_time[1]);
    }

    let today = moment().tz(this.props.timezone).format();
    let defaultTimeToSet = roundDate(props.currentDay, timeStep, min_date)
      .tz(props.timezone)
      .set({ hour: 7, minute: 0 });

    const _returnStateParamsDependsOnCurrentRecord = this.returnStateParamsDependsOnCurrentRecord(
      props.currentRecord
    );
    const _start_time = props.startTime
      ? props.startTime
      : moment(today).isAfter(defaultTimeToSet)
        ? roundDate(moment(), timeStep, min_date).tz(this.props.timezone)
        : moment(roundDate(props.currentDay, timeStep, min_date))
          .tz(props.timezone)
          .set({ hour: 7, minute: 0 });

    const _end_time = props.endTime
      ? props.endTime
      : moment(today).isAfter(defaultTimeToSet)
        ? roundDate(moment(), timeStep, min_date)
          .tz(this.props.timezone)
          .add(timeStep, "minutes")
        : roundDate(props.currentDay, timeStep, min_date)
          .tz(props.timezone)
          .set({ hour: 8, minute: 0 });
    this.state = {
      lessonType: localStorage.getItem("DUPLICATE_LESSON_DATA") ? JSON.parse(localStorage.getItem("DUPLICATE_LESSON_DATA")).lessonType : -1,
      allowBooking: "single", //Default type
      hidden: "d-none",
      repeatState: null,
      oneClick:false,

      hasDataInClipboard: !!this.getClipboardData(),

      quantity: props.maxAllowedBookings || "",
      advance: props.advance || 365,
      buffer: props.buffer || 0,
      price: props.price || "",
      guest_price: props.guest_price || "",
      note: props.note || "",
      coach: props.coach || [],
      coachList: [],
      courts: props.selected_courts ? props.selected_courts : [],
      courtsList: props.courts,
      loadingAfterBook: false,
      description: props.description || "",
      start_time: _start_time,
      end_time: _end_time,
      allow_booking_until: _start_time,
      currentDay: props.currentDay,
      occurenc: true,

      error: {},
      availableCourtsForAssign: [],
      includeFilter: false,
      includeFilterCoach: false,
      multipleDatesForSingleDay: [],
      allow_booking_until_open: false,

      // Special price
      include_special_price: false,
      special_price: "",
      special_point: "",
      for_whom: [],

      // Facility fee
      include_facility_fee: false,
      facility_price: "",
      facility_point: "",

      openAddNewName: false,
      who_can_book: [],
      who_can_book_filters: {},
      sportId: -1,
      book_pay_type: "1",
      point_price: "",
      guest_point_price: "",
      cancel_allowed_hour: 24,
      rData: null,
      isRecarenceOn:false,
      ..._returnStateParamsDependsOnCurrentRecord,
    };
    if (props.lessonType === "privatelesson") {
      this.state.quantity = 1;
      this.state.quantityDisable = true;
    }

    this.closeModal = this.closeModal.bind(this);
    this.selectMultipleOption = this.selectMultipleOption.bind(this);
    this.selectCoachOption = this.selectCoachOption.bind(this);
    this.getCourtListingAvailability = this.getCourtListingAvailability.bind(
      this
    );

    this.addNewName = this.addNewName.bind(this);
    this.getUnique = this.getUnique.bind(this);
    this.handleMultipleLessonDatesForToday = this.handleMultipleLessonDatesForToday.bind(
      this
    );
    this.cancelDateListing = this.cancelDateListing.bind(this);
    this.handleDateChangeForToday = this.handleDateChangeForToday.bind(this);

    this.showAlertIfDiasbled = this.showAlertIfDiasbled.bind(this);
  }

  returnStateParamsDependsOnCurrentRecord(currentRecord) {
    return {
      llid: currentRecord && currentRecord.llid ? currentRecord.llid : null,
      status: currentRecord && currentRecord.status ? currentRecord.status : "",
      closed: currentRecord && currentRecord.status === "booked" ? true : false,
      online: false,
      cancel_allowed:
        currentRecord && currentRecord.status ? currentRecord.status : true,
      noteMarkAsBooked:
        currentRecord && currentRecord.noteMarkAsBooked
          ? currentRecord.noteMarkAsBooked
          : "",
      bookedPersons:
        currentRecord &&
          Array.isArray(currentRecord.bookedBy) &&
          currentRecord.bookedBy.length > 0
          ? this.getUnique(currentRecord.bookedBy, "uid")
          : [],
      disabled:
        currentRecord && currentRecord.bookedBy.length > 0 ? true : false,
      currentRecord: currentRecord,
    };
  }

  async componentWillMount() {
    await this.props.getCourts();

    if (this.state.currentRecord) {
      this.setSavedMultipleRecords();
      const filteredCoaches = this.props.coachList.filter((coach) => {
        if (this.props.coach && this.props.coach.includes(coach.mid)) {
          return coach;
        }
      });
      this.setState({
        coach: filteredCoaches,
      });
    }
    if (this.props.coachList.length > 0) {
      this.setState({
        includeFilterCoach: true,
      });
    }

    setTimeout(() => {
      this.show();
    }, this.props.wait || 0);
  }
  show = () => {
    this.setState({ hidden: "" });
  };

  componentDidMount() {
    if (this.props.lessonType === -1) {
      this.selectRef.setCustomValidity("Select some type");
    }

    window.addEventListener("storage", this.handleStorageChange);
  }

  componentWillUnmount() {
    window.removeEventListener("storage", this.handleStorageChange);
  }

  handleStorageChange = () => {
    const hasDataInClipboard = !!this.getClipboardData();
    if (this.state.hasDataInClipboard !== hasDataInClipboard) {
      this.setState((st) => ({ ...st, hasDataInClipboard }));
    }
  };

  getClipboardData = () => {
    try {
      let copy_data = localStorage.getItem("DUPLICATE_LESSON_DATA");
      copy_data = JSON.parse(copy_data);

      copy_data = copy_data !== null ? copy_data : undefined;
      return copy_data;
    } catch (e) {
      console.log("log: failed to parse DUPLICATE_LESSON_DATA: ", e);
    }

    return undefined;
  };

  closeModal(e) {
    if (e.target.id === "modalBack") {
      this.props.close();
    }
  }

  setSavedMultipleRecords() {
    if (this.state.currentRecord.assignedCourts) {
      const savedCourts = this.state.currentRecord.assignedCourts.map(
        (court) => court.courts
      );
      const filteredCourts = this.props.courts.filter((court) => {
        if (savedCourts.includes(court.courts)) {
          return court;
        }
      });
      this.setState({
        courts: filteredCourts,
      });
    }
  }

  /**********************************Part for multiple dates for Current day */

  makeid(length = 40) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  async showAlertIfDiasbled(e) {
    if (e.currentTarget.classList.contains("disabled-div")) {
      await window.modal.alert(
        "Info",
        "Chose either “Do you want to repeat?” or “List Different Times” function"
      );
      return false;
    }
  }

  handleDateChangeForToday(key, accesKey) {
    return async (time) => {
      try {
        let _multipleDatesForSingleDay = this.state.multipleDatesForSingleDay;
        let _objectForPush = {};
        let index = _.findIndex(_multipleDatesForSingleDay, function (o) {
          return o.hasOwnProperty(key);
        });

        if (index === -1) {
          throw "Selected range missing from List";
        }

        if (key.includes("start_time")) {
          _objectForPush = this.createLessonDateObject(
            accesKey,
            time,
            moment(time).add(this.props.minDuration, "minutes")
          );
        }
        if (key.includes("end_time")) {
          let _getObjectByIndex = _multipleDatesForSingleDay[index];
          const start_time = moment(
            _getObjectByIndex["start_time_" + accesKey]
          ).clone();
          if (
            !(
              start_time.isSame(time, "day") ||
              start_time.add(1, "day").startOf("day").isSame(time)
            )
          ) {
            throw "Selected range should be for same day";
          }
          _objectForPush = this.createLessonDateObject(
            accesKey,
            _getObjectByIndex["start_time_" + accesKey],
            time
          );
        }

        let _range = this.createRangeForCurrentChange(_objectForPush);

        this.validateDatesRangeOverlapp(_range, _objectForPush["accesKey"]);
        _multipleDatesForSingleDay.splice(index, 1, _objectForPush);

        this.setState({
          multipleDatesForSingleDay: _multipleDatesForSingleDay,
        });
      } catch (error) {
        await window.modal.alert("Error", error.toString());
        return false;
      }
    };
  }

  createRangeForCurrentChange(_getObjectByIndex) {
    const _moment = extendMoment(moment);
    let _accesKey = _getObjectByIndex["accesKey"];
    let _start_time = _getObjectByIndex["start_time_" + _accesKey];
    let _end_time = moment(_getObjectByIndex["end_time_" + _accesKey]).subtract(
      1,
      "minutes"
    );
    return _moment().range(_start_time, _end_time);
  }

  validateDatesRangeOverlapp(_range, accesKey) {
    try {
      const _moment = extendMoment(moment);
      let _multipleDatesForSingleDay = this.state.multipleDatesForSingleDay;
      _multipleDatesForSingleDay = _multipleDatesForSingleDay.filter((v) => {
        let _accesKey = v["accesKey"];
        let _start_time = v["start_time_" + _accesKey];
        let _end_time = moment(v["end_time_" + _accesKey]).subtract(
          1,
          "minutes"
        );
        if (_accesKey === accesKey) {
          return false;
        }
        let range = _moment().range(_start_time, _end_time);
        return _range.overlaps(range); // true range.contains(time);
      });

      if (_multipleDatesForSingleDay.length > 0) {
        throw "Invalid selected range";
      }
    } catch (error) {
      throw error;
    }
  }

  createLessonDateObject(_uuidForRecord, start_time, end_time) {
    let _objectForPush = {};
    _objectForPush["start_time_" + _uuidForRecord] = start_time; // this.state.start_time;
    _objectForPush["end_time_" + _uuidForRecord] = end_time; //this.state.end_time;
    _objectForPush["duration"] = 1; //moment.duration(moment(end_time).set({ second: 0 }).diff(moment(start_time).set({ second: 0 }))).asHours(),
    _objectForPush["accesKey"] = _uuidForRecord;
    return _objectForPush;
  }

  async handleMultipleLessonDatesForToday() {
    let _multipleDatesForSingleDay = this.state.multipleDatesForSingleDay;

    try {
      if (_multipleDatesForSingleDay.length === 10) {
        throw "Allowed listing count is 10";
      }
    } catch (error) {
      await window.modal.alert("Error", error.toString());
      return false;
    }

    if (_multipleDatesForSingleDay.length === 0) {
      for (let i = 0; i <= 1; i++) {
        let _uuidForRecord = this.makeid();
        let _objectForPush = this.createLessonDateObject(
          _uuidForRecord,
          this.state.start_time,
          this.state.end_time
        );
        _multipleDatesForSingleDay.push(_objectForPush);
      }
    } else {
      let _uuidForRecord = this.makeid();
      let _objectForPush = this.createLessonDateObject(
        _uuidForRecord,
        this.state.start_time,
        this.state.end_time
      );
      _multipleDatesForSingleDay.push(_objectForPush);
    }

    this.setState({
      multipleDatesForSingleDay: _multipleDatesForSingleDay,
    });
  }

  cancelDateListing(i) {
    let _multipleDatesForSingleDay = this.state.multipleDatesForSingleDay;
    _multipleDatesForSingleDay.splice(i, 1);
    this.setState({
      multipleDatesForSingleDay: _multipleDatesForSingleDay,
    });
  }

  /*********************************** */
  handleChangeStartTime() {
    return (time) => {
      this.setState({
        start_time: time,
        allow_booking_until: time,
        end_time: moment(time).add(this.props.minDuration, "minutes"),
      });
    };
  }

  hendlerChangeCoach() {
    return (e) => {
      const value = e.target.value;
      this.setState({ coach: value });
    };
  }

  handleChangeEndTime() {
    return (time) => {
      this.setState({ end_time: time });
    };
  }
  handleChangeAllowBookingUntil() {
    return (time) => {
      this.setState({ allow_booking_until: time });
    };
  }
  handleBookingOpen = (flag) => {
    this.setState({ allow_booking_until_open: flag });
  };

  handleFacilityFee = (flag) => {
    this.setState({ include_facility_fee: flag });
  };

  handleAllowCancel = (flag) => {
    this.setState({ cancel_allowed: flag });
  };
  handleAllowCancelHour = () => {
    return (e) => {
      let { value } = e.target;
      this.setState({ cancel_allowed_hour: value });
    };
  };

  handleChangeSelect() {
    return (e) => {
      const { name, value } = e.target;
      if (name === "lessonType" && value === "privatelesson") {
        this.setState({ [name]: value, quantity: 1, quantityDisable: true });
      } else {
        this.setState({ [name]: value, quantityDisable: false });
      }
      e.target.setCustomValidity("");
    };
  }

  async getCourtListingAvailability() {
    const { repeatState } = this.state;

    if (this.checkDate(this.state.start_time, this.state.end_time)) {
      await this.props.getCourtListingavAilability(this.state, repeatState);
    } else {
      await window.modal.alert("Error", "Please provide valid date range", {
        style: { width: "400px" },
      });
      return false;
    }

    return true;
  }

  checkDate(startDate, endDate) {
    const currentDateTime = moment(this.props.currentDay)
      .tz(this.props.timezone)
      .format();

    const endDateFormatted = endDate.format();
    let today = moment().tz(this.props.timezone).format();
    const startsDateFormatted = startDate.format();
    const currentDayDiff = moment(today).diff(currentDateTime, "days");
    if (currentDayDiff > 0) {
      return false;
    }

    if (moment(endDateFormatted).isSameOrAfter(startsDateFormatted)) {
      return moment(startsDateFormatted).isAfter(currentDateTime);
    }
    return false;
  }

  getAssignedCourts() {
    return this.state.currentRecord && this.state.currentRecord.assignedCourts
      ? this.state.currentRecord.assignedCourts.map((each) => each.courts)
      : [];
  }

  handleChange() {
    return (e) => {
      let { name, value } = e.target;

      if (name === "advance") {
        if((value<0)|| (value>365)){
         return
        }
        // value = Math.max(1, value);
        // value = Math.min(365, value);
      }
      
      this.setState({ [name]: value });
    };
  }

  addLesson() {
    const { isfromCourt } = this.props;
    const { repeatState } = this.state; 
    if (this.state.start_time > this.state.allow_booking_until) {

      this.setState((state) => {
        return { allow_booking_until: state.start_time };
      });
    }
    return async (e) => {
      e.preventDefault();
     
      try {
        let _multipleDatesForSingleDay = this.state.multipleDatesForSingleDay;
        _multipleDatesForSingleDay.forEach((v) => {
          let _range = this.createRangeForCurrentChange(v);
          this.validateDatesRangeOverlapp(_range, v["accesKey"]);
        });
      } catch (error) {
        await window.modal.alert("Error", "Invalid selected range in list");
        return false;
      }

      const repiat = repeatState;
      const booked_by = this.booked_by.state;

      const { who_can_book, who_can_book_filters } = this.state;
      let body = who_can_book;
      body = body.map((v) => {
        return { ...v, filters: who_can_book_filters };
      });

      const is_for_public =
        body.length > 0 ? body["0"]["for"] === "public" : false;
      if (is_for_public && !this.props.isConnected) {
        return window.modal
          .alert(`If you make bookings available to public, you will need to activate the 
                                         payment integration (Stripe) to enable anyone to Book & Pay.
                                         We will require 24 hours advance cancellation, will charge the customers 24 hours  
                                         before the event and will eliminate the risk of no shows for your business!`);
      }

      const closed =
        this.state.closed &&
          +this.state.quantity === booked_by.booked_persons_during_add.length
          ? false
          : this.state.closed;

      const data = {
        ...this.state,
        closed: closed,
        microSettings: body,
        booked_by: booked_by.booked_persons_during_add,
      };

      /* 
      Special price code block
      */

      if (this.state.include_special_price) {
        const { for_whom } = this.state;
        let specialMicroSettings = for_whom;
        specialMicroSettings = specialMicroSettings.map((v) => {
          return { ...v, filters: who_can_book_filters };
        });

        data["specialMicroSettings"] = specialMicroSettings;
      }
      this.setState((st) => ({ ...st, oneClick: true }));
      const bool = await this.props.AddLesson(
        data,
        repiat,
        this.props.lessonType
      );

      if (bool) {
        if (this.props.fromCourtListing) {
          return this.props.history.push("/private/dashboard");
        }
        if (isfromCourt && this.props.hasOwnProperty("getListings")) {
          await this.props.getListings();
        }
        this.props.close();
      }else{
        this.setState((st) => ({ ...st, oneClick: false }));
      }
    };
  }

  getUnique(arr, comp) {
    const unique = arr
      .map((e) => e[comp])

      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter((e) => arr[e])
      .map((e) => arr[e]);

    return unique;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.lessonType) {
      this.setState({
        lessonType: nextProps.lessonType,
      });
    }

    if (nextProps.coachList) {
      this.setState({
        coachList: nextProps.coachList.map((each) => {
          return {
            id: each.id,
            displayName: `${each.first_name} ${each.last_name}`,
          };
        }),
      });
    }

    if (nextProps.courts.length > 0 && this.state.courtsList.length === 0) {
      this.setState({
        courtsList: nextProps.courts,
        courts: nextProps.courts.filter((court) => {
          if (this.state.courts.includes(court.id)) {
            return court;
          }
          return false;
        }),
      });
    }
    const nextAvailableCourts = nextProps.availableCourts;

    //Check if Courts are equal
    if (nextAvailableCourts.length > 0) {
      const changedCourtIds = this.state.courts.filter((eachCourt) => {
        const found = _.find(nextAvailableCourts, ["id", eachCourt.id]);
        if (found) {
          return eachCourt;
        }
      });
      this.setState({
        courtsList: nextAvailableCourts,
        courts: changedCourtIds,
      });
    }

    const { timeStep, minDuration } = nextProps;

    if (nextProps.fromCourt) {
      const { business, currentDay, timezone } = nextProps;
      const csh_start_time =
        business && business.csh_start_time
          ? business.csh_start_time.split("__")
          : null;
      const min_date = currentDay.clone().tz(timezone);

      if (csh_start_time) {
        min_date.set("hour", csh_start_time[0]);
        min_date.set("minute", csh_start_time[1]);
      }

      const fromCourt = nextProps.fromCourt;
      const ruleButton = nextProps.ruleButton;
      const start_time =
        fromCourt && !ruleButton
          ? roundDate(this.moment(fromCourt.start), timeStep, min_date)
          : roundDate(this.props.currentDay, timeStep, min_date)
            .tz(this.props.timezone)
            .set({ hour: 7, minute: 0 });

      const end_time =
        fromCourt && !ruleButton
          ? roundDate(this.moment(fromCourt.end), timeStep, min_date)
          /* 
          old verison by Raf or levon
          ? roundDate(this.moment(fromCourt.end), timeStep, min_date).add(
             minDuration,
             "minutes"
           )
           */
          : moment(roundDate(this.props.currentDay, timeStep, min_date))
            .tz(this.props.timezone)
            .set({ hour: 8, minute: 0 });

      this.setState({
        start_time: start_time,
        allow_booking_until: start_time,
        end_time: end_time,
        courts: !ruleButton
          ? nextProps.fromCourt.courts.map((v) => {
            return { id: v.id, name: v.title };
          })
          : [],
      });
    }
  }

  selectMultipleOption(value) {
    this.setState({ courts: value });
  }

  selectCoachOption(value) {
    this.setState({ coach: value });
  }

  handleCheckboxChange = async (event) => {
    const {
      target: { value },
    } = event;
    this.setState((st) => ({
      ...st,
      allowBooking: value,
    }));
    if (value === "recurrence") {
      await window.modal.alert(
        "Info",
        "Select Total Program Price and Facility Fee (if applicable)."
      );
    } else {
      await window.modal.alert(
        "Info",
        "Select Single Occurrence Price and  Facility Fee (if applicable)."
      );
    }
  };

  handleCheckboxClosed = (event) => {
    const target = event.target;
    this.setState({ [target.name]: target.checked });
  };

  addNewName() {
    this.setState((prevState) => {
      return { openAddNewName: !prevState.openAddNewName };
    });
  }

  onChangeWhoCanBook = (changed) => {
    this.setState((st) => ({
      ...st,
      for_whom: [],
      who_can_book: changed,
    }));
  };

  handleChangeForWhom = (changed) => {
    this.setState((st) => ({
      ...st,
      for_whom: changed,
    }));
  };

  onChangeWhoCanBookFilters = (changed) => {
    this.setState({
      who_can_book_filters: changed,
    });
  };

  onChangeRepeat = (repeatState) => {
    this.setState({
      repeatState: repeatState,
    });
  };
  onChangeBookPayType = (changed) => {
    this.setState({
      book_pay_type: changed,
    });
  };

  isForPublic = () => {
    const { who_can_book } = this.state;
    return (
      who_can_book &&
      Array.isArray(who_can_book) &&
      who_can_book.length === 1 &&
      who_can_book["0"]["for"] === "public"
    );
  };

  renderForm() {
    const { close, currentDay, timezone, whoCanBookData } = this.props;

    const {
      price,
      guest_price,
      note,
      error,
      quantity,
      advance,
      buffer,
      start_time,
      allow_booking_until,
      end_time,
      description,
      llid,
      loadingAfterBook,
      hidden,
      multipleDatesForSingleDay,
      coachList,
      who_can_book,
      who_can_book_filters,
      sportId,
      allowBooking,
      allow_booking_until_open,
      // Facility fee
      include_facility_fee,
      facility_point,
      facility_price,

      // Special price
      include_special_price,
      special_price,
      special_point,
      for_whom,

      book_pay_type,
      point_price,
      guest_point_price,
      cancel_allowed,
      cancel_allowed_hour,
      oneClick,
    } = this.state;

    let for_whom_data = {};

    for (const item of who_can_book) {
      const key = titleKeyValueMapper[item.for];

      if (key === "public") {
        for_whom_data = {
          ...whoCanBookData,
        };
      } else {
        const list = whoCanBookData[key];
        if (list) {
          for_whom_data[key] = list.filter((itm) => {
            return !!item.values.find((i) => i == itm.id);
          });
        }
      }
    }

    const disableAddMultiple = false;

    const allowed_payment_types = ["1", "-1"];
    const allowed_payment_point_types = ["-1", "2"];

    const sportCategoryId = getActiveFacilityGroup()?.sport_category_id;

    const { business } = this.props;
    const csh_start_time =
      business && business.csh_start_time
        ? business.csh_start_time.split("__")
        : null;
    const min_date = currentDay.clone().tz(timezone).startOf("day");

    const start_min_time = start_time
      ? start_time.clone().tz(timezone).startOf("day")
      : min_date;
    const end_min_time = end_time
      ? end_time.clone().tz(timezone).startOf("day")
      : min_date;

    if (csh_start_time) {
      min_date.set("hour", csh_start_time[0]);
      min_date.set("minute", csh_start_time[1]);

      start_min_time.set("hour", csh_start_time[0]);
      start_min_time.set("minute", csh_start_time[1]);
      end_min_time.set("hour", csh_start_time[0]);
      end_min_time.set("minute", csh_start_time[1]);
    }

    const startMin = {
      time: start_min_time,
      date: min_date,
    };
    const startMax = {
      time: startMin.time.clone().add(1, "day").startOf("day"),
    };

    const endMinTime = moment(start_time)
      .tz(timezone)
      .add(this.props.timeStep, "minutes");

    const endMin = {
      time: endMinTime,
      date: endMinTime,
    };

    const endMax = {
      time: end_min_time.clone().add(1, "day").startOf("day"),
    };

    const injectStartTimes = generateTimeListStartedFromByStep(
      start_min_time,
      this.props.timeStep
    );
    const injectEndTimes = generateTimeListStartedFromByStep(
      end_min_time,
      this.props.timeStep
    );

    const timeIntervals = 24 * 60;
    const [_,pairId] = window.localStorage.getItem(GLOBAL_LOCATION_FACILITY_PAIR).split(":");
    const isShowBuffer = (this.props.business.id==1 && pairId == 38) ||
    (this.props.business.id==33 && pairId == 43) ||
    (this.props.business.id==2 && pairId == 10) ||
    (this.props.business.id==90 && pairId == 103) ||
    (this.props.business.id==107 && pairId == 130) 
     
    const isWhoCanBookPublic=(who_can_book.length>0?who_can_book[0].for=="public":false)
   
    return (
      <React.Fragment>
        {loadingAfterBook ||
          this.props.loadingGetCourts ||
          this.props.loadingAddLesson ? (
          <Loading />
        ) : (
          ""
        )}
        {localStorage.getItem("DUPLICATE_LESSON_DATA") ? (
          <center style={{ color: "red", fontSize: "11px", marginTop: "-10px", marginBottom: "10px" }}><span>This is the duplicate</span></center>
        ) : null}
        <div className={`${hidden} lesson-create-wrap lesson-create-wrap_new form-enlarge-container`}>
          <style>
            {`
              .lesson-create-wrapa .react-datepicker__time-list-item--disabled{
                display: none;
              }
             `}
          </style>
          {/* <div className="modal-title">
            <h3>Lesson </h3>
          </div> */}
          <form autoComplete="nope" className="removeCoursorsForSafary" onSubmit={this.addLesson()}>
            <div className="each-col pad-l-no  first-col  border-left-2-gray">
            <div className="borderedBlock">
            <div className="rec-form">
                <div className="form-group">
                  <label>
                    <span className="important-span">*</span>&nbsp; Sport
                  </label>
                  <div className="picky-container">
                    <SportSelect
                      value={{
                        id: sportId,
                      }}
                      sportCategoryId={sportCategoryId}
                      onChange={(opt) =>
                        this.setState((st) => ({ ...st, sportId: opt.id }))
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="rec-form">
                <div className="form-group">
                  <label>
                    <span className="important-span">*</span>&nbsp; Service Type
                  </label>
                  <select
                    name="lessonType"
                    required
                    ref={(selectRef) => {
                      this.selectRef = selectRef;
                    }}
                    value={this.state.lessonType}
                    onChange={this.handleChangeSelect()}
                  >
                    <option value="-1" disabled>
                      Select type
                    </option>
                    {this.props.lessonTypes &&
                      this.props.lessonTypes.sort(function(a, b) {
                        return (a.name.toUpperCase() < b.name.toUpperCase()) ? -1 : (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : 0;
                    }).map((type) => {
                        return (
                          <option key={type.id} value={type.id}>
                            {type.display_name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
              </div>
              <div className="borderedBlock">
              <div className="rec-form">
                <div className="form-group">
                  <label>
                    <span className="important-span">*</span>&nbsp; Who Can Book
                  </label>
                  <div className="picky-container">
                    <WhoCanBook
                      onChangeWhoCanBook={this.onChangeWhoCanBook}
                      value={who_can_book}
                    />
                  </div>
                </div>
              </div>
              <div className="rec-form">
                <div className="form-group">
                  <label>Additional who can book filter</label>
                  <div className="picky-container">
                    <DropdownFilters
                      onChangeWhoCanBook={this.onChangeWhoCanBookFilters}
                      value={who_can_book_filters}
                    />
                  </div>
                </div>
              </div>
              </div>
              <div className="borderedBlock">
              {multipleDatesForSingleDay.length <= 1 && (
                <div className="scroll-container-dates">
                  <table className="dates-table">
                    <tbody>
                      <tr>
                        <td>
                          <span><span style={{color:"red"}}>*</span> Start Time</span>
                          <div className="lesson-start-time lesson-form-group">
                            <label htmlFor="start_time">
                              <DatePicker
                                selected={start_time}
                                onChange={this.handleChangeStartTime()}
                                minDate={startMin.date}
                                minTime={startMin.time}
                                maxTime={startMax.time}
                                maxDate={startMax.date}
                                showTimeSelect
                                popperPlacement="bottom-start"
                                timeIntervals={timeIntervals}
                                dateFormat="MMM D, h:mma"
                                timeCaption="Time"
                                injectTimes={injectStartTimes}
                                readOnly
                              />
                            </label>
                          </div>
                        </td>

                        <td>
                          <span><span style={{color:"red"}}>*</span> End Time</span>
                          <div className="lesson-start-time lesson-end-time  lesson-form-group">
                            <DatePicker
                              selected={end_time}
                              onChange={this.handleChangeEndTime()}
                              preventOpenOnFocus={false}
                              minDate={endMin.date}
                              minTime={endMin.time}
                              maxTime={endMax.time}
                              maxDate={endMax.date}
                              showTimeSelect
                              popperPlacement="bottom-start"
                              timeIntervals={timeIntervals}
                              dateFormat="MMM D, h:mma"
                              timeCaption="Time"
                              injectTimes={injectEndTimes}
                              readOnly
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
              <div className="scroll-container-dates">
                <table className="dates-table">
                  <tbody>
                    <tr>
                      <td colSpan="2">
                        <div
                          onClick={this.showAlertIfDiasbled}
                          className={
                            disableAddMultiple
                              ? "addMultipleButton style-for-plus-icon disabled-div"
                              : "addMultipleButton style-for-plus-icon"
                          }
                        >
                          List Different Times{" "}
                          <div className="plus-icon" id="plus">
                            <button
                              type="button"
                              onClick={this.handleMultipleLessonDatesForToday}
                            >
                              <img src="/assets/img/plus.png" alt="plus" />
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {multipleDatesForSingleDay.length > 1 && (
                <div className="scroll-container-dates">
                  <table className="dates-table table table-striped table-bordered dates-table-multiple">
                    <thead>
                      <tr>
                        <td>
                          <span><span style={{color:"red"}}>*</span> Start Time</span>
                        </td>
                        <td>
                          <span> <span style={{color:"red"}}>*</span> End Time</span>
                        </td>
                        <td>&nbsp;</td>
                      </tr>
                    </thead>

                    <tbody>
                      {multipleDatesForSingleDay.map((v, i) => {
                        let start_timeKeyName = "start_time_" + v["accesKey"];
                        let end_timeKeyName = "end_time_" + v["accesKey"];
                        let accesKey = v["accesKey"];
                        let start_timeKeyValue = v[start_timeKeyName];
                        let end_timeKeyValue = v[end_timeKeyName];

                        const start_min_time = start_timeKeyValue
                          .clone()
                          .tz(timezone)
                          .startOf("day");
                        const end_min_time = end_timeKeyValue
                          .clone()
                          .tz(timezone)
                          .startOf("day");

                        if (csh_start_time) {
                          start_min_time.set("hour", csh_start_time[0]);
                          start_min_time.set("minute", csh_start_time[1]);
                          end_min_time.set("hour", csh_start_time[0]);
                          end_min_time.set("minute", csh_start_time[1]);
                        }

                        const startMin = {
                          time: start_min_time,
                          date: min_date,
                        };

                        const startMax = {
                          time: startMin.time
                            .clone()
                            .add(1, "day")
                            .startOf("day"),
                        };

                        const endMinTime = moment(start_timeKeyValue)
                          .tz(timezone)
                          .add(this.props.minDuration, "minutes");

                        const endMin = {
                          time: endMinTime,
                          date: endMinTime,
                        };

                        const endMax = {
                          time: end_min_time
                            .clone()
                            .add(1, "day")
                            .startOf("day"),
                        };

                        const injectStartTimes = generateTimeListStartedFromByStep(
                          start_min_time,
                          this.props.timeStep
                        );
                        const injectEndTimes = generateTimeListStartedFromByStep(
                          end_min_time,
                          this.props.timeStep
                        );

                        const timeIntervals = 24 * 60;

                        return (
                          <tr key={i + 1}>
                            <td>
                              <div className="lesson-start-time lesson-form-group">
                                <DatePicker
                                  selected={start_timeKeyValue}
                                  onChange={this.handleDateChangeForToday(
                                    start_timeKeyName,
                                    accesKey
                                  )}
                                  minDate={startMin.date}
                                  minTime={startMin.time}
                                  maxTime={startMax.time}
                                  maxDate={startMax.date}
                                  showTimeSelect
                                  popperPlacement="bottom-start"
                                  timeIntervals={timeIntervals}
                                  dateFormat="MMM D, h:mm a"
                                  timeCaption="Time"
                                  injectTimes={injectStartTimes}
                                />
                              </div>
                            </td>
                            <td>
                              <div className="lesson-start-time  lesson-form-group">
                                <DatePicker
                                  selected={end_timeKeyValue}
                                  onChange={this.handleDateChangeForToday(
                                    end_timeKeyName,
                                    accesKey
                                  )}
                                  preventOpenOnFocus={false}
                                  minDate={endMin.date}
                                  minTime={endMin.time}
                                  maxTime={endMax.time}
                                  maxDate={endMax.date}
                                  showTimeSelect
                                  timeIntervals={timeIntervals}
                                  dateFormat="MMM D, h:mm a"
                                  timeCaption="Time"
                                  injectTimes={injectEndTimes}
                                />
                              </div>
                            </td>
                            <td>
                              <img
                                title="Cancel"
                                onClick={() => {
                                  this.cancelDateListing(i);
                                }}
                                className="chargeButton w-29"
                                src="/assets/img/error-cancel.svg"
                                alt="Cancel"
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
               </div>
              <div className=" left-right-container">
                <div className="left-side">
                <div className="borderedBlock">
                  <div className="_rec-form">
                    <BookPayType
                      book_pay_type={book_pay_type}
                      onChangeBookPayType={this.onChangeBookPayType}
                    />
                  </div>
                  {allowed_payment_types.includes(book_pay_type) && (
                    <div className="rec-form">
                      <div className="lessons-price form-group">
                        <label>
                          {" "}
                          <span className="important-span">*</span>
                          &nbsp;Customer (member) price (
                          {allowBooking === "single"
                            ? "Single Occurence"
                            : "Total Program"}
                          )
                        </label>
                        <input
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          name="price"
                          value={price}
                          required
                          onChange={this.handleChange()}
                        />
                      </div>
                    </div>
                  )}
                  {this.isForPublic() &&
                    allowed_payment_types.includes(book_pay_type) && (
                      <div className="rec-form">
                        <div className="lessons-price form-group">
                          <label>
                            <span className="important-span">*</span>&nbsp;
                            Visitor (public) price
                          </label>
                          <input
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            name="guest_price"
                            value={guest_price}
                            onChange={this.handleChange()}
                          />
                        </div>
                      </div>
                    )}

                  {allowed_payment_point_types.includes(book_pay_type) && (
                    <div className="rec-form">
                      <div className="lessons-price form-group">
                        <label>
                          {" "}
                          <span className="important-span">*</span>
                          &nbsp;Customer (member) point (
                          {allowBooking === "single"
                            ? "Single Occurence"
                            : "Total Program"}
                          )
                        </label>
                        <input
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          name="point_price"
                          value={point_price}
                          required
                          onChange={this.handleChange()}
                        />
                      </div>
                    </div>
                  )}
                  {this.isForPublic() &&
                    allowed_payment_point_types.includes(book_pay_type) && (
                      <div className="rec-form">
                        <div className="lessons-price form-group">
                          <label>
                            <span className="important-span">*</span>&nbsp;
                            Visitor (public) point
                          </label>
                          <input
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            name="guest_point_price"
                            value={guest_point_price}
                            onChange={this.handleChange()}
                          />
                        </div>
                      </div>
                    )}

                  {/* Add special price block */}

                  <div className="switcher-div facility-fee-div">
                    <span className="facility-fee-title">
                      Add special price:
                    </span>
                    <label className="switcherLabel">
                      <GreenSwitcher
                        value={include_special_price}
                        checked={include_special_price}
                        onChange={() =>
                          this.handleChange()({
                            target: {
                              name: "include_special_price",
                              value: !include_special_price,
                            },
                          })
                        }
                      />
                    </label>
                    {include_special_price ? <span>On</span> : <span>Off</span>}

                  </div>
                  {include_special_price && (
                    <div className="rec-form">
                      <div className="form-group">
                        <label>
                          <span className="important-span">*</span>&nbsp;For who
                        </label>
                        <div className="picky-container">
                          <WhoCanBookPlain
                            excludePublicOption
                            whoCanBook={for_whom_data}
                            getWhoCanBook={() =>
                              this.setState((st) => ({
                                ...st,
                                for_whom: [],
                              }))
                            }
                            onChangeWhoCanBook={this.handleChangeForWhom}
                            value={for_whom}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {allowed_payment_types.includes(book_pay_type) &&
                    include_special_price && (
                      <div className="rec-form">
                        <div className="lessons-price form-group">
                          <label>
                            {" "}
                            <span className="important-span">*</span>
                            &nbsp;Special price
                          </label>
                          <input
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            name="special_price"
                            value={special_price}
                            required
                            onChange={this.handleChange()}
                          />
                        </div>
                      </div>
                    )}
                  {allowed_payment_point_types.includes(book_pay_type) &&
                    include_special_price && (
                      <div className="rec-form">
                        <div className="lessons-price form-group">
                          <label>
                            {" "}
                            <span className="important-span">*</span>
                            &nbsp;Special point
                          </label>
                          <input
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            name="special_point"
                            value={special_point}
                            required
                            onChange={this.handleChange()}
                          />
                        </div>
                      </div>
                    )}

                  {/* END: Add special price block */}

                  {/* Add facility fee block */}

                  <div className="switcher-div facility-fee-div">
                    <span className="facility-fee-title">
                      Add facility fee:
                    </span>
                    <label className="switcherLabel">
                      <GreenSwitcher
                        value={include_facility_fee}
                        checked={include_facility_fee}
                        onChange={() =>
                          this.handleFacilityFee(!include_facility_fee)
                        }
                      />
                    </label>
                    {include_facility_fee ? <span>On</span> : <span>Off</span>}
                    <Notification>
                      Facility/court fee will be charged in addition to
                      event/lesson price for every day of booking. And if the
                      event/lesson is assigned to multiple courts each day the
                      additional fee will be charged for one (not multiple)
                      court each day from each customer.
                    </Notification>
                  </div>
                  {allowed_payment_types.includes(book_pay_type) &&
                    include_facility_fee && (
                      <div className="rec-form">
                        <div className="lessons-price form-group">
                          <label>
                            {" "}
                            <span className="important-span">*</span>
                            &nbsp;Customer (member) facility price (
                            {allowBooking === "single"
                              ? "Single Occurence"
                              : "Total Program"}
                            )
                          </label>
                          <input
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            name="facility_price"
                            value={facility_price}
                            required
                            onChange={this.handleChange()}
                          />
                        </div>
                      </div>
                    )}
                  {allowed_payment_point_types.includes(book_pay_type) &&
                    include_facility_fee && (
                      <div className="rec-form">
                        <div className="lessons-price form-group">
                          <label>
                            {" "}
                            <span className="important-span">*</span>
                            &nbsp;Customer (member) facility point (
                            {allowBooking === "single"
                              ? "Single Occurence"
                              : "Total Program"}
                            )
                          </label>
                          <input
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            name="facility_point"
                            value={facility_point}
                            required
                            onChange={this.handleChange()}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  {/* END: Add facility fee block */}
                  <div className="borderedBlock">
                  <div className="rec-form">
                    <div className="lessons-price form-group">
                      <label>
                        {" "}
                        <span className="important-span">*</span>&nbsp; How many
                        people can book
                      </label>
                      <input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        id="quantity"
                        min="1"
                        max="150"
                        disabled={this.state.quantityDisable}
                        name="quantity"
                        value={quantity}
                        required
                        onChange={this.handleChange()}
                      />
                    </div>
                  </div>
                  <div className="rec-form">
              <div className="form-group" style={{display:"block"}}>
                  <label  style={{ justifyContent: "start", }} >
                    <span
                      className="important-span"
                      style={{ color: "tomato" }}
                    >
                      *
                    </span>
                    &nbsp;Earliest Book in Advance
                  </label>
                 
                  <input
                    type="number"
                    id="advance"
                    min="1"
                    max="365"
                    onWheel={(e) => e.target.blur()}
                    name="advance"
                    value={advance}
                    required
                    onChange={this.handleChange()}
                  />
                </div>
                </div>
               
                <div className="rec-form">
                {isShowBuffer &&(
                  <div className="form-group">
                  <label
                    style={{
                      justifyContent: "start",
                    }}
                  >
                    <span
                      className="important-span"
                      style={{ color: "tomato" }}
                    >
                      *
                    </span>
                    &nbsp; Buffer (not bookable days before) 
                  </label>
                  <input
                    type="number"
                    id="buffer"
                    min="0"
                    max="365"
                    onWheel={(e) => e.target.blur()}
                    name="buffer"
                    value={buffer}
                    required
                    onChange={this.handleChange()}
                  />
                </div>
                )}
              </div>
              </div>
                </div>
                
              </div>
            </div>
            <div className="each-col second-col  p-rel border-left-2-gray">

              <Repeat
                currentDay={currentDay}
                llid={llid ? llid : null}
                onChangeRepeat={this.onChangeRepeat}
                recurringInfo={this.props.occurrenceInfo}
                rData={this.state.rData}
                classNameForRepeat={
                  multipleDatesForSingleDay.length <= 1
                    ? "ok-style"
                    : "disabled-div"
                }
                changeIsRecarenceOn={(bul)=>{
                  this.setState((prevState) => ({
                    ...prevState,
                    isRecarenceOn:bul,
                  }));
                }}
                changeOccurenc={() => {
                  this.setState((prevState) => ({
                    ...prevState,
                    occurenc: !prevState.occurenc,
                  }));
                }}
              />
              
              <div className="camps-section">
                {this.state.isRecarenceOn&&(
                   <div className="alert-box alert-info">
                   <span style={{display:"none"}}>
                     If #2 is allowed, then the listing shows up in the Programs
                     & Camps tab for your customers.
                   </span>
                   <div className="camps-section-option orange">
                     <span className="counter-camp">1)</span>
                     <FormGroup
                       type="radio"
                       name="allowBooking" 
                       id="book-single"
                       checked={allowBooking === "single"}
                       value={"single"}
                       onChange={this.handleCheckboxChange}
                       label="Allow customers to book only by single occurence"
                     ></FormGroup>
                   </div>
                   <div className="camps-section-option purple">
                     <span className="counter-camp">2)</span>
                     <FormGroup
                       type="radio"
                       name="allowBooking"
                       id="book-recurrence"
                       checked={allowBooking === "recurrence"}
                       value={"recurrence"}
                       onChange={this.handleCheckboxChange}
                       label="Require customers to book only the whole event chain"
                     ></FormGroup>
                   </div>
                 </div>
                )}
               
                {allowBooking === "recurrence"&& this.state.isRecarenceOn && (
                  <div className="camps-section-option allow-booking-until">
                    Allow to book pro rata the remaining chain of events/days
                    after the start of the first day of the camp/program but no
                    later than.
                    <div className="switch-booking-date">
                      <div className="switcher-div">
                        <label className="switcherLabel">
                          <GreenSwitcher
                            value={allow_booking_until_open}
                            checked={allow_booking_until_open}
                            onChange={() =>
                              this.handleBookingOpen(!allow_booking_until_open)
                            }
                          />
                        </label>
                        {allow_booking_until_open ? (
                          <span>yes</span>
                        ) : (
                          <span>no</span>
                        )}
                      </div>
                      <div
                        className={
                          allow_booking_until_open
                            ? "allow-booking-until-date"
                            : "allow-booking-until-date disabled-div"
                        }
                      >
                        <span>*</span>
                        <div className="lesson-start-time  lesson-form-group">
                          <DatePicker
                            selected={allow_booking_until}
                            onChange={this.handleChangeAllowBookingUntil()}
                            preventOpenOnFocus={false}
                            showTimeSelect
                            dateFormat="MMM D, h:mma"
                            timeCaption="Time"
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              <br />
              <div className="_rec-form">
                <div className="form-group">
                  <label> Assign to </label>
                  <div className="picky-container">
                    <Picky
                      options={coachList}
                      value={this.state.coach}
                      valueKey="id"
                      labelKey="displayName"
                      multiple={true}
                      numberDisplayed={1}
                      includeFilter={this.state.includeFilterCoach}
                      onChange={this.selectCoachOption}
                      dropdownHeight={350}
                      placeholder="Admin"
                    />
                  </div>
                </div>
              </div>
              <div className="_rec-form">
                    <div
                      className="form-group p-rel assign-book-court"
                      style={{ position: "relative" }}
                    >
                      <label>
                        {" "}
                        Assign/book court &nbsp;{" "}
                        {/** <img
                          title="Refresh"
                          className="refreshButton p-abs-refresh"
                          src="/assets/img/baseline_refresh_black_18dp.png"
                          onClick={this.getCourtListingAvailability}
                          alt="refresh"
                        />{" "}*/}
                      </label>
                      <div className="picky-container">
                        <Picky
                          options={this.state.courtsList}
                          value={this.state.courts}
                          valueKey="id"
                          labelKey="name"
                          multiple={true}
                          numberDisplayed={2}
                          includeFilter={true}
                          includeSelectAll={true}
                          onChange={this.selectMultipleOption}
                          dropdownHeight={350}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="rec-form">
                    <div className="switcher-div">
                      <label className="switcherLabel">
                        <GreenSwitcher
                          value={cancel_allowed}
                          checked={cancel_allowed}
                          onChange={() =>
                            this.handleAllowCancel(!cancel_allowed)
                          }
                        />
                      </label>
                      {cancel_allowed ? (
                        <span className="lesson_cancel_test" style={{ position: "relative" }}>
                          Cancellation
                          <input
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            className="cancel_allowed_hour"
                            name="cancel_allowed_hour"
                            value={cancel_allowed_hour}
                            onChange={this.handleAllowCancelHour()}
                            min="0"
                            max="999"
                          />{" "}
                          hours before allowed{" "}
                          <Notification>
                            Cancellation allowed minimum zero (anytime) and max
                            999 hours.
                          </Notification>
                        </span>
                      ) : (
                        <span>No cancellation and no refunds are allowed</span>
                      )}
                    </div>
                  </div>
                 
                  <div className="right-side">
                  <div className="lessons-note">
                    <div className="modal-form-group">
                      <h5>Description </h5>
                      <textarea
                        className={`${error.description ? "error" : ""}`}
                        name="description"
                        value={description}
                        onChange={this.handleChange()}
                      />
                      <div>
                        <span className="counter_cutum">
                          {description ? description.length : 0} /{" "}
                          {description ? 280 - description.length : 280}
                        </span>
                      </div>
                      <Notification>Max 280 characters</Notification>
                    </div>
                  </div>
                  <div className="lessons-note">
                    <div className="modal-form-group">
                      <h5>Note (note is private, only you see the note) </h5>
                      <textarea
                        className={`${error.note ? "error" : ""}`}
                        name="note"
                        value={note}
                        onChange={this.handleChange()}
                      />
                      <div>
                        <span className="counter_cutum">
                          {description ? description.length : 0} /{" "}
                          {description ? 280 - description.length : 280}
                        </span>
                      </div>
                      <Notification>Max 280 characters</Notification>
                    </div>
                  </div>
                </div>
                <br />
                
                
              </div>
            </div>
            <div className="each-col thirth-col pad-r-no p-rel">
            <div className="rec-form">
                {this.state.disabled ? (
                  ""
                ) : (
                  <div className="markAsbookedDiv">
                    <FormGroup
                      type="checkbox"
                      name="closed"
                      id="checkM"
                      checked={this.state.closed}
                      value={this.state.closed}
                      onChange={this.handleCheckboxClosed}
                      label="Block/Close for booking"
                    >
                      <label htmlFor="checkM" className="label-icon" />
                    </FormGroup>
                  </div>
                )}
              </div>
              <BookedBy
                _during_add={true}
                quantity={quantity}
                isWhoCanBookPublic={isWhoCanBookPublic}
                ref={(booked_by) => {
                  this.booked_by = booked_by;
                }}
              />
               
              {!this.props.occurrenceInfo && (
                <div className="lesson-submit">
                 <div className="pretty-button">
                    <button
                      type="button"
                      className="red-button"
                      onClick={close}
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="pretty-button" style={{marginLeft:"5px"}}>
                    <button
                      type="submit"
                      className="green-button"
                      disabled={oneClick}
                    >
                      Save 
                    </button>
                  </div>
                  <span style={{ visibility: "hidden" }}>
                    {this.state.hasDataInClipboard &&
                      /*<button
                        type="button"
                        onClick={this.handleFillFromClipboard}
                        className="btn btn-green lesson-button"
                        style={{ padding: "5px 20px", marginLeft: "10px" }}
                      >
                        Fill from clipboard
                      </button>*/

                      setTimeout(() => {
                        this.handleFillFromClipboard();
                      }, 0)}
                  </span>
                </div>
              )}
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }

  render() {
    const { close, isfromCourt } = this.props;
    
    return (
      <React.Fragment>
        {!isfromCourt && (
          <div
            className={
              this.state.llid
                ? "modal active lessons-modal enlarge-for-update"
                : "modal active lessons-modal"
            }
            id="modalBack"
          >
            <div className={` modal-content zoomInClassLesson`}>
              <div className="close-btn">
                <button onClick={close}>
                  <img src="/assets/img/cancel.png" alt="cancel" />
                </button>
              </div>
              {this.renderForm()}
            </div>
          </div>
        )}
        {isfromCourt && this.renderForm()}
      </React.Fragment>
    );
  }

  handleFillFromClipboard = () => {
    let copy_data = this.getClipboardData();
    if (typeof copy_data === "object") {
      const duplicateData = this.cleanCopyData(copy_data);
     
      this.setState((state) => ({
        ...state,
        isRecarenceOn:duplicateData?.repeatState?.repeatOn,
        ...duplicateData,
      }));
      setTimeout(() => {
        localStorage.removeItem("DUPLICATE_LESSON_DATA");
        localStorage.removeItem("DUPLICATE_LESSON_DATA_FOR_REPEAT");
      }, 2500)

    }
  };

  cleanCopyData = (copy_data) => {
    const duplicateData = {};

    Object.keys(this.state).forEach((key) => {
      if (
        copy_data.hasOwnProperty(key) &&
        !(
          key === "currentDay" ||
          key === "llid" ||
          key === "bookedPersons" ||
          //key === "coach" ||
          key === "coachList" ||
          key === "currentRecord" ||
          key === "courtsList" ||
         // key === "includeFilterCoach" ||
          key === "includeFilter" ||
          key === "openAddNewName" ||
          key === "who_can_book_filters" //||
        //  key === "cancel_allowed" ||
         // key === "cancel_allowed_hour"
        )
      ) {
        if(key === "coach"){
          duplicateData[key] = copy_data[key].map((itm)=>{
            return {id:itm.id,displayName:itm._displayName||itm.displayName}
          });
        }else{
          duplicateData[key] = copy_data[key];
        }
        
      }
    });

    // fix datetimes
    let today = moment().tz(this.props.timezone).format();

    const start_time = moment(duplicateData.start_time).tz(this.props.timezone);

    if (moment(today).isAfter(start_time)) {
      delete duplicateData.start_time;
    } else {
      duplicateData.start_time = start_time;
    }

    const end_time = moment(duplicateData.end_time).tz(this.props.timezone);

    if (moment(today).isAfter(end_time)) {
      delete duplicateData.end_time;
    } else {
      duplicateData.end_time = end_time;
    }
    
    return duplicateData;
  };
}

const mapStateToProps = (state) => {
  return {
    availableCourts: state.lesson.availableCourts,
    lessonTypes: state.lesson.lessonTypes,
    courts: state.courts.data,
    loadingGetCourts: state.lesson.loadingGetCourts,
    loadingAddLesson: state.lesson.loading,
    isConnected: state.stripe.isConnected,
    user: state.auth.user,
    timezone: state.auth.user.business.timezone,
    business: state.auth.user.business,
    allow_booking_until: state.auth.user.business.allow_booking_until,
    timeStep: state.auth.user.business.booking_to_start,
    minDuration: state.auth.user.business.min_list_duration,
    whoCanBookData: state.micro_settings.current.whoCanBook || {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCoach: () => dispatch(getCoach()),
    getCourts: () => dispatch(getCourts()),
    getCourtListingavAilability: (state, repiatState) =>
      dispatch(getCourtListingavAilability(state, repiatState)),
    AddLesson: (state, repiatState, lessonTypeToGetAfterAdd) =>
      dispatch(AddLesson(state, repiatState, lessonTypeToGetAfterAdd)),
  };
};
ModalLessons.propTypes = {
  close: PropTypes.func.isRequired,

  timezone: PropTypes.string.isRequired,
  currentDay: PropTypes.objectOf(PropTypes.any).isRequired,
  AddLesson: PropTypes.func.isRequired,
  coachList: PropTypes.array,
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ModalLessons)
);
