import React, { Component } from 'react';
import moment from 'moment-timezone';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { GetTags, DelTag, UpdateTag } from '../../actions/Tag';
import TagsForm from './Form';
import GreenSwitcher from '../UI/GreenSwitcher';

class TagsList extends Component {
  state = {
    showForm: false,
    showArchived: false,
    editable: null,
  };

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.showArchived !== this.state.showArchived) {
      this.loadData();
    }
  }

  render() {
    const { showForm, editable, showArchived } = this.state;

    return (
      <div className={`tags-list`}>
        {showForm ? <TagsForm onCancel={this.handleFormClose} data={editable} /> : null}

        <div className="flex-container filter-container">
          <div className="each-filter">
            <label className="switcherLabel">
              Show archived
              <GreenSwitcher
                value={showArchived}
                checked={showArchived}
                onChange={() => this.setState(state => ({ ...state, showArchived: !showArchived }))}
              />
              {showArchived ? <span>On</span> : <span>Off</span>}
            </label>
          </div>
          <div className="each-filter">
            <label>
              Create new tag
              <button className="btn btn-green rounded btn-create-tag" type="button" onClick={this.handleCreate}>
                <img src="/assets/img/plus.png" width={20} height={20} alt="plus" />
              </button>
            </label>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Name</th>
                <th>Color</th>
                <th>CreatedAt</th>
                {!showArchived ? <th>Actions</th> : null}
              </tr>
            </thead>
            <tbody>{this.getTableContent()}</tbody>
          </table>
        </div>
      </div>
    );
  }

  loadData = () => {
    this.props.getTags({
      status: this.state.showArchived ? 1 : undefined,
    });
  };

  getTableContent = () => {
    let result;
    const {
      tag: { loading, list },
      timeZone,
    } = this.props;
    const { showArchived } = this.state;

    if (loading) {
      return (
        <tr>
          <td colSpan="4">Loading...</td>
        </tr>
      );
    } else if (!list.length) {
      return (
        <tr>
          <td colSpan="4">No tags found.</td>
        </tr>
      );
    } else {
      result = list.map(row => (
        <tr key={row.id}>
          <td>{row.display_name}</td>
          <td>
            <span
              style={{
                width: '15px',
                height: '15px',
                background: row.color,
                display: 'inline-block',
                borderRadius: '50%',
              }}></span>
          </td>
          <td>
            {moment(row.createdAt)
              .tz(timeZone)
              .format('MMMM Do YYYY, h:mm:ss a')}
          </td>

          {!showArchived ? (
            <td>
              <div className="d-flex justify-center">
                <button
                  className="btn btn-gray rounded mr-2"
                  title="Archive"
                  type="button"
                  onClick={() => this.handleDelete(row.id)}>
                  <img src="/assets/img/archive.png" width={15} height={15} alt="archive" />
                </button>
                <button
                  className="btn btn-green rounded"
                  title="Edit"
                  type="button"
                  onClick={() => this.handleEdit(row)}>
                  <img src="/assets/img/edit.png" width={15} height={15} alt="edit" />
                </button>
              </div>
            </td>
          ) : null}
        </tr>
      ));
    }

    return result;
  };

  handleCreate = () => {
    this.setState({
      showForm: true,
      editable: null,
      archived: false,
    });
  };

  handleFormClose = () => {
    this.setState({
      showForm: false,
      editable: null,
    });
  };

  handleDelete = async id => {
    if (await window.modal.cnfirm('Confirm', 'Are you sure to delete the tag?')) {
      this.props.deleteTag(id);
    }
  };

  handleEdit = item => {
    this.setState({
      showForm: true,
      editable: item,
    });
  };
}

const mapStateToProps = state => {
  return {
    error: state.auth.signup,
    user: state.auth.user,
    tag: state.tag,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getTags: query => dispatch(GetTags(query)),
    deleteTag: id => dispatch(DelTag(id)),
    updateTag: (id, data) => dispatch(UpdateTag(id, data)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(TagsList)
);
