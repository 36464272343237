import * as actionTypes from '../constants/Shop';

const initState = {
  category: {
    error: undefined,
    current: {
      loading: false,
      data: undefined,
    },
    list: {
      loading: false,
      total: 0,
      data: [],
    },
  },
  activity: {
    error: undefined,
    current: {
      loading: false,
      data: undefined,
    },
    list: {
      loading: false,
      total: 0,
      data: [],
    },
  },
  product_type: {
    error: undefined,
    current: {
      loading: false,
      data: undefined,
    },
    list: {
      loading: false,
      total: 0,
      data: [],
    },
  },
  brand: {
    error: undefined,
    current: {
      loading: false,
      data: undefined,
    },
    list: {
      loading: false,
      total: 0,
      data: [],
    },
  },
  model: {
    error: undefined,
    current: {
      loading: false,
      data: undefined,
    },
    list: {
      loading: false,
      total: 0,
      data: [],
    },
  },
  color: {
    error: undefined,
    current: {
      loading: false,
      data: undefined,
    },
    list: {
      loading: false,
      total: 0,
      data: [],
    },
  },
  size: {
    error: undefined,
    current: {
      loading: false,
      data: undefined,
    },
    list: {
      loading: false,
      total: 0,
      data: [],
    },
  },
  unit: {
    error: undefined,
    current: {
      loading: false,
      data: undefined,
    },
    list: {
      loading: false,
      total: 0,
      data: [],
    },
  },
  vendor: {
    error: undefined,
    current: {
      loading: false,
      data: undefined,
    },
    list: {
      loading: false,
      total: 0,
      data: [],
    },
  },

  cart: {
    error: undefined,
    current: {
      loading: false,
      data: undefined,
    },
    list: {
      loading: false,
      total: 0,
      data: JSON.parse(localStorage.getItem('CART_LIST_DATA') || '[]'),
    },
  },

  analytics: {
    error: undefined,
    loading: false,
    data: undefined,
  },
};

const ShopReducer = (state = initState, action) => {
  switch (action.type) {
    // CATEGORY_LIST handlers
    case actionTypes.SHOP_CATEGORY_LIST_LOADING: {
      return {
        ...state,
        category: {
          ...state.category,
          list: {
            ...state.category.list,
            loading: action.payload,
          },
        },
      };
    }
    case actionTypes.SHOP_CATEGORY_LIST_ERROR: {
      return {
        ...state,
        category: {
          ...state.category,
          list: {
            ...state.category.list,
            error: action.payload,
          },
        },
      };
    }
    case actionTypes.SHOP_CATEGORY_LIST_SUCCESS: {
      return {
        ...state,
        category: {
          ...state.category,
          list: {
            ...state.category.list,
            error: undefined,
            data: action.payload.entities,
            total: action.payload.total,
          },
        },
      };
    }

    // CATEGORY_CURRENT handlers
    case actionTypes.SHOP_CATEGORY_CURRENT_LOADING: {
      return {
        ...state,
        category: {
          ...state.category,
          current: {
            ...state.category.current,
            loading: action.payload,
          },
        },
      };
    }
    case actionTypes.SHOP_CATEGORY_CURRENT_ERROR: {
      return {
        ...state,
        category: {
          ...state.category,
          current: {
            ...state.category.current,
            error: action.payload,
          },
        },
      };
    }
    case actionTypes.SHOP_CATEGORY_CURRENT_SUCCESS: {
      return {
        ...state,
        category: {
          ...state.category,
          current: {
            ...state.category.current,
            error: undefined,
            data: action.payload,
          },
        },
      };
    }

    // ACTIVITY_LIST handlers
    case actionTypes.SHOP_ACTIVITY_LIST_LOADING: {
      return {
        ...state,
        activity: {
          ...state.activity,
          list: {
            ...state.activity.list,
            loading: action.payload,
          },
        },
      };
    }
    case actionTypes.SHOP_ACTIVITY_LIST_ERROR: {
      return {
        ...state,
        activity: {
          ...state.activity,
          list: {
            ...state.activity.list,
            error: action.payload,
          },
        },
      };
    }
    case actionTypes.SHOP_ACTIVITY_LIST_SUCCESS: {
      return {
        ...state,
        activity: {
          ...state.activity,
          list: {
            ...state.activity.list,
            error: undefined,
            data: action.payload.entities,
            total: action.payload.total,
          },
        },
      };
    }

    // ACTIVITY_CURRENT handlers
    case actionTypes.SHOP_ACTIVITY_CURRENT_LOADING: {
      return {
        ...state,
        activity: {
          ...state.activity,
          current: {
            ...state.activity.current,
            loading: action.payload,
          },
        },
      };
    }
    case actionTypes.SHOP_ACTIVITY_CURRENT_ERROR: {
      return {
        ...state,
        activity: {
          ...state.activity,
          current: {
            ...state.activity.current,
            error: action.payload,
          },
        },
      };
    }
    case actionTypes.SHOP_ACTIVITY_CURRENT_SUCCESS: {
      return {
        ...state,
        activity: {
          ...state.activity,
          current: {
            ...state.activity.current,
            error: undefined,
            data: action.payload,
          },
        },
      };
    }

    // PRODUCT_TYPE_LIST handlers
    case actionTypes.SHOP_PRODUCT_TYPE_LIST_LOADING: {
      return {
        ...state,
        product_type: {
          ...state.product_type,
          list: {
            ...state.product_type.list,
            loading: action.payload,
          },
        },
      };
    }
    case actionTypes.SHOP_PRODUCT_TYPE_LIST_ERROR: {
      return {
        ...state,
        product_type: {
          ...state.product_type,
          list: {
            ...state.product_type.list,
            error: action.payload,
          },
        },
      };
    }
    case actionTypes.SHOP_PRODUCT_TYPE_LIST_SUCCESS: {
      return {
        ...state,
        product_type: {
          ...state.product_type,
          list: {
            ...state.product_type.list,
            error: undefined,
            data: action.payload.entities,
            total: action.payload.total,
          },
        },
      };
    }

    // PRODUCT_TYPE_CURRENT handlers
    case actionTypes.SHOP_PRODUCT_TYPE_CURRENT_LOADING: {
      return {
        ...state,
        product_type: {
          ...state.product_type,
          current: {
            ...state.product_type.current,
            loading: action.payload,
          },
        },
      };
    }
    case actionTypes.SHOP_PRODUCT_TYPE_CURRENT_ERROR: {
      return {
        ...state,
        product_type: {
          ...state.product_type,
          current: {
            ...state.product_type.current,
            error: action.payload,
          },
        },
      };
    }
    case actionTypes.SHOP_PRODUCT_TYPE_CURRENT_SUCCESS: {
      return {
        ...state,
        product_type: {
          ...state.product_type,
          current: {
            ...state.product_type.current,
            error: undefined,
            data: action.payload,
          },
        },
      };
    }

    // BRAND_LIST handlers
    case actionTypes.SHOP_BRAND_LIST_LOADING: {
      return {
        ...state,
        brand: {
          ...state.brand,
          list: {
            ...state.brand.list,
            loading: action.payload,
          },
        },
      };
    }
    case actionTypes.SHOP_BRAND_LIST_ERROR: {
      return {
        ...state,
        brand: {
          ...state.brand,
          list: {
            ...state.brand.list,
            error: action.payload,
          },
        },
      };
    }
    case actionTypes.SHOP_BRAND_LIST_SUCCESS: {
      return {
        ...state,
        brand: {
          ...state.brand,
          list: {
            ...state.brand.list,
            error: undefined,
            data: action.payload.entities,
            total: action.payload.total,
          },
        },
      };
    }

    // BRAND_CURRENT handlers
    case actionTypes.SHOP_BRAND_CURRENT_LOADING: {
      return {
        ...state,
        brand: {
          ...state.brand,
          current: {
            ...state.brand.current,
            loading: action.payload,
          },
        },
      };
    }
    case actionTypes.SHOP_BRAND_CURRENT_ERROR: {
      return {
        ...state,
        brand: {
          ...state.brand,
          current: {
            ...state.brand.current,
            error: action.payload,
          },
        },
      };
    }
    case actionTypes.SHOP_BRAND_CURRENT_SUCCESS: {
      return {
        ...state,
        brand: {
          ...state.brand,
          current: {
            ...state.brand.current,
            error: undefined,
            data: action.payload,
          },
        },
      };
    }

    // MODEL_LIST handlers
    case actionTypes.SHOP_MODEL_LIST_LOADING: {
      return {
        ...state,
        model: {
          ...state.model,
          list: {
            ...state.model.list,
            loading: action.payload,
          },
        },
      };
    }
    case actionTypes.SHOP_MODEL_LIST_ERROR: {
      return {
        ...state,
        model: {
          ...state.model,
          list: {
            ...state.model.list,
            error: action.payload,
          },
        },
      };
    }
    case actionTypes.SHOP_MODEL_LIST_SUCCESS: {
      return {
        ...state,
        model: {
          ...state.model,
          list: {
            ...state.model.list,
            error: undefined,
            data: action.payload.entities,
            total: action.payload.total,
          },
        },
      };
    }

    // MODEL_CURRENT handlers
    case actionTypes.SHOP_MODEL_CURRENT_LOADING: {
      return {
        ...state,
        model: {
          ...state.model,
          current: {
            ...state.model.current,
            loading: action.payload,
          },
        },
      };
    }
    case actionTypes.SHOP_MODEL_CURRENT_ERROR: {
      return {
        ...state,
        model: {
          ...state.model,
          current: {
            ...state.model.current,
            error: action.payload,
          },
        },
      };
    }
    case actionTypes.SHOP_MODEL_CURRENT_SUCCESS: {
      return {
        ...state,
        model: {
          ...state.model,
          current: {
            ...state.model.current,
            error: undefined,
            data: action.payload,
          },
        },
      };
    }

    // COLOR_LIST handlers
    case actionTypes.SHOP_COLOR_LIST_LOADING: {
      return {
        ...state,
        color: {
          ...state.color,
          list: {
            ...state.color.list,
            loading: action.payload,
          },
        },
      };
    }
    case actionTypes.SHOP_COLOR_LIST_ERROR: {
      return {
        ...state,
        color: {
          ...state.color,
          list: {
            ...state.color.list,
            error: action.payload,
          },
        },
      };
    }
    case actionTypes.SHOP_COLOR_LIST_SUCCESS: {
      return {
        ...state,
        color: {
          ...state.color,
          list: {
            ...state.color.list,
            error: undefined,
            data: action.payload.entities,
            total: action.payload.total,
          },
        },
      };
    }

    // COLOR_CURRENT handlers
    case actionTypes.SHOP_COLOR_CURRENT_LOADING: {
      return {
        ...state,
        color: {
          ...state.color,
          current: {
            ...state.color.current,
            loading: action.payload,
          },
        },
      };
    }
    case actionTypes.SHOP_COLOR_CURRENT_ERROR: {
      return {
        ...state,
        color: {
          ...state.color,
          current: {
            ...state.color.current,
            error: action.payload,
          },
        },
      };
    }
    case actionTypes.SHOP_COLOR_CURRENT_SUCCESS: {
      return {
        ...state,
        color: {
          ...state.color,
          current: {
            ...state.color.current,
            error: undefined,
            data: action.payload,
          },
        },
      };
    }

    // SIZE_LIST handlers
    case actionTypes.SHOP_SIZE_LIST_LOADING: {
      return {
        ...state,
        size: {
          ...state.size,
          list: {
            ...state.size.list,
            loading: action.payload,
          },
        },
      };
    }
    case actionTypes.SHOP_SIZE_LIST_ERROR: {
      return {
        ...state,
        size: {
          ...state.size,
          list: {
            ...state.size.list,
            error: action.payload,
          },
        },
      };
    }
    case actionTypes.SHOP_SIZE_LIST_SUCCESS: {
      return {
        ...state,
        size: {
          ...state.size,
          list: {
            ...state.size.list,
            error: undefined,
            data: action.payload.entities,
            total: action.payload.total,
          },
        },
      };
    }

    // SIZE_CURRENT handlers
    case actionTypes.SHOP_SIZE_CURRENT_LOADING: {
      return {
        ...state,
        size: {
          ...state.size,
          current: {
            ...state.size.current,
            loading: action.payload,
          },
        },
      };
    }
    case actionTypes.SHOP_SIZE_CURRENT_ERROR: {
      return {
        ...state,
        size: {
          ...state.size,
          current: {
            ...state.size.current,
            error: action.payload,
          },
        },
      };
    }
    case actionTypes.SHOP_SIZE_CURRENT_SUCCESS: {
      return {
        ...state,
        size: {
          ...state.size,
          current: {
            ...state.size.current,
            error: undefined,
            data: action.payload,
          },
        },
      };
    }

    // UNIT_LIST handlers
    case actionTypes.SHOP_UNIT_LIST_LOADING: {
      return {
        ...state,
        unit: {
          ...state.unit,
          list: {
            ...state.unit.list,
            loading: action.payload,
          },
        },
      };
    }
    case actionTypes.SHOP_UNIT_LIST_ERROR: {
      return {
        ...state,
        unit: {
          ...state.unit,
          list: {
            ...state.unit.list,
            error: action.payload,
          },
        },
      };
    }
    case actionTypes.SHOP_UNIT_LIST_SUCCESS: {
      return {
        ...state,
        unit: {
          ...state.unit,
          list: {
            ...state.unit.list,
            error: undefined,
            data: action.payload.entities,
            total: action.payload.total,
          },
        },
      };
    }

    // UNIT_CURRENT handlers
    case actionTypes.SHOP_UNIT_CURRENT_LOADING: {
      return {
        ...state,
        unit: {
          ...state.unit,
          current: {
            ...state.unit.current,
            loading: action.payload,
          },
        },
      };
    }
    case actionTypes.SHOP_UNIT_CURRENT_ERROR: {
      return {
        ...state,
        unit: {
          ...state.unit,
          current: {
            ...state.unit.current,
            error: action.payload,
          },
        },
      };
    }
    case actionTypes.SHOP_UNIT_CURRENT_SUCCESS: {
      return {
        ...state,
        unit: {
          ...state.unit,
          current: {
            ...state.unit.current,
            error: undefined,
            data: action.payload,
          },
        },
      };
    }

    // VENDOR_LIST handlers
    case actionTypes.SHOP_VENDOR_LIST_LOADING: {
      return {
        ...state,
        vendor: {
          ...state.vendor,
          list: {
            ...state.vendor.list,
            loading: action.payload,
          },
        },
      };
    }
    case actionTypes.SHOP_VENDOR_LIST_ERROR: {
      return {
        ...state,
        vendor: {
          ...state.vendor,
          list: {
            ...state.vendor.list,
            error: action.payload,
          },
        },
      };
    }
    case actionTypes.SHOP_VENDOR_LIST_SUCCESS: {
      return {
        ...state,
        vendor: {
          ...state.vendor,
          list: {
            ...state.vendor.list,
            error: undefined,
            data: action.payload.entities,
            total: action.payload.total,
          },
        },
      };
    }

    // VENDOR_CURRENT handlers
    case actionTypes.SHOP_VENDOR_CURRENT_LOADING: {
      return {
        ...state,
        vendor: {
          ...state.vendor,
          current: {
            ...state.vendor.current,
            loading: action.payload,
          },
        },
      };
    }
    case actionTypes.SHOP_VENDOR_CURRENT_ERROR: {
      return {
        ...state,
        vendor: {
          ...state.vendor,
          current: {
            ...state.vendor.current,
            error: action.payload,
          },
        },
      };
    }
    case actionTypes.SHOP_VENDOR_CURRENT_SUCCESS: {
      return {
        ...state,
        vendor: {
          ...state.vendor,
          current: {
            ...state.vendor.current,
            error: undefined,
            data: action.payload,
          },
        },
      };
    }

    // CART_LIST handlers
    case actionTypes.SHOP_CART_LIST_LOADING: {
      return {
        ...state,
        cart: {
          ...state.cart,
          list: {
            ...state.cart.list,
            loading: action.payload,
          },
        },
      };
    }
    case actionTypes.SHOP_CART_LIST_ERROR: {
      return {
        ...state,
        cart: {
          ...state.cart,
          list: {
            ...state.cart.list,
            error: action.payload,
          },
        },
      };
    }
    case actionTypes.SHOP_CART_LIST_SUCCESS: {
      const cartListData = action.payload;

      localStorage.setItem('CART_LIST_DATA', JSON.stringify(cartListData));

      return {
        ...state,
        cart: {
          ...state.cart,
          list: {
            ...state.cart.list,
            error: undefined,
            data: cartListData,
          },
        },
      };
    }

    // CART_CURRENT handlers
    case actionTypes.SHOP_CART_CURRENT_LOADING: {
      return {
        ...state,
        cart: {
          ...state.cart,
          current: {
            ...state.cart.current,
            loading: action.payload,
          },
        },
      };
    }
    case actionTypes.SHOP_CART_CURRENT_ERROR: {
      return {
        ...state,
        cart: {
          ...state.cart,
          current: {
            ...state.cart.current,
            error: action.payload,
          },
        },
      };
    }
    case actionTypes.SHOP_CART_CURRENT_SUCCESS: {
      return {
        ...state,
        cart: {
          ...state.cart,
          current: {
            ...state.cart.current,
            error: undefined,
            data: action.payload,
          },
        },
      };
    }

    // ANALYTICS handlers
    case actionTypes.SHOP_ANALYTICS_LOADING: {
      return {
        ...state,
        analytics: {
          ...state.analytics,
          loading: action.payload,
        },
      };
    }
    case actionTypes.SHOP_ANALYTICS_ERROR: {
      return {
        ...state,
        analytics: {
          ...state.analytics,
          error: action.payload,
        },
      };
    }
    case actionTypes.SHOP_ANALYTICS_SUCCESS: {
      return {
        ...state,
        analytics: {
          ...state.analytics,
          error: undefined,
          data: action.payload,
        },
      };
    }

    default:
      return state;
  }
};

export default ShopReducer;
