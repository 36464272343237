// SPORT part
export const LOCATION_ENTITY_CURRENT_LOADING = 'LOCATION_ENTITY_CURRENT_LOADING';
export const LOCATION_ENTITY_CURRENT_SUCCESS = 'LOCATION_ENTITY_CURRENT_SUCCESS';
export const LOCATION_ENTITY_CURRENT_ERROR = 'LOCATION_ENTITY_CURRENT_ERROR';

export const LOCATION_ENTITY_LIST_LOADING = 'LOCATION_ENTITY_LIST_LOADING';
export const LOCATION_ENTITY_LIST_SUCCESS = 'LOCATION_ENTITY_LIST_SUCCESS';
export const LOCATION_ENTITY_LIST_ERROR = 'LOCATION_ENTITY_LIST_ERROR';


export const LOCATION_CHECKIN_LIST_LOADING = 'LOCATION_CHECKIN_LIST_LOADING';
export const LOCATION_CHECKIN_LIST_SUCCESS = 'LOCATION_CHECKIN_LIST_SUCCESS';
export const LOCATION_CHECKIN_LIST_ERROR = 'LOCATION_CHECKIN_LIST_ERROR';