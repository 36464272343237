import React, { Component } from 'react';
import PropTypes from 'prop-types';
import StripeCharge from '../StripeCharge';


class ChargeModal extends Component {
    constructor(props) {
        super(props);
        this.state = {}

    }

    render() {
        const { clouseModal, booking, show_members, cbCharge, default_price,fromNewCard } = this.props;
        
        return (
            <div className="modal stripe-modal active">
                <div className="modal-content">
                    <div className="close-btn">
                        <button onClick={clouseModal}>
                            <img src="/assets/img/cancel.png" alt="cancel" />
                        </button>
                    </div>
                    <StripeCharge
                        booking={booking}
                        member_type_id={this.props.member_type_id}
                        from={this.props.from}
                        cbCharge={cbCharge}
                        default_price={default_price}
                        show_members={show_members}
                        outerState={this.props.outerState ? this.props.outerState : null}
                        uid={this.props.uid}
                        fromNewCard={fromNewCard}
                        stripeUserId={this.props.stripeUserId}
                        clouseModal={this.props.clouseModal}
                    />
                </div>
            </div>
        );
    }
}

ChargeModal.propTypes = {
    clouseModal: PropTypes.func.isRequired,
    //uid: PropTypes.string.isRequired,
    stripeUserId: PropTypes.string,
};
ChargeModal.defaultProps = {
    stripeUserId: null
};
export default ChargeModal;
