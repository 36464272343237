import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment-timezone";
import DatePicker from "react-datepicker";
import { blockCoachAvailability } from "../../actions/MicroSettings";
import { generateTimeListStartedFromByStep } from "../../utils/helpers";

class BlockAvailability extends Component {
  constructor(props) {
    super(props);

    const {
      data: { coach_id, startDate, endDate },

      timeZone,
    } = props;

    this.state = {
      coach_id: coach_id,
      startTime: moment(startDate).tz(timeZone),
      endTime: moment(endDate).tz(timeZone),
      loadingPage: false,
    };
  }

  render() {
    const {
      className,
      onClose,
      timeStep,
      timeZone,
      data: { startDate, endDate },
    } = this.props;
    const { startTime, endTime, loadingPage } = this.state;

    const min_date = moment(startDate).tz(timeZone).clone().tz(timeZone);

    const injectTimes = generateTimeListStartedFromByStep(min_date, timeStep);
    const timeIntervals = 24 * 60;

    return (
      <div className={`modal modal-availability active ${className}`}>
        <div className="modal-content">
          <div className="modal-header">
            <div className="close-btn">
              <button onClick={onClose}>
                <img src="/assets/img/cancel.png" alt="cancel" />
              </button>
            </div>
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              this.handleSubmit();
            }}
            className="lessonViewPage courtlisting-div"
          >
            <h5>Select time range to block.</h5>
            <div className="rec-form">
              <div className="form-group lesson-form-group">
                <label>
                  <span className="important-span">*</span>&nbsp; Start
                </label>
                <div className="picky-container">
                  <DatePicker
                    selected={startTime}
                    onChange={(date) => {
                      this.handleChange({
                        target: {
                          name: "startTime",
                          value: date,
                        },
                      });
                    }}
                    showTimeSelect
                    showTimeSelectOnly
                    minTime={min_date}
                    maxTime={moment(endDate).tz(timeZone)}
                    popperPlacement="bottom-end"
                    timeIntervals={timeIntervals}
                    dateFormat="MMM D, YYYY  h:mma"
                    timeCaption="Time"
                    injectTimes={injectTimes}
                  />
                </div>
              </div>
            </div>
            <div className="rec-form">
              <div className="form-group lesson-form-group">
                <label>
                  <span className="important-span">*</span>&nbsp; End
                </label>
                <div className="picky-container">
                  <DatePicker
                    selected={endTime}
                    onChange={(date) => {
                      this.handleChange({
                        target: {
                          name: "endTime",
                          value: date,
                        },
                      });
                    }}
                    showTimeSelect
                    showTimeSelectOnly
                    minTime={moment(startTime).tz(timeZone)}
                    maxTime={moment(endDate).tz(timeZone)}
                    popperPlacement="bottom-end"
                    timeIntervals={timeIntervals}
                    dateFormat="MMM D, YYYY  h:mma"
                    timeCaption="Time"
                    injectTimes={injectTimes}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex justify-center">
            <div className="pretty-button">
              <button className="btn red-button mr-2" type="button" onClick={onClose}>
                Cancel
              </button>
              </div>
              <div className="pretty-button">
              <button
                type="submit"
                className="btn green-button"
                style={{ padding: "5px 20px" }}
                disabled={loadingPage}
              >
                Block
              </button>
              </div>
              
            </div>
          </form>
        </div>
      </div>
    );
  }

  handleSubmit = async () => {
    if (this.checkIsTimeRangeValid()) {
      const { coach_id, startTime, endTime } = this.state;

      try {
        let promise;
        promise = this.props.blockCoachAvailability({
          startDate: startTime.clone().utc().format(),
          endDate: endTime.clone().utc().format(),
          coach_id: coach_id,
        });
        
        if (await promise) {
          this.props.onClose();
          window.modal.alert("Success", "You successfully blocked time range."); 
          
        }
      } catch (e) {
        window.modal.alert("Error", e.message);
      }
    }
  };

  handleChange = (e) => {
    const {
      target: { name, value },
    } = e;

    this.setState((st) => ({
      ...st,
      [name]: value,
    }));
  };

  checkIsTimeRangeValid = () => {
    const { startTime, endTime } = this.state;

    if (endTime.isBefore(startTime)) {
      window.modal.alert("Error", `Please select valid time range.`);

      return false;
    }

    return true;
  };
}

const mapStateToProps = (state) => {
  return {
    business: state.auth.user.business,
    timeZone: state.auth.user.business.timezone,
    timeStep: state.auth.user.business.booking_to_start,
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    blockCoachAvailability: (data) => dispatch(blockCoachAvailability(data)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BlockAvailability)
);
