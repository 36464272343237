import React, { Component } from "react";
import PropTypes from "prop-types";
import "./court-sheet.scss";
import moment from "moment-timezone";
import { withRouter } from "react-router-dom";

import { Link } from "react-router-dom";
import { Views } from "react-big-calendar";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";

import TimeZoneAgnosticBigCalendar, {
  convertDateToZonedDate,
} from "./TimeZoneAgnosticBigCalendar";
import { hasPermission, hexToRgbA, makeid } from "../../utils/helpers";
import ModalDashboard from "../ModalDashboard";
import ModalDel from "../ModalDel";
import Loading from "../UI/Loading";
import { date } from "date-arithmetic";

const golfBusinessIds=[109];

const ResourceTitleComp = ({ name, id, roles }) => {
  if (hasPermission(roles, "settings", "update")) {
    return (
      <div>
        <div>
          <Link to={`/private/court/${id}`}>{name} Edit</Link>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div>{name}</div>
      </div>
    );
  }
};

const EventContent = ({
  event: {
    record,
    appointment,
    business,
    lesson_id,
    is_nbu,
    user,
    currency_code,
    book_pay_type,
    price_per_hour,
    point_per_hour,
    type,
    title,
    original_coachAvail,
  },
}) => {
  const member_type_id = 1;
  const admin_type_id = 4;
  let paid = record.make_it_paid;
  let authorized = record.authorized;
  let bookings = [];
  let hasAddon= record?.bookings?.some((itm)=>{
    return itm?.add_on_id!=null
  })
  
  
  const notShowPrice= !golfBusinessIds.includes(parseInt(user?.business?.id))
  
  if (record && record.bookings) {
    bookings = is_nbu
      ? record.bookings.filter(
        (v) => v.user_id === user.id || v.creator_id === user.id
      )
      : record.bookings; //.filter(v => v.user.member_type_id === member_type_id);
  }

  return (
    <React.Fragment>
      {book_pay_type && is_nbu && notShowPrice && (
        <div className="priceContentNBU">
          <div className="section-extra-prop">
            <span style={{ lineHeight: `12px` }}>
              {(parseInt(book_pay_type) < 1 || parseInt(book_pay_type) < 2) &&
                `$ ${parseFloat(price_per_hour).toFixed(1)}`}
              <br />
              {(parseInt(book_pay_type) < 1 || parseInt(book_pay_type) > 1) &&
                `P ${parseFloat(point_per_hour).toFixed(1)}`}
            </span>
          </div>
        </div>
      )}

      {type === "coach_availability" && business?.coach_dashboard&&(
        <div className="section-extra-prop">
          <span className="coachName">
          <b>{title}</b>
          </span>
         
        </div>
      )}
      {appointment && (
        <div>
          <span
            className={`circle-indicator ${paid ? "green-circle-indicator " :(authorized?"yellow-circle-indicator ":"red-circle-indicator") 
              }`}
          ></span>
          {hasAddon&&(
            <span
            className={`circle-indicator  circle-indicator-add-on blue-circle-indicator`}
          ></span>
          )}
          {record.tags && record.tags.length > 0 && (
            <div className="section-extra-prop">
              <b style={{ color: `${record.tags["0"].color}` }}>Spot Tag</b>
              <span style={{ color: `${record.tags["0"].color}` }}>
                {" "}
                {record.tags["0"].display_name}
              </span>
            </div>
          )}
          {business &&
            business.coach_dashboard &&
            record.assigned &&
            record.assigned.filter(
              (v) =>
                //v.member_type_id !== admin_type_id &&
                v.member_type_id !== member_type_id
            ).length > 0 && (
              <div className="section-extra-prop">
                <b>{" "}
                <span>
                  {" "}
                  {record.assigned.map((v) => v.first_name).join(", ")}
                </span>
                </b>
              </div>
            )}
          {business && business.lesson_dashboard && lesson_id && record.Lesson && (
            <div className="section-extra-prop">
              {/* <b>Lesson type</b>{" "} */}
              <b> {record.Lesson.LessonType.display_name}</b>
            </div>
          )}
          {business &&
            business.booked_dashboard &&
            bookings &&
            bookings.length > 0 && (
              <div className="section-extra-prop">
                <b>Booked by</b>{" "}
                <span>
                  {" "}
                  {bookings
                    .map((v) => `${v.user.first_name} ${v.user.last_name}`)
                    .join(", ")}{" "}
                </span>
              </div>
            )}
          <span>...</span>
        </div>
      )}
    </React.Fragment>
  );
};

class CourtSheet extends Component {
  constructor(props) {
    super(props);
    // props.timeZone
    this.withTimeZone = moment.tz.setDefault(props.timeZone);

    // this.localizer = momentLocalizer(this.withTimeZone);
    this.courtSheetRef = React.createRef();
    this.state = {
      start: null,
      end: null,
      courts: null,
      evtId: null,
      modalActive: false,
      delModal: null,
      selectedRanges: [],
      timeslots: 1,
    };
  }

  onEventResize = ({ event, start, end, resourceId, resourceTitle }) => {
    // this.openModal({ evtId: event.id, start, end, resourceId, resourceTitle });
  };

  onEventDrop = async ({
    event,
    start: _start,
    end: _end,
    resourceId,
    resourceTitle,
  }) => {
    if (event.type === "coach_availability") return;
    if (event.type === "microsettings") return;

    const court = this.props.courts.find((crt) => +crt.id === resourceId);
    const answer = await window.modal.cnfirm(
      ` Are you sure to move the event?`
    );

    if (answer) {
      const start = _start;
      const end = _end;

      this.props.updateEvent(
        event,
        {
          start: this.withTimeZone(start),
          end: this.withTimeZone(end),
          court_id: resourceId,
        },
        event.type
      );
    }
  };

  handleSelect = ({ start: _start, end: _end, resourceId, resourceTitle }) => {
    
    const { identity } = this.props;
    const roles = identity.member_type.roles;

    const start = _start;
    const end = _end;

    const is_coach = ["coach", "supercoach", "superdupercoach"].includes(
      identity.member_type.key
    );

    if (hasPermission(roles, "court", "update") && !is_coach) {
      this.openModal({ start, end, resourceId, resourceTitle });
    } else {
      return false;
    }
  };

  detectIfCoachAndFilter = (record) => {
    const user = this.props.identity;
    const currentDay = this.props.currentDay;
    const roles = user.member_type.roles;
    const is_coach = ["coach", "supercoach"].includes(user.member_type.key);
    const is_nbu = ["member"].includes(user.member_type.key);
    if (!record || Object.keys(record).length === 0) {
      return true;
    }

    if (hasPermission(roles, "court", "update") && !is_coach && !is_nbu) {
      return true;
    }

    let assigned = record.assigned;
    let lesson_id = record.lesson_id;
    let allow_booking_until = record.Lesson
      ? record.Lesson.allow_booking_until
      : null;
    let allowBooking = record.Lesson ? record.Lesson.allowBooking : null;
    const bookings = record.bookings;

    if (assigned && assigned.length > 0) {
      let _assigned = assigned.filter(
        (v) => parseInt(v.appointment_assign.user_id) === parseInt(user.id)
      );
      if (_assigned.length > 0) {
        return true;
      }
    }
    if (bookings && bookings.length > 0) {
      let _bookings = bookings.filter(
        (v) =>
          parseInt(v.user_id) === parseInt(user.id) ||
          parseInt(v.creator_id) === parseInt(user.id)
      );
      if (_bookings.length > 0) {
        return true;
      }
    }
    if (lesson_id && is_nbu) {
     
      if (allowBooking === "recurrence" && allow_booking_until && moment(allow_booking_until).isBefore(moment().format()) ) {
      
        return false;
      }
      return true;
    }
    return false;
  };

  openDeleteCase = (if_booked_in_recurrence = false, item) => {
    return new Promise((resolve, reject) => {
      this.setState({
        delModal: (
          <ModalDel
            business={this.props.business}
            if_booked_in_recurrence={if_booked_in_recurrence}
            item={item}
            for_remove={true}
            resolve={resolve}
            closeDell={this.closeDell}
          />
        ),
      });
    });
  };

  closeDell = () => {
    this.setState({
      delModal: null,
    });
  };

  closeModal = () => {
    this.setState({
      start: null,
      end: null,
      courts: null,
      evtId: null,
      modalActive: false,
    });
    this.props.detectModalActive(false);
  };

  openModal = ({ start, end, resourceId, resourceTitle, evtId }) => {
    const { timeZone } = this.props;
    const dValue = start;

    const mValue = moment.tz(
      {
        year: dValue.year(),
        month: dValue.month(),
        date: dValue.date(),
        hour: dValue.hour(),
        minute: dValue.minute(),
      },
      timeZone
    );
    const line = moment.tz(timeZone);

    const diff = mValue.diff(line, "minutes");

    if (diff <= -this.props.business.booking_to_start) {
      return;
    }

    const court = this.props.courts.find((crt) => +crt.id === resourceId);

    this.setState({
      start: this.withTimeZone(start),
      end: this.withTimeZone(end),
      courts: [
        {
          id: resourceId,
          title: court ? court.name : "Unnamed",
        },
      ],
      evtId,
      modalActive: true,
    });
    this.props.detectModalActive(true);
  };

  submitData = (data) => {
    const id = this.state.evtId;
    if (id) {
      this.props.updateEvent(id, data);
    } else {
      this.props.addEvent(data);
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.reset) {
      this.setState({
        selectedRanges: [],
      });
    }
  }
  ordinalDateText=(number) =>{
    const b = number % 10;
    const output = (~~ (number % 100 / 10) === 1) ? 'th' :
            (b === 1) ? 'st' :
            (b === 2) ? 'nd' :
            (b === 3) ? 'rd' : 'th';
        return number + output;
  }
  prepareUrl = (event) => {
    const {
      id,
      is_lesson,
      resourceId,
      start,
      end,
      start_time,
      end_time,
    } = event;

    const { family } = this.props;

    let url = `/private/courtlisting/${id}/${resourceId}?family=${family || ""
      }`;
    if (is_lesson) {
      url = `/private/lesson/${id}?family=${family || ""}`;
    }
    if (isNaN(id)) {
      const _start_time = this.withTimeZone(start_time).utc().format();
      const _end_time = this.withTimeZone(end_time).utc().format();
      let _start = moment(this.withTimeZone(start));
      let _end = moment(this.withTimeZone(end));
      
      const court =
        this.props.courts.find((i) => i.id === event.resourceId) || {};

      if (court.enable_time_to_start_booking) {
        if (_start.minutes() !== court.time_to_start_booking) {
          _start.set({ minute: court.time_to_start_booking });

          const min_list_duration =
            court.min_list_duration ||
            this.props.user?.business?.min_list_duration ||
            0;

          _end = _start.clone().add(min_list_duration, "minutes");
         
        }
      }
     
      _start = _start.utc().format();
      _end = _end.utc().format();
 
      url = `/private/time_rule/-1/${resourceId}/${_start_time}/${_end_time}/${_start}/${_end}?family=${family || ""
        }`;
    }
    return url;
  };

  render() {
    const { modalActive, delModal, timeslots } = this.state;
    const {
      events,
      courts,
      currentDay,
      timeZone,
      identity,
      dateFormat,
      business,
      family,
      coachAvailabilityList,
    } = this.props;
    
  
    
    const resources = courts.map((court) => ({
      resourceId: +court.id,
      resourceTitle: (
        <ResourceTitleComp
          roles={identity.member_type.roles}
          name={court.name}
          id={court.id}
          handleSelect={this.handleRangeSelect}
        />
      ),
    }));

    const csh_start_time =
      business && business.csh_start_time
        ? business.csh_start_time.split("__")
        : null;
    const min_date = !csh_start_time
      ? new Date(
        currentDay.year(),
        currentDay.month(),
        currentDay.date(),
        0,
        0,
        0
      )
      : new Date(
        currentDay.year(),
        currentDay.month(),
        currentDay.date(),
        csh_start_time["0"],
        csh_start_time[1],
        0
      );

    const __date = new Date(
      currentDay.year(),
      currentDay.month(),
      currentDay.date(),
      currentDay.hours(),
      currentDay.minutes(),
      currentDay.seconds(),
      currentDay.milliseconds()
    );

    const formattedEvents = [];
    coachAvailabilityList.forEach((court) => {
      court.ranges.forEach((evt) => {
        const id = parseInt(evt.group_id);
        let type = "coach_availability";

        const startTime = evt.start_time;
        const endTime = evt.end_time;
        const range_start_time = evt.range_start_time;
        const range_end_time = evt.range_end_time;

        let correctedEndDate = this.withTimeZone(endTime).toDate();
        let hourMinute = moment(correctedEndDate).format("HH:mm:ss");
        if (hourMinute == "00:00:00") {
          correctedEndDate = this.withTimeZone(
            new Date(new Date(endTime).getTime() - 0.1)
          ).toDate();
        }

        let data_for_push = {
          id: isNaN(id) ? makeid(5) : id,
          type,
          title:(
            this.props.coachList.find((i) => i.coach_id == court.coach_id) 
          )?.coach_name|| (
            this.props.coachList.find((i) => i.id == court.coach_id) || {
              displayName: "Loading...",
            }
          ).displayName,
          start: this.withTimeZone(startTime).toDate(),
         // end: this.withTimeZone(endTime).toDate(),
          end: correctedEndDate,
          start_time: this.withTimeZone(range_start_time).toDate(),
          end_time: this.withTimeZone(range_end_time).toDate(),
          resourceId: +evt.court_id,
          range_start_time: range_start_time,
          range_end_time: range_end_time,
          rules: court.rules.map((v) => v.group_id),
          color: evt.block ? "#FF0000" : "#FFFF00",
          is_nbu: ["member"].includes(this.props.identity.member_type.key),
          user: this.props.identity,
          currency_code: business && business.currency_code,
          status: evt.block ? "disabled" : "default",
          record: {},
          original_coachAvail: court,
          original_range: evt,
          showAsWhite: !!evt.status,
          blocked: !!evt.block,
        };

        formattedEvents.push({
          ...data_for_push,
        });
      });
    });
    
    events.forEach((court) => {
      court.ranges.forEach((evt,index) => {
        
        const id = parseInt(evt.appointment_id);
        const lesson_id = parseInt(evt.lesson_id);
        let correctedEndDate = this.withTimeZone(evt.end_time).toDate();
        let type = "lesson";

        if (!isNaN(lesson_id)) {
          type = "lesson";
        } else if (!isNaN(id)) {
          type = "appointment";
        } else {
          
          type = "microsettings";
        }
        let hourMinute = moment(correctedEndDate).format("HH:mm:ss");

        if (hourMinute == "00:00:00") {
          correctedEndDate = this.withTimeZone(
            new Date(new Date(evt.end_time).getTime() - 0.1)
          ).toDate();
        }
        let eventStartTime = evt.start_time
        let rangeStartTime =evt.range_start_time
        let rangeEndTime =evt.range_end_time
        
       
        if(type == "microsettings"){ 
          evt.ranges?.forEach((evt_childe) => {
           
            correctedEndDate = this.withTimeZone(evt_childe.end_time).toDate();
            if (hourMinute == "00:00:00") {
              correctedEndDate = this.withTimeZone(
                new Date(new Date(evt_childe.end_time).getTime() - 0.1)
              ).toDate();
            }
            eventStartTime = evt_childe.start_time
            rangeStartTime  = evt_childe.start_time
            rangeEndTime =evt_childe.end_time
            
            let data_for_push = {
              id: isNaN(id) ? makeid(5) : id,
              type,
              group_id: evt.group_id,
              lesson_id: isNaN(lesson_id) ? null : lesson_id,
              is_lesson: isNaN(lesson_id) ? false : true,
              title: "",
              recurrence_id: evt.recurrence_id,
              global_recurrence_id: evt.global_recurrence_id,
              if_booked_in_recurrence: evt.if_booked_in_recurrence,
              start: this.withTimeZone(eventStartTime).toDate(),
              end: correctedEndDate,
              originEndTime:evt.end_time,
              start_time: this.withTimeZone(rangeStartTime).toDate(),
              end_time: this.withTimeZone(rangeEndTime).toDate(),
              resourceId: +evt.court_id,
              rules: court.rules.map((v) => v.group_id),
              range_start_time: rangeStartTime,
              range_end_time: rangeEndTime,
              status: evt.status || "default",
              record: evt.record || {},
              color: evt.color,
              is_nbu: ["member"].includes(this.props.identity.member_type.key),
              user: this.props.identity,
              appointment: evt.appointment,
              currency_code: business && business.currency_code,
            };
    
            if (evt.appointment) {
              data_for_push = {
                ...data_for_push,
                business: {
                  coach_dashboard: business && business.coach_dashboard,
                  lesson_dashboard: business && business.lesson_dashboard,
                  booked_dashboard: business && business.booked_dashboard,
                },
              };
            } else {
              data_for_push = {
                ...data_for_push,
                book_pay_type: evt_childe.book_pay_type,
                price_per_hour: evt_childe.pricePerHour,
                point_per_hour:evt_childe.pointPerHour,
              };
            }
    
            formattedEvents.push({
              ...data_for_push,
            });
            
          });
        }else{
          let data_for_push = {
            id: isNaN(id) ? makeid(5) : id,
            type,
            group_id: evt.group_id,
            lesson_id: isNaN(lesson_id) ? null : lesson_id,
            is_lesson: isNaN(lesson_id) ? false : true,
            title: "",
            recurrence_id: evt.recurrence_id,
            global_recurrence_id: evt.global_recurrence_id,
            if_booked_in_recurrence: evt.if_booked_in_recurrence,
            start: this.withTimeZone(eventStartTime).toDate(),
            end: correctedEndDate,
            originEndTime:evt.end_time,
            start_time: this.withTimeZone(rangeStartTime).toDate(),
            end_time: this.withTimeZone(rangeEndTime).toDate(),
            resourceId: +evt.court_id,
            rules: court.rules.map((v) => v.group_id),
            range_start_time: rangeStartTime,
            range_end_time: rangeEndTime,
            status: evt.status || "default",
            record: evt.record || {},
            color: evt.color,
            is_nbu: ["member"].includes(this.props.identity.member_type.key),
            user: this.props.identity,
            appointment: evt.appointment,
            currency_code: business && business.currency_code,
          };
  
          if (evt.appointment) {
            data_for_push = {
              ...data_for_push,
              business: {
                coach_dashboard: business && business.coach_dashboard,
                lesson_dashboard: business && business.lesson_dashboard,
                booked_dashboard: business && business.booked_dashboard,
              },
            };
          } else {
            data_for_push = {
              ...data_for_push,
              book_pay_type: evt.ranges?.["0"].book_pay_type,
              price_per_hour: evt.ranges?.["0"].pricePerHour,
              point_per_hour: evt.ranges?.["0"].pointPerHour,
            };
          }
  
          formattedEvents.push({
            ...data_for_push,
          });
        }
       // let correctedEndDate = this.withTimeZone(evt.end_time).toDate();
       

        
      });
    });

    const { booking_to_start = 60 } = business || {};

    const user = this.props.identity;
    const roles = user && user.member_type ? user.member_type.roles : [];
    const userType =
      user && user.member_type && user.member_type.key
        ? user.member_type.key
        : "owner";

    return (
      <div className="court-sheet">
        {resources.length ? (
          <TimeZoneAgnosticBigCalendar
            date={__date}
            min={min_date}
            getNow={() => {
              const zonedTime = moment.tz(timeZone);
              return new Date(
                zonedTime.year(),
                zonedTime.month(),
                zonedTime.date(),
                zonedTime.hour(),
                zonedTime.minute()
              );
            }}
           
            timeZone={timeZone}
            events={formattedEvents}
            resources={resources}
            timeZoneName={this.localizer}
            onEventDrop={this.onEventDrop}
            onEventResize={this.onEventResize}
            onSelectSlot={this.handleSelect}
            onSelectEvent={(event, el) => {
              if (
                !this.detectIfCoachAndFilter(event.record) ||
                event.showAsWhite ||
                event.blocked
              )
                return;
               
              const start_event = moment(event.start);
              const end_event = moment(event.originEndTime);
              const end_event_forPrivete = moment(event.end);
              
              const event_height = el.nativeEvent.srcElement.classList.contains(
                "rbc-event " 
              )
                ? el.nativeEvent.srcElement.offsetHeight
                : el.nativeEvent.srcElement.parentElement.offsetHeight;

              const duration_in_min = moment
                .duration(moment(event.end).diff(start_event))
                .asMinutes();

              const minute_height = event_height / duration_in_min;

              const clicked_position = el.nativeEvent.offsetY;

              const minutes_in_click = Math.round(
                clicked_position / minute_height
              );
              
              const start_by_click = (minutes_in_click===parseInt(minutes_in_click, 10)?start_event
              .clone()
              .add(minutes_in_click, "minutes"):start_event);
               
              const leftover = minutes_in_click % booking_to_start;
             
              let new_start_time = start_by_click
                .clone()
                .subtract(leftover, "minutes");

              if (new_start_time.isSameOrBefore(start_event)) {
                new_start_time = start_event;
              }

              const court =
                this.props.courts.find((i) => i.id === event.resourceId) || {};

              const min_list_duration =
                court.min_list_duration || business.min_list_duration;

              let new_end_time = new_start_time
                .clone()
                .add(min_list_duration, "minutes");
               
              if (new_end_time.isAfter(end_event)) {
                new_end_time = end_event_forPrivete;
              }
            
              
              if (event.type === "coach_availability") {
                this.props.onCoachAvailabilityBook({
                  court_id: event.resourceId,
                  user_id: event.user.id,
                  coach_id: event.original_coachAvail.coach_id,
                  lesson_type_id: event.original_range.lesson_type_id,
                  sport_id: event.original_range.sport_id,
                  group_id: event.original_range.group_id,
                  start: this.withTimeZone(new_start_time).utc().format(),
                  end: this.withTimeZone(new_end_time).utc().format(),
                  start_time: this.withTimeZone(event.start).utc().format(),
                  end_time: this.withTimeZone(event.end).utc().format(),
                  currentDay: this.props.currentDay.clone(),
                });
                return;
              }

              if (
                !(
                  hasPermission(roles, "court", "update") ||
                  userType === "member"
                )
              ) {
                window.modal.alert("Info", "Your Assigned Role Is View Only");
                return;
              }

              const url = this.prepareUrl({
                ...event,
                start: new_start_time,
                end: new_end_time,
              });

              this.props.history.push(url);
            }}
            onNavigate={() => { }}
            step={booking_to_start}
            timeslots={timeslots}
            // scrollToTime={'00:00'}
            formats={{
              timeGutterFormat: (date, culture, localizer) => {
                return localizer.format(date, dateFormat, culture);
              },
              eventTimeRangeFormat: ({ start, end }, culture, localizer) => {
                const s = localizer.format(start, dateFormat, culture);
                const e = localizer.format(end, dateFormat, culture);
                return `${s} - ${e}`;
              },
              agendaTimeRangeFormat: ({ start, end }, culture, localizer) => {
                const s = localizer.format(start, dateFormat, culture);
                const e = localizer.format(end, dateFormat, culture);
                return `${s} - ${e}`;
              },
              dayRangeHeaderFormat: ({ start, end }, culture, localizer) => {
                const s = localizer.format(start, "MMM DD", culture);
                const e = localizer.format(end, "MMM DD", culture);
                return `${s} - ${e}`;
              },
            }}
            showMultiDayTimes
            selectable
            resizable={false}
            eventPropGetter={(event) => {
              let color = event.color
                ? event.color
                : event.appointment
                  ? "#d4ffbe"
                  : "#dff0ff";
              color = event.status === "disabled" ? "#FF605C" : color;

              const backgroundColor = hexToRgbA(
                color,
                !["microsettings", "coach_availability"].includes(event.type)
                  ? 1
                  : 0.65
              );

              const style = {
                backgroundColor: backgroundColor,
               
              };
            
              return {
                style: style,
                //className: `rbc-event--${event.status} ${(event.original_range ? "rbc-event-booked": "rbc-event-blocked")} `,
                className: `rbc-event--${event.status} `,
              };
            }}
            slotPropGetter={(date, resourceId) => {
              const _date = convertDateToZonedDate(date, timeZone);
              const _now = moment.tz(timeZone); //.startOf("hour");
              const diff = _date.diff(_now, "minutes");
              return {
                className: diff <= -booking_to_start ? "rbc-off-range-bg" : "",
              };
            }}
           
            components={{
              event: EventContent,
              toolbar: (props) => {
                const mDate = props.date;
                return (
                  <div className="rbc-toolbar">
                    <span className="rbc-btn-group">
                      <div className="refresh">
                        <button
                          type="button"
                          disabled={this.props.loadingListing}
                          onClick={this.props.refresh}
                          title="Data will refresh automatically and instantly. You can also refresh anytime by a click on
                          Refresh button."
                        >
                          <img src="/assets/img/refresh.png" alt="refresh" />
                          <span>Refresh</span>
                        </button>
                      </div>
                    </span>
                    <span className="rbc-toolbar-label">
                      {/* {props.localizer.format(mDate, " D")} */}
                      {props.localizer.format(mDate, `dddd, MMMM [${this.ordinalDateText(props.localizer.format(mDate,"D"))}] YYYY`)}
                    </span>
                    <span className="rbc-btn-group">
                      <button
                        type="button"
                        className="btn"
                        title="Zoom Out"
                        onClick={() => {
                          this.setState((st) => {
                            let { timeslots } = st;

                            timeslots += 1;

                            timeslots = Math.min(timeslots, 4);

                            return {
                              ...st,
                              timeslots,
                            };
                          });
                        }}
                      >
                        -
                      </button>

                      <button
                        type="button"
                        className="btn"
                        title="Zoom In"
                        onClick={() => {
                          this.setState((st) => {
                            let { timeslots } = st;

                            timeslots -= 1;

                            timeslots = Math.max(timeslots, 1);

                            return {
                              ...st,
                              timeslots,
                            };
                          });
                        }}
                        style={{ marginRight: "10px" }}
                      >
                        +
                      </button>

                      <button
                        type="button"
                        className="btn"
                        title="Scroll court-sheet to left"
                        onClick={() => {
                          this.courtSheetRef.current.scrollLeft();
                        }}
                      >
                        {"<"}
                      </button>
                      <button
                        type="button"
                        className="btn"
                        title="Scroll court-sheet to right"
                        onClick={() => {
                          this.courtSheetRef.current.scrollRight();
                        }}
                      >
                        {">"}
                      </button>
                    </span>
                  </div>
                );
              },
              
              eventWrapper: (props) => {
              
                // Some data that you might have inserted into the event object
                const event = props.event;
                const record = event.record;

                const children = props.children;
                const dValue = moment(event.end);
                const dsValue = moment(event.start);
                const mValue = moment.tz(
                  {
                    year: dValue.year(),
                    month: dValue.month(),
                    date: dValue.date(),
                    hour: dValue.hour(),
                    minute: dValue.minute(),
                  },
                  timeZone
                );
                const now = moment.tz(timeZone);
                const diff = mValue.diff(now, "minutes");
                const diff_time = mValue.diff(dsValue, "minutes");
                
                const iOS = !window.MSStream && /iPad|iPhone|iPod/.test(navigator.userAgent);
                return (
                  <ContextMenuTrigger
                    holdToDisplay={iOS?1800:-1}
                   
                    id={
                      event.type === "coach_availability"
                        ? "event_coach_availability_context_menu"
                        : "event_context_menu"
                    }
                    collect={(props) => {
                      return { event };
                    }}
                  >
                    <div
                      className={`colored-event

                      ${this.detectIfCoachAndFilter(record)
                          ? `ok-style`
                          : this.props.coachAvailabilityList.length
                            ? `white-style`
                            : "d-none"
                        }

                      ${event.showAsWhite ? `white-style` : ""}

                      status-${event.status}
                      ${diff < 0 ? "disabled-event" : ""}

                      ${
                        // TODO: discus with team
                        Math.abs(diff_time) <= booking_to_start
                          ? "small-event"
                          : ""
                        } ${event.type}`}
                    >
                      {children}
                    </div>
                  </ContextMenuTrigger>
                );
              },
            }}
            defaultView={Views.DAY}
            views={["day"]}
            resourceIdAccessor="resourceId"
            resourceTitleAccessor="resourceTitle"
            ref={this.courtSheetRef}
          />
        ) : (
          <Loading />
        )}

        <ModalDashboard
          submit={this.submitData}
          data={{
            start: this.state.start,
            end: this.state.end,
            courts: this.state.courts,
          }}
          permission={hasPermission(roles, "lesson", "update")}
          coachList={this.props.coachList}
          modalClass={modalActive}
          closeModal={this.closeModal}
          currentDay={currentDay}
          timeZone={timeZone}
          getListings={this.props.getListings}
        />
  
        <ContextMenu id="event_coach_availability_context_menu">
          {this.props.identity.user_type !== "nbu" && (
            <MenuItem
              className="nav-a"
              onClick={(xz, { event }) => {
                if (event.original_range.block) {
                  this.props.unBlockCoachAvailability({
                    startDate: this.withTimeZone(event.start),
                    endDate: this.withTimeZone(event.end),
                    coach_id: event.original_coachAvail.coach_id,
                  });
                } else {
                  this.props.blockCoachAvailability({
                    startDate: this.withTimeZone(event.start),
                    endDate: this.withTimeZone(event.end),
                    coach_id: event.original_coachAvail.coach_id,
                  });
                }
              }}
            >
              Block/Unblock
            </MenuItem>
          )}
        </ContextMenu>

        <ContextMenu id="event_context_menu">
          {this.props.identity.user_type !== "nbu" && (
            <MenuItem
              onClick={async (xz, { event }) => {
                const is_appointment = event.appointment;
                const recurrence_id = event.recurrence_id
                  ? event.recurrence_id
                  : null;
                const global_recurrence_id = event.global_recurrence_id
                  ? event.global_recurrence_id
                  : null;
                const if_booked_in_recurrence = event.if_booked_in_recurrence
                  ? event.if_booked_in_recurrence
                  : false;

                let clid = event.id;

                if (is_appointment) {
                  const deltypeObj = await this.openDeleteCase(
                    if_booked_in_recurrence,
                    event
                  );
                  const deltype = parseInt(deltypeObj.event) || 0;
                  const automatically_refund_payment =
                    deltypeObj.automatically_refund_payment;
                  if (deltype > -1) {
                    if (deltype === 0) {
                      await this.props.deleteAppointmentFromCourt({
                        clid: clid,
                        automatically_refund_payment: automatically_refund_payment,
                      });
                    } else {
                      if (deltype === 2 || deltype === 4) {
                        clid = -1;
                      }
                      await this.props.deleteRequrence({
                        id: clid,
                        keep_booked: deltype === 4,
                        automatically_refund_payment: automatically_refund_payment,
                        recurrence_id: recurrence_id,
                        //  global_recurrence_id: global_recurrence_id
                      });
                    }
                  }
                } else {
                  if (
                    await window.modal.cnfirm(
                      "Confirm",
                      "Are you sure to delete the listing?"
                    )
                  ) {
                    await this.props.deleteTimeRuleFromCourt({
                      court_id: event.resourceId,
                      start_time: moment(event.start_time)
                        .clone()
                        .utc()
                        .format(),
                      end_time: moment(event.end_time).clone().utc().format(),
                    });
                  }
                }
              }}
            >
              Delete
            </MenuItem>
          )}
          <MenuItem
            className="nav-a"
            onClick={(xz, { event }) => {
              if (!this.detectIfCoachAndFilter(event.record)) return;

              const url = this.prepareUrl(event);

              this.props.history.push(url);
            }}
          >
            View Details
          </MenuItem>
        </ContextMenu>
        {delModal}
      </div>
    );
  }
}

CourtSheet.defaultProps = {
  dateFormat: "hh:mm A",
};

CourtSheet.propTypes = {
  courts: PropTypes.arrayOf(PropTypes.object).isRequired,
  events: PropTypes.arrayOf(PropTypes.object).isRequired,
  addEvent: PropTypes.func.isRequired,
  updateEvent: PropTypes.func.isRequired,
  currentDay: PropTypes.shape({}).isRequired,
  timeZone: PropTypes.string.isRequired,
  dateFormat: PropTypes.string.isRequired,
};

export default withRouter(CourtSheet);
