import React from 'react';
import styled from 'styled-components';

const Label = styled.label`
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;
  display: inline-block;
  border-radius: 12px;
  border: none;
  margin: 5px;
`;

const Switch = styled.input.attrs({ type: 'checkbox' })`
  opacity: 0;
  width: 0;
  height: 0;
`;

const Slider = styled.span`
    position: absolute;
    cursor: pointer;
    top: 6px;
    left: 0;
    right: 0;
    bottom: 0;
    height: 12px;
    background-color: #C6C5C5;
    -webkit-transition: .4s;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 12px
  ${Switch}:checked + & {
    background-color: #C7E588;
  }
  &::before{
    position: absolute;
    content: "";
    width: 22px;
    height: 22px;
    left: 0px;
    top: -5px;
    background-image: none;
    background-color: #8D8989;
    opacity: 1;
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
    border-radius: 50%;
    ${Switch}:checked + & {
        left: 24px;
        background-color: #9BD12A;
    }
  }
`;

export default function GreenSwitcher(props) {
  return (
    <Label>
      <Switch {...props} />
      <Slider />
    </Label>
  )
}
