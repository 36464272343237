import createAction from './create-action';
import axios from '../config/axios';
import {
  TAG_LOADING,
  GET_TAGS_SUCCESS,
  GET_TAG_SUCCESS,
  ADD_TAG_SUCCESS,
  UPDATE_TAG_SUCCESS,
  DELATE_TAG_SUCCESS,
  RESET_TAG,
  RESET_TAGS,
} from '../constants/Tag';

export const GetTags = (query = {}) => async dispatch => {
  dispatch(createAction(TAG_LOADING, true));

  try {
    const {
      data: { payload },
    } = await axios.get('tags/get', {
      params: query,
    });

    // const payload = getMocks(!!query.archived);

    dispatch(createAction(GET_TAGS_SUCCESS, payload));
    return true;
  } catch (error) {
    console.log(error);
    dispatch(createAction(ADD_TAG_SUCCESS, []));
    //await window.modal.alert('Error', error.response.data.message);
  } finally {
    dispatch(createAction(TAG_LOADING, false));
  }

  return false;
};

export const GetTag = id => async dispatch => {
  dispatch(createAction(TAG_LOADING, true));

  try {
    const {
      data: { payload },
    } = await axios.get(`tags/get/${id}`);

    // const payload = getMock(id);

    dispatch(createAction(GET_TAG_SUCCESS, payload));
    return true;
  } catch (error) {
    console.log(error);
    //await window.modal.alert('Error', error.response.data.message);
  } finally {
    dispatch(createAction(TAG_LOADING, false));
  }

  return false;
};

export const AddTag = data => async dispatch => {
  dispatch(createAction(TAG_LOADING, true));

  try {
    const {
      data: { payload },
    } = await axios.post(`tags/add`, data);

    // const payload = addMock(data);

    dispatch(createAction(ADD_TAG_SUCCESS, payload));

    return await dispatch(GetTags());
  } catch (error) {
    console.log(error);
    //await window.modal.alert('Error', error.response.data.message);
  } finally {
    dispatch(createAction(TAG_LOADING, false));
  }

  return false;
};

export const UpdateTag = (id, data) => async dispatch => {
  dispatch(createAction(TAG_LOADING, true));

  try {
    const {
      data: { payload },
    } = await axios.put(`tags/update/${id}`, data);

    // const payload = updateMock(id, data);

    dispatch(createAction(UPDATE_TAG_SUCCESS, payload));
    return true;
  } catch (error) {
    console.log(error);
    //await window.modal.alert('Error', error.response.data.message);
  } finally {
    dispatch(createAction(TAG_LOADING, false));
  }

  return false;
};

export const DelTag = id => async dispatch => {
  dispatch(createAction(TAG_LOADING, true));

  try {
    const {
      data: { payload },
    } = await axios.put(`tags/archive/${id}`);

    // const payload = deleteMock(id);

    dispatch(
      createAction(DELATE_TAG_SUCCESS, {
        id,
      })
    );
    return true;
  } catch (error) {
    console.log(error);
    //await window.modal.alert('Error', error.response.data.message);
  } finally {
    dispatch(createAction(TAG_LOADING, false));
  }

  return false;
};
