export const ADD_COACH_SUCCESS = 'ADD_COACH_SUCCESS';
export const ADD_COACH_LOADING = 'ADD_COACH_LOADING';
export const GET_COACH_SUCCESS = 'GET_COACH_SUCCESS';
export const GET_COACH_SUCCESS_MOBILE = 'GET_COACH_SUCCESS_MOBILE';
export const GET_HIDDEN_COACH_SUCCESS = 'GET_HIDDEN_COACH_SUCCESS';
export const GET_COACH_LOADING = 'GET_COACH_LOADING';
export const UPDATE_COACH_LOADING = 'UPDATE_COACH_LOADING';
export const GET_MEMBER_TYPE_SUCCESS = 'GET_MEMBER_TYPE_SUCCESS';
export const ADD_PAYMANT_SUCCESS_STAFF = 'ADD_PAYMANT_SUCCESS_STAFF';
export const CHANGE_PAYMANT_SUCCESS_STAFF = 'CHANGE_PAYMANT_SUCCESS_STAFF';
export const REMOVE_PAYMANT_SUCCESS_STAFF = 'REMOVE_PAYMANT_SUCCESS_STAFF';
export const GET_CUSTOMER_STAFF_SUCCESS = 'GET_CUSTOMER_STAFF_SUCCESS';
export const GET_STAFF_BALANCE_SUCCESS = 'GET_STAFF_BALANCE_SUCCESS';
export const GET_STAFF_BOOKING_SUCCESS = 'GET_STAFF_BOOKING_SUCCESS';
