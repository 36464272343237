import React, { Component } from "react";

import FormGroupNew from "../../components/FormGroupNew";
import { sendDemo } from "../../actions/util";
import "./styles/request-demo.scss";

class RequestDemo extends Component {
  constructor(props) {
    const _randomNr1 = Math.floor(Math.random() * 10);
    const _randomNr2 = Math.floor(Math.random() * 10);
    super(props);
    this.state = {
      fullName: "",
      email: "",
      company: "",
      jobTitle: "",
      message: "",
      error: {},
      total_math: "",
      totalNr: _randomNr1 + _randomNr2,
      randomNr1: _randomNr1,
      randomNr2: _randomNr2,
    };
    this.HandleChange = this.HandleChange.bind(this);

    this.hendlerCangeText = this.hendlerCangeText.bind(this);
  }

  HandleChange(e) {
    const { [e.target.name]: error } = this.checkValidation(
      Object.assign(this.state, { [e.target.name]: e.target.value })
    );
    this.setState({
      [e.target.name]: e.target.value,
      error: Object.assign(this.state.error, { [e.target.name]: error }),
    });
  }

  request = async () => {
    const error = this.checkValidation(this.state);
    if (error.success) {
      const bool = await sendDemo(this.state); //await Promise.resolve(true);//
      if (bool) {
        this.setState({
          fullName: "",
          email: "",
          company: "",
          jobTitle: "",
          message: "",
          error: {},
          total_math: "",
        });
        if (this.props.detectSent) {
          this.props.detectSent(true);
        }
      }
    } else {
      this.setState({ error: Object.assign(this.state.error, error) });
    }
  };

  hendlerCangeText(e) {
    const { name, value } = e.target;
    const { [e.target.name]: error } = this.checkValidation(
      Object.assign(this.state, { [e.target.name]: e.target.value })
    );
    if (typeof value === "string" && value.length <= 210) {
      this.setState({
        [name]: value,
        error: Object.assign(this.state.error, { [e.target.name]: error }),
      });
    }
  }

  checkValidation(state) {
    const {
      fullName,
      email,
      company,
      jobTitle,
      message,
      total_math,
      totalNr,
    } = state;
    const error = { success: true };
    const onlyNumbers = /^\+?[0-9]{0,17}$/gi;
    const regexpEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!fullName || fullName.length < 2) {
      error.fullName = true;
      error.success = false;
    } else {
      error.fullName = false;
    }

    if (!RegExp(regexpEmail).test(email.trim())) {
      error.email = true;
      error.success = false;
    } else {
      error.email = false;
    }

    if (!company) {
      error.company = true;
      error.success = false;
    } else {
      error.company = false;
    }
    if (!jobTitle) {
      error.jobTitle = true;
      error.success = false;
    } else {
      error.jobTitle = false;
    }
    // if (!message || message.length < 2 || message.length > 210) {
    //     error.message = true;
    //     error.success = false;
    // } else {
    //     error.message = false;
    // }

    // if (!total_math || parseInt(total_math) !== parseInt(totalNr)) {
    //     error.total_math = true;
    //     error.success = false;
    // } else {
    //     error.total_math = false;
    // }
    return error;
  }

  mathCaptcha = () => {
    return (
      "I am not a robot. What is the sum of " +
      this.state.randomNr1 +
      " + " +
      this.state.randomNr2
    );
  };

  render() {
    const {
      fullName,
      email,
      company,
      jobTitle,
      message,
      total_math,
    } = this.state;
    const {
      fullName: RfullName,
      email: Remail,
      company: Rcompany,
      jobTitle: RjobTitle,
      message: Rmessage,
      total_math: Rtotal_math,
    } = this.state.error;

    const math_captcha = this.mathCaptcha();

    return (
      <div className="schedule-demo">
        <FormGroupNew
          className={`${RfullName ? "error" : ""}`}
          type="text"
          name="fullName"
          placeholder="Full Name"
          onChange={this.HandleChange}
          value={fullName}
        />

        <FormGroupNew
          className={`${Remail ? "error" : ""}`}
          type="text"
          name="email"
          placeholder="Email Address"
          onChange={this.HandleChange}
          value={email}
        />

        <FormGroupNew
          className={`${Rcompany ? "error" : ""}`}
          type="text"
          name="company"
          placeholder="Club Name"
          onChange={this.HandleChange}
          value={company}
        />
        <FormGroupNew
          className={`${RjobTitle ? "error" : ""}`}
          type="text"
          name="jobTitle"
          placeholder="Job Title"
          onChange={this.HandleChange}
          value={jobTitle}
        />

        <FormGroupNew
          parentClassName={"large-input"}
          className={`optional-field ${Rmessage ? "error " : ""}`}
          type="text"
          id="messageId"
          name="message"
          onChange={this.hendlerCangeText}
          placeholder="Message (optional)"
          value={message}
        />
        {/* <FormGroupNew
                    className={Rtotal_math ? 'error' : ''}
                    type="text"
                    name="total_math"
                    onChange={this.HandleChange}
                    placeholder={math_captcha}
                    value={total_math}
                /> */}

        <div className="signin">
          <button type="button" onClick={this.request}>
            Schedule
          </button>
        </div>
      </div>
    );
  }
}
export default RequestDemo;
