import React, { Component } from "react";
import Picky from "react-picky";

import Tax from "../../components/Forms/Tax";
import TaxRateModal from "../../components/TaxRateModal";
import TaxRateList from "../../components/TaxRateList";
import { connect } from "react-redux";
import { updatetaxRate } from "../../actions/Auth";

class Taxes extends Component {
  constructor(props) {
    super(props);
    const { tax, default_value } = props;
    this.state = {
      openedModal: null,
      selected_taxes: default_value
        ? tax && tax.filter((v) => default_value.includes(v.tax_id))
        : [],
    };
  }

  closeModal = () => {
    this.setState({ openedModal: null });
  };

  openTaxRateModal = () => {
    this.setState({
      openedModal: <TaxRateModal clouseModal={this.closeModal} />,
    });
  };

  openTaxList = () => {
    const { tax } = this.props;
    this.setState({
      openedModal: (
        <TaxRateList selected_taxes={tax} clouseModal={this.closeModal} />
      ),
    });
  };

  SetTaxNew = (id = null, taxModel) => {
    this.props.SetTax(null, taxModel);
  };

  selectTax = (value) => {
    const { selectTax } = this.props;
    this.setState({ selected_taxes: value });
    selectTax(value);
  };

  archiveTaxRate = async (tax_rate) => {
    if (
      await window.modal.cnfirm(
        "Confirm",
        `Are you sure to remove tax rate <b>${tax_rate.display_name}</b> ?`
      )
    ) {
      await this.props.SetTax(tax_rate.id, null, true);
    }
  };

  render() {
    const { goods, tax } = this.props;
    const { selected_taxes, openedModal } = this.state;
    return (
      <div className="taxes">
        <div className="taxParth">
          {!goods && tax.length === 0 ? (
            <Tax setTax={this.SetTaxNew} />
          ) : !goods && tax.length > 1 ? (
            <div className="tax-flexbox">
              <div className="flex-col-50">
                <div className="mod-item">
                  <div className="lesson-form-group">
                    <label>Select tax rate </label>
                    <div className="picky-container">
                      <Picky
                        includeSelectAll={true}
                        options={tax}
                        value={selected_taxes}
                        valueKey="id"
                        labelKey="display_name"
                        multiple={true}
                        numberDisplayed={1}
                        includeFilter={false}
                        onChange={this.selectTax}
                        placeholder="Select"
                        dropdownHeight={350}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex-col-25">
                <p
                  onClick={this.openTaxRateModal}
                  className="taxrateClass"
                  style={{ textAlign: "right", paddingBottom: "0px" }}
                >
                  Add tax rate %
                </p>
              </div>
              <div className="flex-col-25">
                <p
                  onClick={this.openTaxList}
                  className="taxrateClass"
                  style={{ textAlign: "right", paddingBottom: "0px" }}
                >
                  Tax list
                </p>
              </div>
            </div>
          ) : null}
          {!goods && tax.length === 1 ? (
            <div className="tax-flexbox">
              <div className="flex-col-50">
                <p
                  onClick={this.openTaxRateModal}
                  className="taxrateClass"
                  style={{ textAlign: "right", paddingBottom: "0px" }}
                >
                  Add tax rate {tax.map((v) => v.percentage).join("")}%
                </p>
              </div>
            </div>
          ) : null}
        </div>
        {openedModal}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    timezone: state.auth.user.business.timezone,
    business: state.auth.user.business,
    taxLodaing: state.auth.taxLodaing,
    tax: state.auth.user.business.business_tax_rate
      ? state.auth.user.business.business_tax_rate.map((v) => {
          return {
            id: v.id,
            tax_id: v.tax_id,
            display_name: v.display_name,
            percentage: v.percentage,
            description: v.description,
            type: v.type,
            createdAt: v.createdAt,
          };
        })
      : [],
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    SetTax: (id = null, taxRate = null, remove = false) =>
      dispatch(updatetaxRate(id, taxRate, remove)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Taxes);
