import React, { PureComponent } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const DivWrapp = styled.div`
  position:relative;
  display: inline-block;
  margin:0 auto;
`;
const Parsent = styled.span`
  position:absolute;
  top:5px;
  left: 7px;
`;
const Input = styled.input.attrs({ type: 'text' })`
  border: 1px solid;
  background-color: transparent;
  font-size: 20px;
  outline: none;
  padding: .375rem .25rem !important;
  border-radius: 3px;
  font-size: 16px;
  padding-left: 5px !important;
  width:100%;
  border-color: ${props => props.error ? "red" : "#606948"} !important ; 
`;

class RefundInput extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            error: false,
            maxValue: props.maxValue
        };
        this.refund = null;
    }

    componentDidMount() {
        if (this.refund) {
            this.refund.focus();
        }
    }

    componentDidUpdate() {

    }

    render() {
        const { onChange: parentOnChange, ...rest } = this.props;
        const { maxValue } = this.state;
        return (

            <DivWrapp>
                <Input autoComplete="off" ref={(input) => {
                    this.refund = input
                }} error={this.state.error} {...rest} onChange={(e) => {
                    const target = e.target;

                    if (new RegExp(/^[0-9]*\.?[0-9]*$/).test(target.value) && (parseFloat(target.value) <= maxValue) && parseFloat(target.value) > 0) {
                        this.setState({ error: false });
                        target.setCustomValidity('');
                        parentOnChange(e, { error: false });
                    } else {
                        this.setState({ error: true });
                        target.setCustomValidity('Not valid Tax number');
                        parentOnChange(e, { error: true });
                    }

                }} />

            </DivWrapp>
        );
    }
}

RefundInput.propTypes = {
    onChange: PropTypes.func.isRequired,
    // value: PropTypes.string.isRequired
};

export default RefundInput;
