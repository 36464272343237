import {
  MICRO_SETTINGS_LOADING,
  MICRO_SETTINGS_ERROR,
  GET_MICRO_SETTINGS_SUCCESS,
  GET_MICRO_SETTINGS_LIST_SUCCESS,
  WHO_CAN_BOOK_DATA,
  MICRO_SETTINGS_RESET,
  FREEZE_LOGIC_LOADING,
  FREEZE_LOGIC_SUCCESS,
  ADDON_LOGIC_SUCCESS,
  RESTRICTIONS_LOGIC_LOADING,
  RESTRICTIONS_LOGIC_SUCCESS,
  RESTRICTIONS_LOGIC_SUCCESS_LIST,
  COACH_AVAILABILITY_LOADING,
  COACH_AVAILABILITY_LIST,
  COACH_AVAILABILITY_DETAILED_LIST,
} from "../constants/MicroSettings";

import axios from "../config/axios";

/* freeze logic */

function FreezeLoading(loading) {
  return {
    type: FREEZE_LOGIC_LOADING,
    payload: loading,
  };
}

function FreezeSuccess(data) {
  return {
    type: FREEZE_LOGIC_SUCCESS,
    payload: data,
  };
}
function AddOnSuccess(data) {
  return {
    type: ADDON_LOGIC_SUCCESS,
    payload: data,
  };
}

/* Restrictions logic */

function RestrictionsLoading(loading) {
  return {
    type: RESTRICTIONS_LOGIC_LOADING,
    payload: loading,
  };
}

function RestrictionsSuccess(data) {
  return {
    type: RESTRICTIONS_LOGIC_SUCCESS,
    payload: data,
  };
}

function RestrictionsListSuccess(data) {
  return {
    type: RESTRICTIONS_LOGIC_SUCCESS_LIST,
    payload: data,
  };
}

/* microsettings */

function MicroSettingsLoading(loading) {
  return {
    type: MICRO_SETTINGS_LOADING,
    payload: loading,
  };
}

function MicroSettingsError(error) {
  return {
    type: MICRO_SETTINGS_ERROR,
    payload: error,
  };
}

function MicroSettingsReset() {
  return {
    type: MICRO_SETTINGS_RESET,
  };
}

export const resetMicroSetting = () => (dispatch) =>
  dispatch(MicroSettingsReset());

export const getWhoCanBook = (data) => {
  return async (dispatch) => {
    try {
      dispatch(MicroSettingsLoading(true));
      //business.timeZone = moment.tz.guess();

      let res = await axios.get("microsettings/whocanbook", {
        params: data,
      });

      dispatch({
        type: WHO_CAN_BOOK_DATA,
        payload: res.data.payload,
      });
    } catch (error) {
      console.log(error);
      // await window.modal.alert(error.response.data.message);
      /*dispatch(
        MicroSettingsError({
          message: error.response.data.message,
        })
      );*/
    } finally {
      dispatch(MicroSettingsLoading(false));
    }
  };
};

export const getMicroSettings = (id, options = {}) => {
  return async (dispatch) => {
    try {
      let paging = {
        limit: 30,
        offset: 0,
      };

      if (options && options.offset) {
        paging.offset = options.offset;
      }
      if (options && options.limit) {
        paging.limit = options.limit;
      }

      dispatch(MicroSettingsLoading(true));
      //business.timeZone = moment.tz.guess();

      let res = await (!id
        ? axios.get(
            `microsettings/all?paging=${encodeURIComponent(
              JSON.stringify(paging)
            )}`
          )
        : axios.get("microsettings/" + id));

      dispatch({
        type: id ? GET_MICRO_SETTINGS_SUCCESS : GET_MICRO_SETTINGS_LIST_SUCCESS,
        payload: res.data.payload,
      });

      return res.data.payload;
    } catch (error) {
      console.log(error);
      // await window.modal.alert(error.response.data.message);
      /*dispatch(
        MicroSettingsError({
          message: error.response.data.message,
        })
      );*/
    } finally {
      dispatch(MicroSettingsLoading(false));
    }
  };
};

export const getMicroSettingsFreezed = () => {
  return async (dispatch) => {
    try {
      dispatch(FreezeLoading(true));

      let res = await axios.get("microsettings/all?freeze=1");
      dispatch(FreezeSuccess(res.data.payload.rows));
    } catch (error) {
      console.log(error);
      // await window.modal.alert(error.response.data.message);
      /*dispatch(
        MicroSettingsError({
          message: error.response.data.message,
        })
      );*/
    } finally {
      dispatch(FreezeLoading(false));
    }
  };
};
export const getMicroSettingsAddOn = () => {
  return async (dispatch) => {
    try {
      dispatch(FreezeLoading(true));

      let res = await axios.get("add_on");
      dispatch(AddOnSuccess(res.data.payload));
    } catch (error) {
      console.log(error);
      
    } finally {
      dispatch(FreezeLoading(false));
    }
  };
};
export const deleteAddOn = (id) => {
  return async (dispatch) => {
    try {
      dispatch(FreezeLoading(true));

      let res = await axios.delete(`add_on/remove/${id}`);
     return true;
    } catch (error) {
      console.log(error);
      
    } finally {
      dispatch(FreezeLoading(false));
    }
  };
};
export const deleteMicroSettings = (
  id,
  type = "apply",
  group_by = null,
  options = {}
) => {
  return async (dispatch) => {
    try {
      dispatch(MicroSettingsLoading(true));
      //business.timeZone = moment.tz.guess();

      await axios.delete(
        `microsettings/delete/${id}${group_by ? `?group_by=${group_by}` : ``}`,
        {}
      );
      if (type === "apply") {
        dispatch(getMicroSettings(null, options));
      }
      if (type === "freeze") {
        dispatch(getMicroSettingsFreezed());
      }
    } catch (error) {
      console.log(error);
      await window.modal.alert(error.response.data.message);
      dispatch(
        MicroSettingsError({
          message: error.response.data.message,
        })
      );
    } finally {
      dispatch(MicroSettingsLoading(false));
    }
  };
};

export const starMicroSettings = (id, type = true, options = {}) => {
  return async (dispatch) => {
    try {
      dispatch(MicroSettingsLoading(true));
      //business.timeZone = moment.tz.guess();

      await axios.post("microsettings/star/" + id, {
        stared: type,
      });
      dispatch(getMicroSettings(null, options));
    } catch (error) {
      console.log(error);
      await window.modal.alert(error.response.data.message);
      dispatch(
        MicroSettingsError({
          message: error.response.data.message,
        })
      );
    } finally {
      dispatch(MicroSettingsLoading(false));
    }
  };
};

export function createMicroSettings(data, update = true, options = {}) {
  return async (dispatch) => {
    try {
      dispatch(MicroSettingsLoading(true));

      await axios.post("microsettings/add", data, {});

      if (update) {
        dispatch(getMicroSettings(null, options));
      }
      return true;
    } catch (error) {
      console.log(error);
      await window.modal.alert(error.response.data.message);
      return false;
    } finally {
      dispatch(MicroSettingsLoading(false));
    }
  };
}

export function createFreeze(data) {
  return async (dispatch) => {
    try {
      dispatch(FreezeLoading(true));
      let res = await axios.post("appointment/freeze", data, {});

      dispatch(FreezeSuccess(res.data.payload));
      return true;
    } catch (error) {
      console.log(error);
      await window.modal.alert(error.response.data.message);
      return false;
    } finally {
      dispatch(FreezeLoading(false));
    }
  };
}
export function createAddOn(data) {
  return async (dispatch) => {
    try {
      dispatch(FreezeLoading(true));
      let res = await axios.post("add_on/add", data, {});

      //dispatch(AddOnSuccess(res.data.payload));
      return true;
    } catch (error) {
      console.log(error);
      await window.modal.alert(error.response.data.message);
      return false;
    } finally {
      dispatch(FreezeLoading(false));
    }
  };
}
export function updateAddOn(id,data) {
  return async (dispatch) => {
    try {
      dispatch(FreezeLoading(true));
      let res = await axios.post(`add_on/update/${id}`, data, {});

      //dispatch(AddOnSuccess(res.data.payload));
      return true;
    } catch (error) {
      console.log(error);
      await window.modal.alert(error.response.data.message);
      return false;
    } finally {
      dispatch(FreezeLoading(false));
    }
  };
}

export function updateMicroSettings(id, data, options = {}) {
  return async (dispatch) => {
    try {
      dispatch(MicroSettingsLoading(true));

      await axios.post("microsettings/update/" + id, data, {});
      dispatch(getMicroSettings(null, options));
      return true;
    } catch (error) {
      console.log(error);
      await window.modal.alert(error.response.data.message);

      return false;
    } finally {
      dispatch(MicroSettingsLoading(false));
    }
  };
}

/* end microsettings */

// Restrictions

export function createRestrictions(data, id) {
  return async (dispatch) => {
    try {
      dispatch(RestrictionsLoading(true));
      //business.timeZone = moment.tz.guess();
      let url = `restrictions/add`;
      if (id) {
        url = `restrictions/update/${id}`;
      }

      let res = await axios.post(url, data, {});

      dispatch(getRestrictions());

      // dispatch(RestrictionsSuccess(res.data.payload));
      return true;
    } catch (error) {
      console.log(error);
      await window.modal.alert(error.response.data.message);
      return false;
    } finally {
      dispatch(RestrictionsLoading(false));
    }
  };
}

export const deleteRestrictions = (id) => {
  return async (dispatch) => {
    try {
      dispatch(RestrictionsLoading(true));

      await axios.delete("restrictions/delete/" + id, {});
      dispatch(getRestrictions());
    } catch (error) {
      console.log(error);
      await window.modal.alert(error.response.data.message);
      dispatch();
    } finally {
      dispatch(RestrictionsLoading(false));
    }
  };
};

export function getRestrictions() {
  return async (dispatch) => {
    try {
      dispatch(RestrictionsLoading(true));

      let res = await axios.get("restrictions/all", {});

      dispatch(RestrictionsListSuccess(res.data.payload));
      return true;
    } catch (error) {
      console.log(error);
      await window.modal.alert(error.response.data.message);
      return false;
    } finally {
      dispatch(RestrictionsLoading(false));
    }
  };
}
export function getRestriction(id) {
  return async (dispatch, getStore) => {
    try {
      dispatch(RestrictionsLoading(true));

      const list = getStore().micro_settings.restrictions.list;
      const found = list.filter((v) => v.id === id);
      dispatch(
        RestrictionsSuccess({
          ...found["0"],
          addedAt: new Date(),
        })
      );
      return true;
    } catch (error) {
      console.log(error);
      await window.modal.alert(error.response.data.message);
      return false;
    } finally {
      dispatch(RestrictionsLoading(false));
    }
  };
}

/* COACH AVAILABILITY */

export function getCoachAvailabilityList(data) {
  return async (dispatch) => {
    try {
      dispatch({
        type: COACH_AVAILABILITY_LOADING,
        payload: true,
      });

      const res = await axios.post("appointment/listing-coach", data);
      dispatch({
        type: COACH_AVAILABILITY_LIST,
        payload: res.data.payload,
      });

      return true;
    } catch (error) {
      await window.modal.alert(error.response.data.message);
      return false;
    } finally {
      dispatch({
        type: COACH_AVAILABILITY_LOADING,
        payload: false,
      });
    }
  };
}

export function getCoachAvailabilityDetailedList(data) {
  return async (dispatch) => {
    try {
      dispatch({
        type: COACH_AVAILABILITY_LOADING,
        payload: true,
      });

      const res = await axios.post("microsettings/all-coach", data);
      dispatch({
        type: COACH_AVAILABILITY_DETAILED_LIST,
        payload: res.data.payload,
      });

      return true;
    } catch (error) {
      await window.modal.alert(error.response.data.message);
      return false;
    } finally {
      dispatch({
        type: COACH_AVAILABILITY_LOADING,
        payload: false,
      });
    }
  };
}

export function createCoachAvailability(data, update = false, options = {}) {
  return async (dispatch) => {
    try {
      dispatch({
        type: COACH_AVAILABILITY_LOADING,
        payload: true,
      });
      await axios.post("microsettings/add-coach", data);

      if (update) {
        dispatch(getCoachAvailabilityList(options));
      }

      return true;
    } catch (error) {
      await window.modal.alert(error.response.data.message);
      return false;
    } finally {
      dispatch({
        type: COACH_AVAILABILITY_LOADING,
        payload: false,
      });
    }
  };
}

export function resetCoachAvailabilityList() {
  return async (dispatch) => {
    dispatch({
      type: COACH_AVAILABILITY_LIST,
      payload: [],
    });
    dispatch({
      type: COACH_AVAILABILITY_DETAILED_LIST,
      payload: [],
    });
  };
}

export function bookCoachAvailability(data, update = false, options = {}) {
  return async (dispatch) => {
    try {
      dispatch({
        type: COACH_AVAILABILITY_LOADING,
        payload: true,
      });
      await axios.post("appointment/add_book_coach", data);

      if (update) {
        dispatch(getCoachAvailabilityList(options));
      }

      return true;
    } catch (error) {
      if (error.response.data?.code === 409) {
        await window.modal.alert(
          "Error",
          `
          You can't book this time range. Please check court-sheet to select valid time range.
          <br/>
          ${error.response.data.message}
          `
        );
      } else {
        await window.modal.alert(error.response.data.message);
      }

      return false;
    } finally {
      dispatch({
        type: COACH_AVAILABILITY_LOADING,
        payload: false,
      });
    }
  };
}
export function CoachBookRequest(data) {
  return async (dispatch) => {
    try {
      dispatch({
        type: COACH_AVAILABILITY_LOADING,
        payload: true,
      });
      await axios.post("appointment/add-coach", data);
      return true;
    } catch (error) {
      if (error.response.data?.code === 409) {
        await window.modal.alert(
          "Error",
          `
          You can't book this time range. Please check court-sheet to select valid time range.
          <br/>
          ${error.response.data.message}
          `
        );
      } else {
        await window.modal.alert(error.response.data.message);
      }

      return false;
    } finally {
      dispatch({
        type: COACH_AVAILABILITY_LOADING,
        payload: false,
      });
    }
  };
}
export function CoachBookAndPayRequest(data) {
  return async (dispatch) => {
    try {
      dispatch({
        type: COACH_AVAILABILITY_LOADING,
        payload: true,
      });
      await axios.post("appointment/book-and-pay-coach", data);
      return true;
    } catch (error) {
      if (error.response.data?.code === 409) {
        await window.modal.alert(
          "Error",
          `
          You can't book this time range. Please check court-sheet to select valid time range.
          <br/>
          ${error.response.data.message}
          `
        );
      } else {
        await window.modal.alert(error.response.data.message);
      }

      return false;
    } finally {
      dispatch({
        type: COACH_AVAILABILITY_LOADING,
        payload: false,
      });
    }
  };
}
export function bookCoachAvailabilityForMember(
  data,
  update = false,
  options = {}
) {
  return async (dispatch) => {
    try {
      dispatch({
        type: COACH_AVAILABILITY_LOADING,
        payload: true,
      });
      await axios.post("appointment/book-and-pay-coach", data);

      if (update) {
        dispatch(getCoachAvailabilityList(options));
      }

      return true;
    } catch (error) {
      if (error.response.data?.code === 409) {
        await window.modal.alert(
          "Error",
          `
          You can't book this time range. Please check court-sheet to select valid time range.
          <br/>
          ${error.response.data.message}
          `
        );
      } else {
        await window.modal.alert(error.response.data.message);
      }

      return false;
    } finally {
      dispatch({
        type: COACH_AVAILABILITY_LOADING,
        payload: false,
      });
    }
  };
}

export function updateCoachAvailability(id, data, options = {}) {
  return async (dispatch) => {
    try {
      dispatch({
        type: COACH_AVAILABILITY_LOADING,
        payload: true,
      });

      await axios.post("microsettings/update-coach/" + id, data, {});

      return true;
    } catch (error) {
      await window.modal.alert(error.response.data.message);
      return false;
    } finally {
      dispatch({
        type: COACH_AVAILABILITY_LOADING,
        payload: false,
      });
    }
  };
}

export const deleteCoachAvailability = (id, data) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: COACH_AVAILABILITY_LOADING,
        payload: true,
      });

      await axios.delete(`appointment/delete_rule_coach`, {
        data,
      });

      return true;
    } catch (error) {
      await window.modal.alert(error.response.data.message);
      return false;
    } finally {
      dispatch({
        type: COACH_AVAILABILITY_LOADING,
        payload: false,
      });
    }
  };
};

export const blockCoachAvailability = (data) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: COACH_AVAILABILITY_LOADING,
        payload: true,
      });

      await axios.post(`appointment/coach-block`, data);

      return true;
    } catch (error) {
      await window.modal.alert(error.response.data.message);
      return false;
    } finally {
      dispatch({
        type: COACH_AVAILABILITY_LOADING,
        payload: false,
      });
    }
  };
};

export const unBlockCoachAvailability = (data) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: COACH_AVAILABILITY_LOADING,
        payload: true,
      });

      await axios.post(`appointment/coach-block-remove`, data);

      return true;
    } catch (error) {
      await window.modal.alert(error.response.data.message);
      return false;
    } finally {
      dispatch({
        type: COACH_AVAILABILITY_LOADING,
        payload: false,
      });
    }
  };
};
