import {
  GET_TERMINAL_LIST,
  GET_TERMINAL_LIST_LOADING,
} from "../constants/Terminal";

function getTerminalLoading(bool) {
  return {
    type: GET_TERMINAL_LIST_LOADING,
    payload: bool,
  };
}

function getTerminalList(payload) {
  return {
    type: GET_TERMINAL_LIST,
    payload: payload,
  };
}

export function discoverReaders(terminal) {
  return async (dispatch) => {
    dispatch(getTerminalLoading(true));
    try {
      const discoverResult = await terminal.discoverReaders();
      if (discoverResult.error) {
        console.log("Failed to discover: ", discoverResult.error);
        throw new Error("Failed to discover: ", discoverResult.error);
      } else if (discoverResult.discoveredReaders.length === 0) {
        console.log("No available readers.");
        dispatch(getTerminalLoading(false));
        return;
        // throw new Error('No available readers.');
      } else {
        dispatch(getTerminalList(discoverResult.discoveredReaders));
      }
      //dispatch(getSingleLessonsSuccess(res.data.payload['0']));
    } catch (error) {
      console.log(error);
      //await window.modal.alert('Error', error.response.data.message);
    } finally {
      dispatch(getTerminalLoading(false));
    }
  };
}
