import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Picky from 'react-picky';
import { connect } from 'react-redux';
import { GetTags } from '../../actions/Tag';

class TagsSelect extends Component {
  componentDidMount() {
    this.props.getTags();
  }

  render() {
    const { tag, onChange } = this.props;
    let { value } = this.props;

    const defaultOption = { id: -1, display_name: "None" }

    const options =  [defaultOption, ...tag.list];

    if (value && !value.display_name) {
      const valueFromList = options.find(itm => itm.id === value.id);

      value = valueFromList
        ? valueFromList
        : {
            ...value,
            display_name: 'Tag id: ' + value.id,
          };
    }

    return (
      <div className={'picky-container'} style={{ flex: '0 0 70%' }}>
        <Picky
          includeFilter
          options={options}
          value={value}
          valueKey="id"
          labelKey="display_name"
          multiple={false}
          onChange={onChange}
          dropdownHeight={350}
        />
      </div>
    );
  }
}

TagsSelect.propTypes = {
  // onChange: PropTypes.func.isRequired,
  // value: PropTypes.shape({ id: PropTypes.any.isRequired() }),
};

const mapStateToProps = state => {
  return {
    tag: state.tag,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getTags: () => dispatch(GetTags()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TagsSelect);
