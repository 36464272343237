import React, { Component } from 'react';
import './bb-options.scss';
class BbOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { for_athletes } = this.props;
    const options = [
      {
        img: '/assets/img/person.png',
        desc: for_athletes ? 'See your profile' : 'See their profile'
      }, {
        img: '/assets/img/tennis-court-icon-v2.png',
        desc: 'Book courts/spots/lanes'
      }, {
        img: '/assets/img/track-icon.png',
        desc: for_athletes ? 'Track your own <br/> activity' : 'Track their own <br/> activity'
      }, {
        img: '/assets/img/dollar-paper-bills-stack.svg',
        desc: 'Manage payments'
      }, {
        img: '/assets/img/book-tennis-icon-v2.png',
        desc: 'Book lessons and programs'
      }
    ];
    const _options = options.map((v, i) =>
      <div key={v.img} className={`option ${for_athletes ? `m-bottom-40` : ``} ${`option_` + i}`}>
        <div className="option-img-div">
          <img src={v.img} alt={v.img} />
        </div>
        <div className="option-desc-div">
          <span dangerouslySetInnerHTML={{ __html: v.desc }}></span>
        </div>

      </div>
    );
    return (
      <div className="athlete-options-container">
        <div className="main-container">
          <div className="athlete-options">
            <div className="options-desc">
              {for_athletes ? `You can:` : " Your Members/ \r\n Customers Will Have The Most Modern And Full-Featured Interaction With Your Business. They can:"}

            </div>
            {_options}
          </div>
        </div>
      </div>

    );
  }
}

export default BbOptions;
