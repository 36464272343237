import React, { Component } from 'react';

class Notification extends Component {
  render() {
    return (
      <div className={`notification ${this.props.className ? this.props.className : ''}`} style={this.props.style}>
        <span className="not-icon">
          <img src="/assets/img/notification.png" alt="notification" />
        </span>
        <p className={this.props.classNameChild ? this.props.classNameChild : ''} style={this.props.styleForChild}>{this.props.children}</p>
      </div>
    );
  }
}

Notification.propTypes = {};

export default Notification;
