import {
  ADD_GROUP_ERROR,
  ADD_GROUP_MEMBER_SUCCESS,
  ADD_GROUP_SUCCESS,
  ADD_PAYMANT_SUCCESS_GROUP,
  CHANGE_PAYMANT_SUCCESS_GROUP,
  GET_GROUP_MEMBER_ERROR,
  GET_GROUP_MEMBER_LOADING,
  GET_GROUP_MEMBER_SUCCESS,
  GET_GROUP_MEMBERS_ERROR,
  GET_GROUP_MEMBERS_LOADING,
  GET_GROUP_MEMBERS_SUCCESS,
  GET_GROUP_SUCCESS,
  GET_GROUPS_ERROR,
  GET_GROUPS_LOADING,
  GET_GROUPS_SUCCESS,
  UPDATE_GROUP_LOADING,
  UPDATE_GROUP_MEMBER_SUCCESS,
  UPDATE_GROUP_SUCCESS,
} from '../constants/Group';

const initStore = {
  loading: false,
  updateLoading: false,
  membersLoading: false,
  memberLoading: false,
  data: [],
  group: {},
  members: [],
  member: {},
  memberAttendanceHistory: [],
  error: null,
};
const GroupReducer = (store = initStore, action) => {
  switch (action.type) {
    case GET_GROUPS_SUCCESS:
      return Object.assign({}, store, {
        data: action.payload,
      });
    case GET_GROUP_SUCCESS:
      return Object.assign({}, store, {
        group: action.payload,
      });
    case GET_GROUP_MEMBERS_SUCCESS:
      return Object.assign({}, store, {
        members: action.payload,
      });
    case ADD_GROUP_SUCCESS:
      return {
        ...store,
        data: [...store.data, action.payload],
      };
    case UPDATE_GROUP_SUCCESS:
      return {
        ...store,
        data: store.data.map(item => (item.id === action.payload.id ? action.payload : item)),
        group: action.payload,
      };
    case GET_GROUPS_ERROR:
      return Object.assign({}, store, {
        error: action.payload,
      });
    case GET_GROUP_MEMBERS_ERROR:
      return Object.assign({}, store, {
        error: action.payload,
      });
    case ADD_GROUP_ERROR:
      return Object.assign({}, store, {
        error: action.payload,
      });
    case GET_GROUPS_LOADING:
      return Object.assign({}, store, {
        loading: action.payload,
      });
    case UPDATE_GROUP_LOADING:
      return Object.assign({}, store, {
        updateLoading: action.payload,
      });
    case GET_GROUP_MEMBERS_LOADING:
      return Object.assign({}, store, {
        membersLoading: action.payload,
      });
    case GET_GROUP_MEMBER_ERROR:
      return Object.assign({}, store, {
        error: action.payload,
      });
    case GET_GROUP_MEMBER_SUCCESS:
      return Object.assign({}, store, {
        member: action.payload,
      });
    case ADD_GROUP_MEMBER_SUCCESS:
      return Object.assign({}, store, {
        member: action.payload,
      });
    case UPDATE_GROUP_MEMBER_SUCCESS:
      return Object.assign({}, store, {
        member: action.payload,
      });
    case GET_GROUP_MEMBER_LOADING:
      return Object.assign({}, store, {
        memberLoading: action.payload,
      });
    case ADD_PAYMANT_SUCCESS_GROUP:
      return {
        ...store,
        member: {
          ...store.member,
          stripePaymentMethods: [action.payload],
        },
      };
    case CHANGE_PAYMANT_SUCCESS_GROUP:
      return {
        ...store,
        member: {
          ...store.member,
          stripePaymentMethods: store.member.stripePaymentMethods.map(itemPaymant => {
            if (action.payload.defaultPaymentMethod === itemPaymant.id) {
              return {
                ...itemPaymant,
                default_source: true,
              };
            }
            return {
              ...itemPaymant,
              default_source: false,
            };
          }),
        },
      };
    default:
      return store;
  }
};
export default GroupReducer;
