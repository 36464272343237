import axios from "../config/axios";
import {
  ADD_COACH_LOADING,
  ADD_COACH_SUCCESS,
  GET_COACH_LOADING,
  GET_COACH_SUCCESS,
  GET_COACH_SUCCESS_MOBILE,
  GET_HIDDEN_COACH_SUCCESS,
  UPDATE_COACH_LOADING,
  GET_MEMBER_TYPE_SUCCESS,
  ADD_PAYMANT_SUCCESS_STAFF,
  CHANGE_PAYMANT_SUCCESS_STAFF,
  REMOVE_PAYMANT_SUCCESS_STAFF,
  GET_CUSTOMER_STAFF_SUCCESS,
  GET_STAFF_BALANCE_SUCCESS,
  GET_STAFF_BOOKING_SUCCESS,
} from "../constants/Coach";

export function addSourciesStaffSuccess(payload) {
  return {
    type: ADD_PAYMANT_SUCCESS_STAFF,
    payload: payload,
  };
}

export function getCustomerStaffSuccess(payload, member_id) {
  return {
    type: GET_CUSTOMER_STAFF_SUCCESS,
    payload: {
      payload,
      member_id,
    },
  };
}

export function getStaffBookingSuccess(payload, member_id) {
  return {
    type: GET_STAFF_BOOKING_SUCCESS,
    payload: {
      ...payload,
      member_id,
    },
  };
}

export function getBalanceStaffSuccess(payload, member_id) {
  return {
    type: GET_STAFF_BALANCE_SUCCESS,
    payload: {
      payload,
      member_id,
    },
  };
}
export function changeSourciesStaffSuccess(payload) {
  return {
    type: CHANGE_PAYMANT_SUCCESS_STAFF,
    payload: payload,
  };
}
export function removeCardStaff(payload) {
  return {
    type: REMOVE_PAYMANT_SUCCESS_STAFF,
    payload: payload,
  };
}

function addCoachSuccess(payload) {
  return {
    type: ADD_COACH_SUCCESS,
    payload,
  };
}

function getMemberTypeSuccess(payload) {
  return {
    type: GET_MEMBER_TYPE_SUCCESS,
    payload,
  };
}

function addCoachLoading(bool) {
  return {
    type: ADD_COACH_LOADING,
    payload: bool,
  };
}

function updateCoachSuccess(bool) {
  return {
    type: UPDATE_COACH_LOADING,
    payload: bool,
  };
}

function getCoachSuccess(payload) {
  return {
    type: GET_COACH_SUCCESS,
    payload,
  };
}
function getCoachSuccessMobile(payload) {
  return {
    type: GET_COACH_SUCCESS_MOBILE,
    payload,
  };
}
function getHiddenCoachSuccess(payload) {
  return {
    type: GET_HIDDEN_COACH_SUCCESS,
    payload,
  };
}
function getCoachLoading(bool) {
  return {
    type: GET_COACH_LOADING,
    payload: bool,
  };
}

export function addCoach(data) {
  return async (dispatch) => {
    const { error, changePassword, namespace, role, err, ...rest } = data;
    dispatch(addCoachLoading(true));
    try {
      let res = await axios.post("coaches/add", {
        ...rest,
        member_type_id: role.id,
      });
      if (res.data.code !== 200) throw new Error(res.data.message);
      dispatch(addCoachSuccess(res.data.payload));
    } catch (error) {
      await window.modal.alert("Error", error.response.data.message);
    } finally {
      dispatch(addCoachLoading(false));
    }
  };
}

export function updateCoach(data, mid) {
  return async (dispatch) => {
    const { error, err, role, ...rest } = data;
    dispatch(addCoachLoading(true));
    try {
      let res = await axios.put(`coaches/update/${mid}`, {
        ...rest,
        member_type_id: role.id,
      });

      dispatch(updateCoachSuccess(res.data.payload));
    } catch (error) {
      await window.modal.alert("Error", error.response.data.message);
    } finally {
      dispatch(addCoachLoading(false));
    }
  };
}

export function getCoach() {
  return async (dispatch) => {
    dispatch(getCoachLoading(true));
    try {
      let res = await axios.get(`coaches/get-all`); 
      dispatch(getCoachSuccess(res.data.payload));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(getCoachLoading(false));
    }
  };
}

export function GetCoachesRequestBody(business_Id,data) {
  return async (dispatch) => {
    dispatch(getCoachLoading(true));
    try {
     
      let res = await axios.post(`appointment/coach-list/${business_Id}`,{
        ...data,
      }); 
      dispatch(getCoachSuccessMobile(res.data.payload));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(getCoachLoading(false));
    }
  };
}
export function GetExpiredRules() {
  return async (dispatch) => {
    dispatch(getCoachLoading(true));
    try {
     
      let res = await axios.get(`microsettings/expiring`); 
     // dispatch(getCoachSuccessMobile(res.data.payload));
      return res.data.payload
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(getCoachLoading(false));
    }
  };
}

export function getStaff(staff = true,hidden ) {
  return async (dispatch) => {
    dispatch(getCoachLoading(true));
    try {
      let res = await axios.get(`coaches/get`, {
        params: {
          staff: staff ? staff : undefined,
          hidden: hidden ? hidden : false,
        },
      });
      dispatch(getCoachSuccess(res.data.payload));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(getCoachLoading(false));
    }
  };
}

export function getStaffHidden() {
  return async (dispatch) => {
    dispatch(getCoachLoading(true));
    try {
      let res = await axios.get(`coaches/get`, {
        params: {
          staff: true,
          hidden:  true,
        },
      });
      dispatch(getHiddenCoachSuccess(res.data.payload));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(getCoachLoading(false));
    }
  };
}

export function getMemberTypes() {
  return async (dispatch) => {
    dispatch(getCoachLoading(true));
    try {
      let res = await axios.get("roles/member-types");
      dispatch(getMemberTypeSuccess(res.data.payload));
    } catch (error) {
      await window.modal.alert("Error", error.response.data.message);
    } finally {
      dispatch(getCoachLoading(false));
    }
  };
}
