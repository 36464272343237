/* eslint-disable no-empty */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createStripeCardReader } from '../../actions/Stripe';
import { LocationSelect } from "../../containers/Business";

// eslint-disable-next-line react/no-multi-comp
class AddTerminalModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lable: '',
            code: '',
            locationId: -1,
        }
    }

    hendlerInputChange() {
        return (e) => {
            const { value, name } = e.target;
            this.setState({ [name]: value });
        }
    }

    registerReader() {
        return async () => {
            try {
                if (this.state.code.length > 2 && this.state.lable.length >= 2 && this.state.locationId) {
                    await createStripeCardReader(this.state.code, this.state.lable,this.state.locationId);
                    await this.props.getReaders();
                    this.props.clouseModal();
                } else {
                    await window.modal.alert('Error', 'incorect form vales');
                }
            } catch (error) {
                await window.modal.alert('Error', error.response.data.message);
            }
        }
    }

    render() {
        const { clouseModal } = this.props;
        const { code, lable ,locationId} = this.state;
        return (
            <div className="modal terminal-modal active">
                <div className="modal-content">
                    <div className="close-btn">
                        <button onClick={clouseModal}>
                            <img src="/assets/img/cancel.png" alt="cancel" />
                        </button>
                    </div>
                    <div>
                        <div className='form-group'>
                            <input type="text" name='code' placeholder='Code From Reader' value={code}
                            onChange={this.hendlerInputChange()} />
                        </div>
                        <div className='form-group'>
                         <input type="text" name='lable' placeholder='Name for Reader' value={lable}
                            onChange={this.hendlerInputChange()} />
                        </div>
                        <div className='form-group'>
                        <span className="label-span" style={{display:"block",textAlign:"left"}}>*Location </span>
                        <LocationSelect
                            value={{
                            id: locationId,
                            }}
                            fromQRPopup={true}
                            onChange={(opt) =>
                                this.setState({ "locationId": opt.id })
                               
                            }
                            
                        />
                        </div>
                        <div className="sign-up-btn-new" style={{margin:"auto"}}>
                            <div className="pretty-button ">
                                <button className='green-button' onClick={this.registerReader()}>Register Reader</button>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        );
    }
}

AddTerminalModal.propTypes = {
    clouseModal: PropTypes.func.isRequired,
};

export default AddTerminalModal;
