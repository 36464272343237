import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSportCategoryList } from '../../../actions/Facility';

const FacilitySportCategory = ({ value, onChange }) => {
  const dispatch = useDispatch();
  const { sportCategoryList } = useSelector(state => ({
    sportCategoryList: state.facility.sport_category.list,
  }));

  useEffect(() => {
    dispatch(getSportCategoryList());
  }, [dispatch]);

  return (
    <div className="facility-groups">
      <div className="row row-cols-3 facility-groups--list">
        {sportCategoryList.data.map(fGroup => {
          const htmlId = `sportCategory-${fGroup.id}`;
          return (
            <label className="col mb-4 facility-groups--item" htmlFor={htmlId} key={fGroup.id}>
              <input
                type="radio"
                name="sportCategory"
                value={fGroup.id}
                checked={value == fGroup.id}
                onChange={onChange}
                id={htmlId}
              />
              <div className="facility-groups--item__content">
                <div className="card facility-group">
                  <div className="card-body">
                    <h5 className="card-title facility-group--title">{fGroup.display_name}</h5>

                    <div className="card-content">
                      <ul className="list-group list-group-flush facility-list">
                        {fGroup.sports.map(fc => (
                          <li className="list-group-item facility-list--item" key={fc.id}>
                            {fc.display_name}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </label>
          );
        })}
      </div>
    </div>
  );
};

export default FacilitySportCategory;
