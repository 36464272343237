
import React, { Component } from 'react';
import { connect } from 'react-redux';


class FamilyDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            family_id: ''
        };
    }
    componentDidMount() {

    }

    hendlerChange = (e) => {
        const { cb } = this.props;
        const value = e.target.value;
        this.setState({ family_id: value });
        cb(value); 
       
    }



    render() {
        const { user, placeholder } = this.props;
        const { family_id } = this.state;
        let _placeholder = `${user.first_name} ${user.last_name}`;
        const business = user.business;
        const family = business.family ? business.family : [];
        return (
            <div className="dropdown-filter">
                <div>
                    <select name="familyFilter" value={family_id} onChange={this.hendlerChange}>
                        <option value="">{_placeholder}</option>
                        {family.map(item => {
                            return <option key={item.id} value={item.user_id}>
                                {item.member ? `${item.member.first_name} ${item.member.last_name}` : ``}
                            </option>;
                        })}
                    </select>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};

FamilyDropdown.propTypes = {

};

export default connect(mapStateToProps, mapDispatchToProps)(FamilyDropdown);

