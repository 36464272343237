// SPORT part
export const FACILITY_SPORT_CURRENT_LOADING = 'FACILITY_SPORT_CURRENT_LOADING';
export const FACILITY_SPORT_CURRENT_SUCCESS = 'FACILITY_SPORT_CURRENT_SUCCESS';
export const FACILITY_SPORT_CURRENT_ERROR = 'FACILITY_SPORT_CURRENT_ERROR';

export const FACILITY_SPORT_LIST_LOADING = 'FACILITY_SPORT_LIST_LOADING';
export const FACILITY_SPORT_LIST_SUCCESS = 'FACILITY_SPORT_LIST_SUCCESS';
export const FACILITY_SPORT_LIST_ERROR = 'FACILITY_SPORT_LIST_ERROR';


// SPORT_CATEGORY part
export const FACILITY_SPORT_CATEGORY_CURRENT_LOADING = 'FACILITY_SPORT_CATEGORY_CURRENT_LOADING';
export const FACILITY_SPORT_CATEGORY_CURRENT_SUCCESS = 'FACILITY_SPORT_CATEGORY_CURRENT_SUCCESS';
export const FACILITY_SPORT_CATEGORY_CURRENT_ERROR = 'FACILITY_SPORT_CATEGORY_CURRENT_ERROR';

export const FACILITY_SPORT_CATEGORY_LIST_LOADING = 'FACILITY_SPORT_CATEGORY_LIST_LOADING';
export const FACILITY_SPORT_CATEGORY_LIST_SUCCESS = 'FACILITY_SPORT_CATEGORY_LIST_SUCCESS';
export const FACILITY_SPORT_CATEGORY_LIST_ERROR = 'FACILITY_SPORT_CATEGORY_LIST_ERROR';


// GROUP part
export const FACILITY_GROUP_CURRENT_LOADING = 'FACILITY_GROUP_CURRENT_LOADING';
export const FACILITY_GROUP_CURRENT_SUCCESS = 'FACILITY_GROUP_CURRENT_SUCCESS';
export const FACILITY_GROUP_CURRENT_ERROR = 'FACILITY_GROUP_CURRENT_ERROR';

export const FACILITY_GROUP_LIST_LOADING = 'FACILITY_GROUP_LIST_LOADING';
export const FACILITY_GROUP_LIST_SUCCESS = 'FACILITY_GROUP_LIST_SUCCESS';
export const FACILITY_GROUP_LIST_ERROR = 'FACILITY_GROUP_LIST_ERROR';
