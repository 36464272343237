import React, { Component } from "react";
import "./athletes-slider.scss";
class AthletesSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const slider_images = [
      "/assets/img/player-1.png",
      "/assets/img/player-2.png",
      "/assets/img/player-3.png",
      "/assets/img/player-4.png",
    ];

    return (
      <div className="athlets-slider">
        <div className="main-container">
          <div className="small-circle">
            <img src="/assets/img/small-ball.png" alt="small-ball.png" />
          </div>
          <div className="middle-circle">
            <img src="/assets/img/middle-ball.png" alt="middle-ball.png" />
          </div>
          <div className="titles">
            <div className="title">
              <h2>Club members and even pro athletes love us!</h2>
            </div>
          </div>
          <div className="slider">
            {slider_images.map((v) => (
              <div key={v} className="each-img">
                <img src={v} alt={v} />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default AthletesSlider;
