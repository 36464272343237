import React, { Component } from "react";
import { connect } from "react-redux";
import FormGroup from "../../components/FormGroup";
import ColorPicker from "../UI/ColorPicker";
import GreenSwitcher from "../UI/GreenSwitcher";
import "react-responsive-list/assets/index.css";
import "../DynamicForm/booking-step.scss";
import Notification from "../Notification";
import axiosInstant from "../../config/axios";
import { withRouter } from "react-router";
import { addLessonType, editLessonType ,GetLessonTypes} from "../../actions/Lesson";


const Input = (props) => {
  return (
    <FormGroup {...props} formGroupClass="form-group-booking-step">
      <button
        type="button"
        className="btn btn-gray"
        onClick={() =>
          props.onChange({
            target: {
              name: props.name,
              value: props.value - props.step,
            },
          })
        }
      >
        -
      </button>
      <button
        type="button"
        className="btn btn-gray"
        onClick={() =>
          props.onChange({
            target: {
              name: props.name,
              value: props.value + props.step,
            },
          })
        }
      >
        +
      </button>
    </FormGroup>
  );
};
class LessonTypeModal extends Component {
  constructor(props) {
    super(props);
    const { lessonType } = props;
    this.state = {
      timeStep: props.timeStep ? props.timeStep : 1,
      name: "",
      color: lessonType && lessonType.color ? lessonType.color : "#1bb1e4",
      display_name: lessonType ? lessonType.display_name : "",
      allowed_count: 50,
      list_duration: lessonType && lessonType.is_min_max_enabled ? lessonType.is_min_max_enabled : false,
      min_list_duration: lessonType && lessonType.min_list_duration ? lessonType.min_list_duration : props.timeStep ? props.timeStep : 0,
      max_list_duration: lessonType && lessonType.max_list_duration ? lessonType.max_list_duration : props.timeStep ? props.timeStep : 0,
      error: {},
      isOptionOpen: lessonType && lessonType.is_min_max_enabled ? lessonType.is_min_max_enabled : false,
    };

    this.saveType = this.saveType.bind(this);
    this.toogelLessonMinMax = this.toogelLessonMinMax.bind(this);
    this.HandleChange = this.HandleChange.bind(this);
    this.handleChangeColor = this.handleChangeColor.bind(this);
    this.HideLessonType = this.HideLessonType.bind(this);
  }
  HideLessonType = async () =>{
    if(await window.modal.cnfirm("Confirm", "Are you sure to archive this service type from dashboard?<br/> (all archived types are save in “Archived” folder)")){
      try { 
        let res = await axiosInstant.put(`lesson/type/edit/hidden/${this.props.lessonType.id}`,{hidden:true});
        if(res.data.message=="Success"){
          await  window.modal.alert("Success");
          this.props.history.push(`/private/lessons/-1`);
          this.props.clouseModal();
          window.location.reload();
        }
      }catch (error){
        window.modal.alert("Notice","Something went wrong, please try later.");
    }
   }
  }
  
  HandleChange(e) {
    let error;
    if (e.target.name != "min_list_duration" && e.target.name != "max_list_duration") {
      const { [e.target.name]: error } = this.checkValidation(
        Object.assign(this.state, { [e.target.name]: e.target.value })
      );
    }

    if (e.target.name == "list_duration") {
      if (e.target.value == "false") {
        this.setState({
          [e.target.name]: true,
        });
      } else {
        this.setState({
          [e.target.name]: false,
        });
      }
    } else {
      if (e.target.name == "min_list_duration") {

        if (e.target.name == "min_list_duration" && e.target.value >= this.state.timeStep) {
          this.setState({
            [e.target.name]: e.target.value,
            error: Object.assign(this.state.error, { [e.target.name]: error }),
          });
        }
      } else if (e.target.name == "max_list_duration") {

        if (e.target.name == "max_list_duration" && e.target.value >= this.state.timeStep) {
          this.setState({
            [e.target.name]: e.target.value,
            error: Object.assign(this.state.error, { [e.target.name]: error }),
          });
        }
      } else {
        if(e.target.name=="display_name" && e.target.value.length>30){
          return
        }else{
          this.setState({
            [e.target.name]: e.target.value,
            error: Object.assign(this.state.error, { [e.target.name]: error }),
          });
        }
       
      }
    }

  }
  handleChangeColor(color) {
    this.setState({ color: color.hex });
  }
  toogelLessonMinMax() {
    this.setState({ isOptionOpen: !this.state.isOptionOpen });

  }
  checkValidation(state) {
    const { name, display_name } = state;
    const error = { success: true };

    /* if (!name || name.length < 2) {
             error.name = true;
             error.success = false;
         } else {
             error.name = false;
         }*/
    if (!display_name || display_name.length < 2) {
      error.display_name = true;
      error.success = false;
    } else {
      error.display_name = false;
    }

    return error;
  }
  saveType() {
    const { lessonType } = this.props;
    const error = this.checkValidation(this.state);

    if (error.success) {
      if (this.state.min_list_duration > this.state.max_list_duration) {
        window.modal.alert("Error", "The max time must be greater then min time");
      } else {
        if (lessonType) {
          this.props.editLessonType(lessonType.id, { ...this.state });
        } else {
          this.props.addLessonType({ ...this.state });
        }

        this.setState({
          name: "",
          display_name: "",
          allowed_count: "",
          error: {},
        });
        this.props.clouseModal();
      }

    } else {
      this.setState({ error: Object.assign(this.state.error, error) });
    }
  }

  render() {
    const { clouseModal } = this.props;
    const { name, display_name, allowed_count, color, list_duration, min_list_duration, max_list_duration, timeStep, isOptionOpen } = this.state;
    const {
      name: Rname,
      display_name: Rdisplay_name,
      allowed_count: Rallowed_count,
    } = this.state.error;

    return (
      <div className="modal stripe-modal active">
        <div className="modal-content">
          <div className="close-btn">
            <button onClick={clouseModal}>
              <img src="/assets/img/cancel.png" alt="cancel" />
            </button>
          </div>
          <div className="modal-title">Create New Event Type</div>
          {this.props.lessonType &&(
            <div className="archiveBtnOfLesson">
            <Notification>Archive this service type from dashboard.</Notification>
            <button
                className="btn btn-gray archive rounded mr-2"
                title="Archive"
                type="button"
                onClick={this.HideLessonType}
                >
                <img src="/assets/img/archive.png" width={15} height={15} alt="archive" />
              </button>
          </div>
          )}
          <div className="auth-container lessonType lessonType_custom removeCoursorsForSafary">
            <FormGroup
              className={`${Rdisplay_name ? "error" : ""}`}
              type="text"
              name="display_name"
              label="*Event Type Name"
              onChange={this.HandleChange}
              value={display_name}
              maxlength="30"
            />
            <span className="counter_cutum">
              
              {display_name ?display_name.length: 0} / {display_name.length>0 ?30- display_name.length:30}
              
            </span> 
            <div className="form-group">
              <ColorPicker
                color={color}
                handleChangeColor={this.handleChangeColor}
              />
            </div>
            <div className={`switcherLabel lessonMinMaxLable ${isOptionOpen ? `showLessonMinMax` : ``}`}>
              <h4 onClick={this.toogelLessonMinMax}> Optional <span style={{ top: isOptionOpen ? "0px" : "6px", transform: isOptionOpen ? "rotate(44deg)" : "rotate(223deg)" }}>&#9698;</span></h4>
              <small style={{color:"#1bb1e4", fontSize:"14px"}}>If this service type to be used also as Private Session (in staff’s Availability For Private Session settings), please set Min and Max time limitations per booking. Otherwise ignore this optional portion.</small>
             <br /> <br />
              <span>Time limitations per booking Off/On</span>
              <GreenSwitcher
                value={list_duration}
                checked={list_duration}
                name="list_duration"
                onChange={this.HandleChange}

              />
              {list_duration && (
                <div className="lessonMinMax">
                  <Input
                    type="number"
                    name="min_list_duration"
                    id="min_list_duration"
                    onChange={this.HandleChange}
                    value={min_list_duration}
                    label="Min"
                    readOnly
                    min={timeStep}
                    step={timeStep}
                  />
                  <Input
                    type="number"
                    name="max_list_duration"
                    id="max_list_duration"
                    onChange={this.HandleChange}
                    value={max_list_duration}
                    label="Max"
                    readOnly
                    min={timeStep}
                    step={timeStep}
                  />
                </div>
              )}
            </div>
            {/*<FormGroup
                            className={`${Rname ? 'error' : ''}`}
                            type="text"
                            name="name"
                            label="*Lesson Name"
                            onChange={this.HandleChange}
                            value={name}
                        />*/}

            {/*<FormGroup
                            className={`${Rdisplay_name ? 'error' : ''}`}
                            type="text"
                            name="display_name"
                            label="*Display Name"
                            onChange={this.HandleChange}
                            value={display_name}
                        />
                        <FormGroup
                            className={`${Rallowed_count ? 'error' : ''}`}
                            type="number"
                            name="allowed_count"
                            label="*Allowed Count"
                            onChange={this.HandleChange}
                            value={allowed_count}
                        />*/}
            <div className="sign-up-btn-new" style={{margin:"auto"}}>
              <div className="pretty-button ">
                <button className="green-button" onClick={this.saveType}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.lesson.loading,
    timeStep: state.auth.user.business.booking_to_start,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addLessonType: (data) => dispatch(addLessonType(data)),
    editLessonType: (id, data) => dispatch(editLessonType(id, data)),
    GetLessonTypes: () => dispatch(GetLessonTypes()),
  };
};
LessonTypeModal.propTypes = {};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LessonTypeModal));
