import Homepage from './Homepage';
import MainSection from './MainSection';
import MacSection from './MacSection';
import BlueSection from './BlueSection';
import SectionD from './SectionD';

export default Homepage;
export {
    MainSection,
    MacSection,
    BlueSection,
    SectionD
}