import axios from "../config/axios";
import {
  ADD_MEMBER_SUCCESS,
  GET_MEMBER_ONLY_SUCCESS,
  GET_MEMBERS_ERROR,
  GET_MEMBERS_LOADING,
  GET_MEMBERS_GROUPS_LOADING,
  GET_MEMBERS_SUCCESS,
  REMOVE_MEMBERS_SUCCESS,
  GET_CUSTOMER_SUCCESS,
  GET_MEMBER_FAMILY_SUCCESS,
  GET_MEMBER_BALANCE_SUCCESS,
  GET_MEMBER_BOOKING_SUCCESS,
  GET_MEMBER_INVOICE_ITEM_SUCCESS,
  GET_MEMBER_INVOICE_HISTORY_SUCCESS,
  GET_MEMBER_GROUPS_SUCCESS,
  GET_CUSTOMER_LOADING,
  GET_MEMBER_POINTS_PAYMENTS_SUCCESS,
  ADD_MEMBER_POINTS_PAYMENTS_SUCCESS,
  BUY_MEMBER_POINTS_PAYMENTS_SUCCESS,
  GET_MEMBER_RESTRICTIONS_SUCCESS,
  GET_STAFF_BOOKING_OVERIDES_SUCCESS,
  GET_MEMBER_LEVEL_SUCCESS,
} from "../constants/Members";

import {
  getSourciesSuccess,
  getNbuFamilySuccess
} from "../actions/Auth";
import {
  getCustomerStaffSuccess,
  getBalanceStaffSuccess,
  getStaffBookingSuccess,
} from "../actions/Coach";

import {
  attachBusinessForNbu
} from "../utils/helpers";
import {
  setProfileImage
} from "./util";
import FileDownload from "js-file-download";
import { parseInt } from "lodash";

function getMembersSuccess(payload, notfirst) {
  return {
    type: GET_MEMBERS_SUCCESS,
    payload: {
      payload,
      notfirst,
    },
  };
}

function getCustomerSuccess(payload, member_id) {
  return {
    type: GET_CUSTOMER_SUCCESS,
    payload: {
      payload,
      member_id,
    },
  };
}

function getMemberFamilySuccess(payload, member_id) {
  return {
    type: GET_MEMBER_FAMILY_SUCCESS,
    payload: {
      ...payload,
      member_id,
    },
  };
}

function getMemberPointsPaymentsSuccess(payload, member_id) {
  return {
    type: GET_MEMBER_POINTS_PAYMENTS_SUCCESS,
    payload: {
      ...payload,
      member_id,
    },
  };
}
export function addMemberPointsPaymentsSuccess(payload, member_id) {
  return {
    type: ADD_MEMBER_POINTS_PAYMENTS_SUCCESS,
    payload: {
      points_payments: {
        ...payload,
      },
      member_id: member_id,
    },
  };
}
export function buyMemberPointsPaymentsSuccess(payload, member_id) {
  return {
    type: BUY_MEMBER_POINTS_PAYMENTS_SUCCESS,
    payload: {
      user_payment: {
        ...payload,
      },
      member_id: member_id,
    },
  };
}

function getMemberBalanceSuccess(payload, member_id) {
  return {
    type: GET_MEMBER_BALANCE_SUCCESS,
    payload: {
      ...payload,
      member_id,
    },
  };
}

function getMemberRestrictionsSuccess(payload, member_id) {
  return {
    type: GET_MEMBER_RESTRICTIONS_SUCCESS,
    payload: {
      ...payload,
      member_id,
    },
  };
}

export function getStaffBookingOveridesSuccess(payload, member_id) {
  return {
    type: GET_STAFF_BOOKING_OVERIDES_SUCCESS,
    payload: {
      ...payload,
      member_id,
    },
  };
}

function getMemberBookingSuccess(payload, member_id) {
  return {
    type: GET_MEMBER_BOOKING_SUCCESS,
    payload: {
      ...payload,
      member_id,
    },
  };
}

function getMemberLevelSuccess(payload, member_id) {
  return {
    type: GET_MEMBER_LEVEL_SUCCESS,
    payload: {
      ...payload,
      member_id,
    },
  };
}

function getMemberInvoiceItemsSuccess(payload, member_id) {
  return {
    type: GET_MEMBER_INVOICE_ITEM_SUCCESS,
    payload: {
      ...payload,
      member_id,
    },
  };
}

function getMemberInvoiceHistorySuccess(payload, member_id) {
  return {
    type: GET_MEMBER_INVOICE_HISTORY_SUCCESS,
    payload: {
      ...payload,
      member_id,
    },
  };
}

function getMemberGroupsSuccess(payload, member_id) {
  return {
    type: GET_MEMBER_GROUPS_SUCCESS,
    payload: {
      payload,
      member_id,
    },
  };
}

function getMemberOnlySuccess(payload) {
  return {
    type: GET_MEMBER_ONLY_SUCCESS,
    payload: payload,
  };
}

function getMembersError(payload) {
  return {
    type: GET_MEMBERS_ERROR,
    payload: payload,
  };
}

function getMembersLoading(bool) {
  return {
    type: GET_MEMBERS_LOADING,
    payload: bool,
  };
}

function getMembersGroupsLoading(bool) {
  return {
    type: GET_MEMBERS_GROUPS_LOADING,
    payload: bool,
  };
}

function getCustomerLoading(bool) {
  return {
    type: GET_CUSTOMER_LOADING,
    payload: bool,
  };
}

function removeMemberSuccess() {
  return {
    type: REMOVE_MEMBERS_SUCCESS,
    payload: true,
  };
}

function addMemberSuccess() {
  return {
    type: ADD_MEMBER_SUCCESS,
    payload: true,
  };
}
export function getCustomeresCount() {
  return async (dispatch) => {
    try {
      let response = await axios.request({
        url: "members/getCount",
        method: "GET",
      });
      return response
    } catch (error) {
      await window.modal.alert("Error", error.response.data.message);
      return false;
    }
  };
}
export function downloadMemberList() {
  return async (dispatch) => {
    try {
      let res = await axios.request({
        url: "reports/members",
        method: "GET",

        responseType: "blob",
      });

      FileDownload(res.data, `Customers.csv`);

      return true;
    } catch (error) {
      await window.modal.alert("Error", error.response.data.message);
      return false;
    }
  };
}
export function getMembers(lastUserId = -1, notfirst, all_groups = false) {
  return async (dispatch) => {
    dispatch(getMembersLoading(true));
    try {
      let res = await axios.get(`members/get?last_id=${lastUserId}&all_groups=${all_groups}`);
      if (res.data.code !== 200) throw new Error();
      dispatch(getMembersSuccess(res.data.payload, notfirst));
    } catch (error) {
      dispatch(
        getMembersError({
          message: error.message,
        })
      );
    } finally {
      dispatch(getMembersLoading(false));
    }
  };
}
export function getPublicMembers(lastUserId = -1, notfirst, all_groups = false) {
  return async (dispatch) => {
    dispatch(getMembersLoading(true));
    try {
      let res = await axios.get(
        `members/get-visitor?last_id=${lastUserId}&all_groups=${all_groups}`
      );
      if (res.data.code !== 200) throw new Error();
      dispatch(getMembersSuccess(res.data.payload, notfirst));
    } catch (error) {
      dispatch(
        getMembersError({
          message: error.message,
        })
      );
    } finally {
      dispatch(getMembersLoading(false));
    }
  };
}
export function GetMember(id, params) {
  return async (dispatch, getStore) => {
    let business_id = attachBusinessForNbu(getStore());
    const _filters =
      params &&
      Object.keys(params)
      .map((key) => {
        if (key === "type") {
          return key + "=" + params[key];
        } else {
          return key + "=" + params[key].clone().utc().format();
        }
      })
      .join("&");
    dispatch(getMembersLoading(true));
    try {
      let url = business_id ?
        `members/member/${id}/${business_id}/${
            _filters ? `?${_filters}` : ""
          }` :
        `members/member/${id}${_filters ? `?${_filters}` : ""}`;
      let res = await axios.get(url);
      dispatch(getMembersSuccess([res.data.payload]));
    } catch (error) {
      dispatch(
        getMembersError({
          message: error.message,
        })
      );
      await window.modal.alert("Error", error.response.data.message);
    } finally {
      dispatch(getMembersLoading(false));
    }
  };
}
export function GetPublicMember(id, params) {
  return async (dispatch, getStore) => {
    let business_id = attachBusinessForNbu(getStore());
    const _filters =
      params &&
      Object.keys(params)
      .map((key) => {
        if (key === "type") {
          return key + "=" + params[key];
        } else {
          return key + "=" + params[key].clone().utc().format();
        }
      })
      .join("&");
    dispatch(getMembersLoading(true));
    try {
      let url =  `members/visitor/${id}`;
      let res = await axios.get(url);
      dispatch(getMembersSuccess([res.data.payload]));
    } catch (error) {
      dispatch(
        getMembersError({
          message: error.message,
        })
      );
      await window.modal.alert("Error", error.response.data.message);
    } finally {
      dispatch(getMembersLoading(false));
    }
  };
}

export function setFamilyMembers(id, params) {
  return async (dispatch) => {
    dispatch(getMembersLoading(true));
    try {
      let res = await axios.post(`members/setfamilymembers/${id}`, {
        ...params,
      });
      dispatch(getMemberFamilySuccess(res.data.payload, id));
    } catch (error) {
      dispatch(
        getMembersError({
          message: error.message,
        })
      );
      dispatch(getMembersLoading(false));
      await window.modal.alert("Error", error.response.data.message);
    } finally {
      dispatch(getMembersLoading(false));
    }
  };
}

export function AddToGroup(member_id, group_ids, keep_others = false) {
  return async (dispatch) => {
    dispatch(getMembersGroupsLoading(true));
    try {
      let res = await axios.post(
        keep_others ? `members/addtogroup?keep_others=1` : `members/addtogroup`, {
          group_ids: group_ids,
          member_id: member_id,
        }
      );
      dispatch(getMemberGroupsSuccess(res.data.payload, member_id));
    } catch (error) {
      dispatch(
        getMembersError({
          message: error.message,
        })
      );
      await window.modal.alert("Error", error.response.data.message);
    } finally {
      dispatch(getMembersGroupsLoading(false));
    }
  };
}

export async function searchMembers(string) {
  try {
    let url = `members/get?last_id=-1`;

    if (string) {
      url = `members/get?last_id=-1&search=${string}`;
    }

    let res = await axios.get(url);
    let {
      payload
    } = res.data;
    if (payload.length > 0) {
      // returning the label for react-select baed on the title
      return payload.map((item) => ({
        label: item.member.first_name + " " + item.member.last_name,
        value: item.id,
        data: item,
      }));
    } else {
      return [];
    }
  } catch (error) {
    console.log(error);
  }
}

export async function searchUsers(string) {
  try {
    let url = `members/get?last_id=-1`;

    if (string) {
      url = `members/get?last_id=-1&search=${string}&include_coach=true`;
    }

    let res = await axios.get(url);
    let payload = res.data.payload;
    if (payload.length > 0) {
      // returning the label for react-select baed on the title
      return payload.map((item) => ({
        label: item.member.first_name + " " + item.member.last_name,
        value: item.member.id,
        ...item,
      }));
    } else {
      return [{
        label: string,
        value: null,
      }];
    }
  } catch (error) {
    console.log(error);
  }
}

export function getCustomerInfo(id, customer_id, admin = false, options = {}) {
  return async (dispatch) => {
    dispatch(getCustomerLoading(true));
    try {
      let is_staff = options.is_staff ? true : false;
      if (null !== customer_id) {
        let _url = admin ?
          "payment/getbusinesscustomer/" :
          "payment/getcustomer/";

        let res = await axios.get(`${_url}${customer_id}`);

        if (is_staff) {
          dispatch(getCustomerStaffSuccess(res.data.payload, id));
        }
        if (!is_staff) {
          if (admin) {
            dispatch(getSourciesSuccess(res.data.payload));
          } else {
            dispatch(getCustomerSuccess(res.data.payload, id));
          }
        }
      } else {
        if (is_staff) {
          dispatch(
            getCustomerStaffSuccess({
                default_source: -1,
                sources: {
                  data: [],
                },
                subscriptions: {
                  data: [],
                },
              },
              id
            )
          );
        } else {
          dispatch(
            getCustomerSuccess({
                default_source: -1,
                sources: {
                  data: [],
                },
                subscriptions: {
                  data: [],
                },
              },
              id
            )
          );
        }
      }
    } catch (error) {
      console.log(error);
      dispatch(
        getMembersError({
          message: error.message,
        })
      );
    } finally {
      dispatch(getCustomerLoading(false));
    }
  };
}
export function getFamilyMembers(id, business_id = null, global = false) {
  return async (dispatch) => {
    dispatch(getCustomerLoading(true));
    try {
      let _url = `members/getfamily/${id}`;
      _url = global ? `users/getfamily/${id}/${business_id}` : _url;
      let res = await axios.get(_url);

      if (global) {
        dispatch(getNbuFamilySuccess(res.data.payload));
      } else {
        dispatch(
          getMemberFamilySuccess({
              family: res.data.payload,
            },
            id
          )
        );
      }
    } catch (error) {
      dispatch(
        getMembersError({
          message: error.message,
        })
      );
    } finally {
      dispatch(getCustomerLoading(false));
    }
  };
}

export function getMemberPointsPurchaseHistory(
  user_id,
  member_id,
  filters,
  is_staff,
  gifts = false
) {
  return async (dispatch) => {
    dispatch(getCustomerLoading(true));
    try {
      const _filters =
        filters &&
        Object.keys(filters)
        .map((key) => {
          if (key === "type") {
            return key + "=" + filters[key];
          } else {
            return key + "=" + filters[key].clone().utc().format();
          }
        })
        .join("&");
      let _url = gifts ?
        `point/getgifts/${user_id}${_filters ? `?${_filters}` : ""}` :
        `members/points_payments/${user_id}${_filters ? `?${_filters}` : ""}`;
      let res = await axios.get(_url);

      dispatch(
        getMemberPointsPaymentsSuccess(
          gifts ? {
            points_gifts: res.data.payload,
          } : {
            points_payments: res.data.payload,
          },
          member_id
        )
      );
    } catch (error) {
      console.error(error);
      dispatch(
        getMembersError({
          message: error.message,
        })
      );
    } finally {
      dispatch(getCustomerLoading(false));
    }
  };
}

function prepareFiltersSearch(filters) {
  if (Object.keys(filters).length > 0) {
    delete filters.family;
    for (let filter in filters) {
      if (filter !== "type" && filter !== "is_coach") {
        filters[filter] = filters[filter].clone().format();
      }
    }
  }

  return filters;
}
export function getMemberBalance(id, member_id, filters = {}, is_staff) {
  return async (dispatch) => {
    dispatch(getCustomerLoading(true));
    try {
      let res = await axios.post(`members/balance/${id}`, {
        ...prepareFiltersSearch({
          ...filters,
        }),
      });

      if (is_staff) {
        dispatch(getBalanceStaffSuccess(res.data.payload, id));
      } else {
        dispatch(getMemberBalanceSuccess(res.data.payload, member_id));
      }
    } catch (error) {
      console.log(error);
      dispatch(
        getMembersError({
          message: error.message,
        })
      );
    } finally {
      dispatch(getCustomerLoading(false));
    }
  };
}
export function getMemberRestrictions(id, member_id, options = {}, is_staff) {
  return async (dispatch) => {
    dispatch(getCustomerLoading(true));
    try {
      let res = await axios.post(`restrictions/statistics/${id}`, {
        time_unit: options.time_unit,
        unit_period: options.unit_period || "hour",
        filters: {
          ...prepareFiltersSearch({
            ...options.filters,
          }),
        },
      });

      if (is_staff) {
        dispatch(getBalanceStaffSuccess(res.data.payload, id));
      } else {
        dispatch(getMemberRestrictionsSuccess(res.data.payload, member_id));
      }
    } catch (error) {
      console.log(error);
      dispatch(
        getMembersError({
          message: error.message,
        })
      );
    } finally {
      dispatch(getCustomerLoading(false));
    }
  };
}

export function getMemberBooking(
  id,
  member_id,
  filters = {},
  is_staff = false
) {
  return async (dispatch) => {
    dispatch(getCustomerLoading(true));
    try {
      let res = await axios.post(`members/booking/${id}`, {
        ...prepareFiltersSearch({
          ...filters,
        }),
      });

      if (is_staff) {
        dispatch(getStaffBookingSuccess(res.data.payload, id));
      } else {
        dispatch(getMemberBookingSuccess(res.data.payload, member_id));
      }
    } catch (error) {
      console.log(error);
      dispatch(
        getMembersError({
          message: error.message,
        })
      );
    } finally {
      dispatch(getCustomerLoading(false));
    }
  };
}

export function getLevels(id, member_id, is_staff = false) {
  return async (dispatch) => {
    dispatch(getCustomerLoading(true));
    try {
      let res = await axios.get(`members/levels/${id}`);

      if (is_staff) {
        dispatch(getStaffBookingSuccess(res.data.payload, id));
      } else {
        dispatch(
          getMemberLevelSuccess({
              levels: res.data.payload,
            },
            member_id
          )
        );
      }
    } catch (error) {
      console.log(error);
      dispatch(
        getMembersError({
          message: error.message,
        })
      );
    } finally {
      dispatch(getCustomerLoading(false));
    }
  };
}
export function setLevels(id, member_id, data = [], is_staff = false) {
  return async (dispatch) => {
    dispatch(getCustomerLoading(true));
    try {
      let res = await axios.put(`members/levels/${id}`, {
        levels: [...data],
      });

      if (is_staff) {
        dispatch(getStaffBookingSuccess(res.data.payload, id));
      } else {
        dispatch(
          getMemberLevelSuccess({
              levels: res.data.payload,
            },
            member_id
          )
        );
      }
      window.modal.alert("Alert", "Success");
    } catch (error) {
      console.log(error);
      dispatch(
        getMembersError({
          message: error.message,
        })
      );
    } finally {
      dispatch(getCustomerLoading(false));
    }
  };
}

export function getMemberInvoiceItems(user_id, id) {
  return async (dispatch) => {
    dispatch(getCustomerLoading(true));
    try {
      let res = await axios.get(`subscription/get/${user_id}`);

      dispatch(
        getMemberInvoiceItemsSuccess({
            invoice_items: res.data.payload,
          },
          id
        )
      );
    } catch (error) {
      dispatch(
        getMembersError({
          message: error.message,
        })
      );
    } finally {
      dispatch(getCustomerLoading(false));
    }
  };
}

export function getMemberInvoiceHistory(user_id, id) {
  return async (dispatch) => {
    dispatch(getCustomerLoading(true));
    try {
      let res = await axios.get(`members/invoice/history/${user_id}`);

      dispatch(
        getMemberInvoiceHistorySuccess({
            invoice_history: res.data.payload,
          },
          id
        )
      );
    } catch (error) {
      dispatch(
        getMembersError({
          message: error.message,
        })
      );
    } finally {
      dispatch(getCustomerLoading(false));
    }
  };
}
export function cancelMemberInvoiceItems(
  invoice_id,
  user_id,
  id,
  options = {}
) {
  return async (dispatch) => {
    dispatch(getCustomerLoading(true));
    try {
      let res = await axios.post(`subscription/${invoice_id}`, {
        ...options,
      });

      dispatch(getMemberInvoiceItems(user_id, id));
    } catch (error) {
      dispatch(
        getMembersError({
          message: error.message,
        })
      );
    } finally {
      dispatch(getCustomerLoading(false));
    }
  };
}

export function refundItem(user_id, member_id, options = {}) {
  return async (dispatch) => {
    const from = options.from ? options.from : "invoice";
    dispatch(getCustomerLoading(true));
    try {
      await axios.post(`payment/charges/refunditem`, {
        ...options,
      });

      if (user_id) {
        switch (from) {
          case "invoice":
            dispatch(getMemberInvoiceHistory(user_id, member_id));
            break;

          default:
            break;
        }
      }
    } catch (error) {
      window.modal.alert("Alert", error.response.data.message);
    } finally {
      dispatch(getCustomerLoading(false));
    }
  };
}

export function getMembersBysearch(string, plan, all_groups = false) {
  return async (dispatch) => {
    dispatch(getMembersLoading(true));
    try {
      let url = `members/get?last_id=-1&all_groups=${all_groups}`;
      if (string && plan) {
        url = `members/get?last_id=-1&search=${string}&plan=${plan}&all_groups=${all_groups}`;
      }
      if (string && !plan) {
        url = `members/get?last_id=-1&search=${string}&all_groups=${all_groups}`;
      }
      if (!string && plan) {
        url = `members/get?last_id=-1&plan=${plan}&all_groups=${all_groups}`;
      }
      let res = await axios.get(url);

      dispatch(getMembersSuccess(res.data.payload));
    } catch (error) {
      dispatch(
        getMembersError({
          message: error.message,
        })
      );
    } finally {
      dispatch(getMembersLoading(false));
    }
  };
}
export function getMembersBysearchPublic(string, plan, all_groups = false) {
  return async (dispatch) => {
    dispatch(getMembersLoading(true));
    try {
      let url = `members/get-visitor?last_id=-1&all_groups=${all_groups}`;
      if (string && plan) {
        url = `members/get-visitor?last_id=-1&search=${string}&plan=${plan}&all_groups=${all_groups}`;
      }
      if (string && !plan) {
        url = `members/get-visitor?last_id=-1&search=${string}&all_groups=${all_groups}`;
      }
      if (!string && plan) {
        url = `members/get-visitor?last_id=-1&plan=${plan}&all_groups=${all_groups}`;
      }
      let res = await axios.get(url);

      dispatch(getMembersSuccess(res.data.payload));
    } catch (error) {
      dispatch(
        getMembersError({
          message: error.message,
        })
      );
    } finally {
      dispatch(getMembersLoading(false));
    }
  };
}
export function removeclubmember(uid) {
  return async (dispatch) => {
    dispatch(getMembersLoading(true));
    try {
      await axios.delete(`members/delete/${uid}`);
      dispatch(removeMemberSuccess());
      let res = await axios.get(`members/get?last_id=-1`);

      dispatch(getMembersSuccess(res.data.payload));
    } catch (error) {
      dispatch(
        getMembersError({
          message: error.message,
        })
      );
      console.error(error);
    } finally {
      dispatch(getMembersLoading(false));
    }
  };
}
export function removePubblicMember(uid) {
  return async (dispatch) => {
    dispatch(getMembersLoading(true));
    try {
      await axios.post(`members/remove-visitor`,{visitor_id:parseInt(uid)});
      dispatch(removeMemberSuccess());
      let res = await axios.get(`members/get-visitor?last_id=-1`);

      dispatch(getMembersSuccess(res.data.payload));
    } catch (error) {
      dispatch(
        getMembersError({
          message: error.message,
        })
      );
      console.error(error);
    } finally {
      dispatch(getMembersLoading(false));
    }
  };
}
export function addToMembers(email) {
  return async (dispatch) => {
    dispatch(getMembersLoading(true));
    try {
      await axios.post(`members/visitor-to-member`,{email:email});
      dispatch(removeMemberSuccess());
      let res = await axios.get(`members/get?last_id=-1`);

      dispatch(getMembersSuccess(res.data.payload));
    } catch (error) {
      dispatch(
        getMembersError({
          message: error.message,
        })
      );
      console.error(error);
    } finally {
      dispatch(getMembersLoading(false));
    }
  };
}

export function addclubmember(data) {
  return async (dispatch) => {
    dispatch(getMembersLoading(true));
    try {
      const {
        error,
        username,
        family,
        child,
        spouse,
        email,
        level,
        level_ntrp,
        level_type,
        about,
        doc_signed_current,
        modalActive,
        selectedFile,
        profile_image,
        max_date,
        ...rest
      } = data;
      const family_id = family ? family.value : null;
      const is_child = child ? 1 : 0;
      const is_spouse = spouse ? 1 : 0;
      const _email = email ? email : null;
      const _level = level ? level : 0;
      let resAdd = await axios.post("members/add", {
        ...rest,
        email: _email,
        family_id: family_id,
        is_child: is_child,
        level: _level,
        is_spouse: is_spouse,
      });

      dispatch(addMemberSuccess());
      let res = await axios.get("members/get?last_id=-1");
      dispatch(getMembersSuccess(res.data.payload));
      return true;
    } catch (error) {
      if (error.response && error.response.data.message) {
        window.modal.alert("Alert", error.response.data.message);
      } else {
        window.modal.alert("Alert", error.message);
      }

      return false;
    } finally {
      dispatch(getMembersLoading(false));
    }
  };
}
export function updateclubmember(id, data) {
  return async (dispatch) => {
    dispatch(getMembersLoading(true));
    try {
      const {
        error,
        family,
        child,
        spouse,
        email,
        profile_image,
        selectedFile,
        modalActive,
        level_ntrp,
        level_type,
        doc_signed_current,
        level,
        max_date,
        ...rest
      } = data;
      const _email = email ? email : null;
      const _level = level ? level : 0;
      const is_child = child ? 1 : 0;
      if (data.selectedFile) {
        await setProfileImage("users/fileupload", data.selectedFile);
      }
      let resAdd = await axios.put(`members/update/${id}`, {
        ...rest,
        is_child: is_child,
        level: _level,
        email: _email,
      });
      dispatch(getMembersLoading(false));
      dispatch(addMemberSuccess());
      dispatch(GetMember(id));
    } catch (error) {
      if (error.response && error.response.data.message) {
        window.modal.alert("Alert", error.response.data.message);
      } else {
        window.modal.alert("Alert", error.message);
      }
    } finally {
      dispatch(getMembersLoading(false));
    }
  };
}

export function getMemberOnly(uid) {
  return async (dispatch) => {
    dispatch(getMembersLoading(true));
    try {
      let res = await axios.post("user/public/info", {
        uid,
      });
      if (res.data.code !== 200) throw new Error();
      dispatch(getMemberOnlySuccess(res.data.payload[0]));
    } catch (error) {
      dispatch(
        getMembersError({
          message: error.message,
        })
      );
    } finally {
      dispatch(getMembersLoading(false));
    }
  };
}

export function importFromCsv(data) {
  return async (dispatch) => {
    dispatch(getMembersLoading(true));
    try {
      let formData = new FormData();
      formData.append("filename", data.selectedFile);

      let resfile = await axios.post("members/uploadcsv", formData, {
        headers: {
          // eslint-disable-next-line no-undef
          "Content-Type": "multipart/form-data",
        },
      });

      window.modal.alert("Alert", "Done");
      dispatch(getMembers());
    } catch (error) {
      window.modal.alert("Error", "Incorrect Fields in the File ");
      dispatch(
        getMembersError({
          message: error.message,
        })
      );
    } finally {
      dispatch(getMembersLoading(false));
    }
  };
}
export function sendBulkEmail(data) {
  return async (dispatch) => {
    dispatch(getMembersLoading(true));
    try {
      await axios.post("members/send_bulk_email", data);

      window.modal.alert("Alert", "Success");
    } catch (error) {
      if (error.response && error.response.data) {
        await window.modal.alert("Warning", error.response.data.message);
      } else {
        window.modal.alert("Error", "Operaition failed ");
      }
    } finally {
      dispatch(getMembersLoading(false));
    }
  };
}

//Member Restrictions overides.
export function getMemberRestrictionsOverides(id, member_id, is_staff) {
  return async (dispatch) => {
    dispatch(getCustomerLoading(true));
    try {
      let res = await axios.get(`restrictions/overides?user_id=${id}`);
      if (is_staff) {
        dispatch(getBalanceStaffSuccess(res.data.payload, id));
      } else {
        dispatch(getStaffBookingOveridesSuccess(res.data.payload, member_id));
      }
    } catch (error) {
      dispatch(
        getMembersError({
          message: error.message,
        })
      );
    } finally {
      dispatch(getCustomerLoading(false));
    }
  };
}

export function deleteMemberRestrictionsOveride(
  id,
  member_id,
  data = {},
  is_staff
) {
  return async (dispatch) => {
    dispatch(getCustomerLoading(true));
    try {
      await axios.delete(`restrictions/overides/${data.id}`);
      dispatch(getMemberRestrictionsOverides(id, member_id, is_staff));
    } catch (error) {
      dispatch(
        getMembersError({
          message: error.message,
        })
      );
    } finally {
      dispatch(getCustomerLoading(false));
    }
  };
}

export function saveMemberRestrictionsOverides(
  id,
  member_id,
  data = {},
  is_staff
) {
  return async (dispatch) => {
    dispatch(getCustomerLoading(true));
    try {
      let _id = data.id ? data.id : null;
      if (_id) {
        delete data.id;
        await axios.put(`restrictions/overides/${_id}`, {
          ...data,
        });
      } else {
        await axios.post(`restrictions/overides`, {
          ...data,
        });
      }

      dispatch(getMemberRestrictionsOverides(id, member_id, is_staff));
    } catch (error) {
      dispatch(
        getMembersError({
          message: error.message,
        })
      );
    } finally {
      dispatch(getCustomerLoading(false));
    }
  };
}
