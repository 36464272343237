import React, { Component } from "react";
import AutoFill from "../UI/AutoFill";
import Notification from "../Notification";
import isEqual from "lodash/isEqual";
import "./booked-by.scss";

class BookedBy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openAddNewName: true,
      booked_persons_during_add: [],
    };
  }

  addNewName = () => {
    this.setState((prevState) => {
      return { openAddNewName: !prevState.openAddNewName };
    });
  };

  changeFreeText = (bol) => {

    this.setState({ isFreetext: bol })
  }

  handleBook = (member_id, book) => {
    const { _during_add, partner, title, isWhoCanBookPublic } = this.props;
    const { isFreetext } = this.state

    if (!member_id) {
      if (!isFreetext && title != "Guest(s)" && !isWhoCanBookPublic) {
        window.modal.alert("Notice", "No free text allowed for customer names. Select the name from the autofill suggestions when typing customer name.");
        return
      }
    }


    if (_during_add) {
      member_id = member_id || -1;
      if (partner && member_id === -1) {
        return false;
      }
      this.setState((prevState) => {
        if (
          !prevState.booked_persons_during_add
            .map((v) => v.id)
            .includes(member_id) ||
          member_id === -1
        ) {
          return {
            booked_persons_during_add: [
              ...prevState.booked_persons_during_add,
              {
                id: member_id,
                name: book.userFullName,
              },
            ],
          };
        }
      });
    }
  };

  handleCancellationDuringCreation = (i) => {
    this.setState((prevState) => {
      const prev_presons = [...prevState.booked_persons_during_add];
      prev_presons.splice(i, 1);
      return {
        booked_persons_during_add: [...prev_presons],
      };
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const { booked_persons_during_add } = this.state;
    const {
      booked_persons_during_add: booked_persons_during_add_old,
    } = prevState;
    if (!isEqual(booked_persons_during_add, booked_persons_during_add_old)) {
      if (this.props.onChangeBookedBy) {
        this.props.onChangeBookedBy([...booked_persons_during_add]);
      }
    }
  }

  render() {
    const { openAddNewName, booked_persons_during_add, isFreetext } = this.state;
    const {
      quantity,
      add_partners,
      title,
      user,
      partner,
      button_name,
      _during_add,
    } = this.props;
    const is_can_add =
      quantity && quantity > 0 && quantity > booked_persons_during_add.length;

    return (
      <div className="bookedby-container">
        <div
          className={
            add_partners
              ? `form-enlarge-container no-padding`
              : `form-enlarge-container charge-col booked-by-section`
          }
        >
          <div className="modal-title">
            <span>
              {add_partners ? ` ${title}` : `Booked by (customer name)`}
            </span>
          </div>
          {this.props.ms && (
            <div className="alert-box alert-info">
              <span>
                You can add bookings to this listing if you already have a
                customer(s) in mind or you can just save the listing to be
                booked by your staff and by the people selected by you in the
                Who Can Book section of this form.
              </span>
            </div>
          )}
          <div className="autofill-">
            {is_can_add && (
              <div className="plus-icon" id="plus">
                <button type="button" onClick={this.addNewName}>
                  <img
                    src="/assets/img/plus.png"
                    title="Add new name"
                    alt="Add new name"
                  />
                </button>
              </div>
            )}

            {openAddNewName && (
              <AutoFill
                changeFreeText={this.changeFreeText}
                isFreeText={isFreetext}
                handleBook={this.handleBook}
                during_add={_during_add}
                button_name={button_name}
                add_partners={add_partners}
                partner={partner}
                id={-1}
                user={user}
                lessonState={this.state}
              />
            )}
          </div>
          {booked_persons_during_add.length > 0 ? (
            <div className="booked-persons">
              <table className="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th className="w-75">Name</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {booked_persons_during_add.map((each, i) => {
                    return (
                      <tr key={i}>
                        <td>{each.name}</td>
                        <td>
                          <div className="make-it-flex">
                            <React.Fragment>
                              <img
                                title="Charge Card Not On File"
                                onClick={async () => {
                                  return await window.modal.alert(
                                    "Info",
                                    "Save the booking to be able to charge a payment."
                                  );
                                }}
                                className="chargeButton"
                                src="/assets/img/dollar-paper-bills-stack-guest.svg"
                                alt="Charge Card Not On File"
                              />
                              <img
                                title="Cancel"
                                onClick={() => {
                                  return this.handleCancellationDuringCreation(
                                    i
                                  );
                                }}
                                className="chargeButton w-29"
                                src="/assets/img/error-cancel.svg"
                                alt="Cancel"
                              />
                            </React.Fragment>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

BookedBy.defaultProps = {
  userType: "owner",
};
BookedBy.propTypes = {};

export default BookedBy;
