import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ResetPass } from '../../actions/Auth';
import FormGroup from '../../components/FormGroup';

const Forgot = (props) => {
  const [{ userName, numbersValue, redBorder }, setState] = useState({ userName: '', numbersValue: "", redBorder: 0 });
  const [trueText, setTrueText] = useState('');
  const [disableSave, setDisableSave] = useState(true);
  const { loading } = useSelector(state => ({
    loading: state.auth.loading,

  }));

  const dispatch = useDispatch();
  let isDisable = useState(disableSave) || loading;
  const HandleChange = useCallback(
    e => {
      const { name, value } = e.target;
      setState(st => ({ ...st, [name]: value.replace(/\s/g, "") }));
    },
    [setState]
  );


  function generateRundomText(lengthu) {
    let result = '';
    //const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const characters = '123456789';
    const charactersLength = characters.length;
    for (var i = 0; i < lengthu; i++) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    setTrueText(result);
  }

  const HandleSubmit = useCallback(
    e => {
      dispatch(ResetPass(userName));
     
      setTimeout(() => {
       
      if(props.err().message==null){
        props.onChangeModalTarget('login')
        
      }
    }, 2000)
    },
    [dispatch, userName]
  );
  useEffect(() => {
    generateRundomText(5)
  }, []);
  return (
    <div>
      <FormGroup type="text" name="userName" placeholder="Username or Email" onChange={HandleChange} value={userName} />
      <div className="checkIfCancelTrueLogin">
        <span>{trueText}</span>
        <input onPaste={(e) => {
          e.preventDefault()
          return false;
        }} value={numbersValue} style={{ borderColor: redBorder == 1 ? 'red' : (redBorder == 2 ? 'green' : '') }} onChange={e => {
          const { value } = e.target;
          setState(st => ({ ...st, numbersValue: value }));
          if (e.target.value.length == trueText.length) {
            if (e.target.value == trueText) {
              isDisable = false
              setState(st => ({ ...st, redBorder: 2 }));
              setDisableSave(false)
            } else {
              setState({ redBorder: 1 });
            }
          }
        }} type="text" />
      </div>
      <div className="signin">
        <button disabled={loading || disableSave} onClick={HandleSubmit}>
          Reset
        </button>
      </div>
    </div>
  );
};

export default Forgot;
