import axios from "../config/axios";

import {
  SIGN_IN_SUCCESS,
  SIGN_IN_ERROR,
  SIGN_IN_LOADING,
  SIGN_UP_SUCCESS,
  SIGN_UP_ERROR,
  SIGN_UP_LOADING,
  GET_MEMBERSHIPS_SUCCESS,
  SIGN_OUT_ERROR,
  SIGN_OUT_SUCCESS,
  RESET_PASS_SUCCESS,
  RESET_PASS_ERROR,
  RESET_PASS_LOADING,
  CHANGE_PASS_SUCCESS,
  CLEAR_ERRORS,
  CHANGE_PLAN_ID_SELECED,
  SET_DEMO_URL_CODE,
  TAX_RATE_UPDATE_LOADING,
  TAX_RATE_UPDATE_SUCCESS,
  GET_SOURCIES_SUCCESS,
  ADD_SOURCIES_SUCCESS,
  CHANGE_SOURCIES_SUCCESS,
  REMOVE_CARD_SUCCESS,
  GET_NBU_FAMILIY_SUCCESS,
  SIGNED_SUCCESS,
} from "../constants/Auth";
import {
  STRIPE_CONNECT_SUCCESS,
  STRIPE_NO_CONNECT_SUCCESS,
} from "../constants/Stripe";
import {
  GLOBAL_LOCATION_FACILITY_PAIR
} from "../utils/constants";

import {
  setProfileImage
} from "./util";

export function changePlanId(planId) {
  return {
    type: CHANGE_PLAN_ID_SELECED,
    payload: planId,
  };
}

export function getSourciesSuccess(payload) {
  return {
    type: GET_SOURCIES_SUCCESS,
    payload: payload,
  };
}
export function getNbuFamilySuccess(payload) {
  return {
    type: GET_NBU_FAMILIY_SUCCESS,
    payload: payload,
  };
}
export function addSourciesSuccess(payload) {
  return {
    type: ADD_SOURCIES_SUCCESS,
    payload: payload,
  };
}
export function signedSuccess() {
  return {
    type: SIGNED_SUCCESS,
    payload: true,
  };
}
export function changeSourciesSuccess(payload) {
  return {
    type: CHANGE_SOURCIES_SUCCESS,
    payload: payload,
  };
}
export function removeCardBusiness(payload) {
  return {
    type: REMOVE_CARD_SUCCESS,
    payload: payload,
  };
}

export function setDemoUrlCode(code) {
  return {
    type: SET_DEMO_URL_CODE,
    payload: code,
  };
}

function SignUpErorr(error) {
  return {
    type: SIGN_UP_ERROR,
    payload: error,
  };
}

function SignInErorr(error) {
  return {
    type: SIGN_IN_ERROR,
    payload: error,
  };
}

function ascSortById(a, b) {
  if (a.id < b.id) {
    return -1;
  }
  if (a.id > b.id) {
    return 1;
  }
  return 0;
}

function SignInSuccess(user) {
  // todo: this is temporary solution
  try {
    let activePair = window.localStorage.getItem(GLOBAL_LOCATION_FACILITY_PAIR);

    if (!activePair || activePair === "undefined") {
      user.locations = user.locations.sort(ascSortById);
      user.locations.forEach((loc) => {
        loc.facility_groups = loc.facility_groups.sort(ascSortById);
      });

      const loc = user.locations[0];
      const facilityGroup = loc.facility_groups[0];
      activePair = `${loc.id}:${facilityGroup?.id}`;
      localStorage.setItem(
        GLOBAL_LOCATION_FACILITY_PAIR,
        `${loc.id}:${facilityGroup?.id}`
      );
    }

    const [locId, facId] = activePair.split(":");
    const loc = user.locations.find((itm) => itm.id == locId)?user.locations.find((itm) => itm.id == locId):user.locations[0];
    const facilityGroup = loc.facility_groups.find((it) => it.id == facId);

    Object.keys(facilityGroup).forEach((key) => {
      if (!user.business) user.business = {};
      if (!user.business.hasOwnProperty(key))
        user.business[key] = facilityGroup[key];
    });

    user.business.timezone = loc.timezone;
  } catch (e) {
    console.log("log: error during shit: ", e);
   // window.location.reload();
  }

  return {
    type: SIGN_IN_SUCCESS,
    payload: user,
  };
}

function SignUpSuccess(user) {
  return {
    type: SIGN_UP_SUCCESS,
    payload: user,
  };
}

function SignOutErorr(error) {
  return {
    type: SIGN_OUT_ERROR,
    payload: error,
  };
}

function SignOutSuccess(message) {
  window.localStorage.removeItem(GLOBAL_LOCATION_FACILITY_PAIR);
  return {
    type: SIGN_OUT_SUCCESS,
    payload: {
      message: message,
    },
  };
}

function SignInLoading(loading) {
  return {
    type: SIGN_IN_LOADING,
    payload: loading,
  };
}

function SignUpLoading(loading) {
  return {
    type: SIGN_UP_LOADING,
    payload: loading,
  };
}

function GetMemberShipsSuccess(data) {
  return {
    type: GET_MEMBERSHIPS_SUCCESS,
    payload: data,
  };
}

function ChangePassSuccess(newToken) {
  return {
    type: CHANGE_PASS_SUCCESS,
    payload: newToken,
  };
}

function ResetPassSuccess(data) {
  return {
    type: RESET_PASS_SUCCESS,
    payload: data,
  };
}

function ResetPassError(error) {
  return {
    type: RESET_PASS_ERROR,
    payload: error,
  };
}

function ResetPassLoading(loading) {
  return {
    type: RESET_PASS_LOADING,
    payload: loading,
  };
}

export function CleareErrors() {
  return {
    type: CLEAR_ERRORS,
    payload: {},
  };
}

function StripeConnectrdSuccess() {
  return {
    type: STRIPE_CONNECT_SUCCESS,
    payload: {},
  };
}

function StripeNoConnectrdSuccess() {
  return {
    type: STRIPE_NO_CONNECT_SUCCESS,
    payload: {},
  };
}

export function IsSignIn() {
  return async (dispatch) => {
    dispatch(SignInLoading(true));
    try {
      const token = window.localStorage.getItem("token");

      if (!token) {
        dispatch(SignInLoading(false));
        return;
      }

      let res = await axios.get("users/info");
     
      const user = res.data.payload;
      const business = user.business || false;

      dispatch(SignInSuccess(user));
      if (business && business.payment_account_id) {
        dispatch(StripeConnectrdSuccess());
      } else {
        dispatch(StripeNoConnectrdSuccess());
      }
    } catch (error) {
      dispatch(
        SignInErorr({
          message: error.response.data.message?error.response.data.message:"",
        })
      );
    } finally {
      dispatch(SignInLoading(false));
    }
  };
}

export function SignIn({
    userName: username,
    password,
    WorkspaceId: namespace,
    role: user_type
  },
  router,
  referer
) {
  return async (dispatch) => {
    try {
      username = username.trim().toLowerCase();
      dispatch(SignInLoading(true));
      let res = await axios.post("auth/login", {
        username,
        password,
        front: true,
      });
      const {
        user,
        token
      } = res.data.payload;
      const business = res.data.payload.user.business || false;
      
      dispatch(SignInSuccess(user));
      // eslint-disable-next-line no-undef
      window.localStorage.setItem("token", token);
      
      //TODO WILL BE ADDED AFTER PAYMENT IMPLEMENTATION
      if (business && business.payment_account_id) {
        dispatch(StripeConnectrdSuccess());
        dispatch(IsSignIn());
      } else {
        dispatch(StripeNoConnectrdSuccess());
      }

      if (referer) {
        router.push(referer);
        return;
      }

      let ress = await axios.get("users/info");
     
      let {
        key
      } = user.member_type;
      switch (key) {
        case "proshop":
          return router.push("/private/stripe");
          // break;
        case "member":
          return router.push("/private/choose-business");
          // break;
        default:
          break;
      }
      if (user.business && user.business.fields_count) {
        router.push("/private/dashboard");
      } else {
        router.push("/private/profile");
      }
    } catch (error) {
     // console.error(error);

      if(error.response.data.err.name=="BlockError"){
        router.push("/NotFound");
        dispatch(
          SignInErorr({
            message: error.response.data.message,
          })
        );
       return
      }
     
      if (error.message != "Network Error") {

        dispatch(
          SignInErorr({
            message: "Incorrect Credentials",
          })
        );
      }
    } finally {
      //  dispatch(await createpaymentauthtoken());
      dispatch(SignInLoading(false));
    }
  };
}

export function SignOut(router) {
  return async (dispatch) => {
    try {
      dispatch(SignInLoading(true));
      // eslint-disable-next-line no-undef
      window.localStorage.clear();
      dispatch(SignOutSuccess("User successfully signOut"));
      router.push("/");
    } catch (error) {
      dispatch(SignOutErorr(error));
    } finally {
      dispatch(SignInLoading(false));
    }
  };
}

export async function CheckUserName(userName) {
  try {
    let res = await axios.post("auth/verifyusername", {
      username: userName,
    });
    if (res.data.code !== 200) throw new Error("Incorect userName");
    return true;
  } catch (error) {
    return false;
  }
}

export function upData(data) {
  return async (dispatch, getStore) => {
    try {
      dispatch(SignUpLoading(true));
      const is_coach = getStore().auth.user.member_type.key != "owner"
      if (data.selectedFile) {
        await setProfileImage("users/fileuploadbusiness", data.selectedFile);
      }

      const {
        email,
        username,
        firstName,
        lastName,
        about,
        shared_members,
        phone,
        lat,
        long,
        level,
        ...business
      } = data;

      delete business.error;
      delete business.selectedFile;
      //business.timeZone = moment.tz.guess();

      const business_object = {
        address: business.address,
        placeId: business.placeId,
        lat: lat,
        long: long,
        name: business.courtName,
        manager_name: firstName + " " + lastName,
        phone: phone,
        about: about,
        shared_members: shared_members,
        level: level ? parseInt(level.id) : null,
      };
      let body = {
        email,
        username,
        first_name: firstName,
        last_name: lastName,
        business: business_object,
      };
      if (is_coach) {
        delete body.business;
      }
      let res = await axios.put("users/update", body);
      dispatch(SignUpSuccess(res.data.payload.user));
    } catch (error) {
      await window.modal.alert(error.response.data.message);
    } finally {
      dispatch(SignUpLoading(false));
    }
  };
}
export function updateVisitorCheck(data) {
  return async (dispatch) => {
    dispatch(SignUpLoading(true));
    try {
      let res = await axios.post("users/update_visitor_check", data);

      dispatch(SignUpSuccess(res.data.payload.user));
      window.location.reload()
    }catch (error) {
      await window.modal.alert(error.response.data.message);
    } finally {
      dispatch(SignUpLoading(false));

    }
  }
}
export function updateWaiver(data) {
  return async (dispatch) => {
    dispatch(SignUpLoading(true));
    try {
      let formData = new FormData();
      formData.append("filename", data.selectedFile);
      data.require_waiver_sign = data.require_waiver_sign === "0" ? false : true;
      data.require_waiver_sign_nbu = data.require_waiver_sign_nbu === "0" ? false : true;
      formData.append("require_waiver_sign", data.require_waiver_sign);
      formData.append("require_waiver_sign_nbu", data.require_waiver_sign_nbu);

      let res = await axios.post("users/update_waiver", formData, {
        headers: {
          // eslint-disable-next-line no-undef
          "Content-Type": "multipart/form-data",
        },
      });

      dispatch(SignUpSuccess(res.data.payload.user));
    } catch (error) {
      await window.modal.alert(error.response.data.message);
    } finally {
      dispatch(SignUpLoading(false));
    }
  };
}

export function updateSettings(data) {
  return async (dispatch) => {
    try {
      dispatch(SignUpLoading(true));
      //business.timeZone = moment.tz.guess();

      const business_object = {
        fields_count: data.courtNum,
        price: data.price,
        guest_fee: data.guest_fee ? data.guest_fee : 0,
        guest_fee_point: data.guest_fee_point ? data.guest_fee_point : 0,
        split_payment: data.split_payment,
        details: data.details,
        surface: data.surface,
        access_lessons: data.access_lessons,
        access: data.access,
        booking_to_start: data.booking_to_start,
        min_list_duration: data.min_list_duration,
        require_partner_name: data.require_partner_name,
        require_guest_name: data.require_guest_name,
        require_guest_and_partner_name: data.require_guest_and_partner_name,
        allow_to_pay_in_person: !data.allow_to_pay_in_person,
        cancel_member_subscription_immediately: data.cancel_member_subscription_immediately,
        automatically_refund_payment: data.automatically_refund_payment,
        add_to_invoice: data.add_to_invoice,
        cancel_on_freeze: data.cancel_on_freeze,
        //  show_court_fee: data.show_court_fee,
        show_waitlist: data.show_waitlist,
        start_subscription_immediately: data.start_subscription_immediately,
        allow_checkin: data.allow_checkin,
        coach_dashboard: data.coach_dashboard,
        lesson_dashboard: data.lesson_dashboard,
        booked_dashboard: data.booked_dashboard,
        court_change_email: data.court_change_email,
        cancel_allowed: data.cancel_allowed,

        enable_max_time_for_bookings: data.enable_max_time_for_bookings,

        max_time_for_singles: data.max_time_for_singles,
        max_time_for_doubles: data.max_time_for_doubles,
        max_time_for_triples: data.max_time_for_triples,
        max_time_for_quadruples: data.max_time_for_quadruples,

        currency_code: data.currency_code.id,
        csh_start_time: data.csh_start_time.id,
        advance_time: data.advance_time,
        am_pm: data.am_pm,
        allow_booking_until: data.allow_booking_until,
      };
      const body = {
        business: business_object,
      };
      
      let res = await axios.put("users/update", body);

      dispatch(SignUpSuccess(res.data.payload.user));
      await window.modal.alert("Success.", "Success");
    } catch (error) {
      await window.modal.alert("Warning", error.response.data.message);

      /*  dispatch(SignUpErorr({
          message: error.response.data.message,
        }));*/
    } finally {
      dispatch(SignUpLoading(false));
    }
  };
}

export function updatetaxRate(id = null, taxRateModel, remove = false) {
  return async (dispatch) => {
    try {
      dispatch({
        type: TAX_RATE_UPDATE_LOADING,
        payload: true,
      });
      const requestData = remove ?
        null :
        mapRequestForTaxRate(id, taxRateModel);
      let url = id ? `payment/tax/update/${id}` : "payment/tax/create";
      url = remove ? `payment/tax/archive/${id}` : url;

      let res = remove ?
        await axios.delete(url) :
        await axios.post(url, requestData);

      dispatch({
        type: TAX_RATE_UPDATE_SUCCESS,
        payload: res.data.payload,
      });
    } catch (error) {
      await window.modal.alert(error.response.data.message);
    } finally {
      dispatch({
        type: TAX_RATE_UPDATE_LOADING,
        payload: false,
      });
    }
  };
  // eslint-disable-next-line no-undef
}

export async function checkDemoCode(code) {
  try {
    let res = await axios.post("auth/verifydemocode", {
      demo_code: code,
    });
    return res.data.payload;
  } catch (error) {
    throw error;
  }
}

export function SignUpF(data, router) {
  return async (dispatch, getStore) => {
    try {
      dispatch(SignUpLoading(true));
      let {
        email,
        username,
        firstName,
        sport_category,
        lastName,
        lat,
        long,
        password,
        about,
        phone,
        level,
        ...business
      } = data;
      delete business.error;
      delete business.cnfirm_password;
      delete business.accessCodeTemp;
      delete business.otherCurrency;

      const business_object = {
        sport_category_id: sport_category,
        address: business.address,
        placeId: business.placeId,
        name: business.courtName,
        plan: getStore().auth.selectedPlanId,
        manager_name: firstName + " " + lastName,
        phone: phone,
        level: level ? level.id : null,
      };
      username = username.trim();
      //business.timeZone = moment.tz.guess();
      const body = {
        email,
        username,
        first_name: firstName,
        last_name: lastName,
        password,
        lat,
        long,
        phone,
        user_type: "bu",
        demo_code: getStore().auth.demoUrlCode,
        business: business_object,
      };
      let res = await axios.post("auth/register", body);

      dispatch(
        SignUpSuccess({
          ...res.data.payload.user,
          business: {
            ...res.data.payload.user.business,
            planId: getStore().auth.selectedPlanId,
          },
        })
      );
      // eslint-disable-next-line no-undef
      window.localStorage.setItem("token", res.data.payload.token);
      dispatch(StripeNoConnectrdSuccess());

      /*if (res.data.payload.payment.stripeUserId) {
        dispatch(StripeConnectrdSuccess());
      } else {
        dispatch(StripeNoConnectrdSuccess());
      }*/
      if (getStore().auth.selectedPlanId === "lessonproviderplan") {
        router.push("/private/lessons/-1");
      } else {
        router.push("/private/profile");
      }
    } catch (error) {
      await window.modal.alert(error.response.data.message);
      dispatch(
        SignUpErorr({
          message: error.message,
        })
      );
    } finally {
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve(true);
        }, 700);
      });
      dispatch(SignUpLoading(false));
    }
  };
}

export function SignUpNBU(data, router, referer) {
  return async (dispatch) => {
    try {
      dispatch(SignUpLoading(true));
      let {
        email,
        username,
        firstName,
        lastName,
        dob,
        password
      } = data;

      username = username.trim();

      const body = {
        email,
        username,
        first_name: firstName,
        last_name: lastName,
        dob: dob ? dob : undefined,
        password,
        user_type: "nbu",
      };
      await axios.post("auth/register", body);
      dispatch(
        SignIn({
            userName: username,
            password: password,
          },
          router,
          referer
        )
      );
    } catch (error) {
      await window.modal.alert(error.response.data.message);
      dispatch(
        SignUpErorr({
          message: error.message,
        })
      );
    } finally {
      dispatch(SignUpLoading(false));
    }
  };
}

export function ResetPass(userName) {
  return async (dispatch) => {
    try {
      dispatch(ResetPassLoading(true));
      let res = await axios.post("auth/forgotpassword", {
        username: userName,
      });
      if (res.data.code !== 200) throw new Error(res.data.message);
      if (res.data.code== 200){
        dispatch(
          ResetPassError({
            message: null,
          })
        );
      }
      await window.modal.alert("Success.", "Please check your email.");
      dispatch(ResetPassSuccess(res.data));
      // eslint-disable-next-line no-undef
    } catch (error) {
      dispatch(
        ResetPassError({
          message: "Incorrect Credentials",
        })
      );
    } finally {
      dispatch(ResetPassLoading(false));
    }
  };
}

export function ChangePassword(oldPassword, newPassword) {
  return async (dispatch) => {
    try {
      dispatch(ResetPassLoading(true));
      let res = await axios.post("users/changepassword", {
        old_password: oldPassword,
        new_password: newPassword,
        confirm_password: newPassword,
      });
       window.localStorage.removeItem("token");
       window.localStorage.setItem("token", res.data.payload.token);
      
     // await window.modal.alert("Password changed successfully ");
      dispatch(ChangePassSuccess(res.data));
      // eslint-disable-next-line no-undef
    } catch (error) {
      await window.modal.alert(error.response.data.message);
      /* dispatch(ResetPassError({
         message: error.message,
       }));*/
    } finally {
      dispatch(ResetPassLoading(false));
    }
  };
}

export function getMemberShips() {
  return async (dispatch) => {
    try {
      dispatch(SignUpLoading(true));
      let res = await axios.get("users/memberships");

      dispatch(GetMemberShipsSuccess(res.data.payload));
      // eslint-disable-next-line no-undef
    } catch (error) {
      await window.modal.alert(error.response.data.message);
    } finally {
      dispatch(SignUpLoading(false));
    }
  };
}
export function setBusiness(business_id) {
  return async (dispatch) => {
    try {
      dispatch(SignUpLoading(true));

      await axios.put("users/set_business", {
        business_id,
      });

      window.localStorage.removeItem(GLOBAL_LOCATION_FACILITY_PAIR);

      dispatch(IsSignIn());

      return true;
    } catch (error) {
      await window.modal.alert(error.response.data.message);
      return false;
    } finally {
      dispatch(SignUpLoading(false));
    }
  };
}
export function setWaiver(business_id) {
  return async (dispatch) => {
    try {
      dispatch(SignUpLoading(true));
      await axios.put("users/members/sign_waiver", {
        business_id,
        signed: true,
      });
      dispatch(signedSuccess());

      return true;

      // eslint-disable-next-line no-undef
    } catch (error) {
      await window.modal.alert(error.response.data.message);
      return false;
    } finally {
      dispatch(SignUpLoading(false));
    }
  };
}

function mapRequestForTaxRate(id = null, taxrateModel) {
  let data = {
    display_name: taxrateModel.type === "custom" ?
      taxrateModel.typeName : taxrateModel.type, // REQUIRED
    description: taxrateModel.description,
    type: taxrateModel.type,
    jurisdiction: taxrateModel.region || "Default region",
    percentage: parseFloat(taxrateModel.tax).toFixed(2), // REQUIRED
    inclusive: taxrateModel.rateType === "inclusive" ? true : false, // REQUIRED
  };
  if (id) {
    delete data.percentage;
  }

  return data;
}
