import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import RequestModal from '../../RequestModal';

class MainSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpened: false
    }
  }

  openModalRequestDemo = () => {
    this.setState({
      modalOpened: (<RequestModal opened={true} close={this.closeModal} />),
    });
  }

  closeModal = () => {
    this.setState({
      modalOpened: false
    })
  }

  render() {
    const { modalOpened } = this.state;
    return (
      <React.Fragment>
        <MetaTags>
          <title>Tennis Club Management Software | Manage Tennis Courts & Lessons</title>
          <meta name="description" content="Ballbutton Enterprise: Manage tennis clubs, courts, lessons and groups. Comes with integrated POS. Manage members and subscriptions. Analytics for revenue and booked hours." />
        </MetaTags>
        <section className="main-section"
          style={{ backgroundImage: "url('/assets/img/web_play_background_long_1.gif')" }}>
          <div className="main-container">
            <div className="main-content">
              <div className="text-block">
                <div className="main-title">
                  {/*<h2><span>Ballbutton:</span> for tennis players and fans!</h2>*/}
                  <h2><span>Ballbutton Enterprise:</span> for tennis court, lesson and group managers!</h2>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="info-section"
          style={{}}>
          <div className="main-container">
            <div className="main-content">
              <div className="text-block">
                <div className="message-section">
                  <div className="img-section">
                    <img src="/assets/img/ballbutton_website.gif" alt="" />
                  </div>
                  <div className="text-section-right">
                    <p>
                      <span>The Most Flexible, Full-Featured Tennis Club Management Software On The Market. The Preferred Choice Of Modern Tennis Clubs!</span>
                      <br />
                      Grow Revenue, Improve Efficiency, And Offer A Superior Member Experience With Ballbutton.
                    <br />
                      <br />
                      Increase Your Occupancy Rate By A Magnitude: Diminish Dead Hours and Empty Courts.
                      <br />
                      <br />
                      Manage Your Courts, Lessons & Members All In One Place. Easily Automate Reminders, Process Payments, And More.
                      <br />
                      <br />
                      Schedule A Customized Private Today!&nbsp; <b style={{ display: "inline-block" }} onClick={this.openModalRequestDemo} className="demo-btn" >Get a Private Demo</b>
                    </p>
                  </div>

                </div>
                <br />
                <br />
                <br />
                <div className="message-section">
                  <div className="text-section">
                    <p>
                      <span>Use Court Management, Ballbutton Ace or Ballbutton Enterprise accounts to manage/grow your tennis club business:</span>
                      <br />
                      (1) First and foremost, manage court bookings, programs, lessons, teams, members,
                      member retention, keep your current customers engaged, achieve repeated bookings,
                      and manage payments.
                    <br />
                      <br />
                      (2) List your excess capacity that stays unfilled by your members/customers to be booked by public.
                  <br /> <br />
                      You can use Ballbutton for both functionalities or for any of the two depending on your needs.
               </p>
                  </div>
                  <div className="img-section">
                    <img src="/assets/img/main-section.jpg" alt="" />
                  </div>
                </div>

                <br />
                <br />
                <br />
                <div className="message-section withimg">
                  <div className="img-section">
                    <img src="/assets/img/web_play_background_long_1_cut_1.gif" alt="" />
                  </div>
                  <div className="text-section-right">
                    <p>
                      <span>Use our program/lesson manager account if you are:</span>
                    </p>
                    <br />
                    <ul className="ul-messagesection">
                      <li>Manager of a club</li>
                      <li>Manager of a team</li>
                      <li>Freelancer</li>
                      <li>Coach at a club which uses Ballbutton  for court management</li>
                      <li>Coach at a club which doesn’t use Ballbutton. (Your club will benefit anyway if you bring more customer foot-traffic).</li>
                    </ul>
                  </div>

                </div>
              </div>

            </div>
          </div>
          {modalOpened}
        </section>
      </React.Fragment>
    );
  }
}

MainSection.propTypes = {};

export default MainSection;
