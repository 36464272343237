import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './form-group-new.scss';
class FormGroupNew extends Component {
    constructor(props) {
        super(props);
    }


    render() {
        const { children, parentClassName, ...rest } = this.props;
        return (
            <div className={`form-group-rounded ${parentClassName}`}>
                <input autoComplete="off" {...rest} />
                {children}
            </div>
        );
    }


}

FormGroupNew.defaultProps = {
    id: null,
    placeholder: '',
    label: '',
    // children: {},
};
FormGroupNew.propTypes = {
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    id: PropTypes.string,
    placeholder: PropTypes.string,
    label: PropTypes.string
};

export default FormGroupNew;
