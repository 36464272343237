import React, { Component } from "react";

import { connect } from "react-redux";

import PartialRefundPos from "../PartialRefundPos";
import { refundCharge } from "../../actions/Lesson";
import { refundGift } from "../../actions/Points";
import { GetMember, refundItem } from "../../actions/Members";
import { getChargeHistory } from "../../actions/ChargeHistory";

class RefundPaymentAndReturnBtn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openedModal: null,
    };
  }

  refundCharge = async (chargeId, amount) => {
    const {
      apt_id = null,
      refund_for,
      member_id = null,
      params = null,
      cb,
      item,
    } = this.props;
    if (
      await window.modal.cnfirm(
        "Confirm",
        "Are you sure to refund the charge ?"
      )
    ) {
      await this.props.refundCharge(
        apt_id,
        chargeId,
        item.id,
        amount,
        refund_for
      );

      if (cb) {
        await this.props.cb(params);
        this.setState({
          openedModal: null,
        });
        return;
      }

      if (!apt_id) {
        await this.props.getChargeHistory(0, null, params);
      }
      //Update member charges
      if (apt_id && apt_id < 0) {
        await this.props.GetMember(member_id, params);
      }

      this.setState({
        openedModal: null,
      });
    }
  };

  refundGiftPoint = async (paymentId, amount) => {
    const { id, user_id, member_id, business } = this.props;
    if (
      await window.modal.cnfirm(
        "Confirm",
        "Are you sure to refund the gift points ?"
      )
    ) {
      await this.props.refundGift(id, {
        user_id: user_id,
        member_id: member_id,
        amount: amount,
        business_id: business.id,
      });

      this.setState({
        openedModal: null,
      });
    }
  };

  refundItem = async (paymentId, count) => {
    const {
      user_id,
      member_id,
      order_details_id,
      from,
      params,
      closeDetailsModal,
    } = this.props;
    let options = {
      payment_id: paymentId,
      order_count: count,
      amount:1
    };
    if (
      await window.modal.cnfirm("Confirm", "Are you sure to refund the item ?")
    ) {
      if (order_details_id) {
        options = {
          ...options,
          order_details_id: order_details_id,
        };
        options = {
          ...options,
          return_inverntory: true,
        };
      }

      await this.props.refundItem(user_id, member_id, {
        ...options,
      });

      if (from === "payment") {
        await this.props.getChargeHistory(0, null, params);
      }
      //Update member charges
      if (from === "memberview") {
        await this.props.GetMember(member_id, params);
      }
      closeDetailsModal && closeDetailsModal();
    }
  };

  openRefundChargeModal = () => {
    const {
      payment_id,
      tax_percent,
      amount,
      is_tax,
      is_gift,
      is_item,
      item,
      updateRecentListDitels,
      count
    } = this.props;
    this.setState({
      openedModal: is_gift ? (
        <PartialRefundPos
          paymentId={payment_id}
          amount={amount}
          refundCharge={this.refundGiftPoint}
          clouseModal={this.closeModal}
          updateRecentListDitels={updateRecentListDitels}
        />
      ) : (
        <PartialRefundPos
          paymentId={payment_id}
          tax_percent={tax_percent}
          amount={amount}
          item={item}
          count={count}
          isTax={is_tax}
          refundCharge={is_item ? this.refundItem : this.refundCharge}
          clouseModal={this.closeModal}
          updateRecentListDitels={updateRecentListDitels}
        />
      ),
    });
  };

  closeModal = () => {
    this.setState({ openedModal: null });
  };

  render() {
    const { openedModal } = this.state;
    const { permission } = this.props;
    let permission_props = false;
    if (permission != undefined) {
      permission_props = permission
    }
    return (
      <div className="pretty-button">
        <button disabled={permission_props ? false : true} onClick={this.openRefundChargeModal} className="green-button">
          Refund And Return
        </button>
        {openedModal}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isConnected: state.stripe.isConnected,
    business: state.auth.user.business,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    refundCharge: (llid, chargeId, paymentId, amount, refund_for) =>
      dispatch(refundCharge(llid, chargeId, paymentId, amount, refund_for)),
    refundGift: (id, options = {}) => dispatch(refundGift(id, options)),
    refundItem: (user_id, member_id, options = {}) =>
      dispatch(refundItem(user_id, member_id, options)),
    getChargeHistory: (last_id, cancel_token = null, params = null) =>
      dispatch(getChargeHistory(last_id, cancel_token, params)),
    GetMember: (id, params = null) => dispatch(GetMember(id, params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RefundPaymentAndReturnBtn);
