import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { MicroSettingsList } from '../../containers/MicroSettings';
import './micro-settings-modal.scss';

class MicroSettingsModal extends Component {
  constructor(props) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
  }

  closeModal = e => {
    this.props.close();
  };

  render() {
    const { close, timeZone, currentDay, showForm,expiredRules } = this.props;

    return (
      <div className={'modal active group-modal'} id="modalBack">
        <div className="modal-content micro-settings-modal  micro-settings-new-style">
          <div className="close-btn">
            <button onClick={close}>
              <img src="/assets/img/cancel.png" alt="cancel" />
            </button>
          </div>
          <MicroSettingsList expiredRules={expiredRules} showForm={showForm} currentDay={currentDay} timeZone={timeZone} />
        </div>
      </div>
    );
  }
}

MicroSettingsModal.propTypes = {
  close: PropTypes.func.isRequired,
  showForm: PropTypes.bool,
};

export default MicroSettingsModal;
