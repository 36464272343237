/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { CardElement, injectStripe } from "react-stripe-elements";
import Switcher from "../UI/Switcher";
import Notification from "../Notification";
import Loading from '../../components/UI/Loading';
// import { chargecustomer } from '../../actions/Stripe';
export class RomingInputNumber extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isValidationError: false,
    };
    this.hendlerChange = this.hendlerChange.bind(this);
  }

  hendlerChange(e) {
    const { value } = e.target;
    const isValidationError = !/^\d{6,9}$/.test(value);
    this.state.isValidationError !== isValidationError &&
      this.setState({ isValidationError });
    this.props.onChange(e);
  }

  render() {
    const { isValidationError } = this.state;
    return (
      <input
        {...this.props}
        style={isValidationError ? { border: "1px solid red" } : {}}
        onChange={this.hendlerChange}
      />
    );
  }
}

RomingInputNumber.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string.isRequired,
};
RomingInputNumber.defaultProps = {
  onChange: (e) =>
    console.warn(e, "RomingInputNumber shuld have a onChange eventHendler"),
};

// eslint-disable-next-line react/no-multi-comp
export class AccountInputNumber extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isValidationError: false,
    };
    this.hendlerChange = this.hendlerChange.bind(this);
  }

  hendlerChange(e) {
    const { value } = e.target;
    const isValidationError = !/^\d{1,12}$/.test(value);
    this.state.isValidationError !== isValidationError &&
      this.setState({ isValidationError });
    this.props.onChange(e);
  }

  render() {
    const { isValidationError } = this.state;
    return (
      <input
        {...this.props}
        style={isValidationError ? { border: "1px solid red" } : {}}
        onChange={this.hendlerChange}
      />
    );
  }
}

AccountInputNumber.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string.isRequired,
};
AccountInputNumber.defaultProps = {
  onChange: (e) =>
    console.warn(e, "AccountInputNumber shuld have a onChange eventHendler"),
};

// eslint-disable-next-line react/no-multi-comp
class AddPaymant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isadd: props.cardList.length > 1 ? false : true,
      isBank: false,
      routing_number: "",
      account_number: "",
      account_holder_name: "",
      account_holder_type: "individual",
      openCard:true,
      defValue1:"",
      defValue2:"",
      card: (props.cardList.find((item) => item.default_source) || {}).id,
    };
    this.notVerifid = {};
    this.notVerifidInputs = {};
    this.submit = this.submit.bind(this);
    this.hendlerOnChange = this.hendlerOnChange.bind(this);
    this.hendlerOnChangeInput = this.hendlerOnChangeInput.bind(this);
    this.hendlerStripeElementChange = this.hendlerStripeElementChange.bind(
      this
    );
    this.HandleChangeCheckBoxDetails = this.HandleChangeCheckBoxDetails.bind(
      this
    );
  }

  /*static getDerivedStateFromProps(nextProps, prevState) {
    if ((nextProps.cardList.find(item => item.default_source) || {}).id !== prevState.card) {
      return {
        loading: false,
        isBank: false,
        routing_number: '',
        account_number: '',
        account_holder_name: '',
        account_holder_type: 'individual',
        isadd: nextProps.cardList.length > 1 ? false : true,
        card: (nextProps.cardList.find(item => item.default_source) || {}).id,
      };
    }
    return null;
  }*/
  componentWillReceiveProps(nextProps) {
    if (
      (nextProps.cardList.find((item) => item.default_source) || {}).id !==
      this.state.card
    ) {
      this.setState({
        loading: false,
        isBank: false,
        routing_number: "",
        account_number: "",
        account_holder_name: "",
        account_holder_type: "individual",
        isadd: nextProps.cardList.length > 1 ? false : true,
        card: (nextProps.cardList.find((item) => item.default_source) || {}).id,
      });
    }
  }

  hendlerOnChange(e) {
    const { name, type, value: valueTarget, checked } = e.target;
    const value = type === "checkbox" ? checked : valueTarget;

    this.setState({ isadd: false, [name]: value });
  }

  hendlerOnChangeInput(e) {
    const { name, value } = e.target;
    this.setState({ isadd: true, [name]: value });
  }

  hendlerStripeElementChange() {
    this.setState({ isadd: true });
  }

  HandleChangeCheckBoxDetails() {
    this.setState((prevState) => ({ isBank: !prevState.isBank, isadd: true }));
  }

  async submit() {
    const {
      routing_number,
      account_number,
      account_holder_name,
      account_holder_type,
    } = this.state;
    try {
      this.setState({ loading: true });
      if (this.state.isadd) {
        if (this.state.isBank) {
          const { token } = await this.props.stripe.createToken(
            "bank_account",
            {
              country: "US",
              currency: "usd",
              routing_number,
              account_number,
              account_holder_name,
              account_holder_type,
            }
          );
          if (token) {
            this.props.addPaymant(token.id);
          }
        } else {
          const { token } = await this.props.stripe.createToken({
            name: this.props.fullName,
          });
          if (token) {
            this.props.addPaymant(token.id);
          }
        }
      } else {
        this.props.changeDefaultCard(this.state.card);
      }
      if (this.state.isadd && this.state.isBank) {
        this.setState({
          loading: false,
          routing_number: "",
          account_number: "",
          account_holder_name: "",
          account_holder_type: "individual",
        });
      } else if (this.state.isadd) {
        this.setState({ loading: false });
        this.el && this.el.clear();
      } else {
        this.setState({ loading: false });
      }
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
      await window.modal.alert("Error", error.response.data.message);
    }
  }

  verifi(id) {
    return async () => {
      if (
        parseFloat(this.notVerifidInputs[id + "1"].value) > 0 &&
        parseFloat(this.notVerifidInputs[id + "2"].value) > 0
      ) {
       if( this.props.verifiBankAccount(
        id,
       "0."+ this.notVerifidInputs[id + "1"].value,
       "0."+  this.notVerifidInputs[id + "2"].value
      )){
        this.setState({openCard:!this.state.openCard})
        this.setState({defValue1:"",defValue2:""})
      }
      }
      
    };
  }

  cancelSource = async (card_id) => {
    const { admin } = this.props;
    const userCardList = this.props.cardList || this.props.rootInfo.stripePaymentMethods;
   
    if (await window.modal.cnfirm("Are you sure?", "to remove this card")) {
      this.props.removeCard(card_id, admin ? true : false,userCardList.length==1);
    }
  };

  render() {
    const { loading, isBank,defValue1 ,defValue2} = this.state;
    const business_cards = this.props.cardList.filter((v) => !v.metadata.nbu);
    const userCardList = this.props.cardList || this.props.rootInfo.stripePaymentMethods;
    const hasSubscriptions =this.props.subscriptions?.length>0;
    const isBU =this.props.isBU;
    
    return (
      <div className={`checkout rel ${loading ? "loading" : ""}`}>
        {this.props.stripeUserId === null ? (
          <h1>Add Payment method for {this.props.fullName}</h1>
        ) : (
          <h1>Change Payment method for {this.props.fullName}</h1>
        )}
        <div>
          ACH
          <Switcher
            name="isBank"
            onChange={this.HandleChangeCheckBoxDetails}
            value={this.state.isBank}
            checked={this.state.isBank}
          />
          credit card
        </div>
        {userCardList.length <= 0 ? null : (
          <table>
            <tbody>
              {userCardList.length > 1 ? (
                <React.Fragment>
                  {userCardList.map((item) => (
                    <React.Fragment key={item.id}>
                      <tr key={item.id + "real"}>
                        <td style={{ paddingTop: "10px" }}>
                          <input
                            type="radio"
                            id={item.id}
                            name="card"
                            value={item.id}
                            checked={item.id === this.state.card}
                            onChange={this.hendlerOnChange}
                          />
                        </td>
                        <td>
                          <label
                            htmlFor={item.id}
                          >{`**** **** **** ${item.last4}`}</label>
                        </td>
                        <td>
                          <label htmlFor={item.id}  style={{position: "relative",top: "-6px"}}>
                            <img
                              src={
                                item.object === "card"
                                  ? "/assets/img/card.png"
                                  : "/assets/img/ach.png"
                              }
                              alt="card"
                            />
                           
                            {isBU && !this.props.fromBUprofile && !(item.default_source && hasSubscriptions )&& (
                              
                              <img
                                title="Cancel"
                                onClick={() => {
                                  this.cancelSource(item.id);
                                }}
                                className="chargeButton w-29"
                                src="/assets/img/error-cancel.svg"
                                alt="Cancel"
                              />
                            )}
                            {isBU && (item.default_source && hasSubscriptions )&&(
                              <span className="paymentInfoIcon">
                                <Notification>
                               The customer has an active subscription (membership). Cannot remove the payment method.
                            </Notification>
                              </span>
                            )}
                            
                            {item.object === "bank_account" && (
                              <span> <br />
                              <span
                                className={
                                  item.status === "verified"
                                    ? "verified"
                                    : "notverified"
                                }
                                style={{marginLeft:"5px"}}
                                onClick={() => {
                                  this.setState({openCard:!this.state.openCard})
                                  this.setState({defValue1:"",defValue2:""})
                                  this.notVerifid[item.id].classList.toggle(
                                    "activ"
                                  );
                                }}
                              >
                               
                                {item.status === "verified"
                                  ? "verified"
                                  : "not verified"}
                              </span>
                              </span>
                            )}
                          </label>
                        </td>
                      </tr>
                      {item.object === "bank_account" &&
                        item.status !== "verified" ? (
                        <tr
                          className="verification-form"
                          ref={(ref) => (this.notVerifid[item.id] = ref)}
                        >
                          <td colSpan="3">
                            <div className="vrify-group">
                              <h3>
                              We sent two small deposits (in cents) to this bank account. Can take 1-2 business days till you see them on your bank account. To verify this account, please confirm the amounts of these deposits.
                              </h3>
                              <div className="verify_sub_group">
                                <div className="verify-col">
                                <label style={{display:"block"}}><b>First trans</b></label>
                                <span style={{fontSize:" 20px",verticalAlign: "middle"}}>0.</span> 
                                  <input
                                    ref={(ref) =>
                                    (this.notVerifidInputs[
                                      item.id + "1"
                                    ] = ref)
                                    }
                                    type="number"
                                    style={{width: "80% !important"}}
                                    onWheel={(e) => e.target.blur()}
                                    placeholder=""
                                  />
                                </div>
                                <div className="verify-col">
                                <label style={{display:"block"}}><b>Second trans</b></label>
                                <span style={{fontSize:" 20px",verticalAlign: "middle"}}>0.</span> 
                                  <input
                                    ref={(ref) =>
                                    (this.notVerifidInputs[
                                      item.id + "2"
                                    ] = ref)
                                    }
                                    type="number"
                                    style={{width: "80% !important"}}
                                    onWheel={(e) => e.target.blur()}
                                    placeholder=""
                                  />
                                </div>
                                <div className="verify-col" style={{marginTop:"19px"}}>
                                  <div className="pretty-button">
                                    <button
                                      className="green-button h-30"
                                      disabled={this.props.verificationLoading}
                                      onClick={this.verifi(item.id)}
                                    >
                                      Verify
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="tr-min" />
                          </td>
                        </tr>
                      ) : (
                        <tr></tr>
                      )}
                    </React.Fragment>
                  ))}
                </React.Fragment>
              ) : (
                this.props.cardList.map((item) => (
                  <React.Fragment key={item.id}>
                    <tr>
                      <td >
                        <label
                          htmlFor={item.id}
                          
                        >{`**** **** **** ${item.last4}`}</label>
                      </td>
                      <td >
                        <label htmlFor={item.id}  style={{position: "relative",top: "-6px"}}>
                          <img
                            src={
                              item.object === "card"
                                ? "/assets/img/card.png"
                                : "/assets/img/ach.png"
                            }
                            alt="card"
                          />
                          {isBU && !this.props.fromBUprofile && !(item.default_source && hasSubscriptions )&& (
                              
                              <img
                                title="Cancel"
                                onClick={() => {
                                  this.cancelSource(item.id);
                                }}
                                className="chargeButton w-29"
                                src="/assets/img/error-cancel.svg"
                                alt="Cancel"
                              />
                            )}
                            {isBU && (item.default_source && hasSubscriptions )&&(
                              <span className="paymentInfoIcon">
                                <Notification>
                               The customer has an active subscription (membership). Cannot remove the payment method.
                            </Notification>
                              </span>
                            )}
                            
                          {item.object === "bank_account" && (
                            <span>
                               <br />
                           
                            <span
                              className={
                                item.status === "verified"
                                  ? "verified"
                                  : "notverified"
                              }
                              onClick={() => {
                                this.notVerifid[item.id].classList.toggle(
                                  "activ"
                                );
                              }}
                            >
                             
                              {item.status === "verified"
                                ? "verified"
                                : "not verified"}
                            </span>
                            </span>
                          )}
                        </label>
                      </td>
                    </tr>
                    {item.object === "bank_account" &&
                      item.status !== "verified" ? (
                      <tr
                        className="verification-form"
                        ref={(ref) => (this.notVerifid[item.id] = ref)}
                      >
                        <td colSpan="3">
                          <div className="vrify-group">
                            <h3>
                              We sent two small deposits to this bank account.To
                              verify this account, please confirm the amounts of
                              these deposits
                            </h3>
                            <div className="verify_sub_group">
                              <div className="verify-col">
                              <label style={{display:"block"}}><b>First trans</b></label>
                              <span style={{fontSize:" 20px",verticalAlign: "middle"}}>0.</span> 
                               <input
                                  ref={(ref) =>
                                    (this.notVerifidInputs[item.id + "1"] = ref)
                                  }
                                  type="number"
                                  style={{width: "80% !important"}}
                                  onWheel={(e) => e.target.blur()}
                                  placeholder=""
                                />
                              </div>
                              <div className="verify-col">
                              <label style={{display:"block"}}><b>Second trans</b></label>
                               <span style={{fontSize:" 20px",verticalAlign: "middle"}}>0.</span> 
                               <input
                                  style={{width: "80% !important"}}
                                  ref={(ref) =>
                                    (this.notVerifidInputs[item.id + "2"] = ref)
                                  }
                                  type="number"
                                 
                                  onWheel={(e) => e.target.blur()}
                                  placeholder=""
                                />
                               
                              </div>
                              <div className="verify-col" style={{marginTop:"19px"}}>
                                <button
                                  disabled={this.props.verificationLoading}
                                  onClick={this.verifi(item.id)}
                                >
                                  Verify
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="tr-min" />
                        </td>
                      </tr>
                    ) : (
                      <tr></tr>
                    )}
                  </React.Fragment>
                ))
              )}
            </tbody>
          </table>
        )}
        {isBank ? (
          <div className="bankAccountForm">
            <select name="country" id="">
              <option defaultChecked>United States</option>
            </select>
            <select name="currency" id="">
              <option defaultChecked>USD</option>
            </select>
            <select
              name="account_holder_type"
              value={this.state.account_holder_type}
              onChange={this.hendlerOnChangeInput}
            >
              <option value="individual">individual</option>
              <option value="company">company</option>
            </select>
            <input
              onChange={this.hendlerOnChangeInput}
              type="text"
              name="account_holder_name"
              value={this.state.account_holder_name}
              placeholder="Account holder name"
            />
            <RomingInputNumber
              onChange={this.hendlerOnChangeInput}
              type="text"
              name="routing_number"
              value={this.state.routing_number}
              placeholder="Routing number"
            />
            <AccountInputNumber
              onChange={this.hendlerOnChangeInput}
              type="text"
              name="account_number"
              value={this.state.account_number}
              placeholder="Account number"
            />
          </div>
        ) : (this.state.openCard &&
          <div>
            <h3>Card Info</h3>
            <CardElement
              onReady={(el) => {
                this.el = el;
              }}
              onChange={this.hendlerStripeElementChange}
              style={{ base: { fontSize: "16px" } }}
            />
          </div>
        )}
         <div className="sign-up-btn-new" style={{margin:"auto"}}>
              <div className="pretty-button ">
              <button className="green-button" type="button" onClick={this.submit}>
                {this.state.isadd
                  ? isBank
                    ? "Add bank account"
                    : "Add Card"
                  : "Change"}
              </button>
              </div>
          </div>
        
        {this.state.loading?<Loading/>:null}
      </div>
    );
  }
}

AddPaymant.propTypes = {
  stripe: PropTypes.objectOf(PropTypes.any),
  fullName: PropTypes.string.isRequired,
  stripeUserId: PropTypes.string,
  cardList: PropTypes.arrayOf(PropTypes.any),
  addPaymant: PropTypes.func.isRequired,
  changeDefaultCard: PropTypes.func.isRequired,
  verifiBankAccount: PropTypes.func.isRequired,
  verificationLoading: PropTypes.bool.isRequired,
};
AddPaymant.defaultProps = {
  stripe: null,

  stripeUserId: null,
  cardList: [],
};
export default injectStripe(AddPaymant);
