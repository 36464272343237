import React, { Component } from 'react';

import { checkPromo } from '../../actions/util';
import './promo.scss';
class Promo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            promo: '',
            disabled: false
        };
    }
    hendlerChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
        this.props.hendlerChangePromo(value);
    }

    hendlerClickCheckPromo = async () => {
        this.setState({
            disabled: true
        });
        await checkPromo(this.state.promo);
        this.setState({
            disabled: false
        });
    }


    render() {
        const { promo, disabled } = this.state;
        return (
            <div className="promo-div">
                <input
                    type="text"
                    name="promo"
                    value={promo}
                    onChange={this.hendlerChange}
                    placeholder="Promo Code (optional)"
                />
                <button
                    className="check-promo"
                    disabled={promo.length < 5 || disabled}
                    onClick={this.hendlerClickCheckPromo}>
                    Apply
            </button>
            </div>
        );
    }
}


export default Promo;
