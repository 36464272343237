import React, { Component } from "react";
import PropTypes from "prop-types";
import { MicroSettings } from "../../containers/MicroSettings";
import GreenSwitcher from "../UI/GreenSwitcher";
import LessonModal from "../../components/ModalLessons";

class ModalDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isType: false,
      isLesson: false,
      start: null,
      end: null,
      courts: [],
      error: {},
    };
    this.closeModal = this.closeModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.modalClass !== this.props.modalClass) {
      this.setState({ error: {} });
    }

    this.setState({
      start: nextProps.data.start,
      end: nextProps.data.end,
      courts: nextProps.data.courts,
    });
  }

  closeModal = (e) => {
    this.props.closeModal();
    this.setState({
      isType: false,
      isLesson: false,
      start: null,
      end: null,
      courts: [],
      error: {},
    });
  };

  handleChange = ({ key, value }) => {
    this.setState({
      [key]: value,
    });
  };

  checkValidation = (state) => {
    const { start, end } = state;
    const error = { success: true };
    return error;
  };

  submit = (data) => {
    this.props.submit(data);

    this.closeModal({ target: { id: "modalBack" } });
  };

  changeModals = () => {
    this.setState((state) => ({
      ...state,
      isLesson: !state.isLesson,
    }));
  };

  render() {
    const { modalClass, timeZone, currentDay, data, ruleButton, permission } = this.props;
    const { isType, isLesson } = this.state;

    if (!modalClass) return null;

    return (
      <div
        className={`modal modal-pass ${modalClass ? "active" : ""}`}
        onClick={(e) => {
          if (e.target.id === "modal-dashboard-bak") {
            //this.closeModal(e);
          }
        }}
        id="modal-dashboard-bak"
      >
        <div className={`modal-content micro-settings-new-style lesson-in-court`}>
          <div className="close-btn">
            <button onClick={this.closeModal}>
              <img src="/assets/img/cancel.png" alt="cancel" />
            </button>
          </div>
          <div className="modal-title switcher-title-div">
            <div className="form-group">
              <label disabled={!permission} className="switcherLabel dashboradswitcher">
                <GreenSwitcher
                  value={isLesson}
                  checked={isLesson}
                  disabled={!permission}
                  onChange={this.changeModals}
                />
                {isLesson ? (
                  <span className="switcher-title">Lesson</span>
                ) : (
                  <span className="switcher-title">Spot</span>
                )}
              </label>
            </div>
          </div>
          {isLesson && (
            <React.Fragment>
              <div className="d-block">
                <LessonModal
                  wait={300}
                  isfromCourt={true}
                  ruleButton={ruleButton}
                  start_time={data.start}
                  end_time={data.end}
                  fromCourt={{
                    ...data,
                  }}
                  getListings={this.props.getListings}
                  currentDay={currentDay}
                  coachList={this.props.coachList ? this.props.coachList : []}
                  close={this.closeModal}
                />
              </div>
            </React.Fragment>
          )}
          {!isLesson && (
            <React.Fragment>
              {/*<div className="d-block">
              <div className="form-group">
                <label className="switcherLabel dashboradswitcher">
                  <GreenSwitcher
                    value={isType}
                    checked={isType}
                    onChange={() => this.setState(state => ({ ...state, isType: !state.isType }))}
                  />
                  {this.state.isType ? <span className="switcher-title">Book Court Time</span> : <span className="switcher-title">List Court Time</span>}
                </label>
              </div>
          </div>*/}
              <div className="d-flex">
                <MicroSettings
                  timeZone={timeZone}
                  ruleButton={ruleButton}
                  fromCourt={{
                    ...data,
                  }}
                  currentDay={currentDay}
                  isType={isType}
                  onClose={this.closeModal}
                  onSubmit={this.submit}
                />
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

ModalDashboard.propTypes = {
  modalClass: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  currentDay: PropTypes.shape().isRequired,
  timeZone: PropTypes.string.isRequired,
};

export default ModalDashboard;
