import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TagsList from './List';
// import TagsForm from './Form';

class TagsModal extends Component {
  constructor(props) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
  }

  closeModal = e => {
    this.props.close();
  };

  render() {
    const { close, timeZone } = this.props;

    return (
      <div className={'modal active group-modal'}>
        <div className="modal-content tags-modal">
          <div className="close-btn">
            <button onClick={close}>
              <img src="/assets/img/cancel.png" alt="cancel" />
            </button>
          </div>
          <TagsList timeZone={timeZone} />
        </div>
      </div>
    );
  }
}

TagsModal.propTypes = {
  close: PropTypes.func.isRequired,
};

export default TagsModal;
