import React, { Component } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { components } from "react-select";
import { getWhoCanBook } from "../../actions/MicroSettings";
import isEqual from "lodash/isEqual";
import "./who-can-book.scss";

const delimiter = "#";

const titleMapper = {
  public: "public",
  allmembers: "allmembers",
  groups: "Groups",
  plans: "Subscription Plans",
  coaches: "Staff",
  facility_groups: "Facility",
};
export const titleKeyValueMapper = {
  allmembers: "allmembers",
  group: "groups",
  subscriptions: "plans",
  coach: "coaches",
  public: "public",
  facility_groups: "facility_groups",
};
export const titleKeyValueReverseMapper = {
  allmembers: "allmembers",
  groups: "group",
  plans: "subscriptions",
  coaches: "coach",
  public: "public",
  facility_groups: "facility_groups",
};

//'coach', 'group', 'public', 'subscriptions', 'allmembers'

// facility_groups: [1,2]
// lesson / online: boolean

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const MultiValue = (props) => (
  <components.MultiValue {...props}>
    <span>{props.data.name || props.data.nickname || props.data.username}</span>
  </components.MultiValue>
);

const GroupHeading = (props) => {
  return (
    props.children !== "allmembers" &&
    props.children !== "public" && (
      <React.Fragment>
        <components.GroupHeading {...props} />
        <div></div>
      </React.Fragment>
    )
  );
};

const animatedComponents = makeAnimated();

class WhoCanBook extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
  }

  getInitialState = () => {
    return {
      groupedOptions: [],
      value: [],
      error: {},
    };
  };

  getOptionForPublic = () => {
    return {
      public: [
        {
          id: "-1",
          name: "Anyone (Public)",
        },
      ],
    };
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.loadingWhoCanBook) {
      return true;
    }
    if (nextProps.whoCanBook) {
      if (Object.keys(nextProps.whoCanBook).length > 0) {
        const who_can_book = this.generateWhoCanBook({
          ...(nextProps.excludePublicOption ? {} : this.getOptionForPublic()),
          ...nextProps.whoCanBook,
        });

        const value = this.generateValue(
          [...nextProps.value],
          [...who_can_book]
        );
        this.setState({
          groupedOptions: [...who_can_book],
          value: value,
        });
      } else {
        this.setState({
          groupedOptions: [],
          value: [],
        });
      }
    }
  }

  generateWhoCanBook(who_can_book) {
    let data = [];

    for (let [key, value] of Object.entries(who_can_book)) {
      data.push({
        label: titleMapper[key],
        options: value.map((v) => {
          return {
            ...v,
            id: `${key}${delimiter}${v.id}`,
          };
        }),
      });
    }
    return data;
  }

  generateValue = (value, who_can_book) => {
    let data = [];

    let values = [].concat
      .apply(
        [],
        value.map((v) =>
          v.values.map((_v) => `${titleKeyValueMapper[v.for]}${delimiter}${_v}`)
        )
      )
      .filter(Boolean);

    if (values.length > 0) {
      for (let each of who_can_book) {
        const options = each.options;
        const label = each.label;
        let selected_values = [];
        if (label === "Subscription Plans") {
          selected_values = options.filter(
            (v) =>
              values.includes(`plans${delimiter}${v.hash_id}`) ||
              values.includes(v.id)
          );
        } else {
          selected_values = options.filter((v) => values.includes(v.id));
        }
        data = [...data, ...selected_values];
        if (
          options.filter((v) => !v.all).length === selected_values.length &&
          selected_values.length > 0
        ) {
          let all_select_optgroup = [].concat.apply(
            [],
            who_can_book.map((v) =>
              v.options.filter(
                (v) => v.group === `all-${selected_values["0"]["group"]}`
              )
            )
          );

          data = [...data, ...all_select_optgroup];
        }
      }
    }
    return data;
  };

  handleChange = (property, current) => {
    const { groupedOptions } = this.state;

    //Determine all in optgroup;
    const filter_public = property ? this.ifCheckedPublic([...property]) : [];

    if (!filter_public) {
      let current_option =
        current.action === "remove-value"
          ? current.removedValue
          : current.option;

      const [group_by, _id] = current_option
        ? current_option.id.split(delimiter)
        : [];
      const _value = this.detectAllOptGroup([...property], current, group_by);
      this.setState({
        value: _value,
      });
    } else {
      this.setState({
        value: filter_public,
      });
    }
  };

  ifCheckedPublic = (value) => {
    const filtered = value.filter((v) => {
      const [group_by, _id] = v.id.split(delimiter);
      if (group_by === "public") {
        return true;
      }
      return false;
    });
    if (filtered.length === 0) {
      return false;
    }
    return filtered;
  };
  detectAllOptGroup = (value, current, optgroup = "coaches") => {
    const { groupedOptions } = this.state;
    let current_option =
      current.action === "remove-value" ? current.removedValue : current.option;
    const [group_by, _id] = current_option
      ? current_option.id.split(delimiter)
      : [];
    let all_optgroup = [].concat.apply(
      [],
      groupedOptions.map((v) => v.options.filter((v) => v.group === optgroup))
    );
    let all_optgroup_length = all_optgroup.length;
    let length_in_value = value.filter((v) => v.group === optgroup).length;

    let all = value;
    if (
      parseInt(_id) === -3 &&
      current.action === "select-option" &&
      group_by === optgroup
    ) {
      all = [...value.filter((v) => v.group !== optgroup), ...all_optgroup];
    }
    if (
      parseInt(_id) === -3 &&
      (current.action === "deselect-option" ||
        current.action === "remove-value") &&
      group_by === optgroup
    ) {
      all = [...value.filter((v) => v.group !== optgroup)];
    }
    if (parseInt(_id) !== -3) {
      if (length_in_value === all_optgroup_length && length_in_value > 0) {
        let all_select_optgroup = [].concat.apply(
          [],
          groupedOptions.map((v) =>
            v.options.filter((v) => v.group === `all-${optgroup}`)
          )
        );
        all = [
          ...all.filter((v) => v.group !== `all-${optgroup}`),
          ...all_select_optgroup,
        ];
      } else {
        all = [...all.filter((v) => v.group !== `all-${optgroup}`)];
      }
    }

    return all;
  };

  whoCanBookForSave = (data) => {
    let prepared = [];
    let tmpStore = {};
    const item = {
      for: "public",
      values: [-1],
      courts: [],
      rules: [],
    };

    if (data.length > 0) {
      for (let value of data) {
        const [group_by, _id] = value.id.split(delimiter);
        if (parseInt(_id) === -3) {
          continue;
        }
        const id = !isNaN(+_id) ? +_id : _id;
        if (tmpStore[group_by]) {
          tmpStore[group_by].values.push(id);
        } else {
          tmpStore[group_by] = {
            ...item,
            for: titleKeyValueReverseMapper[group_by],
            values: [id],
          };
        }
      }
      Object.keys(tmpStore).forEach((key) => {
        prepared.push(tmpStore[key]);
      });
    }

    return prepared;
  };

  componentDidUpdate(prevProps, prevState) {
    const { value } = this.state;
    const { value: value_old } = prevState;
    if (!isEqual(value, value_old)) {
      const updated = this.whoCanBookForSave([...value]);
      this.props.onChangeWhoCanBook([...updated]);
    }
  }

  componentDidMount() {
    this.props.getWhoCanBook();
  }

  render() {
    const { groupedOptions, value } = this.state;
    //defaultMenuIsOpen={true}
    return (
      <div className="who-can-book">
        <Select
          defaultMenuIsOpen={false}
          isMulti
          isClearable={true}
          value={value}
          className="basic-multi-select"
          classNamePrefix="select"
          onChange={this.handleChange}
          options={groupedOptions}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          components={{ Option, GroupHeading, MultiValue, animatedComponents }}
          getOptionLabel={({ name, username, nickname }) =>
            name || nickname || username
          }
          getOptionValue={({ id }) => id}
        />
      </div>
    );
  }
}

export { WhoCanBook as WhoCanBookPlain };

const mapStateToProps = (state) => {
  return {
    whoCanBook: state.micro_settings.current.whoCanBook,
    loadingWhoCanBook: state.micro_settings.current.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getWhoCanBook: (data) => dispatch(getWhoCanBook(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WhoCanBook);
