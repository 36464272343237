import React, { Component } from "react";
import PropTypes from "prop-types";

class FormGroup extends Component {
  render() {
    const {
      label,
      show_policy,
      children,
      hint,
      fromSettings,
      formGroupClass = "",
      ...rest
    } = this.props;

    return (
      <div className={`form-group ${formGroupClass}`}>
        {label && (
          <label htmlFor={fromSettings ? "" : rest.id} className="label-text">
            {show_policy && this.getTermsPolicy()}
            {!show_policy && label}
          </label>
        )}
        {hint && (
          <div className="input-div">
            <span className="filed-hint">({hint})</span>
            <input {...rest} />
          </div>
        )}
        {!hint && <input {...rest} />}
        {children}
      </div>
    );
  }

  getTermsPolicy() {
    return (
      <span>
        *Agree to{" "}
        <a href="/assets/file/privacy-policy" target="_blank">
          Terms of Service
        </a>{" "}
        and{" "}
        <a href="terms" target="_blank">
          Privacy Policy
        </a>
      </span>
    );
  }
}

FormGroup.defaultProps = {
  id: null,
  placeholder: "",
  label: "",
  // children: {},
};
FormGroup.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
};

export default FormGroup;
