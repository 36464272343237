import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";

import * as serviceWorker from "./serviceWorker";

import { render } from "react-dom";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import store, { history } from "./config/store";
import NewModal from "./components/ModalNew";
import Root from "./containers/Routes";
import "@kenshooui/react-multi-select/dist/style.css";
import "./assets/styles/main.scss";
import { ToastProvider } from "react-toast-notifications";

window.onload = function () {
  window.localStorage.removeItem("datePickerSetedDay");
  window.localStorage.removeItem("datePickerSetedDayLesson");
};

render(
  <div>
    <ToastProvider>
    <Provider store={store}>
      <Router history={history}>
        <Root />
      </Router>
    </Provider>
    <NewModal
      ref={(Modal) => {
        window.modal = Modal;
      }}
      history={history}
    />{" "}
    </ToastProvider>
  </div>,
  // eslint-disable-next-line no-undef
  document.getElementById("app")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
