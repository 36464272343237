import * as actionTypes from '../constants/Facility';

const initState = {
  sport: {
    error: undefined,
    current: {
      loading: false,
      data: undefined,
    },
    list: {
      loading: false,
      total: 0,
      data: [],
    },
  },
  sport_category: {
    error: undefined,
    current: {
      loading: false,
      data: undefined,
    },
    list: {
      loading: false,
      total: 0,
      data: [],
    },
  },
  group: {
    error: undefined,
    current: {
      loading: false,
      data: undefined,
    },
    list: {
      loading: false,
      total: 0,
      data: [],
    },
  },
};

const FacilityReducer = (state = initState, action) => {
  switch (action.type) {
    // SPORT_LIST handlers
    case actionTypes.FACILITY_SPORT_LIST_LOADING: {
      return {
        ...state,
        sport: {
          ...state.sport,
          list: {
            ...state.sport.list,
            loading: action.payload,
          },
        },
      };
    }
    case actionTypes.FACILITY_SPORT_LIST_ERROR: {
      return {
        ...state,
        sport: {
          ...state.sport,
          list: {
            ...state.sport.list,
            error: action.payload,
          },
        },
      };
    }
    case actionTypes.FACILITY_SPORT_LIST_SUCCESS: {
      return {
        ...state,
        sport: {
          ...state.sport,
          list: {
            ...state.sport.list,
            error: undefined,
            data: action.payload,
            total: 0,
          },
        },
      };
    }

    // SPORT_CURRENT handlers
    case actionTypes.FACILITY_SPORT_CURRENT_LOADING: {
      return {
        ...state,
        sport: {
          ...state.sport,
          current: {
            ...state.sport.current,
            loading: action.payload,
          },
        },
      };
    }
    case actionTypes.FACILITY_SPORT_CURRENT_ERROR: {
      return {
        ...state,
        sport: {
          ...state.sport,
          current: {
            ...state.sport.current,
            error: action.payload,
          },
        },
      };
    }
    case actionTypes.FACILITY_SPORT_CURRENT_SUCCESS: {
      return {
        ...state,
        sport: {
          ...state.sport,
          current: {
            ...state.sport.current,
            error: undefined,
            data: action.payload,
          },
        },
      };
    }

    // SPORT_CATEGORY_LIST handlers
    case actionTypes.FACILITY_SPORT_CATEGORY_LIST_LOADING: {
      return {
        ...state,
        sport_category: {
          ...state.sport_category,
          list: {
            ...state.sport_category.list,
            loading: action.payload,
          },
        },
      };
    }
    case actionTypes.FACILITY_SPORT_CATEGORY_LIST_ERROR: {
      return {
        ...state,
        sport_category: {
          ...state.sport_category,
          list: {
            ...state.sport_category.list,
            error: action.payload,
          },
        },
      };
    }
    case actionTypes.FACILITY_SPORT_CATEGORY_LIST_SUCCESS: {
      return {
        ...state,
        sport_category: {
          ...state.sport_category,
          list: {
            ...state.sport_category.list,
            error: undefined,
            data: action.payload,
            total: 0,
          },
        },
      };
    }

    // SPORT_CATEGORY_CURRENT handlers
    case actionTypes.FACILITY_SPORT_CATEGORY_CURRENT_LOADING: {
      return {
        ...state,
        sport_category: {
          ...state.sport_category,
          current: {
            ...state.sport_category.current,
            loading: action.payload,
          },
        },
      };
    }
    case actionTypes.FACILITY_SPORT_CATEGORY_CURRENT_ERROR: {
      return {
        ...state,
        sport_category: {
          ...state.sport_category,
          current: {
            ...state.sport_category.current,
            error: action.payload,
          },
        },
      };
    }
    case actionTypes.FACILITY_SPORT_CATEGORY_CURRENT_SUCCESS: {
      return {
        ...state,
        sport_category: {
          ...state.sport_category,
          current: {
            ...state.sport_category.current,
            error: undefined,
            data: action.payload,
          },
        },
      };
    }

    // GROUP_LIST handlers
    case actionTypes.FACILITY_GROUP_LIST_LOADING: {
      return {
        ...state,
        group: {
          ...state.group,
          list: {
            ...state.group.list,
            loading: action.payload,
          },
        },
      };
    }
    case actionTypes.FACILITY_GROUP_LIST_ERROR: {
      return {
        ...state,
        group: {
          ...state.group,
          list: {
            ...state.group.list,
            error: action.payload,
          },
        },
      };
    }
    case actionTypes.FACILITY_GROUP_LIST_SUCCESS: {
      return {
        ...state,
        group: {
          ...state.group,
          list: {
            ...state.group.list,
            error: undefined,
            data: action.payload.entities,
            total: action.payload.total,
          },
        },
      };
    }

    // GROUP_CURRENT handlers
    case actionTypes.FACILITY_GROUP_CURRENT_LOADING: {
      return {
        ...state,
        group: {
          ...state.group,
          current: {
            ...state.group.current,
            loading: action.payload,
          },
        },
      };
    }
    case actionTypes.FACILITY_GROUP_CURRENT_ERROR: {
      return {
        ...state,
        group: {
          ...state.group,
          current: {
            ...state.group.current,
            error: action.payload,
          },
        },
      };
    }
    case actionTypes.FACILITY_GROUP_CURRENT_SUCCESS: {
      return {
        ...state,
        group: {
          ...state.group,
          list: {
            ...state.group.list,
            data: [...state.group.list.data.map(v => {
              if (action.payload.id === v.id) {
                return {
                  ...v,
                  ...action.payload
                }
              }
              return {
                ...v
              }
            })]
          },
          current: {
            ...state.group.current,
            error: undefined,
            data: action.payload,
          },
        },
      };
    }

    default:
      return state;
  }
};

export default FacilityReducer;
