import React from "react";
import { useCallback } from "react";
import { GLOBAL_LOCATION_FACILITY_PAIR } from "../../utils/constants";
import FacilitySelect from "./Facility/facility-select";

const GlobalBusinessSelect = () => {
  const [, facilityGroupId] = (
    window.localStorage.getItem(GLOBAL_LOCATION_FACILITY_PAIR) || `-1:-1`
  ).split(":");

  const handleChange = useCallback(({ target: { value } }) => {
    localStorage.setItem(
      GLOBAL_LOCATION_FACILITY_PAIR,
      `${value.data.location.id}:${value.data.facilityGroup?.id}`
    );
    window.location.reload();
  }, []);

  return (
    <FacilitySelect
      className="kep-original-style"
      defaultOption={null}
      onChange={(opt) =>
        handleChange({ target: { name: "loc_fac_pair", value: opt } })
      }
      value={{
        id: facilityGroupId,
      }}
    />
  );
};

export default GlobalBusinessSelect;
