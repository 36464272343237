import React, { PureComponent } from 'react';

import Pricing from '../../Pricing';
import RequestModal from '../../RequestModal';
import MetaTags from 'react-meta-tags';

// eslint-disable-next-line react/prefer-stateless-function
export default class SectionD extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modalOpened: '',
      modalOpenedCalendly: ''
    };

    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
  }

  closeModal() {
    this.setState({
      modalOpened: '',
    });
  }

  openModal() {
    this.setState({
      modalOpened: (<RequestModal opened={true} close={this.closeModal} />),
    });
  }



  render() {
    const { modalOpened } = this.state;
    return (
      <React.Fragment>
        <MetaTags>
          <title>Tennis Lessons Management Software | Ballbutton Enterprise</title>
          <meta name="description" content="List and sell tennis lessons with the Ballbutton software and app. Manage tennis lessons, groups, teams, accept payments, and find new students with Ballbutton's modern tennis coach software." />
        </MetaTags>
        <section className="section-d" id="players-fans">
          <img className="img" src="/assets/img/web_play_background_long_1.gif" alt="ten" />
          <div className="main-container">
            <div className="section-d-content">
              <div className="section-d-img">
                <img src="/assets/img/iphone-light-3.png" alt="Iphone X" />
                <div className="download-app">
                  <div className="download-logo">
                    <a href="https://itunes.apple.com/us/app/ballbutton-enterprise/id1420755918?mt=8">
                      <img src="/assets/img/mac-logo.png" alt="ballbutton" />
                    </a>
                    <p>
                      Ballbutton Enterprise:
                    <br />
                      For tennis court, lesson and group managers
                  </p>
                  </div>

                  {/*<div>

                    <h3>Download the app</h3>
                    <ul>
                      <li>
                        <a href="https://itunes.apple.com/us/app/ballbutton-enterprise/id1420755918?mt=8">
                          <img src="/assets/img/app-store.png" alt="App Store" />
                        </a>
                      </li>
                    </ul>

                  </div>*/}

                </div>
              </div>
              <div className="mac-img">
                <img src="/assets/img/macbook-lesson.png" alt="Macbook" />
                <div className="message-section">
                  <p>
                    <span> Use our program/lesson manager account if you are:</span>
                  </p>
                  <br />
                  <ul className="ul-messagesection">
                    <li>Manager of a club</li>
                    <li>Manager of a team</li>
                    <li>Freelancer</li>
                    <li>Coach at a club which uses Ballbutton  for court management</li>
                    <li>Coach at a club which doesn’t use Ballbutton. (Your club will benefit anyway if you bring more customer foot-traffic).</li>
                  </ul>
                </div>

              </div>
            </div>
            <Pricing onClickRequestDemo={this.openModal} />
          </div>
          {modalOpened}
        </section>
      </React.Fragment>
    );
  }
}

SectionD.propTypes = {};
