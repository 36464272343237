export const GET_GROUPS_SUCCESS = 'GET_GROUPS_SUCCESS';
export const GET_GROUP_SUCCESS = 'GET_GROUP_SUCCESS';
export const GET_GROUPS_ERROR = 'GET_GROUPS_ERROR';
export const GET_GROUPS_LOADING = 'GET_GROUPS_LOADING';
export const ADD_GROUP_SUCCESS = 'ADD_GROUP_SUCCESS';
export const ADD_GROUP_ERROR = 'ADD_GROUP_ERROR';
export const GET_GROUP_MEMBERS_SUCCESS = 'GET_GROUP_MEMBERS_SUCCESS';
export const GET_GROUP_MEMBERS_LOADING = 'GET_GROUP_MEMBERS_LOADING';
export const GET_GROUP_MEMBERS_ERROR = 'GET_GROUP_MEMBERS_ERROR';
export const GET_GROUP_MEMBER_SUCCESS = 'GET_GROUP_MEMBER_SUCCESS';
export const GET_GROUP_MEMBER_LOADING = 'GET_GROUP_MEMBER_LOADING';
export const GET_GROUP_MEMBER_ERROR = 'GET_GROUP_MEMBER_ERROR';
export const UPDATE_GROUP_LOADING = 'UPDATE_GROUP_LOADING';
export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS';
export const ADD_PAYMANT_SUCCESS_GROUP = 'ADD_PAYMANT_SUCCESS_GROUP';
export const CHANGE_PAYMANT_SUCCESS_GROUP = 'CHANGE_PAYMANT_SUCCESS_GROUP';
export const ADD_GROUP_MEMBER_SUCCESS = 'ADD_GROUP_MEMBER_SUCCESS';
export const ADD_GROUP_MEMBER_ERROR = 'ADD_GROUP_MEMBER_ERROR';
export const UPDATE_GROUP_MEMBER_SUCCESS = 'UPDATE_GROUP_MEMBER_SUCCESS';
export const UPDATE_GROUP_MEMBER_ERROR = 'UPDATE_GROUP_MEMBER_ERROR';
