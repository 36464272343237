// category part
export const SHOP_CATEGORY_CURRENT_LOADING = 'SHOP_CATEGORY_CURRENT_LOADING';
export const SHOP_CATEGORY_CURRENT_SUCCESS = 'SHOP_CATEGORY_CURRENT_SUCCESS';
export const SHOP_CATEGORY_CURRENT_ERROR = 'SHOP_CATEGORY_CURRENT_ERROR';

export const SHOP_CATEGORY_LIST_LOADING = 'SHOP_CATEGORY_LIST_LOADING';
export const SHOP_CATEGORY_LIST_SUCCESS = 'SHOP_CATEGORY_LIST_SUCCESS';
export const SHOP_CATEGORY_LIST_ERROR = 'SHOP_CATEGORY_LIST_ERROR';

// activity part
export const SHOP_ACTIVITY_CURRENT_LOADING = 'SHOP_ACTIVITY_CURRENT_LOADING';
export const SHOP_ACTIVITY_CURRENT_SUCCESS = 'SHOP_ACTIVITY_CURRENT_SUCCESS';
export const SHOP_ACTIVITY_CURRENT_ERROR = 'SHOP_ACTIVITY_CURRENT_ERROR';

export const SHOP_ACTIVITY_LIST_LOADING = 'SHOP_ACTIVITY_LIST_LOADING';
export const SHOP_ACTIVITY_LIST_SUCCESS = 'SHOP_ACTIVITY_LIST_SUCCESS';
export const SHOP_ACTIVITY_LIST_ERROR = 'SHOP_ACTIVITY_LIST_ERROR';

// PRODUCT_TYPE part
export const SHOP_PRODUCT_TYPE_CURRENT_LOADING = 'SHOP_PRODUCT_TYPE_CURRENT_LOADING';
export const SHOP_PRODUCT_TYPE_CURRENT_SUCCESS = 'SHOP_PRODUCT_TYPE_CURRENT_SUCCESS';
export const SHOP_PRODUCT_TYPE_CURRENT_ERROR = 'SHOP_PRODUCT_TYPE_CURRENT_ERROR';

export const SHOP_PRODUCT_TYPE_LIST_LOADING = 'SHOP_PRODUCT_TYPE_LIST_LOADING';
export const SHOP_PRODUCT_TYPE_LIST_SUCCESS = 'SHOP_PRODUCT_TYPE_LIST_SUCCESS';
export const SHOP_PRODUCT_TYPE_LIST_ERROR = 'SHOP_PRODUCT_TYPE_LIST_ERROR';

// BRAND part
export const SHOP_BRAND_CURRENT_LOADING = 'SHOP_BRAND_CURRENT_LOADING';
export const SHOP_BRAND_CURRENT_SUCCESS = 'SHOP_BRAND_CURRENT_SUCCESS';
export const SHOP_BRAND_CURRENT_ERROR = 'SHOP_BRAND_CURRENT_ERROR';

export const SHOP_BRAND_LIST_LOADING = 'SHOP_BRAND_LIST_LOADING';
export const SHOP_BRAND_LIST_SUCCESS = 'SHOP_BRAND_LIST_SUCCESS';
export const SHOP_BRAND_LIST_ERROR = 'SHOP_BRAND_LIST_ERROR';

// MODEL part
export const SHOP_MODEL_CURRENT_LOADING = 'SHOP_MODEL_CURRENT_LOADING';
export const SHOP_MODEL_CURRENT_SUCCESS = 'SHOP_MODEL_CURRENT_SUCCESS';
export const SHOP_MODEL_CURRENT_ERROR = 'SHOP_MODEL_CURRENT_ERROR';

export const SHOP_MODEL_LIST_LOADING = 'SHOP_MODEL_LIST_LOADING';
export const SHOP_MODEL_LIST_SUCCESS = 'SHOP_MODEL_LIST_SUCCESS';
export const SHOP_MODEL_LIST_ERROR = 'SHOP_MODEL_LIST_ERROR';

// COLOR part
export const SHOP_COLOR_CURRENT_LOADING = 'SHOP_COLOR_CURRENT_LOADING';
export const SHOP_COLOR_CURRENT_SUCCESS = 'SHOP_COLOR_CURRENT_SUCCESS';
export const SHOP_COLOR_CURRENT_ERROR = 'SHOP_COLOR_CURRENT_ERROR';

export const SHOP_COLOR_LIST_LOADING = 'SHOP_COLOR_LIST_LOADING';
export const SHOP_COLOR_LIST_SUCCESS = 'SHOP_COLOR_LIST_SUCCESS';
export const SHOP_COLOR_LIST_ERROR = 'SHOP_COLOR_LIST_ERROR';

// SIZE part
export const SHOP_SIZE_CURRENT_LOADING = 'SHOP_SIZE_CURRENT_LOADING';
export const SHOP_SIZE_CURRENT_SUCCESS = 'SHOP_SIZE_CURRENT_SUCCESS';
export const SHOP_SIZE_CURRENT_ERROR = 'SHOP_SIZE_CURRENT_ERROR';

export const SHOP_SIZE_LIST_LOADING = 'SHOP_SIZE_LIST_LOADING';
export const SHOP_SIZE_LIST_SUCCESS = 'SHOP_SIZE_LIST_SUCCESS';
export const SHOP_SIZE_LIST_ERROR = 'SHOP_SIZE_LIST_ERROR';

// UNIT part
export const SHOP_UNIT_CURRENT_LOADING = 'SHOP_UNIT_CURRENT_LOADING';
export const SHOP_UNIT_CURRENT_SUCCESS = 'SHOP_UNIT_CURRENT_SUCCESS';
export const SHOP_UNIT_CURRENT_ERROR = 'SHOP_UNIT_CURRENT_ERROR';

export const SHOP_UNIT_LIST_LOADING = 'SHOP_UNIT_LIST_LOADING';
export const SHOP_UNIT_LIST_SUCCESS = 'SHOP_UNIT_LIST_SUCCESS';
export const SHOP_UNIT_LIST_ERROR = 'SHOP_UNIT_LIST_ERROR';

// VENDOR part
export const SHOP_VENDOR_CURRENT_LOADING = 'SHOP_VENDOR_CURRENT_LOADING';
export const SHOP_VENDOR_CURRENT_SUCCESS = 'SHOP_VENDOR_CURRENT_SUCCESS';
export const SHOP_VENDOR_CURRENT_ERROR = 'SHOP_VENDOR_CURRENT_ERROR';

export const SHOP_VENDOR_LIST_LOADING = 'SHOP_VENDOR_LIST_LOADING';
export const SHOP_VENDOR_LIST_SUCCESS = 'SHOP_VENDOR_LIST_SUCCESS';
export const SHOP_VENDOR_LIST_ERROR = 'SHOP_VENDOR_LIST_ERROR';

// CART part
export const SHOP_CART_CURRENT_LOADING = 'SHOP_CART_CURRENT_LOADING';
export const SHOP_CART_CURRENT_SUCCESS = 'SHOP_CART_CURRENT_SUCCESS';
export const SHOP_CART_CURRENT_ERROR = 'SHOP_CART_CURRENT_ERROR';

export const SHOP_CART_LIST_LOADING = 'SHOP_CART_LIST_LOADING';
export const SHOP_CART_LIST_SUCCESS = 'SHOP_CART_LIST_SUCCESS';
export const SHOP_CART_LIST_ERROR = 'SHOP_CART_LIST_ERROR';

export const SHOP_ANALYTICS_LOADING = 'SHOP_ANALYTICS_LOADING';
export const SHOP_ANALYTICS_SUCCESS = 'SHOP_ANALYTICS_SUCCESS';
export const SHOP_ANALYTICS_ERROR = 'SHOP_ANALYTICS_ERROR';

export const GENDER_MAP = {
  NA: 'N/A',
  male: 'Male',
  female: 'Female',
};
