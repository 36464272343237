import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import Auth from "../../containers/Auth";
import ContactAsModal from "../ContactAsModal";
import LessonTypeModal from "../LessonTypeModal";
import { connect } from "react-redux";
import { GetLessonTypes ,GetHiddenLessonTypes} from "../../actions/Lesson";
import RequestModal from "../../components/RequestModal";
import { hasPermission, roundTextTo } from "../../utils/helpers";
import Demo from "../Demo";
import More from "./more";
import { GlobalBusinessSelect } from "../../containers/Business";
import Select, { StylesConfig } from 'react-select';
import chroma from 'chroma-js';
import "./dropdownmenu.scss";
import { ArchiveModal } from "../ArchivedTypes";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpened: false,
      menuOpened: false,
      openedModal: null,
      modalOpenedDemo: null,
      contactModal: false,
      lessonTypes: [],
      hiddenLessonTypes: [],
      lessonTypesOptions: [],
    };

    this.closeModal = this.closeModal.bind(this);
    this.closeModal2 = this.closeModal2.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.openModal2 = this.openModal2.bind(this);
    this.openLessonTypeModal = this.openLessonTypeModal.bind(this);
    this.closeLessonTypeModal = this.closeLessonTypeModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.showArchiveModal = this.showArchiveModal.bind(this);
  }

  componentWillMount() {
    document.body.onclick = (e) => {
      if (e.target.id === "app-content") {
        this.setState({
          menuOpened: false,
        });
        document.getElementById("app-content").classList.remove("active");
      }
    };
  }

  scrollHeader = () => {
    /*const header = document.getElementById("bb_header");
    const _height = header.offsetHeight;
    const spacer = document.getElementById("spacer");
    const sticky = header.offsetTop;


    if (window.pageYOffset > sticky) {
      header.classList.add("sticky");
      spacer.classList.add("d-block");
      spacer.style.height = `${_height}px`
    } else {
      header.classList.remove("sticky");
      spacer.classList.remove("d-block");
      spacer.style.height = 'auto'
    }*/
  };

  _scroll = () => {
    if (this.props.location.pathname !== "/") {
      document.addEventListener("scroll", this.scrollHeader);
      document.getElementById("bb_header").classList.remove("no-shadow");
    } else {
      document.getElementById("bb_header").classList.add("no-shadow");
    }
  };

  componentDidUpdate(prevProps) {
    this._scroll();

    this.checkToOpenLoginModal(prevProps.location.pathname);
  }

  componentDidMount() {
    const { user } = this.props;
    if (user && (user.user_type !== "nbu" || user.business_id)) {
      this.props.GetLessonTypes();
      this.props.GetHiddenLessonTypes();
    }
    this._scroll();

    this.checkToOpenLoginModal();
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.scrollHeader);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.lessonTypes && nextProps.lessonTypes.length > 0) {
      this.setState({
        lessonTypes: nextProps.lessonTypes,
        hiddenLessonTypes: nextProps.lessonTypes,
      });
    }
  }

  navigateTo = (path) => {
    this.props.history.push({
      pathname: path,
      search: this.props.location.search,
    });
  };

  checkToOpenLoginModal = (prevPathname) => {
    const { pathname } = this.props.location;
    if (pathname !== prevPathname) {
      if (pathname.includes("/login")) {
        this.setState((st) => ({ ...st, modalOpened: true }));
      } else if (this.state.modalOpened) {
        this.setState((st) => ({ ...st, modalOpened: false }));
      }
    }
  };

  closeModal2() {
    this.setState({
      contactModal: false,
    });
  }

  openModal2() {
    this.setState({
      contactModal: true,
    });
  }
  showArchiveModal(){
    this.setState({
      openedModal: <ArchiveModal close={this.closeLessonTypeModal}/>,
    });
   
  }
  closeModal() {
    this.setState({
      modalOpened: false,
      modalOpenedDemo: null,
    });
  }

  openModalRequestDemo = () => {
    this.setState({
      modalOpenedDemo: <RequestModal opened={true} close={this.closeModal} />,
    });
  };

  openLessonTypeModal() {
    this.setState({
      openedModal: <LessonTypeModal clouseModal={this.closeLessonTypeModal} />,
    });
  }

  closeLessonTypeModal() {
    this.setState({ openedModal: null });
  }

  toggleMenu() {
    this.setState({
      menuOpened: !this.state.menuOpened,
    });
    if (!this.state.menuOpened) {
      document.getElementById("app-content").classList.add("active");
    } else {
      document.getElementById("app-content").classList.remove("active");
    }
  }
  handleChange(el){
    
    this.props.history.push(`/private/lessons/${el.value}`);
  }
  render() {
    const { isSignIn, user } = this.props;
    const roles = user.member_type && user.member_type.roles;
    const member_type = user.member_type;
    const is_nbu = user.business && user.user_type === "nbu" ? true : false;
    const is_admin = user.business && user.user_type === "bu" ? true : false;
    const reportPermiton = (user.member_type && user.member_type.key!=undefined?user.member_type.key=="admin" || user.member_type.key=="accountant" || user.member_type.key=="owner":false) 
    const is_lesson =
      this.props.location.pathname.indexOf("/private/lessons") > -1;
    const { openedModal, lessonTypes, modalOpenedDemo ,HiddenLessonTypes} = this.state;
    const paths_for_request_demo = ["/lesson_management"];
    const paths_for_show_nav = [
      "/pricing-and-features",
      "/club_management_software",
      "/why-ballbutton",
      "/membership_advantage",
      "/fitness_yoga_swim_cycle_gym",
      "/social_engage",
      "/newsfeed",
      "/golf",
      "/racquets"
    ];
    let lis_for_not_signin = "";
    
    if (
      paths_for_show_nav.includes(this.props.location.pathname) ||
      this.state.menuOpened
    ) {
      lis_for_not_signin = (
        <React.Fragment>
          <li>
            <Demo onClick={this.openModalRequestDemo} />
          </li>
        </React.Fragment>
      );
    }

    const dot = (color = 'transparent') => ({
      alignItems: 'center',
      display: 'flex',
    
      ':before': {
        backgroundColor: color,
        borderRadius: 10,
        content: '" "',
        display: 'block',
        marginRight: 8,
        height: 10,
        width: 10,
      },
    });
    
   
    const lessonTypesOptions = [{value:"-1",label:"All",color:"#000000"},...lessonTypes.map((type)=>{
      return {value:type.id,label:type.display_name,color:(type.color!=null?type.color:"#1aab8a")}
    }).sort(function(a, b) {
      return (a.label.toUpperCase() < b.label.toUpperCase()) ? -1 : (a.label.toUpperCase() > b.label.toUpperCase()) ? 1 : 0;
  })];
  const lessonSelecteId = this.props.location.pathname.substring(this.props.location.pathname.lastIndexOf('/') + 1);
   const selectedLessonTypesOptions= (
    lessonSelecteId!="-1" &&lessonTypesOptions.findIndex(item => item.value == lessonSelecteId)>0?
    lessonTypesOptions.findIndex(item => item.value == lessonSelecteId):
     0
     )
    
    const colourStyles= {
      control: (styles) => ({ ...styles, backgroundColor: 'white' ,width:300}),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const color = chroma(data.color);
        return {
          ...styles,
          backgroundColor: isDisabled
            ? undefined
            : isSelected
            ? data.color
            : isFocused
            ? color.alpha(0.1).css()
            : undefined,
          color: isDisabled
            ? '#ccc'
            : isSelected
            ? chroma.contrast(color, 'white') > 2
              ? 'white'
              : 'black'
            : data.color,
          cursor: isDisabled ? 'not-allowed' : 'default',
    
          ':active': {
            ...styles[':active'],
            backgroundColor: !isDisabled
              ? isSelected
                ? data.color
                : color.alpha(0.3).css()
              : undefined,
          },
        };
      },
      input: (styles) => ({ ...styles, ...dot() }),
      placeholder: (styles) => ({ ...styles, ...dot('#ccc') }),
      singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
    };
   
    return (
      <React.Fragment>
        <header id="bb_header" className={!isSignIn ? "sticky" : ""}>
          <div className="main-container">
            <div className="header-content">
              <div className="header-logo">
                <Link to="/">
                  <img src="/assets/img/logo-new.png" alt="ballbutton" />
                </Link>
              </div>
              {!isSignIn &&
                paths_for_request_demo.includes(
                  this.props.location.pathname
                ) && (
                  <div className="mobile-get-demo">
                    <div
                      onClick={this.openModalRequestDemo}
                      className="demo-btn"
                    >
                      Get a Private Demo
                    </div>
                  </div>
                )}
              <div
                className={`header-menu-btn ${this.state.menuOpened ? "close" : ""
                  }`}
              >
                <button onClick={this.toggleMenu}>
                  <ul>
                    <li />
                    <li className="item2" />
                    <li />
                  </ul>
                </button>
              </div>
              <div
                style={isSignIn ? {} : { paddingLeft: "114px" }}
                className={`header-menu ${this.state.menuOpened ? "active" : ""
                  }`}
                id="header-menu"
              >
                <div className={is_lesson ? "nav-bar extra-navbar" : "nav-bar"}>
                  {isSignIn && (is_nbu || is_admin) ? (
                    this.props.location.pathname !==
                      "/private/choose-business" ? (
                      <React.Fragment>
                        <ul>
                          {hasPermission(roles, "court", "read") && (
                            <li
                              className={
                                this.props.location.pathname ===
                                  "/private/dashboard"
                                  ? "active"
                                  : ""
                              }
                            >
                              <Link
                                onClick={this.toggleMenu}
                                to="/private/dashboard"
                              >
                                {roundTextTo(
                                  user.business?.btn3 || "Facility-sheet",
                                  14
                                )}
                              </Link>
                            </li>
                          )}
                          {hasPermission(roles, "lesson", "read") && (
                            <li
                              className={
                                this.props.location.pathname.indexOf(
                                  "/private/lessons"
                                ) > -1
                                  ? "active"
                                  : ""
                              }
                            >
                              <Link
                                onClick={this.toggleMenu}
                                to="/private/lessons/-1"
                              >
                                {user.business?.btn2 || user.business?.btn1
                                  ? `${roundTextTo(
                                    user.business.btn2,
                                    14
                                  )} ${user.business?.btn2 && user.business?.btn1?"&":""} ${roundTextTo(user.business.btn1, 14)}`
                                  : `${roundTextTo(user.business.btn2, 14)}`}
                              </Link>
                            </li>
                          )}
                          {reportPermiton && (
                            <li>
                              <Link
                                onClick={this.toggleMenu}
                                to="/private/reports"
                              >
                                Reports
                              </Link>

                              { /* <ul>
                                {!is_nbu && (
                                  <li
                                    className={
                                      this.props.location.pathname.indexOf(
                                        "/private/reports"
                                      ) > -1
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    <Link
                                      onClick={this.toggleMenu}
                                      to="/private/reports"
                                    >
                                      Reports
                                    </Link>
                                  </li>
                                )}
                                <li
                                  className={
                                    this.props.location.pathname ===
                                      "/private/analytics"
                                      ? "active"
                                      : ""
                                  }
                                >
                                  <Link
                                    onClick={this.toggleMenu}
                                    to="/private/analytics"
                                  >
                                    Analytics
                                 </Link>
                                </li>
                              </ul>*/}
                            </li>
                          )}
                          {hasPermission(roles, "members", "read") && (
                            <li
                              className={
                                this.props.location.pathname ===
                                  "/private/members"
                                  ? "active"
                                  : ""
                              }
                            >
                              <Link
                                onClick={this.toggleMenu}
                                to="/private/members"
                              >
                                Customers
                              </Link>
                            </li>
                          )}
                          {hasPermission(roles, "notifications", "read") && (
                            <li
                              className={
                                this.props.location.pathname ===
                                  "/private/notifications"
                                  ? "active"
                                  : ""
                              }
                            >
                              <Link
                                onClick={this.toggleMenu}
                                to="/private/notifications"
                              >
                                Notifications
                              </Link>
                            </li>
                          )}
                          {hasPermission(roles, "payments", "read") && (
                            <li>
                              <span>{is_nbu ? `Pro Shop` : `POS`}</span>
                              <ul>
                                {!is_nbu && (
                                  <li
                                    className={
                                      this.props.location.pathname.indexOf(
                                        "/private/stripe"
                                      ) > -1
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    <Link
                                      onClick={this.toggleMenu}
                                      to="/private/stripe"
                                    >
                                      Payment
                                    </Link>
                                  </li>
                                )}
                                <li
                                  className={
                                    this.props.location.pathname ===
                                      "/private/shop"
                                      ? "active"
                                      : ""
                                  }
                                >
                                  <Link
                                    onClick={this.toggleMenu}
                                    to="/private/shop"
                                  >
                                    {is_nbu ? `Pro Shop` : `POS`}
                                  </Link>
                                </li>
                                {hasPermission(
                                  roles,
                                  "pos_backoffice",
                                  "read"
                                ) && (
                                    <li
                                      className={
                                        this.props.location.pathname ===
                                          "/private/shop/control-panel"
                                          ? "active"
                                          : ""
                                      }
                                    >
                                      <Link
                                        onClick={this.toggleMenu}
                                        to="/private/shop/control-panel"
                                      >
                                        POS Back Office
                                      </Link>
                                    </li>
                                  )}
                              </ul>
                            </li>
                          )}
                          <li className="pf_btn_li" >
                          {isSignIn && (
                              <button className="pf-button " onClick={()=>{
                                window.modal.pfAlert("BallButton User Benefit: PassFactory (our sister company)", "This season’s benefit: our partner special deal with PassFactory <a href='https://passfactory.com' target='_blank'><b>passfactory.com</b></a>  Use coupon code <b>BB20peRcent4evER</b> for 20% off any membership.")
                              }}>Event Dinings</button>
                            )}
                          </li>
                        </ul>
                        {is_lesson && hasPermission(roles, "lesson", "read") ? (
                          <ul className="submenu">
                            {!["coach", "supercoach", "member"].includes(
                              member_type.key
                            ) && (
                                <li>
                                  <div  className="btn_item" style={{width: "85px"}}>
                                  <div className="plus-icon" id="plus">
                                    <button disabled={(hasPermission(roles, "lesson", "update") ? false : true)} onClick={this.openLessonTypeModal}>
                                      <img
                                        src="/assets/img/plus.png"
                                        alt="plus"
                                      />
                                    </button>
                                    
                                  </div>
                                  <small  className="smallTextTitleToPlusBtn">Add Service Type</small>
                                  </div>
                                </li>
                              )}
                            <li
                              className={
                                this.props.location.pathname ===
                                  "/private/lessons/-1"
                                  ? "active"
                                  : ""
                              }
                            >
                              {lessonTypesOptions.length>0 ?
                              <div className="serviceTypesSelect">
                                <span>Service Types</span>
                                <Select
                                defaultValue={lessonTypesOptions[selectedLessonTypesOptions]}
                                options={lessonTypesOptions}
                                styles={colourStyles}
                                onChange={this.handleChange}
                                />
                              </div>
                              :null}
                              {!is_nbu&&(
                                 <div className="pretty-button archivedTypesBtn">
                                 <button type="button"  onClick={this.showArchiveModal}  className={"yellow-button"}>Archived Service Types</button>
                               </div>
                              )}
                             
                            {/*  <Link
                                onClick={this.toggleMenu}
                                to="/private/lessons/-1"
                              >
                                All
                              </Link>
                            */}
                            </li>
                            {false &&
                              lessonTypes.map((type) => {
                                return (
                                  <li
                                    key={type.id}
                                    className={
                                      this.props.location.pathname ===
                                        `/private/lessons/${type.id}`
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    <Link
                                      onClick={this.toggleMenu}
                                      to={`/private/lessons/${type.id}`}
                                    >
                                      <span
                                        style={{ background: type.color }}
                                        className="circle-indicator"
                                      ></span>
                                      {type.display_name}
                                    </Link>
                                  </li>
                                );
                              })}
                          </ul>
                        ) : (
                          ""
                        )}
                      </React.Fragment>
                    ) : null
                  ) : (
                    (paths_for_show_nav.includes(
                      this.props.location.pathname
                    ) ||
                      this.state.menuOpened) && (
                      <ul className="home-navbar">
                        <li className="dropdown_menu">
                          <span>Products</span> <span className="cursor">&#10095;</span>
                          <ul>
                            <li
                              className={
                                this.props.location.pathname ===
                                  "/racquets"
                                  ? "active"
                                  : ""
                              }
                            >
                              <Link
                                onClick={this.toggleMenu}
                                to="/racquets"
                              >
                                Racquet Sports
                              </Link>
                              <span className="green-line"></span>
                            </li>
                            <li
                              className={
                                this.props.location.pathname ===
                                  "/golf"
                                  ? "active"
                                  : ""
                              }
                            >
                              <Link
                                onClick={this.toggleMenu}
                                to="/golf"
                              >
                                Golf
                              </Link>
                              <span className="green-line"></span>
                            </li>
                            {/* <li
                              className={
                                this.props.location.pathname ===
                                  "/fitness_yoga_swim_cycle_gym"
                                  ? "active"
                                  : ""
                              }
                            >
                              <Link
                                onClick={this.toggleMenu}
                                to="/fitness_yoga_swim_cycle_gym"
                              >
                                Fitness, Swim, Yoga
                              </Link>
                              <span className="green-line"></span>
                            </li> */}
                            {/* <li
                              className={
                                this.props.location.pathname ===
                                  "/membership_advantage"
                                  ? "active"
                                  : ""
                              }
                            >
                              <Link
                                onClick={this.toggleMenu}
                                to="/membership_advantage"
                              >
                                Membership Management
                              </Link>
                              <span className="green-line"></span>
                            </li> */}

                            {/* <li
                              className={
                                this.props.location.pathname ===
                                  "/newsfeed"
                                  ? "active"
                                  : ""
                              }
                            >
                              <Link
                                onClick={this.toggleMenu}
                                to="/newsfeed"
                              >
                                Club’s News Feed
                              </Link>
                              <span className="green-line"></span>
                            </li> */}
                          </ul>
                        </li>
                        <li
                          className={
                            this.props.location.pathname ===
                              "/club_management_software"
                              ? "active"
                              : ""
                          }
                        >
                          <Link
                            onClick={this.toggleMenu}
                            to="/club_management_software"
                          >
                            About
                          </Link>
                          <span className="green-line"></span>
                        </li>
                        <li
                          className={
                            this.props.location.pathname ===
                              "/pricing-and-features"
                              ? "active"
                              : ""
                          }
                        >
                          <Link
                            onClick={this.toggleMenu}
                            to="/pricing-and-features"
                          >
                            Pricing
                          </Link>
                          <span className="green-line"></span>
                        </li>
                        <li
                          className={
                            this.props.location.pathname === "/why-ballbutton"
                              ? "active"
                              : ""
                          }
                        >
                          <Link onClick={this.toggleMenu} to="/why-ballbutton">
                            Why Ballbutton
                          </Link>
                          <span className="green-line"></span>
                        </li>
                      </ul>
                    )
                  )}
                  
                </div>
               
                
                {isSignIn ? (
                  <div className="sign-btns">
                    
                    <ul>
                      {(user.user_type === "nbu" ? user.business_id : true) &&
                        this.props.location.pathname !==
                        "/private/choose-business" && (
                          <li className="filterAndLocationDropdown">
                            <div
                              className="form-group m-0"
                              style={{ margin: 0 }}
                            >
                              <label>Filter by location & facility</label>
                              <GlobalBusinessSelect />
                            </div>
                          </li>
                        )}
                      {user.user_type === "nbu" &&
                        !user.business_id &&
                        this.props.location.pathname !==
                        "/private/choose-business" && (
                          <li>
                            <Link
                              to="/private/choose-business"
                              className="btn"
                              style={{ marginTop: "20px" }}
                            >
                              Memberships
                            </Link>
                          </li>
                        )}
                      <li style={{ marginTop: "20px" }}>
                        <More
                          signOut={this.props.signOut}
                          history={this.props.history}
                          roles={roles}
                          user={user}
                          location={this.props.location}
                          toggleMenu={this.toggleMenu}
                        />
                      </li>
                    </ul>
                  </div>
                ) : (
                  <div className="sign-btns small_for_mobile">
                    <ul>
                      {lis_for_not_signin}
                      <li className="log white-log">
                        <button onClick={() => this.navigateTo("/login")}>
                          Sign in
                        </button>
                      </li>
                    </ul>
                    <div className="log-in-xs">
                      <p>Or</p>
                      <button onClick={() => this.navigateTo("/login")}>
                        My account
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {!isSignIn && (
            <Auth
              opened={this.state.modalOpened}
              close={() => this.navigateTo("/")}
            />
          )}
          <ContactAsModal
            opened={this.state.contactModal}
            close={this.closeModal2}
          />
          {openedModal}
          {modalOpenedDemo}
        </header>
        {!isSignIn && <div id="spacer"></div>}
      </React.Fragment >
    );
  }
}

Header.propTypes = {
  isSignIn: PropTypes.bool.isRequired,
  signOut: PropTypes.func.isRequired,
  userType: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  return {
    lessonTypes: state.lesson.lessonTypes,
    hiddenLessonTypes: state.lesson.hiddenLessonTypes,
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetLessonTypes: () => dispatch(GetLessonTypes()),
    GetHiddenLessonTypes: () => dispatch(GetHiddenLessonTypes()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
