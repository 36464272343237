import React, { Component } from "react";
import moment from "moment-timezone";
import FormGroup from "../FormGroup";
import DatePicker from "react-datepicker";
//import { injectStripe } from 'react-stripe-elements';
import { searchuserbyfullname } from "../../actions/util";
import PaymentsModal from "../PaymentsModal";
import ModalWaiver from "../ModalWaiver";
import Switcher from "../UI/Switcher";

import { getActivePair } from "../../utils/facility";

const WAIT_INTERVAL = 400;

class AutoFill extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.generateInitialState(),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleBook = this.handleBook.bind(this);
  }

  async handleChange(e) {
    const { is_nbu, partner, user, during_add, theNameSelected } = this.props;

    // Init a timeout variable to be used below
    const _value = e.target.value.replace(/[^A-Za-z ]+/g, "");

    this.setState({
      memberId: null,
      stripeUserId: null,
    });

    // Make a new timeout set to go off in 800ms
    if (_value.length > 0) {
      this.setState({
        book: _value,
      });


      if (
        (!is_nbu && partner) ||
        (user && user.user_type === "bu") ||
        during_add
      ) {
        clearTimeout(this.timer);

        this.timer = setTimeout(async () => {
          if (theNameSelected) {
            theNameSelected(_value.length > 1)
          }

          const value = await searchuserbyfullname(_value.trim());

          this.setState({
            names: value,
          });
        }, WAIT_INTERVAL);
      }
    } else {
      this.setState({
        ...this.generateInitialState(),
      });

      if (theNameSelected) {
        theNameSelected(_value.length > 1)
      }

    }

  }
  generateInitialState = () => {
    const { is_nbu, user, names, family_member } = this.props;
    let book_name = "";
    let memberId = null;
    if (is_nbu) {
      book_name = family_member
        ? `${family_member.member.first_name} ${family_member.member.last_name}`
        : `${user.first_name} ${user.last_name}`;
      memberId = family_member ? family_member.user_id : user.id;
    }

    return {
      names: names ? names : [],
      disabled: false,
      ...this.getStartEndDate(),
      showRange: false,
      book: book_name,
      memberId: memberId,
      stripeUserId: null,
      openedModal: null,
    };
  };
  closeModal = () => {
    this.setState({ openedModal: null });
  };

  getPaymentAccount = () => {
    const { user, family_member, is_nbu } = this.props;
    let payment_account_id;
    if (is_nbu) {
      payment_account_id =
        user && user.business ? user.business.member.payment_account_id : null;
      payment_account_id =
        family_member && family_member.payment_account_id
          ? family_member.payment_account_id
          : !family_member
            ? payment_account_id
            : family_member.payer.payment_account_id;
    }
    return payment_account_id;
  };

  openUpdateCase = () => {
    const { user } = this.props;
    return new Promise((resolve, reject) => {
      this.setState({
        openedModal: (
          <ModalWaiver
            business={user?.business}
            resolve={resolve}
            closeDell={this.closeModal}
          />
        ),
      });
    });
  };

  handleAddFreeTextBtn() {
    const { isFreeText } = this.props

    this.props?.changeFreeText(!isFreeText)
  }
  async handleBook(e) {
    const { user, currentRecord, is_nbu, id, outerState } = this.props;
    const [activeLocation, activeFacilityGroup] = getActivePair();
    const allow_to_pay_in_person = activeFacilityGroup
      ? activeFacilityGroup.allow_to_pay_in_person
      : true;
    const cancel_allowed = activeFacilityGroup
      ? activeFacilityGroup.cancel_allowed
      : true;
    let payment_account_id = this.getPaymentAccount();
    let default_price = currentRecord ? currentRecord.price : 0;
    let default_point = currentRecord ? currentRecord.point_price : 0;

    const lesson_id = currentRecord?.lesson_id;
    const appointmentId = currentRecord?.appointment_id;

    const booking_type = e.target.name;

    const require_waiver_sign = user?.business?.require_waiver_sign;
    const is_signed = user?.business?.member?.signed;

    const business = this.props?.user?.business;

    const query = new URLSearchParams(this.props?.location?.search);
    let family_member = null
    const family_param = query.get("family");
    const family_list = business?.family;

    if (family_param) {
      family_member = family_list.filter((v) => v.user_id === family_param);
      family_member = family_member.length === 1 ? family_member["0"] : null;
    }

    if (is_nbu && require_waiver_sign && !is_signed) {
      let updateMode = await this.openUpdateCase();
      if (!updateMode.event) {
        return;
      }
    }

    //return false
    if ((!allow_to_pay_in_person || !cancel_allowed) && is_nbu) {
      let book_pay_type = Number(outerState.book_pay_type);
      if (id < 0) {
        if (family_member) {
          switch (book_pay_type) {
            case -1:
              default_price = await this.props.getPrice({
                startDate: moment(outerState.startTime).utc().format(),
                endDate: moment(outerState.endTime).utc().format(),
                sport_id: outerState.sport_id,
                courts: [outerState.courtid],
                user_id: family_member ? family_member.user_id : null,
                for: "price",
              });
              default_point = await this.props.getPrice({
                startDate: moment(outerState.startTime).utc().format(),
                endDate: moment(outerState.endTime).utc().format(),
                sport_id: outerState.sport_id,
                courts: [outerState.courtid],
                user_id: family_member ? family_member.user_id : null,
                for: "point_price",
              });
              break;
            case 1:
              default_price = await this.props.getPrice({
                startDate: moment(outerState.startTime).utc().format(),
                endDate: moment(outerState.endTime).utc().format(),
                sport_id: outerState.sport_id,
                courts: [outerState.courtid],
                user_id: family_member ? family_member.user_id : null,
                for: "price",
              });
              break;
            case 2:
              default_point = await this.props.getPrice({
                startDate: moment(outerState.startTime).utc().format(),
                endDate: moment(outerState.endTime).utc().format(),
                sport_id: outerState.sport_id,
                courts: [outerState.courtid],
                user_id: family_member ? family_member.user_id : null,
                for: "point_price",
              });
              break;

            default:
              break;
          }
        } else {
          switch (book_pay_type) {
            case -1:
              default_price = await this.props.getPrice({
                startDate: moment(outerState.startTime).utc().format(),
                endDate: moment(outerState.endTime).utc().format(),
                sport_id: outerState.sport_id,
                courts: [outerState.courtid],
                for: "price",
              });
              default_point = await this.props.getPrice({
                startDate: moment(outerState.startTime).utc().format(),
                endDate: moment(outerState.endTime).utc().format(),
                sport_id: outerState.sport_id,
                courts: [outerState.courtid],
                for: "point_price",
              });
              break;
            case 1:
              default_price = await this.props.getPrice({
                startDate: moment(outerState.startTime).utc().format(),
                endDate: moment(outerState.endTime).utc().format(),
                sport_id: outerState.sport_id,
                courts: [outerState.courtid],
                for: "price",
              });
              break;
            case 2:
              default_point = await this.props.getPrice({
                startDate: moment(outerState.startTime).utc().format(),
                endDate: moment(outerState.endTime).utc().format(),
                sport_id: outerState.sport_id,
                courts: [outerState.courtid],
                for: "point_price",
              });
              break;

            default:
              break;
          }
        }
      }
      if (Number(default_price) === 0) {
        return this.bookEvent(booking_type);
      }

      return this.setState({
        openedModal: (
          <PaymentsModal
            from={{
              hide_subtotal: true,
            }}
            lessonId={lesson_id}
            appointmentId={appointmentId}
            isRecurrence={currentRecord?.allowBooking === "recurrence"}
            default_price={default_price}
            default_point={default_point}
            payment_account_id={payment_account_id}
            cbCharge={(data) => this.bookEvent(booking_type, data)}
            user={user}
            book_pay_type={
              book_pay_type ? book_pay_type.toString() : book_pay_type
            }
            clouseModal={this.closeModal}
          />
        ),
      });
    } else {
      this.bookEvent(booking_type);
    }
  }

  bookEvent = (booking_type, data = null) => {
    const { memberId, book, startDate, endDate } = this.state;
    const { is_lesson } = this.props;
    const payment_account_id = this.getPaymentAccount();

    if (is_lesson) {
      this.props.handleBook(memberId, {
        booking_type: booking_type,
        userFullName: book,
        startDate: startDate,
        endDate: endDate,
        payment: data ? { ...data, customer: payment_account_id } : null,
      });
    } else {
      if (booking_type === "recurrence") {
        this.props.handleBook(
          memberId,
          {
            userFullName: book,
            payment: data ? { ...data, customer: payment_account_id } : null,
          },
          true
        );
      } else {
        this.props.handleBook(memberId, {
          userFullName: book,
          payment: data ? { ...data, customer: payment_account_id } : null,
        });
      }
    }

    this.setState({
      ...this.generateInitialState(),
    });
  };

  getStartEndDate = () => {
    const { currentRecord, timezone } = this.props;

    const booking_to_start = this.props.user?.business?.booking_to_start || 30;

    return {
      startDate:
        currentRecord && currentRecord.start_time
          ? moment(currentRecord.start_time).tz(timezone)
          : moment.tz(timezone),
      endDate:
        currentRecord && currentRecord.end_time
          ? moment(currentRecord.end_time).tz(timezone)
          : moment.tz(timezone).add(booking_to_start, "minutes"),
      minDate:
        currentRecord && currentRecord.min_max_dates
          ? moment(currentRecord.min_max_dates["0"]["min_start_time"]).tz(
            timezone
          )
          : moment.tz(timezone),
      maxDate:
        currentRecord && currentRecord.min_max_dates
          ? moment(currentRecord.min_max_dates["0"]["max_end_time"]).tz(
            timezone
          )
          : moment.tz(timezone),
    };
  };

  handleBookRange = (show) => {
    this.setState({
      showRange: show,
    });
  };
  setDate = (date, field) => {
    this.setState({
      [field]: date,
    });
  };

  addToWaitlist = () => {
    this.props.addToWaitlist({
      userId: this.state.memberId,
      fullName: this.state.book,
    });
  };
  dropClick(value, uid = null, username = null) {
    const _stripeUserId = username ? "-1" : null;

    this.setState({
      book: value,
      names: [],
      memberId: uid,
      stripeUserId: _stripeUserId,
    });
  }

  componentDidUpdate() {
    if (!this.state.book && this.state.names.length > 0) {
      this.setState({
        names: [],
      });
    }
  }
  render() {
    const {
      names,
      book,
      startDate,
      endDate,
      showRange,
      minDate,
      maxDate,
      openedModal,

    } = this.state;
    const {
      type,
      is_lesson,
      add_partners,
      button_name,
      is_nbu,
      currentRecord,
      show_book,
      isFreeText,
      add_to_waitlist,
    } = this.props;


    return (
      <React.Fragment>
        <div className="mod-item autofill-container">
          <div className="grouped-form  pad-0 ">
            <FormGroup
              type="text"
              name="book"
              autoComplete="off"
              className={`${this.state.book ? "ignore-right-border" : ""}`}
              value={book}
              onChange={this.handleChange}
              disabled={this.state.disabled}
            />
          </div>

          <div
            className={`${this.state.book
              ? show_book === false && !is_lesson && is_nbu
                ? ` sign-up-btn disabled-div`
                : "sign-up-btn"
              : "d-none"
              }`}
          >
            {!showRange && (
              <React.Fragment>
                {((currentRecord &&
                  currentRecord.allowBooking !== "recurrence") ||
                  !is_nbu) && (
                    <button
                      title=""
                      alt=""
                      onClick={type ? this.addToWaitlist : this.handleBook}
                      name="single"
                      type="button"
                    >
                      {type ? `Add` : button_name ? button_name : `Book`}
                    </button>
                  )}
                {this.props.id > 0 &&
                  ((is_nbu &&
                    currentRecord &&
                    currentRecord.allowBooking !== "single") ||
                    (!is_nbu && currentRecord.recurrence_id !== null)) && (
                    <button
                      alt="Book all reoccurring events"
                      title="Book all reoccurring events"
                      onClick={this.handleBook}
                      name="recurrence"
                      type="button"
                    >
                      Book all
                    </button>
                  )}
              </React.Fragment>
            )}

            {is_lesson &&
              !is_nbu &&
              currentRecord &&
              currentRecord.allowBooking !== "single" && (
                <button
                  alt="Book by range"
                  className="red-bg"
                  title="Book by range"
                  onClick={() => this.handleBookRange(!showRange)}
                  name="range"
                  type="button"
                >
                  {showRange ? "Cancel" : "Book range"}
                </button>
              )}
          </div>
          <div className="break-basis"></div>

          {!isFreeText && (
            <div className="input-drop">
              <ul>
                {names.map((item) => (
                  <li
                    key={item.id}
                    onClick={() =>
                      this.dropClick(
                        `${item.member.first_name} ${item.member.last_name}`,
                        add_partners ? item.id : item.member.id,
                        item.member.username ? item.member.username : null
                      )
                    }
                  >
                    {item.member.first_name} {item.member.last_name}
                  </li>
                ))}
              </ul>
            </div>
          )}

          {is_lesson && showRange && (
            <div className="filters-container">
              <div className="each-filter from-to">
                <span>From</span>
                <DatePicker
                  showTimeSelect
                  minDate={minDate}
                  maxDate={maxDate}
                  selected={startDate}
                  dateFormat="MMM D, YYYY  h:mma"
                  onChange={(date) => this.setDate(date, "startDate")}
                />
              </div>
              <div className="each-filter from-to">
                <span>To</span>
                <DatePicker
                  showTimeSelect
                  minDate={minDate}
                  maxDate={maxDate}
                  selected={endDate}
                  dateFormat="MMM D, YYYY  h:mma"
                  onChange={(date) => this.setDate(date, "endDate")}
                />
              </div>
              <div className="each-filter buton-save">
                <div className="sign-up-btn">
                  <button
                    alt="Book by range"
                    title="Book by range"
                    onClick={this.handleBook}
                    name="range"
                    type="button"
                  >
                    Book range
                  </button>
                </div>
              </div>
            </div>
          )}
          {book?.length == 0 && !this.props?.add_partners && (
            <div className={`addFreeTextButton ${!isFreeText?"addFreeTextButtonDisaled":""}`}>
              <b><small>Add free text name for booking</small></b>
               <Switcher
                    name="freeText"
                    onChange={()=>{
                      this.handleAddFreeTextBtn()
                    }}
                    value={!isFreeText}
                    checked={!isFreeText}
                  />
                <label htmlFor="freetext"><b>{!isFreeText?"Off":"On"}</b></label>
             {/*  <div className="pretty-button p-rel">
                <button
                  className={isFreeText ? "red-button" : "green-button-simple_new"}

                  onClick={(e) => {
                    e.preventDefault()
                    this.handleAddFreeTextBtn()
                  }

                  }
                >
                 
                  {isFreeText ? "Cancel free text name for booking" : "Add free text name for booking"}
                </button>

              </div> */}
            </div>
          )}

          {openedModal}
        </div>
      </React.Fragment>
    );
  }
}

export default AutoFill;
