import axios from 'axios';
import {
  GLOBAL_LOCATION_FACILITY_PAIR
} from '../utils/constants';

const axiosInstant = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
});
axiosInstant.interceptors.request.use(config => {
  const token = window.localStorage.getItem('token');
  const pairId = window.localStorage.getItem(GLOBAL_LOCATION_FACILITY_PAIR);

  if (token && token !== 'undefined') {
    config.headers['x-access-token'] = token;
  }

  if (pairId && pairId !== 'undefined') {
    const [locationId, facilityGroupId] = pairId.split(':');
    config.headers['x-location-id'] = locationId;
    config.headers['x-facility-group-id'] = facilityGroupId;
  }
  return config;
});

export default axiosInstant;
