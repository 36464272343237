import { applyMiddleware, compose, createStore } from 'redux';
import { createBrowserHistory } from 'history';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';

export const history = createBrowserHistory({
  basename: '',
  forceRefresh: false,
  keyLength: 6,
  getUserConfirmation: (message, callback) => callback(window.confirm(message)),
});

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let array_middleware = [];
array_middleware.push(thunk);

const enhancers = [applyMiddleware(...array_middleware)];

let initialState = {};

function configureStore() {
  return createStore(rootReducer, initialState, composeEnhancer(...enhancers));
}

const store = configureStore();

export default store;
