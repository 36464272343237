import {
  ADD_COACH_LOADING,
  ADD_COACH_SUCCESS,
  GET_COACH_LOADING,
  GET_COACH_SUCCESS,
  GET_COACH_SUCCESS_MOBILE,
  GET_HIDDEN_COACH_SUCCESS,
  UPDATE_COACH_LOADING,
  GET_MEMBER_TYPE_SUCCESS,
  ADD_PAYMANT_SUCCESS_STAFF,
  CHANGE_PAYMANT_SUCCESS_STAFF,
  REMOVE_PAYMANT_SUCCESS_STAFF,
  GET_CUSTOMER_STAFF_SUCCESS,
  GET_STAFF_BALANCE_SUCCESS,
  GET_STAFF_BOOKING_SUCCESS,
} from "../constants/Coach";

const initStore = {
  loading: false,
  member_types: [],
  loadingadd: false,
  data: [],
  dataMobileVersion:[]
};
const CourtsReducer = (store = initStore, action) => {
  switch (action.type) {
    case ADD_COACH_SUCCESS:
      return Object.assign({}, store, {
        data: [...store.data, action.payload],
      });
    case ADD_COACH_LOADING:
      return Object.assign({}, store, {
        loadingadd: action.payload,
      });
    case GET_COACH_SUCCESS:
      return Object.assign({}, store, {
        data: [...action.payload],
      });
      case GET_COACH_SUCCESS_MOBILE:
      return Object.assign({}, store, {
        dataMobileVersion: [...action.payload],
      });
      case GET_HIDDEN_COACH_SUCCESS:
      return Object.assign({}, store, {
        hdiddenCoach: [...action.payload],
      });
    case GET_MEMBER_TYPE_SUCCESS:
      return Object.assign({}, store, {
        member_types: [...action.payload],
      });
    case UPDATE_COACH_LOADING:
      return Object.assign({}, store, {
        data: [
          ...store.data.map((item) => {
            if (item.id === action.payload.id) {
              return action.payload;
            } else {
              return item;
            }
          }),
        ],
      });
    case GET_COACH_LOADING:
      return Object.assign({}, store, {
        loading: action.payload,
      });
    case ADD_PAYMANT_SUCCESS_STAFF:
      return {
        ...store,
        data: store.data.map((item) => {
          if (item.id === action.payload.uid) {
            return {
              ...item,
              stripePaymentMethods: [
                ...store.data
                  .find((item) => item.id === action.payload.uid)
                  .stripePaymentMethods.map((item) => ({
                    ...item,
                    default_source: false,
                  })),
                {
                  ...action.payload.payment,
                  default_source: true,
                },
              ],
              payment_account_id: action.payload.payment.customer,
            };
          } else {
            return {
              ...item,
            };
          }
        }),
      };
    case CHANGE_PAYMANT_SUCCESS_STAFF:
      return {
        ...store,
        data: store.data.map((item) => {
          if (item.id === action.payload.user_id) {
            return {
              ...item,
              stripePaymentMethods: item.stripePaymentMethods.map(
                (itemPaymant) => {
                  if (action.payload.defaultPaymentMethod === itemPaymant.id) {
                    return {
                      ...itemPaymant,
                      default_source: true,
                    };
                  }
                  return {
                    ...itemPaymant,
                    default_source: false,
                  };
                }
              ),
            };
          }
          return {
            ...item,
          };
        }),
      };
    case REMOVE_PAYMANT_SUCCESS_STAFF:
      return {
        ...store,
        data: store.data.map((item) => {
          if (item.id === action.payload.uid) {
            const index = item.stripePaymentMethods.findIndex(
              (item) => item.id === action.payload.id
            );
            item.stripePaymentMethods.splice(index, 1);
            return {
              ...item,
              stripePaymentMethods: [...item.stripePaymentMethods],
            };
          }
          return {
            ...item,
          };
        }),
      };
    case GET_CUSTOMER_STAFF_SUCCESS:
      let sourcies = action.payload.payload.sources.data;
      let subscriptions = action.payload.payload.subscriptions.data;
      let default_source = action.payload.payload.default_source;
      sourcies = sourcies.map((item) => {
        if (item.id === default_source) {
          return {
            ...item,
            default_source: true,
          };
        }
        return {
          ...item,
          default_source: false,
        };
      });
      //return store;
      return {
        ...store,
        data: store.data.map((item) => {
          if (action.payload.member_id === item.id) {
            return {
              ...item,
              stripePaymentMethods: sourcies,
              subscriptions: subscriptions,
            };
          } else {
            return item;
          }
        }),
      };
    case GET_STAFF_BALANCE_SUCCESS:
      let balance = action.payload.payload.balance;
      let all_resources = action.payload.payload.all_resources;
      let balance_payments = action.payload.balance_payments;
      return {
        ...store,
        data: store.data.map((item) => {
          if (action.payload.member_id === item.id) {
            return {
              ...item,
              balance: balance,
              all_resources: all_resources,
              balance_payments: balance_payments,
            };
          } else {
            return item;
          }
        }),
      };
    case GET_STAFF_BOOKING_SUCCESS:
      let booking_history = action.payload.bookings_history;
      return {
        ...store,
        data: store.data.map((item) => {
          if (action.payload.member_id === item.id) {
            return {
              ...item,
              booking_history: booking_history,
            };
          } else {
            return item;
          }
        }),
      };

    default:
      return store;
  }
};
export default CourtsReducer;
