import React, { Component } from "react";
import { connect } from "react-redux";
import { getCoach ,GetCoachesRequestBody} from "../../actions/Coach";
import { CoachSelect, LessonTypeSelect } from "../Business";
import {
  getCoachAvailabilityList,
  resetCoachAvailabilityList,
} from "../../actions/MicroSettings";
import { getActivePair } from "../../utils/facility";
import Notification from "../../components/Notification";

class FilterAvailabilityList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lesson_type_id: "-1",
      coach_id: props.select_coach_id,
      showForm: false,
      loading: false,
      selectedCoachName:null
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.state.showForm &&
      prevProps.selectedDate !== this.props.selectedDate
    ) {
      this.handleSubmit();
    }
  }

  componentWillUnmount() {
    this.props.resetCoachAvailabilityList();
    this.props.GetCoachesRequestBody(this.props.bid,this.props.dataForGetAvailabilityList)
    
  }

  render() {
    const { className } = this.props;
    const { lesson_type_id, coach_id, showForm, loading } = this.state;
    
  
    return (
      <div className={`filter-availability-list ${className}`}>
        {this.props.is_nbu ?(
          <Notification  classNameChild={"notificationFromBottomAvalibility"} >
          Set filters to see availability in yellow
         </Notification>
        ):(
          <Notification  classNameChild={"notificationFromBottomAvalibility"} >
          Set private service availability in staff profiles
         </Notification>
        )}
        
          <br />
        <div  className={`pretty-button ${showForm ? "d-none" : ""}`}  onClick={this.handleToggle} >
          <button className="green-button">Add Private Session</button>
        </div>

        <form
          className={!showForm ? "d-none" : ""}
          onSubmit={this.handleSubmit}
        >
          <h4>Select filters</h4>
          <div className="rec-form">
            <div className="form-group lesson-form-group">
            {/* <label>
                <span className="important-span">*</span>
                &nbsp;Coach
              </label>
              */}
              <div className="picky-container">
                <CoachSelect
                  disabled={!!this.props.select_coach_id}
                  value={{
                    id: coach_id,
                  }}
                  selectedCoach={this.state.selectedCoachName}
                  onChange={(opt) =>
                    this.handleChange({
                      target: { name: "coach_id", value: opt.id },
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div className="rec-form">
            <div className="form-group lesson-form-group">
             {/* <label>
                <span className="important-span">*</span>
                &nbsp; Lesson Type
              </label>
              */} 
              <div className="picky-container">
                <LessonTypeSelect
                  value={{
                    id: lesson_type_id,
                  }}
                  coachId={this.state.coach_id}
                  fromCourtSheet={true}
                  onChange={(opt) =>
                    this.handleChange({
                      target: { name: "lesson_type_id", value: opt.id },
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div className="d-flex justify-center"> 
          <div className="pretty-button">
            <button
              className="btn mr-2 red-button"
              type="button"
              onClick={this.handleToggle}
            >
              Cancel
            </button>
            </div>
            <div className="pretty-button">
            <button
              type="submit"
              id="refreshPrivate"
              className="btn green-button"
              style={{ padding: "5px 15px" }}
              disabled={loading}
            >
              Search
            </button>
            </div>
          </div>
        </form>
      </div>
    );
  }

  handleSubmit = async (e) => {
    try {
      e?.preventDefault();

      this.setState((st) => ({
        ...st,
        loading: true,
      }));

      const { selectedDate } = this.props;
      const { lesson_type_id, coach_id } = this.state;
     
      if (coach_id == "-1" || lesson_type_id == "-1" ) {
        if(e!=undefined){
          return window.modal.alert("Select all mandatory filters.");
        }else{
          return false
        }
        
      }

      const startDate = selectedDate.clone().startOf("day");
      const endDate = selectedDate.clone().add(1, "day").startOf("day");

      const [, facilityGroup] = getActivePair();
      
      await this.props.getCoachAvailabilityList({
        startDate,
        endDate,
        coach_id: coach_id != "-1" ? coach_id : undefined,
        lesson_type_id: lesson_type_id != "-1" ? lesson_type_id : undefined,
        facility_group_id: facilityGroup?.id,
      });
    } finally {
      this.setState((st) => ({
        ...st,
        loading: false,
      }));
    }
  };

  handleToggle = () => {
    this.setState((st) => {
      const { showForm } = st;
      let newSt = { ...st };

      this.props.resetCoachAvailabilityList();
      if (!showForm) {
        this.props.GetCoachesRequestBody(this.props.bid,this.props.dataForGetAvailabilityList)
       
        newSt = {
          ...st,
          lesson_type_id: "-1",
          coach_id: this.props.select_coach_id || "-1",
        };
        
      }else{
        this.props.getCoach();
      }

      return {
        ...newSt,
        showForm: !showForm,
      };
    });
  };

  handleChange = (e) => {
    let selectedCoachInfo= this.props.data.filter((item)=>item.id==this.state.coach_id ||item.coach_id==this.state.coach_id)
    let selectedCoachName= selectedCoachInfo.length>0?selectedCoachInfo[0].coach_name||selectedCoachInfo[0].displayName:null

    const {
      target: { name, value },
    } = e;
    this.setState((st) => ({
      ...st,
      [name]: value,
      selectedCoachName:selectedCoachName
    }));
  };
}

const mapStateToProps = (state) => {
  return {
    data: state.coach.dataMobileVersion,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetCoachesRequestBody: (bid,data) => dispatch(GetCoachesRequestBody(bid,data)),
    getCoachAvailabilityList: (data) =>
      dispatch(getCoachAvailabilityList(data)),
    resetCoachAvailabilityList: () => dispatch(resetCoachAvailabilityList()),
    getCoach: () => dispatch(getCoach()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterAvailabilityList);
