import React, { Component } from 'react';
import { connect } from 'react-redux';
import FormGroup from '../FormGroup';
import { Link } from 'react-router-dom';
import { setWaiver } from '../../actions/Auth';

class ModalWaiver extends Component {
  constructor(props) {
    super(props);

    this.state = {
      event: ''
    };
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.checked,
    });
  }


  render() {
    const { event } = this.state;

    const { closeDell, resolve, customClassname, business } = this.props;

    const filename = business.sign_file;
    let file_url = filename
      ? process.env.REACT_APP_API_BASE_URL + 'public/' + business.user_id + '/' + filename
      : '#';

    return (
      <div className={customClassname ? `modal active ${customClassname}` : 'modal active'}>
        <div className="modal-content">
          <div className="check-group dell-group sign-group" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <label style={{ display: "inline-block", width: "290px", marginRight: "15px" }}>{business.name} requires you to agree to the following  {filename ? (
              <a href={file_url} target="_blank">
                Terms and Policies
              </a>
            ) : (
                '')}   </label>
            <FormGroup
              type="checkbox"
              name="event"
              id="this"
              checked={event}
              value={event}
              onChange={this.handleChange}
              label="">
              <label htmlFor="this" className="label-icon" />
            </FormGroup>
          </div>

          <div className="modal-btn">
            <button
              onClick={async () => {
                let bool = false;
                if (event) {
                  bool = await this.props.setWaiver(business.id);
                }

                resolve({ event: bool });
                closeDell();
              }}>
              Confirm
            </button>
            <button
              onClick={() => {
                resolve({ event: null });
                closeDell();
              }}>
              Cancel
            </button>

          </div>
        </div>
      </div >
    );
  }
}

ModalWaiver.propTypes = {};

const mapStateToProps = state => {
  return {

  };
};

const mapDispatchToProps = dispatch => {
  return {
    setWaiver: (business_id) => dispatch(setWaiver(business_id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalWaiver);


