import React, { PureComponent } from 'react';
import styled from 'styled-components';
import "./loading.scss";

const DivWrapp = styled.div`
  position: absolute;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #4c4c4c78;
`;

const Loader = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border: 16px solid #f3f3f3;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border-top: 16px solid black;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
`;

class Loading extends PureComponent {
  render() {
    return (
      <div className="overPage">
       <div className="loaderMain">
        <svg className="circular-loader" viewBox="25 25 50 50">
            <circle className="loader-path" cx="50" cy="50" r="20"></circle>
        </svg>
       </div>
      </div>
    );
  }
}

export default Loading;
