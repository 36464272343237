import { GLOBAL_LOCATION_FACILITY_PAIR } from "./constants";
import store from "../config/store";

export const getActiveFacilityGroup = () => {
  const pairId =
    window.localStorage.getItem(GLOBAL_LOCATION_FACILITY_PAIR) || "-1:-1";
  const [_, facilityGroupId] = pairId.split(":");

  const facilityGroup = store
    .getState()
    .facility.group.list.data.find((itm) => itm.id == facilityGroupId);

  return facilityGroup;
};

export const getActivePair = () => {
  const pairId =
    window.localStorage.getItem(GLOBAL_LOCATION_FACILITY_PAIR) || "-1:-1";
  const [locationId, facilityGroupId] = pairId.split(":");

  const state = store.getState();
  const location = state.location.entity.list.data.find(
    (itm) => itm.id == locationId
  );
  const facilityGroup = state.facility.group.list.data.find(
    (itm) => itm.id == facilityGroupId
  );

  return [location, facilityGroup];
};
