import React, { Component } from 'react';
import './mobile-screens.scss';
class MobileScreens extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {

    const screens = [
      '/assets/img/lesson-mobile.png',
      //  '/assets/img/analytics-mobile.png',
      // '/assets/img/matchup-mobile.png',
      '/assets/img/ontour-mobile.png'
    ];

    const _screens = screens.map((v, i) =>
      <div key={i} className="screen">
        <img src={v} alt={v} />
      </div>
    );

    const stars = [1, 2, 3, 4, 5];
    const _stars = stars.map((v, i) =>
      <img key={i} src='/assets/img/star.png' alt={v} />
    );

    return (

      <div className="mobile-screens-section">
        <div className="main-container">
          <div className="title">
            {/* <h2> For Athletes In Any Sport</h2> */}
          </div>
          <div className="mobile-screens">
            {_screens}
          </div>
          <div className="appstore">
            <img src='/assets/img/download-on-app-store-png-click-the-qr-code-to-show-the-download-link-800.png' alt='download-on-app-store-png-click-the-qr-code-to-show-the-download-link-800' />
          </div>
          <div className="appstore no-padding-top">
            <img src='/assets/img/google-play-badge.png' alt='google-play-badge.png' />
          </div>
          <div className="stars">
            {_stars}
          </div>
        </div>
      </div>

    );
  }
}

export default MobileScreens;
