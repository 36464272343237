import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchConnectionToken } from "../../actions/util";
import TerminalModal from "../TerminalModal";
import { discoverReaders } from "../../actions/Terminal";

class TerminalIcon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      terminal: null,
      discoverResult: [{ id: -1 }],
      modal: null,
      selectedIndex: -1,
      isOnlineTerminal: false,
    };
    //this.connectReader = this.connectReader.bind(this);
  }

  async componentDidMount() {
    if (window.StripeTerminal) {
      const terminal = await window.StripeTerminal.create({
        onFetchConnectionToken: fetchConnectionToken,
        onUnexpectedReaderDisconnect: this.unexpectedDisconnect,
      });
      await this.props.discoverReaders(terminal);
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({ terminal });
    } else {
      document
        .querySelector("#stripeTerminal-js")
        .addEventListener("load", async () => {
          const terminal = await window.StripeTerminal.create({
            onFetchConnectionToken: fetchConnectionToken,
            onUnexpectedReaderDisconnect: this.unexpectedDisconnect,
          });
          await this.props.discoverReaders(terminal);
          this.setState({ terminal });
        });
    }
  }
  unexpectedDisconnect() {
    console.log("Disconnected from reader");
  }

  clouseModal() {
    return () => {
      this.setState({ modal: null });
      this.state.terminal.disconnectReader();
    };
  }

  connectReaderByIndex(index) {
    return async () => {
      // Just select the first reader here.
      this.setState({ selectedIndex: index });
      const selectedReader = this.state.discoverResult[index];
      const connectResult = await this.state.terminal.connectReader(
        selectedReader
      );
      if (connectResult.error) {
        console.log("Failed to connect:", connectResult.error);
      } else {
        this.setState({
          modal: (
            <TerminalModal
              isFromUserProfile={this.props.isFromUserProfile}
              show_members={this.props.show_members}
              bookings={this.props.bookings}
              cbCharge={this.props.cbCharge}
              apt_id={this.props.apt_id}
              is_lesson={this.props.is_lesson}
              user_id={this.props.user_id}
              terminal={this.state.terminal}
              reader={selectedReader}
              connection={connectResult}
              from={this.props.from}
              user={this.props.user}
              clouseModal={this.clouseModal(index)}
            />
          ),
          selectedIndex: -1,
        });
      }
    };
  }

  componentDidUpdate() { }

  componentWillReceiveProps(nextProps) {
    /// Check Terminal
    const _terminalList = [...nextProps.discoverResult];
    const _terminal = _terminalList.filter((item) => {
      return item.status === "online";
    });

    if (_terminal.length > 0) {
      this.setState({
        isOnlineTerminal: true,
        discoverResult: _terminal,
      });
    } else {
      this.setState({
        isOnlineTerminal: false,
        discoverResult: [{ id: -1 }],
      }); //
    }
  }

  render() {
    const { isOnlineTerminal } = this.state;
    
    return (
      <div>
        {this.state.discoverResult.map((item, index) => (
          <div
            key={item.id}
            className={`chargeIcon ${index === this.state.selectedIndex
              ? "loading"
              : !isOnlineTerminal
                ? "disableAction"
                : ""
              }`}
          >
            <img
              key={item.id}
              title="Terminal"
              onClick={this.connectReaderByIndex(index)}
              className={"chargeButton"}
              src={isOnlineTerminal?"/assets/img/payment-terminal-new.svg":"/assets/img/payment-terminal.svg"}
              alt="Terminal"
            />
          </div>
        ))}
        {this.state.modal}
      </div>
    );
  }
}

TerminalIcon.propTypes = {};

const mapStateToProps = (state) => {
  return {
    discoverResult: state.terminal.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    discoverReaders: (terminal) => dispatch(discoverReaders(terminal)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TerminalIcon);
