import React from 'react';
import styled from 'styled-components';

const Label = styled.label`
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;
  display: inline-block;
  border-radius: 12px;
  border: none;
  margin: 5px;
`;

const Switch = styled.input.attrs({ type: 'checkbox' })`
  opacity: 0;
  width: 0;
  height: 0;
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 12px;
  box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.8);
  ${Switch}:checked + & {
    background-color: #ffffff;
  }
  &::before{
    position: absolute;
    content: "";
    width: 20px;
    height: 20px;
    left: 22px;
    top: 2px;
    background-image: none;
    background-color: #9ad308 ;
    opacity: 1;
    transition: all .2s linear;
    border-radius: 50%;
    ${Switch}:checked + & {
      left: 2px;
    }
  }
`;

export default function Switcher(props) {
  return (
    <Label>
      <Switch {...props} />
      <Slider />
    </Label>
  )
}
