import {
  ADD_LESSON_LOADING,
  DELATE_LESSON_SUCCESS,
  GET_COURT_LISTING_AVAILABILITY_LOADING,
  GET_COURT_LISTING_AVAILABILITY_SUCCESS,
  GET_LESSON_SUCCESS,
  GET_SINGLE_LESSON_SUCCESS,
  MARK_AS_BOOK_SUCCESS,
  RESET_SINGLE_LESSON,
  UPDATE_LESSON_SUCCESS,
  GET_LESSON_TYPES_SUCCESS,
  GET_HIDDEN_LESSON_TYPES_SUCCESS,
  GET_LESSON_TYPE_SUCCESS,
  ADD_LESSON_TYPES_SUCCESS,
  EDIT_LESSON_TYPES_SUCCESS,
  GET_LESSON_TYPE_LOADING,
  WAITLIST_LOADING,
  WAITLIST_SUCCESS,
  CHECKINS_LOADING_L,
  CHECKINS_SUCCESS_L,
  CHECKINS_SUCCESS_By_C,
  CHECKINS_ADD_SUCCESS_L,
  CHECKOUT_ADD_SUCCESS_L,
  CLOCKIN_SUCCESS_L,
  ATTENDANCE_SUCCESS_L,
  ATTENDANCE_LOADING_L,
  BALANCE_SUCCESS_L,
  BALANCE_LOADING_L
} from '../constants/Lesson';

const initStore = {
  loading: false,
  loadingWaitlist: false,
  loadingGetLessonType: false,
  loadingGetCourts: false,
  loadingCheckins: false,
  loadingAttendance: false,
  loadingBalance: false,
  data: [],
  lessonTypes: [],
  hiddenLessonTypes: [],
  checkins: [],
  checkinsByClub: null,
  clockins: [],
  lessonType: {},
  availableCourts: [],
  currentLesson: {}
};
const LessonReducer = (store = initStore, action) => { 
  switch (action.type) {
    case ADD_LESSON_LOADING:
      return Object.assign({}, store, {
        loading: action.payload,
      });
    case WAITLIST_LOADING:
      return Object.assign({}, store, {
        loadingWaitlist: action.payload,
      });
    case GET_LESSON_TYPE_LOADING:
      return Object.assign({}, store, {
        loadingGetLessonType: action.payload,
      });
    case GET_COURT_LISTING_AVAILABILITY_LOADING:
      return Object.assign({}, store, {
        loading: action.payload,
      });
    case GET_COURT_LISTING_AVAILABILITY_SUCCESS:
      return Object.assign({}, store, {
        availableCourts: action.payload
      });
      break;
    case UPDATE_LESSON_SUCCESS:
      return {
        ...store,
        data: store.data.map(item => {
            if (action.payload.appointment_id === item.appointment_id) {
              return action.payload;
            } else {
              return item;
            }
          }),
          currentLesson: action.payload,
      };
    case GET_LESSON_SUCCESS:
      return Object.assign({}, store, {
        data: action.payload,
      });
    case GET_LESSON_TYPES_SUCCESS:
      return Object.assign({}, store, {
        lessonTypes: action.payload,
      }); 
      case GET_HIDDEN_LESSON_TYPES_SUCCESS:
      return Object.assign({}, store, {
        hiddenLessonTypes: action.payload,
      });
    case GET_LESSON_TYPE_SUCCESS:
      return Object.assign({}, store, {
        lessonType: action.payload,
      });
    case ADD_LESSON_TYPES_SUCCESS:
      return Object.assign({}, store, {
        lessonTypes: [...store.lessonTypes, action.payload],
      });
    case EDIT_LESSON_TYPES_SUCCESS:
      return {
        ...store,
        lessonTypes: store.lessonTypes.map(item => {
          if (action.payload.id === item.id) {
            return action.payload;
          } else {
            return item;
          }
        })
      };
    case CHECKINS_LOADING_L:
      return Object.assign({}, store, {
        loadingCheckins: action.payload,
      });
    case CHECKINS_SUCCESS_L:
      return Object.assign({}, store, {
        checkins: [
          ...action.payload
        ],
      });
    case CHECKINS_SUCCESS_By_C:
        return Object.assign({}, store, {
          checkinsByClub: action.payload,
        });

    case ATTENDANCE_LOADING_L:
      return Object.assign({}, store, {
        loadingAttendance: action.payload,
      });
    case ATTENDANCE_SUCCESS_L:
      return Object.assign({}, store, {
        attendance: [
          ...action.payload.apts
        ],
        all_members_attendance: [
          ...action.payload.all_members_attendance
        ],
      });

    case BALANCE_LOADING_L:
      return Object.assign({}, store, {
        loadingBalance: action.payload,
      });
    case BALANCE_SUCCESS_L:
      return Object.assign({}, store, {
        balance: [
          ...action.payload.apts
        ],
        all_members_balance: [
          ...action.payload.all_members_balance
        ],
      });

    case CLOCKIN_SUCCESS_L:
      return Object.assign({}, store, {
        clockins: [...action.payload],
      });
    case CHECKINS_ADD_SUCCESS_L:
      return Object.assign({}, store, {
        currentLesson: {
          ...store.currentLesson,
          checkins: action.payload
        },
      });
    case CHECKOUT_ADD_SUCCESS_L:
      return Object.assign({}, store, {
        currentLesson: {
          ...store.currentLesson,
          checkouts: action.payload
        },
      });
    case GET_SINGLE_LESSON_SUCCESS:
      return Object.assign({}, store, {
        currentLesson: action.payload,
      });
    case WAITLIST_SUCCESS:
      return Object.assign({}, store, {
        currentLesson: {
          ...store.currentLesson,
          waitList: action.payload
        },
      });
    case RESET_SINGLE_LESSON:
      return Object.assign({}, store, {
        currentLesson: action.payload,
      });
    case MARK_AS_BOOK_SUCCESS:
      return {
        ...store,
        data: store.data.map(item => {
          if (action.payload.llid === item.llid) {
            return action.payload;
          } else {
            return item;
          }
        })
      };
      // eslint-disable-next-line no-case-declarations
    case DELATE_LESSON_SUCCESS:
      const index = store.data.findIndex(item => item.appointment_id === action.payload);
      store.data.splice(index, 1);

      return {
        ...store,
        data: [...store.data]
      };
    default:
      return store;
  }
};
export default LessonReducer;
