import {
  GET_MEMBER_ONLY_SUCCESS,
  GET_MEMBERS_ERROR,
  GET_MEMBERS_LOADING,
  GET_CUSTOMER_LOADING,
  GET_MEMBERS_SUCCESS,
  GET_CUSTOMER_SUCCESS,
  GET_MEMBER_FAMILY_SUCCESS,
  GET_MEMBER_POINTS_PAYMENTS_SUCCESS,
  ADD_MEMBER_POINTS_PAYMENTS_SUCCESS,
  GET_MEMBER_BALANCE_SUCCESS,
  GET_MEMBER_BOOKING_SUCCESS,
  GET_MEMBER_INVOICE_ITEM_SUCCESS,
  GET_MEMBER_INVOICE_HISTORY_SUCCESS,
  BUY_MEMBER_POINTS_PAYMENTS_SUCCESS,
  GET_MEMBER_GROUPS_SUCCESS,
  GET_MEMBERS_GROUPS_LOADING,
  GET_MEMBER_RESTRICTIONS_SUCCESS,
  GET_STAFF_BOOKING_OVERIDES_SUCCESS,
  GET_MEMBER_LEVEL_SUCCESS,
} from "../constants/Members";
import {
  ADD_PAYMANT_SUCCESS,
  CHANGE_PAYMANT_SUCCESS,
  REMOVE_PAYMANT_SUCCESS,
  VERIFI_ACCOUNT_LOADING,
  VERIFI_ACCOUNT_SUCCESS,
} from "../constants/Stripe";

const initStore = {
  loading: false,
  loadingCustomer: false,
  loadingGroups: false,
  verificationLoading: false,
  data: [],
  only: {},
  isEnd: false,
  error: null,
};
const CourtsReducer = (store = initStore, action) => {
  switch (action.type) {
    case GET_MEMBER_ONLY_SUCCESS:
      return Object.assign({}, store, {
        only: action.payload,
      });
    case GET_MEMBERS_LOADING:
      return Object.assign({}, store, {
        loading: action.payload,
      });
    case GET_MEMBERS_GROUPS_LOADING:
      return Object.assign({}, store, {
        loadingGroups: action.payload,
      });
    case GET_CUSTOMER_LOADING:
      return Object.assign({}, store, {
        loadingCustomer: action.payload,
      });
    case GET_CUSTOMER_SUCCESS:
      let sourcies = action.payload.payload.sources.data;
      let subscriptions = action.payload.payload.subscriptions.data;
      let default_source = action.payload.payload.default_source;
      sourcies = sourcies.map((item) => {
        if (item.id === default_source) {
          return {
            ...item,
            default_source: true,
          };
        }
        return {
          ...item,
          default_source: false,
        };
      });
      //return store;
      return {
        ...store,
        data: store.data.map((item) => {
          if (action.payload.member_id === item.id) {
            return {
              ...item,
              stripePaymentMethods: sourcies,
              subscriptions: subscriptions,
            };
          } else {
            return item;
          }
        }),
      };

    case GET_MEMBER_FAMILY_SUCCESS:
      let family = action.payload.family;
      let member = action.payload.member;
      return {
        ...store,
        data: store.data.map((item) => {
          if (action.payload.member_id === item.id) {
            if (member) {
              return {
                ...member,
                family: family,
              };
            }
            return {
              ...item,
              family: family,
            };
          } else {
            return item;
          }
        }),
      };
    case GET_MEMBER_BALANCE_SUCCESS:
      let balance = action.payload.balance;
      let all_resources = action.payload.all_resources;
      let balance_payments = action.payload.balance_payments;
      return {
        ...store,
        data: store.data.map((item) => {
          if (action.payload.member_id === item.id) {
            return {
              ...item,
              balance: balance,
              all_resources: all_resources,
              balance_payments: balance_payments,
            };
          } else {
            return item;
          }
        }),
      };
    case GET_MEMBER_RESTRICTIONS_SUCCESS:
      let statistics = action.payload;
      return {
        ...store,
        data: store.data.map((item) => {
          if (action.payload.member_id === item.id) {
            return {
              ...item,
              statistics: statistics,
            };
          } else {
            return item;
          }
        }),
      };
    case GET_STAFF_BOOKING_OVERIDES_SUCCESS:
      let overides = action.payload.entities;
      return {
        ...store,
        data: store.data.map((item) => {
          if (action.payload.member_id === item.id) {
            return {
              ...item,
              overides: [...overides],
            };
          } else {
            return item;
          }
        }),
      };
    case GET_MEMBER_POINTS_PAYMENTS_SUCCESS:
      let points_payments = action.payload.points_payments;
      let points_gifts = action.payload.points_gifts;
      return {
        ...store,
        data: store.data.map((item) => {
          if (action.payload.member_id === item.id) {
            return {
              ...item,
              points_payments: points_payments
                ? points_payments
                : item.points_payments,
              points_gifts: points_gifts ? points_gifts : item.points_gifts,
            };
          } else {
            return item;
          }
        }),
      };
    case ADD_MEMBER_POINTS_PAYMENTS_SUCCESS:
      let points_payment = action.payload.points_payments;
      return {
        ...store,
        data: store.data.map((item) => {
          if (action.payload.member_id === item.id) {
            return {
              ...item,
              points_payments: [points_payment, ...item.points_payments],
            };
          } else {
            return item;
          }
        }),
      };
    case BUY_MEMBER_POINTS_PAYMENTS_SUCCESS:
      let user_payment = action.payload.user_payment;

      return {
        ...store,
        data: store.data.map((item) => {
          if (action.payload.member_id === item.id) {
            return {
              ...item,
              member: {
                ...item.member,
                points: [user_payment, ...item.member.points],
              },
            };
          } else {
            return item;
          }
        }),
      };
    case GET_MEMBER_BOOKING_SUCCESS:
      let booking_history = action.payload.bookings_history;
      return {
        ...store,
        data: store.data.map((item) => {
          if (action.payload.member_id === item.id) {
            return {
              ...item,
              booking_history: booking_history,
            };
          } else {
            return item;
          }
        }),
      };
    case GET_MEMBER_LEVEL_SUCCESS:
      let levels = action.payload.levels;
      return {
        ...store,
        data: store.data.map((item) => {
          if (action.payload.member_id === item.id) {
            return {
              ...item,
              levels: levels,
            };
          } else {
            return item;
          }
        }),
      };
    case GET_MEMBER_INVOICE_ITEM_SUCCESS:
      let invoice_items = action.payload.invoice_items;
      return {
        ...store,
        data: store.data.map((item) => {
          if (action.payload.member_id === item.id) {
            return {
              ...item,
              invoice_items: invoice_items,
            };
          } else {
            return item;
          }
        }),
      };

    case GET_MEMBER_INVOICE_HISTORY_SUCCESS:
      let invoice_history = action.payload.invoice_history;
      return {
        ...store,
        data: store.data.map((item) => {
          if (action.payload.member_id === item.id) {
            return {
              ...item,
              invoice_history: invoice_history,
            };
          } else {
            return item;
          }
        }),
      };

    case GET_MEMBER_GROUPS_SUCCESS:
      return {
        ...store,
        data: store.data.map((item) => {
          if (action.payload.member_id === item.id) {
            return {
              ...action.payload.payload,
            };
          } else {
            return item;
          }
        }),
      };

    case GET_MEMBERS_SUCCESS:
      return Object.assign(
        {},
        store,
        action.payload.notfirst
          ? {
              data: [...store.data, ...action.payload.payload],
              isEnd: action.payload.payload.length < 20,
            }
          : {
              data: action.payload.payload,
              isEnd: action.payload.payload.length < 20,
            }
      );
    case GET_MEMBERS_ERROR:
      return Object.assign({}, store, {
        error: action.payload,
      });
    // stripe -----------------------------------------------
    case ADD_PAYMANT_SUCCESS:
      return {
        ...store,
        data: store.data.map((item) => {
          if (item.id === action.payload.uid) {
            return {
              ...item,
              stripePaymentMethods: [
                ...store.data
                  .find((item) => item.id === action.payload.uid)
                  .stripePaymentMethods.map((item) => ({
                    ...item,
                    default_source: false,
                  })),
                {
                  ...action.payload.payment,
                  default_source: true,
                },
              ],
              payment_account_id: action.payload.payment.customer,
            };
          } else {
            return {
              ...item,
            };
          }
        }),
      };
    case CHANGE_PAYMANT_SUCCESS:
      return {
        ...store,
        data: store.data.map((item) => {
          if (item.id === action.payload.uid) {
            return {
              ...item,
              stripePaymentMethods: item.stripePaymentMethods.map(
                (itemPaymant) => {
                  if (action.payload.defaultPaymentMethod === itemPaymant.id) {
                    return {
                      ...itemPaymant,
                      default_source: true,
                    };
                  }
                  return {
                    ...itemPaymant,
                    default_source: false,
                  };
                }
              ),
            };
          }
          return {
            ...item,
          };
        }),
      };
    case REMOVE_PAYMANT_SUCCESS:
      return {
        ...store,
        data: store.data.map((item) => {
          if (item.id === action.payload.uid) {
            const index = item.stripePaymentMethods.findIndex(
              (item) => item.id === action.payload.id
            );
            item.stripePaymentMethods.splice(index, 1);
            return {
              ...item,
              stripePaymentMethods: [...item.stripePaymentMethods],
            };
          }
          return {
            ...item,
          };
        }),
      };
    case VERIFI_ACCOUNT_LOADING:
      return {
        ...store,
        verificationLoading: action.payload,
      };
    case VERIFI_ACCOUNT_SUCCESS:
      return {
        ...store,
        data: store.data.map((item) => {
          if (item.id === action.payload.uid) {
            return {
              ...item,
              stripePaymentMethods: [
                ...item.stripePaymentMethods.map((itemPaymant) => {
                  if (itemPaymant.id === action.payload.id) {
                    return {
                      ...itemPaymant,
                      status: "verified",
                    };
                  } else {
                    return {
                      ...itemPaymant,
                    };
                  }
                }),
              ],
            };
          } else {
            return {
              ...item,
            };
          }
        }),
      };
    default:
      return store;
  }
};
export default CourtsReducer;
