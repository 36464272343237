import React, { Component } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import isEqual from "lodash/isEqual";
import "./dropdown.scss";

class DropdownFilters extends Component {
  constructor(props) {
    super(props);
    const value = props.value;
    const filters = this.getInitialStateFilters(value);
    this.state = {
      open: false,
      title: this.createTitle([...filters]),
      filters: [...filters],
      selected: this.props.initial || -1,
    };
  }
  getInitialStateFilters = (value = null) => {
    let initial = [
      {
        label: "Minimum Age",
        key: "min_age",
        input: "number",
        value: "",
      },
      {
        label: "Maximum Age",
        key: "max_age",
        input: "number",
        value: "",
      },
      {
        label: "Minimum Athletic level",
        key: "min_level",
        input: "number",
        value: "",
      },
      {
        label: "Maximum Athletic level",
        key: "max_level",
        input: "number",
        value: "",
      },
      {
        label: "Gender",
        key: "gender",
        input: "select",
        value: "",
      },
    ];

    if (value && value.length > 0) {
      let filters = value["0"]["filters"] || {};

      if (Object.keys(filters).length > 0) {
        initial = initial.map((v) => {
          v.value = filters[v.key] || v.value;
          return v;
        });
      }
    }
    return initial;
  };

  toggleDropdownFilters() {
    this.setState({
      active: !this.state.active,
    });
  }
  hideDropdown = () => {
    this.setState({
      active: false,
    });
  };
  componentWillReceiveProps(nextProps, nextContext) {
    if (
      nextProps.who_can_book_filters &&
      Object.keys(nextProps.who_can_book_filters).length > 0
    ) {
      let value = nextProps.who_can_book_filters;
      const filters = this.getInitialStateFilters([
        {
          filters: value,
        },
      ]);
      this.setState({
        filters: [...filters],
        title: this.createTitle([...filters]),
      });
    }
  }

  handleClick(i) {
    this.setState({
      selected: i,
    });
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    const { filters } = this.state;
    const new_filters = [
      ...filters.map((v) => {
        if (v.key === name) {
          return {
            ...v,
            value: value,
          };
        }
        return v;
      }),
    ];

    this.setState({
      filters: [...new_filters],
      title: this.createTitle([...new_filters]),
    });
  };
  createTitle = (data) => {
    const filtered = data.filter((v) => !!v.value);
    if (filtered.length === 0) {
      return this.getDefaultTitle();
    }
    return [].concat
      .apply(
        [],
        filtered.map((v) => `${v.label}#${v.value}`)
      )
      .join(", ");
  };
  getDefaultTitle = () => {
    return "Select...";
  };

  whoCanBookFiltersForSave = (data) => {
    let prepared = [];

    if (data.length > 0) {
      prepared = data
        .filter((v) => !!v.value)
        .map((v) => {
          return {
            [v.key]: v.value,
          };
        });
    }

    return Object.assign({}, ...prepared);
  };

  componentDidUpdate(prevProps, prevState) {
    const { filters } = this.state;
    const { filters: filters_old } = prevState;
    if (!isEqual(filters, filters_old)) {
      const updated = this.whoCanBookFiltersForSave([...filters]);

      this.props.onChangeWhoCanBook({ ...updated });
    }
  }

  renderOptions() {
    const { filters } = this.state;
    return filters.map((option, i) => {
      let input = option.input;
      return (
        <li key={i} className={"dropdown__list-item"}>
          {input === "number" ? (
            <div className="form-group">
              <label htmlFor="">{option.label}</label>
              <input
                type="number"
                name={option.key}
                onWheel={(e) => e.target.blur()}
                className="form-control"
                onChange={this.handleChange}
                value={option.value}
              />
            </div>
          ) : (
            <div className="form-group">
              <label htmlFor="">{option.label}</label>
              <select
                className="form-control"
                name={option.key}
                onChange={this.handleChange}
                value={option.value}
              >
                <option value="">NA</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>
          )}
        </li>
      );
    });
  }

  render() {
    const { title } = this.state;
    return (
      <OutsideClickHandler
        onOutsideClick={() => {
          this.hideDropdown();
        }}
      >
        <div className="dropdown-filters">
          <div
            onClick={() => this.toggleDropdownFilters()}
            className="dropdown__toggle dropdown__list-item"
          >
            {title}
            <i className="fa fa-angle-down" aria-hidden="true"></i>
          </div>
          <ul
            className={
              "dropdown__list" +
              (this.state.active ? "dropdown__list--active" : "")
            }
          >
            {this.renderOptions()}
          </ul>
        </div>
      </OutsideClickHandler>
    );
  }
}

DropdownFilters.propTypes = {};

export default DropdownFilters;
