import React, { Component } from "react";
import PropTypes from "prop-types";
import "./right-column.scss";

import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import ModalDashboard from "../ModalDashboard";
import MicroSettingsModal from "../MicroSettingsModal";
import FamilyDropdown from "../../components/FamilyDropdown";
import { TagsModal } from "../Tags";
import { hasPermission } from "../../utils/helpers";
import { withRouter } from "react-router";
import { FilterAvailabilityList } from "../../containers/Availability";
import FreezeModal from "../FreezeModal";
import {

  resetCoachAvailabilityList,
} from "../../actions/MicroSettings";
import { GetCoachesRequestBody, GetExpiredRules } from "../../actions/Coach";
import { connect } from "react-redux";

class RightColume extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startOfDay: moment(props.currentDay),
      days: [],
      book: "",
      asbook: true,
      openedModal: null,
      expiredRules: []
    };

    this.filterAvailabilityRef = React.createRef();

    this.handleInputChange = this.handleInputChange.bind(this);

    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
  }

  handleChangeDate = async (selected) => {

    if (await this.props.isEmptyCourtListing()) {

      this.setState({ startOfDay: selected, error: "" });
      this.props.changeDate(selected);
      this.props.resetCoachAvailabilityList();
      if (this.props.family) {
        this.props.GetCoachesRequestBody(this.props.identity.business.id, {
          startDate: moment(this.state.startOfDay).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).utc(),
          endDate: moment(this.state.startOfDay).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).add(1, 'days').utc(),
          facility_group_id: (this.props.facility_grop_id ? this.props.facility_grop_id.id : ""),
          userId: this.props.family,
          // sport_id:(this.props.facility_grop_id?this.props.facility_grop_id.sport_category_id:""),
        })
      } else {
        this.props.GetCoachesRequestBody(this.props.identity.business.id, {
          startDate: moment(this.state.startOfDay).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).utc(),
          endDate: moment(this.state.startOfDay).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).add(1, 'days').utc(),
          facility_group_id: (this.props.facility_grop_id ? this.props.facility_grop_id.id : ""),
          //sport_id:(this.props.facility_grop_id?this.props.facility_grop_id.sport_category_id:""),
        })
      }

    }
  };
  setCookie = (cname, cvalue, exdays) => {
   
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));

    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
  getCookie = (cname) => {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return false;
  }

  componentWillReceiveProps() {
    
    const cookiealue = this.getCookie("expiredRulesId__New" + this.props?.identity?.uid)
    if (cookiealue && cookiealue!="null") {
     
      this.setState({
        expiredRules: JSON.parse(cookiealue)
      })
    } else {
      const isNbu = this.props.userType === "member";
      if(!isNbu){
        this.props.GetExpiredRules().then((res) => {

          this.setState({
            expiredRules: res
          })
          
          if(res!=undefined){
            
            this.setCookie("expiredRulesId__New" + this.props?.identity?.uid, JSON.stringify(res), 7)
          }
        })
      }
      

    }
 

  if(this.props?.coachList?.length==0){

    if (this.props.family) {
   
      this.props.GetCoachesRequestBody(this.props.identity.business.id, {
        startDate: moment(this.state.startOfDay).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).utc(),
        endDate: moment(this.state.startOfDay).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).add(1, 'days').utc(),
        facility_group_id: (this.props.facility_grop_id ? this.props.facility_grop_id.id : ""),
        userId: this.props.family,
        //sport_id:(this.props.facility_grop_id?this.props.facility_grop_id.sport_category_id:""),
      })
    } else {
      if (this.props?.facility_grop_id?.id) {
       
        this.props.GetCoachesRequestBody(this.props.identity.business.id, {
          startDate: moment(this.state.startOfDay).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).utc(),
          endDate: moment(this.state.startOfDay).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).add(1, 'days').utc(),
          facility_group_id: (this.props.facility_grop_id ? this.props.facility_grop_id.id : ""),
          // sport_id:(this.props.facility_grop_id?this.props.facility_grop_id.sport_category_id:""),
        })
      }

    }
  }
    
  }
  submitData = (data) => {
    this.props.addEvent(data);
  };

  closeModal = () => {
    this.setState({
      openedModal: null,
    });
  };

  showModal = (type) => {
    const { currentDay, timeZone, coachList, getListings } = this.props;
    const { expiredRules } = this.state

    let modal;
    switch (type) {
      case "tags": {
        modal = <TagsModal timeZone={timeZone} close={this.closeModal} />;
        break;
      }
      case "tr": {
        modal = (
          <ModalDashboard
            submit={this.submitData}
            data={{}}
            ruleButton={true}
            coachList={coachList}
            modalClass={true}
            closeModal={this.closeModal}
            currentDay={currentDay}
            timeZone={timeZone}
            getListings={getListings}
          />
        );
        break;
      }
      case "fr": {
        modal = (
          <FreezeModal
            extraClassName={true}
            close={this.closeModal}
            modalClass={true}
          />
        );
        break;
      }
      default: {
        modal = (
          <MicroSettingsModal
            showForm={type === "ms-add"}
            currentDay={currentDay}
            expiredRules={expiredRules}
            timeZone={timeZone}
            close={this.closeModal}
          />
        );
        break;
      }
    }

    this.setState({
      openedModal: modal,
    });

  };

  handleCheckboxChange(event) {
    const target = event.target;
    this.setState({ [target.name]: target.checked });
  }

  handleInputChange(event) {
    const target = event.target;
    if (this.state.days.findIndex((item) => target.value === item) > -1) {
      this.state.days.remove(target.value);
      this.setState({ days: [...this.state.days] });
    } else {
      this.setState({ days: [target.value, ...this.state.days] });
    }
  }

  handleChange = (event) => {
    const target = event.target;
    this.setState({ [target.name]: target.value });
  };

  render() {
    const minuse6m = moment(this.props.currentDay).subtract(13, "month");
    const { openedModal, expiredRules } = this.state;
    const roles = this.props.identity.member_type.roles;

    const is_coach = ["coach", "supercoach"].includes(this.props.identity.member_type.key);
    const is_owner = this.props.userType === "owner"; //|| this.props.userType === 'member';
    const hasPermissionOnCoachSelect = this.props.userType === "owner" || this.props.userType === "admin" || this.props.userType === "reception"

    const is_nbu = this.props.userType === "member";
    let is_supercoach = false;
    //  console.log("log  bb",)
    // This btn is for DC Massage
    // if (this.props.facility_grop_id) {
    //   is_supercoach = (hasPermission(roles, "court", "update") && this.props.identity.business.id == 33) && this.props.facility_grop_id.id == 43;
    // }

    return (
      <div className="lr-wrapp">
        <div>
          <DatePicker
            inline
            onChange={this.handleChangeDate}
            onSelect={() => {
              return false;
            }}
            selected={this.state.startOfDay}
            minDate={minuse6m}
            locale="en-gb"
          />
        </div>
        <p>{this.state.error}</p>
        {is_nbu && <FamilyDropdown cb={this.props.changeFamily} />}
        <div
          style={{
            margin: "0 auto 20px",
            width: `${is_owner ? "70%" : "100%"}`,
          }}
        >
          <FilterAvailabilityList
            family={this.props.family}
            is_nbu={is_nbu}
            forwardedRef={this.filterAvailabilityRef}
            selectedDate={this.state.startOfDay}
            select_coach_id={is_coach ? this.props.identity.id : undefined}
            bid={this.props.identity.business.id}
            dataForGetAvailabilityList={this.props.family ? {
              userId: this.props.family,
              startDate: moment(this.state.startOfDay).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).utc(),
              endDate: moment(this.state.startOfDay).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).add(1, 'days').utc(),
              facility_group_id: (this.props.facility_grop_id ? this.props.facility_grop_id.id : ""),
              // sport_id:(this.props.facility_grop_id?this.props.facility_grop_id.sport_category_id:""),
            } : {
              startDate: moment(this.state.startOfDay).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).utc(),
              endDate: moment(this.state.startOfDay).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).add(1, 'days').utc(),
              facility_group_id: (this.props.facility_grop_id ? this.props.facility_grop_id.id : ""),
              // sport_id:(this.props.facility_grop_id?this.props.facility_grop_id.sport_category_id:""),
            }}
          />
        </div>

        {hasPermissionOnCoachSelect && (
          <div>
            <select
              name="couchFilter"
              value={this.props.coach}
              onChange={this.props.changeCoach}
            >
              <option value="">All</option>
              {this.props.coachList.map((item) => {
                return (
                  <option
                    key={item.id}
                    value={item.id}
                  >{`${item.first_name} ${item.last_name}`}</option>
                );
              })}
            </select>
          </div>
        )}
        <div className="rules-buttons">
          {hasPermission(roles, "settings", "update") && (
            <div className="prentElementForInfoBtn">
              {/* <img src="/assets/img/red-circle.png" alt="notification" /> */}
              {expiredRules?.length > 0 && (
                <span className="expiredRules">{expiredRules?.length}</span>
              )}

              <div className="pretty-button">

                <button
                  type="button"
                  onClick={() => this.showModal("ms")}
                  className={"blue-button facility_availability_rules_btn"}
                >
                  Facility Availability Rules
                </button>
              </div>
            </div>
          )}
          {expiredRules?.length > 0 && !is_nbu && (
            <span className="expiredRulesText">One or more of your rule recurrences will expiring within a month. Please check the rules and update the End Date with a new date ON THE CALENDAR of the rule.<br />  </span>
          )}

          {is_supercoach && (
            <div className="pretty-button" style={{ marginTop: expiredRules?.length > 0 ? "10px" : "" }}>
              <button
                type="button"
                onClick={() => this.showModal("fr")}
                className={"blue-button_light"}
              >
                Facility Freeze Rules
              </button>
            </div>
          )}
          {hasPermission(roles, "settings", "update") && (
            <div className="pretty-button" style={{ marginTop: expiredRules?.length > 0 ? "10px" : "" }}>
              <button
                type="button"
                onClick={() => this.showModal("tags")}
                className={"blue-button"}
              >
                Facility Tags
              </button>
            </div>
          )}
        </div>
        {openedModal}
      </div>
    );
  }

  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    if (params.get("create")) {
      if (
        hasPermission(
          this.props.identity.member_type.roles,
          "settings",
          "update"
        )
      ) {
        // open create modal
        this.showModal("ms-add");
      }
    }
  }
}

RightColume.propTypes = {
  coachList: PropTypes.arrayOf(PropTypes.any).isRequired,
  coach: PropTypes.string.isRequired,
  currentDay: PropTypes.objectOf(PropTypes.any).isRequired,
  changeDate: PropTypes.func.isRequired,
  changeCoach: PropTypes.func.isRequired,
  isEmptyCourtListing: PropTypes.func.isRequired,
  isloading: PropTypes.bool.isRequired,
  from: PropTypes.string,
  userType: PropTypes.string.isRequired,
  namespace: PropTypes.string,
  // timeZone: PropTypes.string.isRequired,
};

RightColume.defaultProps = {
  from: "COURTSHEET",
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetCoachesRequestBody: (bid, data) => dispatch(GetCoachesRequestBody(bid, data)),
    resetCoachAvailabilityList: () => dispatch(resetCoachAvailabilityList()),
    GetExpiredRules: () => dispatch(GetExpiredRules())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RightColume);